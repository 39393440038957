import React from 'react';
import { Card, Typography, Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ActionHeader from '../actionHeader';
import { ObjectList } from '../../list';
import styles from './style';

const useStyles = makeStyles(styles);
const MainPanel = ({ header, children, style }) => {
  const classes = useStyles(style);
  return (
    <Card classes={{ root: classes.card }} style={style}>
      <Grid container spacing={0}>
        {header && (
          <Grid item xs={12}>
            {header && <ActionHeader {...header} />}
          </Grid>
        )}
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Card>
  );
};

export default MainPanel;
