const styles = theme => ({
  root: ({ removeInnerPadding }) => ({
    flexGrow: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(1),
    paddingTop: removeInnerPadding ? 0 : theme.spacing(1),
  }),

  typography: {
    padding: theme.spacing(2),
  },
  shape: {
    backgroundColor: theme.palette.primary.main,
    width: 40,
    height: 40,
  },
  alignCenter: {
    verticalAlign: 'middle',
    justifyContent: 'center',
    alignItems: 'center',
  },
  themeSwitchLabel: {
    height: 24,
  },
  shapeCircle: {
    borderRadius: theme.shape.borderRadius,
  },
  textCenter: {
    textAlign: 'center',
  },
  badge: {
    cursor: 'pointer',
  },
  switch: {
    padding: 0,
    margin: 0,
  },
  icon: {
    color: theme.palette.primary.main,
    // fontSize: theme.typography.body1.fontSize,
  },
});

export default styles;

/*
 main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  */
