import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';

// import a theme provider which we would apply the theme across all components, and
// the createMuiTheme would create a custom theme for us.
import { useGetThemeByName } from '../themes';
import { ThemeContext } from '../context';
import { useRouter, useProcessCookies, useVariables } from '../hooks';

const MaterialTheme = ({ children }) => {
  const variables = useVariables();
  const router = useRouter();
  const processCookies = useProcessCookies();
  const queryThemeCode = router?.query?.t?.toLowerCase();
  const storedThemeName = useContext(ThemeContext)?.themeName;
  const curThemeName = queryThemeCode || storedThemeName;
  const templateName = router?.query?.template || variables?.templateName;
  const [themeName, setThemeName] = useState(curThemeName);
  // Retrieve the theme object by theme name
  const getThemeByName = useGetThemeByName();
  const theme = getThemeByName(themeName, templateName);

  return (
    <ThemeContext.Provider
      value={{
        themeName,
        setThemeName: value => {
          if (value) {
            const cookieValues = {
              theme: value,
            };

            processCookies({ cookieValues });
            setThemeName(value);
          }
        },
      }}
    >
      {theme && <ThemeProvider theme={theme}>{children}</ThemeProvider>}
    </ThemeContext.Provider>
  );
};

export default MaterialTheme;

/*
[theme.breakpoints.down('xs')]: {
          minWidth: '100%',
          maxWidth: '100%',
        },
        [theme.breakpoints.up('sm')]: {
          minWidth: '450px',
          maxWidth: '100%',
        },
        [theme.breakpoints.up('md')]: {
          minWidth: '550px',
          maxWidth: '100%',
        },
        */
