import React, { useContext } from 'react';
import { Box, useMediaQuery, Hidden, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useSelector } from 'react-redux';
import components from './components';
import { useRouter } from '../../../hooks';

import styles from './style';

const ContentBox = ({ contents, isOnlyPrimary }) => {
  const classes = makeStyles(styles);

  const alpha2 = useSelector(state => state?.locations?.yourLocation?.alpha2);
  const { pathname } = useRouter();

  const newContents = contents?.filter(component => {
    const hasValidCountryCondition =
      !component?.countryCondition ||
      (component?.countryCondition &&
        component?.countryCondition?.split(',')?.includes(alpha2));

    const hasValidUrlCondition =
      !component?.urlCondition ||
      (component?.urlCondition &&
        component?.urlCondition?.split(',')?.includes(pathname));
    return hasValidCountryCondition && hasValidUrlCondition;
  });

  return (
    <Box width="100%">
      <Grid
        style={{
          // flexFlow: 'column wrap',
          width: '100%',
        }}
        justify="flex-start"
        alignItems="flex-start"
        container
        spacing={1}
      >
        {newContents?.map((content, index) => {
          const componentType = content?.componentType;
          const Component = components[componentType] || null;

          return Component && !content.hidden ? (
            <Component {...content} isOnlyPrimary={isOnlyPrimary} />
          ) : null;
        })}
      </Grid>
    </Box>
  );
};

export default ContentBox;
