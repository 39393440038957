import React, { useState } from 'react';
import { PromptContext } from '../context';

const Prompt = ({ children }) => {
  const [prompt, setPrompt] = useState(null);
  // // console.log('prompt11: ', prompt);

  return (
    <PromptContext.Provider
      value={{
        globalPrompt: prompt,
        setGlobalPrompt: value => {
          setPrompt(value);
        },
      }}
    >
      {children}
    </PromptContext.Provider>
  );
};

export default Prompt;
