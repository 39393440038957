import React from 'react';
import classnames from 'classnames';
import { Card, Grid, Skeleton, Typography, Button, Box } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';

const PurchaseNowCard = ({
  classes,
  icon,
  materialIcon,
  color,
  loading,
  finalTitle,
  isRequest,
  finalDescription,
  handleMoreInfoOpen,
  newAction,
  handleRequest,
  defaultSelectButtonText = 'Start Application',
}) => {
  const { benefits } = newAction?.additionalInfo || {};
  // console.log('benefits: ', benefits);

  return (
    <Card raised className={classnames(classes.root, classes.card, classes.defaultHover)}>
      <Grid container>
        <Grid item xs={12} md={12} className={classes.purchaseNowCard}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            className={classes.gridPadding}
            spacing={2}
          >
            <Grid item sm={12} align="left">
              {loading ? (
                <Skeleton
                  animation="wave"
                  height={20}
                  width="100%"
                  style={{ marginBottom: 6 }}
                />
              ) : (
                <Typography
                  align="left"
                  className={classnames(classes.title)}
                  sx={{ marginBottom: '.5rem' }}
                >
                  {finalTitle}
                </Typography>
              )}
              {loading ? (
                <>
                  <Skeleton
                    animation="wave"
                    height={20}
                    width="100%"
                    style={{ marginBottom: 6 }}
                  />
                  <Skeleton
                    animation="wave"
                    height={20}
                    width="100%"
                    style={{ marginBottom: 6 }}
                  />
                </>
              ) : (
                <Typography
                  align="left"
                  variant="body1"
                  className={classnames(classes.description)}
                >
                  {finalDescription}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} sx={{ p: '1rem' }}>
          <Box display="flex" flexDirection="column" gap=".5rem">
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: '.88rem',
                textAlign: 'left',
              }}
              className={classnames(classes.listItem)}
            >
              Why choose?
            </Typography>

            {benefits && (
              <Grid container>
                {benefits.map(benefit => (
                  <Grid
                    item
                    lg={6}
                    md={6}
                    xs={12}
                    display="flex"
                    direction="row"
                    gap=".5rem"
                    alignItems="center"
                    sx={{ marginBottom: '.5rem' }}
                  >
                    <CheckIcon
                      className={classnames(classes.listItem)}
                      sx={{ fontSize: '.85rem !important' }}
                    />
                    <Typography
                      className={classnames(classes.listItem)}
                      sx={{ fontSize: '.85rem !important' }}
                    >
                      {benefit}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            )}
            <Grid container spacing={2}>
              {newAction?.hasMoreInfo && (
                <Grid item xs={12} align="center">
                  {loading ? (
                    <Skeleton variant="text" animation="wave" height={40} width="50%" />
                  ) : (
                    <Button
                      onClick={handleMoreInfoOpen}
                      className={classes.secondaryButton}
                      size="small"
                    >
                      More Info
                    </Button>
                  )}
                </Grid>
              )}
              <Grid item xs={12} align="center">
                {loading ? (
                  <Skeleton variant="text" animation="wave" height={40} width="50%" />
                ) : (
                  <Button
                    onClick={handleRequest}
                    className={classes.primaryButton}
                    size="medium"
                    endIcon={<ArrowForwardIcon />}
                  >
                    {newAction?.selectButtonText || defaultSelectButtonText}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default PurchaseNowCard;
