import { gql } from 'graphql-request';

const UPLOAD_MODAL_BUTTON_FIELDS = gql`
  fragment uploadModalButton on ComponentWizardComponentsUploadModalButton {
    UploadModalButton_slug: slug
    UploadModalButton_layoutPosition: layoutPosition
    UploadModalButton_positionNo: positionNo
    UploadModalButton_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    UploadModalButton_name: name
    UploadModalButton_isConditionalComponent: isConditionalComponent
    UploadModalButton_conditionalValue: conditionalValue
    UploadModalButton_conditionalKey: conditionalKey
    UploadModalButton_conditionalOperator: conditionalOperator
    UploadModalButton_conditionalFieldType: conditionalFieldType
    UploadModalButton_conditionalAction: conditionalAction
    UploadModalButton_conditionalArrayKey: conditionalArrayKey
    UploadModalButton_accept: accept
    UploadModalButton_autoUpload: autoUpload
    UploadModalButton_uploadButtonColor: uploadButtonColor
    UploadModalButton_uploadButtonVariant: uploadButtonVariant
    UploadModalButton_uploadButtonSize: uploadButtonSize
    UploadModalButton_buttonColor: buttonColor
    UploadModalButton_buttonSize: buttonSize
    UploadModalButton_buttonVariant: buttonVariant
    UploadModalButton_uploadButtonText: uploadButtonText
    UploadModalButton_uploadButtonText_en: uploadButtonText_en
    UploadModalButton_uploadButtonText_nl: uploadButtonText_nl
    UploadModalButton_uploadUrl: uploadUrl
    UploadModalButton_icon: icon
    UploadModalButton_multiple: multiple
    UploadModalButton_inverseBackgroundColor: inverseBackgroundColor
    UploadModalButton_selectFileText: selectFileText
    UploadModalButton_selectFileText_en: selectFileText_en
    UploadModalButton_selectFileText_nl: selectFileText_nl
    UploadModalButton_uploadConfig: uploadConfig {
      xs
      sm
      md
      align
    }
    UploadModalButton_formData: formData
    UploadModalButton_fullWidth: fullWidth
    UploadModalButton_wizardUpload: wizardUpload
    UploadModalButton_label: label
    UploadModalButton_label_en: label_en
    UploadModalButton_label_nl: label_nl
    UploadModalButton_required: required
    UploadModalButton_disable: disable
    UploadModalButton_show: show
    UploadModalButton_buttonConfig: buttonConfig {
      xs
      sm
      md
      align
    }
    UploadModalButton_labelAlign: labelAlign
    UploadModalButton_containerName: containerName
    UploadModalButton_disableInitialValue: disableInitialValue
    UploadModalButton_tooltip: tooltip
    UploadModalButton_tooltip_nl: tooltip_nl
    UploadModalButton_tooltip_en: tooltip_en
    UploadModalButton_appendKeys: appendKeys {
      ...appendKey
    }
    UploadModalButton_reloadWizard: reloadWizard
    UploadModalButton_uploadTitle: uploadTitle
    UploadModalButton_resetCountWhenUnMount: resetCountWhenUnMount
    UploadModalButton_resetKeys: resetKeys {
      ...appendKey
    }
    UploadModalButton_conditions: conditions {
      ...condition
    }
    UploadModalButton_modalForm: modalForm {
      ...nonRecursiveFormWrapper
    }
  }
`;

/*
  modalForm {
      ...nonRecursiveFormWrapper
    }
    */

// export default UPLOAD_MODAL_BUTTON_FIELDS;
// exports.default = UPLOAD_MODAL_BUTTON_FIELDS;
export default UPLOAD_MODAL_BUTTON_FIELDS;
