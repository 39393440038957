import {
  SUBMIT_HEALTH_CLAIM_REQUEST,
  SUBMIT_HEALTH_CLAIM_SUCCESS,
  SUBMIT_HEALTH_CLAIM_ERROR,
} from '../../../constants/claimActionNames';
import { saveClaimDetailsApi } from '../../../apis/claims';
import { RESET_PENDING_CLAIMS_SUCCESS } from '../../../constants/pendingClaimActionNames';
import { resetClaimsAction } from '../resetClaims';

function submitHealthClaimsRequest() {
  return {
    type: SUBMIT_HEALTH_CLAIM_REQUEST,
    payload: true,
  };
}

function submitHealthClaimsSuccess(res) {
  const { payload: { c360ClaimId, draft } = {} } = res || {};
  return {
    type: SUBMIT_HEALTH_CLAIM_SUCCESS,
    payload: { c360ClaimId, draft },
  };
}

function submitHealthClaimsError(err) {
  return {
    type: SUBMIT_HEALTH_CLAIM_ERROR,
    payload: err,
  };
}

export function submitHealthClaimsAction(params, successCallback, failureCallBack) {
  return async (dispatch, getState) => {
    const state = getState();
    const c360ClaimId = state?.claims?.healthClaimId;

    const { submitClaimDetails } = saveClaimDetailsApi;
    dispatch(submitHealthClaimsRequest());
    try {
      const res = await submitClaimDetails(state, { ...params, c360ClaimId });
      dispatch(submitHealthClaimsSuccess(res));
      dispatch({type: RESET_PENDING_CLAIMS_SUCCESS});
      dispatch(resetClaimsAction());
      // eslint-disable-next-line no-unused-expressions
      successCallback && successCallback(res?.payload?.c360ClaimId);
      return res;
    } catch (e) {
      dispatch(submitHealthClaimsError(e));
      // eslint-disable-next-line no-unused-expressions
      failureCallBack && failureCallBack();
    }
  };
}

export default { submitHealthClaimsAction };
