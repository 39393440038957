import { getRef } from './objectManipulation';

export const getValueFromArray = (array, index) => {
  if (array && Array.isArray(array) && array.length > 0) {
    return array[index] || '';
  }
  return '';
};

export const keySort = (arr = [], path = '', reverse) => {
  const sortOrder = reverse ? -1 : 1;
  const pathSplitted = path.split('.');

  if (arr.length <= 1) {
    return arr;
  }
  return arr.sort(function(a, b) {
    let x = a;
    let y = b;

    x = getRef(x, path);

    y = getRef(y, path);

    /*
    pathSplitted.forEach(function(key) {
      x = x[key];
      y = y[key];
    });
    */

    return sortOrder * (x < y ? -1 : x > y ? 1 : 0);
  });
};

export const groupBy = (list, keyGetter) => {
  const map = new Map();
  list.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

export const getEntries = (o = {}) => {
  const entries = [];
  for (const [k, v] of Object.entries(o)) {
    if (Array.isArray(v)) entries.push(...v.flatMap(getEntries));
    else if (typeof v === 'object') entries.push(...getEntries(v));
    else entries.push([k, v]);
  }
  return entries;
};

export function arrayBufferToBase64(buffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function base64ToArrayBuffer(base64) {
  const binary_string = window.atob(base64);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

export function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export const removeDuplicates = (array, key) => {
  return array.reduce((arr, item) => {
    const removed = arr.filter(i => i[key] !== item[key]);
    return [...removed, item];
  }, []);
};

export const addItemOnce = (arr, item, key) => {
  if (!item) return arr;
  let index = -1;
  if (key) index = arr.findIndex(oneItem => oneItem[key] === item[key]);
  else index = arr.findIndex(oneItem => oneItem === item);

  if (index > -1) {
    arr.splice(index, 1, item);
  } else {
    arr.push(item);
  }
  return arr;
};

export const removeItemOnce = (arr, item, key) => {
  if (!item) return arr;
  let index = -1;
  if (key) index = arr.findIndex(oneItem => oneItem[key] === item[key]);
  else index = arr.findIndex(oneItem => oneItem === item);

  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

export const flatten = arr => {
  const result = [];
  const stack = arr;
  let first;

  while (stack.length > 0) {
    first = stack[0];

    if (Array.isArray(first)) {
      // Replace the nested array with its items
      Array.prototype.splice.apply(stack, [0, 1].concat(first));
    } else {
      result.push(first);
      // Delete the first item
      stack.splice(0, 1);
    }
  }
  return result;
};

export const uniq = a => {
  return a.sort().filter((item, pos, ary) => {
    return !pos || item !== ary[pos - 1];
  });
};

export const mergeArraysDeep = (arr1, arr2) => {
  const unique = arr1.concat(arr2).reduce((hash, item) => {
    const current = hash[item.id];

    if (!current) {
      hash[item.id] = item;
    } else if (Array.isArray(current.data)) {
      current.data = mergeArraysDeep(current.data, item.data);
    } else {
      current.data = item.data;
    }

    return hash;
  }, {});

  return Object.keys(unique).map(key => {
    return unique[key];
  });
};

export const createStringArray = (arr, key) => {
  if (!arr) return arr;
  const result = [];
  for (let i = 0; i < arr.length; i += 1) {
    result.push(arr[i][key]);
  }
  return result;
};

export const addArrayOnce = (arr1, arr2, key) => {
  let finalArray = arr1;
  arr2?.map(x => {
    finalArray = addItemOnce(finalArray, x, key);
  });
  return finalArray;
};

export const mergeItemOnce = (arr, item, key) => {
  if (!item) return arr;
  let index = -1;
  if (key) index = arr.findIndex(oneItem => oneItem[key] === item[key]);
  else index = arr.findIndex(oneItem => oneItem === item);

  if (index > -1 && item) {
    arr.splice(index, 1, { ...arr[index], ...item });
  } else if (item) {
    arr.push(item);
  }
  return arr;
};

export const mergeArrayOnce = (arr1, arr2, key) => {
  let finalArray = arr1;
  arr2?.map(x => {
    finalArray = mergeItemOnce(finalArray, x, key);
  });
  return finalArray;
};

export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map(item => [item[key], item])).values()];
};
