import { portfolioButtonStyles } from '../../../styles';

export default theme => ({
  ...portfolioButtonStyles(theme),
  base: {
    border: '1px solid',
    textTransform: 'none',
    fontWeight: 400,
    borderRadius: '8px',
    minHeight: ({ buttonSize, minHeight }) =>
      minHeight || (buttonSize === 'small' && '35px') || '48px',
    // maxHeight: '58px',
    width: ({ width }) => width,
    height: ({ height }) => height,
    minWidth: ({ minWidth }) => minWidth || '50px',
    margin: ({ isMargin, margin }) => (isMargin ? margin : 0),
    lineHeight: 1.4,
  },
  chevron: {
    color: 'inherit',
    display: 'none',
    paddingLeft: ({ buttonSize }) =>
      buttonSize === 'small' ? theme.spacing(1) : theme.spacing(0),
  },
  buttonIcon: {
    // paddingRight: '20px',
    // paddingLeft: '20px',
    fontSize: '25px',
  },
  svgButton: {
    display: ' inline-block',
    // margin: '0.5rem 1.5rem',
    // borderRadius: '3rem',
    // fontSize: '0.15rem',
    [theme.breakpoints.up('md')]: {
      transition: 'all 0.3s',
    },
    // height: '100%',
    position: 'relative',
    // overflow: 'hidden',
    zIndex: 1,
    // boxShadow: '0px 6px 8px #43434329',
    // backgroundColor: processColor(theme, 'secondary'),
    // minWidth: theme.spacing(12),
    height: '100%',
    width: '100%',
  },
  text: {
    color: ({ textColor }) =>
      textColor === 'inherit'
        ? undefined
        : textColor === 'default'
        ? 'inherit'
        : textColor && theme?.palette?.[textColor]?.main,
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: 'inherit',
    '& $chevron': {
      display: 'block',
    },
    '&:hover': {
      '& $chevron': {
        display: 'block',
        '-webkit-transform': 'rotate(90deg)',
        '-moz-transform': 'rotate(90deg)',
        '-ms-transform': 'rotate(90deg)',
        '-o-transform': 'rotate(90deg)',
        transform: 'rotate(90deg)',
      },
      // color: ({ buttonColor, buttonHoverColor }) =>
      // buttonHoverColor
      // ? processColor(theme, buttonHoverColor, colorShades.main)
      // : processColor(theme, buttonColor),
      backgroundColor: 'transparent',
      fontWeight: 'bold !important',
      border: 'none',
    },
  },
});
