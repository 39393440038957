import { UPDATE_SINGLE_ACCOUNT } from '../../../constants/portfolio/investmentActionNames';

export function updateSingleAccountAction(data) {
  return {
    type: UPDATE_SINGLE_ACCOUNT,
    payload: data,
  };
}

export default {
  updateSingleAccountAction,
};
