import { OPEN_CHAT_BOT_SUCCESS } from '../../../constants/miscActionNames';

function openChatBotSuccess(prod) {
  return {
    type: OPEN_CHAT_BOT_SUCCESS,
    payload: prod,
  };
}

export function openChatBotAction(data) {
  return openChatBotSuccess(data);
}

export default {
  openChatBotAction,
};
