import * as React from 'react';
import { useState } from 'react';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';

export function PasswordField({
  placeholder,
  label,
  handleChange,
  name,
  value,
  error,
  borderRadius,
  width,
  helperText,
  background,
  ...props
}) {
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const iconColorStyles =
    value !== '' ? { color: 'text.primary' } : { color: 'text.disabled' };

  return (
    <Box
      {...props}
      width={{
        xs: '100%',
        sm: '100%',
        md: width || '100%',
      }}
    >
      <Typography
        color="text.label"
        sx={{
          color: 'text.label',
          fontSize: '1rem',
          fontWeight: '500',
          mb: '0.5rem',
        }}
      >
        {label}
      </Typography>
      <TextField
        fullWidth
        error={error}
        sx={{
          background: `${theme.palette.background.paper}`,
          borderRadius,
          '& .MuiOutlinedInput-root': {
            borderRadius: borderRadius || null,
            background: background || `${theme.palette.background.main}`,
          },
          '& .MuiInputBase-input ': {
            padding: '0.75rem 0 0.75rem 1.25rem',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.background.contentBox}`,
          },
        }}
        id="outlined-adornment-password"
        type={showPassword ? 'text' : 'password'}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        helperText={helperText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {value ? (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? (
                    <Visibility sx={iconColorStyles} />
                  ) : (
                    <VisibilityOff sx={iconColorStyles} />
                  )}
                </IconButton>
              ) : (
                <Visibility sx={iconColorStyles} />
              )}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}
