import React from 'react';
import { useSelector } from 'react-redux';
import {
  Paper,
  Modal,
  Fade,
  Button,
  Backdrop,
  FormControl,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Radio,
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useManualCountryChange } from '../../hooks';
import { Header } from '../../componentsV2/common';
import { formatImageUrlFromStrapi } from '../../utils/functions';
import styles from './style';

const useStyles = makeStyles(styles);

export default function ModalCountrySelector({ options, alpha2 }) {
  const classes = useStyles();
  const countryChange = useManualCountryChange();
  const allLocations = useSelector(state => state.locations.allLocations);

  const initialValue = options?.find(x => x?.alpha2?.includes(alpha2))?.alpha2;

  const [value, setValue] = React.useState(initialValue);

  const handleChange = event => {
    setValue(event.target.value);
  };

  return (
    <div>
      <Modal
        className={classes.modal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in>
          <Paper className={classes.paper}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Header
                  align="left"
                  title="Select a Territory"
                  subTitle="Please select the region where you currently have insurance or investments with us, or where you reside."
                  disableSeo
                />
              </Grid>
              <Grid item xs={12}>
                <RadioGroup
                  aria-labelledby="country-radio-buttons-group-label"
                  value={value}
                  name="territory-buttons-group"
                  onChange={handleChange}
                >
                  {options &&
                    options?.length &&
                    options?.map(option => {
                      const territory = allLocations?.find(
                        x => x?.alpha2 === option.alpha2,
                      );
                      return (
                        <FormControlLabel
                          value={option?.alpha2}
                          sx={{ my: 0.5 }}
                          control={<Radio />}
                          label={
                            <Grid container spacing={1} sx={{ width: '15rem' }}>
                              <Grid item xs={4}>
                                <img
                                  style={{ marginRight: 30 }}
                                  src={
                                    territory?.staticFlagLink
                                      ? territory?.staticFlagLink
                                      : formatImageUrlFromStrapi(territory?.flag)?.url
                                  }
                                  className={classes.flag}
                                  alt="Logo"
                                />
                              </Grid>
                              <Grid item xs={8} align="left">
                                <Typography color="primary" variant="h6">
                                  {option?.title}
                                </Typography>
                              </Grid>
                            </Grid>
                          }
                        />
                      );
                    })}
                </RadioGroup>
              </Grid>
              <Grid item xs={12}>
                <Button
                  sx={{ my: 2 }}
                  size="large"
                  onClick={() => {
                    if (value) {
                      countryChange(value);
                    } else {
                      window.alert('Please select a Territory first.');
                    }
                  }}
                  className={classes.primaryButton}
                  disabled={!value}
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
}
