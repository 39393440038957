import React from 'react';

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Box,
  InputLabel,
  FormControl,
  FormHelperText,
} from '@mui/material';

export function CheckBox({
  labelName,
  placeholder,
  required,
  name,
  value,
  handleChange,
  error,
  helperText,
  textColor,
  width,
  ...props
}) {
  return (
    <Box {...props}>
      <InputLabel
        shrink
        htmlFor="labelName"
        sx={{
          width: 'fit-content',
          color: 'text.secondary',
          fontFamily: 'default',
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '1.25rem',
          lineHeight: '1.5rem',
          '& .MuiCheckbox-root.Mui-checked': { color: 'primary.main' },
        }}
      >
        {labelName && labelName}
        {required && ' *'}
      </InputLabel>
      <FormGroup>
        <FormControl required error={error}>
          <FormControlLabel
            control={
              <Checkbox
                name={name}
                value={value}
                onChange={handleChange}
                checked={!!value}
                data-testid={`checkboxIcon`}
              />
            }
            label={placeholder}
            sx={{
              color: textColor || 'text.disabled',
              fontFamily: 'default',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '1rem',
              lineHeight: '1.5rem',
              width: {
                xs: '100%',
                sm: '100%',
                md: width || '100%',
              },
            }}
          />
        </FormControl>

        <FormHelperText
          sx={{
            color: 'error.main',
            marginTop: '3px',
            marginRight: '14px',
            marginBottom: '0',
            marginLeft: '14px',
          }}
        >
          {error ? helperText : ''}
        </FormHelperText>
      </FormGroup>
    </Box>
  );
}
