export const DOCUMENTSUPLOADURL = 'documentsUploadUrl';

export const GETSINGLEHELPCENTERCASEURL = 'getSingleHelpCenterCaseUrl';

export const REOPENORCANCELCASEURL = 'reOpenOrCancelCaseUrl';

export const GETREQUIREDDOCUMENTSURL = 'getRequiredDocumentsUrl';
export const GETREQUIREDDOCUMENTSURLJM = 'getRequiredDocumentsUrlJM';

export const GETTICKETHISTORYURL = 'getTicketHistoryUrl';

export const GETDROPDOWNVALUESURL = 'getDropdownValuesUrl';

export const GETQNRVALUESURL = 'getQnRValuesUrl';

export const GETRESOLUTIONDATEURLJM = 'getResolutionDateUrlJM';

export const GETTICKETSPECIFICCSREPDETAILSJM = 'getTicketSpecificCSRepDetailsUrl';

export const NOTIFYMEONUPDATEURL = 'notifyMeOnUpdateUrl';

export const SUBMITHELPREQUESTURL = 'submitHelpRequestUrl';

export const GETSURVEYLINKURL = 'getSurveyLinkUrl';

export const SERVERERROR = 'Server Error';
