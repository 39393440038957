const styles = theme => ({
  root: {
    display: 'block',
    // marginTop: theme.spacing(2),
    height: '100%',
    width: '100%',
  },
  mediaPlayer: {
    [theme.breakpoints.down('sm')]: {
      minHeight: '250px',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '350px',
    },
  },
});

export default styles;
