import { crmPostCall } from '../../axios';
import { helpCenterApi } from '../../../utils/functions/helpCenterApis';
import { GETTICKETSPECIFICCSREPDETAILSJM, SERVERERROR } from '../constants';

export const getTicketSpecificCSRepDetailsUrl = helpCenterApi(
  GETTICKETSPECIFICCSREPDETAILSJM,
);
const getTicketSpecificCSrepDetailsRequest = (state, body) => {
  const url = getTicketSpecificCSRepDetailsUrl;
  if (url) {
    return crmPostCall(state, url, body);
  }
  return Promise.reject(new Error(SERVERERROR));
};

export default { getTicketSpecificCSrepDetailsRequest };
