import Fuse from 'fuse.js';
import queryString from 'query-string';
import { fuseOptions, trimOneChar } from './functions';

export const portfolioInvestmentFind = (state, match) => {
  // const { location } = state.router;
  const accounts = state?.investments?.accounts || [];

  // // console.log('accounts: ', accounts);

  const id = match?.params?.id;

  const account = accounts.find(info => info.accountId === id);
  return account;
};

export const portfolioInvestmentFilter = state => {
  // const { allRows = [] } = state[name] || { allRows: [] };
  // // console.log('Claims Selector');
  const { searchKeys, accounts } = state.investments;
  const { location } = state.router;
  const parsed = queryString.parse(location.search);
  const options = { ...parsed };
  // // console.log('options: ', options);
  let filteredRows = [...accounts];

  if (options.search && options.search.length > 0 && filteredRows.length > 0) {
    // const obj = filteredRows && filteredRows.length > 0 ? filteredRows[0] : {};
    // // console.log('obj: ', obj);
    const fuse = new Fuse(filteredRows, fuseOptions(searchKeys));
    let searchResults = fuse.search(options.search);
    if (!searchResults || searchResults.length === 0) {
      options.search = `*${options.search}*`;
      searchResults = fuse.search(options.search); // .replace(/ /g, '|')
      options.search = trimOneChar(options.search, '*');
    }

    // // console.log('searchResults: ', searchResults);
    filteredRows = searchResults.map(result => result.item);
  }

  filteredRows = filteredRows.filter(el => {
    return el != null;
  });

  return { accounts: filteredRows, options, count: accounts?.length };
};

export default {
  portfolioInvestmentFind,
  portfolioInvestmentFilter,
};
