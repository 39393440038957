import {
  GET_SINGLE_CLIENT_API_REQUEST,
  GET_SINGLE_CLIENT_API_SUCCESS,
  GET_SINGLE_CLIENT_API_ERROR,
} from '../../../constants/clientActionNames';
import { getClientsApi } from '../../../apis/clients';

function getSingleClientRequest(params, body) {
  return {
    type: GET_SINGLE_CLIENT_API_REQUEST,
    payload: true,
  };
}

function getSingleClientSuccess(client, params, body) {
  return {
    type: GET_SINGLE_CLIENT_API_SUCCESS,
    payload: client,
  };
}

function getSingleClientError(err, params, body) {
  return {
    type: GET_SINGLE_CLIENT_API_ERROR,
    payload: err,
  };
}

export function getSingleClientAction(params, body) {
  // const response = getPayloadResponse();
  return (dispatch, getState) => {
    const state = getState();

    dispatch(getSingleClientRequest(params, body));
    return getClientsApi
      .getSingleClient(state, params)
      .then(data => {
        dispatch(getSingleClientSuccess(data, params, body));
        return data;
      })
      .catch(error => {
        dispatch(getSingleClientError(error, params, body));
        throw error;
        // return response;
      });
  };
}

export default { getSingleClientAction };
