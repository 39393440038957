import { fade } from '@mui/material';

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      maxWidth: '600px',
    },
  },
  popover: {
    marginRight: theme.spacing(2),
  },
  alignCenter: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      minHeight: '200px',
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: '300px',
    },
  },
  main: {
    padding: theme.spacing(0),
    display: 'inline-block',
  },
  searchContainer: {
    display: 'inline-block',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      // height: '100px',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      background: theme.palette.background.paper,
    },

    border: `${theme.shape.borderWidth}px solid ${theme.palette.background.grid}`, // #707070
    // height: '100%',
  },
  clearFilterContainer: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      // height: '100px',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      background: theme.palette.background.paper,
    },
    justifyContent: 'center',
    alignItems: 'center',
    border: `${theme.shape.borderWidth}px solid ${theme.palette.background.grid}`, // #707070
    // height: '100%',
  },
  containerText: {
    font: 'normal normal normal 14px Roboto',
    letterSpacing: '0px',
    // color: `${theme.palette.grey[700]}`, // '#404041',
    paddingBottom: theme.spacing(1),
  },
  filter: {
    [theme.breakpoints.down('sm')]: {
      cursor: 'pointer',
      color: theme.palette.secondary.main,
      // background: `${theme.palette.background.default} 0% 0% no-repeat padding-box`,
      // boxShadow: '0px 3px 6px #00000029',
      // borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      cursor: 'pointer',
      color: theme.palette.secondary.main,
      // background: `${theme.palette.background.default} 0% 0% no-repeat padding-box`,
      // boxShadow: '0px 3px 6px #00000029',
      // borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0.5),
      '& .MuiIcon-root': {
        fontSize: '2.5rem',
      },
    },
    [theme.breakpoints.up('md')]: {
      padding: '0px',
    },
  },
  clearFilter: {
    fontSize: '1.3rem',
  },
  popoverClearFilter: {
    fontSize: '1.2rem',
  },
  iconClearFilter: {
    paddingTop: '7px',
  },
  iconPopoverClearFilter: {
    paddingTop: '15px',
  },
  popoverFilter: {
    [theme.breakpoints.down('sm')]: {
      cursor: 'pointer',
      color: `${theme.palette.grey.A700}`, // '#AEAEAE',
      background: `${theme.palette.background.default} 0% 0% no-repeat padding-box`,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1),
    },
  },
  clearButton: {
    marginLeft: theme.spacing(1),
    textTransform: 'unset !important',
  },
});

export default styles;
