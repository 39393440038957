import {
  CREATE_BATCH_CLAIM_REQUEST,
  CREATE_BATCH_CLAIM_SUCCESS,
  CREATE_BATCH_CLAIM_ERROR,
  UPDATE_BATCH_CLAIM_FIELD,
  UPDATE_BATCH_CLAIM_SUCCESS,
  UPDATE_BATCH_CLAIM_ERROR,
  UPDATE_BATCH_CLAIM_REQUEST,
  TRANSMITTAL_FILE_IRREGULAR_DATA,
  RESET_BATCH_CLAIM_FORM,
  TOGGLE_BATCH_CLAIM_INSTRUCTION_MODAL,
  IS_BATCH_CLAIMS_FORM_DIRTY,
} from '../../constants/claimActionNames';

export const initialState = {
  batchClaimRequestPending: false,
  batchClaimRequestError: false,
  batchClaimRequestSuccess: false,
  batchClaimResponceError: null,
  updateBatchClaimRequestPending: false,
  updateBatchClaimRequestSuccess: false,
  updateBatchClaimRequestError: false,
  batchId: '',
  showInstructionModal: true,
  transmittalDetails: [],
  isTransmittalFilesImproper: false,
  transmittalFileIssues: [],
  batchFile: '',
  stage: '',
  isBatchClaimsFormDirty: false,
  healthClaimForm: {
    organisationName: '',
    transmittalDetailsMode: 'manualEntry',
    transmittalDetailsManualEntry: [],
    transmittalDetailsFile: [],
    claimForms: [],
    userConfirmation: false,
  },
};

function batchClaimsReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_BATCH_CLAIM_REQUEST: {
      return {
        ...state,
        batchClaimRequestError: false,
        batchClaimRequestSuccess: false,
        batchClaimRequestPending: true,
      };
    }
    case CREATE_BATCH_CLAIM_SUCCESS: {
      return {
        ...state,
        batchClaimRequestError: false,
        batchClaimRequestSuccess: true,
        batchClaimRequestPending: false,
        batchId: action.payload.batchId,
        transmittalDetails: action.payload.transmittalDetails,
        batchFile: action.payload.batchFile,
        stage: action.payload.stage,
        transmittalFileIssues: [],
        isTransmittalFilesImproper: false,
      };
    }
    case CREATE_BATCH_CLAIM_ERROR: {
      return {
        ...state,
        batchClaimRequestError: true,
        batchClaimRequestSuccess: false,
        batchClaimRequestPending: false,
      };
    }
    case UPDATE_BATCH_CLAIM_FIELD: {
      const transmittalDetails =
        action.payload.transmittalDetails ?? state.transmittalDetails;
      const batchFile = action.payload.batchFile ?? state.batchFile;
      const isTransmittalFilesImproper =
        action.payload.isTransmittalFilesImproper ?? state.isTransmittalFilesImproper;
      delete action.payload.batchFile;
      delete action.payload.transmittalDetails;
      delete action.payload.isTransmittalFilesImproper;
      return {
        ...state,
        healthClaimForm: {
          ...state.healthClaimForm,
          ...action.payload,
        },
        transmittalDetails,
        batchFile,
        isTransmittalFilesImproper,
      };
    }
    case UPDATE_BATCH_CLAIM_SUCCESS: {
      return {
        ...state,
        updateBatchClaimRequestPending: false,
        updateBatchClaimRequestSuccess: true,
        updateBatchClaimRequestError: false,
        transmittalDetails: action.payload.transmittalDetails,
        batchFile: action.payload.batchFile,
        stage: action.payload.stage,
        transmittalFileIssues: [],
        isTransmittalFilesImproper: false,
      };
    }
    case UPDATE_BATCH_CLAIM_ERROR: {
      return {
        ...state,
        updateBatchClaimRequestPending: false,
        updateBatchClaimRequestSuccess: false,
        updateBatchClaimRequestError: true,
      };
    }
    case UPDATE_BATCH_CLAIM_REQUEST: {
      return {
        ...state,
        updateBatchClaimRequestPending: true,
        updateBatchClaimRequestSuccess: false,
        updateBatchClaimRequestError: false,
      };
    }
    case TRANSMITTAL_FILE_IRREGULAR_DATA: {
      return {
        ...state,
        transmittalFileIssues: action.payload.transmittalFileIssues,
        isTransmittalFilesImproper: !!action.payload.transmittalFileIssues,
        updateBatchClaimRequestPending: false,
        updateBatchClaimRequestSuccess: false,
        updateBatchClaimRequestError: false,
        batchClaimRequestPending: false,
        batchClaimRequestError: false,
        batchClaimRequestSuccess: false,
      };
    }
    case RESET_BATCH_CLAIM_FORM: {
      return {
        ...initialState,
      };
    }
    case TOGGLE_BATCH_CLAIM_INSTRUCTION_MODAL: {
      return {
        ...state,
        showInstructionModal: action.payload,
      };
    }
    case IS_BATCH_CLAIMS_FORM_DIRTY: {
      return {
        ...state,
        isBatchClaimsFormDirty: action.payload,
      };
    }
    default:
      return state;
  }
}

export default batchClaimsReducer;
