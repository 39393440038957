import { portfolioButtonStyles } from '../../../styles';

export default theme => ({
  ...portfolioButtonStyles(theme),

  root: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  card: {
    padding: theme.spacing(2),
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  cardStyleError: {
    borderRadius: theme.shape.borderRadius,
    border: `${theme.shape.borderWidth}px solid ${theme.palette.error.main}`,
  },
  cardStyleSuccess: {
    borderRadius: theme.shape.borderRadius,
    border: `${theme.shape.borderWidth}px solid ${theme.palette.success.main}`,
  },
  iconStyle: {
    color: theme.palette.success.main,
    fontSize: theme.typography.h2.fontSize,
  },
  iconStyleError: {
    color: theme.palette.error.main,
    fontSize: theme.typography.h2.fontSize,
  },
  iconStyleInfo: {
    color: theme.palette.info.main,
    fontSize: theme.typography.h2.fontSize,
  },
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.text.primary),
    '&:hover,&:focus': {
      backgroundColor: theme.palette.getContrastText(theme.palette.text.primary),
      color: theme.palette.primary.main,
    },
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.getContrastText(theme.palette.text.primary),
    '&:hover,&:focus': {
      backgroundColor: theme.palette.getContrastText(theme.palette.text.primary),
      color: theme.palette.secondary.main,
    },
  },
  info: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.getContrastText(theme.palette.text.primary),
    '&:hover,&:focus': {
      backgroundColor: theme.palette.getContrastText(theme.palette.text.primary),
      color: theme.palette.info.main,
    },
  },
  success: {
    backgroundColor: theme.palette.success.main,
    // color: theme.palette.getContrastText(theme.palette.text.primary),
    '&:hover,&:focus': {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.success.main,
    },
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    // color: theme.palette.getContrastText(theme.palette.text.primary),
    '&:hover,&:focus': {
      backgroundColor: theme.palette.warning.dark,
      color: theme.palette.warning.main,
    },
  },
  error: {
    // color: theme.palette.error.main,
    backgroundColor: theme.palette.error.main,
    '&:hover,&:focus': {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.error.main,
    },
  },
  errorOutlined: {
    color: theme.palette.error.main,
  },
  default: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    '&:hover,&:focus': {
      backgroundColor: theme.palette.getContrastText(theme.palette.text.primary),
      color: theme.palette.background.default,
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    '&:hover,&:focus': {
      backgroundColor: theme.palette.getContrastText(theme.palette.text.primary),
      color: theme.palette.background.paper,
    },
  },
  grid: {
    backgroundColor: theme.palette.background.grid,
    color: theme.palette.text.primary,
    '&:hover,&:focus': {
      backgroundColor: theme.palette.getContrastText(theme.palette.text.primary),
      color: theme.palette.background.grid,
    },
  },

  transparent: {
    '&,&:focus,&:hover,&:visited': {
      color: 'inherit',
      background: 'transparent',
    },
  },
});
