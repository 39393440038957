export default [
  {
    id: '101',
    mode: 'Monthly',
  },
  {
    id: '102',
    mode: 'Annually',
  },
  {
    id: '103',
    mode: 'Semi-Annually',
  },
  {
    id: '104',
    mode: 'Quaterly',
  },
];
