import { dateToString, isoStringToDate } from '../../dateManipulation';
import routes from '../../../variables/routes';
import {
  motorLob,
  homeLob,
  motorAccidentType,
  thirdPartyType,
  windscreenType,
  keyReplacementType,
} from '../../../variables';
// import stepIndexMappingObject from './stepIndexMapping';

// function to get stepIndex
export const getStepIndex = (wizard, response) => {
  const { stage } = response || {};
  const forms = wizard?.forms || [];
  const stepIndex = forms?.find(x => x?.key === stage)?.positionNo;
  return stepIndex;
};
// function to generate url
export const createUrl = guestSessionId => {
  return `&guestSessionId=${guestSessionId}`;
};

// main function to map partial claim details
export const createPartialPayanything = (wizard, result) => {
  const response = result.payAnythingDetails;
  // getting stepIndex
  const stepIndex = wizard ? getStepIndex(wizard, response)?.toString() : '0';
  const draft = {
    ...payAnythingResponseMapping(response),
    stepIndex,
  };
  draft.guestSessionId = result?.guestSessionId;
  return draft;
};

function payAnythingResponseMapping(response) {
  const responseObject = {};
  if (response?.verifiedEmail) {
    responseObject.verify_email = response?.verifiedEmail;
  }
  responseObject.firstName = response?.policyDetails?.firstName;
  responseObject.lastName = response?.policyDetails?.lastName;
  responseObject.policyAmount = response?.policyDetails?.policyAmount;
  responseObject.policyNumber = response?.policyDetails?.policyNumber;
  responseObject.TRNNumber = response?.payorDetails?.trnNumber;
  responseObject.payorFirstName = response?.payorDetails?.firstName;
  responseObject.payorLastName = response?.payorDetails?.lastName;
  responseObject.payorEmail = response?.payorDetails?.email;
  responseObject.phone = response?.payorDetails?.phone;
  responseObject.stage = response?.stage;
  responseObject.additionalNotes = response?.policyDetails?.additionalNotes;
  responseObject.sameAsInsured = response?.policyDetails?.sameAsInsured;
  return responseObject;
}
