import { crmPostCall } from '../../../axios';
import { helpCenterApi } from '../../../../utils/functions/helpCenterApis';
import { GETQNRVALUESURL, SERVERERROR } from '../../constants';

export const getQnRValuesUrl = helpCenterApi(GETQNRVALUESURL);
const getQnRValues = (state, body) => {
  const url = getQnRValuesUrl;
  if (url) {
    return crmPostCall(state, url, body);
  }
  return Promise.reject(new Error(SERVERERROR));
};

export default { getQnRValues };
