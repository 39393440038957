import { gql } from 'graphql-request';

const FORM_API_CALL_FIELDS = gql`
  fragment formApiCall on C360CustomFormApiCall {
    title
    url
    httpMethod
    params {
      ...param
    }
    savedKey
    storeReferenceKey
    filterSettings {
      ...nonRecursiveOption
    }
    fireOnParamChange
    verifiedEmailField
    responseType
    storeMethod
    replaceItemName
    conditions {
      ...condition
    }
    allInitialWizardDataAppendKeys {
      ...appendKey
    }
    modifyApiSettings
  }
`;

export default FORM_API_CALL_FIELDS;
