import {
  isIOS,
  isIOSSafari,
  isMobileSafari,
  isSafari,
  iOSversion,
} from '../utils/functions';
import useShouldShowPrompt from './useShouldShowPrompt';
import { iosInstallPromptedAtStorage } from '../utils/variables';

const useIosInstallPrompt = ({ bypassTimeCheck }) => {
  const [
    userShouldBePromptedToInstall,
    handleUserSeeingInstallPrompt,
  ] = useShouldShowPrompt({ promptName: iosInstallPromptedAtStorage, bypassTimeCheck });
  // // console.log('userShouldBePromptedToInstall ios: ', userShouldBePromptedToInstall);
  // iosVersion > 12 &&
  const iosVersion = iOSversion();
  return [
    !('standalone' in window.navigator || window.navigator.standalone) &&
      (isMobileSafari() || isSafari() || isIOSSafari()) &&
      userShouldBePromptedToInstall,
    handleUserSeeingInstallPrompt,
  ];
};
export default useIosInstallPrompt;
