import React, { useState, useRef, useCallback } from 'react';
import {
  Box,
  // Container,
  // Typography,
  // Paper,
  NoSsr,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
// import PropTypes from 'prop-types';
import { useScreenWidth } from '../../../hooks';
import styles from './style';
import { defaultImage } from '../../../utils/variables/images';
import { dutchTemplate } from '../../../utils/variables';
// import { formatImageUrlFromStrapi, getBase64Image } from '../../../utils/functions';

const { sanitizeUrl } = require('@braintree/sanitize-url');

/*
const nullChecks = ({ fit, offsetX, offsetY, scale, fixedSettings = {}, ...rest }) => {
  // fit = fit && fit.lg && fit.sm && fit.md ? fit : { lg: 'auto', sm: 'auto', md: 'auto' };

  offsetX =
    offsetX && offsetX.lg && offsetX.sm && offsetX.md
      ? offsetX
      : { lg: '0px', sm: '0px', md: '0px' };
  offsetY =
    offsetY && offsetY.lg && offsetY.sm && offsetY.md
      ? offsetY
      : { lg: '0px', sm: '0px', md: '0px' };

  scale = scale || { lg: '1', sm: '1', md: '1' };

  fixedSettings.fit = fixedSettings.fit || { lg: 'auto', sm: 'auto', md: 'auto' };

  return { fit, offsetX, offsetY, scale, fixedSettings, ...rest };
};
*/

const useStyles = makeStyles(styles);
const ImageBox = props => {
  const [hasMoreWidthThanHeight, setHasMoreWidthThanHeight] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  const img = useRef();
  // const removeNullProps = nullChecks(props);
  // console.log('props: ', props);
  // console.log('props: ', props);
  const {
    boxStyle,
    imageStyle,
    backgroundPosition,
    scale = { lg: '1', md: '1', sm: '1', xl: '1' },
    // source = { lg: null, md: null, sm: null, xl: null },
    offsetX = { lg: '0', md: '0', sm: '0', xl: '0' },
    offsetY = { lg: '0', md: '0', sm: '0', xl: '0' },
    height,
    isBoxStyleGlobal,
    isImageStyleGlobal,
    newSource,
    fixedSettings,
    children,
    imageBorderRadius,
    ...rest
  } = props;
  const screenWidth = useScreenWidth(props);
  // console.log('height: ', height);
  // console.log('imageStyle: ', imageStyle);
  // console.log('boxStyle: ', boxStyle);
  const newProps = {
    ...props,
    source: newSource || null,
    // fit: (fit && fit[screenWidth()]) || fit[screenWidth(1)] || fit[screenWidth(2)] || 0,
    offsetX:
      (offsetX &&
        (offsetX[screenWidth()] || offsetX[screenWidth(1)] || offsetX[screenWidth(2)])) ||
      '0',
    offsetY:
      (offsetY &&
        (offsetY[screenWidth()] || offsetY[screenWidth(1)] || offsetY[screenWidth(2)])) ||
      '0',
    scale:
      (scale &&
        (scale[screenWidth()] || scale[screenWidth(1)] || scale[screenWidth(2)])) ||
      '1',
    height:
      (height &&
        (height[screenWidth()] || height[screenWidth(1)] || height[screenWidth(2)])) ||
      null,
    boxStyle:
      (isBoxStyleGlobal && boxStyle) ||
      (boxStyle &&
        (boxStyle[screenWidth()] ||
          boxStyle[screenWidth(1)] ||
          boxStyle[screenWidth(2)])) ||
      {},
    imageStyle:
      (isImageStyleGlobal && imageStyle) ||
      (imageStyle &&
        (imageStyle[screenWidth()] ||
          imageStyle[screenWidth(1)] ||
          imageStyle[screenWidth(2)])) ||
      {},
    fixedSettings:
      (fixedSettings && {
        ...fixedSettings,
        fit:
          fixedSettings?.fit[screenWidth()] ||
          fixedSettings?.fit[screenWidth(1)] ||
          fixedSettings?.fit[screenWidth(2)],
      }) ||
      null,
    hasMoreWidthThanHeight,
  };
  // console.log('newProps: ', newProps);
  const classes = useStyles({
    ...newProps,
    hasMoreWidthThanHeight,
    imageBorderRadius,
    backgroundPosition,
  });

  const onLoad = useCallback(() => {
    if (img.current.naturalHeight < img.current.naturalWidth) {
      setHasMoreWidthThanHeight(true);
    } else {
      setHasMoreWidthThanHeight(false);
    }
    setHasLoaded(true);
  }, [img, setHasLoaded, setHasMoreWidthThanHeight]);

  const getDeviceSource = source => {
    const finalSource = {};
    if (newSource?.url) {
      finalSource.xl = source;
      finalSource.lg = source;
    }
    if (source?.formats?.large) {
      finalSource.md = source?.formats?.large;
    }
    if (source?.formats?.medium) {
      finalSource.sm = source?.formats?.medium;
    }
    if (source?.formats?.small) {
      finalSource.xs = source?.formats?.small;
    }
    return (
      finalSource &&
      (finalSource[screenWidth()] ||
        finalSource[screenWidth(1)] ||
        finalSource[screenWidth(2)])
    );
  };

  let imgUrl = (newProps?.source?.url && getDeviceSource(newProps?.source))?.url;

  if (!imgUrl) {
    newProps.imageStyle = {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      overflow: 'hidden',
    };
    newProps.boxStyle = {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      overflow: 'hidden',
    };
    imgUrl = defaultImage;
  }
  // console.log('imgUrl: ', imgUrl);
  return (
    <Box className={`${classnames(classes.boxContainer)} customBoxContainer`} style={newProps?.boxStyle}>
      <NoSsr>
        <img
          style={{
            display: hasLoaded ? 'block' : 'none',
            ...newProps?.imageStyle,
            // borderRadius: roundedBorder ? theme.shape.borderRadius : 0,
          }}
          ref={img}
          className={classes.image}
          onLoad={onLoad}
          src={sanitizeUrl(imgUrl)}
          alt="featured"
        />
      </NoSsr>
      {children}
    </Box>
  );
};

export default ImageBox;
