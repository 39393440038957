import { crmPostCall } from '../../../axios';
import { helpCenterApi } from '../../../../utils/functions/helpCenterApis';
import { GETSINGLEHELPCENTERCASEURL, SERVERERROR } from '../../constants';

export const getSingleHelpCenterCaseUrl = helpCenterApi(GETSINGLEHELPCENTERCASEURL);

const getSingleHelpCenterCase = (state, body) => {
  const url = getSingleHelpCenterCaseUrl;

  if (url) {
    return crmPostCall(state, url, body);
  }
  return Promise.reject(new Error(SERVERERROR));
};

export default { getSingleHelpCenterCase };
