import { portfolioButtonStyles } from '../../../styles';

const styles = theme => ({
  ...portfolioButtonStyles(theme),
  root: {
    padding: theme.spacing(2),
    height: '100%',
  },
});

export default styles;
