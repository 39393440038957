import { SIGNUP_BIO_SUCCESS } from '../../../constants/authActionNames';

function signUpBioSuccess(user) {
  return {
    type: SIGNUP_BIO_SUCCESS,
    payload: user,
  };
}

export function signUpBioAction(data) {
  return signUpBioSuccess(data);
}

export default {
  signUpBioAction,
};
