import {
  GET_HEALTH_CARD_API_REQUEST,
  GET_HEALTH_CARD_API_SUCCESS,
  GET_HEALTH_CARD_API_ERROR,
  GET_REMOTE_CONSULTATION_DETAILS_REQUEST,
  GET_REMOTE_CONSULTATION_DETAILS_SUCCESS,
  GET_REMOTE_CONSULTATION_DETAILS_ERROR,
  GET_REQUEST_REMOTE_CONSULTATION_REQUEST,
  GET_REQUEST_REMOTE_CONSULTATION_SUCCESS,
  GET_REQUEST_REMOTE_CONSULTATION_ERROR,
  POST_HEALTH_CARD_EMAIL_REQUEST,
  POST_HEALTH_CARD_EMAIL_SUCCESS,
  POST_HEALTH_CARD_EMAIL_ERROR,
  RESET_HEALTH_CARD_EMAIL_SUCCESS,
  RESET_REMOTE_CONSULTATION_SUCCESS,
  UPDATE_REMOTE_CONSULTATION_CODE_TIME_LEFT,
} from '../../constants/healthCardActionNames';
import { getExpirationTime } from '../../utils/functions/misc';
import { statusOk } from '../../utils/variables';

export const initialState = {
  isPending: false,
  isError: false,
  isSuccess: false,
  response: null,
  error: null,
};

function healthCardReducer(state = initialState, action) {
  switch (action.type) {
    case GET_HEALTH_CARD_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };

    case GET_HEALTH_CARD_API_SUCCESS: {
      const response = action?.payload;
      console.log('response: ', response);
      const isError = response?.responseType !== statusOk;
      const userData = response?.payload;
      response.payload = null;

      return {
        ...state,
        isPending: false,
        isError,
        isSuccess: true,
        userData,
      };
    }

    case GET_HEALTH_CARD_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
      };

    case GET_REMOTE_CONSULTATION_DETAILS_REQUEST:
      return {
        ...state,
        isRemoteConsultationDataPending: true,
        isRemoteConsultationGetError: false,
        isRemoteConsultationGetSuccess: false,
      };

    case GET_REMOTE_CONSULTATION_DETAILS_SUCCESS: {
      const response = action?.payload;
      let remoteConsultationData = response.payload || [];
      response.payload = null;
      remoteConsultationData = remoteConsultationData?.map(element => {
        return {
          ...element,
          timeLeft: getExpirationTime(element.dateExpired),
        };
      });
      return {
        ...state,
        isRemoteConsultationDataPending: false,
        isRemoteConsultationGetError: false,
        isRemoteConsultationGetSuccess: true,
        remoteConsultationData,
      };
    }
    case UPDATE_REMOTE_CONSULTATION_CODE_TIME_LEFT: {
      let { remoteConsultationData } = state;
      remoteConsultationData = remoteConsultationData?.reduce((result = [], element) => {
        const a = {
          ...element,
          timeLeft: getExpirationTime(element.dateExpired),
        };
        if (a.timeLeft) {
          result.push(a);
        }
        return result;
      }, []);
      return {
        ...state,
        remoteConsultationData,
      };
    }

    case GET_REMOTE_CONSULTATION_DETAILS_ERROR:
      return {
        ...state,
        isRemoteConsultationDataPending: false,
        isRemoteConsultationGetError: true,
        isRemoteConsultationGetSuccess: false,
      };

    case GET_REQUEST_REMOTE_CONSULTATION_REQUEST:
      return {
        ...state,
        isRemoteConsultationPostPending: true,
        isRemoteConsultationPostError: false,
        isRemoteConsultationPostSuccess: false,
      };

    case GET_REQUEST_REMOTE_CONSULTATION_SUCCESS: {
      const response = action.payload;
      let { remoteConsultationData } = state;
      if (remoteConsultationData && remoteConsultationData.length >= 0) {
        remoteConsultationData.push(response.payload);
      } else {
        remoteConsultationData = [response.payload];
      }
      return {
        ...state,
        isRemoteConsultationPostPending: false,
        isRemoteConsultationPostError: false,
        isRemoteConsultationPostSuccess: true,
        remoteConsultationData,
      };
    }

    case GET_REQUEST_REMOTE_CONSULTATION_ERROR:
      return {
        ...state,
        isRemoteConsultationPostPending: false,
        isRemoteConsultationPostError: true,
        isRemoteConsultationPostSuccess: false,
      };

    case RESET_REMOTE_CONSULTATION_SUCCESS:
      return {
        ...state,
        isRemoteConsultationPostPending: true,
        isHealthCardEmailSuccess: false,
        remoteConsultationData: [],
      };

    case POST_HEALTH_CARD_EMAIL_REQUEST:
      return {
        ...state,
        isHealthCardEmailPending: true,
        isHealthCardEmailError: false,
        isHealthCardEmailSuccess: false,
      };

    case POST_HEALTH_CARD_EMAIL_SUCCESS: {
      const response = action?.payload;
      const isError = response?.responseType !== statusOk;
      return {
        ...state,
        isHealthCardEmailPending: false,
        isHealthCardEmailError: isError,
        isHealthCardEmailSuccess: true,
      };
    }

    case POST_HEALTH_CARD_EMAIL_ERROR:
      return {
        ...state,
        isHealthCardEmailPending: false,
        isHealthCardEmailError: true,
        isHealthCardEmailSuccess: false,
      };
    case RESET_HEALTH_CARD_EMAIL_SUCCESS:
      return {
        ...state,
        isHealthCardEmailSuccess: false,
      };
    default:
      return state;
  }
}

export default healthCardReducer;
