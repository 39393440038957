import {
  UPDATE_PENDING_REQUESTS_CURRENT_CACHE_KEY,
  GET_PENDING_REQUESTS_API_REQUEST,
  GET_PENDING_REQUESTS_API_SUCCESS,
  GET_PENDING_REQUESTS_API_ERROR,
  GET_NEXT_PENDING_REQUESTS_SUCCESS,
} from '../../../constants/pendingRequestActionNames';
import { checkCacheValid, generateCacheKey } from '../../../utils/functions/cache';
import { getPendingRequestsUrl } from '../../../apis/pendingRequests/getPendingRequestsApi';
import { getPendingRequestsApi } from '../../../apis/pendingRequests';

function updateCacheKey(cacheKey) {
  return {
    type: UPDATE_PENDING_REQUESTS_CURRENT_CACHE_KEY,
    payload: cacheKey,
  };
}

function getPendingRequestsRequest(body) {
  return {
    type: GET_PENDING_REQUESTS_API_REQUEST,
    payload: true,
    body,
  };
}

function getPendingRequestsSuccess(pendingRequests, params, body, cacheKey, lobTypes) {
  return {
    type: GET_PENDING_REQUESTS_API_SUCCESS,
    payload: pendingRequests,
    params,
    cacheKey,
    lobTypes,
    body,
  };
}

function getPendingRequestsError(err, body) {
  return {
    type: GET_PENDING_REQUESTS_API_ERROR,
    payload: err,
    body,
  };
}

export function getPendingRequestsAction(params = {}, body) {
  // const response = getPayloadResponse();
  return (dispatch, getState) => {
    const state = getState();
    const alpha2 = state?.locations?.yourLocation?.alpha2;
    params.alpha2 = alpha2;
    const cacheKey = generateCacheKey({
      path: getPendingRequestsUrl,
      cacheParams: params,
      state,
    });
    const lobTypes = state?.locations?.lobCategories;
    const { isCacheValid, cache } = checkCacheValid(
      getState,
      'pendingRequests',
      cacheKey,
    );
    if (isCacheValid) {
      // dispatch(getClaimsSuccess(null, params, cacheKey, filteredLobCategories));
      dispatch(updateCacheKey(cacheKey));
      dispatch(getPendingRequestsSuccess(cache, params, body, cacheKey, lobTypes));
      return Promise.resolve(cache);
    }
    dispatch(getPendingRequestsRequest(body));
    return getPendingRequestsApi
      .getPendingRequests(state, params)
      .then(data => {
        dispatch(getPendingRequestsSuccess(data, params, body, cacheKey, lobTypes));
        return data;
      })
      .catch(error => {
        dispatch(getPendingRequestsError(error, body));
        throw error;
        // return response;
      });
  };
}

function getNextPendingRequestsSuccess(prod) {
  return {
    type: GET_NEXT_PENDING_REQUESTS_SUCCESS,
    payload: prod,
  };
}

export function getNextPendingRequestsAction(data) {
  return getNextPendingRequestsSuccess(data);
}

export default { getPendingRequestsAction, getNextPendingRequestsAction };
