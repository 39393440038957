import { gql } from 'graphql-request';

const CONDITION_FIELDS = require('../../condition').default;

const DATE_INPUT_FIELDS = gql`
  fragment dateInput on ComponentWizardComponentsDateInput {
    DateInput_slug: slug
    DateInput_name: name
    DateInput_label: label
    DateInput_labelAlign: labelAlign
    DateInput_inputVariant: inputVariant
    DateInput_layoutPosition: layoutPosition
    DateInput_staticValue: staticValue
    DateInput_reducerKeyValue: reducerKeyValue
    DateInput_positionNo: positionNo
    DateInput_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    DateInput_fullWidth: fullWidth
    DateInput_required: required
    DateInput_tooltip: tooltip
    DateInput_isConditionalComponent: isConditionalComponent
    DateInput_conditionalValue: conditionalValue
    DateInput_conditionalKey: conditionalKey
    DateInput_conditionalOperator: conditionalOperator
    DateInput_conditionalFieldType: conditionalFieldType
    DateInput_conditionalAction: conditionalAction
    DateInput_disable: disable
    DateInput_show: show
    DateInput_disableInitialValue: disableInitialValue
    DateInput_placeholder: placeholder
    DateInput_label_en: label_en
    DateInput_label_nl: label_nl
    DateInput_tooltip_en: tooltip_en
    DateInput_tooltip_nl: tooltip_nl
    DateInput_placeholder_en: placeholder_en
    DateInput_placeholder_nl: placeholder_nl
    DateInput_conditions: conditions {
      ...condition
    }
    DateInput_openTo: openTo
    DateInput_variant: variant
    DateInput_dateFormat: dateFormat
    DateInput_views: views
    DateInput_isMinDateToday: isMinDateToday
    DateInput_isMaxDateToday: isMaxDateToday
    DateInput_minDate: minDate
    DateInput_maxDate: maxDate
    DateInput_excludeCurrentDate: excludeCurrentDate
  }
`;

// export default DATE_INPUT_FIELDS;
// exports.default = DATE_INPUT_FIELDS;
export default DATE_INPUT_FIELDS;
