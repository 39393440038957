import {
  SEARCH_CALL_WIZARD_API_REQUEST,
  SEARCH_CALL_WIZARD_API_SUCCESS,
  SEARCH_CALL_WIZARD_API_ERROR,
} from '../../../constants/wizardActionNames';
// import { api } from '../../../utils/variables';
import { searchCallWizardApi } from '../../../apis/wizards';

function searchCallWizardError(err, savedKey) {
  return {
    type: SEARCH_CALL_WIZARD_API_ERROR,
    payload: err,
    savedKey,
  };
}

function searchCallWizardRequest(savedKey, disablePending) {
  return {
    type: SEARCH_CALL_WIZARD_API_REQUEST,
    payload: !disablePending,
    savedKey,
  };
}
function searchCallWizardSuccess(prod, savedKey, filterSettings, params) {
  return {
    type: SEARCH_CALL_WIZARD_API_SUCCESS,
    payload: prod,
    savedKey,
    filterSettings,
    params,
  };
}

export function searchCallWizardAction(
  url,
  params,
  httpMethod,
  savedKey,
  disablePending,
  filterSettings,
) {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(searchCallWizardRequest(savedKey, disablePending));
    return searchCallWizardApi
      .searchCallWizard(state, url, params, httpMethod)
      .then(data => {
        // console.log('data11: ', data);
        // Update the app state with the results of the API call.

        dispatch(searchCallWizardSuccess(data, savedKey, filterSettings, params));
        return data;
      })
      .catch(error => {
        dispatch(searchCallWizardError(error, savedKey));
        throw error;
      });
  };
}

export default {
  searchCallWizardAction,
};
