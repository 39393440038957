import {
  DELETE_DIGITAL_SALES_APPLICATION_API_REQUEST,
  DELETE_DIGITAL_SALES_APPLICATION_API_SUCCESS,
  DELETE_DIGITAL_SALES_APPLICATION_API_ERROR,
} from '../../../constants/digitalSalesActionNames';

import { deleteDigitalSalesApplicationApi } from '../../../apis/digitalSales';

function deleteDigitalSalesApplicationRequest() {
  return {
    type: DELETE_DIGITAL_SALES_APPLICATION_API_REQUEST,
    payload: true,
  };
}
function deleteDigitalSalesApplicationSuccess(digitalSales) {
  return {
    type: DELETE_DIGITAL_SALES_APPLICATION_API_SUCCESS,
    payload: digitalSales,
  };
}

function deleteDigitalSalesApplicationError(err) {
  return {
    type: DELETE_DIGITAL_SALES_APPLICATION_API_ERROR,
    payload: err,
  };
}

export function deleteDigitalSalesApplicationAction(params) {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(deleteDigitalSalesApplicationRequest());
    return deleteDigitalSalesApplicationApi
      .deleteDigitalSalesApplication(state, params)
      .then(data => {
        dispatch(deleteDigitalSalesApplicationSuccess(data));
        return data;
      })
      .catch(error => {
        dispatch(deleteDigitalSalesApplicationError(error));
        throw error;
      });
  };
}
export default {
  deleteDigitalSalesApplicationAction,
};
