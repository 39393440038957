import { UPDATE_SOURCE_ID_SUCCESS } from '../../../constants/authActionNames';

function updateSourceIdSuccess(prod) {
  return {
    type: UPDATE_SOURCE_ID_SUCCESS,
    payload: prod,
  };
}

export function updateSourceIdAction(data) {
  return updateSourceIdSuccess(data);
}

export default { updateSourceIdAction };
