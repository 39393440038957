import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from './style';

const ContentBox = ({ open, handleClose, content, title, isHtmlContent }) => {
  const useStyles = makeStyles(theme => styles(theme));
  const classes = useStyles();
  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">{title}</DialogTitle>
        <DialogContent className={classes.dialogPaper}>
          <DialogContentText id="dialog-description">
            {isHtmlContent ? (
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>{content}</ReactMarkdown>
            ) : (
              content
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ContentBox;
