import { routerGetCall } from '../../axios';

const getYourAppSetting = (state, params) => {
  const url = `/cms/app-config`;
  return routerGetCall(state, url, params);
};
export default { getYourAppSetting };

// https://c360-core.azure-api.net/cms/getUserLocation
// https://c360-core.azure-api.net/cms/locale
