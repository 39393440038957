import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTheme } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSmall } from '../../../../hooks';
import { addHelpFormDataAction } from '../../../../actions/helpCenter/helpFormData';
import CheckStatusComp from '../../../../containersV2/helpCenter/checkStatus/statusDetailsJM/CheckStatusComp';

function StatusListHeader(props) {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const user = useSelector(state => state.auth.user);
  const history = useHistory();
  const isSmall = useSmall();
  const [caseId, setCaseId] = useState('');
  const [isCaseIdLogin, setIsCaseIdLogin] = useState(false);
  const [checkStatusButton, setCheckStatusButton] = useState(false);
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  const [fullName, setFullName] = useState('');
  const territory = useSelector(state => state?.locations?.yourLocation?.alpha3);
  const navToLogin = () => {
    setCaseId('');
    history.push('/login');
  };

  const navToHelpRequest = () => {
    history.push('/help/request-help');
  };

  const dropdownYears = () => {
    const years = [];
    for (let i = 2020; i <= currentYear; i++) {
      years.push(i);
    }
    return years;
  };

  const handleChange = event => {
    setYear(event.target.value);
    props.caseYear(event.target.value);
  };

  const getCase = () => {
    props.issueCaseId(caseId);
    dispatch(addHelpFormDataAction({ fullName }));
    setIsCaseIdLogin(true);
  };

  useEffect(() => {
    if (caseId != '' && fullName != '') {
      setCheckStatusButton(true);
    } else {
      setCheckStatusButton(false);
    }
  });
  const theme = useTheme();
  return (
    <>
      <Stack>
        <Stack sx={{ my: 1, overflow: 'hidden' }}>
          {!isAuthenticated && !isCaseIdLogin ? (
            territory !== 'JAM' ? (
              <Stack
                alignItems={isSmall ? 'center' : 'flex-start'}
                sx={{
                  backgroundColor: 'background.default',
                  p: 2,
                  borderRadius: '0.625rem',
                  mt: 2,
                  width: '100%',
                }}
              >
                <Typography
                  textAlign={isSmall ? 'center' : 'start'}
                  color="text.label"
                  sx={{ mb: 2 }}
                >
                  To check your status or view support history,
                  <br /> Please log in, or enter your Case ID.
                </Typography>
                <Stack
                  direction={isSmall ? 'column' : 'row'}
                  alignItems="center"
                  sx={{ width: isSmall ? '100%' : 'initial' }}
                >
                  <Stack
                    sx={{
                      width: '100%',
                      marginLeft: isSmall ? '30%' : 'initial',
                      marginBottom: isSmall ? '0.938rem' : 'initial',
                    }}
                  >
                    <Button
                      sx={{
                        width: isSmall ? '70%' : '7.5rem',
                        height: '2.813rem',
                        maxWidth: '18.75rem',
                        backgroundColor: 'primary.main',
                      }}
                      variant="contained"
                      onClick={navToLogin}
                      size="small"
                    >
                      Go to Login
                    </Button>
                  </Stack>
                  <Stack
                    direction={isSmall ? 'column' : 'row'}
                    alignItems="center"
                    sx={{ width: '100%' }}
                  >
                    <Typography
                      color="text.label"
                      sx={{ margin: isSmall ? '0.938rem' : '0 0.938rem' }}
                    >
                      Or
                    </Typography>
                    <TextField
                      sx={{ width: '2.5rem', marginTop: '0.625rem' }}
                      value={caseId}
                      onChange={event => {
                        setCaseId(event.target.value);
                      }}
                      placeholder="Enter Case ID"
                    />
                    <Typography
                      color="text.label"
                      sx={{
                        margin: '0 0.938rem',
                        visibility: isSmall ? 'hidden' : 'initial',
                      }}
                    >
                      +
                    </Typography>
                    {territory === 'TTO' ? (
                      <TextField
                        sx={{ width: '0px', marginTop: isSmall ? '0px' : '10px' }}
                        value={fullName}
                        onChange={event => {
                          setFullName(event.target.value);
                        }}
                        placeholder="Full Name"
                      />
                    ) : (
                      <TextField
                        sx={{ width: '0', marginTop: isSmall ? '0' : '0.625rem' }}
                        placeholder="Name"
                      />
                    )}
                    <Stack
                      sx={{
                        width: '100%',
                        marginTop: isSmall ? '0.938rem' : 'initial',
                        marginLeft: isSmall ? '30%' : 'initial',
                      }}
                    >
                      <Button
                        sx={{
                          width: isSmall ? '70%' : '7.5rem',
                          height: '2.813rem',
                          maxWidth: '18.75rem',
                          marginLeft: isSmall ? '0' : '1.875rem',
                          background: !checkStatusButton ? '#DADADA' : 'primary',
                          color: !checkStatusButton ? 'text.disabled' : 'white',
                          pointerEvents: !checkStatusButton ? 'none' : 'initial',
                        }}
                        variant="contained"
                        size="small"
                        onClick={getCase}
                      >
                        Check Status
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            ) : (
              <CheckStatusComp territory={territory} />
            )
          ) : (
            <Stack sx={{ width: '100%' }}>
              <>
                <Stack
                  direction={isSmall ? 'column' : 'row'}
                  alignItems={isSmall ? 'initial' : 'center'}
                  marginLeft={isSmall ? '1.875rem' : ''}
                >
                  <Typography
                    color="text.label"
                    textAlign={isSmall ? 'left' : null}
                    sx={{ marginRight: isSmall ? '' : '0.5rem' }}
                  >
                    {isCaseIdLogin ? 'Logged in with Case Id:' : 'Logged in as:'}
                  </Typography>
                  {isSmall ? (
                    <>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ margin: '1.25rem 0' }}
                      >
                        <AccountCircleIcon
                          sx={{ marginRight: '0.375rem', color: 'black' }}
                        />
                        <Typography sx={{ color: 'text.main' }}>
                          {isCaseIdLogin ? caseId : `${user.firstName} ${user.lastName}`}
                        </Typography>
                      </Stack>
                    </>
                  ) : (
                    <>
                      <AccountCircleIcon
                        sx={{ marginRight: '0.375rem', color: 'black' }}
                      />
                      <Typography sx={{ color: 'text.main' }}>
                        {isCaseIdLogin ? caseId : `${user.firstName} ${user.lastName}`}
                      </Typography>
                    </>
                  )}
                </Stack>
              </>

              <Stack
                direction={isSmall ? 'column-reverse' : 'row'}
                alignItems="center"
                justifyContent="space-between"
                sx={{ margin: '2.5rem 0' }}
              >
                {isCaseIdLogin ? (
                  <></>
                ) : (
                  <>
                    <Stack
                      sx={{
                        width: isSmall ? '100%' : '8.125rem',
                        padding: isSmall ? '1.25rem' : '',
                        maxWidth: '25rem',
                      }}
                    >
                      <Select
                        labelId="select-year"
                        id="select-year"
                        value={year}
                        label="Year"
                        onChange={handleChange}
                        sx={{
                          textAlign: isSmall ? 'left' : '',
                          color: 'text.disabled',
                          legend: {
                            display: 'none',
                          },
                        }}
                      >
                        {dropdownYears().map(year => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                    </Stack>
                  </>
                )}
                <Stack
                  sx={{
                    marginBottom: '0.625rem',
                    width: isSmall ? '100%' : 'initial',
                    padding: isSmall ? '1.25rem' : '',
                    maxWidth: isSmall ? '25rem' : 'initial',
                  }}
                >
                  <Button
                    onClick={navToHelpRequest}
                    sx={{ height: '3.125rem' }}
                    variant="contained"
                  >
                    Open New Help Request
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
}

export default StatusListHeader;
