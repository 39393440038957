import { RESET_RENEWAL_ORDER_SUCCESS } from '../../../constants/miscActionNames';

function resetRenewalOrderSuccess(prod) {
  return {
    type: RESET_RENEWAL_ORDER_SUCCESS,
    payload: prod,
  };
}

export function resetRenewalOrderAction(data) {
  return resetRenewalOrderSuccess(data);
}

export default {
  resetRenewalOrderAction,
};
