import { routerGetCall } from '../../axios';

export const getClaimsUrl = `/account-services/claimsSummary`;
export const getAgentHealthClaimsUrl = `/account-services/batch-claims`;
const getClaims = (state, params, usertype = null) => {
  let url = ''
  if(usertype === 'agent' && params?.policyType?.includes('health')){
    url = getAgentHealthClaimsUrl;
  } else {
    url = getClaimsUrl;
  }
  return routerGetCall(state, url, params);
};
export default { getClaims };
