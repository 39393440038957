export function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

export function round(value, decimals) {
  return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
}

export function isEven(n) {
  return n % 2 === 0;
}

export function isOdd(n) {
  return Math.abs(n % 2) === 1;
}
