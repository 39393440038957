import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Button,
  Icon,
  DialogContent,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  useMediaQuery,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import CookiePolicyModal from './cookiePolicyModal';
import CookieCategoryCard from './cookieCategoryCard';
import { uuidv4, keySort } from '../../../utils/functions';
import { useSmall } from '../../../hooks';

const useStyles = makeStyles(theme => ({
  appBar: {
    // position: 'relative',
    backgroundColor: theme.palette.background.default,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  container: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
  },
  title: {
    fontWeight: 'bold',
  },
  text: {
    textTransform: 'capitalize',
  },
  button: {
    padding: theme.spacing(2),
  },
  actionButtons: {
    // padding: theme.spacing(2),
  },
  dialogContent: {
    position: 'relative',
    backgroundColor: theme.palette.background.default,
  },
  fullWidth: {
    width: '100%',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CookieSettingsModal = ({
  setModalOpen,
  cookieSetting,
  findMethod,
  disabled,
  ...restProps
}) => {
  const classes = useStyles();
  const isSmall = useSmall();
  const {
    title,
    cookieCategories: initialCookieCategories,
    description,
    showCookiePolicy,
    cookieActions,
    cookieSettingActionColumns,
  } = cookieSetting;

  const cookieCategories = keySort(initialCookieCategories, 'positionNo');

  const [isChecked, setIsChecked] = React.useState(
    cookieCategories.slice().map(x => x?.defaultChecked),
  );

  const toggleCheckboxValue = index => {
    setIsChecked(isChecked.map((v, i) => (i === index ? !v : v)));
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const displayActionButtons = cookieActions?.map(action => {
    const {
      title: buttonTitle,
      hidden = false,
      color,
      variant,
      type,
      size,
      startIcon,
      endIcon,
      fullWidth,
    } = action;
    if (hidden) return null;

    return (
      <Grid item md={12 / cookieSettingActionColumns} className={classes.fullWidth}>
        <Button
          key={uuidv4()}
          color={color || 'inherit'}
          size={size || 'large'}
          variant={variant || 'contained'}
          disabled={disabled}
          onClick={() => {
            const method = findMethod(type);
            // console.log('type: ', type);
            // console.log('isChecked: ', isChecked);
            let keys = cookieCategories.map((category, index) =>
              isChecked[index] ? category.slug : null,
            );
            if (keys && keys.length > 0) {
              keys = keys.filter(val => {
                return val !== null;
              });
            }
            // console.log('keys: ', keys);

            method(keys);
          }}
          className={classes.button}
          startIcon={startIcon && <Icon data-nosnippet>{startIcon}</Icon>}
          endIcon={endIcon && <Icon data-nosnippet>{endIcon}</Icon>}
          fullWidth={fullWidth}
        >
          <Typography variant="body1" className={classes.secondaryButton}>
            {buttonTitle}
          </Typography>
        </Button>
      </Grid>
    );
  });

  return (
    <div>
      <Dialog
        fullScreen={isSmall}
        open
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogContent classes={{ root: classes.dialogContent }}>
          <AppBar
            color="default"
            className={classes.appBar}
            elevation={0}
            position="absolute"
          >
            <Toolbar classes={{ root: classes.toolbar }}>
              <Typography />
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Grid container spacing={2} className={classes.container}>
            <Grid item md={12} className={classes.fullWidth}>
              <Typography color="textPrimary" variant="h5" className={classes.title}>
                {title}
              </Typography>
            </Grid>
            <Grid item md={12} className={classes.fullWidth}>
              <Typography display="inline" color="default" variant="subtitle2">
                {description}
              </Typography>{' '}
              {showCookiePolicy && <CookiePolicyModal {...restProps} />}
            </Grid>
            {cookieCategories && (
              <Grid item md={12}>
                <Grid container spacing={2}>
                  {cookieCategories?.map((cookieCategory, index) => (
                    <Grid key={uuidv4()} item md={12} className={classes.fullWidth}>
                      <CookieCategoryCard
                        {...cookieCategory}
                        toggleCheckboxValue={() => toggleCheckboxValue(index)}
                        isChecked={isChecked[index]}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
            {isSmall && (
              <Grid item md={12}>
                <br />
              </Grid>
            )}
            {displayActionButtons && (
              <Grid item md={12} className={classes.fullWidth}>
                <Grid container spacing={1} justifyContent="center" alignItems="center">
                  {displayActionButtons}
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CookieSettingsModal;
