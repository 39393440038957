const styles = theme => ({
  brandName: {
    color: 'white',
    paddingLeft: theme.spacing(4),
  },
  welcomeText: {
    color: 'silver',
    paddingTop: '40vh',
    paddingLeft: theme.spacing(4),
  },
});

export default styles;
