import {
  GET_NEW_ACCESS_TOKEN_API_REQUEST,
  GET_NEW_ACCESS_TOKEN_API_ERROR,
  GET_NEW_ACCESS_TOKEN_API_SUCCESS,
} from '../../../constants/authActionNames';

// import { api } from '../../../utils/variables';
import { authValidationApi } from '../../../apis/auth';

function getNewAccessTokenError(err) {
  return {
    type: GET_NEW_ACCESS_TOKEN_API_ERROR,
    payload: err,
  };
}

function getNewAccessTokenRequest() {
  return {
    type: GET_NEW_ACCESS_TOKEN_API_REQUEST,
    payload: true,
  };
}

function getNewAccessTokenSuccess(user) {
  return {
    type: GET_NEW_ACCESS_TOKEN_API_SUCCESS,
    payload: user,
  };
}

export function getNewAccessTokenAction(_credentials) {
  const credentials = { ..._credentials };
  // credentials.apiKey = api.apiKey;
  // credentials.apiSecret = api.apiSecret;

  return (dispatch, getState) => {
    const state = getState();
    dispatch(getNewAccessTokenRequest());
    return authValidationApi
      .getNewAccessToken(state, credentials)
      .then(data => {
        dispatch(getNewAccessTokenSuccess(data));
        return data;
      })
      .catch(error => {
        dispatch(getNewAccessTokenError(error));
        throw error;
      });
  };
}

export default {
  getNewAccessTokenAction,
};
