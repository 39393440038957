import {
  GET_SINGLE_HELP_CENTER_CASE_API_ERROR,
  GET_SINGLE_HELP_CENTER_CASE_API_REQUEST,
  GET_SINGLE_HELP_CENTER_CASE_API_SUCCESS,
} from '../../../../constants/helpCenter/getAllHelpCenterCases';
import { getSingleHelpCenterCaseApi } from '../../../../apis/helpCenter';

function getSingleHelpCenterCaseError(err) {
  return {
    type: GET_SINGLE_HELP_CENTER_CASE_API_ERROR,
    payload: err,
  };
}

function getSingleHelpCenterCaseRequest() {
  return {
    type: GET_SINGLE_HELP_CENTER_CASE_API_REQUEST,
    payload: true,
  };
}

function getSingleHelpCenterCaseSuccess(prams) {
  return {
    type: GET_SINGLE_HELP_CENTER_CASE_API_SUCCESS,
    payload: prams,
  };
}

export function getSingleHelpCenterCaseAction(params) {
  return (dispatch, getState) => {
    const state = getState();
    const language = state?.locations?.language || 'en';
    dispatch(getSingleHelpCenterCaseRequest());

    const { getSingleHelpCenterCase } = getSingleHelpCenterCaseApi;

    return getSingleHelpCenterCase(state, params)
      .then(data => {
        dispatch(getSingleHelpCenterCaseSuccess(data));
        return data;
      })
      .catch(error => {
        dispatch(getSingleHelpCenterCaseError(error));
        throw error;
      });
  };
}

export default {
  getSingleHelpCenterCaseAction,
};
