import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BackgroundImage, Brand } from '..';
import { useSmall } from '../../../hooks';
import getBackgroundImage from '../../../utils/functions/getBackgroundImage';
import { agentType } from '../../../utils/variables';
import styles from './style';

const useStyles = makeStyles(styles);

const SplashScreen = () => {
  const classes = useStyles();
  const isSmall = useSmall();
  const yourLocation = useSelector(state => state?.locations?.yourLocation);
  const usertype = useSelector(state => state.auth.usertype);

  const backgroundImage = getBackgroundImage({ yourLocation, slug: 'trigger-signin' });

  let coverImage = null;
  if (usertype === agentType) {
    coverImage = backgroundImage?.pwaAgentCoverImage;
  } else {
    coverImage = backgroundImage?.pwaUserCoverImage;
  }
  const { newSource } = coverImage || {};

  return (
    <div className={classes.root}>
      <div
        style={{
          position: 'relative',
          height: '20vh',
          minWidth: '30vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={
            isSmall
              ? {
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  padding: 10,
                  paddingRight: 20,
                  paddingLeft: 20,
                  borderRadius: 5,
                  textAlign: 'center',
                }
              : {
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  padding: 10,
                  paddingRight: 20,
                  paddingLeft: 20,
                  borderRadius: 5,
                  textAlign: 'center',
                }
          }
        />

        <Grid item xs={12} align="center">
          <Box sx={{ width: '100%' }}>
            <Brand height="100rem" />
            <LinearProgress />
          </Box>
        </Grid>
        {false && !isSmall && newSource && (
          <BackgroundImage imageUrl={newSource?.url} bottom={0} />
        )}
      </div>
    </div>
  );
};

export default SplashScreen;
