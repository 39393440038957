import React from 'react';
import {
  MenuItem,
  Typography,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Stack,
  Link,
  FormHelperText,
  FormControl,
} from '@mui/material';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { useTheme } from '@mui/material/styles';
import { Spinner } from '../../common';

export const DropdownSelect = ({
  name,
  label,
  handleChange,
  category,
  message,
  check,
  multiple,
  value,
  styles,
  width,
  error,
  background,
  borderRadius,
  required,
  disable,
  helperText,
  footNote,
  urlData,
  defaultValue,
  ...props
}) => {
  const theme = useTheme();

  const getUrl = (urlData, name) => {
    return urlData?.find(data => {
      if (data.name === name) {
        return data.url;
      }
    })?.url;
  };

  return (
    <Stack {...props}>
      <FormControl error={error} fullWidth>
        {label && (
          <Typography
            sx={{
              color: 'text.label',
              fontSize: '1rem',
              fontWeight: '500',
              mb: '0.5rem',
              ...styles,
            }}
          >
            {label}
            {required && ' *'}
          </Typography>
        )}
        <Select
          displayEmpty
          labelId={name}
          name={name}
          multiple={multiple}
          value={value}
          defaultValue={defaultValue}
          onChange={handleChange}
          disabled={disable}
          input={<OutlinedInput />}
          renderValue={selected => {
            if (selected?.length === 0) {
              return (
                <Typography align="left" sx={{ color: 'text.disabled', margin: 0 }}>
                  {message.padStart(message?.length + 1, ' ')}
                </Typography>
              );
            }

            return selected;
          }}
          MenuProps={{
            sx: {
              '& li.Mui-selected': {
                background: 'background.main',
              },
              '& li.Mui-selected:hover': {
                background: 'background.main',
              },
            },
          }}
          sx={{
            align: 'left',
            padding: '0.75rem 1.25rem',
            background: background || `${theme.palette.background.main}`,
            width: {
              xs: '100%',
              sm: '100%',
              md: width || '100%',
            },
            borderRadius: borderRadius || '0.5rem',
            height: '3rem',
            '&.MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${theme.palette.background.contentBox}`,
              borderRadius: borderRadius || '0.2rem',
            },
            '& .MuiOutlinedInput-input.Mui-disabled': {
              opacity: 1,
              WebkitTextFillColor: 'text.disabled',
            },
          }}
        >
          {category.length == 0 ? (
            <Spinner loading message="Loading options" />
          ) : (
            category.map(option => {
              let name;
              let type;
              if (typeof option == 'string') {
                name = option;
                type = 'string';
              } else {
                name = option?.name;
                type = option?.type;
              }

              return (
                <MenuItem
                  key={name}
                  value={name}
                  sx={{
                    height: '3rem',
                    boxSizing: 'border-box',
                    padding: 0,
                    margin: 0,
                    '&.MuiMenuItem-root': {
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: width || '100%',
                      },
                    },
                    '&.MuiMenuItem-root:hover': {
                      color: 'inherit',
                    },
                    '&.MuiMenuItem-root:focus': {
                      color: 'inherit',
                    },
                  }}
                >
                  {check ? (
                    <>
                      <Checkbox
                        checked={value.indexOf(name) > -1}
                        sx={{
                          color: 'rgba(0, 0, 0, .6)',
                          '&.Mui-checked': {
                            color: 'rgba(0, 0, 0, .6)',
                          },
                        }}
                      />
                      <ListItemText
                        primary={name}
                        sx={{
                          py: 2,
                          px: 2.5,
                        }}
                      />
                    </>
                  ) : type == 'link' ? (
                    <Link
                      underline="none"
                      color="inherit"
                      href={getUrl(urlData, name)}
                      sx={{
                        py: 2,
                        px: 2.5,
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <ListItemText>{name}</ListItemText>
                      <ArrowOutwardIcon />
                    </Link>
                  ) : (
                    <ListItemText
                      primary={name}
                      sx={{
                        py: 2,
                        px: 2.5,
                      }}
                    />
                  )}
                </MenuItem>
              );
            })
          )}
        </Select>
        <FormHelperText className={error ? 'Mui-error' : ''}>
          {helperText || footNote}
        </FormHelperText>
      </FormControl>
    </Stack>
  );
};
