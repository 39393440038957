import React from 'react';

export default function StartStepper({
  backgroundColor,

  children,
  ...restProps
}) {
  return (
    <svg
      viewBox="70 621.959 900 300.041"
      {...restProps}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        id="path1"
        fill={backgroundColor}
        d="M 70.028 621.959 L 70.028 773.127 L 70 920.959 L 800 922 L 970 772.194 L 800 622.4"
      />
    </svg>
  );
}

/*
  textColor,
  textSize,
     <text
        textAnchor="middle"
        alignmentBaseline="central"
        x="100"
        y="25"
        fontSize={textSize}
        fill={textColor}
      >
        <textPath xlinkHref="#path1" startOffset="50%">
          {children}
        </textPath>
      </text>
      */
