const styles = theme => ({
  img: {
    height: theme.spacing(6),
    width: 'auto',
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(6),
      width: 'auto',
    },
  },
});
export default styles;
