import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useRouter from '../../../hooks/useRouter';
import { signOut } from '../../../actions/auth';
import routes from '../../../utils/variables/routes';
import { removeSessionData } from '../../../utils/functions';

const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

const AppLogout = () => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const user = useSelector(state => state?.auth?.user);
  const dispatch = useDispatch();
  const router = useRouter();
  let timer;

  // this function sets the timer that logs out the user after 10 secs
  const handleLogoutTimer = () => {
    resetTimer(); // Reset the timer
    timer = setTimeout(() => {
      // logs out user
      dispatch(signOut.signOutAction()).then(() => {
        removeSessionData();
        router.replace(routes.auth.login);
      });
    }, user?.expiresIn * 1000);
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const startTimer = () => {
    resetTimer(); // Reset the timer on user activity
    handleLogoutTimer(); // Start the timer
  };

  // when component mounts or isAuthenticated changes, it adds or removes event listeners to the window
  useEffect(() => {
    if (isAuthenticated) {
      // Add event listeners
      events.forEach(event => {
        window.addEventListener(event, startTimer);
      });

      // Start the timer initially
      handleLogoutTimer();
    } else {
      // Clear the timer and remove event listeners when not authenticated
      resetTimer();
      events.forEach(event => {
        window.removeEventListener(event, startTimer);
      });
    }

    // Cleanup: remove event listeners when the component unmounts or isAuthenticated changes
    return () => {
      events.forEach(event => {
        window.removeEventListener(event, startTimer);
      });
      resetTimer(); // Clear the timer on unmount or isAuthenticated changes
    };
  }, [isAuthenticated]);

  return <div />;
};

export default AppLogout;
