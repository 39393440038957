export const cardHeader = 'Check out some of Guardian’s other products';

export const InProgressCardTitle =
  ' Your ticket has been submitted and is now In Progress. Help is on the way!';

export const resolutionText =
  'Based on the type of issue request you’ve submitted, standard resolution time is typically:';

export const checkMyStatusBtn = 'Check my Status';

export const checkMyStatusTitle = 'Check the status of your ticket here:';

export const whatToExpectNextText = 'Here is what to expect next:';

export const submitRequestTitle =
  'Please enter your name, date of birth, TRN and policy number';

export const IssueInfoTitle = 'Select Appropriate Issue Category and Information';

export const documentsContent = {
  header: 'Supporting Documents',
  subHeader:
    'To make sure we can help you as quickly with your request, please upload supporting files and documents below. (This will save us the time of having to ask you for them later)',
};

export const contactTitle = 'Contact Information';

export const topicCardHeader = 'Choose from our most popular topics';

export const requestsHeader = 'Requests, Issues and Reporting';

export const contactHeader = 'Contact Us';

export const statusCheckTitle = 'Status Details';

export const statusCheckHeader = 'Review the status of your open ticket';

export const Authenticated_statusCheckDescription = {
  text1: 'To check your status of your open ticket,',
  text2: 'please enter your Case ID and full name',
};
export const UnAuthenticated_statusCheckDescription = {
  text1: 'To check your status or view support history,',
  text2: 'Please log in, or enter your Case ID.',
};
//Buttons Content
export const submitBtnContent = 'Submit';
export const editBtnContent = 'Edit';
export const nextBtnContent = 'Next';
export const submitTicketBtn = 'Submit ticket';
export const goToLogin = 'Go to Login';
export const surveyBtnContent = 'Start Survey';

export const ticketHistoryTitle = 'Ticket History';

export const updateTicketStatusTitle = 'Update Ticket Status:';

//Summary Data
export const summaryData = {
  name: 'Name:',
  dob: 'Date of Birth:',
  trn: 'TRN:',
  policy: 'Policy#:',
};

//Personal Data

export const personalData = {
  title: 'Personal Information:',
  dateOpened: 'Date Opened:',
  name: 'Name:',
  dob: 'Date of Birth:',
  trn: 'TRN:',
  phone: 'Phone:',
  email: 'Email:',
  policy: 'Policy#:',
  notifications: 'Notifications:',
};

export const issueInfoData = {
  title: 'Issue Information:',
  issueCategory: 'Issue Category:',
  issueType: 'Issue Type:',
  issueCloselyRelated: 'Issue Closely Related:',
  bankName: 'Bank Name:',
  policies: 'Policies Impacted:',
  payorName: 'Payor Name:',
  payorRelation: 'Relationship to Payor:',
  branch: 'Branch:',
  agent: 'Agent:',
  assistance: 'Assistance needed:',
  documents: 'Supporting Documents:',
  allDocStatus: '3 Documents submitted',
  noDocStatus: 'No Documents submitted',
  oneDocStatus: '1 Document submitted',
  twoDocStatus: '2 Documents submitted',
};

//Upload Instructions:
export const uploadInstructions = {
  TITLE: 'Upload Instructions:',
  SUPPORTEDFILETYPESINFO:
    'Supported file formats are format .heic, .hevc, .heif, .pdf, .png, .gif, .jpg, .jpeg, application/pdf, text/csv',
  SIZEINFO: 'Size should be no larger than 10 MB',
  FILESNEEDED: 'Files:',
};

export const surveyTitle = 'Let us know how we did by completing this brief survey:';

export const resolvedTicketConstants = {
  helpRequest: 'Help Request',
  policyRelatedIssue: 'Policy related issue',
  reference: 'Reference#',
  policy: 'Policy #',
  policyName: 'Policy name/type',
  dateCreated: 'Date created',
};

export const notGLLCustomer =
  'If you are not a customer of Guardian Life Limited, please submit your questions ';
export const linkText = 'here.';
