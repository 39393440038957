import { routerPostCall } from '../../axios';

const verifyIdentityConfirm = (state, params) => {
  const url = `/auth/verification/confirm`;
  return routerPostCall(state, url, params);
};

const signUpVerifyIdentityConfirm = (state, params) => {
  const url = `/auth/signUpVerification/confirm`;
  return routerPostCall(state, url, params);
};

const forgotVerifyIdentityConfirm = (state, params) => {
  const url = `/auth/verification/forgetPassword/confirm`;
  return routerPostCall(state, url, params);
};

const verifyIdentitySend = (state, params) => {
  const url = `/auth/verification/resendCode`;
  return routerPostCall(state, url, params);
};
const verifyIdentitySendSmS = (state, params) => {
  const url = `/auth/verification/resendCode/sms`;
  return routerPostCall(state, url, params);
};

const forgotVerifyIdentitySend = (state, params) => {
  const url = `/auth/verification/forgetPassword/resendCode`;
  return routerPostCall(state, url, params);
};
export default {
  verifyIdentityConfirm,
  verifyIdentitySend,
  signUpVerifyIdentityConfirm,
  forgotVerifyIdentityConfirm,
  forgotVerifyIdentitySend,
  verifyIdentitySendSmS,
};
