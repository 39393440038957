import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import {
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
  Stack,
  Chip,
  Grid,
  Icon,
  Avatar,
  Link,
  Skeleton,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { getRef } from '../../../utils/functions';
import styles from './style';

const useStyles = makeStyles(styles);

const ActionHeader = ({
  lob,
  icon,
  materialIcon,
  color,
  loading,
  title,
  subTitle,
  actions,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  // const lobCategories = useSelector(state => state.locations.lobCategories);
  // const oneCategory = lobCategories.find(
  //   x => x?.lob === lob, // x?.requestLink && x.requestLink.length > 0 &&
  // );
  // const { materialIcon, color } = oneCategory;

  const iconDisplay = (
    <>
      {loading ? (
        <Skeleton animation="wave" variant="circular" className={classes.avatar} />
      ) : (
        <>
          {materialIcon && (
            <Avatar
              sx={{ bgcolor: getRef(theme.palette, color) }}
              className={classes.avatar}
            >
              <Icon
                className={classes.icon}
                color="white"
                data-nosnippet="data-nosnippet"
              >
                {materialIcon}
              </Icon>
            </Avatar>
          )}
        </>
      )}
    </>
  );

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={classes.root}
    >
      <Grid item md={2} xs={4}>
        {iconDisplay}
      </Grid>
      <Grid item md={5} xs={8} align="left">
        <Typography variant="subtitle2" align="left" component="p">
          <b>
            {loading ? (
              <Skeleton
                animation="wave"
                height={10}
                width="90%"
                style={{ marginBottom: 6 }}
              />
            ) : (
              title
            )}
          </b>
        </Typography>
        <Typography variant="subtitle2" align="left" component="p">
          {loading ? (
            <Skeleton
              animation="wave"
              height={10}
              width="90%"
              style={{ marginBottom: 6 }}
            />
          ) : (
            subTitle
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} md={5} sx={{ p: 1 }}>
        {actions &&
          actions.length > 0 &&
          actions.map(action => (
            <Button
              className={classes.primaryButton}
              size={action?.size || 'small'}
              fullWidth={action?.fullWidth || false}
              onClick={action?.onClick}
              component={RouterLink}
              to={action?.link}
              startIcon={
                action?.icon && (
                  <Icon className={classes.icon} data-nosnippet="data-nosnippet">
                    {action?.icon}
                  </Icon>
                )
              }
            >
              {action?.title}
            </Button>
          ))}
      </Grid>
    </Grid>
  );
};

export default ActionHeader;
