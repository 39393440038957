import { SET_LAST_PAID_ORDER_ID_SUCCESS } from '../../../constants/miscActionNames';

export function setLastPaidOrderIdAction(data) {
  return {
    type: SET_LAST_PAID_ORDER_ID_SUCCESS,
    payload: data,
  };
}

export default {
  setLastPaidOrderIdAction,
};
