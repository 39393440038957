import {
    GET_POLICY_DOCUMENTS_API_REQUEST,
    GET_POLICY_DOCUMENTS_API_SUCCESS,
    GET_POLICY_DOCUMENTS_API_ERROR,
} from '../../../constants/portfolio/policyDocumentActionNames';

import { getPolicyDocumentsApi } from '../../../apis/portfolio';

function getPolicyDocumentsRequest() {
    return {
        type: GET_POLICY_DOCUMENTS_API_REQUEST,
        payload: true,
    };
}
function getPolicyDocumentsSuccess(documents) {
    return {
        type: GET_POLICY_DOCUMENTS_API_SUCCESS,
        payload: documents,
    };
}

function getPolicyDocumentsError(err) {
    return {
        type: GET_POLICY_DOCUMENTS_API_ERROR,
        payload: err,
    };
}

export function getPolicyDocumentsAction(params) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(getPolicyDocumentsRequest());
        return getPolicyDocumentsApi
            .getPolicyDocuments(state, params)
            .then(data => {
                dispatch(getPolicyDocumentsSuccess(data));
                return data;
            })
            .catch(error => {
                dispatch(getPolicyDocumentsError(error));
                throw error;
            });
    };
}
export default {
    getPolicyDocumentsAction,
};
