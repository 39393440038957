import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import { useMemo } from 'react';
import queryString from 'query-string';
import { push, goBack, goForward } from 'connected-react-router';
import { useDispatch } from 'react-redux';

// Hook
const useRouter = () => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  // // console.log(`match: ${match}`);

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      // For convenience add push(), replace(), pathname at top level
      go: history.go,
      push: history.push,
      replace: history.replace,
      goBack: history.goBack,
      goForward: history.goForward,
      pathname: location.pathname,
      asPath: location.pathname + location.search,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      match,
      location,
      history,
      storePush: link => dispatch(push(link)),
      storeGoBack: link => dispatch(goBack(link)),
      storeGoForward: link => dispatch(goForward(link)),
    };
  }, [params, match, location, history]);
};

export default useRouter;
