export default [
  {
    _id: 'Master-000425_01112017RHR0060',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-04',
    patient: 230958,
    masterId: 'Master-000425',
    name: 'DREW-MORGAN PERSAD',
    type: 'Dependent',
    reportDate: '2017-01-11T14:40:22.163Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 100,
        paidAmount: 80,
        deductibleAmount: 0,
        coinsuranceAmount: 20,
        copayAmount: 0,
        checkNumber: '683359',
        checkDate: '2017-01-12T00:00:00.000Z',
        fromDate: '2016-12-16T00:00:00.000Z',
        toDate: '2016-12-16T00:00:00.000Z',
        receivedDate: '2017-01-09T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'LAB',
        submittedAmount: 150,
        paidAmount: 105,
        deductibleAmount: 0,
        coinsuranceAmount: 45,
        copayAmount: 0,
        checkNumber: '683359',
        checkDate: '2017-01-12T00:00:00.000Z',
        fromDate: '2016-12-16T00:00:00.000Z',
        toDate: '2016-12-16T00:00:00.000Z',
        receivedDate: '2017-01-09T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'VISIT',
        submittedAmount: 200,
        paidAmount: 105,
        deductibleAmount: 0,
        coinsuranceAmount: 45,
        copayAmount: 0,
        checkNumber: '683359',
        checkDate: '2017-01-12T00:00:00.000Z',
        fromDate: '2016-12-16T00:00:00.000Z',
        toDate: '2016-12-16T00:00:00.000Z',
        receivedDate: '2017-01-09T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_01112017RHR0061',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-00',
    patient: 230954,
    masterId: 'Master-000425',
    name: 'BRYAN PERSAD',
    type: 'Insured',
    reportDate: '2017-01-11T14:43:38.680Z',
    claimDetails: [
      {
        procedure: 'VISIT',
        submittedAmount: 200,
        paidAmount: 105,
        deductibleAmount: 0,
        coinsuranceAmount: 45,
        copayAmount: 0,
        checkNumber: '683359',
        checkDate: '2017-01-12T00:00:00.000Z',
        fromDate: '2016-12-16T00:00:00.000Z',
        toDate: '2016-12-16T00:00:00.000Z',
        receivedDate: '2017-01-09T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DRUGS',
        submittedAmount: 74.49,
        paidAmount: 59.59,
        deductibleAmount: 0,
        coinsuranceAmount: 14.9,
        copayAmount: 0,
        checkNumber: '683359',
        checkDate: '2017-01-12T00:00:00.000Z',
        fromDate: '2016-12-20T00:00:00.000Z',
        toDate: '2016-12-20T00:00:00.000Z',
        receivedDate: '2017-01-09T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DRUGS',
        submittedAmount: 228.5,
        paidAmount: 182.8,
        deductibleAmount: 0,
        coinsuranceAmount: 45.7,
        copayAmount: 0,
        checkNumber: '683359',
        checkDate: '2017-01-12T00:00:00.000Z',
        fromDate: '2016-12-20T00:00:00.000Z',
        toDate: '2016-12-20T00:00:00.000Z',
        receivedDate: '2017-01-09T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_01122016MR 0063',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2016-01-12T14:38:45.223Z',
    claimDetails: [
      {
        procedure: 'ACCER',
        submittedAmount: 996.45,
        paidAmount: 946.45,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 50,
        checkNumber: '629415',
        checkDate: '2016-01-20T00:00:00.000Z',
        fromDate: '2015-12-20T00:00:00.000Z',
        toDate: '2015-12-20T00:00:00.000Z',
        receivedDate: '2016-01-05T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_01252018MR 0074',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-04',
    patient: 230958,
    masterId: 'Master-000425',
    name: 'DREW-MORGAN PERSAD',
    type: 'Dependent',
    reportDate: '2018-01-25T16:06:59.523Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 450,
        paidAmount: 210,
        deductibleAmount: 150,
        coinsuranceAmount: 90,
        copayAmount: 0,
        checkNumber: '823448',
        checkDate: '2018-01-26T00:00:00.000Z',
        fromDate: '2017-12-20T00:00:00.000Z',
        toDate: '2017-12-20T00:00:00.000Z',
        receivedDate: '2018-01-16T00:00:00.000Z',
        provider: ' Dental Provider Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_01252018MR 0075',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-04',
    patient: 230958,
    masterId: 'Master-000425',
    name: 'DREW-MORGAN PERSAD',
    type: 'Dependent',
    reportDate: '2018-01-25T16:07:38.710Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 75,
        paidAmount: 52.5,
        deductibleAmount: 0,
        coinsuranceAmount: 22.5,
        copayAmount: 0,
        checkNumber: '823448',
        checkDate: '2018-01-26T00:00:00.000Z',
        fromDate: '2017-11-28T00:00:00.000Z',
        toDate: '2017-11-28T00:00:00.000Z',
        receivedDate: '2018-01-16T00:00:00.000Z',
        provider: ' Dental Provider Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_01252018MR 0076',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-03',
    patient: 230957,
    masterId: 'Master-000425',
    name: 'ZANA-MAE PERSAD',
    type: 'Dependent',
    reportDate: '2018-01-25T16:09:22.560Z',
    claimDetails: [
      {
        procedure: 'LAB',
        submittedAmount: 180,
        paidAmount: 144,
        deductibleAmount: 0,
        coinsuranceAmount: 36,
        copayAmount: 0,
        checkNumber: '823448',
        checkDate: '2018-01-26T00:00:00.000Z',
        fromDate: '2017-12-21T00:00:00.000Z',
        toDate: '2017-12-21T00:00:00.000Z',
        receivedDate: '2018-01-16T00:00:00.000Z',
        provider: 'Anilla Jaggat',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'SPEC',
        submittedAmount: 500,
        paidAmount: 300,
        deductibleAmount: 0,
        coinsuranceAmount: 128.57,
        copayAmount: 0,
        checkNumber: '823448',
        checkDate: '2018-01-26T00:00:00.000Z',
        fromDate: '2017-12-21T00:00:00.000Z',
        toDate: '2017-12-21T00:00:00.000Z',
        receivedDate: '2018-01-16T00:00:00.000Z',
        provider: 'Anilla Jaggat',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_01252018MR 0077',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-03',
    patient: 230957,
    masterId: 'Master-000425',
    name: 'ZANA-MAE PERSAD',
    type: 'Dependent',
    reportDate: '2018-01-25T16:10:40.027Z',
    claimDetails: [
      {
        procedure: 'LAB',
        submittedAmount: 210,
        paidAmount: 168,
        deductibleAmount: 0,
        coinsuranceAmount: 42,
        copayAmount: 0,
        checkNumber: '823448',
        checkDate: '2018-01-26T00:00:00.000Z',
        fromDate: '2017-12-22T00:00:00.000Z',
        toDate: '2017-12-22T00:00:00.000Z',
        receivedDate: '2018-01-16T00:00:00.000Z',
        provider: ' Victoria Laboratories Ltd',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_01252018MR 0078',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-02',
    patient: 230956,
    masterId: 'Master-000425',
    name: 'EMMA-LEE PERSAD',
    type: 'Dependent',
    reportDate: '2018-01-25T16:11:38.937Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 450,
        paidAmount: 210,
        deductibleAmount: 150,
        coinsuranceAmount: 90,
        copayAmount: 0,
        checkNumber: '823448',
        checkDate: '2018-01-26T00:00:00.000Z',
        fromDate: '2017-12-20T00:00:00.000Z',
        toDate: '2017-12-20T00:00:00.000Z',
        receivedDate: '2018-01-16T00:00:00.000Z',
        provider: 'SARAH RAMSAROOP',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_01252018MR 0079',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2018-01-25T16:12:52.540Z',
    claimDetails: [
      {
        procedure: 'SPEC',
        submittedAmount: 500,
        paidAmount: 0,
        deductibleAmount: 428.58,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2018-01-09T00:00:00.000Z',
        toDate: '2018-01-09T00:00:00.000Z',
        receivedDate: '2018-01-16T00:00:00.000Z',
        provider: 'Anilla Jaggat',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_01252018MR 0080',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2018-01-25T16:14:45.807Z',
    claimDetails: [
      {
        procedure: 'LAB',
        submittedAmount: 500,
        paidAmount: 350,
        deductibleAmount: 0,
        coinsuranceAmount: 150,
        copayAmount: 0,
        checkNumber: '823448',
        checkDate: '2018-01-26T00:00:00.000Z',
        fromDate: '2017-12-22T00:00:00.000Z',
        toDate: '2017-12-22T00:00:00.000Z',
        receivedDate: '2018-01-16T00:00:00.000Z',
        provider: ' Westshore Medical Radiology Service',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_01252018MR 0081',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2018-01-25T16:15:32.790Z',
    claimDetails: [
      {
        procedure: 'LAB',
        submittedAmount: 210,
        paidAmount: 168,
        deductibleAmount: 0,
        coinsuranceAmount: 42,
        copayAmount: 0,
        checkNumber: '823448',
        checkDate: '2018-01-26T00:00:00.000Z',
        fromDate: '2018-01-10T00:00:00.000Z',
        toDate: '2018-01-10T00:00:00.000Z',
        receivedDate: '2018-01-16T00:00:00.000Z',
        provider: ' Victoria Medical Laboratories Ltd',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_01252018MR 0082',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-02',
    patient: 230956,
    masterId: 'Master-000425',
    name: 'EMMA-LEE PERSAD',
    type: 'Dependent',
    reportDate: '2018-01-25T16:16:25.507Z',
    claimDetails: [
      {
        procedure: 'SPEC',
        submittedAmount: 500,
        paidAmount: 300,
        deductibleAmount: 0,
        coinsuranceAmount: 128.57,
        copayAmount: 0,
        checkNumber: '823448',
        checkDate: '2018-01-26T00:00:00.000Z',
        fromDate: '2017-12-21T00:00:00.000Z',
        toDate: '2017-12-21T00:00:00.000Z',
        receivedDate: '2018-01-16T00:00:00.000Z',
        provider: 'Anilla Jaggat',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_01252018RHR0146',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-02',
    patient: 230956,
    masterId: 'Master-000425',
    name: 'EMMA-LEE PERSAD',
    type: 'Dependent',
    reportDate: '2018-01-25T20:13:25.633Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 104.4,
        paidAmount: 83.52,
        deductibleAmount: 0,
        coinsuranceAmount: 20.88,
        copayAmount: 0,
        checkNumber: '2274198',
        checkDate: '2018-01-26T00:00:00.000Z',
        fromDate: '2017-12-22T00:00:00.000Z',
        toDate: '2017-12-22T00:00:00.000Z',
        receivedDate: '2018-01-17T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_01312017MR 0065',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-03',
    patient: 230957,
    masterId: 'Master-000425',
    name: 'ZANA-MAE PERSAD',
    type: 'Dependent',
    reportDate: '2017-01-31T20:18:06.367Z',
    claimDetails: [
      {
        procedure: 'FRAME',
        submittedAmount: 629,
        paidAmount: 383.2,
        deductibleAmount: 150,
        coinsuranceAmount: 95.8,
        copayAmount: 0,
        checkNumber: '685629',
        checkDate: '2017-02-02T00:00:00.000Z',
        fromDate: '2016-11-18T00:00:00.000Z',
        toDate: '2016-11-18T00:00:00.000Z',
        receivedDate: '2017-01-26T00:00:00.000Z',
        provider: ' Optical In Network - Subscriber',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'LENS',
        submittedAmount: 450,
        paidAmount: 360,
        deductibleAmount: 0,
        coinsuranceAmount: 90,
        copayAmount: 0,
        checkNumber: '685629',
        checkDate: '2017-02-02T00:00:00.000Z',
        fromDate: '2016-11-18T00:00:00.000Z',
        toDate: '2016-11-18T00:00:00.000Z',
        receivedDate: '2017-01-26T00:00:00.000Z',
        provider: ' Optical In Network - Subscriber',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_01312017MR 0066',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-00',
    patient: 230954,
    masterId: 'Master-000425',
    name: 'BRYAN PERSAD',
    type: 'Insured',
    reportDate: '2017-01-31T21:19:48.537Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 75,
        paidAmount: 52.5,
        deductibleAmount: 0,
        coinsuranceAmount: 22.5,
        copayAmount: 0,
        checkNumber: '685629',
        checkDate: '2017-02-02T00:00:00.000Z',
        fromDate: '2016-11-29T00:00:00.000Z',
        toDate: '2016-11-29T00:00:00.000Z',
        receivedDate: '2017-01-26T00:00:00.000Z',
        provider: ' Dental Provider Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DENT',
        submittedAmount: 450,
        paidAmount: 315,
        deductibleAmount: 0,
        coinsuranceAmount: 135,
        copayAmount: 0,
        checkNumber: '685629',
        checkDate: '2017-02-02T00:00:00.000Z',
        fromDate: '2016-11-29T00:00:00.000Z',
        toDate: '2016-11-29T00:00:00.000Z',
        receivedDate: '2017-01-26T00:00:00.000Z',
        provider: ' Dental Provider Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_02012017MR 0001',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-00',
    patient: 230954,
    masterId: 'Master-000425',
    name: 'BRYAN PERSAD',
    type: 'Insured',
    reportDate: '2017-02-01T12:22:54.417Z',
    claimDetails: [
      {
        procedure: 'VISIT',
        submittedAmount: 200,
        paidAmount: 105,
        deductibleAmount: 0,
        coinsuranceAmount: 45,
        copayAmount: 0,
        checkNumber: '685629',
        checkDate: '2017-02-02T00:00:00.000Z',
        fromDate: '2016-11-28T00:00:00.000Z',
        toDate: '2016-11-28T00:00:00.000Z',
        receivedDate: '2017-01-26T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_02012017MR 0002',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-02-01T12:24:49.573Z',
    claimDetails: [
      {
        procedure: 'LAB',
        submittedAmount: 450,
        paidAmount: 26.25,
        deductibleAmount: 412.5,
        coinsuranceAmount: 11.25,
        copayAmount: 0,
        checkNumber: '685629',
        checkDate: '2017-02-02T00:00:00.000Z',
        fromDate: '2017-01-18T00:00:00.000Z',
        toDate: '2017-01-18T00:00:00.000Z',
        receivedDate: '2017-01-26T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'VISIT',
        submittedAmount: 250,
        paidAmount: 0,
        deductibleAmount: 187.5,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2017-01-18T00:00:00.000Z',
        toDate: '2017-01-18T00:00:00.000Z',
        receivedDate: '2017-01-26T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_02012017MR 0003',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-02-01T12:25:31.727Z',
    claimDetails: [
      {
        procedure: 'VISIT',
        submittedAmount: 250,
        paidAmount: 105,
        deductibleAmount: 0,
        coinsuranceAmount: 45,
        copayAmount: 0,
        checkNumber: '685629',
        checkDate: '2017-02-02T00:00:00.000Z',
        fromDate: '2017-01-14T00:00:00.000Z',
        toDate: '2017-01-14T00:00:00.000Z',
        receivedDate: '2017-01-26T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_02012017MR 0004',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-02-01T12:27:14.640Z',
    claimDetails: [
      {
        procedure: 'LAB',
        submittedAmount: 1600,
        paidAmount: 1120,
        deductibleAmount: 0,
        coinsuranceAmount: 480,
        copayAmount: 0,
        checkNumber: '685629',
        checkDate: '2017-02-02T00:00:00.000Z',
        fromDate: '2017-01-20T00:00:00.000Z',
        toDate: '2017-01-20T00:00:00.000Z',
        receivedDate: '2017-01-26T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'VISIT',
        submittedAmount: 250,
        paidAmount: 105,
        deductibleAmount: 0,
        coinsuranceAmount: 45,
        copayAmount: 0,
        checkNumber: '685629',
        checkDate: '2017-02-02T00:00:00.000Z',
        fromDate: '2017-01-20T00:00:00.000Z',
        toDate: '2017-01-20T00:00:00.000Z',
        receivedDate: '2017-01-26T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_02082017VD 0036',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-02',
    patient: 230956,
    masterId: 'Master-000425',
    name: 'EMMA-LEE PERSAD',
    type: 'Dependent',
    reportDate: '2017-02-08T09:48:43.580Z',
    claimDetails: [
      {
        procedure: 'HMISC',
        submittedAmount: 438.8,
        paidAmount: 351.04,
        deductibleAmount: 0,
        coinsuranceAmount: 87.76,
        copayAmount: 0,
        checkNumber: '686548',
        checkDate: '2017-02-10T00:00:00.000Z',
        fromDate: '2016-10-20T00:00:00.000Z',
        toDate: '2016-10-20T00:00:00.000Z',
        receivedDate: '2017-02-02T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'R&B',
        submittedAmount: 1650,
        paidAmount: 560,
        deductibleAmount: 0,
        coinsuranceAmount: 140,
        copayAmount: 0,
        checkNumber: '686548',
        checkDate: '2017-02-10T00:00:00.000Z',
        fromDate: '2016-10-20T00:00:00.000Z',
        toDate: '2016-10-20T00:00:00.000Z',
        receivedDate: '2017-02-02T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'SPEC',
        submittedAmount: 1000,
        paidAmount: 350,
        deductibleAmount: 0,
        coinsuranceAmount: 87.5,
        copayAmount: 0,
        checkNumber: '686548',
        checkDate: '2017-02-10T00:00:00.000Z',
        fromDate: '2016-10-20T00:00:00.000Z',
        toDate: '2016-10-20T00:00:00.000Z',
        receivedDate: '2017-02-02T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_02082017VD 0037',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-02',
    patient: 230956,
    masterId: 'Master-000425',
    name: 'EMMA-LEE PERSAD',
    type: 'Dependent',
    reportDate: '2017-02-08T11:06:05.823Z',
    claimDetails: [
      {
        procedure: 'ACCER',
        submittedAmount: 955.7,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-10-20T00:00:00.000Z',
        toDate: '2016-10-20T00:00:00.000Z',
        receivedDate: '2017-02-02T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'LAB',
        submittedAmount: 940,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-10-20T00:00:00.000Z',
        toDate: '2016-10-20T00:00:00.000Z',
        receivedDate: '2017-02-02T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_02212018RHR0044',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-00',
    patient: 230954,
    masterId: 'Master-000425',
    name: 'BRYAN PERSAD',
    type: 'Insured',
    reportDate: '2018-02-21T11:14:53.353Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 397.8,
        paidAmount: 318.24,
        deductibleAmount: 0,
        coinsuranceAmount: 79.56,
        copayAmount: 0,
        checkNumber: '2276781',
        checkDate: '2018-02-22T00:00:00.000Z',
        fromDate: '2017-12-22T00:00:00.000Z',
        toDate: '2017-12-22T00:00:00.000Z',
        receivedDate: '2018-02-16T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_03022017CA 0022',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-03-02T01:24:13.503Z',
    claimDetails: [
      {
        procedure: 'ACCER',
        submittedAmount: -4580.5,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2017-02-03T00:00:00.000Z',
        toDate: '2017-02-03T00:00:00.000Z',
        receivedDate: '2017-02-21T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'ACCER',
        submittedAmount: 4580.5,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2017-02-03T00:00:00.000Z',
        toDate: '2017-02-03T00:00:00.000Z',
        receivedDate: '2017-02-21T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_03092016TSM0059',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2016-03-09T12:49:25.130Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 65.99,
        paidAmount: 52.79,
        deductibleAmount: 0,
        coinsuranceAmount: 13.2,
        copayAmount: 0,
        checkNumber: '638032',
        checkDate: '2016-03-11T00:00:00.000Z',
        fromDate: '2016-01-06T00:00:00.000Z',
        toDate: '2016-01-06T00:00:00.000Z',
        receivedDate: '2016-03-08T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_03102016VD 0056',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2016-03-10T12:10:23.470Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 7.28,
        paidAmount: 5.82,
        deductibleAmount: 0,
        coinsuranceAmount: 1.46,
        copayAmount: 0,
        checkNumber: '638271',
        checkDate: '2016-03-14T00:00:00.000Z',
        fromDate: '2016-01-04T00:00:00.000Z',
        toDate: '2016-01-04T00:00:00.000Z',
        receivedDate: '2016-03-09T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DRUGS',
        submittedAmount: 343.56,
        paidAmount: 274.85,
        deductibleAmount: 0,
        coinsuranceAmount: 68.71,
        copayAmount: 0,
        checkNumber: '638271',
        checkDate: '2016-03-14T00:00:00.000Z',
        fromDate: '2016-01-04T00:00:00.000Z',
        toDate: '2016-01-04T00:00:00.000Z',
        receivedDate: '2016-03-09T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'OTCRX',
        submittedAmount: 137.15,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-01-04T00:00:00.000Z',
        toDate: '2016-01-04T00:00:00.000Z',
        receivedDate: '2016-03-09T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_03192016MR 0064',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-00',
    patient: 230954,
    masterId: 'Master-000425',
    name: 'BRYAN PERSAD',
    type: 'Insured',
    reportDate: '2016-03-19T18:35:59.840Z',
    claimDetails: [
      {
        procedure: 'LAB',
        submittedAmount: 1465,
        paidAmount: 1172,
        deductibleAmount: 0,
        coinsuranceAmount: 293,
        copayAmount: 0,
        checkNumber: '639973',
        checkDate: '2016-03-21T00:00:00.000Z',
        fromDate: '2016-02-15T00:00:00.000Z',
        toDate: '2016-02-15T00:00:00.000Z',
        receivedDate: '2016-03-14T00:00:00.000Z',
        provider: ' Medical Associates Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_03192016MR 0065',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-02',
    patient: 230956,
    masterId: 'Master-000425',
    name: 'EMMA-LEE PERSAD',
    type: 'Dependent',
    reportDate: '2016-03-19T18:37:00.837Z',
    claimDetails: [
      {
        procedure: 'SPEC',
        submittedAmount: 400,
        paidAmount: 0,
        deductibleAmount: 400,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-02-16T00:00:00.000Z',
        toDate: '2016-02-16T00:00:00.000Z',
        receivedDate: '2016-03-14T00:00:00.000Z',
        provider: ' Specialist Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_03192016MR 0066',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2016-03-19T18:38:40.867Z',
    claimDetails: [
      {
        procedure: 'LAB',
        submittedAmount: 1700,
        paidAmount: 901.25,
        deductibleAmount: 412.5,
        coinsuranceAmount: 386.25,
        copayAmount: 0,
        checkNumber: '639973',
        checkDate: '2016-03-21T00:00:00.000Z',
        fromDate: '2016-02-01T00:00:00.000Z',
        toDate: '2016-02-01T00:00:00.000Z',
        receivedDate: '2016-03-14T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'VISIT',
        submittedAmount: 200,
        paidAmount: 0,
        deductibleAmount: 187.5,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-02-01T00:00:00.000Z',
        toDate: '2016-02-01T00:00:00.000Z',
        receivedDate: '2016-03-14T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_03192016MR 0067',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2016-03-19T18:39:28.740Z',
    claimDetails: [
      {
        procedure: 'LAB',
        submittedAmount: -1000,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-02-16T00:00:00.000Z',
        toDate: '2016-02-16T00:00:00.000Z',
        receivedDate: '2016-03-14T00:00:00.000Z',
        provider: ' Western Medical Radiology',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'LAB',
        submittedAmount: 1000,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-02-16T00:00:00.000Z',
        toDate: '2016-02-16T00:00:00.000Z',
        receivedDate: '2016-03-14T00:00:00.000Z',
        provider: ' Western Medical Radiology',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_03212017MR 0028',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-03-21T12:53:20.390Z',
    claimDetails: [
      {
        submittedAmount: 0,
        paidAmount: -70.72,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        checkNumber: '2246581',
        checkDate: '2017-03-22T00:00:00.000Z',
        fromDate: '2017-01-14T00:00:00.000Z',
        toDate: '2017-01-14T00:00:00.000Z',
        receivedDate: '2017-03-14T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
      {
        submittedAmount: 0,
        paidAmount: 70.72,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        checkNumber: '690942',
        checkDate: '2017-03-22T00:00:00.000Z',
        fromDate: '2017-01-14T00:00:00.000Z',
        toDate: '2017-01-14T00:00:00.000Z',
        receivedDate: '2017-03-14T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DRUGS',
        submittedAmount: 31.2,
        paidAmount: 24.96,
        deductibleAmount: 0,
        coinsuranceAmount: 6.24,
        copayAmount: 0,
        checkNumber: '2246581',
        checkDate: '2017-03-22T00:00:00.000Z',
        fromDate: '2017-01-14T00:00:00.000Z',
        toDate: '2017-01-14T00:00:00.000Z',
        receivedDate: '2017-03-14T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DRUGS',
        submittedAmount: 88.4,
        paidAmount: 70.72,
        deductibleAmount: 0,
        coinsuranceAmount: 17.68,
        copayAmount: 0,
        checkNumber: '2246581',
        checkDate: '2017-03-22T00:00:00.000Z',
        fromDate: '2017-01-14T00:00:00.000Z',
        toDate: '2017-01-14T00:00:00.000Z',
        receivedDate: '2017-03-14T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_03262018TSM0111',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-00',
    patient: 230954,
    masterId: 'Master-000425',
    name: 'BRYAN PERSAD',
    type: 'Insured',
    reportDate: '2018-03-26T13:57:27.440Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 500,
        paidAmount: 245,
        deductibleAmount: 150,
        coinsuranceAmount: 105,
        copayAmount: 0,
        checkNumber: '829776',
        checkDate: '2018-03-27T00:00:00.000Z',
        fromDate: '2018-02-08T00:00:00.000Z',
        toDate: '2018-02-08T00:00:00.000Z',
        receivedDate: '2018-03-20T00:00:00.000Z',
        provider: ' Dental Provider Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_03262018TSM0112',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2018-03-26T14:00:24.403Z',
    claimDetails: [
      {
        procedure: 'HMISC',
        submittedAmount: 50,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2018-01-30T00:00:00.000Z',
        toDate: '2018-01-30T00:00:00.000Z',
        receivedDate: '2018-03-20T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'IVINJ',
        submittedAmount: 200,
        paidAmount: 160,
        deductibleAmount: 0,
        coinsuranceAmount: 40,
        copayAmount: 0,
        checkNumber: '829776',
        checkDate: '2018-03-27T00:00:00.000Z',
        fromDate: '2018-01-30T00:00:00.000Z',
        toDate: '2018-01-30T00:00:00.000Z',
        receivedDate: '2018-03-20T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'VISIT',
        submittedAmount: 200,
        paidAmount: 11.26,
        deductibleAmount: 171.42,
        coinsuranceAmount: 4.82,
        copayAmount: 0,
        checkNumber: '829776',
        checkDate: '2018-03-27T00:00:00.000Z',
        fromDate: '2018-01-30T00:00:00.000Z',
        toDate: '2018-01-30T00:00:00.000Z',
        receivedDate: '2018-03-20T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_03262018TSM0113',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-02',
    patient: 230956,
    masterId: 'Master-000425',
    name: 'EMMA-LEE PERSAD',
    type: 'Dependent',
    reportDate: '2018-03-26T14:07:42.140Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 249.3,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2017-11-21T00:00:00.000Z',
        toDate: '2017-11-21T00:00:00.000Z',
        receivedDate: '2018-03-20T00:00:00.000Z',
        provider: ' Obstetrician / Gyne Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'LAB',
        submittedAmount: 200,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2017-11-21T00:00:00.000Z',
        toDate: '2017-11-21T00:00:00.000Z',
        receivedDate: '2018-03-20T00:00:00.000Z',
        provider: ' Obstetrician / Gyne Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'LAB',
        submittedAmount: 500,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2017-11-21T00:00:00.000Z',
        toDate: '2017-11-21T00:00:00.000Z',
        receivedDate: '2018-03-20T00:00:00.000Z',
        provider: ' Obstetrician / Gyne Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'SPEC',
        submittedAmount: 500,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2017-11-21T00:00:00.000Z',
        toDate: '2017-11-21T00:00:00.000Z',
        receivedDate: '2018-03-20T00:00:00.000Z',
        provider: ' Obstetrician / Gyne Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_03262018TSM0114',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-03',
    patient: 230957,
    masterId: 'Master-000425',
    name: 'ZANA-MAE PERSAD',
    type: 'Dependent',
    reportDate: '2018-03-26T14:26:24.847Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 1200,
        paidAmount: 525,
        deductibleAmount: 150,
        coinsuranceAmount: 525,
        copayAmount: 0,
        checkNumber: '829776',
        checkDate: '2018-03-27T00:00:00.000Z',
        fromDate: '2018-01-25T00:00:00.000Z',
        toDate: '2018-01-25T00:00:00.000Z',
        receivedDate: '2018-03-20T00:00:00.000Z',
        provider: ' Orthodontist Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_03262018TSM0115',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-03',
    patient: 230957,
    masterId: 'Master-000425',
    name: 'ZANA-MAE PERSAD',
    type: 'Dependent',
    reportDate: '2018-03-26T14:28:04.530Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 1200,
        paidAmount: 600,
        deductibleAmount: 0,
        coinsuranceAmount: 600,
        copayAmount: 0,
        checkNumber: '829776',
        checkDate: '2018-03-27T00:00:00.000Z',
        fromDate: '2018-02-22T00:00:00.000Z',
        toDate: '2018-02-22T00:00:00.000Z',
        receivedDate: '2018-03-20T00:00:00.000Z',
        provider: ' Orthodontist Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_03292017MR 0052',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-03-29T17:50:51.423Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 523.8,
        paidAmount: 419.04,
        deductibleAmount: 0,
        coinsuranceAmount: 104.76,
        copayAmount: 0,
        checkNumber: '2247378',
        checkDate: '2017-03-31T00:00:00.000Z',
        fromDate: '2017-02-18T00:00:00.000Z',
        toDate: '2017-02-18T00:00:00.000Z',
        receivedDate: '2017-03-23T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_03292017MR 0062',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-03-29T18:01:20.793Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 244.44,
        paidAmount: 195.55,
        deductibleAmount: 0,
        coinsuranceAmount: 48.89,
        copayAmount: 0,
        checkNumber: '2247378',
        checkDate: '2017-03-31T00:00:00.000Z',
        fromDate: '2017-02-04T00:00:00.000Z',
        toDate: '2017-02-04T00:00:00.000Z',
        receivedDate: '2017-03-23T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_03292017MR 0063',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-03-29T18:02:04.790Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 145.12,
        paidAmount: 116.1,
        deductibleAmount: 0,
        coinsuranceAmount: 29.02,
        copayAmount: 0,
        checkNumber: '2247378',
        checkDate: '2017-03-31T00:00:00.000Z',
        fromDate: '2017-02-04T00:00:00.000Z',
        toDate: '2017-02-04T00:00:00.000Z',
        receivedDate: '2017-03-23T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_03312018CA 0008',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-00',
    patient: 230954,
    masterId: 'Master-000425',
    name: 'BRYAN PERSAD',
    type: 'Insured',
    reportDate: '2018-03-31T07:48:20.133Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 358.02,
        paidAmount: 286.42,
        deductibleAmount: 0,
        coinsuranceAmount: 71.6,
        copayAmount: 0,
        checkNumber: '2280520',
        checkDate: '2018-04-03T00:00:00.000Z',
        fromDate: '2018-03-08T00:00:00.000Z',
        toDate: '2018-03-08T00:00:00.000Z',
        receivedDate: '2018-03-26T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_04012018GED0031',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-00',
    patient: 230954,
    masterId: 'Master-000425',
    name: 'BRYAN PERSAD',
    type: 'Insured',
    reportDate: '2018-04-01T17:24:48.793Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 397.8,
        paidAmount: 318.24,
        deductibleAmount: 0,
        coinsuranceAmount: 79.56,
        copayAmount: 0,
        checkNumber: '2280520',
        checkDate: '2018-04-03T00:00:00.000Z',
        fromDate: '2018-02-04T00:00:00.000Z',
        toDate: '2018-02-04T00:00:00.000Z',
        receivedDate: '2018-03-09T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_04012018GED0032',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-02',
    patient: 230956,
    masterId: 'Master-000425',
    name: 'EMMA-LEE PERSAD',
    type: 'Dependent',
    reportDate: '2018-04-01T18:31:09.483Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 104.4,
        paidAmount: 83.52,
        deductibleAmount: 0,
        coinsuranceAmount: 20.88,
        copayAmount: 0,
        checkNumber: '2280520',
        checkDate: '2018-04-03T00:00:00.000Z',
        fromDate: '2018-02-04T00:00:00.000Z',
        toDate: '2018-02-04T00:00:00.000Z',
        receivedDate: '2018-03-09T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_04042017CA 0009',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-04-04T11:54:11.137Z',
    claimDetails: [
      {
        procedure: 'ACCER',
        submittedAmount: 1920.7,
        paidAmount: 1000,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 50,
        checkNumber: '2248749',
        checkDate: '2017-04-19T00:00:00.000Z',
        fromDate: '2017-02-02T00:00:00.000Z',
        toDate: '2017-02-02T00:00:00.000Z',
        receivedDate: '2017-02-21T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
      {
        submittedAmount: 0,
        paidAmount: -144.28,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        checkNumber: '2248749',
        checkDate: '2017-04-19T00:00:00.000Z',
        fromDate: '2017-02-02T00:00:00.000Z',
        toDate: '2017-02-03T00:00:00.000Z',
        receivedDate: '2017-02-21T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
      {
        submittedAmount: 0,
        paidAmount: 144.28,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        checkNumber: '694096',
        checkDate: '2017-04-19T00:00:00.000Z',
        fromDate: '2017-02-02T00:00:00.000Z',
        toDate: '2017-02-03T00:00:00.000Z',
        receivedDate: '2017-02-21T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'LAB',
        submittedAmount: 2652,
        paidAmount: 2121.6,
        deductibleAmount: 0,
        coinsuranceAmount: 530.4,
        copayAmount: 0,
        checkNumber: '2248749',
        checkDate: '2017-04-19T00:00:00.000Z',
        fromDate: '2017-02-03T00:00:00.000Z',
        toDate: '2017-02-03T00:00:00.000Z',
        receivedDate: '2017-02-21T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'OTCRX',
        submittedAmount: 7.8,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2017-02-03T00:00:00.000Z',
        toDate: '2017-02-03T00:00:00.000Z',
        receivedDate: '2017-02-21T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_04052017VD 0042',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-03',
    patient: 230957,
    masterId: 'Master-000425',
    name: 'ZANA-MAE PERSAD',
    type: 'Dependent',
    reportDate: '2017-04-05T12:40:48.853Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 1200,
        paidAmount: 525,
        deductibleAmount: 150,
        coinsuranceAmount: 525,
        copayAmount: 0,
        checkNumber: '692784',
        checkDate: '2017-04-06T00:00:00.000Z',
        fromDate: '2017-01-26T00:00:00.000Z',
        toDate: '2017-01-26T00:00:00.000Z',
        receivedDate: '2017-04-03T00:00:00.000Z',
        provider: ' Orthodontist Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_04052017VD 0043',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-04-05T12:43:25.317Z',
    claimDetails: [
      {
        procedure: 'PSYCH',
        submittedAmount: 500,
        paidAmount: 400,
        deductibleAmount: 0,
        coinsuranceAmount: 100,
        copayAmount: 0,
        checkNumber: '692784',
        checkDate: '2017-04-06T00:00:00.000Z',
        fromDate: '2017-03-04T00:00:00.000Z',
        toDate: '2017-03-04T00:00:00.000Z',
        receivedDate: '2017-04-03T00:00:00.000Z',
        provider: ' Psychiatrist Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_04052017VD 0044',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-04-05T12:46:42.490Z',
    claimDetails: [
      {
        procedure: 'LAB',
        submittedAmount: 850,
        paidAmount: 680,
        deductibleAmount: 0,
        coinsuranceAmount: 170,
        copayAmount: 0,
        checkNumber: '692784',
        checkDate: '2017-04-06T00:00:00.000Z',
        fromDate: '2017-03-04T00:00:00.000Z',
        toDate: '2017-03-04T00:00:00.000Z',
        receivedDate: '2017-04-03T00:00:00.000Z',
        provider: ' Victoria Laboratories Ltd',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_04052017VD 0045',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-04-05T12:49:45.293Z',
    claimDetails: [
      {
        procedure: 'SPEC',
        submittedAmount: 400,
        paidAmount: 280,
        deductibleAmount: 0,
        coinsuranceAmount: 120,
        copayAmount: 0,
        checkNumber: '692784',
        checkDate: '2017-04-06T00:00:00.000Z',
        fromDate: '2017-02-04T00:00:00.000Z',
        toDate: '2017-02-04T00:00:00.000Z',
        receivedDate: '2017-04-03T00:00:00.000Z',
        provider: 'Anilla Jaggat',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_04052017VD 0046',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-04-05T14:14:47.903Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 21,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2017-02-04T00:00:00.000Z',
        toDate: '2017-02-04T00:00:00.000Z',
        receivedDate: '2017-04-03T00:00:00.000Z',
        provider: ' Psychiatrist Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DRUGS',
        submittedAmount: 85,
        paidAmount: 68,
        deductibleAmount: 0,
        coinsuranceAmount: 17,
        copayAmount: 0,
        checkNumber: '692901',
        checkDate: '2017-04-06T00:00:00.000Z',
        fromDate: '2017-02-04T00:00:00.000Z',
        toDate: '2017-02-04T00:00:00.000Z',
        receivedDate: '2017-04-03T00:00:00.000Z',
        provider: ' Psychiatrist Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'PSYCH',
        submittedAmount: 600,
        paidAmount: 480,
        deductibleAmount: 0,
        coinsuranceAmount: 120,
        copayAmount: 0,
        checkNumber: '692901',
        checkDate: '2017-04-06T00:00:00.000Z',
        fromDate: '2017-02-04T00:00:00.000Z',
        toDate: '2017-02-04T00:00:00.000Z',
        receivedDate: '2017-04-03T00:00:00.000Z',
        provider: ' Psychiatrist Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DRUGS',
        submittedAmount: 51,
        paidAmount: 40.8,
        deductibleAmount: 0,
        coinsuranceAmount: 10.2,
        copayAmount: 0,
        checkNumber: '692901',
        checkDate: '2017-04-06T00:00:00.000Z',
        fromDate: '2017-02-18T00:00:00.000Z',
        toDate: '2017-02-18T00:00:00.000Z',
        receivedDate: '2017-04-03T00:00:00.000Z',
        provider: ' Psychiatrist Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'PSYCH',
        submittedAmount: 500,
        paidAmount: 400,
        deductibleAmount: 0,
        coinsuranceAmount: 100,
        copayAmount: 0,
        checkNumber: '692901',
        checkDate: '2017-04-06T00:00:00.000Z',
        fromDate: '2017-02-18T00:00:00.000Z',
        toDate: '2017-02-18T00:00:00.000Z',
        receivedDate: '2017-04-03T00:00:00.000Z',
        provider: ' Psychiatrist Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_04052017VD 0047',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-04-05T14:19:40.320Z',
    claimDetails: [
      {
        procedure: 'IVINJ',
        submittedAmount: 100,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-12-09T00:00:00.000Z',
        toDate: '2016-12-09T00:00:00.000Z',
        receivedDate: '2017-04-03T00:00:00.000Z',
        provider: 'V REDDY KOTAPATI',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'LAB',
        submittedAmount: 150,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-12-09T00:00:00.000Z',
        toDate: '2016-12-09T00:00:00.000Z',
        receivedDate: '2017-04-03T00:00:00.000Z',
        provider: 'V REDDY KOTAPATI',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'VISIT',
        submittedAmount: 200,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-12-09T00:00:00.000Z',
        toDate: '2016-12-09T00:00:00.000Z',
        receivedDate: '2017-04-03T00:00:00.000Z',
        provider: 'V REDDY KOTAPATI',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_04052017VD 0048',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-04-05T14:27:57.147Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 49.7,
        paidAmount: 39.76,
        deductibleAmount: 0,
        coinsuranceAmount: 9.94,
        copayAmount: 0,
        checkNumber: '692901',
        checkDate: '2017-04-06T00:00:00.000Z',
        fromDate: '2017-02-03T00:00:00.000Z',
        toDate: '2017-02-03T00:00:00.000Z',
        receivedDate: '2017-04-03T00:00:00.000Z',
        provider: 'EAMON FOSTER',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'OTCRX',
        submittedAmount: 60.2,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2017-02-03T00:00:00.000Z',
        toDate: '2017-02-03T00:00:00.000Z',
        receivedDate: '2017-04-03T00:00:00.000Z',
        provider: 'EAMON FOSTER',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'VISIT',
        submittedAmount: 500,
        paidAmount: 105,
        deductibleAmount: 0,
        coinsuranceAmount: 45,
        copayAmount: 0,
        checkNumber: '692901',
        checkDate: '2017-04-06T00:00:00.000Z',
        fromDate: '2017-02-03T00:00:00.000Z',
        toDate: '2017-02-03T00:00:00.000Z',
        receivedDate: '2017-04-03T00:00:00.000Z',
        provider: 'EAMON FOSTER',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_04052017VD 0049',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-02',
    patient: 230956,
    masterId: 'Master-000425',
    name: 'EMMA-LEE PERSAD',
    type: 'Dependent',
    reportDate: '2017-04-05T14:32:38.130Z',
    claimDetails: [
      {
        procedure: 'FRAME',
        submittedAmount: 1000,
        paidAmount: 595,
        deductibleAmount: 150,
        coinsuranceAmount: 255,
        copayAmount: 0,
        checkNumber: '692901',
        checkDate: '2017-04-06T00:00:00.000Z',
        fromDate: '2017-01-25T00:00:00.000Z',
        toDate: '2017-01-25T00:00:00.000Z',
        receivedDate: '2017-04-03T00:00:00.000Z',
        provider: ' North Shore Optical Studio Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'LENS',
        submittedAmount: 360,
        paidAmount: 252,
        deductibleAmount: 0,
        coinsuranceAmount: 108,
        copayAmount: 0,
        checkNumber: '692901',
        checkDate: '2017-04-06T00:00:00.000Z',
        fromDate: '2017-01-25T00:00:00.000Z',
        toDate: '2017-01-25T00:00:00.000Z',
        receivedDate: '2017-04-03T00:00:00.000Z',
        provider: ' North Shore Optical Studio Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_04052017VD 0050',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-04-05T14:34:28.910Z',
    claimDetails: [
      {
        procedure: 'SPEC',
        submittedAmount: 600,
        paidAmount: 300,
        deductibleAmount: 0,
        coinsuranceAmount: 128.57,
        copayAmount: 0,
        checkNumber: '692901',
        checkDate: '2017-04-06T00:00:00.000Z',
        fromDate: '2017-03-10T00:00:00.000Z',
        toDate: '2017-03-10T00:00:00.000Z',
        receivedDate: '2017-04-03T00:00:00.000Z',
        provider: 'Robert Ramcharan',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_04052017VD 0051',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-04-05T14:56:28.090Z',
    claimDetails: [
      {
        procedure: 'LAB',
        submittedAmount: 3750,
        paidAmount: 3000,
        deductibleAmount: 0,
        coinsuranceAmount: 750,
        copayAmount: 0,
        checkNumber: '693590',
        checkDate: '2017-04-13T00:00:00.000Z',
        fromDate: '2017-03-06T00:00:00.000Z',
        toDate: '2017-03-06T00:00:00.000Z',
        receivedDate: '2017-04-03T00:00:00.000Z',
        provider: ' Alexandra MRI',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_04052017VD 0052',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-03',
    patient: 230957,
    masterId: 'Master-000425',
    name: 'ZANA-MAE PERSAD',
    type: 'Dependent',
    reportDate: '2017-04-05T14:57:49.480Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 1200,
        paidAmount: 600,
        deductibleAmount: 0,
        coinsuranceAmount: 600,
        copayAmount: 0,
        checkNumber: '692901',
        checkDate: '2017-04-06T00:00:00.000Z',
        fromDate: '2017-03-16T00:00:00.000Z',
        toDate: '2017-03-16T00:00:00.000Z',
        receivedDate: '2017-04-03T00:00:00.000Z',
        provider: ' Orthodontist Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_04052017VD 0053',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-04-05T14:59:28.697Z',
    claimDetails: [
      {
        procedure: 'LAB',
        submittedAmount: 160,
        paidAmount: 128,
        deductibleAmount: 0,
        coinsuranceAmount: 32,
        copayAmount: 0,
        checkNumber: '692901',
        checkDate: '2017-04-06T00:00:00.000Z',
        fromDate: '2017-03-21T00:00:00.000Z',
        toDate: '2017-03-21T00:00:00.000Z',
        receivedDate: '2017-04-03T00:00:00.000Z',
        provider: 'Anilla Jaggat',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'LAB',
        submittedAmount: 400,
        paidAmount: 280,
        deductibleAmount: 0,
        coinsuranceAmount: 120,
        copayAmount: 0,
        checkNumber: '692901',
        checkDate: '2017-04-06T00:00:00.000Z',
        fromDate: '2017-03-21T00:00:00.000Z',
        toDate: '2017-03-21T00:00:00.000Z',
        receivedDate: '2017-04-03T00:00:00.000Z',
        provider: 'Anilla Jaggat',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'SPEC',
        submittedAmount: 400,
        paidAmount: 280,
        deductibleAmount: 0,
        coinsuranceAmount: 120,
        copayAmount: 0,
        checkNumber: '692901',
        checkDate: '2017-04-06T00:00:00.000Z',
        fromDate: '2017-03-21T00:00:00.000Z',
        toDate: '2017-03-21T00:00:00.000Z',
        receivedDate: '2017-04-03T00:00:00.000Z',
        provider: 'Anilla Jaggat',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_04052017VD 0054',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-04-05T15:03:29.333Z',
    claimDetails: [
      {
        procedure: 'LAB',
        submittedAmount: 850,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2017-03-04T00:00:00.000Z',
        toDate: '2017-03-04T00:00:00.000Z',
        receivedDate: '2017-04-03T00:00:00.000Z',
        provider: ' Psychiatrist Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'VISIT',
        submittedAmount: 500,
        paidAmount: 105,
        deductibleAmount: 0,
        coinsuranceAmount: 45,
        copayAmount: 0,
        checkNumber: '692901',
        checkDate: '2017-04-06T00:00:00.000Z',
        fromDate: '2017-03-18T00:00:00.000Z',
        toDate: '2017-03-18T00:00:00.000Z',
        receivedDate: '2017-04-03T00:00:00.000Z',
        provider: ' Psychiatrist Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_04262016MR 0030',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-02',
    patient: 230956,
    masterId: 'Master-000425',
    name: 'EMMA-LEE PERSAD',
    type: 'Dependent',
    reportDate: '2016-04-26T14:15:35.567Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 50,
        paidAmount: 35,
        deductibleAmount: 0,
        coinsuranceAmount: 15,
        copayAmount: 0,
        checkNumber: '646095',
        checkDate: '2016-04-27T00:00:00.000Z',
        fromDate: '2016-04-05T00:00:00.000Z',
        toDate: '2016-04-05T00:00:00.000Z',
        receivedDate: '2016-04-15T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DENT',
        submittedAmount: 150,
        paidAmount: 63,
        deductibleAmount: 0,
        coinsuranceAmount: 27,
        copayAmount: 0,
        checkNumber: '646095',
        checkDate: '2016-04-27T00:00:00.000Z',
        fromDate: '2016-04-05T00:00:00.000Z',
        toDate: '2016-04-05T00:00:00.000Z',
        receivedDate: '2016-04-15T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DENT',
        submittedAmount: 200,
        paidAmount: 105,
        deductibleAmount: 50,
        coinsuranceAmount: 45,
        copayAmount: 0,
        checkNumber: '646095',
        checkDate: '2016-04-27T00:00:00.000Z',
        fromDate: '2016-04-05T00:00:00.000Z',
        toDate: '2016-04-05T00:00:00.000Z',
        receivedDate: '2016-04-15T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DENT',
        submittedAmount: 200,
        paidAmount: 0,
        deductibleAmount: 100,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-04-05T00:00:00.000Z',
        toDate: '2016-04-05T00:00:00.000Z',
        receivedDate: '2016-04-15T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_04262016MR 0031',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-00',
    patient: 230954,
    masterId: 'Master-000425',
    name: 'BRYAN PERSAD',
    type: 'Insured',
    reportDate: '2016-04-26T14:20:07.663Z',
    claimDetails: [
      {
        procedure: 'SURG',
        submittedAmount: 300,
        paidAmount: 201.25,
        deductibleAmount: 12.5,
        coinsuranceAmount: 86.25,
        copayAmount: 0,
        checkNumber: '646095',
        checkDate: '2016-04-27T00:00:00.000Z',
        fromDate: '2016-03-24T00:00:00.000Z',
        toDate: '2016-03-24T00:00:00.000Z',
        receivedDate: '2016-04-15T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'VISIT',
        submittedAmount: 200,
        paidAmount: 0,
        deductibleAmount: 187.5,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-03-24T00:00:00.000Z',
        toDate: '2016-03-24T00:00:00.000Z',
        receivedDate: '2016-04-15T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_04262016MR 0032',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2016-04-26T14:21:03.647Z',
    claimDetails: [
      {
        procedure: 'VISIT',
        submittedAmount: 200,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-01-04T00:00:00.000Z',
        toDate: '2016-01-04T00:00:00.000Z',
        receivedDate: '2016-04-15T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_04262016MR 0033',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-03',
    patient: 230957,
    masterId: 'Master-000425',
    name: 'ZANA-MAE PERSAD',
    type: 'Dependent',
    reportDate: '2016-04-26T14:22:35.720Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 50,
        paidAmount: 35,
        deductibleAmount: 0,
        coinsuranceAmount: 15,
        copayAmount: 0,
        checkNumber: '646095',
        checkDate: '2016-04-27T00:00:00.000Z',
        fromDate: '2016-04-05T00:00:00.000Z',
        toDate: '2016-04-05T00:00:00.000Z',
        receivedDate: '2016-04-15T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DENT',
        submittedAmount: 200,
        paidAmount: 105,
        deductibleAmount: 50,
        coinsuranceAmount: 45,
        copayAmount: 0,
        checkNumber: '646095',
        checkDate: '2016-04-27T00:00:00.000Z',
        fromDate: '2016-04-05T00:00:00.000Z',
        toDate: '2016-04-05T00:00:00.000Z',
        receivedDate: '2016-04-15T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DENT',
        submittedAmount: 200,
        paidAmount: 0,
        deductibleAmount: 100,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-04-05T00:00:00.000Z',
        toDate: '2016-04-05T00:00:00.000Z',
        receivedDate: '2016-04-15T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_04262016MR 0034',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-04',
    patient: 230958,
    masterId: 'Master-000425',
    name: 'DREW-MORGAN PERSAD',
    type: 'Dependent',
    reportDate: '2016-04-26T14:24:25.077Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 50,
        paidAmount: 35,
        deductibleAmount: 0,
        coinsuranceAmount: 15,
        copayAmount: 0,
        checkNumber: '646095',
        checkDate: '2016-04-27T00:00:00.000Z',
        fromDate: '2016-04-05T00:00:00.000Z',
        toDate: '2016-04-05T00:00:00.000Z',
        receivedDate: '2016-04-15T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DENT',
        submittedAmount: 150,
        paidAmount: 63,
        deductibleAmount: 0,
        coinsuranceAmount: 27,
        copayAmount: 0,
        checkNumber: '646095',
        checkDate: '2016-04-27T00:00:00.000Z',
        fromDate: '2016-04-05T00:00:00.000Z',
        toDate: '2016-04-05T00:00:00.000Z',
        receivedDate: '2016-04-15T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DENT',
        submittedAmount: 200,
        paidAmount: 105,
        deductibleAmount: 50,
        coinsuranceAmount: 45,
        copayAmount: 0,
        checkNumber: '646095',
        checkDate: '2016-04-27T00:00:00.000Z',
        fromDate: '2016-04-05T00:00:00.000Z',
        toDate: '2016-04-05T00:00:00.000Z',
        receivedDate: '2016-04-15T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DENT',
        submittedAmount: 200,
        paidAmount: 0,
        deductibleAmount: 100,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-04-05T00:00:00.000Z',
        toDate: '2016-04-05T00:00:00.000Z',
        receivedDate: '2016-04-15T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_05122018GED0040',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-02',
    patient: 230956,
    masterId: 'Master-000425',
    name: 'EMMA-LEE PERSAD',
    type: 'Dependent',
    reportDate: '2018-05-12T07:14:32.680Z',
    claimDetails: [
      {
        procedure: 'ACCER',
        submittedAmount: 1430.11,
        paidAmount: 1000,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 50,
        checkNumber: '2285493',
        checkDate: '2018-05-14T00:00:00.000Z',
        fromDate: '2018-04-05T00:00:00.000Z',
        toDate: '2018-04-05T00:00:00.000Z',
        receivedDate: '2018-04-17T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_05232017JJ 0080',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-05-23T11:09:43.427Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 523.8,
        paidAmount: 419.04,
        deductibleAmount: 0,
        coinsuranceAmount: 104.76,
        copayAmount: 0,
        checkNumber: '2252091',
        checkDate: '2017-05-24T00:00:00.000Z',
        fromDate: '2017-03-18T00:00:00.000Z',
        toDate: '2017-03-18T00:00:00.000Z',
        receivedDate: '2017-05-11T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_05282018JJ 0053',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-03',
    patient: 230957,
    masterId: 'Master-000425',
    name: 'ZANA-MAE PERSAD',
    type: 'Dependent',
    reportDate: '2018-05-28T16:23:56.283Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 1200,
        paidAmount: 600,
        deductibleAmount: 0,
        coinsuranceAmount: 600,
        copayAmount: 0,
        checkNumber: '836800',
        checkDate: '2018-05-29T00:00:00.000Z',
        fromDate: '2018-04-05T00:00:00.000Z',
        toDate: '2018-04-05T00:00:00.000Z',
        receivedDate: '2018-05-10T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_05282018JJ 0054',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-03',
    patient: 230957,
    masterId: 'Master-000425',
    name: 'ZANA-MAE PERSAD',
    type: 'Dependent',
    reportDate: '2018-05-28T16:25:26.490Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 1200,
        paidAmount: 600,
        deductibleAmount: 0,
        coinsuranceAmount: 600,
        copayAmount: 0,
        checkNumber: '836800',
        checkDate: '2018-05-29T00:00:00.000Z',
        fromDate: '2018-04-19T00:00:00.000Z',
        toDate: '2018-04-19T00:00:00.000Z',
        receivedDate: '2018-05-10T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_06032015GED0072',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-00',
    patient: 230954,
    masterId: 'Master-000425',
    name: 'BRYAN PERSAD',
    type: 'Insured',
    reportDate: '2015-06-03T09:06:12.827Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 4000,
        paidAmount: 3080,
        deductibleAmount: 150,
        coinsuranceAmount: 770,
        copayAmount: 0,
        checkNumber: '604474',
        checkDate: '2015-08-24T00:00:00.000Z',
        fromDate: '2015-03-14T00:00:00.000Z',
        toDate: '2015-03-14T00:00:00.000Z',
        receivedDate: '2015-06-01T00:00:00.000Z',
        provider: 'Arman Alphan-Ali',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DENT',
        submittedAmount: 850,
        paidAmount: 420,
        deductibleAmount: 0,
        coinsuranceAmount: 105,
        copayAmount: 0,
        checkNumber: '604474',
        checkDate: '2015-08-24T00:00:00.000Z',
        fromDate: '2015-03-24T00:00:00.000Z',
        toDate: '2015-03-24T00:00:00.000Z',
        receivedDate: '2015-06-01T00:00:00.000Z',
        provider: 'Arman Alphan-Ali',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_06032015GED0073',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-00',
    patient: 230954,
    masterId: 'Master-000425',
    name: 'BRYAN PERSAD',
    type: 'Insured',
    reportDate: '2015-06-03T09:23:23.267Z',
    claimDetails: [
      {
        procedure: 'LAB',
        submittedAmount: 420,
        paidAmount: 336,
        deductibleAmount: 0,
        coinsuranceAmount: 84,
        copayAmount: 0,
        checkNumber: '589506',
        checkDate: '2015-06-05T00:00:00.000Z',
        fromDate: '2015-04-22T00:00:00.000Z',
        toDate: '2015-04-22T00:00:00.000Z',
        receivedDate: '2015-06-01T00:00:00.000Z',
        provider: ' Victoria Laboratories Ltd',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'VISIT',
        submittedAmount: 400,
        paidAmount: 0,
        deductibleAmount: 187.5,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2015-04-22T00:00:00.000Z',
        toDate: '2015-04-22T00:00:00.000Z',
        receivedDate: '2015-06-01T00:00:00.000Z',
        provider: ' Victoria Laboratories Ltd',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DRUGS',
        submittedAmount: 111.71,
        paidAmount: 0,
        deductibleAmount: 111.71,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2015-05-16T00:00:00.000Z',
        toDate: '2015-05-16T00:00:00.000Z',
        receivedDate: '2015-06-01T00:00:00.000Z',
        provider: ' Victoria Laboratories Ltd',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_06092016GED0031',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-02',
    patient: 230956,
    masterId: 'Master-000425',
    name: 'EMMA-LEE PERSAD',
    type: 'Dependent',
    reportDate: '2016-06-09T09:41:21.240Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 143.8,
        paidAmount: 115.04,
        deductibleAmount: 0,
        coinsuranceAmount: 28.76,
        copayAmount: 0,
        checkNumber: '653271',
        checkDate: '2016-06-10T00:00:00.000Z',
        fromDate: '2016-04-23T00:00:00.000Z',
        toDate: '2016-04-23T00:00:00.000Z',
        receivedDate: '2016-06-02T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_06182018TSM0115',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-00',
    patient: 230954,
    masterId: 'Master-000425',
    name: 'BRYAN PERSAD',
    type: 'Insured',
    reportDate: '2018-06-18T14:20:13.280Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 397.8,
        paidAmount: 318.24,
        deductibleAmount: 0,
        coinsuranceAmount: 79.56,
        copayAmount: 0,
        checkNumber: '2290130',
        checkDate: '2018-06-20T00:00:00.000Z',
        fromDate: '2018-05-05T00:00:00.000Z',
        toDate: '2018-05-05T00:00:00.000Z',
        receivedDate: '2018-06-12T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_06262016RHR0009',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-00',
    patient: 230954,
    masterId: 'Master-000425',
    name: 'BRYAN PERSAD',
    type: 'Insured',
    reportDate: '2016-06-26T08:40:02.223Z',
    claimDetails: [
      {
        procedure: 'IVINJ',
        submittedAmount: 350,
        paidAmount: 280,
        deductibleAmount: 0,
        coinsuranceAmount: 70,
        copayAmount: 0,
        checkNumber: '655796',
        checkDate: '2016-06-27T00:00:00.000Z',
        fromDate: '2016-06-14T00:00:00.000Z',
        toDate: '2016-06-14T00:00:00.000Z',
        receivedDate: '2016-06-21T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'VISIT',
        submittedAmount: 200,
        paidAmount: 105,
        deductibleAmount: 0,
        coinsuranceAmount: 45,
        copayAmount: 0,
        checkNumber: '655796',
        checkDate: '2016-06-27T00:00:00.000Z',
        fromDate: '2016-06-14T00:00:00.000Z',
        toDate: '2016-06-14T00:00:00.000Z',
        receivedDate: '2016-06-21T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_06262016RHR0010',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-02',
    patient: 230956,
    masterId: 'Master-000425',
    name: 'EMMA-LEE PERSAD',
    type: 'Dependent',
    reportDate: '2016-06-26T08:41:12.150Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 100,
        paidAmount: 70,
        deductibleAmount: 0,
        coinsuranceAmount: 30,
        copayAmount: 0,
        checkNumber: '655796',
        checkDate: '2016-06-27T00:00:00.000Z',
        fromDate: '2016-04-18T00:00:00.000Z',
        toDate: '2016-04-18T00:00:00.000Z',
        receivedDate: '2016-06-21T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DENT',
        submittedAmount: 150,
        paidAmount: 64.4,
        deductibleAmount: 0,
        coinsuranceAmount: 27.6,
        copayAmount: 0,
        checkNumber: '655796',
        checkDate: '2016-06-27T00:00:00.000Z',
        fromDate: '2016-04-18T00:00:00.000Z',
        toDate: '2016-04-18T00:00:00.000Z',
        receivedDate: '2016-06-21T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_06262016RHR0011',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-04',
    patient: 230958,
    masterId: 'Master-000425',
    name: 'DREW-MORGAN PERSAD',
    type: 'Dependent',
    reportDate: '2016-06-26T08:42:50.257Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 100,
        paidAmount: 70,
        deductibleAmount: 0,
        coinsuranceAmount: 30,
        copayAmount: 0,
        checkNumber: '655796',
        checkDate: '2016-06-27T00:00:00.000Z',
        fromDate: '2016-05-02T00:00:00.000Z',
        toDate: '2016-05-02T00:00:00.000Z',
        receivedDate: '2016-06-21T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DENT',
        submittedAmount: 400,
        paidAmount: 280,
        deductibleAmount: 0,
        coinsuranceAmount: 120,
        copayAmount: 0,
        checkNumber: '655796',
        checkDate: '2016-06-27T00:00:00.000Z',
        fromDate: '2016-05-02T00:00:00.000Z',
        toDate: '2016-05-02T00:00:00.000Z',
        receivedDate: '2016-06-21T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_06262016RHR0012',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-03',
    patient: 230957,
    masterId: 'Master-000425',
    name: 'ZANA-MAE PERSAD',
    type: 'Dependent',
    reportDate: '2016-06-26T09:03:00.977Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 100,
        paidAmount: 70,
        deductibleAmount: 0,
        coinsuranceAmount: 30,
        copayAmount: 0,
        checkNumber: '655796',
        checkDate: '2016-06-27T00:00:00.000Z',
        fromDate: '2016-06-10T00:00:00.000Z',
        toDate: '2016-06-10T00:00:00.000Z',
        receivedDate: '2016-06-21T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DENT',
        submittedAmount: 150,
        paidAmount: 64.4,
        deductibleAmount: 0,
        coinsuranceAmount: 27.6,
        copayAmount: 0,
        checkNumber: '655796',
        checkDate: '2016-06-27T00:00:00.000Z',
        fromDate: '2016-06-10T00:00:00.000Z',
        toDate: '2016-06-10T00:00:00.000Z',
        receivedDate: '2016-06-21T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DENT',
        submittedAmount: 300,
        paidAmount: 210,
        deductibleAmount: 0,
        coinsuranceAmount: 90,
        copayAmount: 0,
        checkNumber: '655796',
        checkDate: '2016-06-27T00:00:00.000Z',
        fromDate: '2016-06-10T00:00:00.000Z',
        toDate: '2016-06-10T00:00:00.000Z',
        receivedDate: '2016-06-21T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_06262017GED0094',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-06-26T23:28:13.357Z',
    claimDetails: [
      {
        procedure: 'HCPC',
        submittedAmount: 488.88,
        paidAmount: 391.1,
        deductibleAmount: 0,
        coinsuranceAmount: 97.78,
        copayAmount: 0,
        checkNumber: '2254830',
        checkDate: '2017-06-27T00:00:00.000Z',
        fromDate: '2017-05-09T00:00:00.000Z',
        toDate: '2017-05-09T00:00:00.000Z',
        receivedDate: '2017-06-13T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_07152016MR 0011',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2016-07-15T16:04:58.517Z',
    claimDetails: [
      {
        procedure: 'LAB',
        submittedAmount: 1000,
        paidAmount: 700,
        deductibleAmount: 0,
        coinsuranceAmount: 300,
        copayAmount: 0,
        checkNumber: '658926',
        checkDate: '2016-07-18T00:00:00.000Z',
        fromDate: '2016-02-16T00:00:00.000Z',
        toDate: '2016-02-16T00:00:00.000Z',
        receivedDate: '2016-06-28T00:00:00.000Z',
        provider: ' Western Medical Radiology',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_07182017MR 0116',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-03',
    patient: 230957,
    masterId: 'Master-000425',
    name: 'ZANA-MAE PERSAD',
    type: 'Dependent',
    reportDate: '2017-07-18T16:24:24.863Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 1200,
        paidAmount: 600,
        deductibleAmount: 0,
        coinsuranceAmount: 600,
        copayAmount: 0,
        checkNumber: '804142',
        checkDate: '2017-07-19T00:00:00.000Z',
        fromDate: '2017-06-22T00:00:00.000Z',
        toDate: '2017-06-22T00:00:00.000Z',
        receivedDate: '2017-07-12T00:00:00.000Z',
        provider: ' Dental Provider Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_07182017MR 0117',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-04',
    patient: 230958,
    masterId: 'Master-000425',
    name: 'DREW-MORGAN PERSAD',
    type: 'Dependent',
    reportDate: '2017-07-18T16:25:09.720Z',
    claimDetails: [
      {
        procedure: 'FRAME',
        submittedAmount: 245,
        paidAmount: 76,
        deductibleAmount: 150,
        coinsuranceAmount: 19,
        copayAmount: 0,
        checkNumber: '804142',
        checkDate: '2017-07-19T00:00:00.000Z',
        fromDate: '2017-05-24T00:00:00.000Z',
        toDate: '2017-05-24T00:00:00.000Z',
        receivedDate: '2017-07-12T00:00:00.000Z',
        provider: ' Optical In Network - Subscriber',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'LENS',
        submittedAmount: 450,
        paidAmount: 360,
        deductibleAmount: 0,
        coinsuranceAmount: 90,
        copayAmount: 0,
        checkNumber: '804142',
        checkDate: '2017-07-19T00:00:00.000Z',
        fromDate: '2017-05-24T00:00:00.000Z',
        toDate: '2017-05-24T00:00:00.000Z',
        receivedDate: '2017-07-12T00:00:00.000Z',
        provider: ' Optical In Network - Subscriber',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_07312017MR 0026',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-07-31T17:44:02.520Z',
    claimDetails: [
      {
        procedure: 'IVINJ',
        submittedAmount: 150,
        paidAmount: 120,
        deductibleAmount: 0,
        coinsuranceAmount: 30,
        copayAmount: 0,
        checkNumber: '805556',
        checkDate: '2017-08-02T00:00:00.000Z',
        fromDate: '2017-05-01T00:00:00.000Z',
        toDate: '2017-05-01T00:00:00.000Z',
        receivedDate: '2017-07-21T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'VISIT',
        submittedAmount: 200,
        paidAmount: 105,
        deductibleAmount: 0,
        coinsuranceAmount: 45,
        copayAmount: 0,
        checkNumber: '805556',
        checkDate: '2017-08-02T00:00:00.000Z',
        fromDate: '2017-05-01T00:00:00.000Z',
        toDate: '2017-05-01T00:00:00.000Z',
        receivedDate: '2017-07-21T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_07312017MR 0027',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-07-31T17:45:23.520Z',
    claimDetails: [
      {
        procedure: 'THERP',
        submittedAmount: 400,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2017-03-28T00:00:00.000Z',
        toDate: '2017-03-28T00:00:00.000Z',
        receivedDate: '2017-07-21T00:00:00.000Z',
        provider: ' Bosscher Physiotherapy',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'THERP',
        submittedAmount: 340,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2017-04-05T00:00:00.000Z',
        toDate: '2017-04-05T00:00:00.000Z',
        receivedDate: '2017-07-21T00:00:00.000Z',
        provider: ' Bosscher Physiotherapy',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'THERP',
        submittedAmount: 340,
        paidAmount: 272,
        deductibleAmount: 0,
        coinsuranceAmount: 68,
        copayAmount: 0,
        checkNumber: '805556',
        checkDate: '2017-08-02T00:00:00.000Z',
        fromDate: '2017-04-19T00:00:00.000Z',
        toDate: '2017-04-19T00:00:00.000Z',
        receivedDate: '2017-07-21T00:00:00.000Z',
        provider: ' Bosscher Physiotherapy',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'THERP',
        submittedAmount: 340,
        paidAmount: 272,
        deductibleAmount: 0,
        coinsuranceAmount: 68,
        copayAmount: 0,
        checkNumber: '805556',
        checkDate: '2017-08-02T00:00:00.000Z',
        fromDate: '2017-04-25T00:00:00.000Z',
        toDate: '2017-04-25T00:00:00.000Z',
        receivedDate: '2017-07-21T00:00:00.000Z',
        provider: ' Bosscher Physiotherapy',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_07312017MR 0028',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-07-31T17:46:37.503Z',
    claimDetails: [
      {
        procedure: 'SPEC',
        submittedAmount: 500,
        paidAmount: 300,
        deductibleAmount: 0,
        coinsuranceAmount: 128.57,
        copayAmount: 0,
        checkNumber: '805556',
        checkDate: '2017-08-02T00:00:00.000Z',
        fromDate: '2017-06-10T00:00:00.000Z',
        toDate: '2017-06-10T00:00:00.000Z',
        receivedDate: '2017-07-21T00:00:00.000Z',
        provider: ' Psychiatrist Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_08092016SAL0012',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-03',
    patient: 230957,
    masterId: 'Master-000425',
    name: 'ZANA-MAE PERSAD',
    type: 'Dependent',
    reportDate: '2016-08-09T11:41:12.423Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 400,
        paidAmount: 210,
        deductibleAmount: 0,
        coinsuranceAmount: 90,
        copayAmount: 0,
        checkNumber: '662710',
        checkDate: '2016-08-11T00:00:00.000Z',
        fromDate: '2016-08-03T00:00:00.000Z',
        toDate: '2016-08-03T00:00:00.000Z',
        receivedDate: '2016-08-05T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_08092016SAL0013',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-00',
    patient: 230954,
    masterId: 'Master-000425',
    name: 'BRYAN PERSAD',
    type: 'Insured',
    reportDate: '2016-08-09T11:52:25.750Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 75,
        paidAmount: 0,
        deductibleAmount: 75,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-07-11T00:00:00.000Z',
        toDate: '2016-07-11T00:00:00.000Z',
        receivedDate: '2016-08-05T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DENT',
        submittedAmount: 4500,
        paidAmount: 1907.5,
        deductibleAmount: 75,
        coinsuranceAmount: 817.5,
        copayAmount: 0,
        checkNumber: '662710',
        checkDate: '2016-08-11T00:00:00.000Z',
        fromDate: '2016-07-14T00:00:00.000Z',
        toDate: '2016-07-18T00:00:00.000Z',
        receivedDate: '2016-08-05T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_08102016SAL0005',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-03',
    patient: 230957,
    masterId: 'Master-000425',
    name: 'ZANA-MAE PERSAD',
    type: 'Dependent',
    reportDate: '2016-08-10T09:29:09.920Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 3250,
        paidAmount: 1625,
        deductibleAmount: 0,
        coinsuranceAmount: 1625,
        copayAmount: 0,
        checkNumber: '662867',
        checkDate: '2016-08-12T00:00:00.000Z',
        fromDate: '2016-07-22T00:00:00.000Z',
        toDate: '2016-07-22T00:00:00.000Z',
        receivedDate: '2016-08-05T00:00:00.000Z',
        provider: ' Orthodontist Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_09062016GED0085',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2016-09-06T12:50:33.687Z',
    claimDetails: [
      {
        procedure: 'FRAME',
        submittedAmount: 1125,
        paidAmount: 780,
        deductibleAmount: 150,
        coinsuranceAmount: 195,
        copayAmount: 0,
        checkNumber: '667124',
        checkDate: '2016-09-08T00:00:00.000Z',
        fromDate: '2016-06-15T00:00:00.000Z',
        toDate: '2016-06-15T00:00:00.000Z',
        receivedDate: '2016-08-25T00:00:00.000Z',
        provider: ' Optical In Network - Subscriber',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'LENS',
        submittedAmount: 2115,
        paidAmount: 720,
        deductibleAmount: 0,
        coinsuranceAmount: 180,
        copayAmount: 0,
        checkNumber: '667124',
        checkDate: '2016-09-08T00:00:00.000Z',
        fromDate: '2016-06-15T00:00:00.000Z',
        toDate: '2016-06-15T00:00:00.000Z',
        receivedDate: '2016-08-25T00:00:00.000Z',
        provider: ' Optical In Network - Subscriber',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_09082017JJ 0078',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-09-08T12:33:21.530Z',
    claimDetails: [
      {
        procedure: 'VISIT',
        submittedAmount: 500,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2017-05-06T00:00:00.000Z',
        toDate: '2017-05-06T00:00:00.000Z',
        receivedDate: '2017-08-25T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_09082017JJ 0079',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-03',
    patient: 230957,
    masterId: 'Master-000425',
    name: 'ZANA-MAE PERSAD',
    type: 'Dependent',
    reportDate: '2017-09-08T12:35:31.927Z',
    claimDetails: [
      {
        procedure: 'SURG',
        submittedAmount: 300,
        paidAmount: 0,
        deductibleAmount: 300,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2017-08-12T00:00:00.000Z',
        toDate: '2017-08-12T00:00:00.000Z',
        receivedDate: '2017-08-25T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'VISIT',
        submittedAmount: 200,
        paidAmount: 0,
        deductibleAmount: 187.5,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2017-08-12T00:00:00.000Z',
        toDate: '2017-08-12T00:00:00.000Z',
        receivedDate: '2017-08-25T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_09082017JJ 0080',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-04',
    patient: 230958,
    masterId: 'Master-000425',
    name: 'DREW-MORGAN PERSAD',
    type: 'Dependent',
    reportDate: '2017-09-08T12:40:10.523Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 200,
        paidAmount: 160,
        deductibleAmount: 0,
        coinsuranceAmount: 40,
        copayAmount: 0,
        checkNumber: '809429',
        checkDate: '2017-09-11T00:00:00.000Z',
        fromDate: '2017-07-11T00:00:00.000Z',
        toDate: '2017-07-11T00:00:00.000Z',
        receivedDate: '2017-08-25T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'VISIT',
        submittedAmount: 200,
        paidAmount: 52.5,
        deductibleAmount: 112.5,
        coinsuranceAmount: 22.5,
        copayAmount: 0,
        checkNumber: '809429',
        checkDate: '2017-09-11T00:00:00.000Z',
        fromDate: '2017-07-11T00:00:00.000Z',
        toDate: '2017-07-11T00:00:00.000Z',
        receivedDate: '2017-08-25T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_09082017JJ 0081',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-00',
    patient: 230954,
    masterId: 'Master-000425',
    name: 'BRYAN PERSAD',
    type: 'Insured',
    reportDate: '2017-09-08T12:52:51.573Z',
    claimDetails: [
      {
        procedure: 'LAB',
        submittedAmount: 1050,
        paidAmount: 735,
        deductibleAmount: 0,
        coinsuranceAmount: 315,
        copayAmount: 0,
        checkNumber: '809429',
        checkDate: '2017-09-11T00:00:00.000Z',
        fromDate: '2017-08-09T00:00:00.000Z',
        toDate: '2017-08-09T00:00:00.000Z',
        receivedDate: '2017-08-25T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'VISIT',
        submittedAmount: 200,
        paidAmount: 105,
        deductibleAmount: 0,
        coinsuranceAmount: 45,
        copayAmount: 0,
        checkNumber: '809429',
        checkDate: '2017-09-11T00:00:00.000Z',
        fromDate: '2017-08-09T00:00:00.000Z',
        toDate: '2017-08-09T00:00:00.000Z',
        receivedDate: '2017-08-25T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_09192017GED0031',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-03',
    patient: 230957,
    masterId: 'Master-000425',
    name: 'ZANA-MAE PERSAD',
    type: 'Dependent',
    reportDate: '2017-09-19T06:14:23.037Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 1200,
        paidAmount: 600,
        deductibleAmount: 0,
        coinsuranceAmount: 600,
        copayAmount: 0,
        checkNumber: '810474',
        checkDate: '2017-09-20T00:00:00.000Z',
        fromDate: '2017-07-20T00:00:00.000Z',
        toDate: '2017-07-20T00:00:00.000Z',
        receivedDate: '2017-09-06T00:00:00.000Z',
        provider: ' Dental Provider Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_10122015SC 0013',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-00',
    patient: 230954,
    masterId: 'Master-000425',
    name: 'BRYAN PERSAD',
    type: 'Insured',
    reportDate: '2015-10-12T11:36:55.820Z',
    claimDetails: [
      {
        procedure: 'HCPC',
        submittedAmount: 50,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2015-09-28T00:00:00.000Z',
        toDate: '2015-09-28T00:00:00.000Z',
        receivedDate: '2015-10-09T00:00:00.000Z',
        provider: 'Gerard Farfan',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'HMISC',
        submittedAmount: 1200,
        paidAmount: 840,
        deductibleAmount: 0,
        coinsuranceAmount: 360,
        copayAmount: 0,
        checkNumber: '613204',
        checkDate: '2015-10-14T00:00:00.000Z',
        fromDate: '2015-09-28T00:00:00.000Z',
        toDate: '2015-09-28T00:00:00.000Z',
        receivedDate: '2015-10-09T00:00:00.000Z',
        provider: 'Gerard Farfan',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'SURG',
        submittedAmount: 2950,
        paidAmount: 1924.45,
        deductibleAmount: 200.79,
        coinsuranceAmount: 824.76,
        copayAmount: 0,
        checkNumber: '613204',
        checkDate: '2015-10-14T00:00:00.000Z',
        fromDate: '2015-09-28T00:00:00.000Z',
        toDate: '2015-09-28T00:00:00.000Z',
        receivedDate: '2015-10-09T00:00:00.000Z',
        provider: 'Gerard Farfan',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_10122015SC 0014',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-02',
    patient: 230956,
    masterId: 'Master-000425',
    name: 'EMMA-LEE PERSAD',
    type: 'Dependent',
    reportDate: '2015-10-12T11:50:49.580Z',
    claimDetails: [
      {
        procedure: 'LAB',
        submittedAmount: 1790,
        paidAmount: 1203.01,
        deductibleAmount: 71.42,
        coinsuranceAmount: 515.57,
        copayAmount: 0,
        checkNumber: '613204',
        checkDate: '2015-10-14T00:00:00.000Z',
        fromDate: '2015-08-17T00:00:00.000Z',
        toDate: '2015-08-17T00:00:00.000Z',
        receivedDate: '2015-10-09T00:00:00.000Z',
        provider: ' Paediatrician Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'SPEC',
        submittedAmount: 500,
        paidAmount: 0,
        deductibleAmount: 428.58,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2015-08-17T00:00:00.000Z',
        toDate: '2015-08-17T00:00:00.000Z',
        receivedDate: '2015-10-09T00:00:00.000Z',
        provider: ' Paediatrician Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_10122015SC 0015',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2015-10-12T11:54:25.877Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 900,
        paidAmount: 380,
        deductibleAmount: 150,
        coinsuranceAmount: 95,
        copayAmount: 0,
        checkNumber: '613204',
        checkDate: '2015-10-14T00:00:00.000Z',
        fromDate: '2015-09-03T00:00:00.000Z',
        toDate: '2015-09-03T00:00:00.000Z',
        receivedDate: '2015-10-09T00:00:00.000Z',
        provider: 'Arman Alphan-Ali',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_10122015SC 0016',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2015-10-12T11:57:58.777Z',
    claimDetails: [
      {
        procedure: 'LAB',
        submittedAmount: 800,
        paidAmount: 560,
        deductibleAmount: 0,
        coinsuranceAmount: 240,
        copayAmount: 0,
        checkNumber: '613204',
        checkDate: '2015-10-14T00:00:00.000Z',
        fromDate: '2015-08-19T00:00:00.000Z',
        toDate: '2015-08-19T00:00:00.000Z',
        receivedDate: '2015-10-09T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'VISIT',
        submittedAmount: 200,
        paidAmount: 105,
        deductibleAmount: 0,
        coinsuranceAmount: 45,
        copayAmount: 0,
        checkNumber: '613204',
        checkDate: '2015-10-14T00:00:00.000Z',
        fromDate: '2015-08-19T00:00:00.000Z',
        toDate: '2015-08-19T00:00:00.000Z',
        receivedDate: '2015-10-09T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_10122015SC 0017',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-00',
    patient: 230954,
    masterId: 'Master-000425',
    name: 'BRYAN PERSAD',
    type: 'Insured',
    reportDate: '2015-10-12T12:04:50.307Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 152.5,
        paidAmount: 122,
        deductibleAmount: 0,
        coinsuranceAmount: 30.5,
        copayAmount: 0,
        checkNumber: '613204',
        checkDate: '2015-10-14T00:00:00.000Z',
        fromDate: '2015-08-18T00:00:00.000Z',
        toDate: '2015-08-18T00:00:00.000Z',
        receivedDate: '2015-10-09T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'VISIT',
        submittedAmount: 200,
        paidAmount: 105,
        deductibleAmount: 0,
        coinsuranceAmount: 45,
        copayAmount: 0,
        checkNumber: '613204',
        checkDate: '2015-10-14T00:00:00.000Z',
        fromDate: '2015-08-18T00:00:00.000Z',
        toDate: '2015-08-18T00:00:00.000Z',
        receivedDate: '2015-10-09T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'LAB',
        submittedAmount: 400,
        paidAmount: 280,
        deductibleAmount: 0,
        coinsuranceAmount: 120,
        copayAmount: 0,
        checkNumber: '613204',
        checkDate: '2015-10-14T00:00:00.000Z',
        fromDate: '2015-08-19T00:00:00.000Z',
        toDate: '2015-08-19T00:00:00.000Z',
        receivedDate: '2015-10-09T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'VISIT',
        submittedAmount: 200,
        paidAmount: 105,
        deductibleAmount: 0,
        coinsuranceAmount: 45,
        copayAmount: 0,
        checkNumber: '613204',
        checkDate: '2015-10-14T00:00:00.000Z',
        fromDate: '2015-08-19T00:00:00.000Z',
        toDate: '2015-08-19T00:00:00.000Z',
        receivedDate: '2015-10-09T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_10122015SC 0018',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-02',
    patient: 230956,
    masterId: 'Master-000425',
    name: 'EMMA-LEE PERSAD',
    type: 'Dependent',
    reportDate: '2015-10-12T12:08:47.213Z',
    claimDetails: [
      {
        procedure: 'LAB',
        submittedAmount: 2000,
        paidAmount: 1400,
        deductibleAmount: 0,
        coinsuranceAmount: 600,
        copayAmount: 0,
        checkNumber: '613204',
        checkDate: '2015-10-14T00:00:00.000Z',
        fromDate: '2015-09-17T00:00:00.000Z',
        toDate: '2015-09-17T00:00:00.000Z',
        receivedDate: '2015-10-09T00:00:00.000Z',
        provider: ' Paediatrician Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'SPEC',
        submittedAmount: 400,
        paidAmount: 280,
        deductibleAmount: 0,
        coinsuranceAmount: 120,
        copayAmount: 0,
        checkNumber: '613204',
        checkDate: '2015-10-14T00:00:00.000Z',
        fromDate: '2015-09-17T00:00:00.000Z',
        toDate: '2015-09-17T00:00:00.000Z',
        receivedDate: '2015-10-09T00:00:00.000Z',
        provider: ' Paediatrician Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_10232017VD 0037',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-00',
    patient: 230954,
    masterId: 'Master-000425',
    name: 'BRYAN PERSAD',
    type: 'Insured',
    reportDate: '2017-10-23T14:14:50.000Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 10.5,
        paidAmount: 8.4,
        deductibleAmount: 0,
        coinsuranceAmount: 2.1,
        copayAmount: 0,
        checkNumber: '2264744',
        checkDate: '2017-10-24T00:00:00.000Z',
        fromDate: '2017-08-12T00:00:00.000Z',
        toDate: '2017-08-12T00:00:00.000Z',
        receivedDate: '2017-10-10T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DRUGS',
        submittedAmount: 397.8,
        paidAmount: 318.24,
        deductibleAmount: 0,
        coinsuranceAmount: 79.56,
        copayAmount: 0,
        checkNumber: '2264744',
        checkDate: '2017-10-24T00:00:00.000Z',
        fromDate: '2017-08-12T00:00:00.000Z',
        toDate: '2017-08-12T00:00:00.000Z',
        receivedDate: '2017-10-10T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_11022016ARA0004',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-02',
    patient: 230956,
    masterId: 'Master-000425',
    name: 'EMMA-LEE PERSAD',
    type: 'Dependent',
    reportDate: '2016-11-02T11:43:26.253Z',
    claimDetails: [
      {
        submittedAmount: 0,
        paidAmount: -707.7,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        checkNumber: '674733',
        checkDate: '2016-11-03T00:00:00.000Z',
        fromDate: '2016-10-20T00:00:00.000Z',
        toDate: '2016-10-20T00:00:00.000Z',
        receivedDate: '2016-10-31T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
      {
        submittedAmount: 0,
        paidAmount: 707.7,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        checkNumber: '674724',
        checkDate: '2016-11-03T00:00:00.000Z',
        fromDate: '2016-10-20T00:00:00.000Z',
        toDate: '2016-10-20T00:00:00.000Z',
        receivedDate: '2016-10-31T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'ACCER',
        submittedAmount: 955.7,
        paidAmount: 905.7,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 50,
        checkNumber: '674733',
        checkDate: '2016-11-03T00:00:00.000Z',
        fromDate: '2016-10-20T00:00:00.000Z',
        toDate: '2016-10-20T00:00:00.000Z',
        receivedDate: '2016-10-31T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'LAB',
        submittedAmount: 940,
        paidAmount: 752,
        deductibleAmount: 0,
        coinsuranceAmount: 188,
        copayAmount: 0,
        checkNumber: '674733',
        checkDate: '2016-11-03T00:00:00.000Z',
        fromDate: '2016-10-20T00:00:00.000Z',
        toDate: '2016-10-20T00:00:00.000Z',
        receivedDate: '2016-10-31T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_11022016VD 0029',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-00',
    patient: 230954,
    masterId: 'Master-000425',
    name: 'BRYAN PERSAD',
    type: 'Insured',
    reportDate: '2016-11-02T08:01:58.237Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 550,
        paidAmount: 280,
        deductibleAmount: 0,
        coinsuranceAmount: 120,
        copayAmount: 0,
        checkNumber: '674849',
        checkDate: '2016-11-04T00:00:00.000Z',
        fromDate: '2016-10-10T00:00:00.000Z',
        toDate: '2016-10-10T00:00:00.000Z',
        receivedDate: '2016-10-31T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DENT',
        submittedAmount: 650,
        paidAmount: 455,
        deductibleAmount: 0,
        coinsuranceAmount: 195,
        copayAmount: 0,
        checkNumber: '674849',
        checkDate: '2016-11-04T00:00:00.000Z',
        fromDate: '2016-10-10T00:00:00.000Z',
        toDate: '2016-10-10T00:00:00.000Z',
        receivedDate: '2016-10-31T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_11022016VD 0030',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-02',
    patient: 230956,
    masterId: 'Master-000425',
    name: 'EMMA-LEE PERSAD',
    type: 'Dependent',
    reportDate: '2016-11-02T08:08:40.113Z',
    claimDetails: [
      {
        procedure: 'ACCER',
        submittedAmount: -955.7,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-10-20T00:00:00.000Z',
        toDate: '2016-10-20T00:00:00.000Z',
        receivedDate: '2016-10-31T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'ACCER',
        submittedAmount: 955.7,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-10-20T00:00:00.000Z',
        toDate: '2016-10-20T00:00:00.000Z',
        receivedDate: '2016-10-31T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'HMISC',
        submittedAmount: -1438.8,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-10-20T00:00:00.000Z',
        toDate: '2016-10-20T00:00:00.000Z',
        receivedDate: '2016-10-31T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'HMISC',
        submittedAmount: 1438.8,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-10-20T00:00:00.000Z',
        toDate: '2016-10-20T00:00:00.000Z',
        receivedDate: '2016-10-31T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'LAB',
        submittedAmount: -940,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-10-20T00:00:00.000Z',
        toDate: '2016-10-20T00:00:00.000Z',
        receivedDate: '2016-10-31T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'LAB',
        submittedAmount: 940,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-10-20T00:00:00.000Z',
        toDate: '2016-10-20T00:00:00.000Z',
        receivedDate: '2016-10-31T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'R&B',
        submittedAmount: -1650,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-10-20T00:00:00.000Z',
        toDate: '2016-10-20T00:00:00.000Z',
        receivedDate: '2016-10-31T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'R&B',
        submittedAmount: 1650,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-10-20T00:00:00.000Z',
        toDate: '2016-10-20T00:00:00.000Z',
        receivedDate: '2016-10-31T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'SPEC',
        submittedAmount: -1000,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-10-20T00:00:00.000Z',
        toDate: '2016-10-20T00:00:00.000Z',
        receivedDate: '2016-10-31T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'SPEC',
        submittedAmount: 1000,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-10-20T00:00:00.000Z',
        toDate: '2016-10-20T00:00:00.000Z',
        receivedDate: '2016-10-31T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_11102017JJ 0100',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-00',
    patient: 230954,
    masterId: 'Master-000425',
    name: 'BRYAN PERSAD',
    type: 'Insured',
    reportDate: '2017-11-10T20:10:41.603Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 31.5,
        paidAmount: 25.2,
        deductibleAmount: 0,
        coinsuranceAmount: 6.3,
        copayAmount: 0,
        checkNumber: '2266202',
        checkDate: '2017-11-14T00:00:00.000Z',
        fromDate: '2017-09-24T00:00:00.000Z',
        toDate: '2017-09-24T00:00:00.000Z',
        receivedDate: '2017-10-18T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DRUGS',
        submittedAmount: 179.01,
        paidAmount: 143.21,
        deductibleAmount: 0,
        coinsuranceAmount: 35.8,
        copayAmount: 0,
        checkNumber: '2266202',
        checkDate: '2017-11-14T00:00:00.000Z',
        fromDate: '2017-09-24T00:00:00.000Z',
        toDate: '2017-09-24T00:00:00.000Z',
        receivedDate: '2017-10-18T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_11102017JJ 0101',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-01',
    patient: 230955,
    masterId: 'Master-000425',
    name: 'MARIE-LOUISE PERSAD',
    type: 'Dependent',
    reportDate: '2017-11-10T20:12:51.463Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 204,
        paidAmount: 163.2,
        deductibleAmount: 0,
        coinsuranceAmount: 40.8,
        copayAmount: 0,
        checkNumber: '2266202',
        checkDate: '2017-11-14T00:00:00.000Z',
        fromDate: '2017-09-24T00:00:00.000Z',
        toDate: '2017-09-24T00:00:00.000Z',
        receivedDate: '2017-10-18T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_11152017MR 0067',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-00',
    patient: 230954,
    masterId: 'Master-000425',
    name: 'BRYAN PERSAD',
    type: 'Insured',
    reportDate: '2017-11-15T16:22:37.113Z',
    claimDetails: [
      {
        procedure: 'LAB',
        submittedAmount: 460,
        paidAmount: 322,
        deductibleAmount: 0,
        coinsuranceAmount: 138,
        copayAmount: 0,
        checkNumber: '815077',
        checkDate: '2017-11-16T00:00:00.000Z',
        fromDate: '2017-09-11T00:00:00.000Z',
        toDate: '2017-09-11T00:00:00.000Z',
        receivedDate: '2017-10-25T00:00:00.000Z',
        provider: ' Westshore Medical Radiology Service',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'VISIT',
        submittedAmount: 450,
        paidAmount: 105,
        deductibleAmount: 0,
        coinsuranceAmount: 45,
        copayAmount: 0,
        checkNumber: '815077',
        checkDate: '2017-11-16T00:00:00.000Z',
        fromDate: '2017-09-11T00:00:00.000Z',
        toDate: '2017-09-11T00:00:00.000Z',
        receivedDate: '2017-10-25T00:00:00.000Z',
        provider: ' Westshore Medical Radiology Service',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'LAB',
        submittedAmount: 3800,
        paidAmount: 2660,
        deductibleAmount: 0,
        coinsuranceAmount: 1140,
        copayAmount: 0,
        checkNumber: '815077',
        checkDate: '2017-11-16T00:00:00.000Z',
        fromDate: '2017-09-18T00:00:00.000Z',
        toDate: '2017-09-18T00:00:00.000Z',
        receivedDate: '2017-10-25T00:00:00.000Z',
        provider: ' Westshore Medical Radiology Service',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_11152017MR 0068',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-03',
    patient: 230957,
    masterId: 'Master-000425',
    name: 'ZANA-MAE PERSAD',
    type: 'Dependent',
    reportDate: '2017-11-15T16:23:23.207Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 1200,
        paidAmount: 600,
        deductibleAmount: 0,
        coinsuranceAmount: 600,
        copayAmount: 0,
        checkNumber: '815077',
        checkDate: '2017-11-16T00:00:00.000Z',
        fromDate: '2017-09-01T00:00:00.000Z',
        toDate: '2017-09-01T00:00:00.000Z',
        receivedDate: '2017-10-25T00:00:00.000Z',
        provider: ' Dental Provider Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_11152017MR 0069',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-03',
    patient: 230957,
    masterId: 'Master-000425',
    name: 'ZANA-MAE PERSAD',
    type: 'Dependent',
    reportDate: '2017-11-15T16:24:02.280Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 1200,
        paidAmount: 575,
        deductibleAmount: 0,
        coinsuranceAmount: 575,
        copayAmount: 0,
        checkNumber: '815077',
        checkDate: '2017-11-16T00:00:00.000Z',
        fromDate: '2017-10-19T00:00:00.000Z',
        toDate: '2017-10-19T00:00:00.000Z',
        receivedDate: '2017-10-25T00:00:00.000Z',
        provider: ' Dental Provider Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_11152017MR 0070',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-00',
    patient: 230954,
    masterId: 'Master-000425',
    name: 'BRYAN PERSAD',
    type: 'Insured',
    reportDate: '2017-11-15T16:25:32.260Z',
    claimDetails: [
      {
        procedure: 'VISIT',
        submittedAmount: 500,
        paidAmount: 105,
        deductibleAmount: 0,
        coinsuranceAmount: 45,
        copayAmount: 0,
        checkNumber: '815077',
        checkDate: '2017-11-16T00:00:00.000Z',
        fromDate: '2017-09-02T00:00:00.000Z',
        toDate: '2017-09-02T00:00:00.000Z',
        receivedDate: '2017-10-25T00:00:00.000Z',
        provider: ' Psychiatrist Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_11192017HXF0017',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-02',
    patient: 230956,
    masterId: 'Master-000425',
    name: 'EMMA-LEE PERSAD',
    type: 'Dependent',
    reportDate: '2017-11-19T15:21:07.567Z',
    claimDetails: [
      {
        procedure: 'VISIT',
        submittedAmount: 200,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2017-10-19T00:00:00.000Z',
        toDate: '2017-10-19T00:00:00.000Z',
        receivedDate: '2017-11-16T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_11192017HXF0018',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-02',
    patient: 230956,
    masterId: 'Master-000425',
    name: 'EMMA-LEE PERSAD',
    type: 'Dependent',
    reportDate: '2017-11-19T15:25:35.717Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 582,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2017-10-26T00:00:00.000Z',
        toDate: '2017-10-26T00:00:00.000Z',
        receivedDate: '2017-11-16T00:00:00.000Z',
        provider: 'Anilla Jaggat',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'SPEC',
        submittedAmount: 500,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2017-10-26T00:00:00.000Z',
        toDate: '2017-10-26T00:00:00.000Z',
        receivedDate: '2017-11-16T00:00:00.000Z',
        provider: 'Anilla Jaggat',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_11192017HXF0019',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-02',
    patient: 230956,
    masterId: 'Master-000425',
    name: 'EMMA-LEE PERSAD',
    type: 'Dependent',
    reportDate: '2017-11-19T15:34:06.143Z',
    claimDetails: [
      {
        procedure: 'LAB',
        submittedAmount: 1060,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2017-10-26T00:00:00.000Z',
        toDate: '2017-10-26T00:00:00.000Z',
        receivedDate: '2017-11-16T00:00:00.000Z',
        provider: ' Westshore Medical Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_11232016MR 0183',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-03',
    patient: 230957,
    masterId: 'Master-000425',
    name: 'ZANA-MAE PERSAD',
    type: 'Dependent',
    reportDate: '2016-11-23T16:22:01.510Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 1200,
        paidAmount: 370.6,
        deductibleAmount: 0,
        coinsuranceAmount: 370.6,
        copayAmount: 0,
        checkNumber: '677679',
        checkDate: '2016-11-25T00:00:00.000Z',
        fromDate: '2016-10-12T00:00:00.000Z',
        toDate: '2016-10-12T00:00:00.000Z',
        receivedDate: '2016-11-18T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_11232016MR 0184',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-03',
    patient: 230957,
    masterId: 'Master-000425',
    name: 'ZANA-MAE PERSAD',
    type: 'Dependent',
    reportDate: '2016-11-23T16:22:40.810Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 1200,
        paidAmount: 600,
        deductibleAmount: 0,
        coinsuranceAmount: 600,
        copayAmount: 0,
        checkNumber: '677679',
        checkDate: '2016-11-25T00:00:00.000Z',
        fromDate: '2016-11-10T00:00:00.000Z',
        toDate: '2016-11-10T00:00:00.000Z',
        receivedDate: '2016-11-18T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_11232016MR 0185',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-03',
    patient: 230957,
    masterId: 'Master-000425',
    name: 'ZANA-MAE PERSAD',
    type: 'Dependent',
    reportDate: '2016-11-23T16:24:27.690Z',
    claimDetails: [
      {
        procedure: 'DENT',
        submittedAmount: 11000,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-08-17T00:00:00.000Z',
        toDate: '2016-08-17T00:00:00.000Z',
        receivedDate: '2016-11-18T00:00:00.000Z',
        provider: ' Dental Provisor (Generic)',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_11242016MR 0158',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-02',
    patient: 230956,
    masterId: 'Master-000425',
    name: 'EMMA-LEE PERSAD',
    type: 'Dependent',
    reportDate: '2016-11-24T16:44:25.267Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 121.73,
        paidAmount: 97.38,
        deductibleAmount: 0,
        coinsuranceAmount: 24.35,
        copayAmount: 0,
        checkNumber: '2237636',
        checkDate: '2016-11-25T00:00:00.000Z',
        fromDate: '2016-10-21T00:00:00.000Z',
        toDate: '2016-10-21T00:00:00.000Z',
        receivedDate: '2016-11-22T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'DRUGS',
        submittedAmount: 1092.37,
        paidAmount: 873.9,
        deductibleAmount: 0,
        coinsuranceAmount: 218.47,
        copayAmount: 0,
        checkNumber: '2237636',
        checkDate: '2016-11-25T00:00:00.000Z',
        fromDate: '2016-10-21T00:00:00.000Z',
        toDate: '2016-10-21T00:00:00.000Z',
        receivedDate: '2016-11-22T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'OTCRX',
        submittedAmount: 1.52,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2016-10-21T00:00:00.000Z',
        toDate: '2016-10-21T00:00:00.000Z',
        receivedDate: '2016-11-22T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_12142015SC 0023',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-02',
    patient: 230956,
    masterId: 'Master-000425',
    name: 'EMMA-LEE PERSAD',
    type: 'Dependent',
    reportDate: '2015-12-14T14:11:34.797Z',
    claimDetails: [
      {
        procedure: 'LAB',
        submittedAmount: 1790,
        paidAmount: 1432,
        deductibleAmount: 0,
        coinsuranceAmount: 358,
        copayAmount: 0,
        checkNumber: '624723',
        checkDate: '2015-12-21T00:00:00.000Z',
        fromDate: '2015-08-17T00:00:00.000Z',
        toDate: '2015-08-17T00:00:00.000Z',
        receivedDate: '2015-10-09T00:00:00.000Z',
        provider: ' Victoria Medical Laboratories Ltd',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'SPEC',
        submittedAmount: 500,
        paidAmount: 0,
        deductibleAmount: 428.58,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2015-08-17T00:00:00.000Z',
        toDate: '2015-08-17T00:00:00.000Z',
        receivedDate: '2015-10-09T00:00:00.000Z',
        provider: ' Victoria Medical Laboratories Ltd',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_12222015RHR0058',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-04',
    patient: 230958,
    masterId: 'Master-000425',
    name: 'DREW-MORGAN PERSAD',
    type: 'Dependent',
    reportDate: '2015-12-22T10:52:22.883Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 108.65,
        paidAmount: 86.92,
        deductibleAmount: 0,
        coinsuranceAmount: 21.73,
        copayAmount: 0,
        checkNumber: '625505',
        checkDate: '2015-12-23T00:00:00.000Z',
        fromDate: '2015-11-04T00:00:00.000Z',
        toDate: '2015-11-04T00:00:00.000Z',
        receivedDate: '2015-12-18T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_12232015TSM0023',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-04',
    patient: 230958,
    masterId: 'Master-000425',
    name: 'DREW-MORGAN PERSAD',
    type: 'Dependent',
    reportDate: '2015-12-23T05:39:08.537Z',
    claimDetails: [
      {
        procedure: 'VISIT',
        submittedAmount: 150,
        paidAmount: 0,
        deductibleAmount: 150,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2015-11-04T00:00:00.000Z',
        toDate: '2015-11-04T00:00:00.000Z',
        receivedDate: '2015-12-22T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_12232015TSM0024',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-04',
    patient: 230958,
    masterId: 'Master-000425',
    name: 'DREW-MORGAN PERSAD',
    type: 'Dependent',
    reportDate: '2015-12-23T05:41:14.783Z',
    claimDetails: [
      {
        procedure: 'OTCRX',
        submittedAmount: 59.54,
        paidAmount: 0,
        deductibleAmount: 0,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2015-11-25T00:00:00.000Z',
        toDate: '2015-11-25T00:00:00.000Z',
        receivedDate: '2015-12-22T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
      {
        procedure: 'VISIT',
        submittedAmount: 200,
        paidAmount: 0,
        deductibleAmount: 187.5,
        coinsuranceAmount: 0,
        copayAmount: 0,
        fromDate: '2015-11-25T00:00:00.000Z',
        toDate: '2015-11-25T00:00:00.000Z',
        receivedDate: '2015-12-22T00:00:00.000Z',
        provider: ' Medical GP Out of Network',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_12282017VD 0091',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-00',
    patient: 230954,
    masterId: 'Master-000425',
    name: 'BRYAN PERSAD',
    type: 'Insured',
    reportDate: '2017-12-28T20:25:33.307Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 397.8,
        paidAmount: 318.24,
        deductibleAmount: 0,
        coinsuranceAmount: 79.56,
        copayAmount: 0,
        checkNumber: '2271250',
        checkDate: '2017-12-29T00:00:00.000Z',
        fromDate: '2017-10-07T00:00:00.000Z',
        toDate: '2017-10-07T00:00:00.000Z',
        receivedDate: '2017-12-07T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
  {
    _id: 'Master-000425_12292017HXF0010',
    coverage: 'Master-000425_IndivHealth/PIND6333_MDV_15/TPI6333-00001-00',
    patient: 230954,
    masterId: 'Master-000425',
    name: 'BRYAN PERSAD',
    type: 'Insured',
    reportDate: '2017-12-29T21:18:54.527Z',
    claimDetails: [
      {
        procedure: 'DRUGS',
        submittedAmount: 397.8,
        paidAmount: 318.24,
        deductibleAmount: 0,
        coinsuranceAmount: 79.56,
        copayAmount: 0,
        checkNumber: '2271436',
        checkDate: '2018-01-02T00:00:00.000Z',
        fromDate: '2017-11-10T00:00:00.000Z',
        toDate: '2017-11-10T00:00:00.000Z',
        receivedDate: '2017-12-18T00:00:00.000Z',
        provider: ' Super Pharm Limited',
        status: 'All processing on this claim is complete',
      },
    ],
  },
];
