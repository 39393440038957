import {
  DELETE_PENDING_CLAIM_API_REQUEST,
  DELETE_PENDING_CLAIM_API_SUCCESS,
  DELETE_PENDING_CLAIM_API_ERROR,
} from '../../../constants/pendingClaimActionNames';

import { deletePendingClaimApi } from '../../../apis/claims';

function deletePendingClaimRequest() {
  return {
    type: DELETE_PENDING_CLAIM_API_REQUEST,
    payload: true,
  };
}
function deletePendingClaimSuccess(data) {
  return {
    type: DELETE_PENDING_CLAIM_API_SUCCESS,
    payload: data,
  };
}

function deletePendingClaimError(err) {
  return {
    type: DELETE_PENDING_CLAIM_API_ERROR,
    payload: err,
  };
}

export function deletePendingClaimAction(params) {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(deletePendingClaimRequest());
    return deletePendingClaimApi
      .deletePendingClaim(state, params)
      .then(data => {
        dispatch(deletePendingClaimSuccess(params));
        return data;
      })
      .catch(error => {
        dispatch(deletePendingClaimError(error));
        throw error;
      });
  };
}
export default {
  deletePendingClaimAction,
};
