import React from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Typography, Stack, FormControl } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const MobileNumber = ({
  label,
  name,
  value,
  handleChange,
  error,
  background,
  width,
  helperText,
  footNote,
  borderRadius,
  required,
  styles,
  regions,
  defaultCountry,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        width: {
          xs: '100%',
          sm: '100%',
          md: width || '100%',
        },
      }}
      {...props}
    >
      <Typography
        sx={{
          color: 'text.label',
          fontSize: '1rem',
          fontWeight: '500',
          mb: '0.5rem',
          ...styles,
        }}
      >
        {label}
        {required && ' *'}
      </Typography>
      <FormControl
        fullWidth
        sx={{
          borderRadius: borderRadius || '8px',
        }}
      >
        <MuiPhoneNumber
          value={value}
          required
          error={error}
          helperText={helperText || footNote || ''}
          regions={regions || 'jm'}
          variant="outlined"
          defaultCountry={defaultCountry}
          onChange={handleChange}
          sx={{
            background: `${theme.palette.background.paper}`,
            '& .MuiOutlinedInput-root': {
              background: background || `${theme.palette.background.main}`,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${theme.palette.background.contentBox}`,
              borderRadius: borderRadius || '8px',
            },
            '& .MuiInputBase-input ': {
              background: background || `${theme.palette.background.main}`,
              borderRadius: borderRadius || '8px',
              padding: '0.75rem 1.25rem 0.75rem 1.25rem',
            },
          }}
          name={name}
        />
      </FormControl>
    </Stack>
  );
};
