import { gql } from 'graphql-request';

const NON_RECURSIVE_FORM_DYNAMIC_ZONE_FIELDS = gql`
  fragment nonRecursiveFormDynamicZone on C360CustomFormComponentsDynamicZone {
    ... on ComponentWizardComponentsDateInput {
      ...dateInput
    }
    ... on ComponentWizardComponentsButtonGroupChoice {
      ...buttonGroupChoice
    }
    ... on ComponentWizardComponentsCheckChoice {
      ...checkChoice
    }
    ... on ComponentWizardComponentsAlertMessage {
      ...alertMessage
    }
    ... on ComponentWizardComponentsHeader {
      ...header
    }
    ... on ComponentWizardComponentsDropdownChoice {
      ...dropdownChoice
    }
    ... on ComponentWizardComponentsRadioChoice {
      ...radioChoice
    }
    ... on ComponentWizardComponentsTextLabel {
      ...textLabel
    }
    ... on ComponentWizardComponentsSubmitButton {
      ...submitButton
    }
    ... on ComponentWizardComponentsPhoneInput {
      ...phoneInput
    }
    ... on ComponentWizardComponentsValueInput {
      ...valueInput
    }
    ... on ComponentWizardComponentsToggleButtonChoice {
      ...toggleButtonChoice
    }
    ... on ComponentWizardComponentsDobInput {
      ...dobInput
    }
    ... on ComponentWizardComponentsSearchSingleSelectInput {
      ...singleSearchSelect
    }
    ... on ComponentWizardComponentsDisplayListItems {
      ...displayListItems
    }
    ... on ComponentWizardComponentsRichTextDisplay {
      ...richTextDisplay
    }
    ... on ComponentWizardComponentsEmptyBox {
      ...emptyBox
    }
    ... on ComponentWizardComponentsDownloadButton {
      ...downloadButton
    }
    ... on ComponentWizardComponentsUploadButton {
      ...uploadButton
    }
    ... on ComponentWizardComponentsRegionCountryChoice {
      ...regionCountryChoice
    }
    ... on ComponentWizardComponentsCardItemsList {
      ...cardItemsList
    }
    ... on ComponentWizardComponentsNeedsSummaryCard {
      ...needsSummaryCard
    }
    ... on ComponentWizardComponentsIdVerification {
      ...idVerification
    }
    ... on ComponentWizardComponentsSurvey {
      ...survey
    }
  }
`;

// exports.default = NON_RECURSIVE_FORM_DYNAMIC_ZONE_FIELDS;
export default NON_RECURSIVE_FORM_DYNAMIC_ZONE_FIELDS;
