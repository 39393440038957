import { SET_MODAL_CONTENT_SUCCESS } from '../../../constants/miscActionNames';

function setModalContentSuccess(prod) {
  return {
    type: SET_MODAL_CONTENT_SUCCESS,
    payload: prod,
  };
}

export function setModalContentAction(data) {
  return setModalContentSuccess(data);
}

export default {
  setModalContentAction,
};
