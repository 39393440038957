import React from 'react';
import { Typography, Grid, ListItemIcon, Icon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from './style';

const useStyles = makeStyles(styles);

const Index = ({ title, icons }) => {
  const classes = useStyles();

  const validIcons = icons && icons.length > 0;

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.root}
      spacing={2}
    >
      {icons && icons.length > 0 && (
        <Grid item xs={3} sm={2} align="center">
          {icons.map(icon => (
            <ListItemIcon className={classes.headerIcon}>
              <Icon className={classes.icon} data-nosnippet="data-nosnippet">
                {icon}
              </Icon>
              {/* <Avatar className={classes.avatar}>
            <Icon className={classes.icon}>{icon}</Icon>
          </Avatar> */}
            </ListItemIcon>
          ))}
        </Grid>
      )}
      <Grid item xs={validIcons ? 9 : 12} sm={validIcons ? 10 : 12} align="left">
        <Typography variant="h5" color="primary" align="left">
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Index;
