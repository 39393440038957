import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Snackbar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Backdrop,
  SnackbarContent,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
// import Cookies from '../../../cookies/cookies';
import {
  consentKey,
  customConsentKey,
  acceptAllConsentValue,
  acceptCustomConsentValue,
  rejectConcentValue,
  consentAcceptExpireTimeHrs,
  consentRejectExpireTimeHrs,
  // cookiePolicyVersionKey,
  cookiePolicyVersion,
} from '../../../utils/variables';
import { deleteCookiesAndStorageExceptConsent, uuidv4 } from '../../../utils/functions';
import { ConsentContext, ThemeContext } from '../../../context';

const styles = theme => {
  return {
    root: {
      backgroundColor: `${theme.palette.background.default} !important`,
      padding: theme.spacing(2),
      color: `${theme.palette.text.primary} !important`,
      width: '70vw',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    buttonMargin: {
      marginLeft: theme.spacing(2),
    },
    dialogTextRoot: {
      color: `${theme.palette.text.primary} !important`,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: `${theme.palette.text.primary} !important`,
    },
  };
};
const CookieConsent = props => {
  const { activateCookies, debug, ...restProps } = props;
  const { consent, setConsent } = useContext(ConsentContext);
  // console.log('consent: ', consent);
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const language = useSelector(state => state?.locations?.language) || 'en';
  const yourLocation = useSelector(state => state?.locations?.yourLocation);
  const alpha2 = yourLocation?.alpha2;
  const { themeName: initialThemeName, setThemeName } = useContext(ThemeContext);
  const cookies = yourLocation?.cookies || [];
  const cookiePolicy = yourLocation?.cookiePolicy || null;
  // console.log('cookiePolicyId: ', cookiePolicyId);
  const hasCookiePolicy = !!cookiePolicy;
  // console.log('hasCookiePolicy: ', hasCookiePolicy);

  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(false);

  // console.log('cookiePolicy: ', cookiePolicy);

  // const [open, setOpen] = React.useState(true);
  // const handleClose = () => {
  // setOpen(false);
  // };

  useEffect(() => {
    const storeCookiePolicyVersion = consent?.version || 0; // localStorage.getItem(cookiePolicyVersionKey)
    // console.log('storeCookiePolicyVersion: ', storeCookiePolicyVersion);
    const hasOutdatedCookiePolicy =
      cookiePolicyVersion !== parseInt(storeCookiePolicyVersion, 10);
    // console.log('hasOutdatedCookiePolicy: ', hasOutdatedCookiePolicy);
    // || Cookies.getCookie(cookieName) === rejectConcentValue
    const consentValue = consent?.consent; // Cookies.getCookie(cookieName);
    // console.log('consent: ', consent);
    // console.log('consentValue: ', consentValue);
    if (
      (!(
        consentValue === acceptAllConsentValue ||
        consentValue === acceptCustomConsentValue
      ) ||
        debug ||
        hasOutdatedCookiePolicy) &&
      hasCookiePolicy
    ) {
      setVisible(true);
      // console.log('setVisible: ');

      // deleteCookiesAndStorageExceptConsent();
    }
  }, [consent?.consent]);

  const handleReject = () => {
    if (disabled) return;
    setDisabled(true);
    deleteCookiesAndStorageExceptConsent();

    // Cookies.setCookie(cookieName, rejectConcentValue, rejectExpires);
    setConsent({
      consent: rejectConcentValue,
      version: cookiePolicyVersion,
      customConsent: [],
      cookies: [],
    });
    setTimeout(() => {
      setDisabled(false);
      setVisible(false);
    }, 2000);
    // localStorage.setItem(cookiePolicyVersionKey, cookiePolicyVersion);
  };

  const handleAcceptAll = () => {
    if (disabled) return;
    setDisabled(true);
    // handleClose();
    // deleteCookiesAndStorageExceptConsent();

    const cookieValues = {
      alpha2,
      language,
      theme: initialThemeName,
      // googleAnalytics: true,
    };
    const result = activateCookies(
      cookies,
      acceptAllConsentValue,
      null,
      cookieValues,
      null,
      null,
      hasCookiePolicy,
    );

    // Cookies.setCookie(cookieName, acceptAllConsentValue, acceptExpires);
    setConsent({
      consent: acceptAllConsentValue,
      version: cookiePolicyVersion,
      customConsent: [],
      cookies: [],
    });
    setTimeout(() => {
      setDisabled(false);
      setVisible(false);
    }, 2000);
    // localStorage.setItem(cookiePolicyVersionKey, cookiePolicyVersion);
  };

  const handleAcceptCustom = keys => {
    if (disabled) return;
    setDisabled(true);
    // deleteCookiesAndStorageExceptConsent();

    // console.log('cookies: ', cookies);
    // console.log('keys: ', keys);
    const cookieValues = {
      alpha2,
      language,
      theme: initialThemeName,
      // googleAnalytics: true,
    };
    const result = activateCookies(
      cookies,
      acceptCustomConsentValue,
      keys,
      cookieValues,
      null,
      null,
      hasCookiePolicy,
    );
    // console.log('result: ', result);

    setConsent({
      consent: acceptCustomConsentValue,
      customConsent: keys,
      cookies: result?.cookies,
      version: cookiePolicyVersion,
    });
    setTimeout(() => {
      setDisabled(false);
      setVisible(false);
    }, 2000);
    // localStorage.setItem(cookiePolicyVersionKey, cookiePolicyVersion);
  };

  const handleAcceptStrict = () => {
    if (disabled) return;
    setDisabled(true);
    // deleteCookiesAndStorageExceptConsent();

    const cookieValues = {
      alpha2,
      language,
      theme: initialThemeName,
      // googleAnalytics: true,
    };

    const keys = ['strictly-necessary-cookies'];
    const result = activateCookies(
      cookies,
      acceptCustomConsentValue,
      keys,
      cookieValues,
      null,
      null,
      hasCookiePolicy,
    );

    // handleClose();
    // const customConsentValue = `strictly-necessary-cookies@${result?.cookies
    //  ?.map(cookie => cookie?.cookieId)
    //  .join(',')}`;
    // Cookies.setCookie(cookieName, acceptCustomConsentValue, acceptExpires);
    // Cookies.setCookie(customCookieName, customConsentValue, acceptExpires);
    setConsent({
      consent: acceptCustomConsentValue,
      customConsent: keys,
      cookies: result?.cookies,
      version: cookiePolicyVersion,
    });
    setTimeout(() => {
      setDisabled(false);
      setVisible(false);
    }, 2000);
    // localStorage.setItem(cookiePolicyVersionKey, cookiePolicyVersion);
  };

  const {
    componentType,
    children,
    message,
    snackbarAnchor,
    title,
    acceptButtonLabel,
    rejectButtonLabel,
    actions,
  } = props;
  // console.log('props: ', props);
  // console.log('children: ', children);
  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, {
      key: uuidv4(),
      onAcceptAll: handleAcceptAll,
      onAcceptCustom: handleAcceptCustom,
      onReject: handleReject,
      onAcceptStrict: handleAcceptStrict,
      ...cookiePolicy,
      ...restProps,
    }),
  );
  // /console.log('childrenWithProps: ', childrenWithProps);
  // console.log('childrenWithProps: ', childrenWithProps);
  switch (componentType) {
    case 'Snackbar':
      return children ? (
        <Backdrop className={classes.backdrop} open={visible}>
          <Snackbar anchorOrigin={snackbarAnchor} open={visible}>
            <SnackbarContent
              classes={{ root: classes.root }}
              message={childrenWithProps}
              // disabled={disabled}
              // setDisabled={setDisabled}
            />
          </Snackbar>
        </Backdrop>
      ) : (
        <Backdrop className={classes.backdrop} open={visible}>
          <Snackbar anchorOrigin={snackbarAnchor} open={visible}>
            <SnackbarContent
              disabled={disabled}
              message={<span id="message-id">{message}</span>}
              action={[
                ...React.Children.toArray(actions),
                <Button
                  key="reject"
                  color="inherit"
                  size="small"
                  onClick={handleReject}
                  className={classes.buttonMargin}
                >
                  {rejectButtonLabel}
                </Button>,
                <Button
                  key="accept"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleAcceptAll}
                  className={classes.buttonMargin}
                >
                  {acceptButtonLabel}
                </Button>,
              ]}
              classes={{ root: classes.root }}
            />
          </Snackbar>
        </Backdrop>
      );
    case 'Dialog':
      return (
        <Dialog open={visible}>
          {children ? (
            childrenWithProps
          ) : (
            <>
              {title ? <DialogTitle>{title}</DialogTitle> : null}
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  classes={{ root: classes.dialogTextRoot }}
                >
                  {message}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                {actions}
                {rejectButtonLabel && (
                  <Button
                    color="inherit"
                    onClick={handleReject}
                    className={classes.buttonMargin}
                  >
                    {rejectButtonLabel}
                  </Button>
                )}
                {acceptButtonLabel && (
                  <Button
                    onClick={handleAcceptAll}
                    color="primary"
                    variant="contained"
                    className={classes.buttonMargin}
                  >
                    {acceptButtonLabel}
                  </Button>
                )}
              </DialogActions>
            </>
          )}
        </Dialog>
      );
    default:
      return null;
  }
};

CookieConsent.propTypes = {
  componentType: PropTypes.oneOf(['Dialog', 'Snackbar']),
  cookieName: PropTypes.string,
  customCookieName: PropTypes.string,
  acceptExpires: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  rejectExpires: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  title: PropTypes.string,
  message: PropTypes.string,
  acceptButtonLabel: PropTypes.string,
  rejectButtonLabel: PropTypes.string,
  debug: PropTypes.bool,
  snackbarAnchor: PropTypes.shape({
    horizontal: PropTypes.oneOf(['left', 'center', 'right']),
    vertical: PropTypes.oneOf(['top', 'bottom']),
  }),
};

CookieConsent.defaultProps = {
  componentType: 'Snackbar',
  cookieName: consentKey,
  customCookieName: customConsentKey,
  acceptExpires: consentAcceptExpireTimeHrs,
  rejectExpires: consentRejectExpireTimeHrs,
  debug: false,
  onAccept: () => {},
  onReject: () => {},
  snackbarAnchor: { horizontal: 'center', vertical: 'bottom' },
  message: null,
  title: null,
  acceptButtonLabel: null,
  rejectButtonLabel: null,
};

export default CookieConsent;
