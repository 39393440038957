import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
// import ReactHtmlParser from 'react-html-parser'; // processNodes,
// convertNodeToElement,
// htmlparser2,
// import sanitizeHtml from 'sanitize-html';
import ReactQuill from 'react-quill'; // ES6
import { getRef } from '../../../utils/functions';
import sanitizeText from '../../../utils/functions/sanitizeText';
import styles from './style';
import { dutchTemplate } from '../../../utils/variables';
// import { withWrapper } from '../../hocs';

const useStyles = makeStyles(styles);

const RichTextDisplay = props => {
  const {
    content,
    source,
    staticData,
    loading,
    sx,
    style,
    contentStyle,
    fontColor,
    ...restProps
  } = props;
  const theme = useTheme(props);
  const classes = useStyles({
    fontColor: fontColor ? getRef(theme?.palette, fontColor) : theme.palette.primary.main,
  });
  let html = '';
  if (source === 'remote') {
    html = staticData?.html;
  } else {
    html = content;
  }

  let newHtml = '';
  if (html && html !== 'undefined' && html !== 'null') {
    newHtml = sanitizeText(html);
  }
  //
  const isDutch = theme?.template === dutchTemplate;

  return (
    <div id="#quill" className="ql-content">
      <Box sx={sx} style={style}>
        <ReactQuill
          value={newHtml}
          readOnly
          theme="bubble"
          className={classnames(classes.content, { [classes.dutchContent]: isDutch })}
        />
      </Box>
    </div>
  );

  //  <ReactQuill value={data} readOnly theme="bubble" className={classes.content} />
  // <div dangerouslySetInnerHTML={{ __html: `${data}` }} className={classes.content} />
  // return <div>{ReactHtmlParser(html)}</div>;
};

/*
 return (
    <div dangerouslySetInnerHTML={{ __html: `${data}` }} className={classes.content} />
  );

  <div  ><input type="text" name="name"  onChange={(e) => this.handleChange(e.target.value)}
                value={this.state.editorHtml}/>{this.state.comments} </div> 
  */
RichTextDisplay.propTypes = {
  content: PropTypes.string,
  source: PropTypes.oneOf(['remote', 'local']),
  contentStyle: PropTypes.object,
  staticData: PropTypes.shape({
    html: PropTypes.string,
  }),
};

RichTextDisplay.defaultProps = {
  content: '',
  source: 'local',
  staticData: { html: '' },
  contentStyle: {},
};

export default RichTextDisplay;
