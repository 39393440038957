import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Button,
  ButtonGroup,
  Typography,
  Popover,
  useMediaQuery,
  FormControlLabel,
  FormControl,
  Switch,
  FormLabel,
  RadioGroup,
  Radio,
  Icon,
  Tooltip,
  Box,
} from '@mui/material';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';
import {
  NightsStay as NightsStayIcon,
  Brightness4 as Brightness4Icon,
} from '@mui/icons-material';
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';
import ModeNightRoundedIcon from '@mui/icons-material/ModeNightRounded';
import { ThemeContext } from '../../../context';

import { themeTypes, useGetThemeByName, formatThemeToForm } from '../../../themes';

import { postIframe } from '../../../utils/functions';

import styles from './style';

const useStyles = makeStyles(styles);
const ThemeSwitcher = ({
  view,
  onChangeTheme,
  onCustomClick,
  removeInnerPadding = false,
  ...props
}) => {
  const { themeName: initialThemeName, setThemeName } = useContext(ThemeContext);
  const themeName = initialThemeName?.split('_')[0]
    ? initialThemeName.split('_')[0]
    : initialThemeName;
  const getThemeByName = useGetThemeByName();
  const matches = useMediaQuery(theme => theme.breakpoints.up('md'));

  const sendThemeToIframe = newThemeName => {
    const switchTheme = getThemeByName(newThemeName);
    const formTheme = formatThemeToForm(switchTheme, newThemeName);

    postIframe({ theme: formTheme });
  };

  const setThemeValue = themeNameValue => {
    // setAppThemeName(themeNameValue);
    setThemeName(themeNameValue);
    sendThemeToIframe(themeNameValue);
    onChangeTheme(themeNameValue);
  };

  const classes = useStyles({ removeInnerPadding });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    // console.log('fired');
    setAnchorEl(null);
  };

  const handleSwitch = event => {
    if (onCustomClick) onCustomClick('theme-switcher');
    setThemeValue(event.target.checked ? themeTypes.dark : themeTypes.light);
  };

  const handleToggle = event => {
    if (onCustomClick) onCustomClick('theme-switcher');
    setThemeValue(themeName === themeTypes.dark ? themeTypes.light : themeTypes.dark);
  };

  const handleRadioChange = event => {
    if (onCustomClick) onCustomClick('theme-switcher');
    const newThemeName = event.target.value;
    setThemeValue(newThemeName);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const circle = (
    <div className={classnames(classes.shape, classes.shapeCircle)}>
      <div className={classes.textCenter}>
        {themeName === themeTypes.light ? 'L' : 'D'}
      </div>
    </div>
  );

  const darkModeMessage = 'Eyes sensitive to light mode? Click to try dark mode.';
  const lightModeMessage = 'Switch back to light mode.';

  if (view === 'toggle') {
    return (
      <Tooltip
        title={themeName === themeTypes.light ? darkModeMessage : lightModeMessage}
      >
        <Box sx={{ maxWidth: '32px' }}>
          <Button
            variant="text"
            onClick={handleToggle}
            size="small"
            aria-label="button to toggle theme"
            sx={{ minWidth: '32px', height: '32px', p: '4px' }}
          >
            {themeName === themeTypes.dark ? (
              <WbSunnyRoundedIcon
                fontSize="small"
                sx={{
                  color: 'text.main',
                }}
              />
            ) : (
              <ModeNightRoundedIcon
                fontSize="small"
                sx={{
                  color: 'text.main',
                }}
              />
            )}
          </Button>
        </Box>
      </Tooltip>
    );
  }

  if (view === 'badge') {
    return (
      <Tooltip
        title={themeName === themeTypes.light ? darkModeMessage : lightModeMessage}
      >
        <Badge
          aria-describedby={id}
          className={classes.badge}
          color="secondary"
          overlap="circle"
          badgeContent=""
          onMouseEnter={matches ? handleClick : undefined}
          onFocus={matches ? handleClick : undefined}
        >
          {circle}
        </Badge>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          onExit={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          PaperProps={{ onMouseLeave: handleClose }}
        >
          <div className={classes.root}>
            <Typography className={classes.typography}>Choose your Theme:</Typography>
            <ButtonGroup color="secondary" aria-label="outlined secondary button group">
              <Button
                variant={themeName === themeTypes.light ? 'contained' : 'outlined'}
                onClick={() => {
                  setThemeValue(themeTypes.light);
                  return handleClose();
                }}
              >
                Light
              </Button>
              <Button
                variant={themeName === themeTypes.dark ? 'contained' : 'outlined'}
                onClick={() => {
                  setThemeValue(themeTypes.dark);
                  return handleClose();
                }}
              >
                Dark
              </Button>
            </ButtonGroup>
          </div>
        </Popover>
      </Tooltip>
    );
  }

  if (view === 'radio') {
    return (
      <Tooltip
        title={themeName === themeTypes.light ? darkModeMessage : lightModeMessage}
      >
        <FormControl component="fieldset" className={classes.root}>
          <Typography>Theme Name:</Typography>
          <RadioGroup
            row
            aria-label="position"
            name="position"
            value={themeName}
            onChange={handleRadioChange}
            className={classes.root}
          >
            <FormControlLabel
              value="light"
              control={<Radio color="primary" />}
              label="Light"
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="dark"
              control={<Radio color="primary" />}
              label="Dark"
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="custom"
              control={<Radio color="primary" />}
              label="Custom"
              labelPlacement="bottom"
            />
          </RadioGroup>
        </FormControl>
      </Tooltip>
    );
  }

  // // console.log(`Theme Names: ${themeName}`);

  return (
    <Tooltip title={themeName === themeTypes.light ? darkModeMessage : lightModeMessage}>
      <FormControl component="fieldset" className={classes.root}>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={themeName === themeTypes.dark}
              onChange={handleSwitch}
              id={props.id}
            />
          }
          label={
            themeName === themeTypes.dark ? (
              <NightsStayIcon className={classes.icon} />
            ) : (
              <Brightness4Icon className={classes.icon} />
            )
          }
          classes={{ root: classes.alignCenter, label: classes.themeSwitchLabel }}
          labelPlacement="start" //! matches ? : 'top'
        />
      </FormControl>
    </Tooltip>
  );
};

export default ThemeSwitcher;

ThemeSwitcher.propTypes = {
  view: PropTypes.oneOf(['switch', 'badge', 'radio']),
  onChangeTheme: PropTypes.func,
};
ThemeSwitcher.defaultProps = {
  view: 'switch',
  onChangeTheme: () => {},
};

/*

        
      <Button
        aria-describedby={id}
        variant="contained"
        color="primary"
        
      >
        Open Popover
      </Button>
      */
