export const CREATE_RENEWAL_ORDER_API_REQUEST = 'CREATE_RENEWAL_ORDER_API_REQUEST';
export const CREATE_RENEWAL_ORDER_API_SUCCESS = 'CREATE_RENEWAL_ORDER_API_SUCCESS';
export const CREATE_RENEWAL_ORDER_API_ERROR = 'CREATE_RENEWAL_ORDER_API_ERROR';

export const RENEW_POLICY_API_SUCCESS = 'RENEW_POLICY_API_SUCCESS';
export const RENEW_POLICY_API_REQUEST = 'RENEW_POLICY_API_REQUEST';
export const RENEW_POLICY_API_ERROR = 'RENEW_POLICY_API_ERROR';

export const UPDATE_ORDER_API_SUCCESS = 'UPDATE_ORDER_API_SUCCESS';
export const UPDATE_ORDER_API_REQUEST = 'UPDATE_ORDER_API_REQUEST';
export const UPDATE_ORDER_API_ERROR = 'UPDATE_ORDER_API_ERROR';

export const UPDATE_ORDER_ITEM_API_SUCCESS = 'UPDATE_ORDER_ITEM_API_SUCCESS';
export const UPDATE_ORDER_ITEM_API_REQUEST = 'UPDATE_ORDER_ITEM_API_REQUEST';
export const UPDATE_ORDER_ITEM_API_ERROR = 'UPDATE_ORDER_ITEM_API_ERROR';

export const RENEW_POLICY_HOME_API_REQUEST = 'RENEW_POLICY_HOME_API_REQUEST';
export const RENEW_POLICY_HOME_API_SUCCESS = 'RENEW_POLICY_HOME_API_SUCCESS';
export const RENEW_POLICY_HOME_API_ERROR = 'RENEW_POLICY_HOME_API_ERROR';

export const GET_RENEWAL_DATA_API_REQUEST = 'GET_RENEWAL_DATA_API_REQUEST';
export const GET_RENEWAL_DATA_API_SUCCESS = 'GET_RENEWAL_DATA_API_SUCCESS';
export const GET_RENEWAL_DATA_API_ERROR = 'GET_RENEWAL_DATA_API_ERROR';

export const AMEND_POLICY_API_REQUEST = 'AMEND_POLICY_API_REQUEST';
export const AMEND_POLICY_API_SUCCESS = 'AMEND_POLICY_API_SUCCESS';
export const AMEND_POLICY_API_ERROR = 'AMEND_POLICY_API_ERROR';
export const AMEND_POLICY_SUCCESS = 'AMEND_POLICY_SUCCESS';

export const ADD_CHANGE_REQUEST_API_REQUEST = 'ADD_CHANGE_REQUEST_REQUEST';
export const ADD_CHANGE_REQUEST_API_SUCCESS = 'ADD_CHANGE_REQUEST_SUCCESS';
export const ADD_CHANGE_REQUEST_API_ERROR = 'ADD_CHANGE_REQUEST_ERROR';
export const ADD_CHANGE_REQUEST_SUCCESS = 'ADD_CHANGE_REQUEST_SUCCESS';

export const LOCATION_API_REQUEST = 'LOCATION_API_REQUEST';
export const LOCATION_API_ERROR = 'LOCATION_API_ERROR';
export const LOCATION_API_SUCCESS = 'LOCATION_API_SUCCESS';
export const LOCATION_SUCCESS = 'LOCATION_SUCCESS';

export const DELIVERY_OPTIONS_API_REQUEST = 'DELIVERY_OPTIONS_API_REQUEST';
export const DELIVERY_OPTIONS_API_SUCCESS = 'DELIVERY_OPTIONS_API_SUCCESS';
export const DELIVERY_OPTIONS_API_ERROR = 'DELIVERY_OPTIONS_API_ERROR';
export const DELIVERY_OPTIONS_SUCCESS = 'DELIVERY_OPTIONS_SUCCESS';

export const SEND_INVOICE_API_REQUEST = 'SEND_INVOICE_API_REQUEST';
export const SEND_INVOICE_API_SUCCESS = 'SEND_INVOICE_API_SUCCESS';
export const SEND_INVOICE_API_ERROR = 'SEND_INVOICE_API_ERROR';
export const SEND_INVOICE_SUCCESS = 'SEND_INVOICE_SUCCESS';

export const CAN_POLICY_RENEW_API_REQUEST = 'CAN_POLICY_RENEW_API_REQUEST';
export const CAN_POLICY_RENEW_API_SUCCESS = 'CAN_POLICY_RENEW_API_SUCCESS';
export const CAN_POLICY_RENEW_API_ERROR = 'CAN_POLICY_RENEW_API_ERROR';

export const PAYMENT_OPTIONS_API_REQUEST = 'PAYMENT_OPTIONS_API_REQUEST';
export const PAYMENT_OPTIONS_API_SUCCESS = 'PAYMENT_OPTIONS_API_SUCCESS';
export const PAYMENT_OPTIONS_API_ERROR = 'PAYMENT_OPTIONS_API_ERROR';

export const GET_SUM_INSURED_API_REQUEST = 'GET_SUM_INSURED_API_REQUEST';
export const GET_SUM_INSURED_API_SUCCESS = 'GET_SUM_INSURED_API_SUCCESS';
export const GET_SUM_INSURED_API_ERROR = 'GET_SUM_INSURED_API_ERROR';

export const SET_SUM_INSURED_API_REQUEST = 'SET_SUM_INSURED_API_REQUEST';
export const SET_SUM_INSURED_API_SUCCESS = 'SET_SUM_INSURED_API_SUCCESS';
export const SET_SUM_INSURED_API_ERROR = 'SET_SUM_INSURED_API_ERROR';

export const RESET_SUM_INSURED_API_REQUEST = 'RESET_SUM_INSURED_API_REQUEST';
export const RESET_SUM_INSURED_API_SUCCESS = 'RESET_SUM_INSURED_API_SUCCESS';
export const RESET_SUM_INSURED_API_ERROR = 'RESET_SUM_INSURED_API_ERROR';

export const GET_RENEWAL_POLICY_API_REQUEST = 'GET_RENEWAL_API_REQUEST';
export const GET_RENEWAL_POLICY_API_SUCCESS = 'GET_RENEWAL_API_SUCCESS';
export const GET_RENEWAL_POLICY_API_ERROR = 'GET_RENEWAL_POLICY_API_ERROR';

export const RESET_RENEWAL_SUCCESS = 'RESET_RENEWAL_SUCCESS';

export const NOTIFY_PAYMENT_API_SUCCESS = 'AGENT_RENEW_POLICY_API_SUCCESS';
export const NOTIFY_PAYMENT_API_REQUEST = 'AGENT_RENEW_POLICY_API_REQUEST';
export const NOTIFY_PAYMENT_API_ERROR = 'AGENT_RENEW_POLICY_API_ERROR';
export const RESET_NOTIFY_PAYMENT_SUCCESS = 'RESET_NOTIFY_PAYMENT_SUCCESS';

export const UPDATE_AGREED_CLAUSE_API_SUCCESS = 'UPDATE_AGREED_CLAUSE_API_SUCCESS';
export const UPDATE_AGREED_CLAUSE_API_REQUEST = 'UPDATE_AGREED_CLAUSE_API_REQUEST';
export const UPDATE_AGREED_CLAUSE_API_ERROR = 'UPDATE_AGREED_CLAUSE_API_ERROR';
