import {
  UPDATE_YOUR_LOCATION_API_REQUEST,
  UPDATE_YOUR_LOCATION_API_SUCCESS,
  UPDATE_YOUR_LOCATION_API_ERROR,
} from '../../../constants/mainLayout/locationActionNames';

// import { api } from '../../../utils/variables';
import { updateYourLocationApi } from '../../../apis/mainLayout';

function updateYourLocationError(err) {
  return {
    type: UPDATE_YOUR_LOCATION_API_ERROR,
    payload: err,
  };
}

function updateYourLocationRequest() {
  return {
    type: UPDATE_YOUR_LOCATION_API_REQUEST,
    payload: true,
  };
}
function updateYourLocationSuccess(newLocation, userType) {
  return {
    type: UPDATE_YOUR_LOCATION_API_SUCCESS,
    payload: newLocation,
    userType,
    // params: newLocation,
  };
}

export function updateYourLocationAction(newLocation) {
  // const apiEndpoint = {}; // ...api };
  return (dispatch, getState) => {
    const state = getState();
    const userType = state?.auth?.usertype;
    // console.log('userType: ', userType);
    return dispatch(updateYourLocationSuccess(newLocation, userType));
  };

  // return (dispatch, getState) => {
  // const state = getState();
  // dispatch(updateYourLocationSuccess(newLocation));

  /* dispatch(updateYourLocationRequest());
    return updateYourLocationApi
      .updateYourLocation(state, apiEndpoint)
      .then(data => {
        // Update the app state with the results of the API call.

        dispatch(updateYourLocationSuccess(data, newLocation));
        return data;
      })
      .catch(error => {
        // dispatch(updateYourLocationError(error));
        dispatch(updateYourLocationSuccess(error, newLocation));
        throw error;
      }); */
  // };
}

export default { updateYourLocationAction };
