import {
    GET_POLICY_EXTERNAL_LINKS_API_REQUEST,
    GET_POLICY_EXTERNAL_LINKS_API_SUCCESS,
    GET_POLICY_EXTERNAL_LINKS_API_ERROR,
} from '../../../constants/portfolio/policyExternalLinksActionNames';

import { getPolicyExternalLinksApi } from '../../../apis/portfolio';

function getPolicyExternalLinksRequest() {
    return {
        type: GET_POLICY_EXTERNAL_LINKS_API_REQUEST,
        payload: true,
    };
}
function getPolicyExternalLinksSuccess(documents) {
    return {
        type: GET_POLICY_EXTERNAL_LINKS_API_SUCCESS,
        payload: documents,
    };
}

function getPolicyExternalLinksError(err) {
    return {
        type: GET_POLICY_EXTERNAL_LINKS_API_ERROR,
        payload: err,
    };
}

export function getPolicyExternalLinksAction(params) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(getPolicyExternalLinksRequest());
        return getPolicyExternalLinksApi
            .getPolicyExternalLinks(state, params)
            .then(data => {
                dispatch(getPolicyExternalLinksSuccess(data));
                return data;
            })
            .catch(error => {
                dispatch(getPolicyExternalLinksError(error));
                throw error;
            });
    };
}
export default {
    getPolicyExternalLinksAction,
};
