import routes from './routes.json';

// export default {
//   index: '/',
//   home: '/home',
//   oldHome: '/old-home',
//   redirectModal: '/redirectModal',
//   policies: '/policies',
//   privacyPolicy: '/privacy-policy',
//   termsAndConditions: '/terms-and-conditions',
//   cancellationPolicy: '/cancellation-policy',
//   securityPolicy: '/security-policy',

// "newCart": {
//     "index": "/new-cart",
//     "orderSummary": "/new-cart/order-summary",
//     "payment": "/new-cart/payment",
//     "paymentComplete": "/new-cart/payment-complete",
//     "paymentDeclined": "/new-cart/payment-declined"
//   },

//   products: {
//     index: '/products',
//     details: '/products/:slug',
//     // insuranceDetails: '/products/insurance/:id',
//     // investmentDetails: '/products/investments/:id',
//     // insuranceProductSummary: '/products/insurance/summary',
//     // insuranceProductDetails: '/products/insurance/details',
//   },

//   healthCard: {
//     index: '/health-card/:slug',
//     requestRemoteConsultation: '/health-card/:slug/request-remote-consultation',
//     downloadCard: '/health-card/:slug/download-card',
//   },

//   wizards: {
//     index: '/wizards',
//     quote: '/wizards/quote',
//     claim: '/wizards/claim',
//     submitDocumentMotor: '/wizards/submit-document-motor',
//     submitDocumentHome: '/wizards/submit-document-home',
//     submitDocument: '/wizards/submit-document',
//     request: '/wizards/request',
//     details: '/wizards/:slug',
//     fnol: '/wizards/fnol',
//     calculator: '/wizards/calculator',
//     customerReferral: '/wizards/customer-referral',
//   },
//   quotes: {
//     index: '/quotes',
//     details: '/quotes/:id',
//   },
//   clients: {
//     index: '/clients',
//     details: '/clients/:id',
//   },
//   clientPolicies: {
//     index: '/client-policies',
//     details: '/client-policies/:id',
//   },
//   cart: {
//     index: '/cart',
//     orderSummary: '/cart/order-summary',
//     payment: '/cart/payment',
//     paymentComplete: '/cart/payment-complete',
//     paymentDeclined: '/cart/payment-declined',
//     // paymentReceipt: '/cart/paymentReceipt',
//   },
//   newCart: {
//     index: '/new-cart',
//     orderSummary: '/new-cart/order-summary',
//     payment: '/new-cart/payment',
//     paymentComplete: '/new-cart/payment-complete',
//     paymentDeclined: '/new-cart/payment-declined',
//   },
//   auth: {
//     index: '/auth',
//     signIn: '/auth/sign-in',
//     unAuthChangePassword: '/auth/password-change-required',
//     beforeYouGetStarted: '/auth/before-you-get-started',
//     verifyIdentity: '/auth/verify-identity',
//     signUp: '/auth/sign-up',
//     agentSignUp: '/auth/agent-sign-up',
//     signUpBio: '/auth/sign-up-bio',
//     signUpContact: '/auth/sign-up-contact',
//     confirmYourInfo: '/auth/confirm-info',
//     signUpComplete: '/auth/sign-up-complete',
//     forgotPassword: '/auth/forgot-password',
//     insuranceRepForgotPassword: '/auth/agent-forgot-password',
//     resetPassword: '/auth/reset-password',
//     insuranceRepResetPassword: '/auth/agent-reset-password',
//     resetPasswordSuccess: '/auth/reset-success',
//     setPassword: '/auth/set-password',
//     triggerSignIn: '/auth/trigger-sign-in',
//     facebookRedirect: '/auth/facebook-redirect/:id',
//     googleRedirect: '/auth/google-redirect/:id',
//     insuranceRep: '/auth/insurancerep',
//     addNewDevice: '/auth/add-new-device',
//     addNickname: '/auth/add-nickname',
//     trustedDeviceAdded: '/auth/trusted-device-added',
//   },
//   portfolio: {
//     index: '/portfolio',
//     portfolioSummary: '/portfolio/insurance',
//     insuranceDetails: '/portfolio/insurance/:id',
//     investmentSummary: '/portfolio/investments',
//     investmentDetails: '/portfolio/investments/:id',
//     investmentHoldingDetails: '/portfolio/investments/:id/:securityCode',
//     insuredItems: {
//       index: '/portfolio/insurance/:id/insured-items',
//       details: '/portfolio/insurance/:id/insured-items/:itemId',
//     },
//     investmentHistoryDetails: '/portfolio/investments/history/:id',
//   },
//   renewal: {
//     index: '/renewal',
//     pickupDelivery: '/renewal/pickup-delivery',
//     invoice: '/renewal/invoice',
//     // homeInvoice: '/renewal/home-invoice',
//     location: '/renewal/location',
//     pendingItem: '/renewal/pending-item',
//     amendPolicy: '/renewal/amend-policy',
//     successAmendPolicy: '/renewal/success-amend-policy',
//     agentRenewalConfirmation: '/renewal/agent-renewal-confirmation',
//   },
//   profile: {
//     index: '/profile',
//     // editPersonalInformation: '/profile/edit-personal-information',
//     // editIdentificationInformationID: '/profile/edit-identification-information-id',
//     // editIdentificationInformationDP: '/profile/edit-identification-information-dp',
//     // editIdentificationInformationPassport:
//     //  '/profile/edit-identification-information-passport',
//     // editAddressInformation: '/profile/edit-address-information',
//     // editEmailInformation: '/profile/edit-email-information',
//     // editPhoneInformation: '/profile/edit-phone-information',
//   },
//   chatBot: {
//     index: '/chat',
//   },
//   notifications: {
//     index: '/notifications',
//   },
//   account: {
//     index: '/account',
//     settings: '/account/settings',
//     changePassword: '/account/change-password',
//     deRegister: '/account/de-register',
//     signOut: '/account/sign-out',
//     confirmPolicy: '/account/confirm-account',
//   },
//   claims: {
//     index: '/claims',
//     details: '/claims/:id',
//   },
//   requests: {
//     index: '/requests',
//   },
//   reports: {
//     index: '/reports',
//     renewalListing: '/reports/renewal-listing',
//     transactionBordereau: '/reports/transaction-bordereau',
//   },
//   test: {
//     allScreens: '/test/all-screens',
//     carousel: '/test/carousel',
//     customTheme: '/test/custom-theme',
//     dataPayload: '/test/data-payload',
//     dataPayloadID: '/test/data-payload/:id',
//   },
//   embed: {
//     index: '/embed',
//     syncData: '/embed/sync-data',
//     updateData: '/embed/update-data',
//     themeSwitcher: '/embed/theme-switcher',
//     locationSwitcher: '/embed/location-switcher',
//     wizardBuilder: '/embed/wizards/:slug',
//   },
//   receipts: {
//     index: '/receipts',
//     details: '/receipts/:id',
//   },
//   pdf: {
//     index: '/pdf',
//   },
//   investmentLetters: {
//     index: '/investmentLetters',
//   },
//   customerReferrals: {
//     index: '/customerReferrals',
//   },
//  officeLocations: {
//    index: '/office-locations',
//  },
// };
export default routes;
