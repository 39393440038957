import React from 'react';
import { Typography } from '@mui/material';
import { getEnv } from '../../../utils/functions';

const elementId = 'surveyLink';

const SurveyLink = props => {
  const { surveyLink = '', styles, headingText, headingVariant } = props;

  const iframeStyle = {
    margin: 'auto',
    maxWidth: '100%',
    width: '100%',
    height: 'inherit',
    // border: '1px solid #cfcfcf',
  };

  const env = getEnv();
  const link = surveyLink && surveyLink[env] ? surveyLink[env] : surveyLink;
  return (
    <div
      data-rid-id={elementId}
      data-fg="#252525"
      data-bg="#EDEDED"
      style={styles}
      data-auto-scroll="true"
    >
      {headingText && (
        <Typography variant={headingVariant || 'h5'}>{headingText}</Typography>
      )}
      <iframe title={elementId} style={iframeStyle} src={link} />
    </div>
  );
};

export default SurveyLink;
