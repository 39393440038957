import { networkStatusStorage } from '../utils/variables';

export const getNetworkStatus = () => {
  const networkStatus = localStorage.getItem(networkStatusStorage) || true;
  return networkStatus;
};

export const removeNetworkStatus = () => {
  if (localStorage.getItem(networkStatusStorage)) {
    localStorage.removeItem(networkStatusStorage);
  }
  return null;
};

export const setNetworkStatus = networkStatus => {
  if (networkStatus) {
    localStorage.setItem(networkStatusStorage, networkStatus);
  }
  return null;
};

export default {
  getNetworkStatus,
  removeNetworkStatus,
  setNetworkStatus,
};
