export const UPDATE_CLAIMS_CURRENT_CACHE_KEY = 'UPDATE_CLAIMS_CURRENT_CACHE_KEY';
export const GET_CLAIMS_API_REQUEST = 'GET_CLAIMS_API_REQUEST';
export const GET_CLAIMS_API_SUCCESS = 'GET_CLAIMS_API_SUCCESS';
export const GET_CLAIMS_API_ERROR = 'GET_CLAIMS_API_ERROR';
export const GET_NEXT_CLAIMS_SUCCESS = 'GET_NEXT_CLAIMS_SUCCESS';
export const GET_CLAIM_DETAILS_API_REQUEST = 'GET_CLAIM_DETAILS_API_REQUEST';
export const GET_CLAIM_DETAILS_API_SUCCESS = 'GET_CLAIM_DETAILS_API_SUCCESS';
export const GET_CLAIM_DETAILS_API_ERROR = 'GET_CLAIM_DETAILS_API_ERROR';
export const RESET_CLAIMS_SUCCESS = 'RESET_CLAIMS_SUCCESS';
export const GET_INSURANCE_DETAILS_REQUEST = 'GET_INSURANCE_DETAILS_REQUEST';
export const GET_INSURANCE_DETAILS_SUCCESS = 'GET_INSURANCE_DETAILS_SUCCESS';
export const GET_INSURANCE_DETAILS_ERROR = 'GET_INSURANCE_DETAILS_ERROR';
export const GET_INDIVIDUAL_INSURANCE_DETAILS_REQUEST =
  'GET_INDIVIDUAL_INSURANCE_DETAILS_REQUEST';
export const GET_INDIVIDUAL_INSURANCE_DETAILS_SUCCESS =
  'GET_INDIVIDUAL_INSURANCE_DETAILS_SUCCESS';
export const GET_INDIVIDUAL_INSURANCE_DETAILS_ERROR =
  'GET_INDIVIDUAL_INSURANCE_DETAILS_ERROR';
export const UPDATE_GLOC_CLAIM_FIELD = 'UPDATE_GLOC_CLAIM_FIELD';
export const UPDATE_GLOC_CLAIM_MULTIPLE_FIELDS = 'UPDATE_GLOC_CLAIM_MULTIPLE_FIELDS';
export const SAVE_HEALTH_CLAIM_REQUEST = 'SAVE_HEALTH_CLAIM_REQUEST';
export const SAVE_HEALTH_CLAIM_SUCCESS = 'SAVE_HEALTH_CLAIM_SUCCESS';
export const SAVE_HEALTH_CLAIM_ERROR = 'SAVE_HEALTH_CLAIM_ERROR';
export const SUBMIT_HEALTH_CLAIM_REQUEST = 'SUBMIT_HEALTH_CLAIM_REQUEST';
export const SUBMIT_HEALTH_CLAIM_SUCCESS = 'SUBMIT_HEALTH_CLAIM_SUCCESS';
export const SUBMIT_HEALTH_CLAIM_ERROR = 'SUBMIT_HEALTH_CLAIM_ERROR';
export const RESET_HEALTH_CLAIMS = 'RESET_HEALTH_CLAIMS';
export const CREATE_BATCH_CLAIM_REQUEST = 'CREATE_BATCH_CLAIM_REQUEST';
export const CREATE_BATCH_CLAIM_SUCCESS = 'CREATE_BATCH_CLAIM_SUCCESS';
export const CREATE_BATCH_CLAIM_ERROR = 'CREATE_BATCH_CLAIM_ERROR';
export const UPDATE_BATCH_CLAIM_FIELD = 'UPDATE_BATCH_CLAIM_FIELD';
export const UPDATE_BATCH_CLAIM_SUCCESS = 'UPDATE_BATCH_CLAIM_SUCCESS';
export const UPDATE_BATCH_CLAIM_ERROR = 'UPDATE_BATCH_CLAIM_ERROR';
export const UPDATE_BATCH_CLAIM_REQUEST = 'UPDATE_BATCH_CLAIM_REQUEST';
export const TRANSMITTAL_FILE_IRREGULAR_DATA = 'TRANSMITTAL_FILE_IRREGULAR_DATA';
export const RESET_BATCH_CLAIM_FORM = 'RESET_BATCH_CLAIM_FORM';
export const TOGGLE_INSTRUCTION_MODAL = 'TOGGLE_INSTRUCTION_MODAL';
export const TOGGLE_BATCH_CLAIM_INSTRUCTION_MODAL =
  'TOGGLE_BATCH_CLAIM_INSTRUCTION_MODAL';
export const IS_HEALTH_CLAIMS_FORM_DIRTY = 'IS_HEALTH_CLAIMS_FORM_DIRTY';
export const IS_BATCH_CLAIMS_FORM_DIRTY = 'IS_BATCH_CLAIMS_FORM_DIRTY';
