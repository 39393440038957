import { portfolioButtonStyles } from '../../../styles';

const styles = theme => ({
  ...portfolioButtonStyles(theme),

  root: {
    width: 'inherit',
    minHeight: 'inherit',
  },
  paper: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    minHeight: 'inherit',
    backgroundColor: `${theme.palette.background.main} !important`,
  },
  modalBackground: {
    backgroundColor: `${theme.palette.background.main} !important`,
  },
  alert: {},
  datePicker: {
    '&::-webkit-calendar-picker-indicator': {
      filter: theme.palette.mode === 'dark' ? 'invert(100%)' : 'invert(0%)',
    },
  },
});

export default styles;
