import React, { useEffect } from 'react';
import { useSelector, useStore } from 'react-redux';
import { Alert, Grid, AlertTitle, Button, Icon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { uuidv4 } from '@microsoft/omnichannel-chat-sdk';
import { Link as RouterLink } from 'react-router-dom';
import sanitizeText from '../../../utils/functions/sanitizeText';
import { useVariables, useRouter, useGTMDataLayer } from '../../../hooks';
import { isValidHttpUrl, sourceTextFromData } from '../../../utils/functions';
import { wizardsComponentEvent } from '../../../utils/variables/events';
import styles from './style';

const DisplayMessages = ({
  alertMessages: initialAlertMessages,
  show,
  name,
  activeStep,
  componentSlug,
  wizardSlug,
  label,
}) => {
  const useStyles = makeStyles(styles);
  const variables = useVariables();
  const storeGtmDataLayer = useGTMDataLayer();
  const router = useRouter();
  const storeState = useStore().getState();
  const classes = useStyles();
  const yourLocation = useSelector(state => state?.locations?.yourLocation);
  const { pathname, asPath } = router;
  const alertMessagesConfig = variables?.alertMessagesConfig;

  useEffect(() => {
    if (wizardSlug) {
      storeGtmDataLayer({
        event: wizardsComponentEvent,
        stepIndex: activeStep,
        componentSlug,
        key: name || '',
        wizardSlug,
        action: 'display',
        label,
      });
    }
  }, []);

  if (show === false) {
    return null;
  }

  const filteredMessages =
    initialAlertMessages ||
    variables?.alertMessages?.filter(message => {
      if (
        message?.inActive ||
        (yourLocation?.alpha2 &&
          message?.territory &&
          message?.territory?.toLowerCase() !== yourLocation?.alpha2?.toLowerCase()) ||
        (message?.pathname &&
          pathname &&
          ((message?.exact && pathname !== message?.pathname) ||
            (!message?.exact && !pathname?.includes(message?.pathname)))) ||
        (message?.asPath &&
          asPath &&
          ((message?.exact && asPath !== message?.asPath) ||
            (!message?.exact && !asPath?.includes(message?.asPath))))
      ) {
        return false;
      }

      return true;
    });

  const alertMessages = filteredMessages?.map(x => (
    <Grid item xs={12}>
      <Alert
        sx={{ width: '100%', p: 2 }}
        severity={x?.severity}
        variant={x?.variant || 'standard'}
      >
        <Grid container justifyContent="center" spacing={1}>
          <Grid item xs={12} lg={alertMessagesConfig?.contentWidth || 12} align="justify">
            {x?.title && (
              <AlertTitle>{sourceTextFromData(storeState, x?.title)}</AlertTitle>
            )}
            {sourceTextFromData(storeState, x?.description)}
            {x?.html && (
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizeText(sourceTextFromData(storeState, x?.html)),
                }}
                style={{ fontSize: x?.fontSize }}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={alertMessagesConfig?.actionsWidth || 12}>
            {x?.actions &&
              x?.actions?.length > 0 &&
              x?.actions?.map(action => {
                return (
                  <Grid
                    key={uuidv4()}
                    item
                    xs={12}
                    md={12}
                    lg={x?.actionWidth || 12}
                    sx={{ height: 'auto' }}
                  >
                    <Button
                      justifyContent="center"
                      className={classes[action?.className]}
                      component={isValidHttpUrl(action?.url) ? 'a' : RouterLink}
                      href={isValidHttpUrl(action?.url) && action?.url}
                      target={isValidHttpUrl(action?.url) ? '_blank' : '_self'}
                      to={action?.url}
                      startIcon={
                        action?.icon && <Icon data-nosnippet>{action?.icon}</Icon>
                      }
                      sx={{ height: '100%' }}
                    >
                      {action?.capitalizeText
                        ? action?.title?.toUpperCase()
                        : action?.title}
                    </Button>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Alert>
    </Grid>
  ));
  if (!filteredMessages || filteredMessages?.length === 0) return null;
  return (
    <Grid container spacing={1}>
      {alertMessages}
    </Grid>
  );
};

export default DisplayMessages;
