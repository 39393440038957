import { RESET_INSURANCE } from '../../../constants/portfolio/insuranceActionNames';

export function resetInsuranceAction(data) {
  return {
    type: RESET_INSURANCE,
    payload: data,
  };
}

export default {
  resetInsuranceAction,
};
