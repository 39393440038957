export const RESET_WIZARD_DATA_SUCCESS = 'RESET_WIZARD_DATA_SUCCESS';
export const RESET_WIZARD_SUCCESS = 'RESET_WIZARD_SUCCESS';
export const API_CALL_WIZARD_API_REQUEST = 'API_CALL_WIZARD_API_REQUEST';
export const API_CALL_WIZARD_API_SUCCESS = 'API_CALL_WIZARD_API_SUCCESS';
export const API_CALL_WIZARD_API_ERROR = 'API_CALL_WIZARD_API_ERROR';
export const GET_WIZARD_API_REQUEST = 'GET_WIZARD_API_REQUEST';
export const GET_WIZARD_API_SUCCESS = 'GET_WIZARD_API_SUCCESS';
export const GET_WIZARD_API_ERROR = 'GET_WIZARD_API_ERROR';
export const GET_MODAL_WIZARD_API_REQUEST = 'GET_MODAL_WIZARD_API_REQUEST';
export const GET_MODAL_WIZARD_API_SUCCESS = 'GET_MODAL_WIZARD_API_SUCCESS';
export const GET_MODAL_WIZARD_API_ERROR = 'GET_MODAL_WIZARD_API_ERROR';
export const SEARCH_CALL_WIZARD_API_REQUEST = 'SEARCH_CALL_WIZARD_API_REQUEST';
export const SEARCH_CALL_WIZARD_API_SUCCESS = 'SEARCH_CALL_WIZARD_API_SUCCESS';
export const SEARCH_CALL_WIZARD_API_ERROR = 'SEARCH_CALL_WIZARD_API_ERROR';
export const ADD_INITIAL_WIZARD_DATA_SUCCESS = 'ADD_INITIAL_WIZARD_DATA_SUCCESS';
export const EMPTY_INITIAL_WIZARD_DATA_SUCCESS = 'EMPTY_INITIAL_WIZARD_DATA_SUCCESS';
export const LOADING_WIZARD_SUCCESS = 'LOADING_WIZARD_SUCCESS';
export const ADD_LOCAL_WIZARD_SUCCESS = 'ADD_LOCAL_WIZARD_SUCCESS';
export const ADD_LOCAL_MODAL_WIZARD_SUCCESS = 'ADD_LOCAL_MODAL_WIZARD_SUCCESS';
export const ADD_LAST_ENTRY_POINT_SUCCESS = 'ADD_LAST_ENTRY_POINT_SUCCESS';
export const RESET_WIZARD_FLAG_SUCCESS = 'RESET_WIZARD_FLAG_SUCCESS';
export const SET_API_CALL_PENDING = 'SET_API_CALL_PENDING';
export const SET_MODAL_API_CALL_PENDING = 'SET_MODAL_API_CALL_PENDING';
export const ADD_MODAL_WIZARD_SLUG_SUCCESS = 'ADD_MODAL_WIZARD_SLUG_SUCCESS';
export const RESET_MODAL_WIZARD_SLUG_SUCCESS = 'RESET_MODAL_WIZARD_SLUG_SUCCESS';
export const RESET_WIZARD_FIELDS_ONCHANGE = 'RESET_WIZARD_FIELDS_ONCHANGE';
export const RESET_APICALL_WIZARD_RESPONSE = 'RESET_APICALL_WIZARD_RESPONSE';
export const SET_CHECKBOX_STATE = 'SET_CHECKBOX_STATE';
export const DUTCH_API_CALL_WIZARD_API_REQUEST = 'DUTCH_API_CALL_WIZARD_API_REQUEST';
export const DUTCH_API_CALL_WIZARD_API_SUCCESS = 'DUTCH_API_CALL_WIZARD_API_SUCCESS';
export const DUTCH_API_CALL_WIZARD_API_ERROR = 'DUTCH_API_CALL_WIZARD_API_ERROR';
