export default theme => ({
  root: ({ justifyContent }) => ({
    display: 'flex',
    justifyContent: justifyContent || 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    backgroundColor: 'transparent !important',
    margin: `${theme.spacing(1)} !important`,
    [theme.breakpoints.down('sm')]: {
      margin: '0 !important',
    },
  }),
  active: () => ({
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.contrastText} !important`,
    borderStyle: 'solid !important',
    borderColor: `${theme.palette.primary.main} !important`,
  }),
  inActive: () => ({
    // fontWeight: 'bold !important',
    // border: 'none !important',
    borderColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.main} !important`,
    backgroundColor: `${theme.palette.primary.contrastText} !important`,
    borderStyle: 'solid !important',
  }),
  chip: () => ({
    margin: `${theme.spacing(0.5)} !important`,
    // margin: 0,
    borderWidth: '1px !important',
    borderStyle: 'solid',

    '&:hover': {
      borderStyle: 'solid',
      color: `${theme.palette.primary.contrastText} !important`,
      backgroundColor: `${theme.palette.primary.main} !important`,
      borderColor: `${theme.palette.primary.contrastText} !important`,
    },
  }),
});
