import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const MessageCard = ({
  message,
  user,
  agentName,
  messageHeader,
  informationNeeded,
}) => {
  const theme = useTheme();

  const renderMessage = message => {
    const temp = message?.split('\\n');
    return temp?.map(msg => (
      <Typography variant="body1" mb={2} key={msg}>
        {msg}
      </Typography>
    ));
  };

  return (
    <>
      {messageHeader && (
        <Typography variant="body1" color="text.label" fontWeight={500} mb={1}>
          {messageHeader}
        </Typography>
      )}
      <Box
        p={4}
        sx={{
          background: `${theme.palette.background.main}`,
          borderRadius: '8px',
        }}
      >
        <Typography variant="body1">Dear {user?.firstName || 'Customer'},</Typography>
        <br />
        {renderMessage(message)}
        <Typography variant="body1">Thank you, {agentName}</Typography>
        {informationNeeded && informationNeeded}
      </Box>
    </>
  );
};
