import routes from '../../../variables/routes';
import { dateToString, isoStringToDate, removeEmpty } from '../..';
import { getStepIndex } from '..';

export const createApplicationUrl = (response, newApplication) => {
  const { applicationId, applicationStage, legacyResponse } = response;

  let url;

  if (newApplication) {
    url = `${routes.wizards.digitalSalesNewApplicationProcess}`;
  } else if (applicationId) {
    url = `${routes.wizards.digitalSalesNewApplicationProcess}?applicationId=${applicationId}`;
  } else url = `${routes.wizards.digitalSalesNewApplicationProcess}`;

  return url;
};

const createCoveragesPayload = coverageAmount => {
  if (coverageAmount === 100000) {
    return { value: 100000, coverage: '$100,000', code: 'XL1001' };
  }
  if (coverageAmount === 200000) {
    return { value: 200000, coverage: '$200,000', code: 'XL2001' };
  }
  if (coverageAmount === 300000) {
    return { value: 300000, coverage: '$300,000', code: 'XL3001' };
  }
  if (coverageAmount === 400000) {
    return { value: 400000, coverage: '$400,000', code: 'XL4001' };
  }
  if (coverageAmount === 500000) {
    return { value: 500000, coverage: '$500,000', code: 'XL5001' };
  }
  return null;
};

const createBeneficiary = beneficiaries => {
  const newBeneficiaries = beneficiaries?.map(data => {
    return {
      id: data?._id,
      beneficiaryFirstName: data?.firstName,
      beneficiaryLastName: data?.lastName,
      beneficiaryGender: data?.gender,
      beneficiaryDateOfBirth: dateToString(isoStringToDate(data?.dateOfBirth)),
      beneficiarySmokerStatus: data?.smokerStatus,
      beneficiarySplit: data?.percentageSplit,
      beneficiaryAddressStreet1: data?.address?.addressLine1,
      beneficiaryAddressStreet2: data?.address?.addressLine2,
      beneficiaryCity: data?.address?.city,
      beneficiaryRelationship: data?.relationship,
      beneficiaryRelationshipLabel: data?.relationship?.label,
      beneficiaryRelationshipValue: data?.relationship?.value,
      uploadedBeneficiaryIds: data?.uploadedIDs,
    };
  });
  return newBeneficiaries;
};

const createModeOfPayment = modeOfPayment => {
  if (modeOfPayment === 'M') {
    return 'Monthly';
  }
  if (modeOfPayment === 'Q') {
    return 'Quarterly';
  }
  if (modeOfPayment === 'SA') {
    return 'SemiAnnual';
  }
  if (modeOfPayment === 'A') {
    return 'Annual';
  }
  return undefined;
};

export const createApplication = (wizard, response) => {
  response.stage = response?.applicationStage;
  if (response?.stage === 'Underwriting' && response?.legacyResponse?.success) {
    response.stage = 'Documents';
  }
  if (
    response?.stage === 'Redo ID Validation' &&
    response?.onFidoMatchResponse?.payload?.result === 'clear'
  ) {
    response.stage = 'Add Item To Cart';
  }

  const stepIndex = wizard ? getStepIndex(wizard, response)?.toString() : '0';

  const insured = response?.insured;
  const product = response?.product;
  const compliance = response?.compliance;
  const underwriting = response?.underwriting;
  const beneficiaries = response?.beneficiaries;
  const documentsUploaded = response?.documentsUploaded;

  return removeEmpty({
    applicationId: response?.applicationId || response?._id || '',
    onfidoApplicantID: response?.onfidoApplicantID || '',
    onFidoStatus: response?.onFidoMatchResponse,
    stepIndex,
    firstName: insured?.firstName,
    lastName: insured?.lastName,
    gender: insured?.gender?.toLowerCase(),
    dateOfBirth: dateToString(isoStringToDate(insured?.dateOfBirth)),
    countryOfBirth: insured?.countryOfBirth,
    email: insured?.emailAddress,
    maritalStatus: insured?.maritalStatus,
    contact: insured?.contact,
    isSmoker: insured?.smokerStatus?.toLowerCase(),
    addressLine1: insured?.address?.addressLine1,
    addressLine2: insured?.address?.addressLine2,
    city: insured?.address?.city,
    isPoliticallyExposed: insured?.isPoliticallyExposed?.toString(),
    pepType: {
      label: insured?.pepType,
      value: insured?.pepType,
    },
    idType: insured?.identity?.idType,
    idNumber: insured?.identity?.number?.toString(),
    idCountry: insured?.identity?.country,
    idExpDate: dateToString(isoStringToDate(insured?.identity?.expiryDate)),
    companyName: insured?.company?.name,
    companyAddressLine1: insured?.company?.addressLine1,
    companyAddressLine2: insured?.company?.addressLine2,
    companyCity: insured?.company?.city,
    companyCountry: insured?.company?.country,
    occupation: {
      label: insured?.occupation,
      value: insured?.occupation,
    },
    annualIncome: insured?.annualIncome,
    productOfInterest: product?.productOfInterest,
    coverageAmountXL: product?.coverageAmount,
    coverageAmountXLPayload: createCoveragesPayload(product?.coverageAmount),
    modeOfPayment: createModeOfPayment(product?.modeOfPayment),
    coverageType: product?.riders[0]?.name,
    coverageAmountAdditional: product?.riders[0]?.sumAssured,
    commencementDate: dateToString(isoStringToDate(product?.commencementDate)),
    preferredContact: product?.preferredContact,
    preferredAppointmentDate: dateToString(
      isoStringToDate(product?.preferredAppointmentDate),
    ),
    contractReview: product?.preferredContractMode,
    methodOfPayment: product?.methodOfPayment,
    premiumAmount: product?.premiumAmount,
    hasBeneficiaries: response?.hasBeneficiaries?.toString(),
    beneficiaryInformation: createBeneficiary(beneficiaries),
    beneficiaries: createBeneficiary(beneficiaries),
    usCitizen: compliance?.usCitizen?.toString(),
    passportNumber: compliance?.identification?.passportNumber,
    passportIssueDate: dateToString(
      isoStringToDate(compliance?.identification?.issueDate),
    ),
    passportExpiryDate: dateToString(
      isoStringToDate(compliance?.identification?.expiryDate),
    ),
    usResident: compliance?.permanentResident?.toString(),
    greenCardNumber: compliance?.citizenship?.greenCardNumber,
    greenCardIssueDate: dateToString(isoStringToDate(compliance?.citizenship?.issueDate)),
    greenCardExpiryDate: dateToString(
      isoStringToDate(compliance?.citizenship?.expiryDate),
    ),
    criticalIllness: underwriting?.criticalIllness?.toString(),
    drugAbuse: underwriting?.drugAbuseTreatment?.toString(),
    isAIDSpositive: underwriting?.isAIDSpositive?.toString(),
    wasDeclinedInsurance: underwriting?.wasDeclinedInsurance?.toString(),
    hasEasicover: underwriting?.hasEasicover?.toString(),
    uploadProofOfAddress: [
      {
        fileId: documentsUploaded?.proofOfAddress?.fileId,
        fileName: documentsUploaded?.proofOfAddress?.name,
      },
    ],
  });
};

export default createApplicationUrl;
