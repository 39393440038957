import { getEntries } from './arrayManipulation';
import { removeEmpty, toStringObject } from './objectManipulation';
import { domain, introVideoUrl } from '../variables';

export function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

export const createUrl = ({
  scheme = window.location.protocol,
  server = window.location.hostname,
  port = window.location.port,
  path = window.location.pathname,
  queryObject,
}) => {
  const newObject = removeEmpty(queryObject);
  let url = `${scheme}://${server}:${port}/${path}`;
  const param = new URLSearchParams(getEntries(newObject)).toString();
  if (param) url += `?${param}`;
  return url;
};

export const createRelativeUrl = ({
  path = window.location.pathname,
  queryObject = {},
}) => {
  let newObject = removeEmpty(queryObject);
  let url = `${path}`;
  newObject = toStringObject(newObject);
  let entries = getEntries(newObject);
  const stepIndexEntry = entries.find(entry => entry[0] === 'stepIndex');
  if (stepIndexEntry && stepIndexEntry.length > 0 && entries && entries.length > 0) {
    const filteredEntries = entries.filter(entry => entry[0] !== 'stepIndex');
    filteredEntries.unshift(stepIndexEntry);
    entries = [...filteredEntries];
  }
  // console.log('entries: ', entries);
  const param = new URLSearchParams(entries).toString();
  if (param) url += `?${param}`;
  return url;
};

export const createAPIRelativeUrl = ({
  path = window.location.pathname,
  queryObject,
}) => {
  const newObject = removeEmpty(queryObject);
  const hasQuery = path.includes('?');

  const queryString = Object.keys(newObject)
    .map(key => `${key}=${encodeURIComponent(newObject[key])}`)
    .join('&');
  const url = hasQuery ? `${path}&${queryString}` : `${path}?${queryString}`;
  return url;
};

export const getFirstPath = tempPath => {
  return tempPath.split('/')[1] || tempPath;
};

export const getFirstSecondPath = tempPath => {
  // // console.log('tempPath: ', tempPath);
  const firstPath = tempPath?.split('/')[1];
  const secondPath = tempPath?.split('/')[2] || '';
  if (firstPath && secondPath) return `${firstPath}/${secondPath}` || tempPath;
  if (firstPath) return firstPath || tempPath;
  return tempPath;
};

export function restructureDownloadLink(initialUrl) {
  if (isValidHttpUrl(initialUrl)) return initialUrl;
  let url = initialUrl;
  // console.log('initialUrl 1: ', initialUrl);
  // url = initialUrl.replace(/(^\w+:|^)\/\//, '');

  // console.log('url 2: ', url);

  const pattern = /^((http|https|ftp):\/\/)/;
  if (!pattern.test(url)) {
    const subPwa = domain?.pwa.replace(/(^\w+:|^)\/\//, '');
    url = `https://${subPwa}${url}`;
    // url = domain?.alternatePwa + url;
    // console.log('url3: ', url);
  }
  // console.log('url final: ', url);
  return url;
}
