import {
  SUBMIT_RENEWAL_API_ERROR,
  SUBMIT_RENEWAL_API_SUCCESS,
  SUBMIT_RENEWAL_API_REQUEST,
  SUBMIT_TRANSACTION_API_ERROR,
  SUBMIT_TRANSACTION_API_REQUEST,
  SUBMIT_TRANSACTION_API_SUCCESS,
  RESET_TRANSACTION_RENEWAL_SUCCESS,
  GET_PRODUCER_DETAILS_ERROR,
  GET_PRODUCER_DETAILS_REQUEST,
  GET_PRODUCER_DETAILS_SUCCESS,
  GET_LINEOFBUSINESS_REQUEST,
  GET_LINEOFBUSINESS_SUCCESS,
  GET_LINEOFBUSINESS_ERROR,
} from '../../constants/reportActionNames';

// import payloadData from './payloadData';
// import {
//   stringToDate,
//   isoStringToDate,
//   uuidv4,
//   dateToString,
//   dateTimeToString,
// } from '../../utils/functions';

// import lobTypes from '../../utils/variables/lobTypes.json';

export const initialState = {
  isRenewalListingPending: false,
  isRenewalListingError: false,
  isRenewalListingSuccess: false,
  renewalListingError: null,
  renewalListingResponse: null,

  transactionResponse: null,
  isTransactionPending: false,
  isTransactionError: false,
  isTransactionSuccess: false,
  transactionError: null,
  isProducerDetailsPending: false,
  isProducerDetailsError: false,
  isProducerDetailsSuccess: false,
  producerDetailsResponse: null,
  isLineOfBusinessPending: false,
  isLineOfBusinessError: false,
  isLineOfBusinessSuccess: false,
  lineOfBusinessResponse: null,
};

function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_RENEWAL_API_REQUEST:
      return {
        ...state,
        isRenewalListingPending: true,
        isRenewalListingError: false,
        isRenewalListingSuccess: false,
      };
    case SUBMIT_RENEWAL_API_SUCCESS: {
      // console.log(action.payload);
      return {
        ...state,
        isRenewalListingPending: false,
        isRenewalListingError: false,
        isRenewalListingSuccess: true,
        renewalListingResponse: action.payload,
      };
    }

    case SUBMIT_RENEWAL_API_ERROR:
      // console.log(action.payload);
      return {
        ...state,
        isRenewalListingPending: false,
        isRenewalListingError: true,
        isRenewalListingSuccess: false,
        renewalListingError: action.payload,
      };

    case SUBMIT_TRANSACTION_API_REQUEST: {
      return {
        ...state,
        isTransactionPending: true,
        isTransactionError: false,
        isTransactionSuccess: false,
      };
    }

    case SUBMIT_TRANSACTION_API_SUCCESS: {
      return {
        ...state,
        isTransactionPending: false,
        isTransactionError: false,
        isTransactionSuccess: true,
        transactionResponse: action.payload,
      };
    }
    case SUBMIT_TRANSACTION_API_ERROR:
      return {
        ...state,
        isTransactionPending: false,
        isTransactionError: true,
        isTransactionSuccess: false,
        transactionError: action.payload,
      };
    case RESET_TRANSACTION_RENEWAL_SUCCESS: {
      return {
        ...initialState,
        renewalListingResponse: state.renewalListingResponse,
        transactionResponse: state.transactionResponse,
      };
    }
    case GET_PRODUCER_DETAILS_REQUEST: {
      return {
        ...state,
        isProducerDetailsPending: true,
        isProducerDetailsError: false,
        isProducerDetailsSuccess: false,
      };
    }
    case GET_PRODUCER_DETAILS_SUCCESS: {
      return {
        ...state,
        isProducerDetailsPending: false,
        isProducerDetailsError: false,
        isProducerDetailsSuccess: true,
        producerDetailsResponse: action.payload,
      };
    }
    case GET_PRODUCER_DETAILS_ERROR:
      return {
        ...state,
        isProducerDetailsPending: false,
        isProducerDetailsError: true,
        isProducerDetailsSuccess: false,
      };
    case GET_LINEOFBUSINESS_REQUEST: {
      return {
        ...state,
        isLineOfBusinessPending: true,
        isLineOfBusinessSuccess: false,
        isLineOfBusinessError: false,
      };
    }
    case GET_LINEOFBUSINESS_SUCCESS: {
      return {
        ...state,
        isLineOfBusinessPending: false,
        isLineOfBusinessSuccess: true,
        isLineOfBusinessError: false,
        lineOfBusinessResponse: action.payload,
      };
    }
    case GET_LINEOFBUSINESS_ERROR:
      return {
        ...state,
        isLineOfBusinessPending: false,
        isLineOfBusinessSuccess: false,
        isLineOfBusinessError: true,
      };
    default:
      return state;
  }
}

export default reportsReducer;
