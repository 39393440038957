const getStyles = ({
  absolutePosition,
  width = {},
  height = {},
  screenSize,
  defaultHeight,
}) => {
  const newWidth = (width && width[screenSize]) || '100%';
  const newHeight = (height && height[screenSize]) || defaultHeight;

  const styles = { marginLeft: 'auto', marginRight: 'auto' };
  styles.width = newWidth;
  styles.height = newHeight;

  if (absolutePosition === 'bottomCenter') {
    styles.position = 'absolute';
    styles.bottom = '5%';
    styles.left = '50%';
  }
  if (absolutePosition === 'bottomLeft') {
    styles.position = 'absolute';
    styles.bottom = '5%';
    styles.left = '5%';
  }
  if (absolutePosition === 'bottomRight') {
    styles.position = 'absolute';
    styles.bottom = '5%';
    styles.right = '5%';
  }
  if (absolutePosition === 'topCenter') {
    styles.position = 'absolute';
    styles.top = '5%';
    styles.left = '50%';
  }
  if (absolutePosition === 'topRight') {
    styles.position = 'absolute';
    styles.top = '5%';
    styles.right = '5%';
  }
  if (absolutePosition === 'topLeft') {
    styles.position = 'absolute';
    styles.top = '5%';
    styles.left = '5%';
  }
  if (absolutePosition === 'centerLeft') {
    styles.position = 'absolute';
    styles.top = '50%';
    styles.left = '5%';
  }
  if (absolutePosition === 'centerRight') {
    styles.position = 'absolute';
    styles.top = '50%';
    styles.right = '5%';
  }
  return styles;
};

export const getScreenSize = breakpoint => {
  if (breakpoint?.sm) {
    return 'sm';
  }
  if (breakpoint?.md) {
    return 'md';
  }
  if (breakpoint?.lg) {
    return 'lg';
  }
  if (breakpoint?.xl) {
    return 'xl';
  }

  return 'xs';
};

export default getStyles;
