export default ['Active', 'InActive', 'All'];

/*
export default [
  {
    id: 'active',
    status: 'Active',
  },
  {
    id: 'inactive',
    status: 'InActive',
  },
  {
    id: 'all',
    status: 'All',
  },
];
*/
