import { defineMessages } from 'react-intl';

const messages = defineMessages({
  yourCurrentLocation: {
    id: 'app_components_common_locationSwitcher_yourCurrentLocation',
    defaultMessage: 'My Current Location:',
  },
  line: {
    id: 'components_layout_territoryToggle_countryChecker_line',
    defaultMessage:
      "Hi! We've detected that you're located in <b>{{ipLocationName}}</b>, but you switched to <b>{{yourLocationName}}</b>. Do you want to switch back to <b>{{ipLocationName}}</b>?",
  },
  yes: {
    id: 'components_layout_territoryToggle_countryChecker_yes',
    defaultMessage: 'Switch back to {{ipLocationName}}',
  },
  no: {
    id: 'components_layout_territoryToggle_countryChecker_no',
    defaultMessage: 'Continue with {{yourLocationName}}',
  },
  checkline: {
    id: 'components_layout_territoryToggle_countryChecker_checkline',
    defaultMessage: 'Remember this preference until I manually change the country.',
  },
});

export default messages;
