// Hook
import { useSelector } from 'react-redux';
import { getRef } from '../utils/functions';

const getUserType = {
  customer: 'isCustomerEnabled',
  agent: 'isAgentEnabled',
  agentBRK: 'isAgentBRKEnabled',
  agentBIN: 'isAgentBINEnabled',
  agentSAL: 'isAgentSALEnabled',
};

const doFeatureToggle = (name, features, refUserType) => {
  // name = "myClaims.gloc.life"
  const objs = name?.split('.') || [];
  let subFeatures = { ...features };
  let defaultChecked = false;
  for (let i = 0; i < objs.length; i++) {
    subFeatures = getRef(subFeatures, objs[i]);
    if (subFeatures) {
      if (subFeatures[refUserType] === false) {
        defaultChecked = false;
      } else if (
        subFeatures?.isEnabled === undefined ||
        subFeatures?.isEnabled === false
      ) {
        defaultChecked = false;
      } else {
        defaultChecked = true;
      }
    }
  }

  return defaultChecked;
};

const useFeatureToggle = () => {
  // const { features, setFeatures } = useContext(FeatureToggleContext);
  const features = useSelector(state => state?.locations?.yourLocation?.features);
  const userType = useSelector(state => state?.auth?.usertype);
  const userSubType = useSelector(state => state?.auth?.user?.agentType);
  const type = `${userType + (userSubType || '')}`;

  const refUserType = getUserType[type] || null;

  return name => doFeatureToggle(name, features, refUserType);
};
export default useFeatureToggle;
