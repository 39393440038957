import {
  CONFIRM_INFO_API_REQUEST,
  CONFIRM_INFO_API_ERROR,
  CONFIRM_INFO_API_SUCCESS,
} from '../../../constants/authActionNames';

// import { api } from '../../../utils/variables';
import { confirmInfoApi } from '../../../apis/auth';

function confirmInfoError(err) {
  return {
    type: CONFIRM_INFO_API_ERROR,
    payload: err,
  };
}

function confirmInfoRequest() {
  return {
    type: CONFIRM_INFO_API_REQUEST,
    payload: true,
  };
}

function confirmInfoSuccess(user) {
  return {
    type: CONFIRM_INFO_API_SUCCESS,
    payload: user,
  };
}

export function confirmInfoAction(_credentials) {
  const credentials = { ..._credentials };
  // credentials.apiKey = api.apiKey;
  // credentials.apiSecret = api.apiSecret;

  return (dispatch, getState) => {
    const state = getState();
    dispatch(confirmInfoRequest());
    return confirmInfoApi
      .confirmInfo(state, credentials)
      .then(data => {
        dispatch(confirmInfoSuccess(data));
        return data;
      })
      .catch(error => {
        dispatch(confirmInfoError(error));
        throw error;
      });
  };
}

export default {
  confirmInfoAction,
};
