export const cartFilter = state => {
  const { cart = [] } = state?.cart || {};
  const alpha2 = state?.locations?.yourLocation?.alpha2;
  let filteredRows = [...cart];
  filteredRows = filteredRows.filter(el => {
    return el != null;
  });

  filteredRows = filteredRows.filter(el => {
    return el?.body?.alpha2 === alpha2;
  });
  let cartCount = 0;
  // // console.log('cart: ', existingCarts);
  const result = filteredRows.map(subCart =>
    subCart.cart.map(item => {
      cartCount += 1;
      // cartCount += item.quantity;
    }),
  );

  return { rows: filteredRows, count: cartCount };
};

export default {
  cartFilter,
};
