import React from 'react';
import {
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import ContentDisplay from '../contentDisplay';
import { useSmall } from '../../../hooks';

const CookieCategoryCard = ({
  title,
  content,
  defaultChecked = false,
  key,
  required,
  toggleCheckboxValue,
  isChecked,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  // const [checked, setChecked] = React.useState(defaultChecked);
  const isSmall = useSmall();

  return (
    <Grid container spacing={1}>
      <Grid item xs={10} sm={11}>
        <Accordion expanded={expanded}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            onClick={() => setExpanded(!expanded)}
          >
            <div
              style={{
                display: isSmall ? 'block' : 'flex',
                flex: 1,
                justifyContent: 'space-between',
              }}
            >
              <Typography color="textPrimary" variant="body1" display="inline">
                {title}
              </Typography>{' '}
              {required && (
                <Typography color="textPrimary" variant="caption" display="inline">
                  Always Active
                </Typography>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <ContentDisplay content={content} />
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={2} sm={1}>
        <Checkbox
          disabled={required}
          checked={isChecked}
          value={key}
          color="primary"
          // onClick={() => setChecked(!checked)}
          onClick={toggleCheckboxValue}
        />
      </Grid>
    </Grid>
  );
};

export default CookieCategoryCard;
