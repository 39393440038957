import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import routes from '../utils/variables/routes';
import { isValidHttpUrl } from '../utils/functions';
import { validateAuth } from '../hooks/useAuth';
import { useFeatureToggle, useRouter, useVariables } from '../hooks';
import { dutchTemplate } from '../utils/variables';

export default function UnauthenticatedRoute({ component: C, appProps, ...rest }) {
  const { isAuthenticated } = useSelector(state => validateAuth(state));
  const redirect = useSelector(state => state?.misc?.redirectUrl);
  const { redirect: urlRedirect } = useRouter()?.query;
  const variables = useVariables();
  const doFeatureToggle = useFeatureToggle();
  // const useFatumLogin = doFeatureToggle('useFatumLogin');

  const userType = useSelector(state => state.auth.usertype);
  // let redirect = validateCredentialsResponse?.redirect;
  const signOutRoute = routes.account.signOut;

  let newRedirect = urlRedirect || redirect;
  if (
    (newRedirect && newRedirect?.toString()?.startsWith(routes.auth.index)) ||
    newRedirect === signOutRoute
  ) {
    newRedirect = routes.portfolio.portfolioSummary;
  }
  if (
    userType === 'agent' ||
    (variables?.templateName === dutchTemplate && !newRedirect)
  ) {
    newRedirect = routes.home;
  }

  if (newRedirect && newRedirect.length > 0 && isValidHttpUrl(newRedirect)) {
    window.location.href = redirect;
    return null;
  }
  return (
    <Route
      {...rest}
      render={props =>
        !isAuthenticated ? (
          <C {...props} {...appProps} />
        ) : (
          <Redirect to={newRedirect || routes.portfolio.portfolioSummary} />
        )
      }
    />
  );
}
