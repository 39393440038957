import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { InputAdornment, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RemoveRedEye } from '@mui/icons-material';

import styles from './style';

const useStyles = makeStyles(styles);

const PasswordInput = props => {
  const classes = useStyles();

  const [passwordIsMasked, setPasswordMasked] = useState(true);

  const togglePasswordMask = () => {
    setPasswordMasked(!passwordIsMasked);
  };
  return (
    <TextField
      type={passwordIsMasked ? 'password' : 'text'}
      {...props}
      InputProps={{
        autoComplete: 'off',
        endAdornment: (
          <InputAdornment position="end">
            <RemoveRedEye
              className={classes.eye}
              color={passwordIsMasked ? 'inherit' : 'primary'}
              onClick={togglePasswordMask}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordInput;
