import React from 'react';
import classnames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Button,
  Link,
  Skeleton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import routes from '../../../utils/variables/routes';
import { useRouter } from '../../../hooks';
import styles from './style';

const useStyles = makeStyles(styles);

const FeaturedCard = ({
  id,
  title,
  description,
  featuredImage_pwa,
  parentProduct,
  loading,
}) => {
  const classes = useStyles();
  const router = useRouter();

  const productImage = featuredImage_pwa || parentProduct?.featuredImage_pwa;
  const productLink = `${routes.products.index}/${id}`;

  return (
    <Card className={classes.root} onClick={() => router.push(productLink)}>
      <div className={classes.noDecoration}>
        {loading ? (
          <Skeleton
            animation="wave"
            height={10}
            width="80%"
            style={{ marginBottom: 6 }}
          />
        ) : (
          <CardHeader
            title={title}
            className={classnames(classes.title, classes.noDecoration)}
          />
        )}
        <CardContent className={classes.cardContent}>
          {loading ? (
            <>
              <Skeleton
                animation="wave"
                variant="text"
                height={10}
                style={{ marginBottom: 6 }}
              />
              <Skeleton animation="wave" variant="text" height={10} width="80%" />
            </>
          ) : (
            <Typography
              color="textSecondary"
              variant="body2"
              className={classnames(classes.description, classes.noDecoration)}
            >
              {description}
            </Typography>
          )}
        </CardContent>
        {loading ? (
          <Skeleton
            variant="text"
            animation="wave"
            height={10}
            width="40%"
            className={classes.learnMore}
          />
        ) : (
          <Link to={productLink} component={RouterLink}>
            <Typography
              variant="body2"
              align="right"
              color="primary"
              className={classes.learnMore}
            >
              Learn More
            </Typography>
          </Link>
        )}

        {loading ? (
          <Skeleton animation="wave" variant="rect" className={classes.media} />
        ) : (
          <CardMedia
            className={classes.media}
            image={
              productImage?.source?.md?.url ||
              productImage?.source?.sm?.url ||
              productImage?.source?.lg?.url
            }
            // image="/images/motorGuard.jpg"
          />
        )}

        <CardContent align="center">
          {loading ? (
            <Grid container direction="row">
              <Grid item xs={6}>
                <Skeleton animation="wave" height={30} width="80%" />
              </Grid>
              <Grid item xs={6}>
                <Skeleton animation="wave" height={30} width="80%" />
              </Grid>
            </Grid>
          ) : (
            <Grid container direction="row">
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  size="small"
                >
                  Get Quote
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  size="small"
                >
                  Buy Now
                </Button>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </div>
    </Card>
  );
};

export default FeaturedCard;

/*
    subheader={
              tile.coverage ? (
                <Chip label={tile.coverage} color="secondary" />
              ) : (
                <Chip className={classes.subheaderTransparent} />
              )
            }
            */
