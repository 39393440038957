import * as React from 'react';
import { Box, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

export const CSRepContactInfo = ({ name, phone, email, width }) => {
  return (
    <Box
      sx={{
        width: width || '100%',
        color: 'text.secondary',
        display: 'grid',
        gridTemplateColumns: '0.2fr 9.8fr',
        gridColumnGap: '1rem',
        wordBreak: 'break-word',
      }}
    >
      {Boolean(name) && (
        <>
          <PersonIcon fontSize="large" sx={{ justifySelf: 'center', mb: 2.25 }} />
          <Typography fontSize="1.25rem">{name}</Typography>
        </>
      )}
      {Boolean(phone) && (
        <>
          <PhoneIcon fontSize="medium" sx={{ justifySelf: 'center', mb: 2 }} />
          <Typography fontSize="1rem">{phone}</Typography>
        </>
      )}
      {Boolean(email) && (
        <>
          <EmailIcon fontSize="medium" sx={{ justifySelf: 'center' }} />
          <Typography fontSize="1rem">{email}</Typography>
        </>
      )}
    </Box>
  );
};
