import { isString } from './stringManipulation';

const get = require('lodash.get');
const set = require('lodash.set');

export const isJsonString = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const renameKeys = (keysMap, obj) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] },
    }),
    {},
  );

export function compareAsc(a, b, key) {
  // Use toUpperCase() to ignore character casing
  const keyA = a[key];
  const keyB = b[key];

  let comparison = 0;
  if (keyA > keyB) {
    comparison = 1;
  } else if (keyA < keyB) {
    comparison = -1;
  }
  return comparison;
}

/*
var obj = { a: { b: 1, c : { d : 3, e : 4}, f: 5 } }
str = 'a.c.d'
ref(obj, str) // 3
*/
export function getRef(obj, str) {
  if (!str) return null;
  return get(obj, str);
  // return str.split('.').reduce(function(o, x) {
  //  return !!o && o[x];
  // }, obj);
}

/*
    var obj = { a: { b: 1, c : { d : 3, e : 4}, f: 5 } }
    str = 'a.c.d'
    set(obj, str, 99)
    // console.log(obj.a.c.d) // 99
    */
export function setRef(obj, str, val) {
  if (!str) return null;
  // str = str.split('.');
  // while (str.length > 1) obj = obj[str.shift()];
  // return (obj[str.shift()] = val);
  return set(obj, str, val);
}

export function deepen(obj) {
  const result = {};

  // For each object path (property key) in the object
  for (const objectPath in obj) {
    // Split path into component parts
    const parts = objectPath.split('.');

    // Create sub-objects along path as needed
    let target = result;
    while (parts.length > 1) {
      const part = parts.shift();
      target = target[part] = target[part] || {};
    }

    // Set value at end of path
    target[parts[0]] = obj[objectPath];
  }

  return result;
}
/*
    // For example ...
    // console.log(deepen({
      'ab.cd.e': 'foo',
      'ab.cd.f': 'bar',
      'ab.g': 'foo2'
    }));
    {
      "ab": {
        "cd": {
          "e": "foo",
          "f": "bar"
        },
        "g": "foo2"
      }
    }
    */

export const sourceTextFromData = (state, str, defaultText) => {
  // const url = 'https://url.com/{{query}}/foo/{{query2}}';
  // const result = { query: 'queryResult1', query2: 'queryResult2' };
  const text = defaultText || '';
  const newText = (_, g1) => {
    // console.log('g1: ', g1);
    const convertedData = getRef(state, g1) || text;
    // console.log('convertedData: ', convertedData);
    if (typeof convertedData === 'object') {
      return JSON.stringify(convertedData);
    }
    return convertedData;
  };
  // // console.log('str: ', str);
  let subString = str;
  // console.log('subString 1: ', subString);
  /// \|([^\\]+)\\/g

  if (/##(.+?)##/g.test(str)) {
    // return sourceTextFromData(state, g1);
    subString = subString?.replace(/##(.+?)##/g, newText || '');
    // console.log('subString 2: ', subString);
  }

  const newString = subString?.toString()?.replace(/{{(.+?)}}/g, newText || ''); // // console.log('newString: ', newString);
  // console.log('newString: ', newString);
  return newString;
};

export const keyify = (obj, prefix = '') =>
  Object.keys(obj).reduce((res, el) => {
    if (Array.isArray(obj[el])) {
      const objectsInArray = obj[el].filter(el => typeof el === 'object');
      if (objectsInArray.length > 0) {
        let objectKeys = [];
        objectsInArray.map(object => {
          objectKeys = objectKeys.concat(keyify(object, `${prefix + el}.`));
        });
        return [...res, ...new Set(objectKeys)];
      }
      return [...res, prefix + el];
    }
    if (typeof obj[el] === 'object' && obj[el] !== null) {
      return [...res, ...keyify(obj[el], `${prefix + el}.`)];
    }
    if (
      (isString(obj[el]) && obj[el].toString().length > 0) ||
      typeof obj[el] === 'number'
    ) {
      return [...res, prefix + el];
    }

    return res;
  }, []);

export const keyifyString = (obj, prefix = '') =>
  Object.keys(obj).reduce((res, el) => {
    if (Array.isArray(obj[el])) {
      const objectsInArray = obj[el].filter(el => typeof el === 'object');
      if (objectsInArray.length > 0) {
        let objectKeys = [];
        objectsInArray.map(object => {
          objectKeys = objectKeys.concat(keyifyString(object, `${prefix + el}.`));
        });
        return [...res, ...new Set(objectKeys)];
      }
      return [...res, prefix + el];
    }
    if (typeof obj[el] === 'object' && obj[el] !== null) {
      return [...res, ...keyifyString(obj[el], `${prefix + el}.`)];
    }
    if (isString(obj[el]) && obj[el].toString().length > 0) {
      return [...res, prefix + el];
    }

    return res;
  }, []);

export const keyifyOld = (obj, prefix = '') =>
  Object.keys(obj).reduce((res, el) => {
    if (Array.isArray(obj[el])) {
      return res;
    }
    if (typeof obj[el] === 'object' && obj[el] !== null) {
      return [...res, ...keyifyOld(obj[el], `${prefix + el}.`)];
    }
    return [...res, prefix + el];
  }, []);

export const keyifyAll = (obj, prefix = '') =>
  Object.keys(obj).reduce((res, el) => {
    const elDisplayName = `${prefix}${el}`;
    if (Array.isArray(obj[el])) {
      const objectsInArray = obj[el].filter(el => typeof el === 'object');
      if (objectsInArray.length > 0) {
        let objectKeys = [];
        objectsInArray.map(object => {
          objectKeys = objectKeys.concat(keyifyAll(object, `${prefix + el}.`));
        });
        return [...res, ...new Set(objectKeys)];
      }
      return [...res, elDisplayName];
    }
    if (typeof obj[el] === 'object' && obj[el] !== null) {
      return [...res, ...keyifyAll(obj[el], `${prefix + el}.`)];
    }
    return [...res, elDisplayName];
  }, []);

export const removeEmpty = obj => {
  if (!obj) return obj;
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key]);
    else if (obj[key] === undefined || obj[key] === null) delete obj[key];
  });
  return obj;
};

export const flattenObject = obj => {
  const flattened = {};

  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key]));
    } else {
      flattened[key] = obj[key];
    }
  });

  return flattened;
};

export function flattenObjectArray(arr, initialArray) {
  if (Array.isArray(arr)) {
    for (let i = 0, l = arr.length; i < l; ++i) {
      if (arr[i] !== undefined) {
        flattenObjectArray(arr[i], initialArray);
      }
    }
  } else if (typeof arr === 'object') {
    for (const key in arr) {
      if (arr.hasOwnProperty(key)) {
        flattenObjectArray(arr[key], initialArray);
      }
    }
  } else {
    initialArray.push(arr);
  }
  return initialArray;
}

export const reduceKeys = obj => {
  const stringObj = Object.keys(obj).reduce((_object, key) => {
    const object = { ..._object };
    // // console.log('object: ', object);
    if (isString(obj[key]) || typeof obj[key] === 'number') {
      // || !Number.isNaN(obj[key])
      object[key] = obj[key];
      // // console.log('obj[key]: ', obj[key]);
    }
    return object;
  }, {});
  return stringObj;
};
export const getKeys = obj => {
  // // console.log('Object.keys(obj): ', Object.keys(obj));
  // // console.log('stringObj: ', stringObj);
  const keys = keyify(obj);

  return keys;
};

export const getAllKeys = obj => {
  // // console.log('Object.keys(obj): ', Object.keys(obj));
  // // console.log('stringObj: ', stringObj);
  const keys = keyifyAll(obj);

  return keys;
};

export const getOnlyStringKeys = obj => {
  // // console.log('Object.keys(obj): ', Object.keys(obj));
  // // console.log('stringObj: ', stringObj);
  const keys = keyifyString(obj);

  return keys;
};

export const getProperty = (propertyName, object) => {
  const parts = propertyName.split('.');
  const { length } = parts;
  let i;
  let property = object || this;

  for (i = 0; i < length; i++) {
    property = property[parts[i]];
  }

  return property;
};

export const setObjByString = (obj, str, val) => {
  let keys;
  let key;
  // make sure str is a string with length
  if (!str || !str.length || Object.prototype.toString.call(str) !== '[object String]') {
    return false;
  }
  if (obj !== Object(obj)) {
    // if it's not an object, make it one
    obj = {};
  }
  keys = str.split('.');
  while (keys.length > 1) {
    key = keys.shift();
    if (obj !== Object(obj)) {
      // if it's not an object, make it one
      obj = {};
    }
    if (!(key in obj)) {
      // if obj doesn't contain the key, add it and set it to an empty object
      obj[key] = {};
    }
    obj = obj[key];
  }
  return (obj[keys[0]] = val);
};

/**
 * Performs a deep merge of objects and returns new object. Does not modify
 * objects (immutable) and merges arrays via concatenation.
 *
 * @param {...object} objects - Objects to merge
 * @returns {object} New object with merged key/values
 */
export const mergeDeep = (...objects) => {
  const isObject = obj => obj && typeof obj === 'object';

  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach(key => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = pVal.concat(...oVal);
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal);
      } else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, {});
};

export const deepCloneRemoval = obj => {
  // const clone = { ...obj };
  if (!obj) return obj;
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object' && !Array.isArray(obj)) {
      obj[key] = deepCloneRemoval(obj[key]);
    } else if (Array.isArray(obj)) {
      const newArray = [];
      obj.forEach(item => {
        if (item) {
          newArray.push(item);
        }
      });
      obj = newArray;
    }
  });
  return obj;
};

export const isNull = (obj, key) => {
  return (
    obj[key] == null ||
    obj[key] === undefined ||
    obj[key] === 'null' ||
    obj[key] === 'undefined'
  );
};

export const hasTruthyValue = obj => {
  return obj ? Object.values(obj).some(value => Boolean(value)) : false;
};

export const toStringObject = o => {
  Object.keys(o).forEach(k => {
    o[k] = o[k].toString();
  });

  return o;
};

/*
// Test objects
const obj1 = {
  a: 1,
  b: 1, 
  c: { x: 1, y: 1 },
  d: [ 1, 1 ]
}
const obj2 = {
  b: 2, 
  c: { y: 2, z: 2 },
  d: [ 2, 2 ],
  e: 2
}
const obj3 = mergeDeep(obj1, obj2);

// Out
console.log(obj3);
{
  "a": 1,
  "b": 2,
  "c": {
    "x": 1,
    "y": 2,
    "z": 2
  },
  "d": [
    1,
    1,
    2,
    2
  ],
  "e": 2
}
*/
