import {
  FORGOT_PASSWORD_API_REQUEST,
  FORGOT_PASSWORD_API_ERROR,
  FORGOT_PASSWORD_API_SUCCESS,
} from '../../../constants/authActionNames';

// import { api } from '../../../utils/variables';
import { forgotPasswordApi } from '../../../apis/auth';

function forgotPasswordError(err) {
  return {
    type: FORGOT_PASSWORD_API_ERROR,
    payload: err,
  };
}

function forgotPasswordRequest() {
  return {
    type: FORGOT_PASSWORD_API_REQUEST,
    payload: true,
  };
}

function forgotPasswordSuccess(data) {
  return {
    type: FORGOT_PASSWORD_API_SUCCESS,
    payload: data,
  };
}

export function forgotPasswordAction(_credentials) {
  const credentials = { ..._credentials };
  // credentials.apiKey = api.apiKey;
  // credentials.apiSecret = api.apiSecret;

  return (dispatch, getState) => {
    const state = getState();
    dispatch(forgotPasswordRequest());
    return forgotPasswordApi
      .forgotPassword(state, credentials)
      .then(result => {
        dispatch(forgotPasswordSuccess(result));
        return result;
      })
      .catch(error => {
        dispatch(forgotPasswordError(error));
        throw error;
      });
  };
}

export default {
  forgotPasswordAction,
};
