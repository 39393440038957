import {
  GET_YOUR_APP_SETTINGS_API_REQUEST,
  GET_YOUR_APP_SETTINGS_API_SUCCESS,
  GET_YOUR_APP_SETTINGS_API_ERROR,
} from '../../../constants/mainLayout/locationActionNames';

// import { api } from '../../../utils/variables';
import { getYourAppSettingApi } from '../../../apis/mainLayout';

function getYourAppSettingError(err, userType) {
  return {
    type: GET_YOUR_APP_SETTINGS_API_ERROR,
    payload: err,
    userType,
  };
}

function getYourAppSettingRequest() {
  return {
    type: GET_YOUR_APP_SETTINGS_API_REQUEST,
    payload: true,
  };
}
function getYourAppSettingSuccess(loc, userType) {
  return {
    type: GET_YOUR_APP_SETTINGS_API_SUCCESS,
    payload: loc,
    userType,
  };
}

export function getYourAppSettingAction() {
  const apiEndpoint = {}; // ...api };

  return (dispatch, getState) => {
    const state = getState();
    const userType = state?.auth?.usertype;
    dispatch(getYourAppSettingRequest());
    return getYourAppSettingApi
      .getYourAppSetting(state, apiEndpoint)
      .then(data => {
        // Update the app state with the results of the API call.

        dispatch(getYourAppSettingSuccess(data, userType));
        return data;
      })
      .catch(error => {
        // console.error('error: ', error);
        dispatch(getYourAppSettingError(error, userType));
        throw error;
      });
  };
}

export default { getYourAppSettingAction };
