import {
    DOWNLOAD_POLICY_DOCUMENT_API_REQUEST,
    DOWNLOAD_POLICY_DOCUMENT_API_SUCCESS,
    DOWNLOAD_POLICY_DOCUMENT_API_ERROR,
} from '../../../constants/portfolio/policyDocumentActionNames';

import { downloadPolicyDocumentApi } from '../../../apis/portfolio';
import { downloadBlobAsFile } from '../../../utils/functions/downloadUtils';

function downloadPolicyDocumentRequest() {
    return {
        type: DOWNLOAD_POLICY_DOCUMENT_API_REQUEST,
        payload: true,
    };
}
function downloadPolicyDocumentSuccess(document) {
    return {
        type: DOWNLOAD_POLICY_DOCUMENT_API_SUCCESS,
        payload: document,
    };
}

function downloadPolicyDocumentError(err) {
    return {
        type: DOWNLOAD_POLICY_DOCUMENT_API_ERROR,
        payload: err,
    };
}

export function downloadPolicyDocumentAction(params) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(downloadPolicyDocumentRequest());
        return downloadPolicyDocumentApi
            .downloadPolicyDocument(state, params)
            .then(async (data) => {
                downloadBlobAsFile(data);
                dispatch(downloadPolicyDocumentSuccess(data.data));
                return data;
            })
            .catch(error => {
                dispatch(downloadPolicyDocumentError(error));
                throw error;
            });
    };
}
export default {
    downloadPolicyDocumentAction,
};
