export const excludedSeoUrls = [
  {
    url: '/auth/sign-up-contact',
  },
  {
    url: '/auth/confirm-info',
  },
  {
    url: '/auth/sign-up-complete',
  },
  {
    url: '/auth/agent-forgot-password',
  },
  {
    url: '/auth/agent-reset-password',
  },
  {
    url: '/auth/agent-sign-up',
  },
  {
    url: '/auth/verify-identity',
  },
  {
    url: '/auth/before-you-get-started',
  },
  {
    url: '/auth/password-change-required',
  },
  {
    url: '/auth/trusted-device-added',
  },
  {
    url: '/auth/add-nickname',
  },
  {
    url: '/auth/add-new-device',
  },
  {
    url: '/auth/insurancerep',
  },
  {
    url: '/auth/google-redirect',
  },
  {
    url: '/auth/facebook-redirect',
  },
  {
    url: '/auth/trigger-sign-in',
  },
  {
    url: '/auth/set-password',
  },
  {
    url: '/auth/reset-password',
  },
  {
    url: '/auth/reset-success',
  },
  {
    url: '/auth/sign-up',
  },
  {
    url: '/chat',
  },
  {
    url: '/wizards/digital-sales',
  },
  {
    url: '/wizards/request',
  },
  {
    url: '/cart/payment-declined',
  },
  {
    url: '/cart/payment-complete',
  },
  {
    url: '/account/de-register',
  },
  {
    url: '/account/sign-out',
  },
  {
    url: '/account/confirm-account',
  },
  {
    url: '/account/de-register',
  },
  {
    url: '/account/settings',
  },
  {
    url: '/reports',
  },
  {
    url: '/pdf',
  },
  {
    url: '/cart/payment',
  },
  {
    url: '/cart/order-summary',
  },
  {
    url: '/account/de-register',
  },
];

export const wizardSeoUrls = [
  {
    url: '/wizards/motor-quote',
  },
  {
    url: '/wizards/home-quote',
  },
  {
    url: '/wizards/life-quote',
  },
  {
    url: '/wizards/basic-health-quote',
  },
  {
    url: '/wizards/basic-invest-consultation',
  },
  {
    url: '/wizards/basic-pension-quote',
  },
  {
    url: '/wizards/basic-motor-claim',
  },
  {
    url: '/wizards/basic-home-claim',
  },
  {
    url: '/wizards/basic-health-claim',
  },
  {
    url: '/wizards/submit-document-motor',
  },
  {
    url: '/wizards/submit-document-general-insurance',
  },
  {
    url: '/wizards/submit-document-home',
  },
  {
    url: '/wizards/mortgage-calculator',
  },
  {
    url: '/wizards/life-digital-sales-application-process',
  },
];

export default excludedSeoUrls;
