const claimsSummaryStyles = theme => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
    width: '100%',
  },
  pagination: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  formControl: {
    margin: theme.spacing(1),
    // padding: theme.spacing(1),
    minWidth: 60,
  },
  grid: {
    height: '100%',
  },
  gridItem: {
    height: 'auto',
  },
});

export default claimsSummaryStyles;
