import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ConsentContext, ThemeContext } from '../context';
import {
  activateCookies,
  parseBool,
  deleteCookiesAndStorageExceptConsent,
} from '../utils/functions';

import {
  consentKey,
  acceptAllConsentValue,
  acceptCustomConsentValue,
  customConsentKey,
  rejectConcentValue,
} from '../utils/variables';

const useProcessCookies = () => {
  const siteWide = useSelector(state => state?.locations?.siteWide);
  const cookieFeatureDisabled = siteWide?.disableCookieConsent;
  // console.log('cookieFeatureDisabled: ', cookieFeatureDisabled);
  const { consent, setConsent } = useContext(ConsentContext);
  const { themeName: initialThemeName, setThemeName } = useContext(ThemeContext);
  const yourLocation = useSelector(state => state?.locations?.yourLocation);
  const customerId = useSelector(state => state?.auth?.user?.customer?.id);
  const usertype = useSelector(state => state?.auth?.usertype);
  const alpha2 = yourLocation?.alpha2;
  const alpha3 = yourLocation?.alpha3;
  const cookiePolicyId = yourLocation?.cookiePolicyId || null;
  const hasCookiePolicy = !!cookiePolicyId;
  // console.log('hasCookiePolicy: ', hasCookiePolicy);
  const consentValue = consent?.consent; // Cookies.getCookie(consentKey);
  // console.log('consentValue: ', consentValue);
  const customKeys = consent?.customConsent || [];
  // console.log('customKeys: ', customKeys);
  const cookies = consent?.cookies || [];

  return ({ cookieValues, additionalSettings = null }) => {
    const dimensions = {
      theme: initialThemeName,
      alpha2,
      customerId,
      usertype,
      alpha3,
    };
    if (
      consentValue === acceptAllConsentValue ||
      consentValue === acceptCustomConsentValue ||
      cookieFeatureDisabled
    ) {
      const result = activateCookies(
        cookies,
        consentValue,
        customKeys,
        cookieValues,
        additionalSettings,
        dimensions,
        hasCookiePolicy,
      );
      // console.log('result: ', result);
      return result;
    }
    if (consentValue === rejectConcentValue) {
      // window[`ga-disable-${getGATrackingID()}`] = true;
      deleteCookiesAndStorageExceptConsent();
      return null;
    }
    return null;
  };
};

export default useProcessCookies;
