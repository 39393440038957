import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export function TopicCard({ imageURL, title, content, width }) {
  const theme = useTheme();
  return (
    <Card
      sx={{
        width: width || '100%',
        borderRadius: '0.5rem',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
        boxSizing: 'border-box',
      }}
    >
      <CardMedia
        component="img"
        image={imageURL}
        alt={title}
        sx={{
          aspectRatio: '16/9',
        }}
      />
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          pb: 3,
          borderBottomLeftRadius: '0.5rem',
          borderBottomRightRadius: '0.5rem',
          borderBottom: `1px solid ${theme.palette.primary.main}`,
          borderRight: `1px solid ${theme.palette.primary.main}`,
          borderLeft: `1px solid ${theme.palette.primary.main}`,
        }}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{
            color: 'primary.main',
            fontFamily: 'default',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '1.125rem',
            lineHeight: '1.75rem',
            mb: 0.5,
            width: {
              xs: '100%',
              sm: '100%',
              md: '100%',
              lg: '21.25rem',
            },
            height: '1.625rem',
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            width: {
              xs: '100%',
              sm: '100%',
              md: '100%',
              lg: '18.25rem',
            },
            height: '3.25rem',
            fontFamily: 'default',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '1rem',
            lineHeight: '1.5rem',
            textAlign: 'center',
            color: 'text.disabled',
          }}
        >
          {content}
        </Typography>
      </CardContent>
    </Card>
  );
}
