import { gql } from 'graphql-request';

const PARAM_FIELDS = gql`
  fragment param on ComponentMiscComponentsApiParam {
    key
    reducerKey
    staticValue
    isBoolean
    isNumeric
    isBooleanInteger
    isAge
    isMonths
    isYearMonthString
    isAgent
    isSmoker
    addToFiles
    isFixedToTwo
    isStringDotNotation
    hasExtraParams
    isArrayParam
    arrayParams {
      key
      reducerKeyValue
      hasExtraParams
      extraParams
      isStringDotNotation
      staticValue
      dontSetIfEmpty
      emptyKey
      isValidBoolean
      isYearOnly
      isNumeric
    }
    isTitleCase
    isCurrency
    transformPeriod
    isYearOnly
    isAlwaysArray
    isValidBoolean
    isBooleanOrString
    dontSetIfEmpty
    emptyKey
    isEnv
    unFormatAlpha2
    formatAlpha2
    conditions {
      ...condition
    }
  }
`;

// export default PARAM_FIELDS;
// exports.default = PARAM_FIELDS;
export default PARAM_FIELDS;
