export default ['Submitted', 'Processing', 'Completed'];
/*
export default [
  {
    id: 'submitted',
    status: 'Submitted',
  },
  {
    id: 'processing',
    status: 'Processing',
  },
  {
    id: 'completed',
    status: 'Completed',
  },
  {
    id: 'all',
    status: 'All',
  },
];
*/
