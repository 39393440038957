import { routerPatchCall, routerPostCall } from '../../axios';

export const submitClaimDetailsUrl = '/account-services/submitted-claims';
const submitClaimDetails = (state, params) => {
  const url = submitClaimDetailsUrl;
  return routerPostCall(state, url, params);
};

export const saveClaimDetailsUrl = '/account-services/submitted-claims';
const saveClaimDetails = (state, params, claimId) => {
  const url = `${saveClaimDetailsUrl}/${claimId}`;
  return routerPatchCall(state, url, params);
};

export default { submitClaimDetails, saveClaimDetails };
