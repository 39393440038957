import React from 'react';
import { Grid } from '@mui/material';
import { ErrorAlert } from '../../common';
import {
  uuidv4,
  toTitleCase,
  createRelativeUrl,
  dateToString,
  splitCamelCaseToString,
} from '../../../utils/functions';

import {
  claimsIndexName,
  requestsIndexName,
  receiptsIndexName,
  quotesIndexName,
  insuredItemsIndexName,
  digitalSalesName,
  statusOk,
  clientPoliciesIndexName,
} from '../../../utils/variables';

const ListError = ({
  name,
  options,
  isError,
  isPending,
  isSuccess,
  customInfoTitle,
  customInfoMessage,
  displayName,
  headers,
  statusCode,
}) => {
  let filterDateType = '';
  if (name === quotesIndexName) {
    filterDateType = 'Created Dates:';
  }
  if (name === claimsIndexName) {
    filterDateType = 'Reported Dates:';
  }
  if (name === requestsIndexName) {
    filterDateType = 'Created Dates:';
  }
  if (name === receiptsIndexName) {
    filterDateType = 'Paid Dates:';
  }
  if (name === insuredItemsIndexName) {
    filterDateType = 'Start Dates:';
  }
  if (displayName) {
    name = displayName;
  }

  let filterIdMessage = '';
  if (options.filterId) {
    filterIdMessage = `, for ${options.filterId}`;
  }

  let filterSearch = '';
  if (options.search && options.search.length > 0) {
    filterSearch += `, with keyword "${options.search}"`;
  }

  let filterLobTitle = '';
  if (options.lob && options.lob.length > 0 && options.lob !== 'all') {
    const tempLobs = options.lob.map(x => toTitleCase(x));
    filterLobTitle = ` "${tempLobs.join(', ')}"`;
  }

  let filterStatus = '';
  if (options?.status && options.status.length > 0) {
    filterStatus = `, with status "${options?.status?.join(', ')}"`;
  }

  let filterDates = '';
  // console.log('displayName: ', displayName);
  // console.log('name: ', name);
  if (options.startDate && options.endDate) {
    filterDates = filterIdMessage ? ', and' : ',';
    filterDates += ` between ${filterDateType} "${dateToString(
      options?.startDate,
    )} and ${dateToString(options?.endDate)}"`;
  }
  return (
    <Grid container justify="center" alignItems="center" direction="row" spacing={2}>
      <Grid item xs={12} md={12}>
        <ErrorAlert
          isError={isError}
          isPending={isPending}
          isSuccess={isSuccess}
          infoTitle={
            customInfoTitle ||
            `No${filterLobTitle} ${toTitleCase(
              splitCamelCaseToString(name),
            )} Available${filterIdMessage}.`
          }
          errorTitle={`Error retrieving${filterLobTitle} ${toTitleCase(
            splitCamelCaseToString(name),
          )}${filterIdMessage}.`}
          infoMessage={
            customInfoMessage ||
            `Sorry, you don't have any${filterLobTitle} ${splitCamelCaseToString(
              name,
            )} available${filterIdMessage}${filterStatus}${filterSearch}${filterDates}.`
          }
          errorMessage={`Error retrieving your${filterLobTitle} ${splitCamelCaseToString(
            name,
          )}${filterIdMessage}${filterStatus}${filterSearch}${filterDates}. Please try again.`}
          headers={headers}
          statusCode={statusCode}
        />
      </Grid>
    </Grid>
  );
};

export default ListError;
