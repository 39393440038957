import { SET_AUTH_USERNAME_SUCCESS } from '../../../constants/authActionNames';

export function setAuthUsernameAction(data, options) {
  return {
    type: SET_AUTH_USERNAME_SUCCESS,
    payload: data,
    options,
  };
}

export default {
  setAuthUsernameAction,
};
