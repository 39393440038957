import React, { useState } from 'react';
import classnames from 'classnames';
import { Button, Grid, Icon, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { uuidv4 } from '../../../utils/functions';
import ThemeSwitcher from '../themeSwitcher';

import sanitizeText from '../../../utils/functions/sanitizeText';
import CookiePolicyModal from './cookiePolicyModal';
import CookieSettingsModal from './cookieSettingsModal';

const styles = theme => {
  return {
    button: {
      // margin: theme.spacing(1),
      padding: theme.spacing(2),
    },
    text: {
      textTransform: 'capitalize',
    },
    fullWidth: {
      width: '100%',
    },
    marginTop: {
      marginTop: theme.spacing(1),
    },
  };
};

const useStyles = makeStyles(styles);

const CookieSnackbarContent = props => {
  const {
    onAcceptAll,
    onAcceptCustom,
    onAcceptStrict,
    onReject,
    snackbarMessage,
    snackbarFootnote,
    snackbarFootnoteVariant,
    snackbarFootnoteColor,
    showSnackbarFootnoteCookiePolicy,
    snackbarFootnoteConfig,
    snackbarMessageConfig,
    snackbarActionsConfig,
    snackbarActions,
    snackbarActionColumns,
    disabled,
    ...restProps
  } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const onModal = () => {
    setModalOpen(true);
  };
  const classes = useStyles();
  const findMethod = type => {
    let method = onAcceptAll;
    if (type === 'reject') {
      method = onReject;
    } else if (type === 'acceptAll') {
      method = onAcceptAll;
    } else if (type === 'acceptCustom') {
      method = onAcceptCustom;
    } else if (type === 'acceptStrict') {
      method = onAcceptStrict;
    } else if (type === 'modal') {
      method = onModal;
    }
    return method;
  };

  let newSnackbarMessage = '';
  if (snackbarMessage && snackbarMessage !== 'undefined' && snackbarMessage !== 'null') {
    newSnackbarMessage = sanitizeText(snackbarMessage);
  }

  const displayActionButtons = snackbarActions?.map(action => {
    const {
      title,
      hidden = false,
      color,
      variant,
      type,
      size,
      startIcon,
      endIcon,
      fullWidth,
    } = action;
    if (hidden) return null;

    return (
      <Grid
        key={uuidv4()}
        item
        md={12 / snackbarActionColumns}
        className={classes.fullWidth}
      >
        <Button
          color={color || 'inherit'}
          size={size || 'large'}
          variant={variant || 'contained'}
          onClick={findMethod(type)}
          className={classes.button}
          startIcon={startIcon && <Icon>{startIcon}</Icon>}
          endIcon={endIcon && <Icon>{endIcon}</Icon>}
          fullWidth={fullWidth}
          disabled={disabled}
        >
          <Typography variant="body1" className={classes.secondaryButton}>
            {title}
          </Typography>
        </Button>
      </Grid>
    );
  });
  return (
    <>
      <div style={{ position: 'absolute', top: 0, right: 20 }}>
        <ThemeSwitcher removeInnerPadding />
      </div>

      <Grid container justifyContent="flex-start" spacing={2} sx={{ mt: 0.5 }}>
        {newSnackbarMessage && (
          <Grid
            item
            xs={snackbarMessageConfig?.xs || 12}
            sm={snackbarMessageConfig?.sm || 12}
            md={snackbarMessageConfig?.md || 12}
            className={classnames(classes.fullWidth, classes.marginTop)}
          >
            {/* <ContentDisplay content={snackbarMessage} /> */}
            <div dangerouslySetInnerHTML={{ __html: `${newSnackbarMessage}` }} />
          </Grid>
        )}
        {snackbarFootnote && (
          <Grid
            item
            xs={snackbarFootnoteConfig?.xs || 12}
            sm={snackbarFootnoteConfig?.sm || 12}
            md={snackbarFootnoteConfig?.md || 12}
            className={classes.fullWidth}
          >
            <Typography
              display="inline"
              color={snackbarFootnoteColor}
              variant={snackbarFootnoteVariant}
            >
              {snackbarFootnote}
            </Typography>{' '}
            {showSnackbarFootnoteCookiePolicy && <CookiePolicyModal {...restProps} />}
          </Grid>
        )}
        {displayActionButtons && (
          <Grid
            item
            xs={snackbarActionsConfig?.xs || 12}
            sm={snackbarActionsConfig?.sm || 12}
            md={snackbarActionsConfig?.md || 12}
          >
            <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
              {displayActionButtons}
            </Grid>
          </Grid>
        )}
        {modalOpen && (
          <CookieSettingsModal
            setModalOpen={setModalOpen}
            findMethod={findMethod}
            {...restProps}
            disabled={disabled}
          />
        )}
      </Grid>
    </>
  );
};

CookieSnackbarContent.defaultProps = {
  onAccept: () => {},
  onReject: () => {},
  onModal: () => {},
  snackbarMessage: '<h2>Main content message</h2>',
  snackbarFootnote: 'Footnote content message, long text im using to test',
  snackbarFootnoteConfig: { md: 6, sm: 12, xs: 12 },
  snackbarMessageConfig: { md: 12, sm: 12, xs: 12 },
  snackbarActionsConfig: { md: 6, sm: 12, xs: 12 },
  snackbarActions: [
    {
      title: 'Manage Cookies',
      startIcon: 'settings',
      color: 'secondary',
      variant: 'text',
      type: 'modal',
      fullWidth: true,
    },
    { title: 'Accept All Cookies', color: 'primary', type: 'accept', fullWidth: true },
  ],
  snackbarActionColumns: 2,
  snackbarFootnoteVariant: 'caption',
  snackbarFootnoteColor: 'initial',
  showSnackbarFootnoteCookiePolicy: true,
  cookiePolicyTitle: 'Cookie Policy',
  cookiePolicyButtonTitle: 'Cookie Policy',
  cookiePolicyContent: '<h1>This is a test</h1>',

  cookieSetting: {
    title: 'Manage Cookie Preferences',
    description: 'this is a sample description',
    cookieCategories: [
      {
        title: 'Strictly necessary cookies',
        defaultChecked: true,
        key: 'strictlyNecessary',
        content: '<h1>This is a test</h1>',
        required: true,
        cookies: [
          { slug: 'google-analytics-page-views', title: 'Google Analytics Page Views' },
        ],
      },
      {
        title: 'Performance cookies',
        defaultChecked: true,
        key: 'performance',
        content: '<h1>This is a test</h1>',
        required: false,
        cookies: [
          { slug: 'google-analytics-page-views', title: 'Google Analytics Page Views' },
        ],
      },
      {
        title: 'Functional cookies',
        defaultChecked: false,
        key: 'functional',
        content: '<h1>This is a test</h1>',
        required: false,
        cookies: [{ slug: 'user-preferred-theme', title: 'User Preferred Theme' }],
      },
      {
        title: 'Advertising cookies',
        defaultChecked: true,
        key: 'advertising',
        content: '<h1>This is a test</h1>',
        required: false,
        cookies: [{ slug: 'google-analytics-ads', title: 'Google Analytics Ads' }],
      },
    ],
    showCookiePolicy: true,
    cookieActions: [
      { title: 'Confirm Cookies', color: 'primary', type: 'accept', fullWidth: true },
    ],
    cookieSettingActionColumns: 1,
  },
};

export default CookieSnackbarContent;
