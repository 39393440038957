import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { InputBase, IconButton, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Search as SearchIcon } from '@mui/icons-material';
import { useRouter } from '../../../../hooks';
import { createRelativeUrl } from '../../../../utils/functions';
import { resetInsurance } from '../../../../actions/portfolio';
import styles from './style';
import { searchKey } from '../../../../utils/variables';

const useStyles = makeStyles(styles);

const Search = ({ hostname }) => {
  const classes = useStyles();
  const router = useRouter();
  const dispatch = useDispatch();
  const parsed = router?.query || {};
  const defaultSearchValue = parsed?.search;
  const [searchValue, setSearchValue] = useState(defaultSearchValue);

  const handleSearch = () => {
    dispatch(resetInsurance.resetInsuranceAction());
    parsed.search = searchValue;
    parsed.scrollKey = searchKey;
    parsed.startIndex = 0;
    const relativeUrl = createRelativeUrl({
      path: hostname || router.pathname,
      queryObject: parsed,
    });
    // console.log('relativeUrl: ', relativeUrl);
    router.push(relativeUrl);
  };

  const handleChange = value => {
    setSearchValue(value);
  };

  const onBlur = e => {
    // console.log('searchValue: ', searchValue);
    if (!searchValue || searchValue.length === 0) {
      const newValue = '';
      handleSearch(newValue);
    }
  };

  return (
    <>
      <Grid item xs={8} sm={9} md={8} align="center">
        <InputBase
          placeholder="   Keyword"
          classes={{
            root: classes.searchBox,
            input: classes.search,
          }}
          inputProps={{ 'aria-label': 'search' }}
          value={searchValue}
          onBlur={onBlur}
          onChange={e => {
            const newValue = e.target.value;
            handleChange(newValue);
          }}
          onKeyPress={ev => {
            // console.log(`Pressed keyCode ${ev.key}`);
            if (ev.key === 'Enter') {
              // Do code here
              ev.preventDefault();
              handleSearch(searchValue);
            }
          }}
        />
      </Grid>
      <Grid item xs={2} sm={1} md={2} align="center">
        <IconButton className={classes.searchIcon} onClick={handleSearch}>
          <SearchIcon />
        </IconButton>
      </Grid>
    </>
  );
};

export default Search;
