import React, { useState, useEffect } from 'react';
import { Button, Icon, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import SurveyComponent from '../surveyComponent';
import DetailsModal from '../../modal/detailsModal';
import { getButtonColorFromVariant } from '../../../utils/functions';

const SurveyModal = ({
  buttonVariant,
  buttonColor,
  buttonText,
  buttonSize,
  buttonStartIcon,
  headingText,
  headingVariant,
  type = 'normal',
  customContext,
  classes,
}) => {
  const initialSurvey = useSelector(state => state?.misc?.survey);
  let survey = null;
  if (initialSurvey) {
    survey = {
      ...initialSurvey,
      ...initialSurvey?.survey,
    };
  }
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const escFunction = event => {
    if (event.keyCode === 27) {
      // console.log('keyCode: ', event.keyCode);
      // Do whatever when esc is pressed
      handleClose();
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('keydown', escFunction, false);
    }

    return () => {
      try {
        document.removeEventListener('keydown', escFunction, false);
      } catch (e) {
        console.log('e: ', e);
      }
    };
  }, [open]);

  return (
    <>
      {headingText && (
        <Typography variant={headingVariant || 'body1'}>{headingText}</Typography>
      )}
      <Button
        variant={buttonVariant || 'contained'}
        color={buttonColor || 'primary'}
        size={buttonSize || 'large'}
        startIcon={buttonStartIcon && <Icon data-nosnippet>{buttonStartIcon}</Icon>}
        onClick={handleOpen}
        className={classes[getButtonColorFromVariant(buttonVariant)]}
        sx={{ fontWeight: 'bold' }}
      >
        {buttonText}
      </Button>
      {open && type === 'custom' && (
        <DetailsModal
          fullWidth
          onClose={handleClose}
          // title={survey?.title}
          content={
            <SurveyComponent
              {...survey}
              renderType="onLoad"
              type="embed"
              embedType="inline"
              defaultHeight="70vh"
              isModal
              customContext={customContext}
            />
          }
        />
      )}
      {open && type === 'normal' && (
        <SurveyComponent
          {...survey}
          renderType="onLoad"
          type="embed"
          embedType="popup"
          isModal
          customContext={customContext}
        />
      )}
    </>
  );
};

export default SurveyModal;
