import React, { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext, ConsentContext } from '../../../context';

import { useGetThemeByName } from '../../../themes';
import { postIframe } from '../../../utils/functions';

const SyncData = () => {
  const { themeName } = useContext(ThemeContext);
  const { consent } = useContext(ConsentContext);
  const user = useSelector(state => state.auth.user);
  const yourLocation = useSelector(state => state.locations.yourLocation);

  const language = useSelector(state => state.locations.language);
  // console.log('language: ', language);
  const alpha2 = yourLocation?.alpha2;
  // console.log('alpha2: ', alpha2);

  const getThemeByName = useGetThemeByName();

  const sendDataToIframe = () => {
    // const switchTheme = getThemeByName(themeName);
    // const formTheme = formatThemeToForm(switchTheme, themeName);
    postIframe({
      theme: themeName,
      // user,
      alpha2,
      language,
      consent,
    });
  };

  useEffect(() => {
    sendDataToIframe();
  }, [
    themeName,
    alpha2,
    language,
    consent?.consent,
    consent?.customConsent,
    consent?.version,
  ]); // user?.accessToken,

  return <div style={{ display: 'none' }}>Syncing Data with C360</div>;
};

export default SyncData;
