import { routerPostCall } from '../../axios';

const resetPassword = (state, params) => {
  const url = `/auth/password/reset`;
  return routerPostCall(state, url, params);
};
const agentResetPassword = (state, params) => {
  const url = `/auth/webstar/agentResetPassword`;
  return routerPostCall(state, url, params);
};
export default { resetPassword, agentResetPassword };
