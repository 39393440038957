import TagManager from 'react-gtm-module';
import { getAppMode } from './misc';
import { isNull } from './objectManipulation';

const GTM_TRACKING_ID = 'GTM-WVN7797';

export function validateGTMObj(_obj) {
  if (!_obj || typeof _obj !== 'object') return _obj;
  const obj = { ..._obj };
  const objKeys = Object.keys(obj);
  objKeys.forEach(key => {
    if (isNull(obj, key)) {
      obj[key] = '';
    }
    if (typeof obj[key] === 'object') {
      validateGTMObj(obj[key]);
    }
  });
  return obj;
}

export const loadGoogleTagManager = dataLayerObj => {
  const appMode = getAppMode();
  if (appMode) {
    dataLayerObj.appMode = appMode;
  }

  const tagManagerArgs = {
    gtmId: GTM_TRACKING_ID,
    dataLayer: { ...validateGTMObj(dataLayerObj) },
    dataLayerName: 'CustomDataLayer',
  };

  TagManager.initialize(tagManagerArgs);
};

export const updateDataLayer = dataLayerObj => {
  const appMode = getAppMode();
  if (appMode) {
    dataLayerObj.appMode = appMode;
  }
  // console.log('dimensions: ', dimensions);
  const tagManagerArgs = {
    dataLayer: {
      ...validateGTMObj(dataLayerObj),
    },
    dataLayerName: 'CustomDataLayer',
  };

  TagManager.dataLayer(tagManagerArgs);
};
