import React from 'react';
import { makeStyles } from '@mui/styles';
import styles from './style';

const useStyles = makeStyles(styles);

const BackgroundImage = props => {
  const { imageUrl, bottom = 0, opacity = 0.8, backgroundPosition, objectFit } = props;
  const classes = useStyles(props);
  return (
    <div
      style={{ opacity, bottom, backgroundImage: `url(${imageUrl})` }}
      className={classes.backgroundImage}
    />
  );
};

export default BackgroundImage;
