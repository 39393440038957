import * as React from 'react';
import { useState } from 'react';
import { Typography, Box, FormHelperText } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';

export function BasicDatePicker({
  label,
  value,
  handleChange,
  required,
  styles,
  background,
  error,
  width,
  helperText,
  name,
  setTouched,
  disable,
  ...props
}) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const formatDate = date => {
    return date ? format(date, 'dd/MMM/yyyy').toUpperCase() : '';
  };

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: '100%',
          md: width || '100%',
        },
        '& .MuiFormControl-root.MuiTextField-root': {
          borderRadius: '0.5rem',
        },
      }}
      {...props}
    >
      <Typography
        sx={
          styles || {
            color: 'text.label',
            fontSize: '1rem',
            fontWeight: '500',
            mb: '0.5rem',
          }
        }
      >
        {label}
        {required ? ' *' : null}
      </Typography>
      <MobileDatePicker
        openTo="year"
        views={['year', 'month', 'day']}
        //dateformat="DD-MM-YYYY"
        inputFormat="dd/MMM/yyyy"
        required
        value={value}
        onChange={e => {
          handleChange(e);
        }}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => {
          setOpen(false);
          setTouched && setTouched({ date: true });
        }}
        slotProps={{
          textField: {
            InputProps: {
              name: { name },
              placeholder: 'DD/MMM/YYYY', // Custom placeholder
              sx: {
                '& fieldset.MuiOutlinedInput-notchedOutline': {
                  borderColor: !error ? '#DADADA !important' : 'error.main',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: error
                    ? '1px solid error.main'
                    : '1px solid background.contentBox',
                  borderRadius: '0.5rem',
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '0.5rem',
                  background: background || 'background.main',
                },
                '& .MuiInputBase-input': {
                  padding: '12px 0px 12px 20px',
                  border: '1px solid background.banner',
                  borderRadius: '0.5rem !important',
                  color: value ? 'text.primary' : 'text.primary',
                },
              },
              error: { error },
              helperText: { helperText },
              value: formatDate(value), // Set formatted date value
              disabled: disable,
            },
          },
        }}
      />
      {helperText && (
        <FormHelperText
          sx={{
            color: 'error.main',
          }}
          error={error}
        >
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
}
