export const GET_ALL_HELP_CENTER_CASES_API_REQUEST =
  'GET_ALL_HELP_CENTER_CASES_API_REQUEST';
export const GET_ALL_HELP_CENTER_CASES_API_ERROR = 'GET_ALL_HELP_CENTER_CASES_API_ERROR';
export const GET_ALL_HELP_CENTER_CASES_API_SUCCESS =
  'GET_ALL_HELP_CENTER_CASES_API_SUCCESS';

export const GET_SINGLE_HELP_CENTER_CASE_API_REQUEST =
  'GET_SINGLE_HELP_CENTER_CASE_API_REQUEST';
export const GET_SINGLE_HELP_CENTER_CASE_API_ERROR =
  'GET_SINGLE_HELP_CENTER_CASE_API_ERROR';
export const GET_SINGLE_HELP_CENTER_CASE_API_SUCCESS =
  'GET_SINGLE_HELP_CENTER_CASE_API_SUCCESS';

export const GET_TICKET_HISTORY_API_REQUEST = 'GET_TICKET_HISTORY_API_REQUEST';
export const GET_TICKET_HISTORY_API_ERROR = 'GET_TICKET_HISTORY_API_ERROR';
export const GET_TICKET_HISTORY_API_SUCCESS = 'GET_TICKET_HISTORY_API_SUCCESS';

export const RE_OPEN_OR_CANCEL_CASE_API_REQUEST = 'RE_OPEN_CASE_API_REQUEST';
export const RE_OPEN_OR_CANCEL_CASE_API_ERROR = 'RE_OPEN_CASE_API_ERROR';
export const RE_OPEN_OR_CANCEL_CASE_API_SUCCESS = 'RE_OPEN_CASE_API_SUCCESS';

export const GET_SURVEY_LINK_REQUEST = 'GET_SURVEY_LINK_REQUEST';
export const GET_SURVEY_LINK_ERROR = 'GET_SURVEY_LINK_ERROR';
export const GET_SURVEY_LINK_SUCCESS = 'GET_SURVEY_LINK_SUCCESS';

export const GET_REQUIRED_DOCUMENTS_LIST_REQUEST = 'GET_REQUIRED_DOCUMENTS_LIST_REQUEST';
export const GET_REQUIRED_DOCUMENTS_LIST_ERROR = 'GET_REQUIRED_DOCUMENTS_LIST_ERROR';
export const GET_REQUIRED_DOCUMENTS_LIST_SUCCESS = 'GET_REQUIRED_DOCUMENTS_LIST_SUCCESS';
