import { crmPostCall } from '../../../axios';
import { helpCenterApi } from '../../../../utils/functions/helpCenterApis';
import { GETSURVEYLINKURL, SERVERERROR } from '../../constants';

export const getSurveyLinkUrl = helpCenterApi(GETSURVEYLINKURL);
const getSurveyLink = (state, body) => {
  const url = getSurveyLinkUrl;

  if (url) {
    return crmPostCall(state, url, body);
  }
  return Promise.reject(new Error(SERVERERROR));
};
export default { getSurveyLink };
