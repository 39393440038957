import { gql } from 'graphql-request';

const TOGGLE_BUTTON_CHOICE_FIELDS = gql`
  fragment toggleButtonChoice on ComponentWizardComponentsToggleButtonChoice {
    ToggleButtonChoice_slug: slug
    ToggleButtonChoice_name: name
    ToggleButtonChoice_label: label
    ToggleButtonChoice_labelAlign: labelAlign
    ToggleButtonChoice_size: size
    ToggleButtonChoice_exclusive: exclusive
    ToggleButtonChoice_layoutPosition: layoutPosition
    ToggleButtonChoice_staticValue: staticValue
    ToggleButtonChoice_reducerKeyValue: reducerKeyValue
    ToggleButtonChoice_positionNo: positionNo
    ToggleButtonChoice_options: options {
      ...nonRecursiveOption
    }
    ToggleButtonChoice_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    ToggleButtonChoice_fullWidth: fullWidth
    ToggleButtonChoice_required: required
    ToggleButtonChoice_tooltip: tooltip
    ToggleButtonChoice_variant: variant
    ToggleButtonChoice_isConditionalComponent: isConditionalComponent
    ToggleButtonChoice_conditionalValue: conditionalValue
    ToggleButtonChoice_conditionalKey: conditionalKey
    ToggleButtonChoice_conditionalOperator: conditionalOperator
    ToggleButtonChoice_conditionalFieldType: conditionalFieldType
    ToggleButtonChoice_conditionalAction: conditionalAction
    ToggleButtonChoice_conditionalArrayKey: conditionalArrayKey
    ToggleButtonChoice_disable: disable
    ToggleButtonChoice_show: show
    ToggleButtonChoice_tooltip_en: tooltip_en
    ToggleButtonChoice_tooltip_nl: tooltip_nl
    ToggleButtonChoice_label_en: label_en
    ToggleButtonChoice_label_nl: label_nl
    ToggleButtonChoice_disableInitialValue: disableInitialValue
    ToggleButtonChoice_conditions: conditions {
      ...condition
    }
  }
`;

// export default TOGGLE_BUTTON_CHOICE_FIELDS;
// exports.default = TOGGLE_BUTTON_CHOICE_FIELDS;
export default TOGGLE_BUTTON_CHOICE_FIELDS;
