import {
  VERIFY_IDENTITY_CONFIRM_API_REQUEST,
  VERIFY_IDENTITY_CONFIRM_API_ERROR,
  VERIFY_IDENTITY_CONFIRM_API_SUCCESS,
  VERIFY_IDENTITY_SEND_API_ERROR,
  VERIFY_IDENTITY_SEND_API_SUCCESS,
  VERIFY_IDENTITY_SEND_API_REQUEST,
  SIGNUP_VERIFY_IDENTITY_CONFIRM_API_REQUEST,
  SIGNUP_VERIFY_IDENTITY_CONFIRM_API_ERROR,
  SIGNUP_VERIFY_IDENTITY_CONFIRM_API_SUCCESS,
  FORGOT_VERIFY_IDENTITY_CONFIRM_API_REQUEST,
  FORGOT_VERIFY_IDENTITY_CONFIRM_API_ERROR,
  FORGOT_VERIFY_IDENTITY_CONFIRM_API_SUCCESS,
  FORGOT_VERIFY_IDENTITY_SEND_API_ERROR,
  FORGOT_VERIFY_IDENTITY_SEND_API_SUCCESS,
  FORGOT_VERIFY_IDENTITY_SEND_API_REQUEST,
  VERIFY_IDENTITY_SEND_SMS_API_ERROR,
  VERIFY_IDENTITY_SEND_SMS_API_SUCCESS,
  VERIFY_IDENTITY_SEND_SMS_API_REQUEST,
} from '../../../constants/authActionNames';

// import { api } from '../../../utils/variables';
import { verifyIdentityApi } from '../../../apis/auth';

function verifyIdentityConfirmError(err) {
  return {
    type: VERIFY_IDENTITY_CONFIRM_API_ERROR,
    payload: err,
  };
}
function verifyIdentityConfirmRequest() {
  return {
    type: VERIFY_IDENTITY_CONFIRM_API_REQUEST,
    payload: true,
  };
}

function verifyIdentityConfirmSuccess(user) {
  return {
    type: VERIFY_IDENTITY_CONFIRM_API_SUCCESS,
    payload: user,
  };
}

export function verifyIdentityConfirmAction(_verification) {
  const verification = { ..._verification };
  // verification.apiKey = api.apiKey;
  // verification.apiSecret = api.apiSecret;

  return (dispatch, getState) => {
    const state = getState();
    dispatch(verifyIdentityConfirmRequest());
    return verifyIdentityApi
      .verifyIdentityConfirm(state, verification)
      .then(data => {
        dispatch(verifyIdentityConfirmSuccess(data));
        return data;
      })
      .catch(error => {
        dispatch(verifyIdentityConfirmError(error));
        throw error;
      });
  };
}

function verifyIdentitySendError(err) {
  return {
    type: VERIFY_IDENTITY_SEND_API_ERROR,
    payload: err,
  };
}
function verifyIdentitySendRequest() {
  return {
    type: VERIFY_IDENTITY_SEND_API_REQUEST,
    payload: true,
  };
}
function verifyIdentitySendSuccess(user) {
  return {
    type: VERIFY_IDENTITY_SEND_API_SUCCESS,
    payload: user,
  };
}

export function verifyIdentitySendAction(_verification) {
  const verification = { ..._verification };
  // verification.apiKey = api.apiKey;
  // verification.apiSecret = api.apiSecret;

  return (dispatch, getState) => {
    const state = getState();
    dispatch(verifyIdentitySendRequest());
    return verifyIdentityApi
      .verifyIdentitySend(state, verification)
      .then(data => {
        dispatch(verifyIdentitySendSuccess(data));
        return data;
      })
      .catch(error => {
        dispatch(verifyIdentitySendError(error));
        throw error;
      });
  };
}

function forgotVerifyIdentitySendError(err) {
  return {
    type: FORGOT_VERIFY_IDENTITY_SEND_API_ERROR,
    payload: err,
  };
}
function forgotVerifyIdentitySendRequest() {
  return {
    type: FORGOT_VERIFY_IDENTITY_SEND_API_REQUEST,
    payload: true,
  };
}
function forgotVerifyIdentitySendSuccess(user) {
  return {
    type: FORGOT_VERIFY_IDENTITY_SEND_API_SUCCESS,
    payload: user,
  };
}

export function forgotVerifyIdentitySendAction(_verification) {
  const verification = { ..._verification };
  // verification.apiKey = api.apiKey;
  // verification.apiSecret = api.apiSecret;

  return (dispatch, getState) => {
    const state = getState();
    dispatch(forgotVerifyIdentitySendRequest());
    return verifyIdentityApi
      .forgotVerifyIdentitySend(state, verification)
      .then(data => {
        dispatch(forgotVerifyIdentitySendSuccess(data));
        return data;
      })
      .catch(error => {
        dispatch(forgotVerifyIdentitySendError(error));
        throw error;
      });
  };
}

function signUpVerifyIdentityConfirmError(err) {
  return {
    type: SIGNUP_VERIFY_IDENTITY_CONFIRM_API_ERROR,
    payload: err,
  };
}
function signUpVerifyIdentityConfirmRequest() {
  return {
    type: SIGNUP_VERIFY_IDENTITY_CONFIRM_API_REQUEST,
    payload: true,
  };
}
function signUpVerifyIdentityConfirmSuccess(user) {
  return {
    type: SIGNUP_VERIFY_IDENTITY_CONFIRM_API_SUCCESS,
    payload: user,
  };
}

export function signUpVerifyIdentityConfirmAction(_verification) {
  const verification = { ..._verification };
  // verification.apiKey = api.apiKey;
  // verification.apiSecret = api.apiSecret;

  return (dispatch, getState) => {
    const state = getState();
    dispatch(signUpVerifyIdentityConfirmRequest());
    return verifyIdentityApi
      .signUpVerifyIdentityConfirm(state, verification)
      .then(data => {
        dispatch(signUpVerifyIdentityConfirmSuccess(data));
        return data;
      })
      .catch(error => {
        dispatch(signUpVerifyIdentityConfirmError(error));
        throw error;
      });
  };
}

function forgotVerifyIdentityConfirmError(err) {
  return {
    type: FORGOT_VERIFY_IDENTITY_CONFIRM_API_ERROR,
    payload: err,
  };
}
function forgotVerifyIdentityConfirmRequest() {
  return {
    type: FORGOT_VERIFY_IDENTITY_CONFIRM_API_REQUEST,
    payload: true,
  };
}
function forgotVerifyIdentityConfirmSuccess(user) {
  return {
    type: FORGOT_VERIFY_IDENTITY_CONFIRM_API_SUCCESS,
    payload: user,
  };
}

export function forgotVerifyIdentityConfirmAction(_verification) {
  const verification = { ..._verification };
  // verification.apiKey = api.apiKey;
  // verification.apiSecret = api.apiSecret;

  return (dispatch, getState) => {
    const state = getState();
    dispatch(forgotVerifyIdentityConfirmRequest());
    return verifyIdentityApi
      .forgotVerifyIdentityConfirm(state, verification)
      .then(data => {
        dispatch(forgotVerifyIdentityConfirmSuccess(data));
        return data;
      })
      .catch(error => {
        dispatch(forgotVerifyIdentityConfirmError(error));
        throw error;
      });
  };
}

function verifyIdentitySendSmSError(err) {
  return {
    type: VERIFY_IDENTITY_SEND_SMS_API_ERROR,
    payload: err,
  };
}
function verifyIdentitySendSmSRequest() {
  return {
    type: VERIFY_IDENTITY_SEND_SMS_API_REQUEST,
    payload: true,
  };
}
function verifyIdentitySendSmSSuccess(user) {
  return {
    type: VERIFY_IDENTITY_SEND_SMS_API_SUCCESS,
    payload: user,
  };
}

export function verifyIdentitySendSmSAction(_verification) {
  const verification = { ..._verification };
  // verification.apiKey = api.apiKey;
  // verification.apiSecret = api.apiSecret;

  return (dispatch, getState) => {
    const state = getState();
    return verifyIdentityApi
      .verifyIdentitySendSmS(state, verification)
      .then(data => {
        dispatch(verifyIdentitySendSmSSuccess(data));
        return data;
      })
      .catch(error => {
        dispatch(verifyIdentitySendSmSError(error));
        throw error;
      });
  };
}

export default {
  verifyIdentityConfirmAction,
  verifyIdentitySendAction,
  forgotVerifyIdentityConfirmAction,
  signUpVerifyIdentityConfirmAction,
  forgotVerifyIdentitySendAction,
  verifyIdentitySendSmSAction,
};
