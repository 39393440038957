import React from 'react';
import { Typography } from '@mui/material';
import { getEnv } from '../../../utils/functions';

const SurveyQRCode = props => {
  const { surveyQRCode, styles, headingText, headingVariant } = props;
  const env = getEnv();
  const qrCode = surveyQRCode && surveyQRCode[env] ? surveyQRCode[env] : surveyQRCode;
  return (
    <div>
      {headingText && (
        <Typography variant={headingVariant || 'body1'}>{headingText}</Typography>
      )}
      <img src={qrCode?.url} alt="qrcode" style={styles} />
    </div>
  );
};
// Scan the QR Code below, to take our survey
export default SurveyQRCode;
