import { CHANGE_BALANCE_DATE_SUCCESS } from '../../../constants/portfolio/investmentActionNames';

export function changeBalanceDateAction(date) {
  return {
    type: CHANGE_BALANCE_DATE_SUCCESS,
    payload: date,
  };
}

export default {
  changeBalanceDateAction,
};
