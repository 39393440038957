import { routerPostCall } from '../../axios';

const getSessionId = (state, params = {}) => {
  const url = `/auth/trust-builder-session`;
  return routerPostCall(state, url, params);
};

export default {
  getSessionId,
};
