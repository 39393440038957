import {
  GET_MODAL_WIZARD_API_REQUEST,
  GET_MODAL_WIZARD_API_SUCCESS,
  GET_MODAL_WIZARD_API_ERROR,
} from '../../../constants/wizardActionNames';

// import { api } from '../../../utils/variables';
// import { getWizardApi } from '../../../apis/wizards';
import makeGraphQuery from '../../../queries';

import getWizardQuery from '../../../queries/wizards';

function getModalWizardError(err) {
  return {
    type: GET_MODAL_WIZARD_API_ERROR,
    payload: err,
  };
}

function getModalWizardRequest() {
  return {
    type: GET_MODAL_WIZARD_API_REQUEST,
    payload: true,
  };
}
function getModalWizardSuccess(prod) {
  return {
    type: GET_MODAL_WIZARD_API_SUCCESS,
    payload: prod,
  };
}

export function getModalWizardAction(params) {
  // const apiEndpoint = {}; // ...api };

  return (dispatch, getState) => {
    const state = getState();
    dispatch(getModalWizardRequest());
    // return getWizardApi.getWizard(state, params)
    return makeGraphQuery(getWizardQuery, params)
      .then(data => {
        // Update the app state with the results of the API call.
        const result = data?.c360CustomWizards;
        dispatch(getModalWizardSuccess(result));
        return result;
      })
      .catch(error => {
        dispatch(getModalWizardError(error));
        throw error;
      });
  };
}

export default {
  getModalWizardAction,
};
