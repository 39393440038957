import { crmPostCall } from '../../../axios';
import { helpCenterApi } from '../../../../utils/functions/helpCenterApis';

import {
  GETREQUIREDDOCUMENTSURL,
  GETREQUIREDDOCUMENTSURLJM,
  SERVERERROR,
} from '../../constants';

export const getRequiredDocumentsUrl = helpCenterApi(GETREQUIREDDOCUMENTSURL);
const getRequiredDocumentsList = (state, body) => {
  const url = getRequiredDocumentsUrl;
  if (url) {
    return crmPostCall(state, url, body);
  }
  return Promise.reject(new Error(SERVERERROR));
};
export default { getRequiredDocumentsList };
