import {
  UPDATE_YOUR_STORAGE_VERSION_SUCCESS,
  ADD_TRUSTED_DEVICE_API_SUCCESS,
} from '../../constants/diskActionNames';
import { DELETE_TRUSTED_DEVICE_API_SUCCESS } from '../../constants/profileActionNames';
import { addItemOnce, removeItemOnce } from '../../utils/functions';

export const initialState = {
  storageVersion: 0,
  devices: null,
};

function diskReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_YOUR_STORAGE_VERSION_SUCCESS: {
      const storageVersion = action?.payload; // action?.payload?.payload ||
      return {
        ...state,
        storageVersion,
      };
    }

    case ADD_TRUSTED_DEVICE_API_SUCCESS: {
      const device = action.payload;
      const initialDevices = state?.devices || [];
      const devices = addItemOnce(initialDevices, device, 'username');

      return {
        ...state,
        devices,
      };
    }
    case DELETE_TRUSTED_DEVICE_API_SUCCESS: {
      const deleteTrustedDevice = action?.payload;
      deleteTrustedDevice._id = deleteTrustedDevice?._id || deleteTrustedDevice?.id;
      const existingDevices = (state.devices && [...state.devices]) || [];
      // const updatedList = existingDevices?.filter(item => item?.id !== deleteTrustedDevice?.id && item?.authId !== deleteTrustedDevice?.authId);
      const updatedList = removeItemOnce(existingDevices, deleteTrustedDevice, '_id');
      // console.log("Misc", existingDevices);
      // console.log("deleteTrustedDevice", deleteTrustedDevice);
      return {
        ...state,
        devices: updatedList,
      };
    }

    default:
      return state;
  }
}
export default diskReducer;
