import React from 'react';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import SurveyComponent from '../surveyComponent';

const SurveyPage = () => {
  const initialSurvey = useSelector(state => state?.misc?.survey);
  let survey = null;
  if (initialSurvey) {
    survey = {
      ...initialSurvey,
      ...initialSurvey?.survey,
    };
  }
  const { title, headingVariant } = survey;
  return (
    <div>
      {/* title && <Typography variant={headingVariant || 'h5'}>{title}</Typography> */}
      <SurveyComponent {...survey} embedType="inline" type="embed" renderType="onLoad" />
    </div>
  );
};

export default SurveyPage;
