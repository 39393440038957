import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton, Grid, Container, Typography, Tooltip } from '@mui/material';
import { Settings as SettingsIcon } from '@mui/icons-material';
import ModalAlert from '../modalAlert';
import { updateTempState } from '../../../actions/temp';

export default function Settings() {
  const dispatch = useDispatch();
  const alertPopUp = useSelector(state => state.temp.alertPopUp);

  const handleClose = () => {
    dispatch(updateTempState.updateTempStateAction({ alertPopUp: null }));
  };

  return (
    <div>
      {alertPopUp && (
        <ModalAlert
          open={alertPopUp}
          title={alertPopUp?.title}
          content={alertPopUp?.content}
          dismissiveAction={alertPopUp?.dismissiveAction}
          confirmingAction={alertPopUp?.confirmingAction}
          setCloseAsDismissiveAction={alertPopUp?.setCloseAsDismissiveAction}
          maxWidth={alertPopUp?.maxWidth}
          onClose={() => handleClose()}
          isBlocking={alertPopUp?.blockEscape}
          allowFullscreen={alertPopUp?.allowFullscreen}
        />
      )}
    </div>
  );
}
