import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Container, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import DetailsModal from '../detailsModal';
import { resetTempState, updateTempState } from '../../../actions/temp';
import styles from './style';
import FilterComponent from './filterComponent';

const useStyles = makeStyles(styles);

const FilterModal = () => {
  // // console.log('encryptKey11: ', encryptKey);
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const filterOpen = useSelector(state => state.temp.filterOpen);
  const filterTitle = useSelector(state => state.temp.filterTitle);

  const modalContent = {
    title: filterTitle || 'Filter Settings',
    content: <FilterComponent />,
  };

  return (
    <div>
      {filterOpen && (
        <DetailsModal
          title={modalContent?.title}
          onClose={() => {
            dispatch(updateTempState.updateTempStateAction({ filterOpen: false }));
          }} // dispatch(setModalContent.setModalContentAction(null))
          content={modalContent?.content}
          modalSize="sm"
          // className={classes.modalBackground}
          sx={{ backgroundColor: `${theme.palette.background.main} !important` }}
        />
      )}
    </div>
  );
};

export default FilterModal;
