export const getActiveStep = issueStatus => {
  switch (issueStatus) {
    case 'Info Needed':
    case 'Waiting for Details':
    case 'In Progress':
    case 'Researching':
    case 'Draft':
    case 'Delayed':
    case 'On Hold':
      return 1;
    case 'Resolved':
    case 'Problem Solved':
    case 'Information Provided':
      return 2;
    case 'Merged':
    case 'Cancelled':
      return 2;
    default:
      return '';
  }
};

export const monthAbbreviations = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

export const formatDate = date => {
  const parts = date?.split('/');

  const originalDate = parts && new Date(`${parts[0]}/${parts[1]}/${parts[2]}`);

  const day = originalDate
    .getDate()
    .toString()
    .padStart(2, '0');
  const month = monthAbbreviations[originalDate.getMonth()];
  const year = originalDate.getFullYear();
  return `${day}/${month}/${year}`;
};
