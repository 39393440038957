import {
  SIGNIN_API_REQUEST,
  SIGNIN_API_ERROR,
  SIGNIN_API_SUCCESS,
} from '../../../constants/authActionNames';

// import { api } from '../../../utils/variables';
import { signInApi } from '../../../apis/auth';

function signInError(err) {
  return {
    type: SIGNIN_API_ERROR,
    payload: err,
  };
}

function signInRequest() {
  return {
    type: SIGNIN_API_REQUEST,
    payload: true,
  };
}

function signInSuccess(user) {
  return {
    type: SIGNIN_API_SUCCESS,
    payload: user,
    // params,
  };
}

export function signInAction(_credentials) {
  const credentials = { ..._credentials };
  // credentials.apiKey = api.apiKey;
  // credentials.apiSecret = api.apiSecret;

  return (dispatch, getState) => {
    const state = getState();
    // const countries = state?.locations?.allLocations || [];
    // const alphas = countries?.map(x => x?.alpha2);
    // const params = { alphas };
    dispatch(signInRequest());
    return signInApi
      .signIn(state, credentials)
      .then(data => {
        dispatch(signInSuccess(data));
        return data;
      })
      .catch(error => {
        dispatch(signInError(error));
        throw error;
      });
  };
}

export default {
  signInAction,
};
