import { portfolioButtonStyles } from '../../../styles';

const styles = theme => ({
  ...portfolioButtonStyles(theme),
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: 1,
    marginTop: theme.spacing(1),
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
  titleAppendix: {
    fontWeight: theme.typography.fontWeightLight,
    fontSize: theme.typography.subtitle2.fontSize,
    position: 'relative',
    whiteSpace: 'normal',
  },
  header: {
    // cursor: 'text',
    fontWeight: theme.typography.fontWeightBold,
    position: 'relative',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  },
  subHeader: {
    display: 'block',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    height: 'auto',
    width: '100%',
  },
  oneLineSubHeader: {
    display: 'block',
    textOverflow: 'ellipsis',
    whiteSpace: 'no-wrap',
    overflow: 'hidden',
    height: '1.8em',
    width: '100%',
    lineHeight: '1.8em',
  },
});

export default styles;
