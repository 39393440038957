import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import {
  Card,
  Grid,
  Skeleton,
  Icon,
  Typography,
  Button,
  useMediaQuery,
  Stack,
  Chip,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { LobIcon } from '../../misc';
import { useSmall } from '../../../hooks';
import { ContentDisplay } from '../../common';
import { updateTempState } from '../../../actions/temp';

const RequestCard = ({
  classes,
  icon,
  materialIcon,
  color,
  loading,
  finalTitle,
  isRequest,
  isAction,
  isModalWizard,
  finalDescription,
  handleMoreInfoOpen,
  newAction,
  handleRequest,
  defaultSelectButtonText,
  textColor,
  mainColor,
  lob,
}) => {
  // console.log('materialIcon: ', materialIcon);
  // console.log('finalIcon: ', finalIcon);
  const isSmall = useSmall();
  const dispatch = useDispatch();
  const tags = newAction?.tags || [];
  const [isFlipped, setIsFlipped] = useState(false);

  const toggleFlip = flipped => {
    setIsFlipped(flipped);
  };

  const handleClick = event => {
    const tooltipObj = {
      content: <ContentDisplay content={newAction?.moreInfo} />,
    };
    dispatch(updateTempState.updateTempStateAction({ alertPopUp: tooltipObj }));
  };

  return (
    <Card
      raised
      className={classnames(classes.root, classes.card, classes.cursorPointer)}
      sx={{ px: isAction || isModalWizard ? 1.5 : 2 }}
    >
      {newAction?.hasMoreInfo && (
        <Grid
          item
          xs={12}
          align="center"
          sx={{
            position: 'absolute',
            right: '5px',
            bottom: '5px',
            zIndex: 100,
          }}
        >
          {loading ? (
            <Skeleton variant="text" animation="wave" height={30} width={30} />
          ) : (
            <Tooltip title="Click for detailed view">
              <IconButton
                aria-label="help"
                size="small"
                sx={{ display: 'inline-block', height: 30, width: 30 }}
                onMouseEnter={() => toggleFlip(true)}
                onMouseLeave={() => toggleFlip(false)}
                onClick={handleClick}
              >
                <HelpOutlineIcon
                  fontSize="medium"
                  color={isFlipped ? 'primary' : 'inherit'}
                />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      )}
      {isFlipped ? (
        <Grid item xs={12} className={classnames(classes.contentCard)}>
          <ContentDisplay
            content={newAction?.moreInfo}
            style={{ zoom: 0.8, '-moz-transform': 'scale(0.8)' }}
          />
        </Grid>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          className={classnames({ [classes.lobHover]: !isFlipped }, classes.requestCard)}
          onClick={handleRequest}
        >
          {tags && tags.length > 0 && (
            <Grid
              item
              xs={12}
              align="center"
              className={classes.tagsHover}
              sx={{ position: 'absolute', right: '5px', top: '5px' }}
            >
              <Stack spacing={1} direction="row" justifyContent="flex-end">
                <Stack direction="row" spacing={1}>
                  {tags?.map(
                    ({ color: tagColor, title: tagTitle }) =>
                      tagTitle && (
                        <Chip
                          label={tagTitle}
                          sx={{
                            borderColor: mainColor,
                            color: mainColor,
                            height: '1rem',
                            fontSize: '0.70rem',
                          }}
                          size="small"
                          variant="outlined"
                        />
                      ),
                  )}
                </Stack>
              </Stack>
            </Grid>
          )}
          {(icon || materialIcon) && (
            <Grid item xs={12} align="center">
              <Box>
                {loading ? (
                  <Skeleton variant="rect" className={classes.icon} />
                ) : (
                  <LobIcon
                    icon={icon}
                    materialIcon={materialIcon}
                    color={color}
                    iconClassName={classes.lobIcon}
                    // fontColor={textColor}
                  />
                )}
              </Box>
            </Grid>
          )}

          {(finalTitle || finalDescription) && (
            <Grid item xs={12} align="center">
              {finalTitle && (
                <Typography
                  align="center"
                  // variant={isRequest ? 'body1' : 'h6'}
                  className={classes.title}
                >
                  {finalTitle}
                </Typography>
              )}
              {finalDescription && (!isSmall || isAction) && (
                <Typography
                  align="center"
                  variant={isAction ? 'subtitle2' : 'body1'}
                  className={classes.description}
                >
                  {finalDescription}
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      )}
    </Card>
  );
};

export default RequestCard;
