export const parseBool = _str => {
  let str = _str;
  // // console.log(typeof str);
  // strict: JSON.parse(str)

  if (str == null || str === undefined) return false;

  if (typeof str === 'boolean') {
    return str === true;
  }

  if (typeof str === 'string') {
    if (
      str === '' ||
      str.toLowerCase() === 'no' ||
      str.toLowerCase() === 'n' ||
      str.toLowerCase() === 'f'
      // str.length === 0
    )
      return false;

    str = str.replace(/^\s+|\s+$/g, '');
    if (
      str.toLowerCase() === 'true' ||
      str.toLowerCase() === 'yes' ||
      str.toLowerCase() === 't' ||
      str.toLowerCase() === 'y'
      // str.length > 0
    )
      return true;

    str = str.replace(/,/g, '.');
    str = str.replace(/^\s*\-\s*/g, '-');
  }

  // var isNum = string.match(/^[0-9]+$/) != null;
  // var isNum = /^\d+$/.test(str);
  if (!Number.isNaN(Number(str))) return parseFloat(str) !== 0;

  return false;
};
export const parseBoolOrString = _str => {
  let str = _str;
  // // console.log(typeof str);
  // strict: JSON.parse(str)

  if (str == null || str === undefined) return false;

  if (typeof str === 'boolean') {
    return str === true;
  }

  if (typeof str === 'string') {
    if (
      str === '' ||
      str.toLowerCase() === 'no' ||
      str.toLowerCase() === 'n' ||
      str.toLowerCase() === 'f'
      // str.length === 0
    )
      return 0;

    str = str.replace(/^\s+|\s+$/g, '');
    if (
      str.toLowerCase() === 'true' ||
      str.toLowerCase() === 'yes' ||
      str.toLowerCase() === 't' ||
      str.toLowerCase() === 'y'
      // str.length > 0
    )
      return 1;

    str = str.replace(/,/g, '.');
    str = str.replace(/^\s*\-\s*/g, '-');
  }

  // var isNum = string.match(/^[0-9]+$/) != null;
  // var isNum = /^\d+$/.test(str);
  if (!Number.isNaN(Number(str))) return parseFloat(str) !== 0;

  return -1;
};

export const parseBoolWizards = (_str, fieldType) => {
  let str = _str;
  // // console.log(typeof str);
  // strict: JSON.parse(str)

  if (str == null || str === undefined) return false;

  if (typeof str === 'boolean') {
    return str === true;
  }

  if (typeof str === 'string') {
    if (
      str === '' ||
      str.toLowerCase() === 'false' ||
      str.toLowerCase() === 'no' ||
      str.toLowerCase() === 'n' ||
      str.toLowerCase() === 'f' ||
      (str.length === 0 && fieldType === 'boolean')
    )
      return false;

    str = str.replace(/^\s+|\s+$/g, '');
    if (
      str.toLowerCase() === 'true' ||
      str.toLowerCase() === 'yes' ||
      str.toLowerCase() === 't' ||
      str.toLowerCase() === 'y' ||
      (str.length > 0 && fieldType === 'boolean')
    )
      return true;

    str = str.replace(/,/g, '.');
    str = str.replace(/^\s*\-\s*/g, '-');
  }

  // if (Array.isArray(str) && str.length === 0 && fieldType === 'boolean') {
  //   debugger;
  //   return false;
  // }

  // if (Array.isArray(str) && str.length > 0 && fieldType === 'boolean') {
  //   return true;
  // }

  // var isNum = string.match(/^[0-9]+$/) != null;
  // var isNum = /^\d+$/.test(str);
  if (!Number.isNaN(Number(str))) return parseFloat(str) !== 0;

  return false;
};

export const parseSmoker = str => {
  return str?.toLowerCase() === 'smoker';
};
