import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import TableContainer from '@mui/material/TableContainer';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Pagination from '@mui/material/Pagination';
import Table from '@mui/material/Table';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useHistory } from 'react-router-dom';
import { TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material';
import PropTypes, { object } from 'prop-types';
import moment from 'moment';
import { cardStatus } from '../../../../containersV2/helpCenter/checkStatus';
import { useSmall } from '../../../../hooks';
import { IssueListStyles } from '../style';
import { getAllHelpCenterCasesAction } from '../../../../actions/helpCenter/cases/getAllHelpCenterCases';
import { getSingleHelpCenterCaseAction } from '../../../../actions/helpCenter/cases/getSingleHelpCenterCase';
import { Loading } from '../../../common';
import { toastrOptions } from '../../../../utils/functions';
import { formatDate } from '../../../../utils/GGILUtils';

function IssueList({ CaseId, CaseYear }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const allHelpCenterCases = useSelector(state => state.helpCenter.allHelpCenterCases);
  const singleHelpCenterCase = useSelector(
    state => state.helpCenter.singleHelpCenterCase,
  );
  const fullName = useSelector(state => state?.helpCenter?.helpFormData?.fullName);
  const error = useSelector(state => state.helpCenter.isErrorCases);
  const user = CaseId == '' ? useSelector(state => state.auth.user) : null;
  const userIngeniumId = user ? user.customerDetails.customer.sourceIds.ingenium : '';
  const lob = useSelector(state => state?.helpCenter?.helpFormData?.lob);
  const currentLocation = useSelector(state => state.locations.yourLocation.alpha2);
  const isSmall = useSmall();
  const tableHeaderStyle = {
    color: 'text.label',
    fontWeight: 'bold',
    padding: '1.563rem 0.938rem',
  };
  const tableRowStyle = { color: 'text.label', borderBottom: 'none' };
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [page, setPage] = useState(1);
  const styles = IssueListStyles;
  const [isLoading, setIsLoading] = useState(true);

  const navToDetails = reference => {
    history.push(`/help/status/details/${reference}`);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  function sortCasesByDate(a, b) {
    const dateA = new Date(a.createdOnSystemFormat).getTime();
    const dateB = new Date(b.createdOnSystemFormat).getTime();
    return dateA < dateB ? 1 : -1;
  }

  useEffect(() => {
    allHelpCenterCases.sort(sortCasesByDate);
  }, [allHelpCenterCases]);

  useEffect(() => {
    if (CaseId) {
      const prams = { caseNumber: CaseId, fullName };
      try {
        dispatch(getSingleHelpCenterCaseAction(prams)).then(() => {
          setIsLoading(false);
        });
      } catch (err) {
        setIsLoading(false);
        const title = err.message;
        const message = 'Please try after some time';
        const status = 'error';
        toastr[status](
          title,
          message,
          toastrOptions({
            status,
          }),
        );
      }
    } else if (userIngeniumId) {
      const prams = { clientId: userIngeniumId };
      try {
        dispatch(getAllHelpCenterCasesAction(prams)).then(() => {
          setIsLoading(false);
        });
      } catch (err) {
        setIsLoading(false);
        const title = err.message;
        const message = 'Please try after some time';
        const status = 'error';
        toastr[status](
          title,
          message,
          toastrOptions({
            status,
          }),
        );
      }
    }
  }, []);

  const getYear = year => {
    return new Date(year).getFullYear();
  };

  const modifyDate = givenDate => {
    const date = new Date(givenDate);
    const newDateTime = date.toLocaleString();
    return newDateTime;
  };

  const formatDateJM = givenDate => {
    const utcToLocalDate = moment
      .utc(givenDate)
      .local()
      .format('MM/DD/YYYY, hh:mm A');

    return `${formatDate(utcToLocalDate?.split(',')[0])}, ${
      utcToLocalDate?.split(',')[1]
    }`;
  };

  if (error && !isLoading) {
    return <Typography sx={{ color: 'text.label' }}>Sorry, No Cases</Typography>;
  }

  if (!lob && !CaseId) {
    history.push('/help/status');
  }

  if (!userIngeniumId && user) {
    return (
      <Typography sx={{ color: 'text.label' }}>
        Cases can't be displayed as User Client ID is not present
      </Typography>
    );
  }

  return (
    <>
      {!isLoading ? (
        <>
          {isSmall ? (
            <>
              {(CaseId == '' ? allHelpCenterCases : singleHelpCenterCase).map(row => (
                <Card
                  key={row.ticketNumber}
                  sx={{
                    padding: '0.938rem 1.25rem 2.5rem 0.938rem',
                    margin: '1.25rem',
                    maxWidth: '23.125rem',
                  }}
                >
                  <table>
                    <tr>
                      <td align="left">
                        <Typography
                          sx={{ fontSize: '0.875rem', color: 'text.label' }}
                          variant="subtitle1"
                        >
                          Reference#:
                        </Typography>
                      </td>
                      <td align="left">
                        <Typography
                          sx={{
                            fontSize: '0.875rem',
                            marginLeft: '1.25rem',
                            textDecoration: 'underline',
                            color: 'text.label',
                          }}
                          variant="subtitle1"
                          onClick={() => navToDetails(row.ticketNumber)}
                        >
                          {row.ticketNumber}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td align="left">
                        <Typography
                          sx={{ fontSize: '0.875rem', color: 'text.label' }}
                          variant="subtitle1"
                        >
                          Created Date:
                        </Typography>
                      </td>
                      <td align="left">
                        <Typography
                          sx={{
                            fontSize: '0.875rem',
                            marginLeft: '1.25rem',
                            color: 'text.label',
                          }}
                          variant="subtitle1"
                        >
                          {currentLocation !== 'JM'
                            ? modifyDate(row.createdOnSystemFormat)
                            : formatDateJM(row.createdOn)}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td align="left">
                        <Typography
                          sx={{ fontSize: '0.875rem', color: 'text.label' }}
                          variant="subtitle1"
                        >
                          Subject:
                        </Typography>
                      </td>
                      <td align="left">
                        <Typography
                          sx={{
                            fontSize: '0.875rem',
                            marginLeft: '1.25rem',
                            color: 'text.label',
                          }}
                          variant="subtitle1"
                        >
                          {row.issueSubject ? row.issueSubject : 'Unavailable'}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td align="left">
                        <Typography
                          sx={{ fontSize: '0.875rem', color: 'text.label' }}
                          variant="subtitle1"
                        >
                          Status:
                        </Typography>
                      </td>
                      <td align="left">
                        <Stack
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginLeft: '1.25rem',
                          }}
                        >
                          <Box
                            sx={{
                              height: '0.5rem',
                              width: '0.5rem',
                              marginRight: '0.5rem',
                              borderRadius: '50%',
                              backgroundColor: cardStatus.IP.includes(row.issueStatus)
                                ? '#00bb55'
                                : cardStatus.Resolved.includes(row.issueStatus)
                                ? '#4F5467'
                                : cardStatus.IN.includes(row.issueStatus)
                                ? '#dd3311'
                                : cardStatus.Cancelled.includes(row.issueStatus)
                                ? 'red'
                                : '#006cbb',
                            }}
                          />
                          <Typography
                            sx={{ fontSize: '0.875rem', color: 'text.label' }}
                            variant="subtitle1"
                          >
                            {row.issueStatus}
                          </Typography>
                        </Stack>
                      </td>
                    </tr>
                  </table>
                </Card>
              ))}
            </>
          ) : (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={tableHeaderStyle}>Subject</TableCell>
                      <TableCell sx={tableHeaderStyle}>Created Date</TableCell>
                      <TableCell sx={tableHeaderStyle}>Reference#</TableCell>
                      <TableCell sx={tableHeaderStyle}>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(CaseId == ''
                      ? allHelpCenterCases.filter(
                          issue => getYear(issue.createdOnSystemFormat) == CaseYear,
                        )
                      : singleHelpCenterCase
                    )
                      .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                      .map(row => (
                        <TableRow key={row.ticketNumber}>
                          <TableCell sx={tableRowStyle}>
                            {row.issueSubject ? row.issueSubject : 'Unavailable'}
                          </TableCell>
                          <TableCell sx={tableRowStyle}>
                            {currentLocation !== 'JM'
                              ? modifyDate(row.createdOnSystemFormat)
                              : formatDateJM(row.createdOn)}
                          </TableCell>
                          <TableCell
                            onClick={() => navToDetails(row.ticketNumber)}
                            sx={{
                              borderBottom: 'none',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                          >
                            {row.ticketNumber}
                          </TableCell>
                          <TableCell
                            sx={{
                              ...tableRowStyle,
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Box
                              sx={{
                                height: '0.875rem',
                                width: '0.875rem',
                                marginRight: '0.5rem',
                                borderRadius: '50%',
                                backgroundColor: cardStatus.IP.includes(row.issueStatus)
                                  ? '#00bb55'
                                  : cardStatus.Resolved.includes(row.issueStatus)
                                  ? '#4F5467'
                                  : cardStatus.IN.includes(row.issueStatus)
                                  ? '#dd3311'
                                  : cardStatus.Cancelled.includes(row.issueStatus)
                                  ? 'red'
                                  : '#006cbb',
                              }}
                            />
                            <span>{row.issueStatus}</span>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {CaseId == '' ? (
                <>
                  <Stack alignItems="flex-end" sx={{ marginTop: '1.25rem' }}>
                    <Stack direction="row" alignItems="center">
                      <Stack>
                        <Pagination
                          sx={styles.pagination}
                          page={page}
                          onChange={handlePageChange}
                          count={Math.ceil(
                            allHelpCenterCases.filter(
                              issue => getYear(issue.createdOnSystemFormat) == CaseYear,
                            ).length / rowsPerPage,
                          )}
                        />
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ marginBottom: '0.125rem' }}
                      >
                        <Typography sx={{ marginRight: '1.25rem' }}>Show</Typography>
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: 'white',
                            color: 'text.label',
                            '&:hover': {
                              backgroundColor: theme.palette.background.default,
                              color: 'text.label',
                            },
                          }}
                        >
                          {rowsPerPage} rows
                          <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{ marginLeft: '0.625rem' }}
                          >
                            <ArrowDropUpIcon
                              sx={{ marginBottom: '-0.438rem' }}
                              onClick={() => setRowsPerPage(rowsPerPage + 1)}
                            />
                            <ArrowDropDownIcon
                              sx={{ marginTop: '-0.438rem' }}
                              onClick={() =>
                                setRowsPerPage(rowsPerPage > 1 ? rowsPerPage - 1 : 1)
                              }
                            />
                          </Stack>
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <Loading />
        </>
      )}
    </>
  );
}

IssueList.propTypes = {
  tableData: PropTypes.arrayOf(object),
};

export default IssueList;
