import queryString from 'query-string';

const getLastValidQuery = (history, location) => {
  const parsed =
    history.find(
      object =>
        location.pathname.startsWith(object.pathname) ||
        object.pathname.startsWith(location.pathname),
    )?.query || queryString.parse(location.search); // location?.query;

  return {
    history,
    parsed,
  };
};

export const updateQueryHistory = (_history, _location) => {
  // // console.log(Object.keys(location?.query).length);
  const history = [..._history];
  const location = { ..._location };
  const parsed = queryString.parse(location.search); // location?.query
  //   // console.log('location 1: ', location);
  //   // console.log('history 1: ', history);
  if (!parsed || Object.keys(parsed).length === 0)
    return getLastValidQuery(history, location);

  const index = history.findIndex(
    object =>
      location.pathname.startsWith(object.pathname) ||
      object.pathname.startsWith(location.pathname),
  );

  if (index !== -1) {
    history.splice(index, 1, location);
  } else {
    history.push(location);
  }

  return {
    history,
    parsed,
  };
};

export const fuseOptions = keys => {
  // // console.log('keys: ', keys);

  return {
    isCaseSensitive: false,
    findAllMatches: true,
    includeMatches: true,
    includeScore: true,
    shouldSort: true,
    useExtendedSearch: false,
    threshold: 0.05,
    ignoreLocation: true,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 2,
    keys, // allRows.length > 0 ? getEntries(allRows[0]) :
  };
};

export const trimOneChar = (string, charToRemove) => {
  if (string.charAt(0) === charToRemove) {
    string = string.substring(1);
  }

  if (string.charAt(string.length - 1) === charToRemove) {
    string = string.substring(0, string.length - 1);
  }

  return string;
};
