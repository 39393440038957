import { dateToString } from './dateManipulation';
import { toTitleCase } from './stringManipulation';
import { investmentLob } from '../variables';

const getPortfolioFilterMessage = (options, variables, lob) => {
  const contactNumber = variables?.contactNumbers?.contact_us?.phone;
  let filterLobTitle = '';
  if (options.lob && options.lob.length > 0 && options.lob !== 'all') {
    const tempLobs = options.lob.map(x => toTitleCase(x));
    filterLobTitle = ` "${tempLobs.join(', ')}"`;
  }

  let filterStatus = '';
  if (options?.status && options.status.length > 0) {
    filterStatus = `, with status "${options?.status?.join(', ')}"`;
  }

  let filterSearch = '';
  if (options.search && options.search.length > 0) {
    filterSearch += `, with keyword "${options.search}"`;
  }

  let filterDates = '';

  if (options.startDate && options.endDate) {
    filterDates = filterSearch || filterStatus ? ', and' : ',';
    filterDates += ` between expiry or due dates ${dateToString(
      options?.startDate,
    )} and ${dateToString(options?.endDate)}`;
  }

  const yesFilter = filterStatus || filterSearch || filterDates;
  // console.log('yesFilter: ', yesFilter);
  const filterMessage = `No${filterLobTitle} products found${filterStatus}${filterSearch}${filterDates}`;
  const errorMessage = `An error occurred while retrieving your ${
    lob === investmentLob ? 'accounts' : 'policies'
  }, please try again later. If you continue to experience this issue, please contact us at ${contactNumber}.`;
  return {
    yesFilter,
    filterMessage,
    errorMessage,
  };
};

export default getPortfolioFilterMessage;
