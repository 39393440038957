export default theme => ({
  root: ({ color, contrastColor }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    backgroundColor: 'transparent !important',
    margin: `${theme.spacing(1)} !important`,
    [theme.breakpoints.down('sm')]: {
      margin: '0 !important',
    },
  }),
  active: ({ color, contrastColor }) => ({
    backgroundColor: `${contrastColor} !important`,
    color: `${color} !important`,
    borderStyle: 'solid !important',
    borderColor: `${contrastColor} !important`,
  }),
  inActive: ({ color, contrastColor }) => ({
    // fontWeight: 'bold !important',
    // border: 'none !important',
    borderColor: `${color} !important`,
    color: `${contrastColor} !important`,
    backgroundColor: `${color} !important`,
    borderStyle: 'solid !important',
  }),
  chip: ({ color, contrastColor }) => ({
    margin: `${theme.spacing(0.5)} !important`,
    // margin: 0,
    borderWidth: '1px !important',
    borderStyle: 'solid',

    '&:hover': {
      borderStyle: 'solid',
      color: `${color} !important`,
      backgroundColor: `${contrastColor} !important`,
      borderColor: `${color} !important`,
    },
  }),
});
