import { routerPostCall } from '../../axios';
// authPostCall
const validateCredentials = (state, credentials, body, headers) => {
  const url = `/auth/credentials/validate`;
  const opts = {
    hasLoginCredentials: true,
    loginCredentials: credentials,
    initialHeaders: headers,
  };
  return routerPostCall(state, url, body, opts);
};
export default { validateCredentials };
