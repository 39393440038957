import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from './style';

const useStyles = makeStyles(styles);
const WelcomeGrid = () => {
  const classes = useStyles();
  const [bgColor, setBgColor] = useState({});

  useEffect(() => {
    setBgColor(getRandomColor());
  }, []);

  function getRandomColor() {
    const colors = [
      { left: '#9C0059', right: '#B75580' },
      { left: '#E86D1F', right: '#FFE512' },
      { left: '#332A86', right: '#62589F' },
    ];
    const random = Math.floor(Math.random() * colors.length);

    return colors[random];
  }

  const containerStyle = {
    background: `linear-gradient(to right top, ${bgColor.left}, ${bgColor.right})`,
    minHeight: '100vh',
    textAlign: 'left',
  };

  return (
    <Grid container>
      <Grid item xs={12} style={containerStyle}>
        <Typography variant="h2" gutterBottom className={classes.welcomeText}>
          Welcome to
        </Typography>
        <Typography variant="h1" gutterBottom className={classes.brandName}>
          Guardian <br /> Group
        </Typography>
      </Grid>
    </Grid>
  );
};

export default WelcomeGrid;
