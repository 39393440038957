import {
  GET_REQUESTS_API_REQUEST,
  GET_REQUESTS_API_SUCCESS,
  GET_REQUESTS_API_ERROR,
  GET_NEXT_REQUESTS_SUCCESS,
} from '../../../constants/requestActionNames';

import { getRequestsApi } from '../../../apis/requests';
import { getPayloadResponse } from '../../../reducers/requests';

function getRequestsRequest() {
  return {
    type: GET_REQUESTS_API_REQUEST,
    payload: true,
  };
}

function getRequestsSuccess(requests, params, lobTypes) {
  return {
    type: GET_REQUESTS_API_SUCCESS,
    payload: requests,
    params,
    lobTypes,
  };
}

function getRequestsError(err) {
  return {
    type: GET_REQUESTS_API_ERROR,
    payload: err,
  };
}

export function getRequestsAction(params) {
  const response = getPayloadResponse();
  return (dispatch, getState) => {
    const state = getState();
    const lobTypes = state?.locations?.lobCategories;
    // console.log(state);
    dispatch(getRequestsRequest());
    return getRequestsApi
      .getRequests(state, params)
      .then(data => {
        dispatch(getRequestsSuccess(response, params, lobTypes));
        return data;
      })
      .catch(error => {
        dispatch(getRequestsSuccess(response, params));
        throw error;
        // return response;
      });
  };
}

function getNextRequestsSuccess(prod) {
  return {
    type: GET_NEXT_REQUESTS_SUCCESS,
    payload: prod,
  };
}

export function getNextRequestsAction(data) {
  return getNextRequestsSuccess(data);
}

export default { getRequestsAction, getNextRequestsAction };
