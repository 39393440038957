const styles = theme => ({
  root: {
    height: 'auto',
  },

  dropdownContainer: {
    marginTop: '1rem',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      padding: '1rem',
    },
  },

  tabActionDescription: {
    color: theme.palette.text.primary,
  },

  tabActionDropdown: {
    textAlign: 'center',
    width: '100%',
    maxWidth: 'fitContent',
    boxShadow: '0 6px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2)',
    '&:first-of-type': {
      borderRadius: `${theme.shape.borderRadius}px 0px 0px ${theme.shape.borderRadius}px`,
      transformOrigin: 'top left',

      [theme.breakpoints.up('md')]: {
        transition: 'transform 0.3s',
      },
    },
    '&:last-of-type': {
      borderRadius: `0px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px`,
      transformOrigin: 'top left',

      [theme.breakpoints.up('md')]: {
        transition: 'transform 0.3s',
      },
    },
  },
  tabActionText: {
    width: '100%',
  },
  tabActive: {
    backgroundColor: theme.palette.primary.main,
    '& $tabActionText': {
      color: theme.palette.primary.contrastText,
    },
  },
  tabInActive: {
    backgroundColor: theme.palette.background.contentBox,

    '&:hover': {
      transformOrigin: 'top left',
      transform: 'scale(1.1)',
      cursor: 'pointer',
    },
    '& $tabActionText': {
      color: theme.palette.text.primary,
    },
  },
});

export default styles;
