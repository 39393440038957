import React from 'react';
import { Button, Box } from '@mui/material';

export function ButtonComponent({
  variant,
  size,
  content,
  onClick,
  styles,
  disabled,
  href,
  ...props
}) {
  const containedStyles =
    variant == 'outlined'
      ? {
          color: 'primary.main',
          border: '1px solid primary.main',
          borderRadius: '8px',
          textTransform: 'capitalize',
          '&:hover': {
            color: 'primary.main',
          },
          ...styles,
        }
      : {
          background: 'primary.main',
          color: 'text.bright',
          textTransform: 'capitalize',
          width: 'max-content',
          borderRadius: '8px',
          '&:hover': {
            background: 'primary.main',
            color: 'text.bright',
            cursor: 'pointer',
          },
          ...styles,
        };

  return (
    <Box sx={{ display: 'inline-block' }} {...props}>
      <Button
        variant={variant || 'contained'}
        href={href}
        size={size || 'large'}
        sx={containedStyles}
        disabled={disabled}
        onClick={onClick}
      >
        {content}
      </Button>
    </Box>
  );
}
