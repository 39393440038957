export default theme => ({
  nameInitials: {
    cursor: 'default',
  },
  defaultAvatar: {
    color: theme.palette.text.main,
  },
  xxxs: {
    width: '30px !important',
    height: '30px !important',
    fontSize: '10px !important',
  },
  xxs: {
    width: '35px !important',
    height: '35px !important',
    fontSize: '15px !important',
  },
  xs: {
    width: '50px !important',
    height: '50px !important',
    fontSize: '18px !important',
  },
  sm: {
    width: '100px !important',
    height: '100px !important',
    fontSize: '22px !important',
  },
  md: {
    width: '150px !important',
    height: '150px !important',
    fontSize: '25px !important',
  },
  lg: {
    width: '200px !important',
    height: '200px !important',
    fontSize: '28px !important',
  },
});
