const styles = theme => ({
  learnMore: {
    // marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    textAlign: 'right',
    textDecoration: 'none',
  },
  root: {
    flexGrow: 1,
    transform: 'translateZ(0px)',
    margin: theme.spacing(1), // adds right padding on cards
    backgroundColor: theme.palette.background.grid,

    cursor: 'pointer',
  },
  button: {
    // width: '45%',
    height: '100%',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  media: {
    height: 200,
    // height: 0,
    // paddingTop: '56.25%', // 16:9
    // marginTop: theme.spacing(1)
  },
  noDecoration: {
    textDecoration: 'none !important',
    backgroundImage: 'none',
  },
  quoteButton: {
    // width: '45%',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  subheaderTransparent: {
    backgroundColor: 'transparent',
  },
  title: {
    lineHeight: '1.2em',
    height: '3.4em',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
  },
  description: {
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '1.2em',
    height: '2.4em',
    overflow: 'hidden',
    fontSize: theme.typography.body2.fontSize, // theme.typography.pxToRem(13),
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  card: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  cardContent: {
    padding: theme.spacing(1),
  },
});

export default styles;
