export const SIGNUP_BIO_REQUEST = 'SIGNUP_BIO_REQUEST';
export const SIGNUP_BIO_ERROR = 'SIGNUP_BIO_ERROR';
export const SIGNUP_BIO_SUCCESS = 'SIGNUP_BIO_SUCCESS';
export const SIGNUP_CONTACT_REQUEST = 'SIGNUP_CONTACT_REQUEST';
export const SIGNUP_CONTACT_ERROR = 'SIGNUP_CONTACT_ERROR';
export const SIGNUP_CONTACT_SUCCESS = 'SIGNUP_CONTACT_SUCCESS';
export const CONFIRM_INFO_API_REQUEST = 'CONFIRM_INFO_API_REQUEST';
export const CONFIRM_INFO_API_ERROR = 'CONFIRM_INFO_API_ERROR';
export const CONFIRM_INFO_API_SUCCESS = 'CONFIRM_INFO_API_SUCCESS';

export const VERIFY_IDENTITY_CONFIRM_API_REQUEST = 'VERIFY_IDENTITY_CONFIRM_API_REQUEST';
export const VERIFY_IDENTITY_CONFIRM_API_ERROR = 'VERIFY_IDENTITY_CONFIRM_API_ERROR';
export const VERIFY_IDENTITY_CONFIRM_API_SUCCESS = 'VERIFY_IDENTITY_CONFIRM_API_SUCCESS';

export const VERIFY_IDENTITY_SEND_API_REQUEST = 'VERIFY_IDENTITY_SEND_API_REQUEST';
export const VERIFY_IDENTITY_SEND_API_ERROR = 'VERIFY_IDENTITY_SEND_API_ERROR';
export const VERIFY_IDENTITY_SEND_API_SUCCESS = 'VERIFY_IDENTITY_SEND_API_SUCCESS';

export const VERIFY_IDENTITY_SEND_SMS_API_ERROR = 'VERIFY_IDENTITY_SEND_SMS_API_ERROR';
export const VERIFY_IDENTITY_SEND_SMS_API_SUCCESS =
  'VERIFY_IDENTITY_SEND_SMS_API_SUCCESS';
export const VERIFY_IDENTITY_SEND_SMS_API_REQUEST =
  'VERIFY_IDENTITY_SEND_SMS_API_REQUEST';

export const GET_FATUM_SESSION_ID_API_REQUEST = 'GET_FATUM_SESSION_ID_API_REQUEST';
export const GET_FATUM_SESSION_ID_API_ERROR = 'GET_FATUM_SESSION_ID_API_ERROR';
export const GET_FATUM_SESSION_ID_API_SUCCESS = 'GET_FATUM_SESSION_ID_API_SUCCESS';

export const VALIDATE_CREDENTIALS_API_REQUEST = 'VALIDATE_CREDENTIALS_API_REQUEST';
export const VALIDATE_CREDENTIALS_API_SUCCESS = 'VALIDATE_CREDENTIALS_API_SUCCESS';
export const VALIDATE_CREDENTIALS_API_ERROR = 'VALIDATE_CREDENTIALS_API_ERROR';
export const SIGNIN_API_REQUEST = 'SIGNIN_API_REQUEST';
export const SIGNIN_API_ERROR = 'SIGNIN_API_ERROR';
export const SIGNIN_API_SUCCESS = 'SIGNIN_API_SUCCESS';
export const FORGOT_PASSWORD_API_REQUEST = 'FORGOT_PASSWORD_API_REQUEST';
export const FORGOT_PASSWORD_API_ERROR = 'FORGOT_PASSWORD_API_ERROR';
export const FORGOT_PASSWORD_API_SUCCESS = 'FORGOT_PASSWORD_API_SUCCESS';
export const AGENT_FORGOT_PASSWORD_API_REQUEST = 'AGENT_FORGOT_PASSWORD_API_REQUEST';
export const AGENT_FORGOT_PASSWORD_API_ERROR = 'AGENT_FORGOT_PASSWORD_API_ERROR';
export const AGENT_FORGOT_PASSWORD_API_SUCCESS = 'AGENT_FORGOT_PASSWORD_API_SUCCESS';
export const RESET_PASSWORD_API_REQUEST = 'RESET_PASSWORD_API_REQUEST';
export const RESET_PASSWORD_API_ERROR = 'RESET_PASSWORD_API_ERROR';
export const RESET_PASSWORD_API_SUCCESS = 'RESET_PASSWORD_API_SUCCESS';
export const AGENT_RESET_PASSWORD_API_REQUEST = 'AGENT_RESET_PASSWORD_API_REQUEST';
export const AGENT_RESET_PASSWORD_API_ERROR = 'AGENT_RESET_PASSWORD_API_ERROR';
export const AGENT_RESET_PASSWORD_API_SUCCESS = 'AGENT_RESET_PASSWORD_API_SUCCESS';

export const AGENT_REFERRAL_VERIFICATION_REQUEST = 'AGENT_REFERRAL_VERIFICATION_REQUEST';
export const AGENT_REFERRAL_VERIFICATION_SUCCESS = 'AGENT_REFERRAL_VERIFICATION_SUCCESS';
export const AGENT_REFERRAL_VERIFICATION_ERROR = 'AGENT_REFERRAL_VERIFICATION_ERROR';

export const SET_PASSWORD_API_REQUEST = 'SET_PASSWORD_API_REQUEST';
export const SET_PASSWORD_API_ERROR = 'SET_PASSWORD_API_ERROR';
export const SET_PASSWORD_API_SUCCESS = 'SET_PASSWORD_API_SUCCESS';
export const RESET_AUTH_SUCCESS = 'RESET_AUTH_SUCCESS';
export const SIGNIN_VIA_FACEBOOK_API_REQUEST = 'SIGNIN_VIA_FACEBOOK_API_REQUEST';
export const SIGNIN_VIA_FACEBOOK_API_ERROR = 'SIGNIN_VIA_FACEBOOK_API_ERROR';
export const SIGNIN_VIA_FACEBOOK_API_SUCCESS = 'SIGNIN_VIA_FACEBOOK_API_SUCCESS';
export const FACEBOOK_REDIRECT_API_REQUEST = 'FACEBOOK_REDIRECT_API_REQUEST';
export const FACEBOOK_REDIRECT_API_ERROR = 'FACEBOOK_REDIRECT_API_ERROR';
export const FACEBOOK_REDIRECT_API_SUCCESS = 'FACEBOOK_REDIRECT_API_SUCCESS';

export const SIGNUP_VERIFY_IDENTITY_CONFIRM_API_REQUEST =
  'SIGNUP_VERIFY_IDENTITY_CONFIRM_API_REQUEST';
export const SIGNUP_VERIFY_IDENTITY_CONFIRM_API_ERROR =
  'SIGNUP_VERIFY_IDENTITY_CONFIRM_API_ERROR';
export const SIGNUP_VERIFY_IDENTITY_CONFIRM_API_SUCCESS =
  'SIGNUP_VERIFY_IDENTITY_CONFIRM_API_SUCCESS';

export const AGENT_SIGNUP_VERIFY_IDENTITY_CONFIRM_API_REQUEST =
  'AGENT_SIGNUP_VERIFY_IDENTITY_CONFIRM_API_REQUEST';
export const AGENT_SIGNUP_VERIFY_IDENTITY_CONFIRM_API_ERROR =
  'AGENT_SIGNUP_VERIFY_IDENTITY_CONFIRM_API_ERROR';
export const AGENT_SIGNUP_VERIFY_IDENTITY_CONFIRM_API_SUCCESS =
  'AGENT_SIGNUP_VERIFY_IDENTITY_CONFIRM_API_SUCCESS';

export const FORGOT_VERIFY_IDENTITY_CONFIRM_API_REQUEST =
  'FORGOT_VERIFY_IDENTITY_CONFIRM_API_REQUEST';
export const FORGOT_VERIFY_IDENTITY_CONFIRM_API_ERROR =
  'FORGOT_VERIFY_IDENTITY_CONFIRM_API_ERROR';
export const FORGOT_VERIFY_IDENTITY_CONFIRM_API_SUCCESS =
  'FORGOT_VERIFY_IDENTITY_CONFIRM_API_SUCCESS';

export const SIGN_OUT_API_SUCCESS = 'SIGN_OUT_API_SUCCESS';
export const SIGN_OUT_API_ERROR = 'SIGN_OUT_API_ERROR';
export const SIGN_OUT_API_REQUEST = 'SIGN_OUT_API_REQUEST';

export const FORGOT_VERIFY_IDENTITY_SEND_API_REQUEST =
  'FORGOT_VERIFY_IDENTITY_SEND_API_REQUEST';
export const FORGOT_VERIFY_IDENTITY_SEND_API_ERROR =
  'FORGOT_VERIFY_IDENTITY_SEND_API_ERROR';
export const FORGOT_VERIFY_IDENTITY_SEND_API_SUCCESS =
  'FORGOT_VERIFY_IDENTITY_SEND_API_SUCCESS';

export const SIGNIN_VIA_GOOGLE_API_SUCCESS = 'SIGNIN_VIA_GOOGLE_API_SUCCESS';
export const SIGNIN_VIA_GOOGLE_API_ERROR = 'SIGNIN_VIA_GOOGLE_API_ERROR';
export const SIGNIN_VIA_GOOGLE_API_REQUEST = 'SIGNIN_VIA_GOOGLE_API_REQUEST';
export const GOOGLE_REDIRECT_API_SUCCESS = 'GOOGLE_REDIRECT_API_SUCCESS';
export const GOOGLE_REDIRECT_API_ERROR = 'GOOGLE_REDIRECT_API_ERROR';
export const GOOGLE_REDIRECT_API_REQUEST = 'GOOGLE_REDIRECT_API_REQUEST';

export const GET_NEW_ACCESS_TOKEN_API_REQUEST = 'GET_NEW_ACCESS_TOKEN_API_REQUEST';
export const GET_NEW_ACCESS_TOKEN_API_ERROR = 'GET_NEW_ACCESS_TOKEN_API_ERROR';
export const GET_NEW_ACCESS_TOKEN_API_SUCCESS = 'GET_NEW_ACCESS_TOKEN_API_SUCCESS';
export const CHANGE_CUSTOMER_ID_API_SUCCESS = 'CHANGE_CUSTOMER_ID_API_SUCCESS';

export const SELECT_CLIENTS_SUCCESS = 'SELECT_CLIENTS_SUCCESS';
export const SELECT_REPORTS_SUCCESS = 'SELECT_REPORTS_SUCCESS';

export const SELECT_PRODUCTS_SUCCESS = 'SELECT_PRODUCTS_SUCCESS';
export const SELECT_DUTCH_CLIENTS_SUCCESS = 'SELECT_DUTCH_CLIENTS_SUCCESS';

export const UPDATE_SOURCE_ID_SUCCESS = 'UPDATE_SOURCE_ID_SUCCESS';

export const ADD_TRUSTED_DEVICE_API_REQUEST = 'ADD_TRUSTED_DEVICE_API_REQUEST';
export const ADD_TRUSTED_DEVICE_API_ERROR = 'ADD_TRUSTED_DEVICE_API_ERROR';
export const ADD_TRUSTED_DEVICE_API_SUCCESS = 'ADD_TRUSTED_DEVICE_API_SUCCESS';

export const SET_AUTH_USERNAME_SUCCESS = 'SET_AUTH_USERNAME_SUCCESS';

export const SIGNUP_EXISTING_CUSTOMER_REQUEST = 'SIGNUP_EXISTING_CUSTOMER_REQUEST';
export const SIGNUP_EXISTING_CUSTOMER_ERROR = 'SIGNUP_EXISTING_CUSTOMER_ERROR';
export const SIGNUP_EXISTING_CUSTOMER_SUCCESS = 'SIGNUP_EXISTING_CUSTOMER_SUCCESS';

export const RESET_AUTH_STATE_SUCCESS = 'RESET_AUTH_STATE_SUCCESS';

export const SET_RECAPTCHA_TOKEN = 'SET_RECAPTCHA_TOKEN ';

export const TRIGGER_RECAPTCHA = 'TRIGGER_RECAPTCHA';
