import {
  UPDATE_LIVE_CHAT_BRIGHT_PATTERN,
  UPDATE_LIVE_CHAT_CRM_D365,
  UPDATE_END_LIVE_CHAT,
  SET_BRIGHT_PATTERN_CONTEXT,
  SET_CRM_CONTEXT,
  START_GLL_LIVE_CHAT,
  END_GLL_LIVE_CHAT,
  UPDATE_GLL_CHAT_BOT_STATUS,
} from '../../constants/liveChatActionNames';

export const initialState = {
  context: null,
  chatId: null,
  history: [],
};

const liveChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LIVE_CHAT_BRIGHT_PATTERN: {
      const payload = action?.payload;
      return {
        ...state,
        chatId: payload.chatId,
      };
    }
    case UPDATE_LIVE_CHAT_CRM_D365: {
      const payload = action?.payload;
      return {
        ...state,
        chatId: payload.chatId,
      };
    }
    case SET_BRIGHT_PATTERN_CONTEXT: {
      return {
        ...state,
        context: action.payload,
      };
    }
    case SET_CRM_CONTEXT: {
      return {
        ...state,
        context: action.payload,
      };
    }
    case UPDATE_END_LIVE_CHAT: {
      return {
        ...state,
        context: null,
        chatId: null,
      };
    }
    case START_GLL_LIVE_CHAT: {
      return {
        ...state,
        user: action.payload,
        gllChat: true,
      };
    }
    case END_GLL_LIVE_CHAT: {
      return {
        ...state,
        user: null,
        gllChat: false,
      };
    }
    case UPDATE_GLL_CHAT_BOT_STATUS: {
      return {
        ...state,
        isGLLChatBotReady: action.payload,
      };
    }

    default:
      return state;
  }
};

export default liveChatReducer;
