export const GET_NOTIFICATION_API_REQUEST = 'GET_NOTIFICATION_API_REQUEST';
export const GET_NOTIFICATION_API_SUCCESS = 'GET_NOTIFICATION_API_SUCCESS';
export const GET_NOTIFICATION_API_ERROR = 'GET_NOTIFICATION_API_ERROR';
export const CREATE_NOTIFICATION_API_REQUEST = 'CREATE_NOTIFICATION_API_REQUEST';
export const CREATE_NOTIFICATION_API_SUCCESS = 'CREATE_NOTIFICATION_API_SUCCESS';
export const CREATE_NOTIFICATION_API_ERROR = 'CREATE_NOTIFICATION_API_ERROR';
export const GET_NEW_NOTIFICATION_API_REQUEST = 'GET_NEW_NOTIFICATION_API_REQUEST';
export const GET_NEW_NOTIFICATION_API_SUCCESS = 'GET_NEW_NOTIFICATION_API_SUCCESS';
export const GET_NEW_NOTIFICATION_API_ERROR = 'GET_NEW_NOTIFICATION_API_ERROR';
export const MARK_NOTIFICATION_AS_VIEWED_API_REQUEST =
  'MARK_NOTIFICATION_AS_VIEWED_API_REQUEST';
export const MARK_NOTIFICATION_AS_VIEWED_API_SUCCESS =
  'MARK_NOTIFICATION_AS_VIEWED_API_SUCCESS';
export const MARK_NOTIFICATION_AS_VIEWED_API_ERROR =
  'MARK_NOTIFICATION_AS_VIEWED_API_ERROR';

export const MARK_NOTIFICATION_AS_READ_API_REQUEST =
  'MARK_NOTIFICATION_AS_READ_API_REQUEST';
export const MARK_NOTIFICATION_AS_READ_API_SUCCESS =
  'MARK_NOTIFICATION_AS_READ_API_SUCCESS';
export const MARK_NOTIFICATION_AS_READ_API_ERROR = 'MARK_NOTIFICATION_AS_READ_API_ERROR';
