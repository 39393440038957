import React, { useContext, useEffect, useState } from 'react';
import IframeComm from 'react-iframe-comm';
import { useSelector } from 'react-redux';
// import { useRouter } from 'next/router';
import { ThemeContext, ConsentContext } from '../../../../context';
import { domain } from '../../../../utils/variables';
import { createRelativeUrl } from '../../../../utils/functions';

let enableIframe = false;
const SyncWithPublicSite = () => {
  // const [enableIframe, setEnableIframe] = useState(false);
  const { themeName } = useContext(ThemeContext);
  // const user = useSelector(state => state.auth.user);
  const { consent } = useContext(ConsentContext);
  const yourLocation = useSelector(state => state.locations.yourLocation);
  // const language = useSelector(state => state.locations.language);
  const alpha2 = yourLocation?.parentTerritory?.alpha2 || yourLocation?.alpha2;

  // domain.publicSite
  const setUrl = `${domain.publicSite}/update-data`;
  const initialUrl = '';
  const [iframeUrl, setIframeUrl] = useState(initialUrl);

  // iframe has loaded
  const onReady = () => {
    // console.log('onReady');
  };

  useEffect(() => {
    // console.log('themeName', themeName);
    // console.log('alpha2', alpha2);
    // console.log('language', language);
    // console.log('enableIframe: ', enableIframe);
    if (enableIframe) {
      const keys = consent?.customConsent || [];
      const cookies = consent?.cookies || [];
      const consentValue = consent?.consent;
      const cookiePolicyVersion = consent?.version;

      const cookieString = JSON.stringify(cookies);
      const delimiter = '@';
      const keyString = JSON.stringify(keys);
      const versionString = cookiePolicyVersion;
      // const keyChain = `${keys?.map((key) => key).join(',')}@${cookies
      //  ?.map((cookie) => cookie?.cookieId)
      //  .join(',')}@${cookiePolicyVersion}`;
      const keyChain = `${keyString}${delimiter}${cookieString}${delimiter}${versionString}`;
      // console.log('keyChain: ', keyChain);

      const params = {
        theme: themeName,
        alpha2,
        // language,
        consent: consentValue, // Cookies.getCookie(consentKey),
        customConsent: keyChain, // Cookies.getCookie(customConsentKey),
        // c: alpha2,
      };
      /// if (alpha2 === 'TT' || alpha2 === 'BB' || alpha2 === 'JM' || alpha2 === 'AN') {
      // params.alpha2 = alpha2;
      // }

      const newSetUrl = createRelativeUrl({ path: setUrl, queryObject: params });
      // console.log('newSetUrl: ', newSetUrl);
      setIframeUrl(newSetUrl);
    }
  }, [themeName, JSON.stringify(consent), alpha2]);

  // /language, alpha2,
  useEffect(() => {
    setTimeout(() => {
      enableIframe = true;
    }, 2000);
  }, []);

  const attributes = {
    src: iframeUrl, // http://c360-pwa-test.azurewebsites.net //http://localhost:3001
    width: '100%',
    height: '200',
    frameBorder: 1,
  };
  // console.log('enableIframe: ', enableIframe);
  return (
    <div style={{ display: 'none' }}>
      {consent?.consent && enableIframe && iframeUrl && (
        <IframeComm
          attributes={attributes}
          // postMessageData={postMessageData}
          handleReady={onReady}
        />
      )}
    </div>
  );
};

export default SyncWithPublicSite;
