export const GET_INSURANCES_API_REQUEST = 'GET_INSURANCES_API_REQUEST';
export const GET_INSURANCES_API_SUCCESS = 'GET_INSURANCES_API_SUCCESS';
export const GET_INSURANCES_API_ERROR = 'GET_INSURANCES_API_ERROR';
export const GET_NEXT_INSURANCES_SCROLL_SUCCESS = 'GET_NEXT_INSURANCE_SCROLL_SUCCESS';
export const GET_INSURANCE_FILTER_SETTINGS_API_REQUEST =
  'GET_INSURANCE_FILTER_SETTINGS_API_REQUEST';
export const GET_INSURANCE_FILTER_SETTINGS_API_SUCCESS =
  'GET_INSURANCE_FILTER_SETTINGS_API_SUCCESS';
export const GET_INSURANCE_FILTER_SETTINGS_API_ERROR =
  'GET_INSURANCE_FILTER_SETTINGS_API_ERROR';
export const GET_SINGLE_INSURANCE_API_REQUEST = 'GET_SINGLE_INSURANCE_API_REQUEST';
export const GET_SINGLE_INSURANCE_API_SUCCESS = 'GET_SINGLE_INSURANCE_API_SUCCESS';
export const GET_SINGLE_INSURANCE_API_ERROR = 'GET_SINGLE_INSURANCE_API_ERROR';
export const RESET_INSURANCE = 'RESET_INSURANCE';

export const GET_INSURANCE_INVESTMENTS_REQUEST = 'GET_INSURANCE_INVESTMENTS_REQUEST';
export const GET_INSURANCE_INVESTMENTS_SUCCESS = 'GET_INSURANCE_INVESTMENTS_SUCCESS';
export const GET_INSURANCE_INVESTMENTS_ERROR = 'GET_INSURANCE_INVESTMENTS_ERROR';

export const UPDATE_TOTALS_SUCCESS = 'UPDATE_TOTALS_SUCCESS';
export const UPDATE_INSURANCE_BODY_SUCCESS = 'UPDATE_INSURANCE_BODY_SUCCESS';
