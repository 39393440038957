import React from 'react';
// must import the react library in every file that uses JSX code. JSX code is basically HTML looking code in a javascript-js file
import ReactDOM from 'react-dom';
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
// import { render } from 'react-dom';
import 'react-virtualized/styles.css'; // only needs to be imported once
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import 'react-chat-widget/lib/styles.css';

import 'react-quill/dist/quill.bubble.css';

import 'react-dropzone-uploader/dist/styles.css';

import EncryptKeyProvider from './providers/encryptKey';

import App from './App';
// App is the top most react component in the entire react application
import * as serviceWorker from './serviceWorker';

// import localeData from './localeData';

// console.log('env', process.env.NODE_ENV);
// console.log('custom_env', process.env.REACT_APP_CUSTOM_NODE_ENV);
/*
if (
  process.env.REACT_APP_CUSTOM_NODE_ENV === 'staging' ||
  process.env.REACT_APP_CUSTOM_NODE_ENV === 'production'
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
*/
ReactDOM.render(
  <EncryptKeyProvider>
    <App />
  </EncryptKeyProvider>,
  document.getElementById('root'),
);

// the ReactDOM.render function takes the react application and renders it to the html page here.

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
// serviceWorker.register();
/*
ReactDOM.render(
  <IntlProvider locale={language} messages={messages}>
    <App />
  </IntlProvider>,
  document.getElementById('root'),
  */
