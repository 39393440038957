/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Container, Typography, Box, useMediaQuery, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { isValidHttpUrl } from '../../../utils/functions';
import styles from './style';
import { uuidv4 } from '../../../utils/functions/stringManipulation';
import { useRouter } from '../../../hooks';

const TabSelector = ({
  actionTabs = [],
  onTabChange,
  initialTabIndex = 0,
  createTabUrl,
}) => {
  const useStyles = makeStyles(theme => styles(theme));
  const classes = useStyles();
  const router = useRouter();

  const [activeTabIndex, setActiveTabIndex] = useState(initialTabIndex);

  useEffect(() => {
    setActiveTabIndex(initialTabIndex);
  }, [initialTabIndex]);

  const handleChange = (actionItem, index) => {
    setActiveTabIndex(index);
    const redirect = actionItem?.url;
    if (isValidHttpUrl(redirect)) {
      window.open(redirect);
    } else if (redirect?.startsWith('/')) {
      router.push(redirect);
    } else if (onTabChange) {
      onTabChange(actionTabs[index]);
    }
  };

  const tabTitleDisplay = item => (
    <Box
      className={classes.tabActionText}
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ height: '100%' }}
    >
      <Typography
        variant="body1"
        // style={{ color: `${processColor(theme, 'tertiary')}` }}
      >
        {item?.title}
      </Typography>
    </Box>
  );

  const tabDescriptionDisplay = item => (
    <Box
      display="inline-block"
      alignItems="center"
      justifyContent="center"
      style={{ height: '100%', bottom: '50px' }}
      className={classes.tabActionDescription}
    >
      <Typography variant="body2">{item?.description}</Typography>
    </Box>
  );

  const handleClick = url => {
    if (isValidHttpUrl(url)) {
      window.location.href = url;
    } else {
      router.push(url);
    }
  };
  return (
    <Container className={classes.root}>
      {/* .......Parent Container............ */}
      <Box className={classes.dropdownContainer} display="flex" justifyContent="center">
        {actionTabs?.map((actionItem, index) => {
          let tabUrl = actionItem?.url;

          if (createTabUrl) {
            tabUrl = createTabUrl(actionItem);
          }
          let isUrlValid = false;
          if (isValidHttpUrl(tabUrl) || tabUrl?.startsWith('/')) isUrlValid = true;
          return (
            <>
              <Box
                className={classnames(
                  classes.tabActionDropdown,
                  {
                    [classes.tabActive]: index === activeTabIndex,
                  },
                  {
                    [classes.tabInActive]: index !== activeTabIndex,
                  },
                )}
                style={{ height: '50px' }}
                key={uuidv4()}
                onClick={() => handleChange(actionItem, index)}
              >
                {isUrlValid ? (
                  <Link
                    key={tabUrl}
                    variant="body2"
                    onClick={() => handleClick(tabUrl)}
                    className={classes.link}
                  >
                    {tabTitleDisplay(actionItem)}
                  </Link>
                ) : (
                  tabTitleDisplay(actionItem)
                )}
                {actionItem?.description &&
                  index === activeTabIndex &&
                  tabDescriptionDisplay(actionItem)}
              </Box>
            </>
          );
        }) || null}
      </Box>
    </Container>
  );
};

export default TabSelector;

TabSelector.defaultProps = {
  actionTabs: [],
};

TabSelector.propTypes = {
  actionTabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
};
