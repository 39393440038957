export default theme => ({
  root: {
    width: 'inherit',
    marginTop: theme.spacing(10),
    padding: theme.spacing(2),
    minHeight: 'inherit',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
  },
  content: {
    position: 'relative',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'inherit',
    minHeight: 'inherit',
    height: '100%',
  },

  button: {
    [theme.breakpoints.down('sm')]: {
      width: '250px',
      height: '50px',
    },
    [theme.breakpoints.up('md')]: {
      width: '350px',
      height: '50px',
    },
  },
});
