import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { InputBase, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import { Search as SearchIcon } from '@mui/icons-material';
import { useRouter } from '../../../hooks';
import { createRelativeUrl } from '../../../utils/functions';

const styles = theme => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.background.grid, 0.6),
    '&:hover': {
      backgroundColor: alpha(theme.palette.background.grid, 1),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    cursor: 'pointer',
    // right: theme.spacing(1),
    // borderRadius: theme.shape.borderRadius,
    // color: theme.palette.primary.main,
    // paddingRight: theme.spacing(1),
  },
  inputRoot: {
    color: 'inherit',

    display: 'flex !important',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
});

const useStyles = makeStyles(styles);

const SearchFilter = ({ placeholder, hostname }) => {
  const classes = useStyles();
  const router = useRouter();
  const parsed = router?.query || {};
  const defaultSearchValue = parsed?.search || '';
  const [searchValue, setSearchValue] = useState(defaultSearchValue);
  // const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const handleSearch = value => {
    parsed.search = value;
    parsed.startIndex = 0;
    // // console.log('parsed: ', parsed);
    const relativeUrl = createRelativeUrl({
      path: hostname || router.pathname,
      queryObject: parsed,
    });
    // console.log('relativeUrl: ', relativeUrl);
    router.push(relativeUrl);
  };

  const handleChange = value => {
    setSearchValue(value);
  };

  const onBlur = e => {
    // console.log('searchValue: ', searchValue);
    if (!searchValue || searchValue.length === 0) {
      const newValue = '';
      handleSearch(newValue);
    }
  };

  return (
    <div className={classes.search}>
      <InputBase
        placeholder={placeholder || 'Search…'}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        // autoFocus={!isSmall}
        inputProps={{ 'aria-label': 'search' }}
        value={searchValue}
        onBlur={onBlur}
        onChange={e => {
          const newValue = e.target.value;
          handleChange(newValue);
        }}
        onKeyPress={ev => {
          // console.log(`Pressed keyCode ${ev.key}`);
          if (ev.key === 'Enter') {
            // Do code here
            ev.preventDefault();
            handleSearch(searchValue);
          }
        }}
        endAdornment={
          <IconButton
            className={classes.searchIcon}
            onClick={() => handleSearch(searchValue)}
          >
            <SearchIcon />
          </IconButton>
        }
      />
    </div>
  );
};

export default SearchFilter;

/*
    [theme.breakpoints.up('md')]: {
      width: '11ch',
      '&:focus': {
        width: '16ch',
      },
    },

  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

    <div className={classes.searchIcon}>
        <SearchIcon onClick={() => handleSearch(searchValue)} />
      </div>
      */
