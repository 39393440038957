import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  // DialogActions,
  Container,
} from '@mui/material';
import styles from './style';

const useStyles = makeStyles(styles);

const AcknowledgementModal = props => {
  const classes = useStyles();

  // Custom Properties
  const { content, acknowledgementAction, onClose } = props;

  return (
    <Dialog onClose={onClose} open>
      <Container className={classes.modalBox}>
        <DialogContent className={classes.noTitlePadding}>
          <DialogContentText classes={{ root: classes.dialogContentText }}>
            {content}
          </DialogContentText>
        </DialogContent>
      </Container>
    </Dialog>
  );
};

AcknowledgementModal.defaultProps = {
  onClose: () => {},
};

AcknowledgementModal.propTypes = {
  // Dialog Properties
  onClose: PropTypes.func,
  // Custom Properties
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.string]),
  acknowledgementAction: PropTypes.element,
};

export default AcknowledgementModal;

/*
  dialogProps: {
    open: true,
    onClose: () => {},
  },
  */
