export const SUBMIT_RENEWAL_API_REQUEST = 'SUBMIT_RENEWAL_API_REQUEST';
export const SUBMIT_RENEWAL_API_SUCCESS = 'SUBMIT_RENEWAL_API_SUCCESS';
export const SUBMIT_RENEWAL_API_ERROR = 'SUBMIT_RENEWAL_API_ERROR';
export const SUBMIT_TRANSACTION_API_REQUEST = 'SUBMIT_TRANSACTION_API_REQUEST';
export const SUBMIT_TRANSACTION_API_SUCCESS = 'SUBMIT_TRANSACTION_API_SUCCESS';
export const SUBMIT_TRANSACTION_API_ERROR = 'SUBMIT_TRANSACTION_API_ERROR';
export const RESET_TRANSACTION_RENEWAL_SUCCESS = 'RESET_TRANSACTION_RENEWAL_SUCCESS';
export const GET_PRODUCER_DETAILS_REQUEST = 'GET_PRODUCER_DETAILS_REQUEST';
export const GET_PRODUCER_DETAILS_SUCCESS = 'GET_PRODUCER_DETAILS_SUCCESS';
export const GET_PRODUCER_DETAILS_ERROR = 'GET_PRODUCER_DETAILS_ERROR';
export const GET_LINEOFBUSINESS_REQUEST = 'GET_LINEOFBUSINESS_REQUEST';
export const GET_LINEOFBUSINESS_SUCCESS = 'GET_LINEOFBUSINESS_SUCCESS';
export const GET_LINEOFBUSINESS_ERROR = 'GET_LINEOFBUSINESS_ERROR';
