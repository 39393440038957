import {
  SUBMIT_REQUEST_API_REQUEST,
  SUBMIT_REQUEST_API_ERROR,
  SUBMIT_REQUEST_API_SUCCESS,
} from '../../../constants/requestActionNames';

// import { api } from '../../../utils/variables';
import { submitRequestApi } from '../../../apis/requests';

function submitRequestError(err) {
  return {
    type: SUBMIT_REQUEST_API_ERROR,
    payload: err,
  };
}

function submitRequestRequest() {
  return {
    type: SUBMIT_REQUEST_API_REQUEST,
    payload: true,
  };
}

function submitRequestSuccess(device) {
  return {
    type: SUBMIT_REQUEST_API_SUCCESS,
    payload: device,
  };
}

export function submitRequestAction(params) {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(submitRequestRequest());
    return submitRequestApi
      .submitRequest(state, params)
      .then(data => {
        dispatch(submitRequestSuccess(data));
        return data;
      })
      .catch(error => {
        // This is temp
        dispatch(submitRequestError(error));
        throw error;
      });
  };
}

export default {
  submitRequestAction,
};
