import {
  GET_NEXT_INSURED_ITEMS_SUCCESS,
  GET_INSURED_ITEMS_SUCCESS,
} from '../../../constants/insuredItemsActionNames';

function getInsuredItemsSuccess(prod, policy) {
  return {
    type: GET_INSURED_ITEMS_SUCCESS,
    payload: prod,
    policy,
  };
}

export function getInsuredItemsAction(data, policy) {
  return getInsuredItemsSuccess(data, policy);
}

function getNextInsuredItemsSuccess(prod, policy) {
  return {
    type: GET_NEXT_INSURED_ITEMS_SUCCESS,
    payload: prod,
    policy,
  };
}

export function getNextInsuredItemsAction(data, policy) {
  return getNextInsuredItemsSuccess(data, policy);
}

export default { getInsuredItemsAction, getNextInsuredItemsAction };
