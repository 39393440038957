import { UPDATE_YOUR_STORAGE_VERSION_SUCCESS } from '../../../constants/diskActionNames';

export function updateYourStorageVersionAction(storageVersion) {
  return {
    type: UPDATE_YOUR_STORAGE_VERSION_SUCCESS,
    payload: storageVersion,
  };
}

export default {
  updateYourStorageVersionAction,
};
