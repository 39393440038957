import {
  RESET_PASSWORD_API_REQUEST,
  RESET_PASSWORD_API_ERROR,
  RESET_PASSWORD_API_SUCCESS,
} from '../../../constants/authActionNames';

// import { api } from '../../../utils/variables';
import { resetPasswordApi } from '../../../apis/auth';

function resetPasswordError(err) {
  return {
    type: RESET_PASSWORD_API_ERROR,
    payload: err,
  };
}

function resetPasswordRequest() {
  return {
    type: RESET_PASSWORD_API_REQUEST,
    payload: true,
  };
}

function resetPasswordSuccess(data) {
  return {
    type: RESET_PASSWORD_API_SUCCESS,
    payload: data,
  };
}

export function resetPasswordAction(_credentials) {
  const credentials = { ..._credentials };
  // credentials.apiKey = api.apiKey;
  // credentials.apiSecret = api.apiSecret;

  return (dispatch, getState) => {
    const state = getState();
    dispatch(resetPasswordRequest());
    return resetPasswordApi
      .resetPassword(state, credentials)
      .then(result => {
        dispatch(resetPasswordSuccess(result));
        return result;
      })
      .catch(error => {
        dispatch(resetPasswordError(error));
        throw error;
      });
  };
}

export default {
  resetPasswordAction,
};
