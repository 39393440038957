import React from 'react';


export default function EndStepper({
  backgroundColor,

  children,
  ...restProps
}) {
  return (
    <svg
      viewBox="1009.746 300.848 901.012 303.049"
      {...restProps}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        id="path3"
        fill={backgroundColor}
        d="M 1726.751 302.373 L 1010.252 302.395 L 1148.613 452.469 L 1011.438 602.373 L 1727.867 595.013 C 1969.661 594.809 1972.803 302.473 1726.751 302.373"
        transform="matrix(0.999994, 0.00339, -0.00339, 0.999994, 1.541936, -4.947657)"
      />
    </svg>
  );
}

/*
  textColor,
  textSize,
<text
        textAnchor="middle"
        alignmentBaseline="central"
        x="100"
        y="25"
        fill={textColor}
        fontSize={textSize}
      >
        <textPath xlinkHref="#path3" startOffset="50%">
          {children}
        </textPath>
      </text>
      */
