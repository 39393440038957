import { crmPostCall } from '../../axios';
import { helpCenterApi } from '../../../utils/functions/helpCenterApis';

import { SUBMITHELPREQUESTURL, SERVERERROR } from '../constants';

export const submitHelpRequestUrl = helpCenterApi(SUBMITHELPREQUESTURL);

const submitHelpRequest = (state, body) => {
  const url = submitHelpRequestUrl;
  if (url) {
    return crmPostCall(state, url, body);
  }
  return Promise.reject(new Error(SERVERERROR));
};

export default { submitHelpRequest };
