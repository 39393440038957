import { loadTransactionCount } from '../../../utils/variables';

const sampleData = params => {
  const arrayLength = loadTransactionCount;
  const startIndex = params.offset || 0;
  return Array.from({ length: arrayLength }, (v, i) => {
    return {
      _id: `URE0000005959FD0000000143920830382_${i + startIndex}`,
      date: '2020-04-01T00:00:00.000Z',
      fees: Math.floor(Math.random() * 100),
      fxRate: Math.floor(Math.random() * 10),
      price: Math.floor(Math.random() * 10),
      qty: Math.floor(Math.random() * 500),
      security: `TTD MONTHLY INCOME FUND_${Math.floor(Math.random() * 100)}`,
      tax: 0,
      trxValue: Math.floor(Math.random() * 5000),
      type: `REDEMPTION_${Math.floor(Math.random() * 10)}`,
      ClientId: '0000005959',
      secCode: 'FD00000001',
      cert: null,
      income: 0,
      reverse: false,
      deltaDate: '2020-04-01T20:28:28.513Z',
      xdDt: null,
      mult: 1,
      rtBVS: 0,
      rtBVT: 0,
      rtNom: 0,
      transactionId: '1077_694598',
      st: true,
    };
  });
};

export default sampleData;
