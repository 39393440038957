import { gql } from 'graphql-request';

const RADIO_CHOICE_FIELDS = gql`
  fragment radioChoice on ComponentWizardComponentsRadioChoice {
    RadioChoice_slug: slug
    RadioChoice_name: name
    RadioChoice_label: label
    RadioChoice_color: color
    RadioChoice_variant: variant
    RadioChoice_horizontal: horizontal
    RadioChoice_labelAlign: labelAlign
    RadioChoice_layoutPosition: layoutPosition
    RadioChoice_staticValue: staticValue
    RadioChoice_reducerKeyValue: reducerKeyValue
    RadioChoice_positionNo: positionNo
    RadioChoice_options: options {
      ...nonRecursiveOption
    }
    RadioChoice_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    RadioChoice_fullWidth: fullWidth
    RadioChoice_required: required
    RadioChoice_tooltip: tooltip
    RadioChoice_isConditionalComponent: isConditionalComponent
    RadioChoice_conditionalValue: conditionalValue
    RadioChoice_conditionalKey: conditionalKey
    RadioChoice_conditionalOperator: conditionalOperator
    RadioChoice_conditionalFieldType: conditionalFieldType
    RadioChoice_conditionalAction: conditionalAction
    RadioChoice_conditionalArrayKey: conditionalArrayKey
    RadioChoice_disable: disable
    RadioChoice_show: show
    RadioChoice_tooltip_en: tooltip_en
    RadioChoice_tooltip_nl: tooltip_nl
    RadioChoice_label_en: label_en
    RadioChoice_label_nl: label_nl
    RadioChoice_disableInitialValue: disableInitialValue
    RadioChoice_addBorder: addBorder
    RadioChoice_reloadWizard: reloadWizard
    RadioChoice_yesRequired: yesRequired
    RadioChoice_validationReqMessage: validationReqMessage
    RadioChoice_validationReqMessage_en: validationReqMessage_en
    RadioChoice_validationReqMessage_nl: validationReqMessage_nl
    RadioChoice_storeKey: storeKey
    RadioChoice_optionLabelKey: optionLabelKey
    RadioChoice_optionValueKey: optionValueKey
    RadioChoice_allInitialWizardDataAppendKeys: allInitialWizardDataAppendKeys
    RadioChoice_conditions: conditions {
      ...condition
    }
  }
`;

// export default RADIO_CHOICE_FIELDS;
// exports.default = RADIO_CHOICE_FIELDS;
export default RADIO_CHOICE_FIELDS;
