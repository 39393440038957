import { CHANGE_CUSTOMER_ID_API_SUCCESS } from '../../../constants/authActionNames';

function changeCustomerId(userDetails) {
  return {
    type: CHANGE_CUSTOMER_ID_API_SUCCESS,
    payload: userDetails,
  };
}

const updateUserDetailsAction = customerType => {
  const data = {
    customerType,
  };
  return (dispatch, getState) => {
    // const state = getState();
    dispatch(changeCustomerId(data));
  };
};

export default { updateUserDetailsAction };
