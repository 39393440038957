import { routerGetCall } from '../../axios';

export const getClientsUrl = `/customers/getAllClients`;
const getClients = (state, params) => {
  const url = getClientsUrl;
  return routerGetCall(state, url, params);
};

export const getSingleClientUrl = '/customers/customers';
const getSingleClient = (state, params) => {
  const url = `${getSingleClientUrl}/${params.id}`;
  delete params.id;
  return routerGetCall(state, url, params);
};

export default { getClients, getSingleClient };
