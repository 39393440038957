export const formInputEntries = '60d3de1dc3336a3ac89fa060'; // 'form-input-entries';
export const userPortfolioData = '60d3e13ab1e04f3f24f70944'; // 'user-portfolio-data';
export const downloadAppModalLastSeen = '60d3e40db1e04f3f24f7094d'; // 'download-app-modal-screen-last-seen';
export const googleAds = '60d3e360b1e04f3f24f7094b'; // 'google-ads';
export const googleAnalyticsPageViews = '60d3e333b1e04f3f24f7094a'; // 'google-analytics';
export const userConsentSelection = '60d3e449b1e04f3f24f7094e'; // 'user-consent-selection';
export const userCountrySelection = '60d3e1cfb1e04f3f24f70945'; // 'user-country-selection';
export const userLanguageSelection = '60d3e220b1e04f3f24f70947'; // 'user-language-selection';
export const userTemplateSection = '60d3e1f8b1e04f3f24f70989';
export const userThemeSelection = '60d3e1f8b1e04f3f24f70946'; // 'user-theme-selection';
export const userDeviceTokens = '60d3e2d3b1e04f3f24f70949'; // 'user-device-tokens';
export const userSessionToken = '60d3e26ab1e04f3f24f70948'; // 'user-session-token';
