import React from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  Button,
  Dialog,
  Hidden,
  Container,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import ImageBox from '../imageBox';
import ContentDisplay from '../contentDisplay';
import { updateTempState } from '../../../actions/temp';
import Brand from '../brand';
import Header from '../header';

const useStyles = makeStyles(theme => ({
  appBar: {
    // position: 'relative',
    backgroundColor: `inherit !important`,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  cookieButtonTitle: {
    textTransform: 'capitalize',
    textDecoration: 'underline',
  },
  dialogContent: {
    position: 'relative',
  },
  button: {
    padding: 0,
    width: 'auto !important',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CookiePolicyModal = ({
  cookiePolicyButtonTitle,
  cookiePolicyTitle,
  cookiePolicyContent,
  cookiePolicyFeaturedImagePWA,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    const dialogContent = (
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <Header title={cookiePolicyTitle} titleVariant="h4" align="center" />
        </Grid>
        <Grid item lg={12}>
          <ContentDisplay content={cookiePolicyContent} />
        </Grid>
      </Grid>
    );

    const deleteObj = {
      content: dialogContent,
      allowFullscreen: true,
    };
    dispatch(updateTempState.updateTempStateAction({ alertPopUp: deleteObj }));
  };

  return (
    <>
      <Button
        variant="text"
        color="primary"
        onClick={handleClickOpen}
        className={classes.button}
      >
        <Typography variant="subtitle1" className={classes.cookieButtonTitle}>
          {cookiePolicyButtonTitle}
        </Typography>
      </Button>
    </>
  );
};

export default CookiePolicyModal;
