import React, { useEffect, useContext, useRef } from 'react';
import { Button } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { setRecaptchaToken } from '../../../actions/auth';
import { ThemeContext } from '../../../context';
import { disableRecaptchaEnv } from '../../../utils/variables';
import { updateTempState } from '../../../actions/temp';
import { parseBool, displayWarning } from '../../../utils/functions';
import styles from './style';

const useStyles = makeStyles(styles);

const ReCaptcha = ({ recaptchaType = 'normal' }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const _reCaptchaRef = useRef();
  const recaptchaToken = useSelector(state => state.auth.recaptchaToken);
  const triggerRecaptcha = useSelector(state => state.auth.triggerRecaptcha);
  const siteWide = useSelector(state => state?.locations?.siteWide);
  const disableRecaptcha = siteWide?.disableRecaptcha || parseBool(disableRecaptchaEnv);
  const REACT_APP_GOOGLE_RECAPTCHA = siteWide?.keys?.REACT_APP_GOOGLE_RECAPTCHA;
  // console.log('disableRecaptcha: ', disableRecaptcha);
  // console.log('enableRecaptha: ', enableRecaptha);
  const { themeName } = useContext(ThemeContext);

  useEffect(() => {
    dispatch(setRecaptchaToken.setRecaptchaTokenAction(null));
  }, []);

  useEffect(async () => {
    if (triggerRecaptcha && recaptchaType === 'invisible' && !disableRecaptcha) {
      await _reCaptchaRef?.current?.reset();
      const token = await _reCaptchaRef?.current?.executeAsync();
      // console.log('token: ', token);
      if (token) {
        dispatch(setRecaptchaToken.setRecaptchaTokenAction(token));
        // console.log('execute: ');
      }
    }
  }, [triggerRecaptcha]);

  const handleChange = value => {
    const token = value;
    if (token) {
      dispatch(setRecaptchaToken.setRecaptchaTokenAction(token));
    }
  };

  if (disableRecaptcha) {
    return null;
  }

  return (
    <ReCAPTCHA
      style={{ display: 'inline-block' }}
      theme={themeName}
      ref={_reCaptchaRef}
      sitekey={REACT_APP_GOOGLE_RECAPTCHA}
      onChange={handleChange}
      onExpired={() => {
        dispatch(setRecaptchaToken.setRecaptchaTokenAction(null));
      }}
      size={recaptchaType}
      badge="bottomleft"
      onErrored={() => {
        const dismissiveAction = (
          <Button
            className={classes.primaryButton}
            sx={{ width: '6rem', mx: 1 }}
            onClick={() => window.location.reload()}
          >
            Reload Site
          </Button>
        );

        const title = `Refresh Required`; // : ${cartItem?.productName}
        const dialogContent = (
          <>
            The login page failed to load properly. Click 'Reload Site' to try again.
            <br />
          </>
        );

        const alertObj = {
          dismissiveAction,
          title,
          content: dialogContent,
          blockEscape: true,
        };

        dispatch(updateTempState.updateTempStateAction({ alertPopUp: alertObj }));
      }}
    />
  );
};
export default ReCaptcha;
