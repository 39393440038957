import { gql } from 'graphql-request';

const FEATURED_IMAGE_PWA_FIELDS = gql`
  fragment featuredImagePWA on ComponentMiscComponentsSimpleImageBox {
    source {
      sm {
        url
      }
      md {
        url
      }
      lg {
        url
      }
    }
    height {
      sm
      md
      lg
      xl
    }
    width {
      sm
      md
      lg
      xl
    }
    fit {
      sm
      md
      lg
      xl
    }
    offsetX {
      sm
      md
      lg
      xl
    }
    offsetY {
      sm
      md
      lg
      xl
    }
    scale {
      sm
      md
      lg
      xl
    }
    caption
    caption_en
    caption_nl
    newSource {
      url
      formats
    }
    backgroundPosition
    boxStyle {
      sm
      md
      lg
      xl
    }
    imageStyle {
      sm
      md
      lg
      xl
    }
  }
`;

// exports.default = FEATURED_IMAGE_PWA_FIELDS;
/*
(function(exports) {
  // your code goes here
  exports.default = APPEND_KEY_FIELDS;
  // (typeof module === 'object') module.exports
})(typeof exports === 'undefined' ? {} : exports);
*/
// module.exports FEATURED_IMAGE_PWA_FIELDS;
export default FEATURED_IMAGE_PWA_FIELDS;
