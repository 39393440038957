import {
  VALIDATE_CREDENTIALS_API_REQUEST,
  VALIDATE_CREDENTIALS_API_ERROR,
  VALIDATE_CREDENTIALS_API_SUCCESS,
} from '../../../constants/authActionNames';

// import { api } from '../../../utils/variables';
import { validateCredentialsApi } from '../../../apis/auth';

function validateCredentialsError(err) {
  return {
    type: VALIDATE_CREDENTIALS_API_ERROR,
    payload: err,
  };
}

function validateCredentialsRequest() {
  return {
    type: VALIDATE_CREDENTIALS_API_REQUEST,
    payload: true,
  };
}

function validateCredentialsSuccess(user) {
  return {
    type: VALIDATE_CREDENTIALS_API_SUCCESS,
    payload: user,
  };
}

export function validateCredentialsAction(_credentials, _body, headers) {
  const credentials = { ..._credentials };
  const body = {
    territory: credentials.territory,
    ..._body,
    username: _credentials.username,
    password: _credentials.password,
  };
  // credentials.apiKey = api.apiKey;
  // credentials.apiSecret = api.apiSecret;

  return (dispatch, getState) => {
    const state = getState();
    dispatch(validateCredentialsRequest());
    return validateCredentialsApi
      .validateCredentials(state, credentials, body, headers)
      .then(data => {
        const { username, redirect } = credentials;
        const newData = { ...data, username, redirect };
        dispatch(validateCredentialsSuccess(newData));
        return newData;
      })
      .catch(error => {
        // This is temp
        dispatch(validateCredentialsError(error));
        throw error;
      });
  };
}

export default {
  validateCredentialsAction,
};
