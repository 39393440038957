import { SIGNUP_CONTACT_SUCCESS } from '../../../constants/authActionNames';

function signUpContactSuccess(user) {
  return {
    type: SIGNUP_CONTACT_SUCCESS,
    payload: user,
  };
}

export function signUpContactAction(data) {
  return signUpContactSuccess(data);
}

export default {
  signUpContactAction,
};
