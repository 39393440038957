import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton, Grid, Container, Typography, Tooltip } from '@mui/material';
import { Settings as SettingsIcon } from '@mui/icons-material';
import DetailsModal from '../detailsModal';
import { updateTempState } from '../../../actions/temp';
import { LocationSwitcher, ThemeSwitcher, LanguageSwitcher } from '../../common';
import { useVariables } from '../../../hooks';

export default function Settings(props) {
  const dispatch = useDispatch();
  const settingsOpen = useSelector(state => state.temp.settingsOpen);
  const contactsOpen = useSelector(state => state.temp.contactsOpen);
  const variables = useVariables();
  const contactNumber =
    variables?.contactNumbers?.contact_us?.phoneFormatted ||
    variables?.contactNumbers?.contact_us?.phone;

  const handleClose = () => {
    dispatch(updateTempState.updateTempStateAction({ settingsOpen: false }));
    dispatch(updateTempState.updateTempStateAction({ contactsOpen: false }));
  };

  let content = null;
  let title = '';
  if (settingsOpen) {
    title = 'My App Settings';
    content = (
      <Container>
        <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
          <Grid item xs={12} sm={5} align="center">
            <Typography variant="body1">Choose your Appearance:</Typography>
          </Grid>
          <Grid item xs={12} sm={7} align="center">
            <ThemeSwitcher id="ModalThemeSwitcher" />
          </Grid>
          <Grid item xs={12} sm={5} align="center">
            <Typography variant="body1">Choose your Location:</Typography>
          </Grid>
          <Grid item xs={12} sm={7} align="center">
            <LocationSwitcher view="fullText" id="ModalLocationSwitcher" />
          </Grid>
          <Grid item xs={12} sm={5} align="center">
            <Typography variant="body1">Choose your Language:</Typography>
          </Grid>
          <Grid item xs={12} sm={7} align="center">
            <LanguageSwitcher type="select" id="ModalLanguageSwitcher" />
          </Grid>
          {/* <Grid item xs={12} sm={5} align="center">
          <Typography variant="body1">Date format:</Typography>
        </Grid>
        <Grid item xs={12} sm={7} align="center">
          <LanguageSwitcher type="select" />
        </Grid>
        */}
        </Grid>
      </Container>
    );
  } else if (contactsOpen) {
    title = 'Contact Details';
    content = (
      <Container sx={{ p: 5 }}>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Typography style={{ whiteSpace: 'pre-line' }}>{contactNumber}</Typography>
        </Grid>
      </Container>
    );
  }

  const open = settingsOpen || contactsOpen;
  return (
    <div>
      {open && (
        <DetailsModal
          title={title}
          onClose={handleClose} // dispatch(setModalContent.setModalContentAction(null))
          content={content}
        />
      )}
    </div>
  );
}
