// Hook
import { useSelector } from 'react-redux';
import { getRef } from '../utils/functions';

const useVariables = name => {
  const variables = useSelector(state => state?.locations?.yourLocation?.variables);

  if (name) {
    const value = getRef(variables, name);
    return value;
  }

  return variables;
};

export default useVariables;
