import React, { useState } from 'react';
import { Grid, Button, IconButton, Badge, Icon, Tooltip } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { useRouter } from '../../../hooks';
import routes from '../../../utils/variables/routes';

const styles = theme => ({
  padding: {
    padding: theme.spacing(2),
  },
});

const useStyles = makeStyles(styles);

const BackButton = ({ onCustomClick }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const router = useRouter();
  const [noBack, setNoBack] = useState(false);
  const { query, history } = router;
  const hideBackButton = useSelector(state => state.temp.hideBackButton);
  // console.log('router: ', router);
  // const nextBackButtonLink = useSelector(state => state.wizards.nextBackButtonLink);
  // const resettedWizardData = useSelector(state => state.wizards.resettedWizardData);

  const removeLastDirectoryPartOf = url => {
    const arr = url.split('/');
    arr.pop();
    return arr.join('/');
  };

  // const previousPath = removeLastDirectoryPartOf(router.pathname);
  // console.log('previousPath: ', previousPath);

  if (
    query?.back === 'fac' ||
    router.pathname === routes.cart.paymentComplete ||
    router.pathname === routes.cart.paymentDeclined ||
    hideBackButton
  ) {
    return null;
  }

  return (
    <>
      {router.pathname.length > 1 &&
        router.pathname !== routes.home &&
        window.history.length > 1 &&
        !noBack && (
          <Tooltip title="Back">
            <IconButton
              sx={{ mx: 1, width: 40, height: 40, color: 'text.main' }}
              onClick={() => {
                onCustomClick('back');
                const result = router.goBack();
                if (result === undefined) {
                  setNoBack(true);
                }
              }}
            >
              <Icon data-nosnippet>arrow_back_ios</Icon>
            </IconButton>
          </Tooltip>
        )}
    </>
  );
};

export default BackButton;

/*
if (resettedWizardData) {
                router.push(previousPath);
                // router.push(nextBackButtonLink);
                dispatch(resetWizard.resetWizardFlagAction(false));
              } else router.goBack();
              */
