import { customThemeStorage } from '../utils/variables';

export const getCustomTheme = () => {
  const theme = localStorage.getItem(customThemeStorage) || null;

  // // console.log('themeName: ', themeName);
  if (!theme) return null;

  return JSON.parse(theme);
};

export const removeCustomTheme = () => {
  if (localStorage.getItem(customThemeStorage)) {
    localStorage.removeItem(customThemeStorage);
  }
  return null;
};

export const setCustomTheme = theme => {
  if (theme) {
    localStorage.setItem(customThemeStorage, JSON.stringify(theme));
  }
  return null;
};

export default {
  setCustomTheme,
  removeCustomTheme,
  getCustomTheme,
};
