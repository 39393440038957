// impot BasicMotorQote fom './basicMotorQuote.json';
// import BasicHealthQuote from './basicHealthQuote.json';
// import BasicLifeQuote from './basicLifeQuote.json';
// import BasicInvestQuote from './basicInvestQuote.json';
//  'gam-meeting-form': '/cacheWizards/gamMeetingForm.json',
import finalCachedWizards from './finalCachedWizards.json';

const wizards = {
  // 'motor-quote': '/cacheWizards/basicMotorQuote.json',
  // 'basic-life-change-beneficiary': '/cacheWizards/basicLifeChangeBeneficiary.json',
  // 'basic-life-change-mode': '/cacheWizards/basicLifeChangeMode.json',
  // 'basic-life-change-address': '/cacheWizards/basicLifeChangeAddress.json',
  // 'basic-life-change-name': '/cacheWizards/basicLifeChangeName.json',
  // 'basic-health-quote': '/cacheWizards/basicHealthQuote.json',
  // 'basic-health-claim': '/cacheWizards/basicHealthClaim.json',
  // 'basic-health-change-beneficiary': '/cacheWizards/basicHealthChangeBeneficiary.json',
  // 'basic-health-change-mode': '/cacheWizards/basicHealthChangeMode.json',
  // 'basic-health-change-address': '/cacheWizards/basicHealthChangeAddress.json',
  // 'basic-health-change-name': '/cacheWizards/basicHealthChangeName.json',
  // 'basic-pension-quote': '/cacheWizards/basicPensionQuote.json',
  // 'basic-pension-change-beneficiary': '/cacheWizards/basicPensionChangeBeneficiary.json',
  // 'basic-pension-change-mode': '/cacheWizards/basicPensionChangeMode.json',
  // 'basic-pension-change-address': '/cacheWizards/basicPensionChangeAddress.json',
  // 'basic-pension-change-name': '/cacheWizards/basicPensionChangeName.json',
  // 'basic-home-quote': '/cacheWizards/basicHomeQuote.json',
  // 'basic-home-claim': '/cacheWizards/basicHomeClaim.json',
  // 'basic-invest-consultation': '/cacheWizards/gamMeetingForm.json', // '/cacheWizards/basicInvestConsultation.json',
  // 'basic-invest-deposit': '/cacheWizards/basicInvestDeposit.json',
  // 'basic-invest-withdrawal': '/cacheWizards/basicInvestWithdrawal.json',
  // 'basic-invest-switch': '/cacheWizards/basicInvestSwitch.json',
  // 'basic-invest-transfer': '/cacheWizards/basicInvestTransfer.json',
  // 'basic-invest-generate-letter': '/cacheWizards/basicInvestGenerateLetter.json',
  // 'edit-profile': '/cacheWizards/editProfile.json',
  // 'edit-id-cards': '/cacheWizards/editIdCards.json',
  // 'basic-invest-deposit-pw': '/cacheWizards/basicInvestDepositPW.json',
  // 'basic-invest-withdrawal-pw': '/cacheWizards/basicInvestWithdrawalPW.json',
  // 'basic-invest-transfer-pw': '/cacheWizards/basicInvestTransferPW.json',
  // 'basic-health-payment-change-request': '/cacheWizards/basicHealthChangePayment.json',
  // 'basic-pension-payment-change-request': '/cacheWizards/basicPensionChangePayment.json',
  // 'basic-life-payment-change-request': '/cacheWizards/basicLifeChangePayment.json',
  // 'claim-producer-fnol-home': '/cacheWizards/claimProducerFNOLHome.json',
  // 'claim-producer-fnol-motor': '/cacheWizards/claimProducerFNOLMotor.json',
  // 'home-quote': '/cacheWizards/homeQuote.json',
  // 'submit-document-home': '/cacheWizards/submitDocumentHome.json',
  // 'submit-document-motor': '/cacheWizards/submitDocumentMotor.json',
  // 'submit-document-general-insurance':
  //   '/cacheWizards/submitDocumentGeneralInsurance.json',
  // 'mortgage-calculator': '/cacheWizards/mortgageCalculator.json',
  // 'customer-referral': '/cacheWizards/customerReferral.json',
  // 'life-quote': '/cacheWizards/lifeQuote.json',
  // 'life-needs-calculator': '/cacheWizards/lifeNeedsCalculator.json',
  // 'life-digital-sales-application-process':
  //   '/cacheWizards/digitalSalesLifeApplicationProcess.json',
  // 'digital-sales-add-beneficiary': '/cacheWizards/digitalSalesBeneficiaryAdd.json',
  // 'digital-sales-edit-beneficiary': '/cacheWizards/digitalSalesBeneficiaryEdit.json',
  // 'home-claim': '/cacheWizards/homeClaim.json',
  // 'motor-claim': '/cacheWizards/motorClaim.json',
  // 'add-details-of-loss-home-claim': '/cacheWizards/addDetailsOfLossHomeClaim.json',
  // 'edit-details-of-loss-home-claim': '/cacheWizards/editDetailsOfLossHomeClaim.json',
  // 'add-third-party-windscreen': '/cacheWizards/addThirdPartyWindscreen.json',
  // 'edit-third-party-windscreen': '/cacheWizards/editThirdPartyWindscreen.json',
  // 'add-witness-motor-claim': '/cacheWizards/addWitnessMotorClaim.json',
  // 'edit-witness-motor-claim': '/cacheWizards/editWitnessMotorClaim.json',
  // 'add-third-party-motor-claim': '/cacheWizards/addThirdPartyMotorClaim.json',
  // 'edit-third-party-motor-claim': '/cacheWizards/editThirdPartyMotorClaim.json',
  // 'add-injury-motor-claim': '/cacheWizards/addInjuryMotorClaim.json',
  // 'edit-injury-motor-claim': '/cacheWizards/editInjuryMotorClaim.json',
  // 'self-service-request': '/cacheWizards/selfServiceRequest.json',
  // 'jm_motor-quote': '/cacheWizards/jmMotorQuote.json',
  // 'jm_home-quote': '/cacheWizards/jmHomeQuote.json',
  // 'letters': '/cacheWizards/letters.json',
  // 'statements': '/cacheWizards/statements.json',
  // 'bb_home-quote': '/cacheWizards/bbHomeQuote.json',
  // 'bb_motor-quote': '/cacheWizards/bbMotorQuote.json',
  // 'pension-value-at-maturity': '/cacheWizards/pensionValueAtMaturity.json',
  // 'motor-claim-type': '/cacheWizards/motorClaimType.json',
  // 'windscreen-motor-claim': '/cacheWizards/windscreenMotorClaim.json',
  // 'motor-accident-motor-claim': '/cacheWizards/motorAccidentMotorClaim.json',
  // 'third-party-motor-claim': '/cacheWizards/thirdPartyMotorClaim.json',
  // 'key-replacement-motor-claim': '/cacheWizards/keyReplacementMotorClaim.json',
};

export const rewrites = {
  'basic-life-quote': 'life-quote',
  'basic-home-quote': 'home-quote',
  'basic-motor-quote': 'motor-quote',
  'basic-motor-claim': 'motor-claim-type',
  'basic-home-claim': 'home-claim',
  'motor-claim': 'motor-claim-type',
  'jm-motor-quote': 'jm_motor-quote',
  'jm-home-quote': 'jm_home-quote',
  'bb-motor-quote': 'bb_motor-quote',
  'bb-home-quote': 'bb_home-quote',
};

export const wizardsToCache = wizards;
export default finalCachedWizards;

/*
import Loadable from 'react-loadable';
import { Spinner } from '../../common';

const BasicMotorQuote = Loadable({
  loader: () => import('./basicMotorQuote'),
  loading: Spinner,
});

const BasicMotorClaim = Loadable({
  loader: () => import('./basicMotorClaim'),
  loading: Spinner,
});

*/
