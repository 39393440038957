import {
  GET_YOUR_LOCATION_API_REQUEST,
  GET_YOUR_LOCATION_API_SUCCESS,
  GET_YOUR_LOCATION_API_ERROR,
} from '../../../constants/mainLayout/locationActionNames';

// import { api } from '../../../utils/variables';
import { getYourLocationApi } from '../../../apis/mainLayout';

function getYourLocationError(err, userType) {
  return {
    type: GET_YOUR_LOCATION_API_ERROR,
    payload: err,
    userType,
  };
}

function getYourLocationRequest() {
  return {
    type: GET_YOUR_LOCATION_API_REQUEST,
    payload: true,
  };
}
function getYourLocationSuccess(loc, userType) {
  return {
    type: GET_YOUR_LOCATION_API_SUCCESS,
    payload: loc,
    userType,
  };
}

export function getYourLocationAction(params) {
  return (dispatch, getState) => {
    const state = getState();
    const userType = state?.auth?.usertype;
    dispatch(getYourLocationRequest());
    return getYourLocationApi
      .getYourLocation(state, params)
      .then(data => {
        // Update the app state with the results of the API call.

        dispatch(getYourLocationSuccess(data, userType));
        return data;
      })
      .catch(error => {
        // console.error('error: ', error);
        dispatch(getYourLocationError(error, userType));
        throw error;
      });
  };
}

export default { getYourLocationAction };
