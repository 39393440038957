import { routerGetCall } from '../../axios';
// authGetCall
const signInViaFacebook = (state, params) => {
  const url = `/auth/facebook/signin`;
  return routerGetCall(state, url, params);
};

// const authFacebook = (state, params) => {
//   const url = `/auth/facebook`;
//   return authGetCall(state, url, params);
// };

export default { signInViaFacebook };
