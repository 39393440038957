import {
  API_CALL_WIZARD_API_REQUEST,
  API_CALL_WIZARD_API_SUCCESS,
  API_CALL_WIZARD_API_ERROR,
} from '../../../constants/wizardActionNames';

// import { api } from '../../../utils/variables';
import { apiCallWizardApi } from '../../../apis/wizards';

function apiCallWizardError(err, savedKey, apiCall) {
  return {
    type: API_CALL_WIZARD_API_ERROR,
    payload: err,
    savedKey,
    apiCall,
  };
}

function apiCallWizardRequest(savedKey, disablePending, apiCall) {
  return {
    type: API_CALL_WIZARD_API_REQUEST,
    payload: !disablePending,
    savedKey,
    apiCall,
  };
}
function apiCallWizardSuccess(prod, savedKey, params, oldStoreData, apiCall, lobTypes) {
  return {
    type: API_CALL_WIZARD_API_SUCCESS,
    payload: prod,
    savedKey,
    params,
    oldStoreData,
    apiCall,
    lobTypes,
  };
}

export function apiCallWizardAction(url, params, apiCall, savedKey, disablePending) {
  return (dispatch, getState) => {
    const state = getState();
    const lobTypes = state?.locations?.lobCategories;
    const oldStoreData = {};

    if (savedKey === 'accounts' || savedKey === 'accountCalculations') {
      oldStoreData.accounts =
        state?.investments?.initialAccounts &&
        state?.investments?.initialAccounts.length > 0
          ? state?.investments?.initialAccounts
          : null;
    }
    dispatch(apiCallWizardRequest(savedKey, disablePending, apiCall));
    return apiCallWizardApi
      .apiCallWizard(state, url, params, apiCall?.httpMethod, apiCall?.opts)
      .then(data => {
        // Update the app state with the results of the API call.
        dispatch(
          apiCallWizardSuccess(data, savedKey, params, oldStoreData, apiCall, lobTypes),
        );
        return data;
      })
      .catch(error => {
        dispatch(apiCallWizardError(error, savedKey, apiCall));
        throw error;
      });
  };
}

export default {
  apiCallWizardAction,
};
