import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getLocationsAction } from '../actions/officeLocator/getLocations';

const useLocations = () => {
  const dispatch = useDispatch();
  // const allRows = useSelector(state => state.officeLocator.allRows);
  // const location = useSelector(state => state?.locations?.yourLocation?.alpha2);
  return () => {
    // if (!allRows || allRows.length === 0) {
    //     return dispatch(getLocationsAction()).catch(err => {
    //         // console.log('err: ', err);
    //     });
    // }
    return dispatch(getLocationsAction()).catch(err => {});
  };
};
export default useLocations;
