import {
  AGENT_FORGOT_PASSWORD_API_REQUEST,
  AGENT_FORGOT_PASSWORD_API_ERROR,
  AGENT_FORGOT_PASSWORD_API_SUCCESS,
} from '../../../constants/authActionNames';

// import { api } from '../../../utils/variables';
import { forgotPasswordApi } from '../../../apis/auth';

function agentForgotPasswordError(err) {
  return {
    type: AGENT_FORGOT_PASSWORD_API_ERROR,
    payload: err,
  };
}

function agentForgotPasswordRequest() {
  return {
    type: AGENT_FORGOT_PASSWORD_API_REQUEST,
    payload: true,
  };
}

function agentForgotPasswordSuccess(data) {
  return {
    type: AGENT_FORGOT_PASSWORD_API_SUCCESS,
    payload: data,
  };
}

export function agentForgotPasswordAction(_credentials) {
  const credentials = { ..._credentials };
  // credentials.apiKey = api.apiKey;
  // credentials.apiSecret = api.apiSecret;

  return (dispatch, getState) => {
    const state = getState();
    dispatch(agentForgotPasswordRequest());
    return forgotPasswordApi
      .agentForgotPassword(state, credentials)
      .then(result => {
        dispatch(agentForgotPasswordSuccess(result));
        return result;
      })
      .catch(error => {
        dispatch(agentForgotPasswordError(error));
        throw error;
      });
  };
}

export default {
  agentForgotPasswordAction,
};
