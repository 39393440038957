import {
  GET_DIGITAL_SALES_APPLICATION_API_REQUEST,
  GET_DIGITAL_SALES_APPLICATION_API_SUCCESS,
  GET_DIGITAL_SALES_APPLICATION_API_ERROR,
} from '../../../constants/digitalSalesActionNames';

import { getDigitalSalesApplicationApi } from '../../../apis/digitalSales';

function getDigitalSalesApplicationRequest() {
  return {
    type: GET_DIGITAL_SALES_APPLICATION_API_REQUEST,
    payload: true,
  };
}

function getDigitalSalesApplicationSuccess(applications, params) {
  return {
    type: GET_DIGITAL_SALES_APPLICATION_API_SUCCESS,
    payload: applications,
    params,
  };
}

function getDigitalSalesApplicationError(err) {
  return {
    type: GET_DIGITAL_SALES_APPLICATION_API_ERROR,
    payload: err,
  };
}

export function getDigitalSalesApplicationAction(params) {
  // const response = getPayloadResponse();
  return (dispatch, getState) => {
    const state = getState();
    dispatch(getDigitalSalesApplicationRequest());
    return getDigitalSalesApplicationApi
      .getDigitalSalesApplication(state, params)
      .then(data => {
        dispatch(getDigitalSalesApplicationSuccess(data, params));
        return data;
      })
      .catch(error => {
        dispatch(getDigitalSalesApplicationError(error));
        throw error;
      });
  };
}

export default { getDigitalSalesApplicationAction };
