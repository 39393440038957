export default (theme, inverse, isSmall, color) => ({
  button: {
    boxShadow: '1px 2px 8px -2px',
    padding: '0',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    width: isSmall ? '90%' : '200px',
    height: '50px',
    marginBottom: '10px',
  },
  buttonIcon: {
    width: '50px',
    height: '50px',
    backgroundColor: color ? color.light : theme.palette.primary.light,
    borderRadius: '5px 0px 0px 5px',
    color: color ? color.dark : theme.palette.primary.dark,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
