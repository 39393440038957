import { routerPostCall } from '../../axios';
// authPostCall
const forgotPassword = (state, params) => {
  const url = `/auth/forgetPassword/sendVerificationCode`;
  return routerPostCall(state, url, params);
};
const agentForgotPassword = (state, params) => {
  const url = `/auth/webstar/agentResetRequest`;
  return routerPostCall(state, url, params);
};
export default { forgotPassword, agentForgotPassword };
