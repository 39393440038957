import React from 'react';
import { Card, CardContent, CardMedia, Typography, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ButtonComponent } from './ButtonComponent';
import sanitizeText from '../../../utils/functions/sanitizeText';

export function CTACard({
  title,
  imageSrc,
  content,
  cardWidth,
  cardMargin,
  cardBackground,
  learnMoreUrl,
  getQuoteUrl,
}) {
  const theme = useTheme();
  return (
    <Card
      sx={{
        boxSizing: 'border-box',
        width: cardWidth,
        margin: cardMargin,
        background: cardBackground,
        display: 'flex',
        flexDirection: 'column',
        gap: '.63rem',
        padding: theme?.spacing(2),
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '8px',
        justifyContent: 'space-between',
      }}
    >
      <Typography fontWeight={500}>{title}</Typography>
      <CardMedia
        sx={{ width: '100%' }}
        component="img"
        image={imageSrc}
        alt="referral-Image"
      />
      <CardContent sx={{ p: 0 }}>
        <Typography
          color="text.disabled"
          sx={{
            textAlign: 'left',
            padding: `0`,
            fontSize: '1rem',
            paddingTop: `${theme.spacing(1)}`,
            paddingBottom: `${theme.spacing(1)}`,
          }}
        >
          {content && (
            <div dangerouslySetInnerHTML={{ __html: `${sanitizeText(content)}` }} />
          )}
        </Typography>
      </CardContent>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <ButtonComponent
          href={getQuoteUrl}
          content="Get Quote"
          variant="outlined"
          size="small"
          styles={{ textTransform: 'capitalize', fontSize: '0.75rem' }}
        />
        <ButtonComponent
          size="small"
          content="Learn More"
          href={learnMoreUrl}
          styles={{ fontSize: '0.75rem' }}
        />
      </Stack>
    </Card>
  );
}
