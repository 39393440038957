const Cookies = {
  getCookie: name => {
    if (typeof window !== 'undefined') {
      const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
      // console.log("v from cookie", v, name, document);
      return v ? v[2] : null;
    }
    return null;
  },

  setCookie: (name, value, hours, additionalSettings = '') => {
    const d = new Date();
    d.setTime(d.getTime() + 60 * 60 * 1000 * hours);
    document.cookie = `${name}=${value};path=/;expires=${d.toGMTString()}${additionalSettings}`;
  },

  deleteCookie: name => {
    Cookies.setCookie(name, '', -1);
  },
  deleteAllCookies: () => {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  },
};

export default Cookies;
