export const getAllKeys = obj => {
  // // console.log('Object.keys(obj): ', Object.keys(obj));
  // // console.log('stringObj: ', stringObj);
  const keys = keyifyAll(obj);

  return keys;
};

export const findDuplicates = (arr = [], language) => {
  const sorted_arr = arr.slice().sort(); // You can define the comparing function here.
  // JS by default uses a crappy string compare.
  // (we use slice to clone the array so the
  // original array won't be modified)
  const results = [];
  for (let i = 0; i < sorted_arr.length; i++) {
    const str = sorted_arr[i];
    if (str?.endsWith(`_${language}`)) {
      if (results.indexOf(str?.split(`_${language}`)[0]) == -1) {
        results.push(str?.split(`_${language}`)[0]);
      }
    }
  }
  return results;
};

export const languageTranslation = async (obj, language = '') => {
  // console.log('old obj: ', obj);
  const keys = getAllKeys(obj);
  // console.log('keys: ', keys);
  const duplicateKeys = findDuplicates(keys, language);
  // console.log('duplicateKeys: ', duplicateKeys);
  // let lang = language == "en" ? "" : language
  for (const key in duplicateKeys) {
    stringDotNotation(obj, duplicateKeys[key], language);
  }
  // console.log('new obj: ', obj);
  return obj;
};

function removeLinebreaks(str) {
  if (str) {
    str = str.toString();
    str = str.trim();
    return str.replace(/[\r\n]+/gm, '');
  }
  return '';
}

const stringDotNotation = (obj, str = '', language = '') => {
  str = str.split('.');
  for (let i = 0; i < str.length; i++) {
    if (str.length - 1 == i) {
      if (Array.isArray(obj)) {
        for (var j = 0; j < obj.length; j++) {
          if (obj[j]) {
            // console.log('str[i]:1 ', str[i]);
            // console.log('obj[str[i]]:1 ', obj[j][`${str[i]}_${language}`]);
            const trimLine = removeLinebreaks(obj[j][`${str[i]}_${language}`]);
            // console.log('trimLine:1 ', trimLine);
            if (
              obj[j][`${str[i]}_${language}`] !== 'null' &&
              obj[j][`${str[i]}_${language}`] !== 'undefined' &&
              trimLine.length > 0 &&
              trimLine != '<p></p>' &&
              trimLine != '<p><br></p>'
            ) {
              obj[j][str[i]] = obj[j][`${str[i]}_${language}`] || obj[j][str[i]];
            } else {
              obj[j][str[i]] = obj[j][str[i]];
            }
          }
        }
      } else if (obj) {
        // console.log('str[i]: ', str[i]);
        // console.log('obj[str[i]]: ', obj[`${str[i]}_${language}`]);
        const trimLine = removeLinebreaks(obj[`${str[i]}_${language}`]);
        // console.log('trimLine: ', trimLine);
        if (
          obj[`${str[i]}_${language}`] !== 'null' &&
          obj[`${str[i]}_${language}`] !== 'undefined' &&
          trimLine.length > 0 &&
          trimLine != '<p></p>' &&
          trimLine != '<p><br></p>'
        ) {
          obj[str[i]] = obj[`${str[i]}_${language}`] || obj[str[i]];
        } else {
          obj[str[i]] = obj[str[i]];
        }
      }
    }
    if (Array.isArray(obj)) {
      if (str.length - 1 != i) {
        const arr = [];
        for (var j = 0; j < obj.length; j++) {
          if (obj[j] != null) {
            if (Array.isArray(obj[j][str[i]])) {
              arr.push(...obj[j][str[i]]);
            } else {
              arr.push(obj[j][str[i]]);
            }
          }
        }
        obj = arr;
      }
    } else {
      obj = obj[str[i]];
    }
    // obj = obj[str[i]];
  }

  return obj;
};

export const keyifyAll = (obj = {}, prefix = '') => {
  if (!obj || typeof obj !== 'object') return [];
  return Object.keys(obj).reduce((res, el) => {
    const elDisplayName = `${prefix}${el}`;
    if (Array.isArray(obj[el])) {
      const objectsInArray = obj[el].filter(el => typeof el === 'object');
      if (objectsInArray.length > 0) {
        let objectKeys = [];
        objectsInArray.map(object => {
          objectKeys = objectKeys.concat(keyifyAll(object, `${prefix + el}.`));
        });
        return [...res, ...new Set(objectKeys)];
      }
      return [...res, elDisplayName];
    }
    if (typeof obj[el] === 'object' && obj[el] !== null) {
      return [...res, ...keyifyAll(obj[el], `${prefix + el}.`)];
    }
    return [...res, elDisplayName];
  }, []);
};
