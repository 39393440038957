export default (theme, inverse) => ({
  noDecoration: {
    textDecoration: 'none !important',
    display: 'inline-block',
  },

  oneButton: {
    // minHeight: '100% !important',
    display: 'inline-block',
    minHeight: '40px',
  },
  button: {
    // minHeight: '100% !important',
    display: 'inline-block',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    minHeight: '40px',
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    height: '1.5em',
    lineHeight: '1.5em',
    width: '100%',
  },
});
