import React, { useCallback } from 'react';
// import { useSelector } from 'react-redux';
import {
  // Select,
  // FormControl,
  // MenuItem,
  Grid,
  // InputLabel,
  // Checkbox,
  // ListItemText,
  Button,
  // Paper,
  TextField,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Clear as ClearIcon } from '@mui/icons-material';

import classnames from 'classnames';
import {
  dateToString,
  isoStringToDate,
  stringToDate,
  stringToIsoDate,
} from '../../../../utils/functions';
import { insuranceIndexName, clientPoliciesIndexName } from '../../../../utils/variables';
// import { useRouter } from '../../../../hooks';
// import { portfolioInsuranceSelector } from '../../../../selectors';
// import { toTitleCase } from '../../../../utils/functions';
// import routes from '../../../../utils/variables/routes';

import styles from './style';

const useStyles = makeStyles(styles);

const Filter = ({ defaultOptions, onChange, dateTitle, indexName }) => {
  // console.log('defaultOptions: ', defaultOptions);
  // debugger;
  const classes = useStyles();

  const [category, setCategory] = React.useState(defaultOptions.lob);
  const [currency, setCurrency] = React.useState(defaultOptions.currency);
  const [status, setStatus] = React.useState(defaultOptions.status);
  const [startDate, setStartDate] = React.useState(defaultOptions.startDate);
  const [endDate, setEndDate] = React.useState(defaultOptions.endDate);

  const handleChangeCategory = useCallback(_value => event => {
    let value = _value;
    const index = category.indexOf(value);
    // console.log('Value', value);
    if (index > -1) {
      category.splice(index, 1);
      value = [...category];
    } else {
      value = [...category, value];
    }
    setCategory(value);
    onChange({ lob: value, status, startDate, endDate, currency });
  });

  const handleChangeCurrency = useCallback(_value => event => {
    let value = _value;
    const index = currency.indexOf(value);
    // console.log('Value', value);
    if (index > -1) {
      currency.splice(index, 1);
      value = [...currency];
    } else {
      value = [...currency, value];
    }
    setCurrency(value);
    onChange({ lob: category, status, startDate, endDate, currency: value });
  });

  const handleChangeStatus = useCallback(_value => event => {
    let value = _value;
    const index = status.indexOf(value);
    // console.log('Value', value);
    if (index > -1) {
      status.splice(index, 1);
      value = [...status];
    } else {
      value = [...status, value];
    }
    setStatus(value);
    onChange({ lob: category, status: value, startDate, endDate, currency });
  });

  const handleStartDate = value => {
    // console.log('date: ', value);
    const newStringDate = value;
    let newDate = null;
    if (indexName === insuranceIndexName || indexName === clientPoliciesIndexName) {
      newDate = stringToIsoDate(newStringDate);
    } else {
      newDate = stringToDate(newStringDate);
    }
    setStartDate(newDate);
    onChange({ lob: category, status, startDate: newDate, endDate, currency });
  };

  const handleEndDate = value => {
    // console.log('date: ', value);
    const newStringDate = value;
    let newDate = null;
    if (indexName === insuranceIndexName || indexName === clientPoliciesIndexName) {
      newDate = stringToIsoDate(newStringDate);
    } else {
      newDate = stringToDate(newStringDate);
    }
    setEndDate(newDate);
    onChange({ lob: category, status, startDate, endDate: newDate, currency });
  };

  return (
    <>
      <Grid container direction="row" spacing={1}>
        <Grid
          item
          xs={12}
          className={classnames(classes.gridItem, classes.gridItemHeader)}
        >
          {dateTitle}
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <Grid container justifyContent="center" alignItems="center">
            <TextField
              id="start"
              label="Start Date"
              type="date"
              placeholder="YYYY-MM-DD"
              fullWidth
              value={dateToString(startDate)}
              onChange={event => handleStartDate(event.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: false,
                endAdornment: (
                  <IconButton
                    style={{ padding: 5, marginLeft: 5 }}
                    edge="end"
                    size="small"
                    disabled={!startDate}
                    onClick={() => handleStartDate('')}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
              className={classes.datePicker}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <Grid container justifyContent="center" alignItems="center">
            <TextField
              id="end"
              label="End Date"
              placeholder="YYYY-MM-DD"
              type="date"
              value={dateToString(endDate)}
              onChange={event => handleEndDate(event.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: false,
                endAdornment: (
                  <IconButton
                    style={{ padding: 5, marginLeft: 5 }}
                    edge="end"
                    size="small"
                    disabled={!endDate}
                    onClick={() => handleEndDate('')}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
              className={classes.datePicker}
            />
          </Grid>
        </Grid>
      </Grid>
      {defaultOptions.lobTypes && defaultOptions.lobTypes.length > 1 ? (
        <Grid container direction="row" spacing={1}>
          <Grid
            item
            xs={12}
            className={classnames(classes.gridItem, classes.gridItemHeader)}
          >
            Product Type
          </Grid>
          {defaultOptions.lobTypes.map(item => {
            return (
              <Grid key={item.id} item xs={4} className={classes.gridItem}>
                <Button
                  fullWidth
                  className={classes.btn}
                  variant={
                    category && category.indexOf(item.lob) > -1 ? 'contained' : 'outlined'
                  }
                  color="secondary"
                  onClick={handleChangeCategory(item.lob)}
                  disableElevation
                >
                  {item.title}
                </Button>
              </Grid>
            );
          })}
        </Grid>
      ) : null}
      {defaultOptions.currencies && defaultOptions.currencies.length > 0 ? (
        <Grid container direction="row" spacing={1}>
          <Grid
            item
            xs={12}
            className={classnames(classes.gridItem, classes.gridItemHeader)}
          >
            Currency
          </Grid>
          {defaultOptions.currencies.map(item => {
            return (
              <Grid key={item} item xs={4} className={classes.gridItem}>
                <Button
                  fullWidth
                  className={classes.btn}
                  variant={
                    currency && currency.indexOf(item) > -1 ? 'contained' : 'outlined'
                  }
                  color="secondary"
                  onClick={handleChangeCurrency(item)}
                  disableElevation
                >
                  {item}
                </Button>
              </Grid>
            );
          })}
        </Grid>
      ) : null}
      {defaultOptions.statuses && defaultOptions.statuses.length > 0 ? (
        <Grid container direction="row" spacing={1}>
          <Grid
            item
            xs={12}
            className={classnames(classes.gridItem, classes.gridItemHeader)}
          >
            Status
          </Grid>
          {defaultOptions.statuses.map(item => {
            // <Grid  item xs={4} className={classes.gridItem}> </Grid>
            return (
              <Button
                key={item}
                fullWidth
                className={classes.btn}
                variant={status && status.indexOf(item) > -1 ? 'contained' : 'outlined'}
                color="secondary"
                onClick={handleChangeStatus(item)}
                disableElevation
              >
                {item}
              </Button>
            );
          })}
        </Grid>
      ) : null}
    </>
  );
};

export default Filter;
