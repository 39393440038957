export const GET_PROFILE_API_REQUEST = 'GET_PROFILE_API_REQUEST';
export const GET_PROFILE_API_SUCCESS = 'GET_PROFILE_API_SUCCESS';
export const GET_PROFILE_API_ERROR = 'GET_PROFILE_API_ERROR';

export const UPDATE_PROFILE_API_REQUEST = 'UPDATE_PROFILE_API_REQUEST';
export const UPDATE_PROFILE_API_SUCCESS = 'UPDATE_PROFILE_API_SUCCESS';
export const UPDATE_PROFILE_API_ERROR = 'UPDATE_PROFILE_API_ERROR';

export const UPDATE_PROFILE_CRM_API_REQUEST = 'UPDATE_PROFILE_CRM_API_REQUEST';
export const UPDATE_PROFILE_CRM_API_SUCCESS = 'UPDATE_PROFILE_CRM_API_SUCCESS';
export const UPDATE_PROFILE_CRM_API_ERROR = 'UPDATE_PROFILE_CRM_API_ERROR';

export const VALIDATE_PROFILE_API_REQUEST = 'VALIDATE_PROFILE_API_REQUEST';
export const VALIDATE_PROFILE_API_ERROR = 'VALIDATE_PROFILE_API_ERROR';
export const VALIDATE_PROFILE_API_SUCCESS = 'VALIDATE_PROFILE_API_SUCCESS';

export const VERIFY_CONTACT_API_REQUEST = 'VERIFY_CONTACT_API_REQUEST';
export const VERIFY_CONTACT_API_ERROR = 'VERIFY_CONTACT_API_ERROR';
export const VERIFY_CONTACT_API_SUCCESS = 'VERIFY_CONTACT_API_SUCCESS';

export const UPLOAD_PROFILE_PASSPORT_API_REQUEST = 'UPLOAD_PROFILE_PASSPORT_API_REQUEST';
export const UPLOAD_PROFILE_PASSPORT_API_SUCCESS = 'UPLOAD_PROFILE_PASSPORT_API_SUCCESS';
export const UPLOAD_PROFILE_PASSPORT_API_ERROR = 'UPLOAD_PROFILE_PASSPORT_API_ERROR';

export const UPLOAD_PROFILE_DRIVERS_PERMIT_API_REQUEST =
  'UPLOAD_PROFILE_DRIVERS_PERMIT_API_REQUEST';
export const UPLOAD_PROFILE_DRIVERS_PERMIT_API_SUCCESS =
  'UPLOAD_PROFILE_DRIVERS_PERMIT_API_SUCCESS';
export const UPLOAD_PROFILE_DRIVERS_PERMIT_API_ERROR =
  'UPLOAD_PROFILE_DRIVERS_PERMIT_API_ERROR';

export const UPLOAD_PROFILE_ID_CARD_API_REQUEST = 'UPLOAD_PROFILE_ID_CARD_API_REQUEST';
export const UPLOAD_PROFILE_ID_CARD_API_SUCCESS = 'UPLOAD_PROFILE_ID_CARD_API_SUCCESS';
export const UPLOAD_PROFILE_ID_CARD_API_ERROR = 'UPLOAD_PROFILE_ID_CARD_API_ERROR';

export const RESET_VALIDATE_PROFILE_SUCCESS = 'RESET_VALIDATE_PROFILE_SUCCESS';
export const RESET_VERIFY_CONTACT_SUCCESS = 'RESET_VERIFY_CONTACT_SUCCESS';

export const GET_TRUSTED_DEVICES_API_REQUEST = 'GET_TRUSTED_DEVICES_API_REQUEST';
export const GET_TRUSTED_DEVICES_API_SUCCESS = 'GET_TRUSTED_DEVICES_API_SUCCESS';
export const GET_TRUSTED_DEVICES_API_ERROR = 'GET_TRUSTED_DEVICES_API_ERROR';

export const DELETE_TRUSTED_DEVICE_API_REQUEST = 'DELETE_TRUSTED_DEVICE_API_REQUEST';
export const DELETE_TRUSTED_DEVICE_API_SUCCESS = 'DELETE_TRUSTED_DEVICE_API_SUCCESS';
export const DELETE_TRUSTED_DEVICE_API_ERROR = 'DELETE_TRUSTED_DEVICE_API_ERROR';

export const GET_DOCUMENTS_API_REQUEST = 'GET_DOCUMENTS_API_REQUEST';
export const GET_DOCUMENTS_API_SUCCESS = 'GET_DOCUMENTS_API_SUCCESS';
export const GET_DOCUMENTS_API_ERROR = 'GET_DOCUMENTS_API_ERROR';

export const DOWNLOAD_DOCUMENT_API_REQUEST = 'DOWNLOAD_DOCUMENT_API_REQUEST';
export const DOWNLOAD_DOCUMENT_API_SUCCESS = 'DOWNLOAD_DOCUMENT_API_SUCCESS';
export const DOWNLOAD_DOCUMENT_API_ERROR = 'DOWNLOAD_DOCUMENT_API_ERROR';
