export const GET_HEALTH_CARD_API_REQUEST = 'GET_HEALTH_CARD_API_REQUEST';
export const GET_HEALTH_CARD_API_SUCCESS = 'GET_HEALTH_CARD_API_SUCCESS';
export const GET_HEALTH_CARD_API_ERROR = 'GET_HEALTH_CARD_API_ERROR';
export const GET_REMOTE_CONSULTATION_DETAILS_REQUEST =
  'GET_REMOTE_CONSULTATION_DETAILS_REQUEST';
export const GET_REMOTE_CONSULTATION_DETAILS_SUCCESS =
  'GET_REMOTE_CONSULTATION_DETAILS_SUCCESS';
export const GET_REMOTE_CONSULTATION_DETAILS_ERROR =
  'GET_REMOTE_CONSULTATION_DETAILS_ERROR';
export const GET_REQUEST_REMOTE_CONSULTATION_REQUEST =
  'GET_REQUEST_REMOTE_CONSULTATION_REQUEST';
export const GET_REQUEST_REMOTE_CONSULTATION_SUCCESS =
  'GET_REQUEST_REMOTE_CONSULTATION_SUCCESS';
export const GET_REQUEST_REMOTE_CONSULTATION_ERROR =
  'GET_REQUEST_REMOTE_CONSULTATION_ERROR';
export const POST_HEALTH_CARD_EMAIL_REQUEST = 'POST_HEALTH_CARD_EMAIL_REQUEST';
export const POST_HEALTH_CARD_EMAIL_SUCCESS = 'POST_HEALTH_CARD_EMAIL_SUCCESS';
export const POST_HEALTH_CARD_EMAIL_ERROR = 'POST_HEALTH_CARD_EMAIL_ERROR';
export const RESET_HEALTH_CARD_EMAIL_SUCCESS = 'RESET_HEALTH_CARD_EMAIL_SUCCESS';
export const RESET_REMOTE_CONSULTATION_SUCCESS = 'RESET_REMOTE_CONSULTATION_SUCCESS';
export const UPDATE_REMOTE_CONSULTATION_CODE_TIME_LEFT =
  'UPDATE_REMOTE_CONSULTATION_CODE_TIME_LEFT';
