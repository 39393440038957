import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import IssueList from '../../../componentsV2/helpCenter/issueStatus/issueList';
import StatusListHeader from '../../../componentsV2/helpCenter/issueStatus/statusListHeader';
import {
  emptyHelpFormDataAction,
  addHelpFormDataAction,
} from '../../../actions/helpCenter/helpFormData';
import { hideBackButtonState } from '../../../actions/temp';
import ChooseLob from '../chooseLob';
import { Header } from '../../../componentsV2/common';
import { useSmall, useRouter } from '../../../hooks';
import { createRelativeUrl } from '../../../utils/functions';
import { lifeLob, healthLob, pensionLob } from '../../../utils/variables';
import CheckStatusComp from './statusDetailsJM/CheckStatusComp';
import styles from './style';

const useStyles = makeStyles(styles);
let counter = 0;

function checkStatus() {
  const [caseId, setCaseId] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles();
  const [caseYear, setCaseYear] = useState(new Date().getFullYear());
  const router = useRouter();
  const { query } = router;
  const { step: initialStep = 0, lob: initialLob } = query;
  const step = parseInt(initialStep, 10);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const isSmall = useSmall();
  const lobCategories = useSelector(state => state.locations.lobCategories);
  const productCategories = lobCategories.filter(x => x?.isAvailableOnCheckMyStatus);

  const currentLocation = useSelector(state => state.locations.yourLocation.alpha2);
  const handleCaseId = issueId => {
    setCaseId(issueId);
  };

  const handleCaseYear = year => {
    setCaseYear(year);
  };

  const handleNextStep = replace => {
    const relativeUrl = createRelativeUrl({
      path: router.pathname,
      queryObject: { ...query, step: step + 1 },
    });
    if (replace) router.replace(relativeUrl);
    else router.push(relativeUrl);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (step === 0) {
      dispatch(emptyHelpFormDataAction());
      if (initialLob && counter === 0) {
        dispatch(addHelpFormDataAction({ lob: initialLob }));
        handleNextStep(true);
      }
    }
    counter++;
  }, []);

  return (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      spacing={1}
      sx={{ width: '100%', px: 2 }}
    >
      {currentLocation !== 'JM' ? (
        <Grid item xs={12}>
          <Header
            title="Status Details"
            subTitle="Browse and review all of your support history"
          />
        </Grid>
      ) : (
        <></>
      )}

      {step === 0 && (
        <Grid item xs={12} sx={{ width: isSmall ? '100%' : '80%' }}>
          <ChooseLob
            nextStep={handleNextStep}
            step={step}
            content="Please choose a product type to view your support history."
            lobCategories={productCategories}
          />
        </Grid>
      )}
      {step > 0 && (
        <Grid item xs={12} sx={{ width: '100%' }}>
          <StatusListHeader issueCaseId={handleCaseId} caseYear={handleCaseYear} />
          {isAuthenticated || caseId != '' ? (
            <>
              <IssueList CaseId={caseId} CaseYear={caseYear} />
            </>
          ) : (
            <></>
          )}
        </Grid>
      )}
    </Grid>
  );
}

export const cardStatus = {
  IN: ['Info Needed', 'Waiting for Details'],
  IP: ['In Progress', 'Researching', 'Draft'],
  Delayed: ['Delayed', 'On Hold'],
  Resolved: ['Resolved', 'Problem Solved', 'Information Provided'],
  Cancelled: ['Merged', 'Cancelled'],
};

export default checkStatus;
