import {
    CHAT_BOT_INTERACTION
} from '../../constants/chatbotActionNames';

export const initialState = {
    chatbotState: 0
};

const chatbotReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHAT_BOT_INTERACTION: {
            return {
                ...state,
                chatbotState: state.chatbotState + 1
            }
        }
        default:
            return state;
    }

}

export default chatbotReducer;