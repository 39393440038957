import { ADD_TRUSTED_DEVICE_API_SUCCESS } from '../../../constants/diskActionNames';

function addTrustedDeviceSuccess(prod) {
  return {
    type: ADD_TRUSTED_DEVICE_API_SUCCESS,
    payload: prod,
  };
}

export function addTrustedDeviceAction(data) {
  return addTrustedDeviceSuccess(data);
}

export default {
  addTrustedDeviceAction,
};
