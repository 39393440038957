import {
  GET_INDIVIDUAL_INSURANCE_DETAILS_REQUEST,
  GET_INDIVIDUAL_INSURANCE_DETAILS_SUCCESS,
  GET_INDIVIDUAL_INSURANCE_DETAILS_ERROR,
} from '../../../constants/claimActionNames';
import { getInsurancesApi } from '../../../apis/portfolio';

function getIndividualInsuranceDetailsRequest() {
  return {
    type: GET_INDIVIDUAL_INSURANCE_DETAILS_REQUEST,
    payload: true,
  };
}

function getIndividualInsuranceDetailsSuccess(userDetails) {
  return {
    type: GET_INDIVIDUAL_INSURANCE_DETAILS_SUCCESS,
    payload: userDetails,
  };
}

function getIndividualInsuranceDetailsError(err) {
  return {
    type: GET_INDIVIDUAL_INSURANCE_DETAILS_ERROR,
    payload: err,
  };
}

export function getIndividualInsuranceDetailsAction(params) {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(getIndividualInsuranceDetailsRequest());
    try {
      const res = await getInsurancesApi.getSingleInsurance(state, params);
      dispatch(getIndividualInsuranceDetailsSuccess(res));
      return res;
    } catch (e) {
      dispatch(getIndividualInsuranceDetailsError(e));
      throw e;
    }
  };
}

export default { getIndividualInsuranceDetailsAction };
