import { routerGetCall } from '../../axios';

const downloadPolicyDocument = (state, params) => {
  const url = `/accounts/policies/${params.policyId}/documents/${params.fileId}/download`;
  const opts = {
    config: { responseType: 'blob' },
  };
  return routerGetCall(state, url, {}, opts);
};
export default { downloadPolicyDocument };
