import * as Yup from 'yup';
import moment from 'moment';

import {
  FIRST_NAME_REGEX,
  LAST_NAME_REGEX,
  TRN_NUM_REGEX,
  POLICY_NUM_REGEX,
  AGENT_FIRST_NAME_REGEX,
  AGENT_LAST_NAME_REGEX,
  MOBILE_NUM_REGEX,
  EMAIL_REGEX,
  CASEID_REGEX,
} from '../../../../../constants/helpCenter/regex';

import {
  FIRST_NAME_ERROR_MSG,
  LAST_NAME_ERROR_MSG,
  FULL_NAME_ERROR_MSG,
  TRN_NUM_ERROR_MSG,
  POLICY_NUM_ERROR_MSG,
  DOB_ERROR_MSG,
  AGENT_FIRST_NAME_ERROR_MSG,
  AGENT_LAST_NAME_ERROR_MSG,
  MOBILE_NUM_ERROR_MSG,
  EMAIL_ERROR_MSG,
  CONFIRM_EMAIL_ERROR_MSG,
  REQUIRED_MSG,
  INVALID_DATE_MSG,
  CASEID_ERROR_MSG,
} from '../../../../../constants/helpCenter/validationMessages';

export const ValidateSchema = (values, showSummary) =>
  Yup.object().shape({
    firstName: Yup.string()
      .matches(FIRST_NAME_REGEX, FIRST_NAME_ERROR_MSG)
      .required(REQUIRED_MSG),
    lastName: Yup.string()
      .matches(LAST_NAME_REGEX, LAST_NAME_ERROR_MSG)
      .required(REQUIRED_MSG),
    date: Yup.date()
      .test('date', DOB_ERROR_MSG, () => {
        return !moment(values.date).isAfter(moment().subtract(18, 'years'));
      })
      .required(REQUIRED_MSG)
      .typeError(INVALID_DATE_MSG),
    TRNNumber: Yup.string().matches(TRN_NUM_REGEX, TRN_NUM_ERROR_MSG),

    policyNumber: Yup.string().matches(POLICY_NUM_REGEX, POLICY_NUM_ERROR_MSG),

    agentFirstName:
      values.guardianAgentCheck &&
      Yup.string()
        .matches(AGENT_FIRST_NAME_REGEX, AGENT_FIRST_NAME_ERROR_MSG)
        .required(REQUIRED_MSG),
    agentLastName:
      values.guardianAgentCheck &&
      Yup.string()
        .matches(AGENT_LAST_NAME_REGEX, AGENT_LAST_NAME_ERROR_MSG)
        .required(REQUIRED_MSG),
    agentBranch: values.guardianAgentCheck && Yup.string().required(REQUIRED_MSG),
    mobileNumber:
      showSummary &&
      Yup.string()
        .matches(MOBILE_NUM_REGEX, MOBILE_NUM_ERROR_MSG)
        .required(REQUIRED_MSG),
    email:
      showSummary &&
      Yup.string()
        .matches(EMAIL_REGEX, EMAIL_ERROR_MSG)
        .required(REQUIRED_MSG),
    confirmEmail:
      showSummary &&
      Yup.string()
        .test('email', CONFIRM_EMAIL_ERROR_MSG, () => {
          return values.email === values.confirmEmail;
        })
        .required(REQUIRED_MSG),
    notified: showSummary && Yup.string().required(REQUIRED_MSG),
    helpName: showSummary && Yup.array().required(REQUIRED_MSG),
    issueName:
      showSummary && values.issueType.length > 0 && Yup.array().required(REQUIRED_MSG),
    qnrDetails: showSummary && values.qnr && Yup.string().required(REQUIRED_MSG),
  });

export const ValidateCheckStatus = values =>
  Yup.object().shape({
    caseID: Yup.string()
      .matches(CASEID_REGEX, CASEID_ERROR_MSG)
      .required(REQUIRED_MSG),
    fullName: Yup.string()
      .matches(LAST_NAME_REGEX, FULL_NAME_ERROR_MSG)
      .required(REQUIRED_MSG),
  });
