import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAxios } from 'use-axios-hooks';
import { Redirect } from 'react-router-dom';
import { Grid, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import WizardBuilder from '../../../componentsV2/wizards/wizardBuilder';
import { getModalWizard, addWizard } from '../../../actions/wizards';
import { formatWizardData } from '../../../componentsV2/wizards/functions/formatWizard';
import { ErrorAlert, Spinner } from '../../../componentsV2/common'; // Spinner
import routes from '../../../utils/variables/routes';
import styles from './style';
import { defaultAlpha2 } from '../../../utils/variables';
import { useRouter } from '../../../hooks';
import ProdWizards, {
  wizardsToCache as DevWizards,
} from '../../../utils/variables/cacheWizards';

let counter = 0;

const useStyles = makeStyles(styles);

const Wizard = ({
  slug: initialSlug,
  isModal = false,
  disablePadding = false,
  clearOnWizardLoad = false,
}) => {
  const { match, location } = useRouter() || {};
  const classes = useStyles();
  const dispatch = useDispatch();
  const slug = initialSlug || match?.params?.slug;
  const lastCacheWizard = useSelector(state => state.wizards?.modalWizard);
  const isGettingWizardPending = useSelector(
    state => state.wizards.isGettingModalWizardPending,
  );
  const isGettingWizardSuccess = useSelector(
    state => state.wizards.isGettingModalWizardSuccess,
  );
  const isGettingWizardError = useSelector(
    state => state.wizards.isGettingModalWizardError,
  );
  const yourLocation = useSelector(state => state.locations.yourLocation);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const territoryId = yourLocation?.id;
  const countryCode = yourLocation?.alpha2 || defaultAlpha2;
  const countryWizardSlug = `${countryCode?.toLowerCase()}_${slug}`;
  const useLocalCache = true;
  const localWizardUrl =
    process.env.NODE_ENV === 'development' && !useLocalCache
      ? DevWizards && (DevWizards[countryWizardSlug] || DevWizards[slug])
      : ProdWizards && (ProdWizards[countryWizardSlug] || ProdWizards[slug]);

  const [{ data, isLoading, error }] = useAxios(localWizardUrl);
  const localWizards = data?.data && data?.data.length > 0 ? data?.data : [];
  const filteredLocalWizards = localWizards?.filter(y =>
    y?.territories.some(
      territory => territory?.id === territoryId || territory === territoryId,
    ),
  );

  const localWizard =
    filteredLocalWizards && filteredLocalWizards.length > 0
      ? filteredLocalWizards[0]
      : null;

  const lastCacheWizardMatchTerritory = lastCacheWizard?.territories.some(
    territory => territory?.id === territoryId || territory === territoryId,
  );

  const currentCacheWizard =
    slug === lastCacheWizard?.slug && lastCacheWizardMatchTerritory
      ? lastCacheWizard
      : null; // && slug === lastCacheWizard?.slug, lastCacheWizard ||
  const wizard = localWizard || currentCacheWizard;
  const isAuthRequired = wizard?.isAuthRequired || false;

  const isError = !!error;

  useEffect(() => {
    if (
      isError &&
      !useLocalCache &&
      (counter === 0 || (wizard?.slug !== slug && counter > 0))
    ) {
      const params = {
        alpha2: countryCode,
        status: 'published',
        hidden: false,
        slug,
      };
      dispatch(getModalWizard.getModalWizardAction(params));
      counter = 1;
    } else if (
      !isLoading &&
      localWizard &&
      (counter === 0 || (currentCacheWizard?.slug !== localWizard.slug && counter > 0))
    ) {
      dispatch(addWizard.addLocalModalWizardAction(localWizard));
      counter = 1;
    }
  }, [isError, isLoading, slug]); // isError, isLoading

  //  <Loader hideLocation text="Loading Wizard" />
  if (isGettingWizardPending || isLoading) {
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12}>
          <div className={classes.alignCenter}>
            <Spinner
              loading={isGettingWizardPending || isLoading}
              message="Loading Online Form"
            />
          </div>
        </Grid>
      </Grid>
    );
  }

  if (!wizard && !isGettingWizardPending && !error && !isLoading) {
    // || (!localWizard && error)
    return (
      <ErrorAlert
        isError={false} // || error
        isSuccess
        isPending={false}
        infoTitle="No Online Form Available"
        errorTitle="Error retrieving Online Form"
        infoMessage="Sorry, We don't have this Online Form available."
        errorMessage="Error retrieving this Online Form. Please try again."
      />
    );
  }

  if (!wizard && !isGettingWizardPending) {
    // || (!localWizard && error)
    return (
      <ErrorAlert
        isError={isGettingWizardError} // || error
        isSuccess={isGettingWizardSuccess}
        isPending={isGettingWizardPending}
        infoTitle="No Online Form Available"
        errorTitle="Error retrieving Online Form"
        infoMessage="Sorry, We don't have this Online Form available."
        errorMessage="Error retrieving this Online Form. Please try again."
      />
    );
  }

  const finalWizard = formatWizardData(wizard);

  return (
    <Container className={classes.root} maxWidth={false} disableGutters>
      <WizardBuilder
        {...finalWizard}
        isModal={isModal}
        disablePadding={disablePadding}
        clearOnWizardLoad={clearOnWizardLoad}
      />
    </Container>
  );
};

export default Wizard;

/*
<Spinner loading={isGettingWizardPending} />
*/
