import {
  gettingStarted,
  disputeResolution,
  beneficiary,
} from '../../../utils/variables/images';

export const InProgressCardContent = [
  {
    number: '01',
    title: 'Help request routed to appropriate group',
    content:
      'You will receive an email with details of your request and your request will be forwarded to the best team to help.',
  },
  {
    number: '02',
    title: 'Request information analyzed',
    content:
      'Our team of will investigate ticket details and underlying causes to help resolve your request.',
  },
  {
    number: '03',
    title: 'Solutions offered and issue closed',
    content: 'We will share the conclusion and offer suggestions to resolve the request.',
  },
];

export const policyType = [
  'Policy 1',
  'Policy 2',
  'Policy 3',
  'Policy 4',
  'Policy 5',
  'Policy 6',
];

export const helpArticleCategory = [
  {
    issue: 'Payment related issues',
    category: 'Payment Related',
  },

  {
    issue: 'Issue changing policy details',
    category: 'Policy Information',
  },
  {
    issue: 'Issue processing a withdrawal',
    category: 'Issue Processing a Withdrawal',
  },
  {
    issue: 'Issue changing beneficiary',
    category: 'Issue with Changing Beneficiary',
  },
  {
    issue: 'Policy information',
    category: 'Policy Information',
  },
  {
    issue: 'Issue with claim',
    category: 'Issue with Claim',
  },
  {
    issue: 'Issue with surrenders',
    category: 'Issue with Surrenders',
  },
  {
    issue: 'Issue changing agents',
    category: 'Issue Changing Agents',
  },
];

export const statusMessage = [
  {
    requestStatus: 'Resolved',
    Message: `Your request has been resolved. 
              Please let us know your satisfaction with how your matter has been addressed by completing the survey sent via email.`,
  },
  {
    requestStatus: 'Info Needed',
    Message: `Thank you for contacting customer support. In order for us to help resolve the request, please upload the following additional files directly into this message.`,
  },
  {
    requestStatus: 'In Progress',
    Message: `Your request is in process. No other action items are needed at this time.`,
  },
  {
    requestStatus: 'Delayed',
    Message: `Thank you for contacting customer support. Your request has been placed on hold as we require additional time to resolve the same. Please contact us at 226-myGG(6944), or guardianlife@myguardiangroup.com referencing the ID number above.
    `,
  },
  {
    requestStatus: 'Cancelled',
    Message: `Thank you for contacting customer support.  Your request has been cancelled.`,
  },
];

export const CTACardContent = {
  card1Content: {
    learnMoreUrl: '/products/life',
    getQuoteUrl: '/wizards/life-quote',
    content:
      '<b> Life Insurance</b> <br />A life insurance policy is a critical part of prudent financial planning. In the event of your death, life insurance provides a safety net for your family.',
  },
  card2Content: {
    learnMoreUrl: '/products/health',
    getQuoteUrl: '/wizards/basic-health-quote',
    content:
      "<b>Health</b><br/>We designed the LifeCare and the Rejuvenator Living Assurance plans to ensure that at times when you're sick you're able to focus on your recovery.",
  },
  card3Content: {
    learnMoreUrl: '/products/pension',
    getQuoteUrl: '/wizards/basic-pension-quote',
    content:
      '<b>Pension Insurance</b><br/>Guardian Life annuities and pension plans allow you to live the lifestyle that you have become accustomed to without sacrifices that will affect your current quality of life.',
  },
};

export const InProgressCardTitle =
  ' Your ticket has been submitted and is now In Progress. Help is on the way!';

export const yourCaseIdNumberIs = 'Your Case ID number is';

export const topicCardContent = {
  topicCard1: {
    title: 'Life Insurance',
    content: 'A life insurance policy is a critical part of prudent financial planning. ',
  },
  topicCard2: {
    title: 'Changing a Beneficiary',
    content: 'How does it work, what will we need from you, and what to expect',
  },
  topicCard3: {
    title: 'Disputing a claim',
    content: 'How to read your claim document and how to open a dispute',
  },
};

export const searchCardContent = {
  title: 'Hello, how can we help you today?',
  placeholder: 'Search help articles',
};

export const requestsCardContent = {
  submitRequest: {
    title: 'Submit Help Request',
    description: 'Open a ticket and our teams will investigate your request',
    buttonVariant: 'contained',
    buttonTitle: 'Submit Request',
  },
  checkMyStatus: {
    title: 'Check my Status',
    description: 'View details on an open ticket',
    buttonVariant: 'contained',
    buttonTitle: 'Check Status',
  },
};

export const contactCardContent = {
  call: {
    title: 'Give us a call',
    description: 'Available from 8:00AM to 4:00PM AST',
    buttonVariant: 'text',
    buttonTitle: '1-888-367-5433',
  },
  chat: {
    title: 'Chat',
    description: 'Available 24/7',
    buttonVariant: 'outlined',
    buttonTitle: 'Launch Chat',
  },
};

export const urlMappings = [
  {
    name: 'Submit Help Request',
    url: '/help/request-help?step=3',
  },
  {
    name: 'Check my Status',
    url: '/help/status?step=1',
  },
];

export const images = [
  {
    name: 'Life Insurance',
    imageUrl:
      gettingStarted ||
      'https://plus.unsplash.com/premium_photo-1661405426747-62e00b115240?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8ODV8fGxpZmUlMjBpbnN1cmFuY2V8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
  },
  {
    name: 'Changing a Beneficiary',
    imageUrl:
      beneficiary ||
      'https://plus.unsplash.com/premium_photo-1661481815917-cde0b572a639?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NTV8fGxpZmUlMjBpbnN1cmFuY2V8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
  },
  {
    name: 'Disputing a claim',
    imageUrl:
      disputeResolution ||
      'https://plus.unsplash.com/premium_photo-1661768283319-544b60277ecd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDl8fGxpZmUlMjBpbnN1cmFuY2V8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
  },
];

export const submitHelpRequestContent = {
  header: 'Submit Help Request',
  title:
    'Making our products easy to use and getting our customers the help they need is our top priority.',
  description:
    'Do you have an issue related to your policy? Our team is ready to assist. Use the form below to provide details and we will investigate to get the answers you need.',
};

export const stepperSteps = ['Submit Request', 'In Progress', 'Issue Resolved'];

export const firstNameData = {
  label: 'First Name',
  name: 'firstName',
  placeholder: 'Enter First Name',
};

export const lastNameData = {
  label: 'Last Name',
  name: 'lastName',
  placeholder: 'Enter Last Name',
};

export const dobData = {
  label: 'Date of Birth',
  name: 'date',
};

export const trnNumberData = {
  label: 'TRN Number',
  name: 'TRNNumber',
  placeholder: 'Enter TRN Number',
};

export const policyNumberData = {
  label: 'Policy#',
  placeholder: 'Enter Policy Number',
  name: 'policyNumber',
  footNote:
    'Policy number should be at least 4 numeric digits or 8 alpha-numeric characters',
};

export const MultiDropdownData = {
  label: 'What kind of request do you need help with? *',
  labelMessages: [
    'Select Issue Category',
    'Select Issue Type',
    'Select Issue That is Closely Related',
  ],
};

export const helpCategoryData = {
  message: 'Select Issue Category',
};
export const helpCategory = {
  message: 'Select Issue Category',
};

export const issueCategory = {
  message: 'Select Issue Type',
};
export const qnrCategory = {
  message: 'Select Issue that is closely related',
};

export const bankData = {
  label: 'Please enter your bank name',
  name: 'bankName',
  placeholder: 'Enter Your bank name',
};

export const policiesData = {
  name: 'policiesImpacted',
  label: 'Select all the policies that are impacted',
  category: [
    { name: 'policies', type: 'string' },
    { name: 'policy 1', type: 'string' },
    { name: 'policy 2', type: 'string' },
    { name: 'policy 3', type: 'string' },
    { name: 'Other', type: 'string' },
  ],
  message: 'Select Policies',
};

export const payorData = {
  label: 'Does this issue involve a Payor? (Beneficiary) ',
  name: 'payorName',
  placeholder: 'Payor Name (if applicable)',
};

export const payorRelationData = {
  name: 'relationWithPayor',
  category: [
    { name: 'Father', type: 'string' },
    { name: 'Mother', type: 'string' },
    { name: 'Husband', type: 'string' },
    { name: 'Wife', type: 'string' },
    { name: 'Other', type: 'string' },
  ],
  message: 'Select Relationship to Payor',
};

export const assistanceData = {
  labelName: 'Please describe the assistance you need',
  placeholder: 'Describe assistance here',
  name: 'assistance',
};

export const guardianAgentCheckData = {
  labelName: 'Do you work with a Specific Guardian Agent?',
  placeholder: 'Yes',
  name: 'guardianAgentCheck',
};

export const agentFirstNameData = {
  label: "Agent's First Name",
  name: 'agentFirstName',
  placeholder: "Agent's First Name",
};

export const agentLastNameData = {
  label: "Agent's Last Name",
  name: 'agentLastName',
  placeholder: "Agent's Last Name",
};

export const agentBranchData = {
  name: 'agentBranch',
  label: 'Agent Branch',
  category: [{ name: 'Customer Service', type: 'string' }],
  message: 'Select Agent Branch',
};

export const emailData = {
  label: 'Email Address',
  name: 'email',
  placeholder: 'user@domain.com',
};

export const confirmEmailData = {
  label: 'Confirm Email Address',
  name: 'confirmEmail',
  placeholder: 'user@domain.com',
};

export const mobileData = {
  label: 'Mobile Number',
  name: 'mobileNumber',
  placeholder: '1 555 123 4567',
};

export const notifiedData = {
  name: 'notified',
  label: 'How would you like to be notified?',
  category: [
    { name: 'Email', type: 'string' },
    { name: 'Call', type: 'string' },
  ],
  message: 'Select Notification Type',
};

export const consentData = {
  consent1: {
    placeholder: 'Consent copy for updating information on file',
    name: 'consent1',
  },
  consent2: {
    placeholder: 'Consent lorem ipsum doret mar ',
    name: 'consent2',
  },
};

export const caseIDData = {
  label: 'Enter Case ID',
  name: 'caseID',
  placeholder: 'ABC-12345-A1B2C3',
};

export const lastNameInputData = {
  label: 'Enter Last Name',
  name: 'lastName',
  placeholder: 'Enter your last name',
};

export const fullNameInputData = {
  label: 'Enter Full Name',
  name: 'fullName',
  placeholder: 'Enter your full name',
};

export const acceptableExtensions = [
  'heic',
  'heif',
  'pdf',
  'png',
  'gif',
  'jpeg',
  'txt',
  'csv',
  'jpg',
  'docx',
  'JPG',
  'JPEG',
  'PNG',
  'HEIC',
  'hevc',
  'HEVC',
];
