import { fade } from '@mui/material';

const styles = theme => ({
  search: {
    border: `${theme.shape.borderWidth}px solid ${theme.palette.background.grid}`,
    // backgroundColor: theme.palette.background.grid,
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.only('sm')]: {
      height: theme.spacing(4),
    },
    height: '20px',
    padding: theme.spacing(1),
  },
  searchBox: {
    width: '100%',
  },
  searchIcon: {
    [theme.breakpoints.down('sm')]: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      // boxShadow: '0px 3px 6px #00000029',
      // background: `${theme.palette.background.default} 0% 0% no-repeat padding-box`,
      // borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      // boxShadow: '0px 3px 6px #00000029',
      // background: `${theme.palette.background.default} 0% 0% no-repeat padding-box`,
      // borderRadius: theme.spacing(0.5),
      padding: theme.spacing(0.5),
      '& .MuiSvgIcon-root': {
        fontSize: '2.5rem',
      },
    },
    [theme.breakpoints.up('md')]: {
      padding: '0px',
    },
  },
});

export default styles;
