import React from 'react';
import { Grid, InputLabel, Stack, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export function TextArea({
  labelName,
  placeholder,
  name,
  handleChange,
  value,
  customStyles,
  required,
  error,
  bgColor,
  width,
  borderRadius,
  background,
  helperText,
  footNote,
  textLimit,
  ...props
}) {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        width: {
          xs: '100%',
          sm: '100%',
          md: width || '100%',
        },
        '&.MuiFormControl-root': {
          fontFamily: 'Roboto',
        },
      }}
      {...props}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="nowrap"
        sx={{ width: '100%' }}
      >
        <Grid item sx={{ width: '100%' }}>
          <InputLabel
            shrink
            htmlFor="labelName"
            sx={{
              color: 'text.label',
              fontFamily: 'default',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '1.25rem',
              lineHeight: '2rem',
            }}
          >
            {labelName} {required && ' *'}
          </InputLabel>
        </Grid>
        <Grid item>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{
              fontWeight: '400',
              fontSize: '0.75rem',
              lineHeight: '1rem',
              color: 'text.disabled',
            }}
          >
            {value?.length || 0}/{textLimit}
          </Typography>
        </Grid>
      </Grid>
      <TextField
        error={error}
        helperText={helperText || footNote || ''}
        fullWidth
        name={name}
        multiline
        onChange={handleChange}
        aria-label="empty textarea"
        placeholder={placeholder}
        rows={4}
        sx={{
          '& .MuiOutlinedInput-root': {
            padding: 0,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.background.contentBox}`,
            borderRadius: borderRadius || '8px',
          },
          '& .MuiInputBase-input ': {
            background: background || `${theme.palette.background.main}`,
            borderRadius: borderRadius || '8px',
            padding: '0.75rem 1.25rem 0.75rem 1.25rem',
          },
        }}
        value={value}
        inputProps={{
          maxLength: textLimit,
        }}
      />
    </Stack>
  );
}
