import { gql } from 'graphql-request';

const ALERT_MESSAGE_FIELDS = gql`
  fragment alertMessage on ComponentWizardComponentsAlertMessage {
    AlertMessage_slug: slug
    AlertMessage_title: title
    AlertMessage_description: description
    AlertMessage_alert: alert
    AlertMessage_layoutPosition: layoutPosition
    AlertMessage_positionNo: positionNo
    AlertMessage_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    AlertMessage_name: name
    AlertMessage_variant: variant
    AlertMessage_descriptionVariant: descriptionVariant
    AlertMessage_secondDescriptionVariant: secondDescriptionVariant
    AlertMessage_descriptionAlign: descriptionAlign
    AlertMessage_secondDescriptionAlign: secondDescriptionAlign
    AlertMessage_secondDescription: secondDescription
    AlertMessage_actionSize: actionSize
    AlertMessage_actionVariant: actionVariant
    AlertMessage_fullWidth: fullWidth
    AlertMessage_redirectUrl: redirectUrl
    AlertMessage_actionTitle: actionTitle
    AlertMessage_actionColor: actionColor
    AlertMessage_isConditionalComponent: isConditionalComponent
    AlertMessage_conditionalValue: conditionalValue
    AlertMessage_conditionalKey: conditionalKey
    AlertMessage_conditionalOperator: conditionalOperator
    AlertMessage_conditionalFieldType: conditionalFieldType
    AlertMessage_conditionalAction: conditionalAction
    AlertMessage_conditionalArrayKey: conditionalArrayKey
    AlertMessage_disable: disable
    AlertMessage_show: show
    AlertMessage_title_en: title_en
    AlertMessage_title_nl: title_nl
    AlertMessage_description_en: description_en
    AlertMessage_description_nl: description_nl
    AlertMessage_secondDescription_en: secondDescription_en
    AlertMessage_secondDescription_nl: secondDescription_nl
    AlertMessage_redirectType: redirectType
    AlertMessage_resetWizardData: resetWizardData
    AlertMessage_redirectAuthType: redirectAuthType
    AlertMessage_redirectAuthUrl: redirectAuthUrl
    AlertMessage_conditions: conditions {
      ...condition
    }
    AlertMessage_payloadKey: payloadKey
    AlertMessage_isManualLastEntry: isManualLastEntry
    AlertMessage_checkbox: checkbox
    AlertMessage_checkboxValue: checkboxValue
  }
`;

// export default ALERT_MESSAGE_FIELDS;
// exports.default = ALERT_MESSAGE_FIELDS;
export default ALERT_MESSAGE_FIELDS;
