const styles = theme => ({
  title: {
    fontWeight: 'bold',
    color: theme.palette.text.label,
  },
  card: ({ backgroundColor, color, padding, ...restProps }) => ({
    padding: padding || theme.spacing(2),
    height: '100%',
    color: color || `${theme.palette.text.primary} !important`,
    backgroundColor: backgroundColor || `${theme.palette.background.paper} !important`,
    ...restProps,
  }),
});

export default styles;
