import React from 'react';
import { CardMedia } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from './style';

const useStyles = makeStyles(styles);

const VideoPlayer = ({ url, title }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CardMedia
        component="video"
        image={url}
        title={title}
        controls
        // allow="accelerometer, autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        muted
        type="video/mp4"
      />
    </div>
  );
};

export default VideoPlayer;

/*
     <video id="video" playsinline autoPlay muted loop>
          <source src={url} type="video/mp4" />
        </video>
 <CardMedia
          component="iframe"
          className={classes.mediaPlayer}
          image={url}
          title={title}
          allow="accelerometer, autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
<CardMedia
    component='iframe'
    title='test'
    src='https://www.youtube.com/embed/VIDEO_ID'
    />
    */
