import React from 'react';
import classnames from 'classnames';
import { Typography, Popover, Icon, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from './style';

const useStyles = makeStyles(styles);
const FilterButton = ({ className, content }) => {
  // toggleFilter,
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Button
        className={classnames(className, classes.filter, classes.marginVertical)}
        // onClick={toggleFilter}
        onClick={handleClick}
        type="button"
      >
        <Icon color={open ? 'primary' : 'inherit'} data-nosnippet="data-nosnippet">
          filter_alt
        </Icon>
        <Typography
          color={open ? 'primary' : 'inherit'}
          display="inline"
          className={classnames(classes.filterFont)}
        >
          Filters
        </Typography>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {content}
      </Popover>
    </>
  );
};

export default FilterButton;
