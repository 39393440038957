const styles = theme => ({
  scrollbar: {
    [theme.breakpoints.down('md')]: {
      overflow: 'auto !important',
    },
    [theme.breakpoints.up('md')]: {
      overflow: 'hidden !important',
    },
    '&:hover': {
      overflow: 'overlay !important',
      '@supports ( -moz-appearance:none )': {
        overflow: 'scroll !important',
        'scrollbar-color': `${theme.palette.background.grid} ${theme.palette.background.paper} !important`,
        'scrollbar-width': 'thin !important',
      },
    },

    // zIndex: 100,
    margin: 0,
    padding: 0,
    listStyle: 'none',
    // height: '100%',
    '&::-webkit-scrollbar': {
      width: '0.8em',
    },
    '@supports ( -moz-appearance:none )': {
      '&::-webkit-scrollbar': {
        width: '0.8em',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '&::-webkit-scrollbar': {
        width: '0.3em',
      },
      '@supports ( -moz-appearance:none )': {
        '&::-webkit-scrollbar': {
          width: '0.3em',
        },
      },
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      backgroundColor: theme.palette.background.paper,
      borderRadius: '12px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.background.grid,
      outline: `1px solid ${theme.palette.background.grid}`,
      borderRadius: '12px',
      /* border-radius: 6px;
      border: 3px solid var(--scrollbarBG); */
    },

    '&::-webkit-scrollbar-corner': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  scrollbarX: {
    [theme.breakpoints.down('md')]: {
      overflowX: 'auto !important',
    },
    [theme.breakpoints.up('md')]: {
      overflowX: 'hidden !important',
    },
    '&:hover': {
      overflowX: 'overlay !important',
      '@supports ( -moz-appearance:none )': {
        overflowX: 'scroll !important',
        'scrollbar-color': `${theme.palette.background.grid} ${theme.palette.background.paper} !important`,
        'scrollbar-width': 'thin !important',
      },
    },
    overflowY: 'hidden !important',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    // height: '100%',
    '&::-webkit-scrollbar': {
      width: '0.8em',
    },
    '@supports ( -moz-appearance:none )': {
      '&::-webkit-scrollbar': {
        width: '0.8em',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '&::-webkit-scrollbar': {
        width: '0.3em',
      },
      '@supports ( -moz-appearance:none )': {
        '&::-webkit-scrollbar': {
          width: '0.3em',
        },
      },
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      backgroundColor: theme.palette.background.paper,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.background.grid,
      outline: `1px solid ${theme.palette.background.grid}`,
      /* border-radius: 6px;
      border: 3px solid var(--scrollbarBG); */
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  scrollbarY: {
    [theme.breakpoints.down('md')]: {
      overflowY: 'auto !important',
    },
    [theme.breakpoints.up('md')]: {
      overflowY: 'hidden !important',
    },
    '&:hover': {
      overflowY: 'overlay !important',
      '@supports ( -moz-appearance:none )': {
        overflowY: 'scroll !important',
        'scrollbar-color': `${theme.palette.background.grid} ${theme.palette.background.paper} !important`,
        'scrollbar-width': 'thin !important',
      },
    },
    overflowX: 'hidden !important',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    // height: '100%',
    '&::-webkit-scrollbar': {
      width: '0.8em',
    },
    '@supports ( -moz-appearance:none )': {
      '&::-webkit-scrollbar': {
        width: '0.8em',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '&::-webkit-scrollbar': {
        width: '0.3em',
      },
      '@supports ( -moz-appearance:none )': {
        '&::-webkit-scrollbar': {
          width: '0.3em',
        },
      },
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      backgroundColor: theme.palette.background.paper,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.background.grid,
      outline: `1px solid ${theme.palette.background.grid}`,
      /* border-radius: 6px;
      border: 3px solid var(--scrollbarBG); */
    },
    '&::-webkit-scrollbar-corner': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  scrollbarSmall: {
    [theme.breakpoints.down('md')]: {
      overflow: 'auto !important',
    },
    [theme.breakpoints.up('md')]: {
      overflow: 'hidden !important',
    },
    '&:hover': {
      overflow: 'overlay !important',
      '@supports ( -moz-appearance:none )': {
        overflow: 'scroll !important',
        'scrollbar-color': `${theme.palette.background.grid} ${theme.palette.background.paper} !important`,
        'scrollbar-width': 'thin !important',
      },
    },

    // zIndex: 100,
    margin: 0,
    padding: 0,
    listStyle: 'none',
    // height: '100%',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      height: '0.4em',
    },
    '@supports ( -moz-appearance:none )': {
      '&::-webkit-scrollbar': {
        width: '0.4em',
        height: '0.4em',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '&::-webkit-scrollbar': {
        width: '0.2em',
        height: '0.2em',
      },
      '@supports ( -moz-appearance:none )': {
        '&::-webkit-scrollbar': {
          width: '0.2em',
          height: '0.2em',
        },
      },
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      backgroundColor: theme.palette.background.paper,
      borderRadius: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.background.grid,
      outline: `1px solid ${theme.palette.background.grid}`,
      borderRadius: '6px',
      /* border-radius: 6px;
      border: 3px solid var(--scrollbarBG); */
    },

    '&::-webkit-scrollbar-corner': {
      backgroundColor: theme.palette.background.paper,
    },
  },
});

export default styles;

/*
list: {
  overflowY: "auto",
  margin: 0,
  padding: 0,
  listStyle: "none",
  height: "100%",
  '&::-webkit-scrollbar': {
    width: '0.4em'
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,.1)',
    outline: '1px solid slategrey'
  }
}
  */
