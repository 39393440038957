import { gql } from 'graphql-request';

const DOB_INPUT_FIELDS = gql`
  fragment dobInput on ComponentWizardComponentsDobInput {
    DobInput_slug: slug
    DobInput_name: name
    DobInput_label: label
    DobInput_labelAlign: labelAlign
    DobInput_variant: variant
    DobInput_layoutPosition: layoutPosition
    DobInput_staticValue: staticValue
    DobInput_reducerKeyValue: reducerKeyValue
    DobInput_positionNo: positionNo
    DobInput_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    DobInput_fullWidth: fullWidth
    DobInput_required: required
    DobInput_isConditionalComponent: isConditionalComponent
    DobInput_conditionalValue: conditionalValue
    DobInput_conditionalKey: conditionalKey
    DobInput_conditionalOperator: conditionalOperator
    DobInput_conditionalFieldType: conditionalFieldType
    DobInput_conditionalAction: conditionalAction
    DobInput_conditionalArrayKey: conditionalArrayKey
    DobInput_disable: disable
    DobInput_show: show
    DobInput_tooltip: tooltip
    DobInput_disableInitialValue: disableInitialValue
    DobInput_placeholder: placeholder
    DobInput_dateType: dateType
    DobInput_openTo: openTo
    DobInput_label_en: label_en
    DobInput_label_nl: label_nl
    DobInput_tooltip_en: tooltip_en
    DobInput_tooltip_nl: tooltip_nl
    DobInput_placeholder_en: placeholder_en
    DobInput_placeholder_nl: placeholder_nl
    DobInput_conditionalMessage: conditionalMessage
    DobInput_conditions: conditions {
      ...condition
    }
    DobInput_isMinDateToday: isMinDateToday
    DobInput_isMaxDateToday: isMaxDateToday
    DobInput_minDate: minDate
    DobInput_maxDate: maxDate
    DobInput_excludeCurrentDate: excludeCurrentDate
    DobInput_transformToAge: transformToAge
  }
`;

// export default DOB_INPUT_FIELDS;
// exports.default = DOB_INPUT_FIELDS;
export default DOB_INPUT_FIELDS;
