import { userHome, agentHome } from '../../../utils/variables/images';

const styles = theme => {
  // const { contentValue } = useMainContentHeight();
  return {
    alignCenter: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      minHeight: '200px',
    },
    root: {
      width: '100%',
      height: 'inherit',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      textAlign: 'center',
      padding: theme.spacing(4),
      minHeight: 'inherit',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
        borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px 0px`,
        display: 'block',
      },
      backgroundColor: `${theme.palette.background.default} !important`,
    },
    homeImageContainer: {
      position: 'relative',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        height: '248px',
      },
      [theme.breakpoints.up('sm')]: {
        height: '350px',
        // transform: 'scale(1.5)',
      },
    },
    imgContainer: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '100%',
        minHeight: '100%',
        objectFit: 'cover',
        backgroundImage: `url(${userHome})`,
        backgroundRepeat: 'no-repeat',
        // transform: 'scale(1.3)',
        // backgroundSize: 'cover',
        backgroundPosition: '20% 30%',
        position: 'absolute',

        // '-webkit-background-size': 'cover',
        // '-moz-background-size': 'cover',
        // '-o-background-size': 'cover',

        overflow: 'hidden',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        // backgroundSize: '100%',
        backgroundSize: '800px',
        // transform: 'scale(2)',
      },
      [theme.breakpoints.up('sm')]: {
        // backgroundSize: '100%',
        backgroundSize: '1000px',
        // transform: 'scale(1.5)',
      },
    },
    agentImgContainer: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '100%',
        minHeight: '100%',
        objectFit: 'cover',
        backgroundImage: `url(${agentHome})`,
        backgroundRepeat: 'no-repeat',
        // transform: 'scale(0.5)',
        // backgroundSize: 'cover',
        backgroundPosition: '50% 10%',
        position: 'absolute',

        // '-webkit-background-size': 'cover',
        // '-moz-background-size': 'cover',
        // '-o-background-size': 'cover',

        overflow: 'hidden',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        // backgroundSize: '500px',
        // transform: 'scale(2)',
        backgroundSize: '100%',
      },
      [theme.breakpoints.up('sm')]: {
        backgroundSize: '100%',
        // backgroundSize: '1000px',
        // transform: 'scale(1.5)',
      },
    },
    home: {
      [theme.breakpoints.down('sm')]: {
        backgroundColor: theme.palette.background.default,
        position: 'relative',
        borderRadius: `${theme.shape.borderRadius * 5}px ${theme.shape.borderRadius *
          5}px 0px 0px`,

        paddingBottom: '0px!important',
        margin: 0,
      },
    },
    line: {
      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'black',
        height: '0.25px',
        zIndex: 1,
        position: 'relative',
        bottom: theme.spacing(2.5),
      },
    },
    fullHeight: {
      height: '100%',
    },
    selectProduct: {
      display: 'inline-block',
      backgroundColor: theme.palette.background.default,
      position: 'sticky',
      zIndex: '10',
      paddingRight: theme.spacing(0.5),
    },
    marginVertical: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
    banner: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      flexGrow: 1,
      justifyContent: 'stretch',

      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
    bannerHome: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
  };
};

export default styles;
