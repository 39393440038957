import { routerGetCall } from '../../axios';

const signInViaGoogle = (state, params) => {
  const url = `/auth/google/signin`;
  return routerGetCall(state, url, params);
};

// const authGoogle = (state, params) => {
//   const url = `/auth/facebook`;
//   return authGetCall(state, url, params);
// };

export default { signInViaGoogle };
