import {
  GET_LOCATIONS_API_ERROR,
  GET_LOCATIONS_API_REQUEST,
  GET_LOCATIONS_API_SUCCESS,
  GET_FILTERED_LOCATIONS_API_ERROR,
  GET_FILTERED_LOCATIONS_API_SUCCESS,
  GET_FILTERED_LOCATIONS_API_REQUEST,
} from '../../constants/locationActionNames';
import { statusOk } from '../../utils/variables';
import initialData from './initialData';

export const initialState = {
  isError: false,
  isSuccess: false,
  isPending: false,
  isLoading: true,
  response: null,
  locationOnMap: null,
  allRows: [],
  companies: [],
  serviceProvider: [],
  specialty: [],
  filteredLocations: [],
  defaultLocation: [],
  isShowOnMap: false,
};

const createCompanies = company => {
  if (!Array.isArray(company)) return null;
  return company.map((object, index) => {
    const list = {
      _id: index,
      label: object?.company || 'N/A',
    };
    return list;
  });
};

const createServiceProvider = serviceProvider => {
  if (!Array.isArray(serviceProvider)) return null;
  return serviceProvider.map((object, index) => {
    return {
      _id: index,
      label: object?.serviceProvider,
    };
  });
};

// const createSpecialty = data => {
//   if (!Array.isArray(data)) return null;
//   return data.map((object) => {
//     console.log("Specialty", object);
//     return {
//       specialty: object?.address,
//     };
//   });
// };

const createProviderSpecialties = provider => {
  if (!Array.isArray(provider)) return null;
  return provider?.map((object, index) => {
    return {
      id: index,
      label: object?.label,
    };
  });
};

const createAddress = address => {
  // console.log("file: index.js : line 64 : address", address);
  if (!Array.isArray(address)) return null;
  return address.map((object, index) => {
    return {
      id: index,
      town: object?.town,
      countryId: object?.countryId,
      cardinalPoint: object?.cardinalPoint,
      contact: object?.contact,
      contact1: object?.contact1 || '',
      contact2: object?.contact2 || '',
      emailAddress: object?.email,
      website: object?.website,
      geoLocation: object?.geoLocation?.coordinates || null,
      addressLine: object?.address,
      providerSpecialties: createProviderSpecialties(object?.providerSpecialties),
      openingHours: object?.openingHours,
    };
  });
};

const disableButton = location => {
  // console.log("file: index.js : line 94 : location", location);
  let disabled = true;
  if (!Array.isArray(location)) return null;
  location = location?.filter(x => x?.geoLocation);
  if (location?.length > 0) {
    disabled = false;
  }
  return disabled;
};

const createDefaultLocation = location => {
  // console.log("file: index.js : line 94 : location", location);
  if (!Array.isArray(location)) return null;
  return location.map((object, index) => {
    const list = {
      _id: index,
      lat: object?.address?.map(coords => coords?.geoLocation?.[0]) || null,
      lng: object?.address?.map(coords => coords?.geoLocation?.[1]) || null,
      latLng: object?.address?.map(add => add?.geoLocation),
      data: object,
      address: object?.address?.map(a => a),
      buttonDisabled: disableButton(object?.address),
    };
    return list;
  });
};

const createPayload = (locations, startIndex = 0) => {
  if (!Array.isArray(locations)) return null;

  const newLocations = locations?.map((object, index) => {
    // console.log('object: ', object);

    const position = startIndex + index;
    const location = {
      ...object,
      index: position,
      _id: index,
      title: object?.title,
      firstName: object?.firstName,
      lastName: object?.lastName,
      company: object?.company,
      address: createAddress(object?.address),
      buttonDisabled: disableButton(object?.address),
    };
    return location;
  });
  return newLocations;
};

const officeLocatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOCATIONS_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isSuccess: false,
        isError: false,
      };
    case GET_LOCATIONS_API_SUCCESS: {
      const locations = action?.payload?.payload?.locations || [];
      const incomingLocations = [...locations];
      const allRows = createPayload(incomingLocations) || initialState?.allRows;
      const companyArray = createCompanies(incomingLocations);
      const count = allRows?.length || 0;
      const location = [...allRows];
      const isError = action?.payload?.responseType !== statusOk;
      const defaultLocation = createDefaultLocation(location);

      const providers = [
        {
          label: 'Life Provider',
        },
        {
          label: 'Health Provider',
        },
      ];

      return {
        ...state,
        isPending: false,
        isError,
        isSuccess: true,
        response: action?.payload,
        locations: location,
        count,
        allRows,
        companies: companyArray,
        providers,
        filteredLocations: location,
        defaultLocation,
        isShowOnMap: true,
      };
    }
    case GET_LOCATIONS_API_ERROR: {
      const testData = createPayload(initialData);
      const companyArray = createCompanies(initialData);
      const serviceProviderArray = createServiceProvider(initialData);
      //  const specialtyArrayTemp = createSpecialty(testData);
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        response: testData,
        companies: companyArray,
        serviceProvider: serviceProviderArray,
        // specialty: specialtyArrayTemp2,
      };
    }
    case GET_FILTERED_LOCATIONS_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isSuccess: false,
        isError: false,
      };
    case GET_FILTERED_LOCATIONS_API_ERROR: {
      const testData = createPayload(initialData);
      // const companyArray = createCompanies(initialData);
      // const serviceProviderArray = createServiceProvider(initialData);
      //  const specialtyArrayTemp = createSpecialty(testData);
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        response: testData,
        // companies: companyArray,
        //  serviceProvider: serviceProviderArray,
        // specialty: specialtyArrayTemp2,
      };
    }
    case GET_FILTERED_LOCATIONS_API_SUCCESS: {
      const locations = action?.payload?.payload?.locations;
      const allRows = createPayload(locations) || initialState?.allRows;
      const location = allRows;
      const isError = action?.payload?.responseType !== statusOk;
      let defaultLocation = [];
      if (location?.length !== 0) {
        defaultLocation = createDefaultLocation(location);
      }

      // let defaultLocation = createDefaultLocation(location);
      // if (location?.length !== 0) {
      //   defaultLocation = createDefaultLocation(location);
      // }

      return {
        ...state,
        isPending: false,
        isError,
        isSuccess: true,
        filteredLocations: location,
        defaultLocation,
        isShowOnMap: true,
        locationOnMap: null,
      };
    }

    case 'update_map_coords': {
      return {
        ...state,
        locationOnMap: action?.payload,
        defaultLocation: [],
      };
    }

    default:
      return state;
  }
};

export default officeLocatorReducer;
