const getColorFromTheme = (color, theme) => {
  // console.log('Get color form theme called for ', color, theme);
  switch (color) {
    case 'primary':
      return theme.palette.primary.main;
    case 'secondary':
      return theme.palette.secondary.main;
    case 'tertiary':
      return theme.palette.tertiary.main;
    default:
      return '';
  }
};

export default theme => ({
  cardButton: props => {
    return {
      [theme.breakpoints.up('md')]: {
        padding: '1rem !important',
      },
    };
  },
  product: {
    [theme.breakpoints.down('md')]: {
      padding: '7px 17px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '16px 24px',
    },
  },
  cardContainer: props => {
    return {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '6px',
      [theme.breakpoints.down('md')]: {
        // minHeight: '20vh',
      },
      [theme.breakpoints.up('md')]: {
        // minHeight: props.isAction ? '100%' : '15vh',
        padding: theme.spacing(1),
      },
      padding: '0 !important',
      boxShadow: theme.effects.boxShadow,
      transition: theme.effects.transition,
    };
  },
  actionHover: props => {
    return {
      backgroundColor: `${props.product.background} !important`,
      '&:hover': {
        backgroundColor: `${theme.palette.text.bright} !important`,
        color: `${props.product.background} !important`,
        '& $iconStyles': {
          // backgroundColor: `${theme.palette.text.bright} !important`,
          color: `${theme.palette.text.bright} !important`,
        },
        '& $iconContainer': {
          borderColor: `${props.product.background} !important`,
          borderWidth: 0,
          borderStyle: 'solid',
          backgroundColor: `${props.product.background} !important`,
          color: `${theme.palette.text.bright} !important`,
        },
        '& $titleStyles,$subTitleStyles': {
          color: `${props.product.background} !important`,
        },
      },
    };
  },
  iconStyles: {
    // color: `${theme.palette.text.bright}`,
  },
  lobHover: props => {
    return {
      backgroundColor: `${theme.palette.background.paper} !important`,
      '&:hover': {
        backgroundColor: `${props.product.iconBackground} !important`,
        color: `${theme.palette.text.bright} !important`,
        '& $iconStyles': {
          // backgroundColor: `${theme.palette.text.bright} !important`,
          color: `${props.product.background} !important`,
        },
        '& $iconContainer': {
          borderColor: `${props.product.background} !important`,
          borderWidth: 0,
          borderStyle: 'solid',
          backgroundColor: `${theme.palette.text.bright} !important`,
          color: `${props.product.background} !important`,
        },
        '& $titleStyles,$subTitleStyles': {
          color: `${theme.palette.text.bright} !important`,
        },
      },
    };
  },

  iconContainer: props => {
    return {
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: `${props.product.iconBackground} !important`,
    };
  },

  textContainer: {
    paddingLeft: theme.spacing(1),
  },

  titleStyles: props => {
    return {
      textAlign: props.isAction && !props.isSmall ? 'left' : 'center',
      fontWeight: 'bold !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px !important',
      },
      color:
        props.product.variant === 'filled'
          ? `${theme.palette.text.bright} !important`
          : `${theme.palette.text.label} !important`,
    };
  },
  subTitleStyles: props => {
    return {
      textAlign: props.isAction && !props.isSmall ? 'left' : 'center',
      fontSize: '16px !important',
      [theme.breakpoints.down('xl')]: {
        fontSize: '14px !important',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '12px !important',
      },
      fontWeight: '500 !important',
      color:
        props.product.variant === 'filled'
          ? `${theme.palette.text.bright} !important`
          : `${theme.palette.text.disabled} !important`,
    };
  },

  iconGridStyles: props => {
    if (props.isAction) {
      return {
        display: 'flex',
        flexDirection: 'row !important',
        alignItems: 'center',
        justifyContent: 'center',
      };
    }
    return {
      display: 'flex',
      flexDirection: 'column !important',
      alignItems: 'center',
      justifyContent: 'center',
    };
  },
});
