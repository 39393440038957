import { Button, Grid, Icon, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSmall } from '../../../hooks';
import styles from './style';

const IconButton = ({
  href,
  icon,
  title,
  inverseBackgroundColor,
  onClick,
  color,
  ...restProps
}) => {
  const isSmall = useSmall();
  const useStyles = makeStyles(theme =>
    styles(theme, inverseBackgroundColor, isSmall, color),
  );
  const classes = useStyles();
  useEffect(() => {}, []);

  return (
    <Button className={classes.button} href={href} onClick={onClick}>
      <Grid container style={{ alignItems: 'center' }} spacing={0}>
        <Grid item xs={3} className={classes.buttonIcon}>
          <Icon
            style={{ fontSize: isSmall ? '1.5rem' : '2rem' }}
            data-nosnippet="data-nosnippet"
          >
            {icon}
          </Icon>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="subtitle2" style={{ textAlign: 'center' }}>
            {title}
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );
};

IconButton.defaultProps = {
  href: '',
  inverseBackgroundColor: false,
  icon: 'cloud_download',
  title: 'button',
};

IconButton.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  inverseBackgroundColor: PropTypes.bool,
};

export default IconButton;
