import React, { useState, useEffect } from 'react';
import {
  Grid,
  IconButton,
  InputBase,
  Paper,
  Typography,
  MenuItem,
  MenuList,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArticleIcon from '@mui/icons-material/Article';
import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { getAllHelpArticlesAction } from '../../../actions/helpCenter/articles/getAllHelpArticles';
import { useRouter } from '../../../hooks';
import routes from '../../../utils/variables/routes';

// eslint-disable-next-line import/prefer-default-export
export function SearchCard({ placeholder }) {
  const theme = useTheme();
  const router = useRouter();
  const dispatch = useDispatch();
  const [focus, setFocus] = useState(false);
  const options = useSelector(state => state.helpCenter.allHelpArticles);
  const alpha2 = useSelector(state => state?.locations?.yourLocation?.alpha2);
  const parsed = router?.query || {};
  const defaultSearchValue = parsed?.search || '';
  const [searchValue, setSearchValue] = useState(defaultSearchValue);
  const [searchOptions, setSearchOptions] = useState(options);

  useEffect(() => {
    if (options.length === 0) {
      dispatch(getAllHelpArticlesAction({ territory: alpha2 }));
    }
    setSearchOptions(options);
  }, [options]);

  const navToHelpArticle = ({ slug }) => {
    router.push(`${routes.helpCenter.helpArticle}/${slug}`);
  };

  const onBlur = e => {
    setTimeout(() => {
      setFocus(false);
    }, 500);
  };

  const handleSearch = value => {
    if (value.trim() && options.length > 0) {
      setSearchOptions(
        options.filter(option => {
          if (option?.title.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
            return option;
          }
        }),
      );
    } else {
      setSearchOptions(options);
    }
  };

  const handleChange = value => {
    if (value.trim() && options.length > 0) {
      setSearchOptions(
        options.filter(option => {
          if (option?.title.toLowerCase().indexOf(value.toLowerCase()) >= 0) {
            return option;
          }
        }),
      );
    } else {
      setSearchOptions(options);
    }

    setSearchValue(value);
  };

  return (
    <Grid
      // container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        // pt: '2',
        position: 'relative',
      }}
    >
      <Grid item>
        <Paper
          component="form"
          sx={{
            p: '0.125rem 0.25rem',
            display: 'flex',
            alignItems: 'center',
            width: {
              md: '30.125rem',
            },
            height: '3rem',
            borderRadius: '0.5rem',
            border: '1px solid background.contentBox',
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1, color: 'text.disabled' }}
            placeholder={placeholder}
            value={searchValue}
            onBlur={onBlur}
            onFocus={() => {
              setFocus(true);
            }}
            onChange={e => {
              const newValue = e.target.value;
              handleChange(newValue);
            }}
            onKeyPress={ev => {
              if (ev.key === 'Enter') {
                ev.preventDefault();
                handleSearch(searchValue);
              }
            }}
            inputProps={{ 'aria-label': 'search help articles' }}
          />
          <IconButton
            type="button"
            sx={{ p: '0.625rem' }}
            aria-label="search"
            onClick={() => handleSearch(searchValue)}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </Grid>
      {searchOptions && (focus || searchValue) && (
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="center"
          sx={{
            borderRadius: '0.5rem',
            backgroundColor: 'text.bright',
            zIndex: 2,
            maxHeight: '22vh',
            overflow: 'auto',
            // position: 'absolute',
            // width: '100%',
          }}
          rowSpacing={1}
          margin="0.25rem 0"
        >
          <Grid item xs={12} margin="0.313rem 0.625rem">
            {searchOptions.length != 0 ? (
              <Typography sx={{ color: 'text.disabled' }} textAlign="left">
                Search Results
              </Typography>
            ) : (
              <Typography sx={{ color: 'text.disabled' }} textAlign="left">
                No search Results
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <MenuList>
              {searchOptions.map(option => (
                <MenuItem
                  key={option}
                  value={option}
                  onClick={() => navToHelpArticle(option)}
                  textAlign="left"
                >
                  <ArticleIcon /> {option?.title}
                </MenuItem>
              ))}
            </MenuList>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
