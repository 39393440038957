import { START_CHAT_CRM, END_CHAT_CRM } from '../../constants/chatSDKActionNames';

export const initialState = {
  chatSDK: {},
  chatToken: null,
  crmChat: false,
};

const chatSDKReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_CHAT_CRM: {
      return {
        ...state,
        crmChat: true,
      };
    }
    case END_CHAT_CRM: {
      return {
        ...state,
        crmChat: false,
      };
    }

    default:
      return state;
  }
};

export default chatSDKReducer;
