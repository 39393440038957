import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { HtmlEditor } from '@aeaton/react-prosemirror';
import { options } from '@aeaton/react-prosemirror-config-default';
import styles from './style';
import sanitizeText from '../../../utils/functions/sanitizeText';

const useStyles = makeStyles(styles);

const DisplayProseContent = props => {
  const { content, source, staticData, sx, style, videoHeight, ...restProps } = props;
  const classes = useStyles({ videoHeight });

  let html = '';
  if (source === 'remote') {
    html = staticData?.html;
  } else {
    html = content;
  }

  let newHtml = '';
  if (html && html !== 'undefined' && html !== 'null') {
    newHtml = sanitizeText(html);
  }

  return (
    <Box className={classes.content} sx={sx} style={style}>
      {HtmlEditor && (
        <HtmlEditor
          options={options}
          value={newHtml}
          attributes={{ contentEditable: false }}
        />
      )}
    </Box>
  );
};

export default DisplayProseContent;
