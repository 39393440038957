import {
  RESET_WIZARD_DATA_SUCCESS,
  RESET_WIZARD_SUCCESS,
  RESET_WIZARD_FLAG_SUCCESS,
  RESET_WIZARD_FIELDS_ONCHANGE,
  SET_API_CALL_PENDING,
  SET_MODAL_API_CALL_PENDING,
  RESET_MODAL_WIZARD_SLUG_SUCCESS,
  SET_CHECKBOX_STATE,
} from '../../../constants/wizardActionNames';

export function resetWizardDataAction(
  newData,
  nextBackButtonLink,
  customerId,
  clearResponse,
) {
  return {
    type: RESET_WIZARD_DATA_SUCCESS,
    payload: newData,
    params: {
      nextBackButtonLink,
    },
    clearResponse,
  };
}

export function resetWizardAction() {
  return {
    type: RESET_WIZARD_SUCCESS,
    payload: true,
  };
}

export function resetWizardFlagAction(flag) {
  return {
    type: RESET_WIZARD_FLAG_SUCCESS,
    payload: flag,
  };
}

export function setApiCallPendingAction(status) {
  return {
    type: SET_API_CALL_PENDING,
    payload: status,
  };
}

export function setModalApiCallPendingAction(status) {
  return {
    type: SET_MODAL_API_CALL_PENDING,
    payload: status,
  };
}

export function resetModalWizardSlugAction() {
  return {
    type: RESET_MODAL_WIZARD_SLUG_SUCCESS,
    payload: true,
  };
}

export function resetWizardFieldsOnChange(resetFields) {
  return {
    type: RESET_WIZARD_FIELDS_ONCHANGE,
    payload: {
      resetFields,
    },
  };
}

export function setCheckBoxState(flag) {
  return {
    type: SET_CHECKBOX_STATE,
    payload: {
      flag,
    },
  };
}

export default {
  resetWizardFieldsOnChange,
  resetWizardDataAction,
  resetWizardAction,
  resetWizardFlagAction,
  setApiCallPendingAction,
  setModalApiCallPendingAction,
  resetModalWizardSlugAction,
  setCheckBoxState,
};
