export const GET_PAYMENT_CARD_DETAILS_REQUEST = 'GET_PAYMENT_CARD_DETAILS_REQUEST';
export const GET_PAYMENT_CARD_DETAILS_SUCCESS = 'GET_PAYMENT_CARD_DETAILS_SUCCESS';
export const GET_PAYMENT_CARD_DETAILS_ERROR = 'GET_PAYMENT_CARD_DETAILS_ERROR';

export const GET_PAYMENT_CARDS_REQUEST = 'GET_PAYMENT_CARDS_REQUEST';
export const GET_PAYMENT_CARDS_SUCCESS = 'GET_PAYMENT_CARDS_SUCCESS';
export const GET_PAYMENT_CARDS_ERROR = 'GET_PAYMENT_CARDS_ERROR';

export const ADD_PAYMENT_CARD_REQUEST = 'ADD_PAYMENT_CARD_REQUEST';
export const ADD_PAYMENT_CARD_SUCCESS = 'ADD_PAYMENT_CARD_SUCCESS';
export const ADD_PAYMENT_CARD_ERROR = 'ADD_PAYMENT_CARD_ERROR';

export const UPDATE_PAYMENT_CARD_REQUEST = 'UPDATE_PAYMENT_CARD_REQUEST';
export const UPDATE_PAYMENT_CARD_SUCCESS = 'UPDATE_PAYMENT_CARD_SUCCESS';
export const UPDATE_PAYMENT_CARD_ERROR = 'UPDATE_PAYMENT_CARD_ERROR';

export const DELETE_PAYMENT_CARD_REQUEST = 'DELETE_PAYMENT_CARD_REQUEST';
export const DELETE_PAYMENT_CARD_SUCCESS = 'DELETE_PAYMENT_CARD_SUCCESS';
export const DELETE_PAYMENT_CARD_ERROR = 'DELETE_PAYMENT_CARD_ERROR';

export const AUTHORIZE_PAYMENT_CARD_REQUEST = 'AUTHORIZE_PAYMENT_CARD_REQUEST';
export const AUTHORIZE_PAYMENT_CARD_SUCCESS = 'AUTHORIZE_PAYMENT_CARD_SUCCESS';
export const AUTHORIZE_PAYMENT_CARD_ERROR = 'AUTHORIZE_PAYMENT_CARD_ERROR';
