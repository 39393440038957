import { useMediaQuery, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { useRouter, useSmall } from '../../../hooks';
import { getRef, sourceTextFromData, getEnv } from '../../../utils/functions';
import { transformReducerValue } from '../../wizards/functions';

const scriptLink = 'https://mfpembedcdnwus2.azureedge.net/mfpembedcontwus2/Embed.js';
const styleLink = 'https://mfpembedcdnwus2.azureedge.net/mfpembedcontwus2/Embed.css';

const elementId = 'surveyDiv';

const renderSurvey = props => {
  const {
    surveyId = '',
    variables = [],
    buttonText = 'Give Feedback',
    buttonVariant,
    embedType,
    renderType,
    type,
    state,
    isSmall,
    customContext,
  } = props;
  // console.log('surveyId: ', surveyId);
  // console.log('state: ', state);
  const se = new window.SurveyEmbed(
    surveyId?.trim(),
    'https://customervoice.microsoft.com/',
    'https://mfpembedcdnwus2.azureedge.net/mfpembedcontwus2/',
    'true',
  );
  // console.log('se: ', se);
  // console.log('variables: ', variables);
  let newContext = variables.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.key]:
          transformReducerValue(state, item?.reducerKey) ||
          sourceTextFromData(state, item?.staticValue),
      }),
    {},
  );

  if (customContext) {
    newContext = {
      ...newContext,
      ...customContext,
    };
  }
  // console.log('newContext: ', newContext);
  Object.keys(newContext).forEach(key => {
    if (newContext[key] === undefined) {
      delete newContext[key];
    }
  });
  // console.log('newContext: ', newContext);
  // JSON.stringify(context);

  const button = document.getElementById('MfpEmbed_Button_Container_Collapse');
  if (button) {
    button.style.display = 'none';
  }

  if (embedType === 'button') {
    se.renderButton(newContext);

    if (button) {
      // console.log('button: ', button);
      button.style.display = 'block';
      if (isSmall) {
        button.style.left = '5%';
        button.style.bottom = '50px';
      } else {
        button.style.left = '300px';
        button.style.bottom = '0px';
      }
    }
  } else if (embedType === 'popup') {
    se.renderPopup(newContext);
  } else if (embedType === 'inline') {
    se.renderInline(elementId, newContext);
  }
};

const EmbedSurvey = props => {
  const {
    styles,
    surveyId: surveyIdObj,
    embedType,
    headingText,
    renderType,
    type,
    headingVariant,
  } = props;
  // console.log('props: ', props);
  const router = useRouter();
  const state = useStore()?.getState();
  const [rendered, setRendered] = useState(false);
  const isSmall = useSmall();

  const env = getEnv();
  const surveyId = surveyIdObj && surveyIdObj[env] ? surveyIdObj[env] : surveyIdObj;
  // console.log('surveyIdObj: ', surveyIdObj);
  // console.log('surveyId: ', surveyId);
  // console.log('isSmall: ', isSmall);
  const addStyle = url => {
    const style = document.createElement('link');
    style.href = url;
    style.rel = 'stylesheet';
    style.async = true;

    document.head.appendChild(style);
  };

  const addScript = src => {
    const script = document.createElement('script');
    script.setAttribute('src', src);
    script.setAttribute('id', 'msvoicescript');
    script.setAttribute('async', 'async');
    script.setAttribute('defer', 'defer');
    document.body.appendChild(script);
    script.onload = () => {
      // do stuff with the script
      if (surveyId && !rendered) {
        renderSurvey({ ...props, state, isSmall, surveyId });
        setRendered(true);
      }
    };
  };

  useEffect(() => {
    if (!rendered) {
      addScript(scriptLink);
      addStyle(styleLink);
    } else if (rendered) {
      renderSurvey({ ...props, state, isSmall, surveyId });
    }
    return () => {
      // setRendered(false);
      const embedButton = document.getElementById('MfpEmbed_Button_Container_Collapse');
      if (embedButton) {
        embedButton.style.display = 'none';
      }
    };
  }, [surveyId, isSmall]);

  /*
  useEffect(() => {
    const embedButton = document.getElementById('MfpEmbed_Button_Container_Collapse');
    if (embedButton) {
      if (embedType === 'button' && type === 'embed' && renderType === 'onLoad') {
        embedButton.style.display = 'block';
      } else {
        embedButton.style.display = 'none';
      }
    }
  }, [router.asPath]);
  */

  return (
    <div>
      {headingText && embedType === 'inline' && (
        <Typography variant={headingVariant || 'body1'}>{headingText}</Typography>
      )}
      <div id={elementId} style={styles} />
    </div>
  );
};
// jPk_g50zD0GtYotxYQjJzxgmDjAlHYZHotqUSuEp44lUOE9HSU1WQjkzQ0tRWjU2U0I1UktEMDhHRC4u
EmbedSurvey.defaultProps = {
  surveyId: '',
  buttonText: 'Give Feedback',
  type: 'embed',
  embedType: 'button',
  variables: [
    {
      key: 'c360Id',
      ref: 'auth.user.id',
    },
    {
      key: 'country',
      ref: 'locations.yourLocation.alpha2',
    },
    {
      key: 'firstName',
      ref: 'auth.user.firstName',
    },
    {
      key: 'lastName',
      ref: 'auth.user.lastName',
    },
    {
      key: 'email',
      ref: 'auth.user.email',
    },
    {
      key: 'phone',
      ref: 'auth.user.mobile',
    },
    {
      key: 'dob',
      ref: 'auth.user.dob',
    },
  ],
};

export default EmbedSurvey;

/*
  const removeJS = filename => {
    const tags = document.getElementsByTagName('script');
    for (let i = tags.length; i >= 0; i--) {
      // search backwards within nodelist for matching elements to remove
      if (
        tags[i] &&
        tags[i].getAttribute('src') != null &&
        tags[i].getAttribute('src').indexOf(filename) != -1
      )
        tags[i].parentNode.removeChild(tags[i]); // remove element by calling parentNode.removeChild()
      console.log('tags[i]: ', tags[i]);
    }
  };
  */
