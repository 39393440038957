import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Dialog,
  // DialogContent,
  // DialogContentText,
  // Container,
  // DialogTitle,
  // DialogActions,
  Typography,
  Grid,
  Button,
  Slide,
  AlertTitle,
  Alert,
} from '@mui/material';

import { makeStyles } from '@mui/styles';
import { sourceTextFromData } from '../../../utils/functions';
import {
  useRouter,
  useGTMDataLayer,
  useVariables,
  useFeatureToggle,
} from '../../../hooks';
import { statusOk } from '../../../utils/variables';
import styles from './style';
import routes from '../../../utils/variables/routes';
import {
  serverModalErrorEvent,
  wizardsServerErrorEvent,
} from '../../../utils/variables/events';

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const getErrorMessages = (responseData, { contactNumber, storeState, useFatumLogin }) => {
  const statusCode =
    responseData?.status || responseData?.statusCode || responseData?.responseDesc?.code;
  let stringErrorMessage = '';
  let defaultErrorTitle = 'Something Went Wrong!';
  let defaultErrorMessage = `Please try again, or contact our customer support at ${contactNumber}`;
  let defaultAlertColor = '';
  let defaultRequestId = '';

  const isMultiple = Array.isArray(responseData);

  if (statusCode === 429) {
    defaultErrorTitle = 'Too many requests';
    stringErrorMessage = responseData?.message;
    defaultAlertColor = 'error';
  }
  const errors = [];
  if (useFatumLogin && responseData?.details?.crmCaseNumber) {
    errors.push({
      title: defaultErrorTitle,
      message: `We regret to inform you that your signup attempt has encountered an issue. A support ticket with the case number '${responseData?.details?.crmCaseNumber}' has been promptly created with our Customer Relations Management (CRM) team. Kindly bear with us, as someone from our team will reach out to you soon.`,
      footer: 'Thank you for your patience.',
      type: responseData?.responseDesc?.type || defaultAlertColor,
      requestId: defaultRequestId,
      redirectUrl: responseData?.responseDesc?.redirectUrl,
    });
    return errors;
  }
  if (!isMultiple && responseData?.responseType) {
    defaultErrorTitle =
      responseData?.responseType === statusOk ? 'Success' : 'Something Went Wrong!';
    defaultErrorMessage =
      responseData?.responseType === statusOk
        ? 'Everything works fine'
        : 'Please check your internet connection, and try again.';
    defaultAlertColor = responseData?.responseType === statusOk ? 'success' : 'error';
    defaultRequestId = responseData?.headers?.['x-request-id'] || statusCode;
    const error =
      responseData?.responseDesc?.message ||
      responseData?.responseDesc ||
      defaultErrorMessage;
    stringErrorMessage =
      typeof error === 'object' && error !== null
        ? `${JSON.stringify(error, null, '\t')}`
        : sourceTextFromData(storeState, error);

    errors.push({
      title: responseData?.responseDesc?.title || defaultErrorTitle,
      message: stringErrorMessage,
      type: responseData?.responseDesc?.type || defaultAlertColor,
      requestId: defaultRequestId,
      redirectUrl: responseData?.responseDesc?.redirectUrl,
    });
  } else if (isMultiple && responseData) {
    responseData.forEach((response, index) => {
      const defaultMultiErrorTitle =
        response?.responseType === statusOk ? 'Success' : 'Something Went Wrong!';
      const defaultMultiAlertColor =
        response?.responseType === statusOk ? 'success' : 'error';
      const subError =
        response?.responseDesc?.message || response?.responseDesc || defaultErrorMessage;
      const subStringErrorMessage =
        typeof subError === 'object' && subError !== null
          ? `${JSON.stringify(subError, null, '\t')}`
          : subError;
      const subRequestId = response?.headers?.['x-request-id'];
      errors.push({
        title: response?.responseDesc?.title || defaultMultiErrorTitle,
        message: subStringErrorMessage,
        type: response?.responseDesc?.type || defaultMultiAlertColor,
        requestId: subRequestId,
        redirectUrl: response?.responseDesc?.redirectUrl,
      });
    });
  } else {
    defaultRequestId = responseData?.headers?.['x-request-id'] || statusCode;
    errors.push({
      title: defaultErrorTitle,
      message: stringErrorMessage,
      type: defaultAlertColor,
      requestId: defaultRequestId,
      redirectUrl: responseData?.responseDesc?.redirectUrl,
    });
  }

  return errors;
};

const ServerErrorModal = ({
  open: initialOpen,
  closeDialog,
  responseData,
  isWizardError,
  slug,
}) => {
  // console.log('responseData: ', responseData);
  const classes = useStyles();
  // const dispatch = useDispatch();
  const router = useRouter();
  const storeGtmDataLayer = useGTMDataLayer();
  const storeState = useSelector(state => state);
  const variables = useVariables();
  const doFeatureToggle = useFeatureToggle();
  const useFatumLogin = doFeatureToggle('useFatumLogin');
  const contactNumber = variables?.contactNumbers?.contact_us?.phone;

  const [errors, setErrors] = useState(null);
  const [open, setOpen] = useState(initialOpen);

  useEffect(() => {
    const newErrors = getErrorMessages(responseData, {
      contactNumber,
      storeState,
      useFatumLogin,
    });
    setOpen(initialOpen);
    setErrors(newErrors);
    // console.log('newErrors: ', newErrors);
    if (newErrors && newErrors?.length > 0) {
      newErrors?.forEach((error, index) => {
        if (error?.message) {
          storeGtmDataLayer({
            event: isWizardError ? wizardsServerErrorEvent : serverModalErrorEvent,
            serverErrorType: error?.type,
            serverErrorMsg: error?.message,
            slug: isWizardError ? slug : router.asPath,
          });
        }
      });
    }
  }, [responseData]);

  const onClose = error => {
    setOpen(false);
    const redirectUrl = error?.redirectUrl;
    closeDialog();
    if (redirectUrl) {
      router.push(redirectUrl);
    }
  };

  const responseDisplay = (
    <div className={classes.root}>
      {errors?.map((error, index) => {
        return (
          <Alert
            severity={error?.type}
            className={classes.alert}
            // color={responseData?.responseDesc?.type || 'error'}
            classes={{ root: classes.align, icon: classes.icon }}
          >
            <Grid container alignItems="center" justifyContent="center">
              <AlertTitle>
                <Typography variant="h5" align="center" className={classes.text}>
                  {error?.title}
                </Typography>
              </AlertTitle>
              {error?.message && (
                <Typography variant="body1" align="center" className={classes.text}>
                  {sourceTextFromData(storeState, error?.message)}
                </Typography>
              )}
              {error?.footer && (
                <Typography variant="body1" align="center" className={classes.text}>
                  {sourceTextFromData(storeState, error?.footer)}
                </Typography>
              )}
              {error && <Button onClick={() => onClose(error)}>Close</Button>}
              <br />
              {error?.requestId && (
                <Grid item xs={12} align="left">
                  <Typography
                    variant="caption"
                    align="left"
                    component="p"
                    className={classes.requestId}
                    sx={{ fontSize: '10px' }}
                  >
                    Request: {error?.requestId}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Alert>
        );
      })}
    </div>
  );

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      // onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll="body"
      PaperProps={{ elevation: 0 }}
      // {classes.responseData?.responseDesc?.title || classes.error}
    >
      {responseDisplay}
    </Dialog>
  );
};

ServerErrorModal.propTypes = {
  closeDialog: PropTypes.func,
  responseData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  open: PropTypes.bool,
  alertColor: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  redirectPath: PropTypes.string,
  isMultiple: PropTypes.bool,
};

ServerErrorModal.defaultProps = {
  closeDialog: () => {},
  responseData: [],
  open: false,
  alertColor: 'error',
  redirectPath: '/',
  isMultiple: false,
};

export default ServerErrorModal;

/*
        <Button onClick={() => handleNextScreen(redirectPath)} color="primary">
          Next Screen
        </Button>
        {success && (
          <Button onClick={() => handleNextScreen(nextScreen)} color="primary">
            Next Screen
          </Button>
        )}
        */
