import React from 'react';
import { Box, Stepper, Step, StepLabel } from '@mui/material';

export function StepperComponent({ steps, activeStep, ...props }) {
  return (
    <Box sx={{ width: '100%' }} {...props}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{
          fontFamily: 'default',
        }}
      >
        {steps.map(label => (
          <Step
            key={label}
            sx={{
              '& .MuiStepLabel-root .Mui-completed': {
                color: 'primary.main',
              },
              '& .MuiStepLabel-root .Mui-active': {
                color: 'primary.main',
              },
              '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
                color: 'text.label',
                fontWeight: '500',
                fontStyle: 'normal',
                fontSize: '0.875rem',
                lineHeight: '1.25rem',
              },
              '& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel': {
                color: 'text.disabled',
                fontWeight: '400',
                fontStyle: 'normal',
                fontSize: '0.875rem',
                lineHeight: '1.25rem',
              },
            }}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
