import {
  AGENT_RESET_PASSWORD_API_REQUEST,
  AGENT_RESET_PASSWORD_API_ERROR,
  AGENT_RESET_PASSWORD_API_SUCCESS,
} from '../../../constants/authActionNames';

// import { api } from '../../../utils/variables';
import { resetPasswordApi } from '../../../apis/auth';

function agentResetPasswordError(err) {
  return {
    type: AGENT_RESET_PASSWORD_API_ERROR,
    payload: err,
  };
}

function agentResetPasswordRequest() {
  return {
    type: AGENT_RESET_PASSWORD_API_REQUEST,
    payload: true,
  };
}

function agentResetPasswordSuccess(data) {
  return {
    type: AGENT_RESET_PASSWORD_API_SUCCESS,
    payload: data,
  };
}

export function agentResetPasswordAction(_credentials) {
  const credentials = { ..._credentials };
  // credentials.apiKey = api.apiKey;
  // credentials.apiSecret = api.apiSecret;

  return (dispatch, getState) => {
    const state = getState();
    dispatch(agentResetPasswordRequest());
    return resetPasswordApi
      .agentResetPassword(state, credentials)
      .then(result => {
        dispatch(agentResetPasswordSuccess(result));
        return result;
      })
      .catch(error => {
        dispatch(agentResetPasswordError(error));
        throw error;
      });
  };
}

export default {
  agentResetPasswordAction,
};
