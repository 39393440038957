import {
  GET_INSURANCE_DETAILS_REQUEST,
  GET_INSURANCE_DETAILS_SUCCESS,
  GET_INSURANCE_DETAILS_ERROR,
} from '../../../constants/claimActionNames';
import { getInsurancesApi } from '../../../apis/portfolio';

function getInsuranceDetailsRequest() {
  return {
    type: GET_INSURANCE_DETAILS_REQUEST,
    payload: true,
  };
}

function getInsuranceDetailsSuccess(insurances) {
  return {
    type: GET_INSURANCE_DETAILS_SUCCESS,
    payload: insurances,
  };
}

function getInsuranceDetailsError(err) {
  return {
    type: GET_INSURANCE_DETAILS_ERROR,
    payload: err,
  };
}

export function getInsuranceDetailsAction(params) {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(getInsuranceDetailsRequest());
    const { getInsurances } = getInsurancesApi;
    try {
      const res = await getInsurances(state, params);
      dispatch(getInsuranceDetailsSuccess(res));
      return res;
    } catch (e) {
      dispatch(getInsuranceDetailsError(e));
      throw e;
    }
  };
}

export default { getInsuranceDetailsAction };
