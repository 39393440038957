import {
  GET_CLAIM_DETAILS_API_REQUEST,
  GET_CLAIM_DETAILS_API_SUCCESS,
  GET_CLAIM_DETAILS_API_ERROR,
} from '../../../constants/claimActionNames';

import { getClaimDetailsApi } from '../../../apis/claims';
// import { getPayloadResponse } from '../../../reducers/claims';

function getClaimDetailsRequest() {
  return {
    type: GET_CLAIM_DETAILS_API_REQUEST,
    payload: true,
  };
}

function getClaimDetailsSuccess(claims, params, lobTypes) {
  return {
    type: GET_CLAIM_DETAILS_API_SUCCESS,
    payload: claims,
    params,
    lobTypes,
  };
}

function getClaimDetailsError(err) {
  return {
    type: GET_CLAIM_DETAILS_API_ERROR,
    payload: err,
  };
}

export function getClaimDetailsAction(params) {
  // const response = getPayloadResponse();
  return (dispatch, getState) => {
    const state = getState();
    const lobTypes = state?.locations?.lobCategories;
    dispatch(getClaimDetailsRequest());
    return getClaimDetailsApi
      .getClaimDetails(state, params)
      .then(data => {
        dispatch(getClaimDetailsSuccess(data, params, lobTypes));
        return data;
      })
      .catch(error => {
        dispatch(getClaimDetailsError(error));
        throw error;
      });
  };
}

export default { getClaimDetailsAction };
