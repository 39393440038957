export const UPDATE_PENDING_CLAIMS_CURRENT_CACHE_KEY =
  'UPDATE_PENDING_CLAIMS_CURRENT_CACHE_KEY';
export const GET_PENDING_CLAIMS_API_REQUEST = 'GET_PENDING_CLAIMS_API_REQUEST';
export const GET_PENDING_CLAIMS_API_SUCCESS = 'GET_PENDING_CLAIMS_API_SUCCESS';
export const GET_PENDING_CLAIMS_API_ERROR = 'GET_PENDING_CLAIMS_API_ERROR';
export const GET_NEXT_PENDING_CLAIMS_SUCCESS = 'GET_NEXT_PENDING_CLAIMS_SUCCESS';
export const GET_PENDING_CLAIM_API_REQUEST = 'GET_PENDING_CLAIM_API_REQUEST';
export const GET_PENDING_CLAIM_API_SUCCESS = 'GET_PENDING_CLAIM_API_SUCCESS';
export const GET_PENDING_CLAIM_API_ERROR = 'GET_PENDING_CLAIM_API_ERROR';
export const GET_NEXT_PENDING_CLAIM_SUCCESS = 'GET_NEXT_PENDING_CLAIM_SUCCESS';
export const GET_PENDING_CLAIM_DETAILS_API_REQUEST =
  'GET_PENDING_CLAIM_DETAILS_API_REQUEST';
export const GET_PENDING_CLAIM_DETAILS_API_SUCCESS =
  'GET_PENDING_CLAIM_DETAILS_API_SUCCESS';
export const GET_PENDING_CLAIM_DETAILS_API_ERROR = 'GET_PENDING_CLAIM_DETAILS_API_ERROR';
export const RESET_PENDING_CLAIMS_SUCCESS = 'RESET_PENDING_CLAIMS_SUCCESS';
export const DELETE_PENDING_CLAIM_API_REQUEST = 'DELETE_PENDING_CLAIM_API_REQUEST';
export const DELETE_PENDING_CLAIM_API_SUCCESS = 'DELETE_PENDING_CLAIM_API_SUCCESS';
export const DELETE_PENDING_CLAIM_API_ERROR = 'DELETE_PENDING_CLAIM_API_ERROR';
export const RESET_PARTIALLY_FILLED_CLAIM = 'RESET_PARTIALLY_FILLED_CLAIM';
