import { portfolioButtonStyles } from '../../../styles';

export default theme => ({
  ...portfolioButtonStyles(theme),
  noTitlePadding: {
    paddingTop: theme.spacing(3),
  },
  dialogContainer: {
    maxWidth: '100%',
    boxShadow: `0 2px 10px 1px ${theme.palette.background.grid} !important`,
    // padding: theme.spacing(2),
  },
  dialogActions: {
    backgroundColor: theme.palette.background.main,
  },
  headerTitle: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
  },
  dialogContent: {
    backgroundColor: theme.palette.background.main,

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  dialogContentText: {
    color: `${theme.palette.text.primary} !important`,
    padding: `${theme.spacing(2)} !important`,
  },
});
