import { portfolioButtonStyles } from '../../../styles';

const styles = theme => ({
  ...portfolioButtonStyles(theme),
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  font: {
    color: theme.palette.text.primary,
  },
  image: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: 'auto',
    width: '30%',
  },
  p1: {
    marginTop: theme.spacing(4),
    fontWeight: `${theme.typography.fontWeightBold} !important`,
    fontSize: theme.typography.h6.fontSize,
  },
  p2: {
    marginTop: theme.spacing(2),
    fontSize: theme.typography.subtitle2.fontSize,
    // color: theme.palette.grey[500],
  },
  button: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
});
export default styles;
