import { dutchInusranceProductType } from '../../../utils/variables/index';

export const dutchMotorInfoText = {
  stpInvalid:
    'Based on your answers we’ll check your request to offer you a customized premium. Please complete your application and we’ll contact you.',
  carPersonInspection:
    'You’re insured for Third-Party Liability (WA) immediately after purchase. A vehicle inspection is required for Plus coverage (Fire/Explosion and Theft). Check your email for further instructions.',
  carInspection:
    'You’re insured for Third-Party Liability (WA) immediately after purchase. A vehicle inspection is required for Plus coverage (Fire/Explosion and Theft). Check your email for further instructions.',
  personInspection:
    'You’re insured for third-party liability (WA) immediately after purchase. An extra evaluation is needed for Plus coverage (Fire/Explosion and Theft). Check your email for further steps.',
  blocked:
    'We’re sorry, based on your answers we are unable to complete your request online. For a customized offer, click here for customer service .',
  legalCheck:
    'Based on your answers we’ll check your request to offer you a customized premium. Please complete your application and we’ll contact you.',
};

export const dutchMotorCascoInfoText = {
  stpInvalid:
    'Based on your answers we’ll check your request to offer you a customized premium. Please complete your application and we’ll contact you.',
  carPersonInspection:
    'You’re insured for Third-Party Liability (WA) immediately after purchase. A vehicle inspection is required for Casco coverage. Check your email for further instructions.',
  carInspection:
    'You’re insured for Third-Party Liability (WA) immediately after purchase. A vehicle inspection is required for Casco coverage. Check your email for further instructions.',
  personInspection:
    'You’re insured for third-party liability (WA) immediately after purchase. An extra evaluation is needed for Casco coverage. Check your email for further steps.',
  blocked:
    'We’re sorry, based on your answers we are unable to complete your request online. For a customized offer, click here for customer service .',
  legalCheck:
    'Based on your answers we’ll check your request to offer you a customized premium. Please complete your application and we’ll contact you.',
};

export const dutchInfotext = {
  stpInvalid:
    'Based on your answers we need to check your request for offering you a customized premium. Please keep entering your information and  we’ll get back to you.',
  // carPersonInspection:
  //   'Based on your answers we need to do a quick vehicle inspection. Please complete your application and\ncheck your email for further steps!',
  // carInspection:
  //   'Based on your answers we need to do a quick vehicle inspection. Please complete your application and\ncheck your email for further steps!',
  personInspection:
    'Based on your answers we’ll check your request to offer you a customized premium. Please complete your\napplication and  we’ll contact you.',
  blocked:
    'We’re sorry, based on your answers we are unable to complete your request online. For a customized\noffer, click here for customer service .',
  legalCheck:
    'Based on your answers we’ll check your request to offer you a customized premium. Please complete\nyour application and we’ll contact you.',
};

export const breakDownTemplate = {
  [dutchInusranceProductType.WA]: [
    {
      type: 'base_wa',
      description: 'Third Party Liability coverage',
      heading: 'Liability coverage (Third Party Liability)',
    },
    {
      type: 'accident_insurance_costs',
      description: 'Personal Accident Coverage',
      hideWhenZero: true,
    },
    {
      type: 'uninsured_third_party_coverage_costs',
      description: 'Uninsured Motorist Coverage Cost',
      hideWhenZero: true,
    },
    {
      type: 'administration_costs',
      description: 'Administration costs',
    },
    {
      type: 'tax',
      isDividerHidden: true,
      description: 'Insurance tax',
    },
    {
      type: 'totalPremium',
      description: 'Total premium ',
      isHighlighted: true,
    },
  ],
  [dutchInusranceProductType.WA_PLUS]: [
    {
      type: 'base_wa',
      description: 'Third Party Liability coverage',
      heading: 'Liability coverage (Third Party Liability)',
    },
    {
      type: 'base_casco',
      description: 'Fire/Explosion & Theft',
      heading: 'Liability Plus coverage (Third Party Liability Plus)',
      hideWhenZero: true,
    },
    {
      type: 'accident_insurance_costs',
      description: 'Personal Accident Coverage',
      hideWhenZero: true,
    },
    {
      type: 'uninsured_third_party_coverage_costs',
      description: 'Uninsured Motorist Coverage Cost',
      hideWhenZero: true,
    },
    {
      type: 'administration_costs',
      description: 'Administration costs',
    },
    {
      type: 'tax',
      isDividerHidden: true,
      description: 'Insurance tax',
    },
    {
      type: 'totalPremium',
      description: 'Total premium ',
      isHighlighted: true,
    },
  ],
  [dutchInusranceProductType.CASCO]: [
    {
      type: 'base_wa',
      description: 'Third Party Liability coverage',
      heading: 'Liability coverage (Third Party Liability)',
    },
    {
      type: 'base_casco',
      description: 'Own damage coverage',
      heading: 'Casco TPL (Own Damage)',
      hideWhenZero: true,
    },
    {
      type: 'accident_insurance_costs',
      description: 'Personal Accident Coverage',
    },
    {
      type: 'uninsured_third_party_coverage_costs',
      description: 'Uninsured Motorist Coverage Cost',
      hideWhenZero: true,
    },
    {
      type: 'administration_costs',
      description: 'Administration costs',
    },
    {
      type: 'tax',
      isDividerHidden: true,
      description: 'Insurance tax',
    },
    {
      type: 'totalPremium',
      description: 'Total premium ',
    },
  ],
  [dutchInusranceProductType.CASCO_1]: [
    {
      type: 'base_wa',
      description: 'Third Party Liability coverage',
      heading: 'Liability coverage (Third Party Liability)',
    },
    {
      type: 'base_casco',
      description:
        'Own Damage (Casco) coverage with 1 year replacement value and depreciation ',
      heading: 'Casco TPL (Own Damage)',
      hideWhenZero: true,
    },
    {
      type: 'accident_insurance_costs',
      description: 'Personal Accident Coverage',
    },
    {
      type: 'uninsured_third_party_coverage_costs',
      description: 'Uninsured Motorist Coverage Cost',
      hideWhenZero: true,
    },
    {
      type: 'administration_costs',
      description: 'Administration costs',
    },
    {
      type: 'tax',
      isDividerHidden: true,
      description: 'Insurance tax',
    },
    {
      type: 'totalPremium',
      description: 'Total premium ',
    },
  ],
  [dutchInusranceProductType.CASCO_2]: [
    {
      type: 'base_wa',
      description: 'Third Party Liability coverage',
      heading: 'Liability coverage (Third Party Liability)',
    },
    {
      type: 'base_casco',
      description:
        'Own Damage (Casco) coverage with 2 year replacement value and depreciation ',
      heading: 'Casco TPL (Own Damage)',
      hideWhenZero: true,
    },
    {
      type: 'accident_insurance_costs',
      description: 'Personal Accident Coverage',
    },
    {
      type: 'uninsured_third_party_coverage_costs',
      description: 'Uninsured Motorist Coverage Cost',
      hideWhenZero: true,
    },
    {
      type: 'administration_costs',
      description: 'Administration costs',
    },
    {
      type: 'tax',
      isDividerHidden: true,
      description: 'Insurance tax',
    },
    {
      type: 'totalPremium',
      description: 'Total premium ',
    },
  ],
  [dutchInusranceProductType.CASCO_3]: [
    {
      type: 'base_wa',
      description: 'Third Party Liability coverage',
      heading: 'Liability coverage (Third Party Liability)',
    },
    {
      type: 'base_casco',
      description:
        'Own Damage (Casco) coverage with 3 year replacement value and depreciation ',
      heading: 'Casco TPL (Own Damage)',
      hideWhenZero: true,
    },
    {
      type: 'accident_insurance_costs',
      description: 'Personal Accident Coverage',
    },
    {
      type: 'uninsured_third_party_coverage_costs',
      description: 'Uninsured Motorist Coverage Cost',
      hideWhenZero: true,
    },
    {
      type: 'administration_costs',
      description: 'Administration costs',
    },
    {
      type: 'tax',
      isDividerHidden: true,
      description: 'Insurance tax',
    },
    {
      type: 'totalPremium',
      description: 'Total premium ',
    },
  ],
  [dutchInusranceProductType.FIFTY_PLUS]: [
    {
      type: 'base_wa',
      description: 'Third Party Liability coverage',
      heading: 'Liability coverage (Third Party Liability)',
    },
    {
      type: 'base_casco',
      description: 'Own damage coverage',
      heading: 'Casco TPL (Own Damage)',
      hideWhenZero: true,
    },
    {
      type: 'administration_costs',
      description: 'Administration costs',
    },
    {
      type: 'tax',
      isDividerHidden: true,
      description: 'Insurance tax',
    },
    {
      type: 'totalPremium',
      description: 'Total premium ',
    },
  ],
  [dutchInusranceProductType.PRIVATE_HOME]: [
    {
      heading: 'Insured amount',
      type: 'insurance',
      description: 'Premium',
    },
    {
      heading: 'Extras',
      type: 'freeAccident',
      description: 'Accident coverage (see conditions)',
    },
    {
      isDividerHidden: true,
      type: 'freeLiability',
      description: 'Liability coverage (see conditions)',
    },
    {
      type: 'administration_costs',
      description: 'Administration costs',
    },
    {
      isDividerHidden: true,
      type: 'tax',
      description: 'Insurance tax',
    },
    {
      type: 'totalPremium',
      description: 'Total premium ',
    },
  ],
  [dutchInusranceProductType.PRIVATE_BASIC_HOME]: [
    {
      heading: 'Insured amount',
      type: 'insurance',
      description: 'Premium',
    },
    // {
    //   heading: 'Extras',
    //   type: 'freeAccident',
    //   description: 'Accident coverage (see conditions)',
    // },
    {
      heading: 'Extras',
      isDividerHidden: false,
      type: 'freeLiability',
      description: 'Liability coverage (see conditions)',
    },
    {
      type: 'administration_costs',
      description: 'Administration costs',
    },
    {
      isDividerHidden: true,
      type: 'tax',
      description: 'Insurance tax',
    },
    {
      type: 'totalPremium',
      description: 'Total premium ',
    },
  ],
  [dutchInusranceProductType.LIABILITY]: [
    {
      heading: 'Insurance amount',
      type: 'insurance',
      description: 'Premium',
    },
    {
      type: 'administration_costs',
      description: 'Administration costs',
    },
    {
      isDividerHidden: true,
      type: 'tax',
      description: 'Insurance tax',
    },
    {
      type: 'totalPremium',
      description: 'Total premium ',
    },
  ],
};
