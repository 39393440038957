import { crmPostCall } from '../../../axios';
import { helpCenterApi } from '../../../../utils/functions/helpCenterApis';
import { GETTICKETHISTORYURL, SERVERERROR } from '../../constants';

export const getTicketHistoryUrl = helpCenterApi(GETTICKETHISTORYURL);

const getTicketHistory = (state, body) => {
  const url = getTicketHistoryUrl;
  if (url) {
    return crmPostCall(state, url, body);
  }
  return Promise.reject(new Error(SERVERERROR));
};

export default { getTicketHistory };
