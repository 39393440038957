import { CHANGE_REDIRECT_SUCCESS } from '../../../constants/miscActionNames';

function changeRedirectSuccess(prod) {
  return {
    type: CHANGE_REDIRECT_SUCCESS,
    payload: prod,
  };
}

export function changeRedirectAction(data) {
  return changeRedirectSuccess(data);
}

export default {
  changeRedirectAction,
};
