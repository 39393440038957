import { validateAccess, transformExpansionItems } from './misc';

export const emptyComponent = (
  component,
  isAuthenticated,
  customerVerified,
  makeNamesDistinct,
  makeDistinctSuffix,
) => {
  let newName = component?.name;
  const emptyWizardPayload = {};
  if (makeNamesDistinct) {
    newName = `${newName}${makeDistinctSuffix}`;
  }
  emptyWizardPayload[newName] = null;
  emptyWizardPayload.index = null;
  //
  if (component?.savedKeyName) {
    const savedKeyName = component?.savedKeyName;
    emptyWizardPayload[savedKeyName] = null;
  }
  if (component?.storeKey && component?.slug === 'CardItemsList') {
    const storeKey = component?.storeKey?.split('.').pop();
    emptyWizardPayload[storeKey] = null;
  }
  if (component?.isPayloadStored) {
    emptyWizardPayload[`${newName}Payload`] = null;
  }
  if (component?.slug === 'DobInput' && component.transformToAge) {
    emptyWizardPayload[`${newName}Age`] = null;
  }
  if (
    (component?.slug === 'ValueInput' && component.type === 'email') ||
    component?.slug === 'PhoneInput'
  ) {
    emptyWizardPayload[`pre_${newName}`] = null;
  }
  if (component?.slug === 'RegionCountryChoice') {
    emptyWizardPayload[`${newName}_region`] = undefined;
    emptyWizardPayload[`${newName}_country`] = undefined;
    emptyWizardPayload[`${newName}_country_fullName`] = undefined;
    emptyWizardPayload[`${newName}_region_fullName`] = undefined;
  }

  return emptyWizardPayload;
};

/*
&&
      validateAccess(
        component.phoneVerificationRequired,
        isAuthenticated,
        customerVerified,
      )

      &&
      validateAccess(
        component.emailVerificationRequired,
        isAuthenticated,
        customerVerified,
      )
      */

export const resetWizardPayload = (
  filterForms,
  isAuthenticated,
  customerVerified,
  makeDistinctSuffix,
) => {
  let emptyWizardPayload = {};
  const resetData = filterForms.map(formWrapper => {
    const formComponents = formWrapper?.form?.components || [];
    const makeNamesDistinct = formWrapper?.form?.makeNamesDistinct;
    formComponents.map(component => {
      if (
        component?.name
        // &&
        // newName !== 'profilePrimaryPhone' &&
        // newName !== 'profilePrimaryEmail' &&
        // newName !== 'email' &&
        // newName !== 'un_email' &&
        // newName !== 'phone' &&
        // newName !== 'un_phone'
        // newName !== 'lastName' &&
        // newName !== 'firstName' &&
        // newName !== 'dob'
      ) {
        const newEmptyPayload = emptyComponent(
          component,
          isAuthenticated,
          customerVerified,
          makeNamesDistinct,
          makeDistinctSuffix,
        );
        emptyWizardPayload = { ...emptyWizardPayload, ...newEmptyPayload };
        const useSubForm = component?.useSubForm;
        // // console.log('useSubForm: ', useSubForm);
        if (useSubForm) {
          const initialSubForm = component?.subForm;
          const newComponent = { ...component };
          // // console.log('newComponent: ', newComponent);
          newComponent.items = newComponent?.items || newComponent?.options;
          const tempItems = transformExpansionItems(newComponent);
          // // console.log('tempItems: ', tempItems);

          tempItems.forEach(item => {
            const subForm = item?.optionSubForm || initialSubForm;
            // // console.log('subForm: ', subForm);
            if (subForm) {
              const {
                components: subComponents,
                makeNamesDistinct: subMakeNamesDistinct,
              } = subForm;
              // // console.log('subComponents: ', subComponents);
              if (subComponents && subComponents.length > 0) {
                subComponents.forEach(subComponent => {
                  const newSubEmptyPayload = emptyComponent(
                    subComponent,
                    isAuthenticated,
                    customerVerified,
                    subMakeNamesDistinct,
                    makeDistinctSuffix,
                  );
                  emptyWizardPayload = { ...emptyWizardPayload, ...newSubEmptyPayload };
                });
              }
            }
          });
        }
      }
    });
  });

  return emptyWizardPayload;
};
