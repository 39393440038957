import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Menu,
  Container,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  GTranslate as GTranslateIcon,
} from '@mui/icons-material';
// import { localeData } from '../../../translations';
import { updateYourLanguageAction } from '../../../actions/mainLayout/updateYourLanguage';
import { defaultIntlId } from '../../../utils/variables';

const useStyles = makeStyles(theme => ({
  formControl: {
    // margin: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  button: {
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

const LanguageSwitcher = ({ type, ...props }) => {
  // You could use redux to get the locale or get it from the url.
  const classes = useStyles();
  const locale = useSelector(state => state.locations.language);
  // // console.log('locale: ', locale);
  const languages = useSelector(state => state.locations.languages);
  const yourLocation = useSelector(state => state.locations.yourLocation);
  const cookies = yourLocation?.cookies;
  const cookiePolicy = yourLocation?.cookiePolicyId || null;
  const hasCookiePolicy = !!cookiePolicy;
  // // console.log('languages: ', languages);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const intl = useIntl();
  // const [locale, setLocale] = React.useState(initialLocale);
  // // console.log('initialLocale: ', locale);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const changeLanguage = newLocale => {
    document.documentElement.lang = newLocale;
    // setLocale(newLocale);
    dispatch(updateYourLanguageAction(newLocale, cookies, hasCookiePolicy));
  };

  const handleMenuItemClick = (event, value) => {
    changeLanguage(value);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentLanguage = languages && languages?.find(x => x.code === locale);

  if (type === 'menu') {
    return (
      <>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          startIcon={<GTranslateIcon />}
          endIcon={<ExpandMoreIcon />}
          className={classes.button}
        >
          {currentLanguage?.name}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {languages &&
            languages?.map(_language => {
              const language = { ..._language };

              return (
                <MenuItem
                  key={language?.code}
                  selected={language?.code === currentLanguage?.code}
                  onClick={event => handleMenuItemClick(event, language?.code)}
                >
                  {`${language?.name} - ${language?.code}`}
                </MenuItem>
              );
            })}
        </Menu>
      </>
    );
  }

  return (
    <Container>
      <FormControl className={classes.formControl} fullWidth variant="outlined">
        <InputLabel id="demo-simple-select-label">Language</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id={props.id}
          name="locale"
          value={locale}
          fullWidth
          onChange={event => changeLanguage(event.target.value)}
          label="Language"
        >
          {languages &&
            languages.map(language => {
              return (
                <MenuItem
                  key={language?.code}
                  value={language?.code}
                  selected={language?.code === currentLanguage?.code}
                >
                  {`${language?.name} - ${language?.code}`}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Container>
  );
};

LanguageSwitcher.defaultProps = {
  type: 'menu',
};

LanguageSwitcher.propTypes = {
  type: PropTypes.oneOf(['menu', 'select']),
};

export default LanguageSwitcher;

/*
import { createIntl, createIntlCache } from 'react-intl';
export const cache = createIntlCache();

  const intl = createIntl({ locale, messages: localeData[locale] }, cache);
Object.keys(localeData)
{Object.keys(localeData).map(localeValue => (
    */

/*
import { createIntl, createIntlCache } from 'react-intl';
export const cache = createIntlCache();
  
You can use this variable in other files even after reassigning it.
// eslint-disable-next-line import/no-mutable-exports
export let intl = createIntl(
  { locale: initialLocale, messages: localeData[initialLocale] },
  cache,
);
// eslint-disable-next-line import/no-mutable-exports
export let fmt = intl.formatMessage;

    intl = createIntl({ locale: newLocale, messages: localeData[newLocale] }, cache);
    fmt = intl.formatMessage;
    */
