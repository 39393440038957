import { APP_READY_STATE } from '../../../constants/tempActionNames';

function appReadyStateSuccess(prod) {
  return {
    type: APP_READY_STATE,
    payload: prod,
  };
}

export function appReadyStateAction(data) {
  return appReadyStateSuccess(data);
}

export default {
  appReadyStateAction,
};
