import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import { useIntl } from 'react-intl';
// import Popover from '@mui/material/Popover';
import {
  Box,
  Popper,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  Zoom,
  ClickAwayListener,
  Paper,
} from '@mui/material';
import { differenceInDays } from 'date-fns';
import { domain, countryVerificationExpireTimeInDays } from '../../../utils/variables';
import {
  dateToIsoString,
  isoStringToDate,
  sourceTextFromData,
} from '../../../utils/functions';
import { useRouter } from '../../../hooks';
import { countryVerificationStorage } from '../../../storage';
import sanitizeText from '../../../utils/functions/sanitizeText';
import messages from './message';
import routes from '../../../utils/variables/routes';

const styles = theme => ({
  popover: {
    pointerEvents: 'none',
  },
  popper: {
    zIndex: 100,
    [theme.breakpoints.down('sm')]: {
      top: '20% !important',
      left: '-5% !important',
    },
  },
  paper: {
    pointerEvents: 'auto',
    padding: theme.spacing.unit,
  },
  popoverClass: {
    marginTop: '10px',
    padding: theme.spacing(2),
    width: '40vw',
    textAlign: 'left',
    [theme.breakpoints.up('xl')]: {
      width: '30vw',
    },
    // border: '1px solid black',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '-15px',
      right: '17px',
      borderBottom: `10px solid ${theme.palette.text.primary}`,
      borderRight: '10px solid transparent',
      borderLeft: '10px solid transparent',
      borderTop: '10px solid transparent',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90vw',

      '&:after': {
        top: '-5%',
        right: '26%',
      },
    },
  },
  box: {
    // height: 100,
    display: 'flex',
    flexDirection: 'column',
    // border: '1px solid black',
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  spreadBox: {
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  button: {
    width: '100%',
    margin: `${theme.spacing(0.5)} !important`,
    textTransform: 'none',
  },
  checkbox: {
    color: `${theme.palette.primary.main} !important`,
    '& .MuiCheckbox-root': {
      color: `${theme.palette.primary.main} !important`,
    },
  },
});
const useStyles = makeStyles(styles);
let initialCount = 0;
const checkValue = 'dontRemind';
// let tempToday = null;
let lastpath = null;
const maxCountReached = 5;

const CountryChecker = ({ myRef, countryChange }) => {
  // console.log('ref: ', myRef);
  const classes = useStyles();
  const router = useRouter();
  const [anchorEl, setAnchorEl] = useState(null);
  const [ipLocation, setIPLocation] = useState(null);
  const [checked, setChecked] = useState(false);
  const intl = useIntl();
  const territories = useSelector(state => state.locations.allLocations);
  const yourLocation = useSelector(state => state.locations.yourLocation);
  const alpha2 = yourLocation?.alpha2;
  const pathname = router?.pathname;
  const query = router?.query;
  const { secretip } = query || {};

  const validRoute =
    pathname === routes.home ||
    pathname === routes.index ||
    pathname === routes.wizards.quote ||
    pathname === routes.wizards.claim ||
    pathname?.includes(routes.products.index) ||
    pathname?.includes(routes.auth.index) ||
    pathname === routes.portfolio.portfolioSummary;

  const handlePopoverOpen = () => {
    setAnchorEl(myRef?.current);
  };

  const handlePopoverClose = () => {
    initialCount = maxCountReached;
    setAnchorEl(null);
  };

  const handleNoClick = () => {
    initialCount = maxCountReached;
    setAnchorEl(null);
    const today = dateToIsoString(new Date());
    const newValue = checked ? `${checkValue}_${alpha2}` : today;
    countryVerificationStorage.setCountryVerification(newValue);
  };

  const handleYesClick = () => {
    initialCount = maxCountReached;
    const newAlpha2 = ipLocation?.alpha2;
    countryChange(newAlpha2);
    const today = dateToIsoString(new Date());
    const newValue = checked ? `${checkValue}_${alpha2}` : today;
    countryVerificationStorage.setCountryVerification(newValue);
  };

  const handleCheckChange = event => {
    setChecked(event.target.checked);
  };

  const lastValue = countryVerificationStorage.getCountryVerification();
  let lastPromptDate = null;
  if (checkValue !== lastValue) {
    lastPromptDate = isoStringToDate(lastValue);
  }
  const newCheckValue = `${checkValue}_${alpha2}`;

  const dontRemind = lastValue === newCheckValue;

  const daysSinceLastPrompt = differenceInDays(new Date(), lastPromptDate);
  const timeExpired =
    Number.isNaN(daysSinceLastPrompt) ||
    daysSinceLastPrompt >= countryVerificationExpireTimeInDays;

  const open =
    Boolean(anchorEl) &&
    ipLocation &&
    validRoute &&
    timeExpired &&
    initialCount < maxCountReached &&
    !dontRemind;

  const id = open ? 'simple-popover' : undefined;
  // onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}
  // console.log('open: ', open);
  // console.log('initialCount: ', initialCount);
  // console.log('dontRemind: ', dontRemind);
  // console.log('ipLocation: ', ipLocation);
  useEffect(() => {
    if (myRef && initialCount < maxCountReached) {
      let url = `findme`;
      if (secretip) {
        url += `?ip=${secretip}`;
      }
      axios.get(`${domain.strapi}/${url}`).then(response => {
        // console.log('findme');

        const newAlpha2 = response?.data?.payload?.country?.toUpperCase();
        const newAlphaWithRegion = `${newAlpha2}-${response?.data?.payload?.region?.toUpperCase() ||
          ''}`;
        const tempLocation = territories?.find(
          x => x?.alpha2 === newAlpha2 || x?.alpha2?.includes(newAlphaWithRegion),
        );
        if (tempLocation) {
          const countryCode = tempLocation?.alpha2;
          setIPLocation(tempLocation);
          if (countryCode && countryCode !== alpha2 && myRef) {
            handlePopoverOpen();
            initialCount++;

            // tempToday = dateToIsoString(new Date());
          }
        }
      });
    }
  }, [myRef]);

  useEffect(() => {
    if (
      lastValue &&
      lastValue.split('_').length > 1 &&
      lastValue.split('_')[1] !== alpha2
    ) {
      countryVerificationStorage.removeCountryVerification();
      setTimeout(() => {
        initialCount = 0;
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (lastpath) {
      const newIPLocation = { ...ipLocation };
      setIPLocation(newIPLocation);
    }
    lastpath = pathname;
  }, [pathname]);

  const tempHtml = intl.formatMessage(messages.line);
  const state = {
    ipLocationName: ipLocation?.name,
    yourLocationName: yourLocation?.name,
  };
  const newHtml = sourceTextFromData(state, tempHtml);
  // console.log('newHtml: ', newHtml);
  const noMessage = intl.formatMessage(messages.no);
  const newNoMessage = sourceTextFromData(state, noMessage);

  const yesMessage = intl.formatMessage(messages.yes);
  const newYesMessage = sourceTextFromData(state, yesMessage);

  return (
    <div>
      <ClickAwayListener onClickAway={handlePopoverClose}>
        <Popper
          className={classes.popper}
          open={open}
          anchorEl={anchorEl || undefined}
          placement="bottom-start"
          transition
          onClose={handlePopoverClose}
          disablePortal
          modifiers={[
            {
              name: 'flip',
              options: {
                padding: 5,
              },
            },
          ]}
        >
          {({ TransitionProps }) => (
            <Zoom {...TransitionProps} timeout={1000} in={open}>
              <Paper className={classes.popoverClass}>
                <div
                  style={{ textAlign: 'justify' }}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeText(newHtml),
                  }}
                />

                <Box
                  component="span"
                  m={1}
                  className={`${classes.spreadBox} ${classes.box}`}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    // style={{ height: 40 }}

                    onClick={handleNoClick}
                    className={classes.button}
                  >
                    {newNoMessage}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    // style={{ height: 40 }}
                    onClick={handleYesClick}
                    className={classes.button}
                  >
                    {newYesMessage}
                  </Button>
                </Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleCheckChange}
                      classes={{ root: classes.checkbox }}
                    />
                  }
                  sx={{ textAlign: 'left' }}
                  label={intl.formatMessage(messages.checkline)}
                />
              </Paper>
            </Zoom>
          )}
        </Popper>
      </ClickAwayListener>
    </div>
  );
};

export default CountryChecker;

/*
  <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
      >
        Hover with a Popover.
          </Typography> 
        <Button
        aria-describedby={id}
        variant="contained"
        onClick={open ? handlePopoverClose : handlePopoverOpen}
      >
        Open Popover
          </Button>
        
      <Popover
        id={id}
        placement="bottom"
        open={open}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
      animation={PopoverAnimationVertical}
      >
        {' '}
        <Typography>I use Popover.</Typography>
      </Popover>
      */
