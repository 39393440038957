import React from 'react';
import classnames from 'classnames';
import { Grid, Typography, Card, Link } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { makeStyles } from '@mui/styles';
import styles from './style';

const useStyles = makeStyles(styles);

const FatumDownloadButton = ({ title, file, date, fileType, downloadLink }) => {
  const classes = useStyles();
  const handleQuickLinks = data => {
    if (data) {
      window.location.href = data;
    }
  };
  return (
    <Link onClick={() => handleQuickLinks(downloadLink)} target="_blank">
      <Card
        sx={{ width: '100%' }}
        className={classnames(classes.customCard, classes.downloadCard)}
      >
        <Grid container spacing={2} sx={{ padding: '10px' }}>
          <Grid item xs={10}>
            <Typography
              className={classnames(classes.quoteDetails, classes.label)}
              align="left"
            >
              {title && title}
              <Typography align="left">{fileType}</Typography>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <FileDownloadIcon className={classnames(classes.fileDownloadIcon)} />
          </Grid>
        </Grid>
      </Card>
    </Link>
  );
};

export default FatumDownloadButton;
