import {
  GET_FATUM_SESSION_ID_API_REQUEST,
  GET_FATUM_SESSION_ID_API_ERROR,
  GET_FATUM_SESSION_ID_API_SUCCESS,
} from '../../../constants/authActionNames';

// import { api } from '../../../utils/variables';
import { getFatumRedirectApi } from '../../../apis/auth';

function getFatumSessionIdError(err) {
  return {
    type: GET_FATUM_SESSION_ID_API_ERROR,
    payload: err,
  };
}

function getFatumSessionIdRequest() {
  return {
    type: GET_FATUM_SESSION_ID_API_REQUEST,
    payload: true,
  };
}

function getFatumSessionIdSuccess(data) {
  return {
    type: GET_FATUM_SESSION_ID_API_SUCCESS,
    payload: data,
  };
}

export function getFatumSessionIdAction() {
  return (dispatch, getState) => {
    const state = getState();
    const selectedClient = state?.auth?.selectedClient;

    dispatch(getFatumSessionIdRequest());
    return getFatumRedirectApi
      .getSessionId(state, {
        customerMasterId: selectedClient ? selectedClient?.masterId : null,
        customerC360Id: selectedClient ? selectedClient?.c360Id : null,
      })
      .then(result => {
        dispatch(getFatumSessionIdSuccess(result));
        return result;
      })
      .catch(error => {
        dispatch(getFatumSessionIdError(error));
        throw error;
      });
  };
}

export default {
  getFatumSessionIdAction,
};
