import React from 'react';
import classnames from 'classnames';
import { Card, Grid, Skeleton, Icon, Typography, Button, Box } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { LobIcon } from '../../misc';

const CalculatorCard = ({
  classes,
  icon,
  materialIcon,
  color,
  loading,
  finalTitle,
  isRequest,
  finalDescription,
  handleMoreInfoOpen,
  newAction,
  handleRequest,
  defaultSelectButtonText = 'Calculate Now',
}) => {
  return (
    <Card
      raised
      className={classnames(
        classes.root,
        classes.card,
        classes.calculatorCard,
        classes.defaultHover,
      )}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.gridPadding}
      >
        {(icon || materialIcon) && (
          <Grid item xs={12} align="center">
            <Box>
              {loading ? (
                <Skeleton variant="rect" className={classes.icon} />
              ) : (
                <LobIcon icon={icon} materialIcon={materialIcon} color={color} />
              )}
            </Box>
          </Grid>
        )}

        <Grid item sm={12} align="center" sx={{ my: 1 }}>
          {loading ? (
            <Skeleton
              animation="wave"
              height={20}
              width="100%"
              style={{ marginBottom: 6 }}
            />
          ) : (
            <Typography align="center" className={classnames(classes.title)}>
              {finalTitle}
            </Typography>
          )}
          {loading ? (
            <>
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6 }}
              />
              <Skeleton
                animation="wave"
                height={20}
                width="100%"
                style={{ marginBottom: 6 }}
              />
            </>
          ) : (
            <Typography
              align="center"
              variant="body1"
              className={classnames(classes.description)}
            >
              {finalDescription}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} align="center">
          <Grid container spacing={1} sx={{ pb: 2 }}>
            {newAction?.hasMoreInfo && (
              <Grid item xs={12} align="center">
                {loading ? (
                  <Skeleton variant="text" animation="wave" height={40} width="50%" />
                ) : (
                  <Button
                    onClick={handleMoreInfoOpen}
                    variant="text"
                    color="primary"
                    size="small"
                  >
                    More Info
                  </Button>
                )}
              </Grid>
            )}
            <Grid item xs={12} align="center">
              {loading ? (
                <Skeleton variant="text" animation="wave" height={40} width="50%" />
              ) : (
                <Button
                  onClick={handleRequest}
                  className={classes.primaryButton}
                  size="medium"
                >
                  {newAction?.selectButtonText || defaultSelectButtonText}
                  <ArrowForwardIcon className={classes.textIcon} />
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CalculatorCard;
