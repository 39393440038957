import { gql } from 'graphql-request';

const query = gql`
  query GetSurveyWizard($slug: String) {
    c360SurveyEvents(where: { slug: $slug }) {
      title
      slug
      description
      content
      type
      embedType
      renderType
      width {
        sm
        md
        lg
      }
      height {
        sm
        md
        lg
      }
      absolutePosition
      buttonText
      buttonVariant
      buttonColor
      buttonSize
      headingText
      buttonStartIcon
      headingVariant
      survey {
        title
        description
        surveyVariables {
          myVariable {
            title
            variables {
              key
              reducerKey
              staticValue
            }
          }
        }
        surveyId {
          dev
          staging
          prod
        }
        surveyQRCode {
          dev {
            url
          }
          staging {
            url
          }
          prod {
            url
          }
        }
        surveyLink {
          dev
          staging
          prod
        }
      }
      lob {
        title
        lob
        icon
        materialIcon
      }
      company {
        title
        title_en
        title_nl
        slug
        abbrev
      }
    }
  }
`;

export default query;
