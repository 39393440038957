import React from 'react';
// import PropTypes from 'prop-types';
import { InputAdornment, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

import styles from './style';

const useStyles = makeStyles(styles);

const PasswordInput = props => {
  const classes = useStyles();
  const { colorValue, changeColor, name, ...rest } = props;
  // // console.log('colorValue: ', colorValue);
  // const [color, setColor] = useState(colorValue);

  // // console.log('colorInput: ', color);
  const updateColor = e => {
    const newValue = e.target.value;
    // setColor(newValue);
    changeColor(newValue, name);
  };

  return (
    <TextField
      name={name}
      {...rest}
      onChange={updateColor}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <input
              className={classes.colorPicker}
              type="color"
              value={colorValue}
              onChange={updateColor}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordInput;
