import { routerPatchCall } from '../../axios';

const updateReferralDetails = (state, params, referralId) => {
  const url = `/auth/referrals/${referralId}`;
  return routerPatchCall(state, url, params);
};

export default {
  updateReferralDetails,
};
