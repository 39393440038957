import { UPDATE_YOUR_LANGUAGE_SUCCESS } from '../../../constants/mainLayout/locationActionNames';

export function updateYourLanguageAction(newLanguage, cookies, hasCookiePolicy) {
  // const apiEndpoint = {}; // ...api };

  return {
    type: UPDATE_YOUR_LANGUAGE_SUCCESS,
    payload: newLanguage,
    cookies,
    hasCookiePolicy,
    // params: newLanguage,
  };
  // return (dispatch, getState) => {
  // const state = getState();
  // dispatch(updateYourLanguageSuccess(newLanguage));

  /* dispatch(updateYourLanguageRequest());
    return updateYourLanguageApi
      .updateYourLanguage(state, apiEndpoint)
      .then(data => {
        // Update the app state with the results of the API call.

        dispatch(updateYourLanguageSuccess(data, newLanguage));
        return data;
      })
      .catch(error => {
        // dispatch(updateYourLanguageError(error));
        dispatch(updateYourLanguageSuccess(error, newLanguage));
        throw error;
      }); */
  // };
}

export default { updateYourLanguageAction };
