import {
  GET_INDIVIDUAL_RECEIPT_API_REQUEST,
  GET_INDIVIDUAL_RECEIPT_API_SUCCESS,
  GET_INDIVIDUAL_RECEIPT_API_ERROR,
  GET_INDIVIDUAL_RECEIPT_CANCEL,
} from '../../../constants/receiptActionNames';

import { getIndividualReceiptApi } from '../../../apis/receipts';

function getIndividualReceiptRequest() {
  return {
    type: GET_INDIVIDUAL_RECEIPT_API_REQUEST,
    payload: true,
  };
}
function getIndividualReceiptSuccess(individualReceipt, params) {
  return {
    type: GET_INDIVIDUAL_RECEIPT_API_SUCCESS,
    payload: individualReceipt,
    params,
  };
}

function getIndividualReceiptError(err) {
  return {
    type: GET_INDIVIDUAL_RECEIPT_API_ERROR,
    payload: err,
  };
}

function cancelReceipt() {
  return {
    type: GET_INDIVIDUAL_RECEIPT_CANCEL,
    payload: false,
  };
}

export function getIndividualReceiptAction(params) {
  return (dispatch, getState) => {
    const state = getState();
    // console.log('Params are', params, state);
    dispatch(getIndividualReceiptRequest());
    return getIndividualReceiptApi
      .getIndividualReceipt(state, params)
      .then(data => {
        dispatch(getIndividualReceiptSuccess(data, params));
        return data;
      })
      .catch(error => {
        dispatch(getIndividualReceiptError(error));
        throw error;
      });
  };
}

export function cancelReceiptAction() {
  return dispatch => {
    dispatch(cancelReceipt());
  };
}
