// function to get stepIndex
export const getStepIndex = (wizard, response) => {
  const { stage } = response || {};
  const forms = wizard?.forms || [];
  const stepIndex = forms?.find(x => x?.key === stage)?.positionNo;

  return stepIndex;
};

export default { getStepIndex };
