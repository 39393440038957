import { useSelector } from 'react-redux';
import { domain } from '../../../../utils/variables';

const getAllHelpArticles = async params => {
  const apiURL = `${domain?.strapi}/gg-help-center-articles?territories.alpha2=${params.territory}`;
  const res = await fetch(apiURL);
  const json = await res.json();
  return json;
};
export default { getAllHelpArticles };
