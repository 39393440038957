import { parseBool } from './boolManipulation';
import {
  userThemeSelection,
  userLanguageSelection,
  userCountrySelection,
  googleAnalyticsPageViews,
  googleAds,
  downloadAppModalLastSeen,
  userTemplateSection,
} from '../variables/cookies';
import { loadGoogleTagManager, updateDataLayer } from './googleTagManager';
import {
  acceptAllConsentValue,
  acceptCustomConsentValue,
  appThemeNameStorage,
  languageStorage,
  alpha2Storage,
  iosInstallPromptedAtStorage,
  webInstallPromptedAtStorage,
  consentAcceptExpireTimeHrs,
  consentRejectExpireTimeHrs,
  consentKey,
  customConsentKey,
  userSettingsExpireTimeHrs,
  templateStorage,
} from '../variables';
import Cookies from '../../cookies/cookies';

const activateCookies = (
  cookies = [],
  consentValue,
  _acceptCookieCategories,
  cookieValues,
  additionalSettings = '',
  dimensions,
  hasCookiePolicy = false,
) => {
  // console.log('consentValue: ', consentValue);
  const result = {};
  const categorySlugs =
    consentValue === acceptCustomConsentValue ||
    cookieValues?.consent === acceptCustomConsentValue
      ? _acceptCookieCategories
      : null;
  const activeCookies = cookies?.filter(cookie =>
    categorySlugs?.includes(cookie?.categorySlug),
  );
  // console.log('_acceptCookieCategories: ', _acceptCookieCategories);
  // console.log('categorySlugs: ', categorySlugs);
  // /console.log('cookies: ', cookies);
  // console.log('activeCookies: ', activeCookies);
  // console.log('categorySlugs: ', categorySlugs);
  // console.log('activeCookies: ', activeCookies);
  // console.log('consentValue: ', consentValue);
  // console.log('cookieValues: ', cookieValues);
  // console.log('cookieFeatureDisabled: ', cookieFeatureDisabled);
  // console.log('hasCookiePolicy: ', hasCookiePolicy);
  // console.log('acceptAllConsentValue: ', acceptAllConsentValue);
  const allCookiesAreActive =
    consentValue === acceptAllConsentValue ||
    cookieValues?.consent === acceptAllConsentValue;

  const hasAllowedGoogleAnalytics =
    allCookiesAreActive ||
    activeCookies.find(cookie => cookie?.cookieId === googleAnalyticsPageViews);
  const hasAllowedGoogleAds =
    allCookiesAreActive || activeCookies.find(cookie => cookie?.cookieId === googleAds);

  const dataLayerObj = {
    ...cookieValues,
    ...dimensions,
    hasAllowedGoogleAds,
    hasAllowedGoogleAnalytics,
  };
  // console.log('consentValue: ', consentValue);
  if (!cookies || cookies.length === 0) {
    result.cookies = [];
    // return result;
  }
  // console.log('allCookiesAreActive: ', allCookiesAreActive);

  if (cookieValues?.theme) {
    // console.log('cookieValues: ', cookieValues);
    // console.log('thisCookie: ', thisCookie);
    // console.log('allCookiesAreActive: ', allCookiesAreActive);
    if (
      allCookiesAreActive ||
      activeCookies.find(cookie => cookie?.cookieId === userThemeSelection)
    ) {
      // themeNameStorage.setAppThemeName(initialThemeName);
      // console.log('im changing theme', cookieValues?.theme);
      Cookies.setCookie(
        appThemeNameStorage,
        cookieValues?.theme,
        userSettingsExpireTimeHrs,
        additionalSettings,
      );
      result.theme = true;
      // console.log('set appThemeNameStorage: ', appThemeNameStorage);
      localStorage.removeItem(appThemeNameStorage);
    } else {
      Cookies.deleteCookie(appThemeNameStorage);
      // console.log('delete appThemeNameStorage: ', appThemeNameStorage);
    }
  }
  if (cookieValues?.language) {
    // console.log('cookieValues: ', cookieValues);
    if (
      allCookiesAreActive ||
      activeCookies.find(cookie => cookie?.cookieId === userLanguageSelection)
    ) {
      // console.log('activeCookies: ', activeCookies);
      // languageStorage.setLanguage(language);
      result.language = true;
      Cookies.setCookie(
        languageStorage,
        cookieValues?.language,
        userSettingsExpireTimeHrs,
        additionalSettings,
      );
      localStorage.removeItem(languageStorage);
    } else {
      Cookies.deleteCookie(languageStorage);
      // console.log('deleteLanguageCookie');
    }
  }
  if (cookieValues?.userTemplateSection) {
    if (
      allCookiesAreActive ||
      activeCookies.find(cookie => cookie?.cookieId === userTemplateSection)
    ) {
      // console.log('activeCookies: ', activeCookies);
      // languageStorage.setLanguage(language);
      result.userTemplateSection = true;
      Cookies.setCookie(
        templateStorage,
        cookieValues?.userTemplateSection,
        userSettingsExpireTimeHrs,
        additionalSettings,
      );
    } else {
      Cookies.deleteCookie(templateStorage);
      // console.log('deleteLanguageCookie');
    }
  }
  if (cookieValues?.alpha2) {
    if (
      allCookiesAreActive ||
      activeCookies.find(cookie => cookie?.cookieId === userCountrySelection)
    ) {
      // alpha2Storage.setAlpha2(alpha2);
      result.alpha2 = true;
      Cookies.setCookie(
        alpha2Storage,
        cookieValues?.alpha2,
        userSettingsExpireTimeHrs,
        additionalSettings,
      );
      // console.log('set alpha2Storage: ', alpha2Storage);
      localStorage.removeItem(alpha2Storage);
    } else {
      Cookies.deleteCookie(alpha2Storage);
      // console.log('delete alpha2Storage: ', alpha2Storage);
    }
  }
  if (cookieValues?.googleAnalytics) {
    // console.log('hasAllowedGoogleAnalytics: ', hasAllowedGoogleAnalytics);
    if (hasAllowedGoogleAnalytics || hasAllowedGoogleAds) {
      result.googleAnalytics = true;
      loadGoogleTagManager(dataLayerObj);
    }
  }

  if (cookieValues?.iosInstallPromptedAt) {
    if (
      allCookiesAreActive ||
      activeCookies.find(cookie => cookie?.cookieId === downloadAppModalLastSeen)
    ) {
      // themeNameStorage.setAppThemeName(initialThemeName);
      // Cookies.setCookie(iosInstallPromptedAtStorage, cookieValues?.iosInstallPromptedAt);
      result.iosInstallPromptedAt = true;
      localStorage.setItem(
        iosInstallPromptedAtStorage,
        cookieValues?.iosInstallPromptedAt,
      );
    } else {
      // Cookies.deleteCookie(iosInstallPromptedAtStorage);
      localStorage.removeItem(iosInstallPromptedAtStorage);
    }
  }

  if (cookieValues?.webInstallPromptedAt) {
    if (
      allCookiesAreActive ||
      activeCookies.find(cookie => cookie?.cookieId === downloadAppModalLastSeen)
    ) {
      // themeNameStorage.setAppThemeName(initialThemeName);
      // Cookies.setCookie(webInstallPromptedAtStorage, cookieValues?.webInstallPromptedAt);
      result.webInstallPromptedAt = true;
      localStorage.setItem(
        webInstallPromptedAtStorage,
        cookieValues?.webInstallPromptedAt,
      );
    } else {
      // Cookies.deleteCookie(webInstallPromptedAtStorage);
      localStorage.removeItem(webInstallPromptedAtStorage);
    }
  }

  if (cookieValues?.updateDataLayer) {
    if (hasAllowedGoogleAnalytics) {
      result.updateDataLayer = true;
      updateDataLayer(dataLayerObj);
    }
  }

  result.cookies = activeCookies;
  return result;
};

export default activateCookies;

/*
 if (cookieValues?.consent !== undefined) {
    Cookies.setCookie(consentKey, cookieValues?.consent);
  }
  if (cookieValues?.customConsent !== undefined) {
    Cookies.setCookie(customConsentKey, cookieValues?.customConsent);
  }
  */
