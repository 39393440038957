import { useSelector } from 'react-redux';

const useBotSettings = () => {
  const siteWideKeys = useSelector(state => state?.locations?.siteWide?.keys);

  const botSettings = {
    id: siteWideKeys?.REACT_APP_CHAT_BOT_ID,
    name: siteWideKeys?.REACT_APP_CHAT_BOT_NAME,
    tenantId: siteWideKeys?.REACT_APP_CHAT_BOT_TENANT_ID,
    chatHandoverFlag: siteWideKeys?.REACT_APP_PVA_CHAT_HANDOVER_FLAG,
    liveChatAuthHeaderPrefix: siteWideKeys?.REACT_APP_BRIGHTPATTERN_AUTH_PREFIX,
    brightPatternAppId: siteWideKeys?.REACT_APP_BRIGHTPATTERN_APP_ID,
    liveChatUrlPrefix: siteWideKeys?.REACT_APP_BRIGHTPATTERN_PREFIX,
    liveChatUrlSuffix: siteWideKeys?.REACT_APP_BRIGHTPATTERN_SUFFIX,
    endpointUrl: siteWideKeys?.REACT_APP_BOT_ENDPOINT_URL,
    directLineUrl: siteWideKeys?.REACT_APP_DIRECTLINE_URL,
    directLineSecret: siteWideKeys?.REACT_APP_DIRECTLINE_SECRET,
    googleSpeechApiKey: siteWideKeys?.REACT_APP_GOOGLE_SPEECH_API_KEY,
    googleMapApiKey: siteWideKeys?.REACT_APP_BOT_GOOGLE_MAP_API_KEY,
    googleMapApiUrl: siteWideKeys?.REACT_APP_GOOGLE_MAP_API_URL,
    orgId: siteWideKeys?.REACT_APP_ORG_ID,
    orgUrl: siteWideKeys?.REACT_APP_ORG_URL,
    widgetId: siteWideKeys?.REACT_APP_WIDGET_ID,
    jamaicaOrgId: siteWideKeys?.REACT_APP_ORG_ID_JAMAICA,
    jamaicaOrgUrl: siteWideKeys?.REACT_APP_ORG_URL_JAMAICA,
    jamaicaWidgetId: siteWideKeys?.REACT_APP_WIDGET_ID_JAMAICA,
    googleRecaptcha: siteWideKeys?.REACT_APP_GOOGLE_RECAPTCHA,
  };
  return botSettings;
};

export default useBotSettings;
