import {
  countryVerificationStorage,
  countryVerificationStorageType,
} from '../utils/variables';

export const getCountryVerification = () => {
  let countryVerificationTimestamp = null;
  if (countryVerificationStorageType === 'session') {
    countryVerificationTimestamp =
      window.sessionStorage.getItem(countryVerificationStorage) || null;
  } else {
    countryVerificationTimestamp =
      window.localStorage.getItem(countryVerificationStorage) || null;
  }
  return countryVerificationTimestamp;
};

export const removeCountryVerification = () => {
  if (
    countryVerificationStorageType === 'session' &&
    window.sessionStorage.getItem(countryVerificationStorage)
  ) {
    window.sessionStorage.removeItem(countryVerificationStorage);
  } else if (window.localStorage.getItem(countryVerificationStorage)) {
    window.localStorage.removeItem(countryVerificationStorage);
  }
  return null;
};

export const setCountryVerification = countryVerificationTimestamp => {
  if (countryVerificationStorageType === 'session' && countryVerificationTimestamp) {
    window.sessionStorage.setItem(
      countryVerificationStorage,
      countryVerificationTimestamp,
    );
  } else if (countryVerificationTimestamp) {
    window.localStorage.setItem(countryVerificationStorage, countryVerificationTimestamp);
  }
  return null;
};

export default {
  getCountryVerification,
  removeCountryVerification,
  setCountryVerification,
};
