export default theme => {
  return {
    root: props => {
      return {
        width: '100%',
        padding: 0,
        margin: 0,
        display: 'block',
        [theme.breakpoints.up('md')]: {
          minHeight: props?.contentHeight,
        },
      };
    },
    alignCenter: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      minHeight: '400px',
    },
  };
};
