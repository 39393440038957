import { Box, Button, Grid, useMediaQuery, Typography, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { ChevronRight } from '@mui/icons-material';
import classnames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateTempState } from '../../../actions/temp';
import {
  downloadFile,
  languageTranslation,
  sourceTextFromData,
  isValidHttpUrl,
} from '../../../utils/functions';
import { domain } from '../../../utils/variables';
import { strapiGetCall } from '../../../apis/axios';
import {
  buttonActionTypes,
  buttonColorTypes,
  buttonSizeTypes,
  buttonVariantTypes,
  gridItemAlignTypes,
} from '../../propertyValues';
import styles from './style';
import { useRouter, useSmall } from '../../../hooks';
import Spinner from '../spinner';
import ContentBox from '../../../containersV2/misc/general/contentBox';

const { sanitizeUrl } = require('@braintree/sanitize-url');

const useStyles = makeStyles(styles);

const getButtonByActionType = props => {
  const {
    actionType,
    actionText,
    onClick,
    actionUrl,
    classes,
    modalBody,
    buttonVariant: initialButtonVariant,
    buttonSize,
    buttonColor,
    width,
    icon,
    closeAction,
    buttonHoverColor,
    showChevron,
    modalButton,
    download,
    wizard,
    hideActionText,
    instantDownload,
    alpha2,
    iconPosition,
    labelJustification,
    labelPositionsExtreme,
    isDisabled,
    iconClassName,
    index,
    autoOpen = false,
    svgIcon,
    isUniversal = false,
    modalContentType,
    disabled,
    forceOutline,
    language,
    themeValue,
    storeState,
    loading,
    setLoading,
    dispatch,
    maxModalWidth,
    ...restProps
  } = props;

  const buttonVariant =
    initialButtonVariant === 'solid' ? 'contained' : initialButtonVariant;
  const colorVariant = `${buttonColor}_${buttonVariant}`;

  // console.log('actionText: ', actionText);
  // console.log('restProps: ', restProps);

  // for testing label alignments ⬇
  // let iconPosition = 'right'; // ['left', 'right']
  // let labelJustification = 'end'; // ['start', 'end', 'center', 'spaceBetween']
  // let labelPositionsExtreme = false; // [true, false]

  const newActionText =
    actionText && actionText?.toString().trim().length > 0
      ? actionText.toString()
      : `<span>Read More</span> `;
  // console.log('newActionText: ', newActionText);
  // debugger;

  const fetchLabelDirection = () => {
    switch (iconPosition) {
      case 'left':
        return 'row-reverse';
      case 'right':
      default:
        return 'row';
    }
  };
  const fetchLabelJustify = () => {
    const justifyArr = ['flex-start', 'flex-end'];
    if (fetchLabelDirection() === 'row-reverse') {
      justifyArr.reverse();
    }
    if (labelPositionsExtreme) {
      return 'spaceBetween';
    }
    switch (labelJustification) {
      case 'start':
        return justifyArr[0];
      case 'end':
        return justifyArr[1];
      case 'spaceBetween':
        return 'space-around';
      case 'center':
      default:
        return 'center';
    }
  };
  const finalIconClassName = iconClassName || classes.buttonIcon;

  const buttonLabel = () => {
    if (loading) {
      return <Spinner />;
    }
    return (
      <Grid
        container
        direction={fetchLabelDirection()}
        justify={fetchLabelJustify()}
        alignItems="center"
        spacing={0}
      >
        {(icon || showChevron) &&
          !labelPositionsExtreme &&
          fetchLabelJustify() !== 'start' && (
            <Grid
              item
              xs={1}
              style={{ opacity: 0, width: '30px' }}
              align={labelJustification}
            >
              a
            </Grid>
          )}
        {!hideActionText && (
          <Grid
            item
            xs={icon ? 8 : showChevron ? 10 : 12}
            align={labelJustification}
            className={classes.text}
          >
            {newActionText}
          </Grid>
        )}
        {icon && (
          <Grid
            item
            xs={hideActionText ? 10 : showChevron ? 2 : 3}
            style={{ width: '30px', paddingRight: '5px' }}
            align={labelJustification}
          >
            <span className={classnames(finalIconClassName, `icon-${icon?.slug}`)} />
          </Grid>
        )}
        {showChevron && (
          <Grid item xs={1} style={{ width: '30px' }} align={labelJustification}>
            <ChevronRight className={classes.chevron} />
          </Grid>
        )}
      </Grid>
    );
  };

  if (actionType === 'modal') {
    const handleModalClick = async () => {
      const getGenericContentsData = async () => {
        let resp = null;
        try {
          setLoading(true);
          const params = {
            slug: actionUrl,
            'territories.alpha2': alpha2,
          };
          const url = `/gg-modals`;
          resp = await strapiGetCall(storeState, url, params);

          if (resp) {
            // console.log('resp1: ', resp);
            resp = resp[0];
            // console.log('resp: ', resp);
            await languageTranslation(resp, language);
          }
        } catch (error) {
          console.log('error', error);
        }
        setLoading(false);
        return resp;
      };

      const genericData = await getGenericContentsData();

      const dialogContent = <ContentBox contents={genericData?.data} />;

      const alertObj = {
        content: dialogContent,
        maxWidth: maxModalWidth,
        setCloseAsDismissiveAction: true,
      };

      dispatch(updateTempState.updateTempStateAction({ alertPopUp: alertObj }));
    };

    return (
      <Button
        aria-label={buttonLabel() || 'Guardian Group'}
        disabled={isDisabled || disabled}
        loading={loading}
        disableElevation
        variant={buttonVariant}
        disableRipple
        className={classnames(classes.base, classes[colorVariant])}
        size={buttonSize}
        width={width}
        onClick={() => {
          handleModalClick();
        }}
      >
        {buttonLabel()}
      </Button>
    );
  }

  if (actionType === 'redirect') {
    const state = {
      domain,
      alpha2,
      language,
      themeValue,
    };

    let newActionUrl = actionUrl
      ? sourceTextFromData(state, actionUrl)
      : download?.file?.url;
    newActionUrl = sanitizeUrl(newActionUrl && newActionUrl !== '#' ? newActionUrl : '#');
    if (svgIcon) {
      // console.log('svgIcon: ', svgIcon);
      return (
        <Box>
          <Link key={newActionUrl} href={sanitizeUrl(newActionUrl)} color="inherit">
            <img className={classes.svgButton} alt="logo" src={svgIcon?.url} />
          </Link>
        </Box>
      );
    }

    return (
      <Button
        aria-label={buttonLabel() || 'Guardian Group'}
        disabled={isDisabled || disabled}
        disableElevation
        variant={buttonVariant}
        loading={loading}
        component={isValidHttpUrl(newActionUrl) ? 'a' : RouterLink}
        href={isValidHttpUrl(newActionUrl) && newActionUrl}
        target={isValidHttpUrl(newActionUrl) ? '_blank' : '_self'}
        to={newActionUrl}
        disableRipple
        className={classnames(classes.base, classes[colorVariant])}
        size={buttonSize}
        width={width}
      >
        {buttonLabel()}
      </Button>
    );
  }
  if (actionType === 'wizard') {
    let newActionUrl = wizard ? `${domain.pwa}${`/wizards/${wizard?.slug}`}` : actionUrl;
    newActionUrl = sanitizeUrl(newActionUrl && newActionUrl !== '#' ? newActionUrl : '#');
    return (
      <Button
        aria-label={buttonLabel() || 'Guardian Group'}
        disabled={isDisabled || disabled}
        disableElevation
        loading={loading}
        variant={buttonVariant}
        component={isValidHttpUrl(newActionUrl) ? 'a' : RouterLink}
        href={isValidHttpUrl(newActionUrl) && newActionUrl}
        target={isValidHttpUrl(newActionUrl) ? '_blank' : '_self'}
        to={newActionUrl}
        disableRipple
        className={classnames(classes.base, classes[colorVariant])}
        size={buttonSize}
        width={width}
      >
        {buttonLabel()}
      </Button>
    );
  }
  if (actionType === 'download' || actionType === 'instantDownload') {
    const state = {
      domain,
      alpha2,
      language,
      themeValue,
    };

    const newActionUrl = download?.file?.url || actionUrl;

    const filename = newActionUrl
      ?.split('/')
      ?.pop()
      ?.split('?')
      ? newActionUrl
          ?.split('/')
          ?.pop()
          ?.split('?')[0]
      : newActionUrl?.split('/')?.pop();

    const newFileName = filename || newActionText;
    return (
      <Button
        aria-label={buttonLabel() || 'Guardian Group'}
        disabled={isDisabled || disabled}
        disableElevation
        loading={loading}
        variant={buttonVariant}
        disableRipple
        className={classnames(classes.base, classes[colorVariant])}
        size={buttonSize}
        width={width}
        onClick={() => downloadFile(newFileName, newActionUrl)}
      >
        {buttonLabel()}
      </Button>
    );
  }
  if (actionType === 'submit') {
    return (
      <Button
        aria-label={buttonLabel() || 'Guardian Group'}
        disabled={isDisabled || disabled}
        disableElevation
        loading={loading}
        variant={buttonVariant}
        onClick={onClick}
        disableRipple
        className={classnames(classes.base, classes[colorVariant])}
        size={buttonSize}
        width={width}
      >
        {buttonLabel()}
      </Button>
    );
  }

  return <div />;
};

const ActionButton = props => {
  // console.log('action button props: ', props);
  const {
    gridItemAlign,
    width,
    height,
    buttonWidth,
    buttonHeight,
    buttonSize,
    buttonColor,
    buttonHoverColor,
    buttonVariant,
    minWidth,
    hidden = false,
    iconPosition,
    labelJustification,
    labelPositionsExtreme,
    fullWidth,
    useGlobalModal = false,
    useDefaultTextColor,
    isMargin,
    margin,
    countryCondition,
    urlCondition,
    ...restProps
  } = props;
  const newButtonColor = buttonColor || 'secondary';
  const newButtonHoverColor = buttonHoverColor || '';
  const newWidth = buttonWidth || width || '300px';
  const newHeight = buttonHeight || height || 'auto';
  const newGridItemAlign = gridItemAlign || 'center';
  const newButtonVariant = buttonVariant || 'outlined';
  const newButtonSize = buttonSize || 'medium';
  const newMinWidth = minWidth || '150px';
  const theme = useTheme();
  const dispatch = useDispatch();
  const isSmall = useSmall();

  const textColor =
    buttonVariant === 'text' && !useDefaultTextColor ? newButtonColor : 'default';

  const classes = useStyles({
    ...restProps,
    margin,
    isMargin,
    buttonColor: newButtonColor || 'inherit',
    textColor,
    useDefaultTextColor,
    width: fullWidth ? '100%' : newWidth,
    height: newHeight,
    minWidth: newMinWidth,
    buttonHoverColor: newButtonHoverColor,
    isSmall,
    buttonSize,
  });

  const { download, actionType, actionText, icon } = restProps;
  // console.log('download: ', download);
  const { pathname } = useRouter();
  const alpha2 = useSelector(state => state?.locations?.yourLocation?.alpha2);
  const language = useSelector(state => state?.locations?.language);
  const storeState = useSelector(state => state);
  const [loading, setLoading] = useState();
  const newProps = {
    theme,
    buttonVariant: newButtonVariant,
    buttonSize: newButtonSize,
    buttonHoverColor: newButtonHoverColor,
    buttonColor: newButtonColor,
    width: newWidth,
    classes,
    alpha2,
    language,
    iconPosition,
    labelJustification,
    labelPositionsExtreme,
    storeState,
    loading,
    setLoading,
    dispatch,
    ...restProps,
  };

  if (hidden) return null;

  const hasValidCountryCondition =
    !countryCondition ||
    (countryCondition && countryCondition?.split(',')?.includes(alpha2));

  const hasValidUrlCondition =
    !urlCondition || (urlCondition && urlCondition?.split(',').includes(pathname));

  if (
    !(actionText || icon) ||
    !actionType ||
    !(hasValidCountryCondition && hasValidUrlCondition)
  )
    return null;

  return (
    <Grid container justify={newGridItemAlign} alignItems="center">
      <Grid item xs={12} align={newGridItemAlign}>
        {getButtonByActionType(newProps)}
      </Grid>
    </Grid>
  );
};

ActionButton.defaultProps = {
  actionUrl: '',
  actionText: '',
  buttonColor: 'secondary',
  buttonHoverColor: null,
  buttonSize: 'medium',
  gridItemAlign: 'center',
  buttonVariant: 'outlined',
  onClick: () => {},
  actionType: 'redirect',
  width: '300px',
  modalBody: null,
  icon: '',
  iconPosition: 'right',
  labelJustification: 'spaceBetween',
  labelPositionsExtreme: false,
  showChevron: true,
  hideActionText: false,
  isDisabled: false,
};

ActionButton.propTypes = {
  actionUrl: PropTypes.string,
  actionText: PropTypes.string,
  gridItemAlign: PropTypes.oneOf(gridItemAlignTypes),
  buttonVariant: PropTypes.oneOf(buttonVariantTypes),
  buttonSize: PropTypes.oneOf(buttonSizeTypes),
  buttonColor: PropTypes.oneOf(buttonColorTypes),
  buttonHoverColor: PropTypes.oneOf(buttonColorTypes),
  onClick: PropTypes.func,
  actionType: PropTypes.oneOf(buttonActionTypes),
  width: PropTypes.string,
  height: PropTypes.string,
  modalBody: PropTypes.object,
  icon: PropTypes.string,
  iconPosition: PropTypes.string,
  labelJustification: PropTypes.string,
  labelPositionsExtreme: PropTypes.string,
  showChevron: PropTypes.bool,
  hideActionText: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default ActionButton;
