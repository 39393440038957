import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Box } from '@mui/material';
import { poweredByFac } from '../../../utils/variables/images';
import styles from './style';

const useStyles = makeStyles(styles);

const FacFooter = () => {
  const classes = useStyles();

  return (
    <Box>
      <img src={poweredByFac} alt="Footer" className={classes.img} />
    </Box>
  );
};

export default FacFooter;
