import { SIGNUP_EXISTING_CUSTOMER_SUCCESS } from '../../../constants/authActionNames';

function signUpExistingCustomerSuccess(user) {
  return {
    type: SIGNUP_EXISTING_CUSTOMER_SUCCESS,
    payload: user,
  };
}

export function signUpExistingCustomerAction(data) {
  return signUpExistingCustomerSuccess(data);
}

export default {
  signUpExistingCustomerAction,
};
