const styles = theme => ({
  root: {
    width: 'inherit',
    minHeight: 'inherit',
  },
  paper: {
    padding: theme.spacing(2),
    minHeight: 'inherit',
  },
  modalBackground: {
    backgroundColor: `${theme.palette.background.main} !important`,
  },
  alert: {},
});

export default styles;
