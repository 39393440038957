import { gql } from 'graphql-request';

const FORM_CONDITION_FIELDS = gql`
  fragment formCondition on C360CustomFormCondition {
    title
    isConditionalComponent
    conditionalValue
    conditionalKey
    conditionalOperator
    conditionalFieldType
    conditionalChain
    subConditions {
      title
      isConditionalComponent
      conditionalValue
      conditionalKey
      conditionalOperator
      conditionalFieldType
    }
    trueIfEmpty
    notTrueIfEmpty
    validationMessage
    validationTitle
    validationType
  }
`;

export default FORM_CONDITION_FIELDS;
