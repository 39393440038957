import { gql } from 'graphql-request';

const NON_RECURSIVE_FORM_FIELDS = gql`
  fragment nonRecursiveForm on C360CustomForm {
    title
    title_en
    title_nl
    justify
    alignItems
    gridContainerAlign
    spacing
    resetWizardData
    makeNamesDistinct
    preventResetComponentData
    applySelectedClientChanges
    finalSubmitCheck
    clearCacheKey
    components {
      ...nonRecursiveFormDynamicZone
    }
    next {
      ...wizardActionButton
    }
    previous {
      ...wizardActionButton
    }
    layoutConfig {
      top {
        xs
        sm
        md
        align
      }
      left {
        xs
        sm
        md
        align
      }
      center {
        xs
        sm
        md
        align
      }
      right {
        xs
        sm
        md
        align
      }
      bottom {
        xs
        sm
        md
        align
      }
    }
  }
`;

// export default FORM_FIELDS;
// exports.default = NON_RECURSIVE_FORM_FIELDS;
export default NON_RECURSIVE_FORM_FIELDS;
