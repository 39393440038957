const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  gridItem: {
    padding: 0,
    width: '100%',
  },
  gridItemHeader: {
    borderBottom: `1px solid ${theme.palette.grey.A700}`,
    color: theme.palette.grey.A700, // '#AEAEAE',
  },
  btn: {
    borderRadius: theme.spacing(2.5),
    margin: theme.spacing(0.5),
  },
  marginLeft: {
    marginLeft: '10px',
  },
  formControl: {
    marginTop: theme.spacing(1),
    display: 'block',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textAlign: 'center',
  },
  filterBtn: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textAlign: 'center',
  },
  datePicker: {
    // width: '145px',
  },
});

export default styles;
