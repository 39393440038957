import React from 'react';
import { Typography, Button, Checkbox, FormControlLabel, Grid } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import TaskIcon from '@mui/icons-material/Task';
import { useTheme } from '@mui/material/styles';

export function FileUploadExternallyManaged({
  fileTitle,
  fileName,
  handleUpload,
  name,
  fileURL,
}) {
  const theme = useTheme();
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        width: '100%',
      }}
      xs={12}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          my: '.5rem',
          width: '100%',
        }}
      >
        <Grid item>
          <FormControlLabel
            control={<Checkbox checked={Boolean(fileName)} />}
            label={
              fileURL !== null ? (
                <a href={fileURL} download={`${fileTitle}`}>
                  {fileTitle}
                </a>
              ) : (
                <p>{fileTitle}</p>
              )
            }
          />
        </Grid>
        <Grid item>
          {fileName ? (
            <Grid
              sx={{
                height: '2rem',
                color: 'text.primary',
                fontWeight: '400',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                fontSize: '0.75rem',
              }}
            >
              <TaskIcon
                fontSize="small"
                sx={{
                  color: 'success.main',
                }}
              />
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                {fileName}
              </Typography>
            </Grid>
          ) : (
            <Button
              variant="outlined"
              component="label"
              sx={{
                width: 'fit-content',
                height: '2rem',
                color: 'text.disabled',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '0.188rem',
                border: `1px solid ${theme.palette.text.disabled}`,
                borderRadius: '0.5rem',
                fontSize: '0.75rem',
                textTransform: 'capitalize',
              }}
            >
              <CloudUploadIcon fontSize="small" />
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                Upload
              </Typography>
              <input name={name} type="file" onChange={handleUpload} hidden />
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
