import React from 'react';
import { Box, Stack, Typography, Card } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export function InProgressCard({ number, icon, title, footNote }) {
  const footNodeFormatted = `'${footNote}'`;
  const theme = useTheme();
  return (
    <Stack
      spacing={1}
      sx={{
        mb: 2,
        '&::after': {
          content: footNodeFormatted,
          display: 'block',
          height: '30px',
          textAlign: 'left',
          fontSize: { xs: '10px', sm: '12px', md: '14px' },
          mt: 1.5,
          mb: { xs: 1, md: 0 },
          color: 'text.disabled',
        },
      }}
    >
      <Card
        sx={{
          background: '#9C0059',
          borderRadius: '8px',
          boxSizing: 'border-box',
          padding: { xs: '1rem', sm: '1.625rem 2.5rem', md: '1rem 1rem' },
          color: 'white',
          width: '100%',
          height: '100%',
        }}
      >
        <Stack direction="column" alignItems="center" spacing={2}>
          <Typography variant="h4" sx={{ fontSize: '3.25rem', fontWeight: '500' }}>
            {number?.toString()?.padStart(2, '0')}
          </Typography>
          <Stack direction="row" alignItems="center">
            <Box>{icon}</Box>
            <Typography
              sx={{
                marginLeft: '1.25rem',
                fontSize: { xs: '0.75rem', sm: '1rem' },
                textAlign: 'left',
              }}
            >
              {title}
            </Typography>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
}
