export const CONFIRM_POLICY_API_REQUEST = 'CONFIRM_POLICY_API_REQUEST';
export const CONFIRM_POLICY_API_ERROR = 'CONFIRM_POLICY_API_ERROR';
export const CONFIRM_POLICY_API_SUCCESS = 'CONFIRM_POLICY_API_SUCCESS';
export const UNSET_POLICY_API_REQUEST = 'UNSET_POLICY_API_REQUEST';
export const UNSET_POLICY_API_ERROR = 'UNSET_POLICY_API_ERROR';
export const UNSET_POLICY_API_SUCCESS = 'UNSET_POLICY_API_SUCCESS';
export const RESET_CONFIRM_POLICY_SUCCESS = 'RESET_CONFIRM_POLICY_SUCCESS';
export const UNSET_CUSTOMER_API_REQUEST = 'UNSET_CUSTOMER_API_REQUEST';
export const UNSET_CUSTOMER_API_ERROR = 'UNSET_CUSTOMER_API_ERROR';
export const UNSET_CUSTOMER_API_SUCCESS = 'UNSET_CUSTOMER_API_SUCCESS';
export const UNSET_LINKED_AUTH_API_REQUEST = 'UNSET_LINKED_AUTH_API_REQUEST';
export const UNSET_LINKED_AUTH_API_ERROR = 'UNSET_LINKED_AUTH_API_ERROR';
export const UNSET_LINKED_AUTH_API_SUCCESS = 'UNSET_LINKED_AUTH_API_SUCCESS';
