import React, { useEffect } from 'react';
import useWindowWidthBreakpoints from 'use-window-width-breakpoints';
import SurveyEmbed from '../surveyEmbed';
import SurveyQRCode from '../surveyQRCode';
import SurveyLink from '../surveyLink';
import getStyles, { getScreenSize } from './getStyles';
import { useGTMDataLayer } from '../../../hooks';
import { surveyLoadEvent } from '../../../utils/variables/events';

const SurveyComponent = props => {
  const {
    surveyId,
    surveyVariables,
    surveyScript,
    surveyQRCode,
    type,
    embedType,
    surveyLink,
    absolutePosition,
    width,
    height,
    buttonText = '',
    defaultHeight,
    buttonVariant,
    buttonColor,
    buttonSize,
    buttonStartIcon,
    headingText,
    headingVariant,
    isModal,
    renderType,
    customContext,
  } = props;
  // console.log('survey props: ', props);
  const storeGtmDataLayer = useGTMDataLayer();

  const breakpoint = useWindowWidthBreakpoints({
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  });

  useEffect(() => {
    if (surveyId) {
      storeGtmDataLayer({ event: surveyLoadEvent, key: surveyId });
    }
  }, [surveyId]);

  const screenSize = getScreenSize(breakpoint);
  if (type === 'link') {
    const styles = getStyles({
      absolutePosition,
      width,
      height,
      defaultHeight: defaultHeight || '85vh',
      screenSize,
    });
    return (
      <SurveyLink
        surveyLink={surveyLink}
        styles={styles}
        headingText={headingText}
        headingVariant={headingVariant}
      />
    );
  }
  if (type === 'qrcode') {
    const styles = getStyles({
      absolutePosition,
      width,
      height,
      defaultHeight: defaultHeight || '45vh',
      screenSize,
    });
    return (
      <SurveyQRCode
        surveyQRCode={surveyQRCode}
        styles={styles}
        headingText={headingText}
        headingVariant={headingVariant}
      />
    );
  }
  if (type === 'embed') {
    const styles = getStyles({
      absolutePosition,
      width,
      height,
      defaultHeight: defaultHeight || '85vh',
      screenSize,
    });
    return (
      <SurveyEmbed
        surveyId={surveyId}
        embedType={embedType}
        variables={surveyVariables?.myVariable?.variables}
        buttonText={buttonText}
        buttonVariant={buttonVariant}
        styles={styles}
        headingText={isModal ? '' : headingText}
        headingVariant={headingVariant}
        renderType={renderType}
        type={type}
        customContext={customContext}
      />
    );
  }
  return <div />;
};

export default SurveyComponent;

/*
  if (surveyScript && surveyScript?.length > 10) {
    return <SurveyEmbedScript  />;
  }
*/
