import {
  GET_INVESTMENT_TRANSACTIONS_API_REQUEST,
  GET_INVESTMENT_TRANSACTIONS_API_SUCCESS,
  GET_INVESTMENT_TRANSACTIONS_API_ERROR,
} from '../../../constants/portfolio/investmentTransactionActionNames';

// import { api } from '../../../utils/variables';
import { getInvestmentTransactionsApi } from '../../../apis/portfolio';
import { getPayloadResponse } from '../../../reducers/portfolio/investmentTransactions';

function getInvestmentTransactionsError(err) {
  return {
    type: GET_INVESTMENT_TRANSACTIONS_API_ERROR,
    payload: err,
  };
}

function getInvestmentTransactionsRequest() {
  return {
    type: GET_INVESTMENT_TRANSACTIONS_API_REQUEST,
    payload: true,
  };
}
function getInvestmentTransactionsSuccess(prod, params) {
  return {
    type: GET_INVESTMENT_TRANSACTIONS_API_SUCCESS,
    payload: prod,
    params,
  };
}

export function getInvestmentTransactionsAction(params) {
  // const params = { ..._params }; // ...api };

  return (dispatch, getState) => {
    const state = getState();
    const response = getPayloadResponse(params);

    dispatch(getInvestmentTransactionsRequest());

    return getInvestmentTransactionsApi
      .getInvestmentTransactions(state, params)
      .then(result => {
        // Update the app state with the results of the API call.

        dispatch(getInvestmentTransactionsSuccess(result, params));
        return result;
      })
      .catch(error => {
        dispatch(getInvestmentTransactionsError(error));
        throw error;
      });
  };
}

export default {
  getInvestmentTransactionsAction,
};
