import { gql } from 'graphql-request';

const CONDITION_FIELDS = gql`
  fragment condition on ComponentMiscComponentsConditions {
    isConditionalComponent
    conditionalValue
    conditionalKey
    conditionalOperator
    conditionalFieldType
    conditionalAction
    conditionalChain
    subConditions {
      title
      isConditionalComponent
      conditionalValue
      conditionalKey
      conditionalOperator
      conditionalFieldType
    }
    validationReqMessage
    conditionalArrayKey
  }
`;

// exports.default = CONDITION_FIELDS;
// exports.default = CONDITION_FIELDS;
export default CONDITION_FIELDS;
