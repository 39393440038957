import { portfolioButtonStyles } from '../../../styles';

const productSummaryStyles = theme => ({
  ...portfolioButtonStyles(theme),
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(2),
    margin: theme.spacing(1),
    width: '100%',
  },
  gridPad: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },

  title: {
    marginBottom: theme.spacing(0.5),
    // lineHeight: '1em',
    // height: '1.4em',
    // overflow: 'hidden',
    // display: '-webkit-box',
    // '-webkit-line-clamp': 1,
    // '-webkit-box-orient': 'vertical',
  },

  card: {
    backgroundColor: theme.palette.background.default,
    // border: `${theme.shape.borderWidth}px solid ${theme.palette.background.grid}`,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    boxShadow: theme.effects.boxShadow,
    transition: theme.effects.transition,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    // /maxWidth: 500,
  },
  image: {
    width: '100%',
    height: '190px',
    objectFit: 'cover',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    borderRadius: theme.shape.borderRadius,
  },
  fullHeight: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
  },
  description: {
    textAlign: 'left',
    // height: imageHeight,
    verticalAlign: 'middle',
    // lineHeight: '25px',
    overflow: 'hidden',
    // overflowY: 'auto',
    // // fontSize: '100%',/theme.typography.pxToRem(14),
    display: '-webkit-box',
    '-webkit-line-clamp': 6,
    '-webkit-box-orient': 'vertical',
  },
  zGrid: {
    height: 'auto',
    width: '100%',
  },
  button: {
    height: '100%',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  cardContent: {
    padding: '0 !important',
  },
  readMore: {
    cursor: 'pointer',
    '&:hover': {
      color: `${theme.palette.tertiary.main} !important`,
    },
  },
});

export default productSummaryStyles;
