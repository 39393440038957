import { useSelector, useDispatch } from 'react-redux';
import { getClientsPolicies } from '../actions/clientspolicies';
import { dateToString, toTitleCase, dateToIsoString } from '../utils/functions';
import { listSelector } from '../selectors';
import useSmall from './useSmall';
import { clientPoliciesIndexName, agentType, customerType } from '../utils/variables';

const useClientPoliciesData = ({ infiniteScroll = false } = {}) => {
  const dispatch = useDispatch();
  const isMobile = useSmall();
  const user = useSelector(state => state.auth.user);
  const auth = useSelector(state => state.auth);
  const selectedClient = useSelector(state => state.auth.selectedClient);
  const usertype = useSelector(state => state.auth.usertype);
  const customerId =
    auth.usertype === agentType ? selectedClient?.clientNumber : auth?.user?.customer?.id;
  const agentId = user?.agent?.id || '';
  const defaultOptions = useSelector(state =>
    listSelector.listOptions({ state, name: clientPoliciesIndexName, infiniteScroll }),
  );
  const alpha2 = useSelector(state => state?.locations?.yourLocation?.alpha2);
  // console.log('defaultOptions: ', defaultOptions);
  // const allRows = useSelector(state => state.clientPolicies.allRows);

  return ({
    nextStartIndex,
    nextItemCount,
    nextScrollCount,
    actionIndex,
    infiniteScroll: primaryInfiniteScroll,
    isMobile: primaryIsMobile,
  } = {}) => {
    // console.log('primaryInfiniteScroll: ', primaryInfiniteScroll);
    let finalInfiniteScroll = false;
    if (primaryInfiniteScroll !== undefined) {
      finalInfiniteScroll = primaryInfiniteScroll;
    } else {
      finalInfiniteScroll = infiniteScroll;
    }

    let finalIsMobile = isMobile;
    if (primaryIsMobile !== undefined) {
      finalIsMobile = primaryIsMobile;
    } else {
      finalIsMobile = isMobile;
    }

    let newStart;
    if (nextStartIndex) {
      newStart = nextStartIndex;
    } else if (defaultOptions?.startIndex) {
      newStart = defaultOptions?.startIndex;
    } else if (defaultOptions?.startIndex === 0) {
      newStart = defaultOptions?.startIndex;
    }

    let newLimit;
    // console.log('nextItemCount: ', nextItemCount);
    // console.log('defaultOptions.itemCount: ', defaultOptions?.itemCount);
    if (nextItemCount) {
      newLimit = nextItemCount;
    } else if (defaultOptions?.itemCount) {
      newLimit = defaultOptions?.itemCount;
    } else if (defaultOptions?.itemCount === 0) {
      newLimit = defaultOptions?.itemCount;
    }

    // console.log('newLimit: ', newLimit);
    // console.log('next load nextStartIndex: ', nextStartIndex);
    const body = {
      customerId,
      agentId: agentId || undefined,
      usertype: usertype || customerType,
      limit: newLimit,
      offset: newStart,
      fromDate: dateToString(defaultOptions?.startDate),
      toDate: dateToString(defaultOptions?.endDate),
      lob: defaultOptions?.lob, // lob: toTitleCase(defaultOptions?.lob?.toString()), // defaultOptions?.lob,
      nextItemCount,
      nextScrollCount,
      nextStartIndex,
      scrollKey: defaultOptions?.scrollKey,
      staticLob: defaultOptions?.staticLob,
      infiniteScroll: finalInfiniteScroll,
      isMobile: finalIsMobile,
      finalInfiniteScroll,
      alpha2,
      search: defaultOptions?.search ? defaultOptions?.search : undefined,
    };

    const params = {
      customer: customerId || undefined,
      // agentId: agentId || undefined,
      // usertype: usertype || undefined,
      limit: newLimit,
      start: newStart,
      policyType: defaultOptions?.lob ? defaultOptions?.lob : undefined,
      q: defaultOptions?.search ? defaultOptions?.search : undefined,
      secondaryStatus:
        defaultOptions.status && defaultOptions.status.length > 0
          ? defaultOptions.status
          : undefined,
      startDate: defaultOptions.startDate
        ? dateToIsoString(defaultOptions.startDate)
        : undefined,
      endDate: defaultOptions.endDate
        ? dateToIsoString(defaultOptions.endDate)
        : undefined,
      currency:
        defaultOptions.currency && defaultOptions.currency.length > 0
          ? defaultOptions.currency
          : undefined,
      canRenew: true,
    };

    return dispatch(getClientsPolicies.getClientPoliciesAction(params, body));
  };
};
export default useClientPoliciesData;
