import { gql } from 'graphql-request';

const RICH_TEXT_DISPLAY_FIELDS = gql`
  fragment richTextDisplay on ComponentWizardComponentsRichTextDisplay {
    RichTextDisplay_slug: slug
    RichTextDisplay_content: content
    RichTextDisplay_label: label
    RichTextDisplay_labelAlign: labelAlign
    RichTextDisplay_tooltip: tooltip
    RichTextDisplay_borderSize: borderSize
    RichTextDisplay_borderColor: borderColor
    RichTextDisplay_borderRadius: borderRadius
    RichTextDisplay_staticData: staticData {
      title
      title_en
      title_nl
      description
      description_en
      description_nl
      html
      html_en
      html_nl
    }
    RichTextDisplay_source: source
    RichTextDisplay_paper: paper
    RichTextDisplay_name: name
    RichTextDisplay_positionNo: positionNo
    RichTextDisplay_layoutPosition: layoutPosition
    RichTextDisplay_align: align
    RichTextDisplay_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    RichTextDisplay_isConditionalComponent: isConditionalComponent
    RichTextDisplay_conditionalValue: conditionalValue
    RichTextDisplay_conditionalKey: conditionalKey
    RichTextDisplay_conditionalOperator: conditionalOperator
    RichTextDisplay_conditionalFieldType: conditionalFieldType
    RichTextDisplay_conditionalAction: conditionalAction
    RichTextDisplay_conditionalArrayKey: conditionalArrayKey
    RichTextDisplay_disable: disable
    RichTextDisplay_show: show
    RichTextDisplay_tooltip_en: tooltip_en
    RichTextDisplay_tooltip_nl: tooltip_nl
    RichTextDisplay_label_en: label_en
    RichTextDisplay_label_nl: label_nl
    RichTextDisplay_content_en: content_en
    RichTextDisplay_content_nl: content_nl
    RichTextDisplay_conditions: conditions {
      ...condition
    }
  }
`;

// export default RICH_TEXT_DISPLAY_FIELDS;
// exports.default = RICH_TEXT_DISPLAY_FIELDS;
export default RICH_TEXT_DISPLAY_FIELDS;
