import { portfolioButtonStyles } from '../../../../styles';

const contentDialogBoxStyles = theme => ({
  ...portfolioButtonStyles(theme),

  dialogPaper: {
    textAlign: 'justify',
    maxHeight: '80vh',
    overflowY: 'auto',
  },
});

export default contentDialogBoxStyles;
