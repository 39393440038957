import { SELECT_PRODUCTS_SUCCESS } from '../../../constants/authActionNames';

function selectedProductSuccess(product) {
  return {
    type: SELECT_PRODUCTS_SUCCESS,
    payload: product,
  };
}

export default {
  selectedProductSuccess,
};
