import {
  RESET_CLAIMS_SUCCESS,
  RESET_HEALTH_CLAIMS,
} from '../../../constants/claimActionNames';

export function resetClaimsAction() {
  return {
    type: RESET_CLAIMS_SUCCESS,
    payload: true,
  };
}

export function resetHealthClaimsAction() {
  return {
    type: RESET_HEALTH_CLAIMS,
    payload: true,
  };
}

export default {
  resetClaimsAction,
  resetHealthClaimsAction,
};
