import { routerGetCall } from '../../axios';

const getIndividualReceipt = (state, params) => {
  const url = `/accounts/individualReceipts`;
  const opts = {
    allowGuestSession: true,
  };
  return routerGetCall(state, url, params, opts);
};
export default { getIndividualReceipt };
