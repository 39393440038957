import { alpha } from '@mui/material/styles';

const styles = theme => ({
  bannerText: {
    color: 'inherit',
    textAlign: 'center',
  },
  bodyStyle: {
    // padding: '0% 10%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'center',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
  form: {
    width: '100%',
  },
  iconStyle: {
    color: theme.palette.primary.main,
    fontSize: '8rem !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '5rem !important',
      color: theme.palette.primary.contrastText,
    },
    // marginTop: '6vh',
  },

  iconContainer: {
    backgroundColor: 'transparent',
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.background.transparent,
    },
    borderRadius: '50%',
    height: '7rem',
    width: '7rem',
    margin: '2vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default styles;
