import { getRef } from '../../../utils/functions';
import { portfolioButtonStyles } from '../../../styles';

export default (theme, textColor, mainColor) => ({
  ...portfolioButtonStyles(theme),
  root: {
    height: '100%',
    position: 'relative',

    [theme.breakpoints.up('md')]: {
      minHeight: 120,
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 50,
    },
    // backgroundColor: theme.palette.background.main, //    color: theme.palette.primary.main,
    // borderStyle: 'solid',
    // borderColor: theme.palette.grey[400],
    // borderWidth: '3px',
    // borderRadius: theme.shape.borderRadius,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  cardPad: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  requestCard: {
    padding: `${theme.spacing(2)} !important`,
    height: '100%',
  },
  contentCard: {
    paddingLeft: `${theme.spacing(2)} !important`,
    paddingRight: `${theme.spacing(2)} !important`,
    overflow: 'hidden',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      maxHeight: '140px',
    },
  },
  calculatorCard: {
    paddingTop: `${theme.spacing(2)} !important`,
    paddingBottom: `${theme.spacing(2)} !important`,
    paddingLeft: `${theme.spacing(5)} !important`,
    paddingRight: `${theme.spacing(5)} !important`,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: `${theme.spacing(2)} !important`,
      paddingRight: `${theme.spacing(2)} !important`,
    },
  },
  purchaseNowCard: {
    padding: `${theme.spacing(2)} !important`,
  },
  height: {
    height: '100%',
  },
  lobIcon: {
    color: `${theme.palette.text.bright} !important`,
  },
  iconContainer: {
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: `${mainColor} !important`,
    maxHeight: '50px !important',
    maxWidth: '50px !important',
    minHeight: '50px !important',
    minWidth: '50px !important',
  },
  iconStyles: {
    fontSize: '25px',
    [theme.breakpoints.up('xl')]: {
      fontSize: '30px !important',
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: '25px !important',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '32px !important',
    },
    color: `${theme.palette.text.bright} !important`,
  },
  title: {
    // cursor: 'pointer',
    color: `${theme.palette.text.dark} !important`,
    fontWeight: 'bold !important',
    fontSize: '1rem !important',
    transition: theme.effects.transition,
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  card: {
    background: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    minHeight: '150px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      minHeight: '100px',
      // padding: theme.spacing(1),
      // marginBottom: theme.spacing(1),
    },
    width: '100%',

    transition: theme.effects.transition,
    boxShadow: theme.effects.boxShadow,
    // borderRadius: `${theme.shape.borderRadius}px`,
    // borderStyle: 'solid',
    // /borderWidth: theme.shape.borderWidth,
    // /borderColor: theme.palette.primary.main,

    // },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      height: '100%',
    },
  },
  defaultHover: {
    background: theme.palette.background.default,
    '&:hover': {
      background: theme.palette.primary.main,
      '& $title': {
        color: `${theme.palette.text.bright} !important`,
      },
      '& $description': {
        color: `${theme.palette.text.bright} !important`,
      },
      '& $listItem': {
        color: `${theme.palette.text.bright} !important`,
      },
      '& $iconContainer': {
        backgroundColor: `${theme.palette.text.bright} !important`,
      },
      '& $iconStyles': {
        color: `${theme.palette.primary.main} !important`,
      },
      '& $primaryButton': {
        borderColor: `${theme.palette.text.bright} !important`,
        backgroundColor: `${theme.palette.text.bright} !important`,
        color: `${theme.palette.primary.main} !important`,
      },
      '& $secondaryButton': {
        borderColor: `${theme.palette.text.bright} !important`,
        backgroundColor: `${theme.palette.text.bright} !important`,
        color: `${theme.palette.primary.main} !important`,
      },
    },
  },
  lobHover: {
    background: theme.palette.background.default,
    '&:hover': {
      background: mainColor,
      '& $title': {
        color: `${textColor} !important`,
      },
      '& $description': {
        color: `${textColor} !important`,
      },
      '& $listItem': {
        color: `${textColor} !important`,
      },
      '& $iconContainer': {
        backgroundColor: `${textColor} !important`,
      },
      '& $lobIcon': {
        color: `${textColor} !important`,
      },
      '& $iconStyles': {
        color: `${mainColor} !important`,
      },
      '& $primaryButton': {
        borderColor: `${textColor} !important`,
        backgroundColor: `${textColor} !important`,
        color: `${mainColor} !important`,
      },
      '& $secondaryButton': {
        borderColor: `${textColor} !important`,
        backgroundColor: `${textColor} !important`,
        color: `${mainColor} !important`,
      },
    },
  },
  action: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  icon: {
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.h3.fontSize,
      // paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.h4.fontSize,
    },
  },
  textIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  description: {
    display: 'block' /* or inline-block */,
    textOverflow: 'ellipsis',
    whiteSpace: 'wrap',
    overflow: 'hidden',
    maxHeight: '7.2em',
    lineHeight: '1.8em',
    width: '100%',
    // textAlign: 'left !important',
    color: theme.palette.text.label,
    transition: theme.effects.transition,
  },
  listItem: {
    color: `${theme.palette.text.label} !important`,
  },
  gridPadding: {
    // paddingTop: theme.spacing(1),
    height: '100%',
  },
  button: {
    width: '100%',
  },
  listContainer: {
    display: 'inline-block',
  },
  tagsHover: {
    display: 'block',
    '&:hover': {
      display: 'none',
    },
  },
});
