export const IssueListStyles = {
  pagination: {
    '.css-yy5a2z-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected': {
      backgroundColor: 'transparent',
      color: '#332A86',
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
  },
};
