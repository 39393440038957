import { gql } from 'graphql-request';

const DROPDOWN_CHOICE_FIELDS = gql`
  fragment dropdownChoice on ComponentWizardComponentsDropdownChoice {
    DropdownChoice_slug: slug
    DropdownChoice_name: name
    DropdownChoice_label: label
    DropdownChoice_variant: variant
    DropdownChoice_labelAlign: labelAlign
    DropdownChoice_options: options {
      ...nonRecursiveOption
    }
    DropdownChoice_multiple: multiple
    DropdownChoice_staticValue: staticValue
    DropdownChoice_reducerKeyValue: reducerKeyValue
    DropdownChoice_positionNo: positionNo
    DropdownChoice_layoutPosition: layoutPosition
    DropdownChoice_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    DropdownChoice_fullWidth: fullWidth
    DropdownChoice_useSampleData: useSampleData
    DropdownChoice_required: required
    DropdownChoice_tooltip: tooltip
    DropdownChoice_tooltipUrl: tooltipUrl
    DropdownChoice_isConditionalComponent: isConditionalComponent
    DropdownChoice_conditionalValue: conditionalValue
    DropdownChoice_conditionalKey: conditionalKey
    DropdownChoice_conditionalOperator: conditionalOperator
    DropdownChoice_conditionalFieldType: conditionalFieldType
    DropdownChoice_conditionalAction: conditionalAction
    DropdownChoice_conditionalArrayKey: conditionalArrayKey
    DropdownChoice_disable: disable
    DropdownChoice_show: show
    DropdownChoice_filterSampleDataByAction: filterSampleDataByAction
    DropdownChoice_filterSampleDataByLob: filterSampleDataByLob
    DropdownChoice_useChip: useChip
    DropdownChoice_chipIcon: chipIcon
    DropdownChoice_storeKey: storeKey
    DropdownChoice_optionLabelKey: optionLabelKey
    DropdownChoice_optionValueKey: optionValueKey
    DropdownChoice_selectAll: selectAll
    DropdownChoice_parentStoreKey: parentStoreKey
    DropdownChoice_findKey: findKey
    DropdownChoice_idKey: idKey
    DropdownChoice_disableInitialValue: disableInitialValue
    DropdownChoice_useAllInputs: useAllInputs
    DropdownChoice_groupTitle: groupTitle
    DropdownChoice_optionGroupKey: optionGroupKey
    DropdownChoice_isPayloadStored: isPayloadStored
    DropdownChoice_apiCalls: apiCalls {
      ...formApiCall
    }
    DropdownChoice_placeholder: placeholder
    DropdownChoice_filterOutByKey: filterOutByKey
    DropdownChoice_reloadWizard: reloadWizard
    DropdownChoice_resetCountWhenUnMount: resetCountWhenUnMount
    DropdownChoice_resetCountWhenMount: resetCountWhenMount
    DropdownChoice_tooltip: tooltip
    DropdownChoice_tooltip_en: tooltip_en
    DropdownChoice_tooltip_nl: tooltip_nl
    DropdownChoice_label_en: label_en
    DropdownChoice_label_nl: label_nl
    DropdownChoice_placeholder_en: placeholder_en
    DropdownChoice_placeholder_nl: placeholder_nl
    DropdownChoice_conditions: conditions {
      ...condition
    }
    DropdownChoice_dontShowMessage: dontShowMessage
    DropdownChoice_appendKeys: appendKeys {
      ...appendKey
    }
  }
`;

// export default DROPDOWN_CHOICE_FIELDS;
// exports.default = DROPDOWN_CHOICE_FIELDS;
export default DROPDOWN_CHOICE_FIELDS;
