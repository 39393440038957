import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, DialogTitle, Typography, Box } from '@mui/material';
import { Close as CloseIcon, ArrowBack } from '@mui/icons-material';

function DialogTitleWithCloseIcon(props) {
  const { onClose, title, className, classes, sx, showBackButton, onBackClick } = props;
  return (
    <DialogTitle
      className={className}
      classes={classes}
      style={{
        width: '100%',
        p: 0,
      }}
      disableTypography
      sx={sx}
    >
      <Box display="flex" justifyContent="space-between">
        <Box>
          {showBackButton ? (
            <IconButton
              onClick={onBackClick}
              style={{ marginRight: 10, marginTop: 0, height: 40, width: 40 }}
              aria-label="Back"
            >
              <ArrowBack />
            </IconButton>
          ) : (
            <div />
          )}
          <Typography
            sx={{ fontWeight: 'bold !important', color: 'text.label' }}
            variant="h6"
          >
            {title}
          </Typography>
        </Box>
        {onClose && (
          <IconButton
            onClick={onClose}
            style={{ marginLeft: 10, right: 0, marginTop: 0, height: 40, width: 40 }}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </DialogTitle>
  );
}

DialogTitleWithCloseIcon.defaultProps = {
  onClose: () => {},
  padding: 5,
  title: '',
  disablePadding: false,
};

DialogTitleWithCloseIcon.propTypes = {
  padding: PropTypes.number,
  onClose: PropTypes.func,
  title: PropTypes.string,
  disablePadding: PropTypes.bool,
};

export default DialogTitleWithCloseIcon;
