export default (theme, align) => ({
  root: {
    // width: '100%',
    padding: theme.spacing(0.5),
  },
  paper: {
    // backgroundColor: theme.palette.background.grid,
  },
  align: {
    textAlign: align || 'left',
    borderStyle: 'solid',
  },
  none: {
    borderWidth: 0,
  },

  xxs: {
    borderWidth: theme.shape.borderWidth,
  },
  xs: {
    borderWidth: theme.shape.borderWidth * 2,
  },
  sm: {
    borderWidth: theme.shape.borderWidth * 3,
  },
  md: {
    borderWidth: theme.shape.borderWidth * 4,
  },
  lg: {
    borderWidth: theme.shape.borderWidth * 6,
  },
  xl: {
    borderWidth: theme.shape.borderWidth * 8,
  },
  neutral: {
    borderColor: theme.palette.grey[400],
  },
  grid: {
    borderColor: theme.palette.background.grid,
  },
  primary: {
    borderColor: theme.palette.primary.main,
  },
  secondary: {
    borderColor: theme.palette.secondary.main,
  },
  info: {
    borderColor: theme.palette.info.main,
  },
  warning: {
    borderColor: theme.palette.warning.main,
  },
  danger: {
    borderColor: theme.palette.error.main,
  },
  success: {
    borderColor: theme.palette.success.main,
  },
  r1: {
    borderRadius: theme.shape.borderRadius,
  },
  r2: {
    borderRadius: theme.shape.borderRadius * 2,
  },
  r3: {
    borderRadius: theme.shape.borderRadius * 3,
  },
  r4: {
    borderRadius: theme.shape.borderRadius * 4,
  },
  r5: {
    borderRadius: theme.shape.borderRadius * 5,
  },
});
