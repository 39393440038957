import React from 'react';
import useIosInstallPrompt from '../../../hooks/useIosInstallPrompt';
import useWebInstallPrompt from '../../../hooks/useWebInstallPrompt';
import { DetailsModal } from '../../modal';

import DownloadContent from '../downloadContent';

const InstallPWA = ({ bypassTimeCheck }) => {
  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt({
    bypassTimeCheck,
  });
  // console.log('iosInstallPrompt: ', iosInstallPrompt);
  const [
    installPromptEvent,
    handleWebInstallDeclined,
    handleWebInstallAccepted,
    userShouldBePromptedToInstallWeb,
  ] = useWebInstallPrompt({ bypassTimeCheck });
  // console.log('userShouldBePromptedToInstallWeb: ', userShouldBePromptedToInstallWeb);
  // console.log('webInstallPrompt: ', webInstallPrompt);

  const webInstallPrompt = installPromptEvent;
  if (!iosInstallPrompt && !installPromptEvent) {
    return null;
  }

  const title = 'Install our App';
  const onClose = iosInstallPrompt ? handleIOSInstallDeclined : handleWebInstallDeclined;
  const content = (
    <DownloadContent
      iosInstallPrompt={iosInstallPrompt}
      webInstallPrompt={webInstallPrompt}
      handleWebInstallAccepted={handleWebInstallAccepted}
    />
  );

  return (
    <>
      {(iosInstallPrompt || (webInstallPrompt && userShouldBePromptedToInstallWeb)) && (
        <DetailsModal
          fullWidth
          onClose={onClose}
          title={title}
          content={content}
          open={
            iosInstallPrompt || (webInstallPrompt && userShouldBePromptedToInstallWeb)
          }
        />
      )}
    </>
  );
};
export default InstallPWA;
