import React from 'react';
import { useDispatch, useStore, useSelector } from 'react-redux';
import DetailsModal from '../detailsModal';
import FormPage from '../../../containersV2/wizards/formDetails';
import WizardPage from '../../../containersV2/wizards/modalDetails';
import { resetWizard } from '../../../actions/wizards';
import { updateTempState } from '../../../actions/temp';
import { useRouter } from '../../../hooks';

const WizardModal = () => {
  const dispatch = useDispatch();
  const storeState = useStore().getState();
  const router = useRouter();
  const modalParams = useSelector(state => state.temp.modalParams);
  const { modalSize, actionType, modalWizardSlug } = modalParams || {};
  // console.log('modalParams: ', modalParams);

  let content = null;
  if (actionType === 'wizard' && modalWizardSlug) {
    content = (
      <WizardPage slug={modalWizardSlug} isModal disablePadding clearOnWizardLoad />
    );
  } else if (actionType === 'form' && modalWizardSlug) {
    content = <FormPage slug={modalWizardSlug} />;
  }

  return (
    <>
      {modalWizardSlug && content && (
        <DetailsModal
          // title={modalSubForm?.title}
          onClose={() => {
            dispatch(
              updateTempState.updateTempStateAction({
                modalParams: null,
              }),
            );

            const emptyModalWizardPayload = localStorage.getItem(
              'emptyModalWizardPayload',
            );
            if (emptyModalWizardPayload) {
              const customerId = storeState?.auth?.user?.id || 'none';
              const clearResponse = false;

              dispatch(
                resetWizard.resetWizardDataAction(
                  JSON.parse(emptyModalWizardPayload),
                  '',
                  customerId,
                  clearResponse,
                ),
              );
            }
          }}
          content={content}
          modalSize={modalSize}
          open
        />
      )}
    </>
  );
};

export default WizardModal;
