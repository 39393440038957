import React from 'react';
import { Card, CardContent, Chip, Stack, Typography, Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from './style';

const ChipCard = ({ data }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { title, chips, content, chipHeader } = data;
  return (
    <Card>
      <Typography className={classes.cardTitleStyles}>{title}</Typography>
      <CardContent className={classes.cardContentStyles}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={8}>
            <Typography variant="body2" color="text.disabled">
              {content}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Button
              className={classes.secondaryButton}
              sx={{ fontSize: '70%', fontWeight: 'bold' }}
              onClick={() => window.alert('This feature is still to be built.')}
            >
              View Details
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      {chips ? (
        <>
          {' '}
          <Typography className={classes.chipHeaderStyle} variant="body1">
            {chipHeader}
          </Typography>
          <Stack sx={{ margin: '10px' }} direction="row" spacing={1}>
            {chips.map(chip => (
              <Chip className={classes.chipsStyle} label={chip} />
            ))}
          </Stack>
        </>
      ) : (
        <></>
      )}
    </Card>
  );
};

export default ChipCard;
