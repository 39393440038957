import { useMediaQuery } from '@mui/material';

const useScreenWidth = props => {
  const { fixedSettings } = props || {};
  const isSm = useMediaQuery(theme => theme.breakpoints.down('md'));
  const isMd = useMediaQuery(theme => theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme => theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme => theme.breakpoints.up('xl'));
  const screenWidth = altChoice => {
    if (isSm) {
      if (altChoice === 1) return fixedSettings?.screenSize?.md || 'md';
      if (altChoice === 2) return fixedSettings?.screenSize?.lg || 'lg';
      return fixedSettings?.screenSize?.sm || 'sm';
    }
    if (isMd) {
      if (altChoice === 1) return fixedSettings?.screenSize?.sm || 'sm';
      if (altChoice === 2) return fixedSettings?.screenSize?.lg || 'lg';
      return fixedSettings?.screenSize?.md || 'md';
    }
    if (isLg) {
      if (altChoice === 1) return fixedSettings?.screenSize?.md || 'md';
      if (altChoice === 2) return fixedSettings?.screenSize?.xl || 'xl';
      return fixedSettings?.screenSize?.lg || 'lg';
    }
    if (isXl) {
      if (altChoice === 1) return fixedSettings?.screenSize?.lg || 'lg';
      if (altChoice === 2) return fixedSettings?.screenSize?.md || 'md';
      return fixedSettings?.screenSize?.xl || 'xl';
    }
    return 'sm';
  };

  return screenWidth;
};

export default useScreenWidth;
