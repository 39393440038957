import {
  GET_INVESTMENT_TRANSACTIONS_API_REQUEST,
  GET_INVESTMENT_TRANSACTIONS_API_SUCCESS,
  GET_INVESTMENT_TRANSACTIONS_API_ERROR,
} from '../../../constants/portfolio/investmentTransactionActionNames';
import { CHANGE_BALANCE_DATE_SUCCESS } from '../../../constants/portfolio/investmentActionNames';

import payloadData from './payloadData';
import {
  stringToDate,
  isoStringToDate,
  uuidv4,
  dateToString,
} from '../../../utils/functions';
import { loadTransactionCount } from '../../../utils/variables';

const createPayload = (payloadArray, startIndex = 0) => {
  if (!Array.isArray(payloadArray)) return null;
  return payloadArray.slice(0, loadTransactionCount).map((object, index) => {
    const position = startIndex + index;
    return {
      index: position,
      id: object._id ? `${object._id}` : uuidv4(), // `${object?._id}_${uiid}`,
      no: position + 1,
      type: object?.type,
      date: dateToString(isoStringToDate(object?.date)),
      quantity: object?.qty,
      price: object?.price,
      fees: object?.fees,
      interest: object?.tax, // 'N/A',
      value: object?.trxValue,
      reversed: object?.reverse,
      clientId: object?.ClientId,
      name: object?.security,
      fxRate: object?.fxRate,
      tax: object?.tax,
      secCode: object?.secCode,
      cert: object?.cert,
      income: object?.income,
      deltaDate: dateToString(isoStringToDate(object?.deltaDate)),
      xdDt: object?.xdDt,
      mult: object?.mult,
      rtBVS: object?.rtBVS,
      rtBVT: object?.rtBVT,
      rtNom: object?.rtNom,
      transactionId: object?.transactionId,
      st: object?.st,
    };
  });
};

export const initialState = {
  response: null,
  transactions: [],
  isPending: false,
  isError: false,
  isSuccess: false,
  error: null,
  count: 0,
};

export const getPayloadResponse = params => {
  // // console.log('params: ', params);
  return {
    payload: {
      payload: { transactions: payloadData(params), count: 100000 },
    },
  };
};

function investmentDetailTransactionsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_INVESTMENT_TRANSACTIONS_API_REQUEST:
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    case GET_INVESTMENT_TRANSACTIONS_API_SUCCESS: {
      const response = action?.payload;
      const payload = response?.payload;
      //! !response?.payload && response.transactions.length > 0
      // ? response.transactions[0]
      // : null;
      const startIndex = action?.params?.offset;
      const count = payload?.count || 0;
      const initialTransactions = payload?.transactions;
      const transactions =
        createPayload(initialTransactions, startIndex) || state?.transactions;
      // const hasMore = response?.payload?.hasMore || true;

      const incomingTransactions = transactions || [];
      // // console.log('incomingTransactions: ', incomingTransactions);
      const existingTransactions =
        startIndex && startIndex > 0 ? [...state?.transactions] : [];
      // // console.log('existingTransactions: ', existingTransactions);

      incomingTransactions.map(incomingTransaction => {
        const index = existingTransactions.findIndex(
          oldTransaction => oldTransaction.id === incomingTransaction.id,
        );
        // Replace item at index using native splice
        if (index !== -1) {
          existingTransactions.splice(index, 1, incomingTransaction);
        } else {
          existingTransactions.push(incomingTransaction);
        }
        return null;
      });

      existingTransactions.sort((a, b) => {
        return a.date.localeCompare(b.date); // a.id - b.id ||
      });

      // const newResponse = { payload: { transactions: existingTransactions, count } };
      // // console.log('newResponse: ', newResponse);

      // const response = action?.payload;
      // response.payload = null;

      // console.log('initialState',initialState);

      return {
        ...state,
        isPending: false,
        isError: false,
        isSuccess: true,
        response, // newResponse, // newPortfolio,
        transactions: existingTransactions,
        count,
      };
    }
    case GET_INVESTMENT_TRANSACTIONS_API_ERROR:
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        error: action.payload,
      };
    case CHANGE_BALANCE_DATE_SUCCESS: {
      return {
        ...state,
        transactions: [...initialState?.transactions],
      };
    }
    default:
      return state;
  }
}
export default investmentDetailTransactionsReducer;
