export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'inherit',
  },
  bullet: {
    display: 'inline-block',
    borderRadius: theme.shape.borderRadius,
    flexGrow: 0,
    flexShrink: 0,
    margin: 5,
  },
  authPosition: {
    bottom: '5px',
    position: 'absolute',
    textAlign: 'center',
  },
  carouselPosition: {
    padding: '0px',
    textAlign: 'center',
  },
  sm: {
    height: theme.spacing(1),
    width: theme.spacing(1),
  },
  md: {
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  lg: {
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
  neutral: {
    backgroundColor: theme.palette.grey[400],
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
  },
  info: {
    backgroundColor: theme.palette.info.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  danger: {
    backgroundColor: theme.palette.error.main,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
});
