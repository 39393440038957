import React, { useState } from 'react';
import { Button, Checkbox, FormControlLabel, Grid, Box, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import TaskIcon from '@mui/icons-material/Task';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

export function FileUpload({
  fileTitle,
  value,
  handleChange,
  error,
  name,
  acceptableExtensions,
  handleReset,
}) {
  const theme = useTheme();

  let { 0: file } = value || {};
  const [check, setCheck] = useState(!!value);
  const [fileError, setFileError] = useState('');

  const handleUpload = e => {
    const arr = e.target.files[0].name.split('.');
    if (acceptableExtensions && acceptableExtensions.includes(arr[1])) {
      if (e.target.files[0].size / (1024 * 1024) >= 10) {
        setFileError('File Size Exceeded');
      } else {
        setCheck(true);
        handleChange(e);
        setFileError('');
      }
    } else {
      setFileError('Invalid FileType');
    }
  };

  const getFileName = name => {
    const arr = name.split('.');
    return arr[0].length > 5
      ? arr[0].slice(0, 5) + '...' + arr[1]
      : arr[0] + '.' + arr[1];
  };

  const appendFileName = name => {
    const arr = name.split('.');
    return arr[0];
  };

  const removeFileUpload = () => {
    setCheck(false);
    handleReset && handleReset();
  };

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          width: '100%',
        }}
        xs={12}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            my: '.5rem',
            width: '100%',
          }}
        >
          <Grid item sx={{ wordBreak: 'break-all', width: '75%' }}>
            <FormControlLabel
              control={<Checkbox checked={check} />}
              label={
                file?.name ? `${fileTitle}_${appendFileName(file?.name)}` : `${fileTitle}`
              }
            />
          </Grid>
          <Grid item>
            {file?.name ? (
              <Grid
                sx={{
                  height: '2rem',
                  color: 'text.primary',
                  fontWeight: '400',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  fontSize: '0.75rem',
                }}
                spacing={1}
              >
                <TaskIcon
                  fontSize="small"
                  sx={{
                    color: 'success.main',
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  {getFileName(file?.name)}
                </Typography>

                <CloseIcon
                  sx={{
                    color: 'red',
                    fontSize: '1.5rem',
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                  onClick={removeFileUpload}
                />
              </Grid>
            ) : (
              <Button
                variant="outlined"
                component="label"
                sx={{
                  width: 'fit-content',
                  height: '2rem',
                  color: 'text.disabled',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '0.188rem',
                  border: `1px solid ${theme.palette.text.disabled}`,
                  borderRadius: '0.5rem',
                  fontSize: '0.75rem',
                  textTransform: 'capitalize',
                }}
              >
                <CloudUploadIcon fontSize="small" />
                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                  Upload
                </Typography>
                <input name={name} type="file" onChange={handleUpload} hidden />
              </Button>
            )}
          </Grid>
        </Grid>
        {fileError ? (
          <Box
            sx={{
              color: 'error.main',
              fontFamily: 'default',
              fontWeight: '400',
              fontSize: '0.75rem',
              lineHeight: '1.66',
              letterSpacing: '0.03333em',
              textAlign: 'left',
              width: '100%',
            }}
          >
            {fileError}
          </Box>
        ) : (
          error
        )}
      </Grid>
    </>
  );
}
