import React from 'react';
import { Link, Hidden, Breadcrumbs, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useRouter } from '../../../hooks';
import { toTitleCase } from '../../../utils/functions';
import styles from './style';
import routes from '../../../utils/variables/routes';

const LinkRouter = props => <Link {...props} component={RouterLink} underline='hover' />;

const isFirstLetterLowerCase = input => {
  const first = input?.charAt(0);
  if (first === first.toLowerCase() && first !== first.toUpperCase()) {
    // first character is a lowercase letter
    return true;
  }
  return false;
};
const MyBreadcrumb = ({ paths }) => {
  const router = useRouter();
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const { location } = router;
  const pathnames = (paths && paths.length > 0) ? paths : location.pathname.split('/').filter(x => x);

  if (location.pathname === routes.home || location.pathname === routes.index) {
    return null;
  }
  return (
    <Hidden smDown>
      <div className={classes.root}>
        <div className={classes.breadcrumb}>
          <Breadcrumbs aria-label="breadcrumb" separator="›">
            <LinkRouter className={classes.link} to="/" >
              Home
            </LinkRouter>
            {pathnames.map((value, index) => {
              const last = index === pathnames.length - 1;
              const to = `/${pathnames.slice(0, index + 1).join('/')}`;

              return last ? (
                <Typography className={classes.link} key={to}>
                  {isFirstLetterLowerCase(value) ? toTitleCase(value) : value}
                </Typography>
              ) : (
                <LinkRouter className={classes.link} to={to} key={to}>
                  {isFirstLetterLowerCase(value) ? toTitleCase(value) : value}
                </LinkRouter>
              );
            })}
          </Breadcrumbs>
        </div>
      </div>
    </Hidden>
  );
};

export default MyBreadcrumb;
