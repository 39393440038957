const styles = theme => ({
  icon: {
    fontSize: 'inherit',
  },
  avatar: {
    padding: theme.spacing(2),
  },
  xsmall: {
    padding: theme.spacing(2),
    height: '30px !important',
    width: '30px !important',
    fontSize: `15px !important`,
  },

  small: {
    padding: theme.spacing(2),
    height: '40px !important',
    width: '40px !important',
    fontSize: `20px !important`,
  },

  medium: {
    padding: theme.spacing(2),
    height: '50px !important',
    width: '50px !important',
    fontSize: `25px !important`,
  },

  large: {
    padding: theme.spacing(2),
    height: '60px !important',
    width: '60px !important',
    fontSize: `30px !important`,
  },
  xlarge: {
    padding: theme.spacing(2),
    height: '80px !important',
    width: '80px !important',
    fontSize: `40px !important`,
  },
});
export default styles;
