import React from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Grid, Avatar, Icon } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getRef } from '../../../utils/functions';
import styles from './style';

const LobIcon = props => {
  const {
    lob,
    customIcon,
    color: initialColor,
    fontColor,
    className,
    iconClassName,
    size = 'medium',
    noAvatar = false,
    invert = false,
  } = props;
  let { icon: finalIcon, materialIcon: finalMaterialIcon } = props;
  // console.log('Lob passed is', lob);
  const theme = useTheme();
  const lobCategories = useSelector(state => state.locations?.lobCategories);

  let finalColor = initialColor || theme.palette.background.chip;
  if (lob) {
    const lobObj = lobCategories.find(item => item.lob === lob);
    const { materialIcon, color, icon } = lobObj || {};
    finalIcon = icon;
    finalMaterialIcon = materialIcon;
    finalColor = getRef(theme.palette, color);
  }
  let finalFontColor = fontColor;

  if (invert) {
    const tempBackgroundColor = finalColor;
    const tempFontColor = finalFontColor;
    finalColor = tempFontColor;
    // console.log('finalColor: ', finalColor);
    finalFontColor = tempBackgroundColor;
    // console.log('finalFontColor: ', finalFontColor);
  }
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const iconContent = (
    <>
      {finalIcon && (
        <span
          style={finalFontColor && { color: finalFontColor }}
          className={classnames(`icon-${finalIcon}`, classes.icon, iconClassName, {
            [classes[size]]: noAvatar,
          })}
        />
      )}
      {finalMaterialIcon && !finalIcon && (
        <Icon
          className={classnames(classes.icon, iconClassName, {
            [classes[size]]: noAvatar,
          })}
          sx={finalFontColor && { color: finalFontColor }}
          data-nosnippet="data-nosnippet"
        >
          {finalMaterialIcon}
        </Icon>
      )}
      {customIcon && (
        <Icon
          className={classnames(classes.icon, iconClassName, {
            [classes[size]]: noAvatar,
          })}
          data-nosnippet="data-nosnippet"
          sx={fontColor && { color: fontColor }}
        >
          {customIcon}
        </Icon>
      )}
    </>
  );

  if (noAvatar) {
    return iconContent;
  }
  return (
    <Avatar
      sx={{ bgcolor: finalColor }}
      className={classnames(className, classes.avatar, classes[size])}
    >
      {iconContent}
    </Avatar>
  );
};

export default LobIcon;
