import { GET_ALL_ARTICLES_API_SUCCESS } from '../../../../constants/helpCenter/helpCenterActionNames';
import { getAllHelpArticlesApi } from '../../../../apis/helpCenter/articles';

export function getAllHelpArticlesSuccess(data) {
  return {
    type: GET_ALL_ARTICLES_API_SUCCESS,
    payload: data,
  };
}

export function getAllHelpArticlesAction(params) {
  return (dispatch, getState) => {
    return getAllHelpArticlesApi
      .getAllHelpArticles(params)
      .then(data => {
        dispatch(getAllHelpArticlesSuccess(data));
        return data;
      })
      .catch(error => {
        console.log(error);
      });
  };
}
