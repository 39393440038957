/* eslint-disable no-nested-ternary */
import React from 'react';
import Loadable from 'react-loadable';
import { useTheme } from '@mui/material/styles';
import { Spinner } from '../../common';
import { useSmall } from '../../../hooks';

const MobileStepper = Loadable({
  loader: () => import('./mobileStepper'),
  loading: Spinner,
});
const DesktopStepper = Loadable({
  loader: () => import('./desktopStepper'),
  loading: Spinner,
});
const DutchDesktopStepper = Loadable({
  loader: () => import('./dutchWizards/desktopStepper'),
  loading: () => <Spinner hasBackDrop />,
});
const DutchMobileStepper = Loadable({
  loader: () => import('./dutchWizards/mobileStepper'),
  loading: () => <Spinner hasBackDrop />,
});

const useMyWizard = wizardType => {
  const isSmall = useSmall();
  const theme = useTheme();
  const templateName = theme?.template || 'default';
  const screenSize = isSmall ? 'mobile' : 'desktop';
  const fullWizardType = `${templateName}_${screenSize}_${wizardType}`;
  const myWizards = {
    default_desktop_stepper: DesktopStepper,
    default_mobile_stepper: MobileStepper,
    dutch_desktop_stepper: DutchDesktopStepper,
    dutch_mobile_stepper: DutchMobileStepper,
  };
  return myWizards[fullWizardType] || MobileStepper;
};

export default useMyWizard;
