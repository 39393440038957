export const FIRST_NAME_ERROR_MSG = 'First name must contain atleast 3 characters';

export const LAST_NAME_ERROR_MSG = 'Last name must contain atleast 1 character';

export const FULL_NAME_ERROR_MSG = 'Full name must contain atleast 1 character';

export const TRN_NUM_ERROR_MSG = 'TRN Number must contain 9 digits';

export const POLICY_NUM_ERROR_MSG = 'Invalid Format';

export const DOB_ERROR_MSG = 'Customer should be above 18 years of age';

export const AGENT_FIRST_NAME_ERROR_MSG =
  "Agent's First name must contain atleast 2 characters";

export const AGENT_LAST_NAME_ERROR_MSG =
  "Agent's Last name must contain atleast 2 characters";

export const MOBILE_NUM_ERROR_MSG = 'Invalid Mobile Number';

export const EMAIL_ERROR_MSG = 'Invalid email address';

export const CONFIRM_EMAIL_ERROR_MSG = "Email and confirm Email doesn't match";

export const REQUIRED_MSG = 'Required';

export const INVALID_DATE_MSG = 'Invalid Date';

export const CASEID_ERROR_MSG = 'Invalid Format';
