/* eslint-disable camelcase */
// import messages_de from './de.json';
import messages_en from './en.json';
import messages_en_TT from './en-TT.json';
import messages_en_BB from './en-BB.json';
import messages_en_JM from './en-JM.json';

export const localeData = {
  // de: messages_de,
  en: messages_en,
  'en-TT': messages_en_TT,
  'en-BB': messages_en_BB,
  'en-JM': messages_en_JM,
};

// Define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these different by checking all of them
export const language =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage ||
  'en-US';

// Split locales with a region code
export const languageWithoutRegionCode = language
  ?.toLowerCase()
  ?.split(/[_-]+/)[0]
  ?.replace(/\s/g, '');
