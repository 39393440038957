// function to get stepIndex
export const getStepIndex = (wizard, response) => {
  const { stage } = response || {};
  const forms = wizard?.forms || [];
  const stepIndex = forms?.find(x => x?.key === stage)?.positionNo;
  return stepIndex;
};

export const createPrecerts = (wizard, result) => {
  const response = result;
  // getting stepIndex
  const stepIndex = wizard ? getStepIndex(wizard, response)?.toString() : '0';
  const draft = {
    ...precertsResponseMapping(response),
    stepIndex,
  };
  draft.precertId = result?._id;
  return draft;
};

function precertsResponseMapping(response) {
  const responseObject = {};
  responseObject.primaryInsurer = response?.primaryInsurer.toString();
  responseObject.procedureDate = response?.procedureDate;
  responseObject.referredBy = response?.referredBy;
  responseObject.referredTo = response?.referredTo;
  responseObject.insuredFirstName = response?.insured?.firstName;
  responseObject.insuredLastName = response?.insured?.lastName;
  responseObject.insuredFullName = `${response?.insured?.firstName} ${response?.insured?.lastName}`;
  responseObject.patientFirstName = response?.patient?.firstName;
  responseObject.patientLastName = response?.patient?.lastName;
  responseObject.patientFullName = `${response?.patient?.firstName} ${response?.patient?.lastName}`;
  responseObject.insuredDob = response?.insured?.dob;
  responseObject.providerName = response?.providerName;
  responseObject.policyEffectiveDate = response?.policyEffectiveDate;
  responseObject.email = response?.email;
  responseObject.policyNo = response?.policyNo;
  responseObject.treatmentCost = response?.treatmentCost;
  responseObject.comments = response?.comments;

  return responseObject;
}
