import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Paper, Button, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { Home as HomeIcon } from '@mui/icons-material';

import styles from './style';
// import { themeTypes } from '../../../themes';
import routes from '../../../utils/variables/routes';

const useStyles = makeStyles(styles);

const NotFound = () => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Paper elevation={2} spacing={2} className={classes.paper}>
          <Typography variant="h5">
            404: The page you are looking for isn’t here
          </Typography>
          <Typography variant="body2">
            This could be due to a broken link or an outdated URL. Try using the
            navigation
          </Typography>
          <br />
          <br />
          <Link component={RouterLink} to={routes.home}>
            <Button
              startIcon={<HomeIcon style={{ fontSize: '286%' }} />}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Go to Homepage
            </Button>
          </Link>
        </Paper>
        {/* {theme.palette.type === themeTypes.light && (
          <BackgroundImage imageUrl={communityBanner} opacity={0.5} />
        )} */}
      </div>
    </div>
  );
};

export default NotFound;
