import { crmPostCall } from '../../../axios';
import { helpCenterApi } from '../../../../utils/functions/helpCenterApis';
import { REOPENORCANCELCASEURL, SERVERERROR } from '../../constants';

export const reOpenOrCancelCaseUrl = helpCenterApi(REOPENORCANCELCASEURL);
const reOpenOrCancelCase = (state, body) => {
  const url = reOpenOrCancelCaseUrl;
  if (url) {
    return crmPostCall(state, url, body);
  }
  return Promise.reject(new Error(SERVERERROR));
};

export default { reOpenOrCancelCase };
