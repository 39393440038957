import { accessTokenStorage } from '../utils/variables';
import { mobileAndTabletCheck } from '../utils/functions';

export const getAccessToken = () => {
  const isMobileOrTablet = mobileAndTabletCheck();
  let accessToken = null;
  if (!isMobileOrTablet) {
    accessToken = window.sessionStorage.getItem(accessTokenStorage) || null;
  } else {
    accessToken = window.localStorage.getItem(accessTokenStorage) || null;
  }
  return accessToken;
};

export const removeAccessToken = () => {
  // const isMobileOrTablet = mobileAndTabletCheck();
  // if (!isMobileOrTablet && window.sessionStorage.getItem(accessTokenStorage)) {
  window.sessionStorage.removeItem(accessTokenStorage);
  // } else if (window.localStorage.getItem(accessTokenStorage)) {
  window.localStorage.removeItem(accessTokenStorage);
  // }
  return null;
};

export const setAccessToken = accessToken => {
  // const isMobileOrTablet = mobileAndTabletCheck();
  // if (!isMobileOrTablet && accessToken) {
  window.sessionStorage.setItem(accessTokenStorage, accessToken);
  // } else if (accessToken) {
  window.localStorage.setItem(accessTokenStorage, accessToken);
  // }
  return null;
};

export default {
  getAccessToken,
  removeAccessToken,
  setAccessToken,
};
