const styles = theme => ({
  root: {
    // flexGrow: 1,
    // marginTop: theme.spacing(2),
  },
  divider: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'start',
    flexGrow: 1,
    // borderBottom: `0.5px dashed ${theme.palette.getContrastText(
    //   theme.palette.background.default,
    // )}`,
    // margin: '5px',
    align: 'center',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.h3.fontSize,
  },
  headerIcon: {
    marginBottom: '5px',
  },
  header: {
    marginTop: '3px',
  },
});

export default styles;
