import { agentType } from '../../../utils/variables';
import { routerGetCall } from '../../axios';

export const getPendingClaimsUrl = `/account-services/submitted-claims`;
export const getPendingBatchClaimUrl = `/account-services/batch-claims?status=draft`;
const getPendingClaims = (state, params) => {
  const usertype = state?.auth?.usertype;
  let url = getPendingClaimsUrl;
  if(usertype === agentType){
     url = getPendingBatchClaimUrl;
  }
  return routerGetCall(state, url, params);
};

export default { getPendingClaims };
