import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore } from 'redux-persist';
import { routerMiddleware } from 'connected-react-router';
import pReducers from '../reducers';
import history from '../history';
import initialState from '../reducers/initialState';

const middleware = [thunk, routerMiddleware(history)];

const composeEnhancers = compose;
const enhancer = composeEnhancers(
  // Middleware you want to use in development:
  applyMiddleware(...middleware),
);

// export const store = createStore(persistedReducer, {}, enhancer);
// export const persistor = persistStore(store);

let store = null;
let persistor = null;
export const configureStore = encryptKey => {
  store = createStore(pReducers(encryptKey), initialState, enhancer);
  persistor = persistStore(store);
  return { store, persistor };
};

export function getStore() {
  return store;
}

export function getPersistor() {
  return persistor;
}

export default getStore();

/*
const store = createStore(pReducers, initialState, enhancer);
const persistor = persistStore(store);

export const configureStore = () => {
  return { store, persistor };
};

export function getStore() {
  return store;
}

export function getPersistor() {
  return persistor;
}

export default getStore();
*/
