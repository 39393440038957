import React from 'react';
import { useScrollTrigger, Zoom } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from './style';

function ScrollTop(props) {
  const { children, window } = props;
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" style={{ cursor: 'pointer' }}>
        {children}
      </div>
    </Zoom>
  );
}

export default ScrollTop;
