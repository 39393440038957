const styles = theme => ({
  backgroundImage: props => {
    return {
      // maxHeight: '400px',

      zIndex: -1,
      width: '100%',
      height: '100%',
      minHeight: '100%',
      objectFit: props.objectFit || 'cover',
      backgroundSize: props.objectFit || 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: props.backgroundPosition || '75% 30%',
      position: 'fixed',
      backgroundAttachment: 'fixed',
      '-webkit-background-size': props.objectFit || 'cover',
      '-moz-background-size': props.objectFit || 'cover',
      '-o-background-size': props.objectFit || 'cover',

      overflow: 'visible',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
    };
  },
});

export default styles;
