import {
  CHANGE_PASSWORD_API_REQUEST,
  CHANGE_PASSWORD_API_ERROR,
  CHANGE_PASSWORD_API_SUCCESS,
  RESET_CHANGE_PASSWORD_SUCCESS,
} from '../../constants/account/changePasswordActionNames';

import {
  UNAUTH_CHANGE_PASSWORD_API_REQUEST,
  UNAUTH_CHANGE_PASSWORD_API_ERROR,
  UNAUTH_CHANGE_PASSWORD_API_SUCCESS,
  RESET_UNAUTH_CHANGE_PASSWORD_SUCCESS,
} from '../../constants/account/unAuthChangePasswordActionNames';

import {
  CONFIRM_POLICY_API_REQUEST,
  CONFIRM_POLICY_API_ERROR,
  CONFIRM_POLICY_API_SUCCESS,
  RESET_CONFIRM_POLICY_SUCCESS,
  UNSET_CUSTOMER_API_REQUEST,
  UNSET_CUSTOMER_API_ERROR,
  UNSET_CUSTOMER_API_SUCCESS,
  UNSET_LINKED_AUTH_API_REQUEST,
  UNSET_LINKED_AUTH_API_ERROR,
  UNSET_LINKED_AUTH_API_SUCCESS,
} from '../../constants/account/confirmPolicyActionNames';

export const initialState = {
  changePasswordResponse: null,
  isChangePasswordPending: false,
  isChangePasswordError: false,
  isChangePasswordSuccess: false,
  changePasswordError: null,

  unAuthChangePasswordResponse: null,
  isAuthChangePasswordPending: false,
  isAuthChangePasswordError: false,
  isAuthChangePasswordSuccess: false,
  unAuthChangePasswordError: null,

  confirmPolicyResponse: null,
  isConfirmPolicyPending: false,
  isConfirmPolicyError: false,
  isConfirmPolicySuccess: false,
  confirmPolicyError: null,
  unsetCustomerResponse: null,
  isUnsetCustomerPending: false,
  isUnsetCustomerError: false,
  isUnsetCustomerSuccess: false,
  unsetCustomerError: null,
  unsetLinkedAuthResponse: null,
  isUnsetLinkedAuthPending: false,
  isUnsetLinkedAuthError: false,
  isUnsetLinkedAuthSuccess: false,
  unsetLinkedAuthError: null,
};

function changePasswordReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_PASSWORD_API_REQUEST:
      return {
        ...state,
        isChangePasswordPending: true,
        isChangePasswordSuccess: false,
        isChangePasswordError: false,
      };
    case CHANGE_PASSWORD_API_SUCCESS:
      return {
        ...state,
        isChangePasswordPending: false,
        isChangePasswordSuccess: true,
        isChangePasswordError: false,
        changePasswordResponse: action.payload,
      };
    case CHANGE_PASSWORD_API_ERROR:
      return {
        ...state,
        isChangePasswordPending: false,
        isChangePasswordSuccess: false,
        isChangePasswordError: true,
        changePasswordError: action.payload,
      };

    case RESET_CHANGE_PASSWORD_SUCCESS: {
      return {
        ...initialState,
        changePasswordResponse: state.changePasswordResponse,
      };
    }

    case UNAUTH_CHANGE_PASSWORD_API_REQUEST:
      return {
        ...state,
        isUnAuthChangePasswordPending: true,
        isUnAuthChangePasswordSuccess: false,
        isUnAuthChangePasswordError: false,
      };
    case UNAUTH_CHANGE_PASSWORD_API_SUCCESS:
      return {
        ...state,
        isUnAuthChangePasswordPending: false,
        isUnAuthChangePasswordSuccess: true,
        isUnAuthChangePasswordError: false,
        unAuthChangePasswordResponse: action.payload,
      };
    case UNAUTH_CHANGE_PASSWORD_API_ERROR:
      return {
        ...state,
        isUnAuthChangePasswordPending: false,
        isUnAuthChangePasswordSuccess: false,
        isUnAuthChangePasswordError: true,
        unAuthChangePasswordError: action.payload,
      };

    case RESET_UNAUTH_CHANGE_PASSWORD_SUCCESS: {
      return {
        ...initialState,
        unAuthChangePasswordResponse: state.unAuthChangePasswordResponse,
      };
    }

    case CONFIRM_POLICY_API_REQUEST:
      return {
        ...state,
        isConfirmPolicyPending: true,
        isConfirmPolicySuccess: false,
        isConfirmPolicyError: false,
      };
    case CONFIRM_POLICY_API_SUCCESS:
      return {
        ...state,
        isConfirmPolicyPending: false,
        isConfirmPolicySuccess: true,
        isConfirmPolicyError: false,
        confirmPolicyResponse: action.payload,
      };
    case CONFIRM_POLICY_API_ERROR:
      return {
        ...state,
        isConfirmPolicyPending: false,
        isConfirmPolicySuccess: false,
        isConfirmPolicyError: true,
        confirmPolicyError: action.payload,
      };

    case UNSET_CUSTOMER_API_REQUEST:
      return {
        ...state,
        isUnsetCustomerPending: true,
        isUnsetCustomerSuccess: false,
        isUnsetCustomerError: false,
      };
    case UNSET_CUSTOMER_API_SUCCESS:
      return {
        ...state,
        isUnsetCustomerPending: false,
        isUnsetCustomerSuccess: true,
        isUnsetCustomerError: false,
        unsetCustomerResponse: action.payload,
      };
    case UNSET_CUSTOMER_API_ERROR:
      return {
        ...state,
        isUnsetCustomerPending: false,
        isUnsetCustomerSuccess: false,
        isUnsetCustomerError: true,
        unsetCustomerError: action.payload,
      };

    case UNSET_LINKED_AUTH_API_REQUEST:
      return {
        ...state,
        isUnsetLinkedAuthPending: true,
        isUnsetLinkedAuthSuccess: false,
        isUnsetLinkedAuthError: false,
      };
    case UNSET_LINKED_AUTH_API_SUCCESS:
      return {
        ...state,
        isUnsetLinkedAuthPending: false,
        isUnsetLinkedAuthSuccess: true,
        isUnsetLinkedAuthError: false,
        unsetLinkedAuthResponse: action.payload,
      };
    case UNSET_LINKED_AUTH_API_ERROR:
      return {
        ...state,
        isUnsetLinkedAuthPending: false,
        isUnsetLinkedAuthSuccess: false,
        isUnsetLinkedAuthError: true,
        unsetLinkedAuthError: action.payload,
      };

    case RESET_CONFIRM_POLICY_SUCCESS: {
      return {
        ...initialState,
        confirmPolicyResponse: state.confirmPolicyResponse,
      };
    }

    default:
      return state;
  }
}
export default changePasswordReducer;
