import { drawerWidth } from '../../../utils/variables';

export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    width: '100%',

    //

    // backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    display: 'flex',
    flexWrap: 'nowrap',
    scrollbarWidth: 'none',
    // [theme.breakpoints.up('md')]: {
    //   width: `calc(100vw - (${drawerWidth}px + 30px)) !important`,
    // },
    // [theme.breakpoints.down('sm')]: {
    //   width: 'calc(100vw - 30px) !important',
    // },
    // [theme.breakpoints.down('xs')]: {
    //   width: 'calc(100vw - 10px) !important',
    // },
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    // paddingBottom: theme.spacing(2),

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});
