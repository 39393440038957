import React from 'react';
import { Card, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from './style';

const useStyles = makeStyles(styles);
const AsidePanel = ({ children, style }) => {
  const classes = useStyles(style);
  return (
    <Card classes={{ root: classes.card }} style={style}>
      {children}
    </Card>
  );
};

export default AsidePanel;
