import { crmPostCall } from '../../axios';
import { helpCenterApi } from '../../../utils/functions/helpCenterApis';
import { SERVERERROR } from '../constants';

export const getPoliciesUrl = helpCenterApi('getPoliciesUrl');
const getPolicies = (state, body) => {
  const url = getPoliciesUrl;
  if (url) {
    return crmPostCall(state, url, body);
  }
  return Promise.reject(new Error(SERVERERROR));
};
export default { getPolicies };
