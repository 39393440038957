import {
  RESET_AUTH_SUCCESS,
  RESET_AUTH_STATE_SUCCESS,
} from '../../../constants/authActionNames';

export function resetAuthAction() {
  return {
    type: RESET_AUTH_SUCCESS,
    payload: true,
  };
}

export function resetAuthStateAction() {
  return {
    type: RESET_AUTH_STATE_SUCCESS,
    payload: true,
  };
}

export default {
  resetAuthAction,
  resetAuthStateAction,
};
