import {
  SAVE_HEALTH_CLAIM_REQUEST,
  SAVE_HEALTH_CLAIM_SUCCESS,
  SAVE_HEALTH_CLAIM_ERROR,
} from '../../../constants/claimActionNames';
import { saveClaimDetailsApi } from '../../../apis/claims';
import { RESET_PENDING_CLAIMS_SUCCESS } from '../../../constants/pendingClaimActionNames';
import { resetClaimsAction } from '../resetClaims';

function saveHealthClaimsRequest() {
  return {
    type: SAVE_HEALTH_CLAIM_REQUEST,
    payload: true,
  };
}

function saveHealthClaimsSuccess(res) {
  const { payload: { c360ClaimId, draft, id } = {} } = res || {};
  return {
    type: SAVE_HEALTH_CLAIM_SUCCESS,
    payload: { c360ClaimId, draft, id },
  };
}

function saveHealthClaimsError(err) {
  return {
    type: SAVE_HEALTH_CLAIM_ERROR,
    payload: err,
  };
}

export function saveHealthClaimsAction(params, successCallback, failureCallBack) {
  return async (dispatch, getState) => {
    const state = getState();
    const c360ClaimId = state?.claims?.healthClaimId;

    const { saveClaimDetails } = saveClaimDetailsApi;
    dispatch(saveHealthClaimsRequest());
    try {
      const res = await saveClaimDetails(state, { ...params, c360ClaimId }, c360ClaimId);
      dispatch(saveHealthClaimsSuccess(res));
      dispatch({ type: RESET_PENDING_CLAIMS_SUCCESS });
      dispatch(resetClaimsAction());

      // eslint-disable-next-line no-unused-expressions
      successCallback && successCallback();
      return res;
    } catch (e) {
      dispatch(saveHealthClaimsError(e));
      // eslint-disable-next-line no-unused-expressions
      failureCallBack && failureCallBack();
      throw e;
    }
  };
}

export default { saveHealthClaimsAction };
