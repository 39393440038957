import {
  routerPostCall,
  routerGetCall,
  strapiGetCall,
  strapiPostCall,
  routerPutCall,
  routerDeleteCall,
  routerPatchCall,
} from '../../axios';

const searchCallWizard = (state, url, params, httpMethod, opts) => {
  if (httpMethod === 'routerGet') {
    return routerGetCall(state, url, params, opts);
  }
  if (httpMethod === 'routerPost') {
    return routerPostCall(state, url, params, opts);
  }
  if (httpMethod === 'routerPatch') {
    return routerPatchCall(state, url, params, opts);
  }
  if (httpMethod === 'routerPut') {
    return routerPutCall(state, url, params, opts);
  }
  if (httpMethod === 'routerDelete') {
    return routerDeleteCall(state, url, params, opts);
  }
  if (httpMethod === 'strapiGet') {
    return strapiGetCall(state, url, params);
  }
  if (httpMethod === 'strapiPost') {
    return strapiPostCall(state, url, params);
  }
  return Promise.resolve(null);
};
export default { searchCallWizard };
