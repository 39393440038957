const Styles = theme => ({
  banerBox: {
    position: 'relative',
    overflow: 'hidden',
  },

  image: props => {
    // console.log('props: ', props);
    return {
      // position: 'absolute',
      top: 0,
      left: 0,
      width:
        props?.fixedSettings?.fit === 'width' ||
        props?.fit === 'width' ||
        (props?.fit === 'auto' && props?.hasMoreWidthThanHeight)
          ? '100%'
          : 'auto',
      height:
        props?.fixedSettings?.fit === 'height' ||
        props?.fit === 'height' ||
        (props?.fit === 'auto' && !props?.hasMoreWidthThanHeight)
          ? props?.height || '100%'
          : 'auto',
      transform: props?.scale && `scale(${props?.scale})`,
      objectFit: 'cover',
      objectPosition: `${props?.offsetX} ${props?.offsetY}`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: props.backgroundPosition || '50% 50%',
      borderRadius: props?.imageBorderRadius,
      // minHeight: '250px',
    };
  },
  boxContainer: props => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    height: props?.height || 'auto',
  }),
});

export default Styles;
