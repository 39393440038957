import { gql } from 'graphql-request';

const CHECK_CHOICE_FIELDS = gql`
  fragment checkChoice on ComponentWizardComponentsCheckChoice {
    CheckChoice_slug: slug
    CheckChoice_name: name
    CheckChoice_label: label
    CheckChoice_color: color
    CheckChoice_variant: variant
    CheckChoice_horizontal: horizontal
    CheckChoice_labelAlign: labelAlign
    CheckChoice_layoutPosition: layoutPosition
    CheckChoice_staticValue: staticValue
    CheckChoice_reducerKeyValue: reducerKeyValue
    CheckChoice_positionNo: positionNo
    CheckChoice_options: options {
      ...nonRecursiveOption
    }
    CheckChoice_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    CheckChoice_fullWidth: fullWidth
    CheckChoice_required: required
    CheckChoice_isConditionalComponent: isConditionalComponent
    CheckChoice_conditionalValue: conditionalValue
    CheckChoice_conditionalKey: conditionalKey
    CheckChoice_conditionalOperator: conditionalOperator
    CheckChoice_conditionalFieldType: conditionalFieldType
    CheckChoice_conditionalAction: conditionalAction
    CheckChoice_conditionalArrayKey: conditionalArrayKey
    CheckChoice_disable: disable
    CheckChoice_show: show
    CheckChoice_tooltip: tooltip
    CheckChoice_tooltip_en: tooltip_en
    CheckChoice_tooltip_nl: tooltip_nl
    CheckChoice_label_en: label_en
    CheckChoice_label_nl: label_nl
    CheckChoice_useSampleData: useSampleData
    CheckChoice_boldLabel: boldLabel
    CheckChoice_icon: icon
    CheckChoice_checkedIcon: checkedIcon
    CheckChoice_disableInitialValue: disableInitialValue
    CheckChoice_conditions: conditions {
      ...condition
    }
    CheckChoice_filterSampleDataByAction: filterSampleDataByAction
    CheckChoice_filterSampleDataByLob: filterSampleDataByLob
    CheckChoice_storeKey: storeKey
    CheckChoice_optionLabelKey: optionLabelKey
    CheckChoice_optionValueKey: optionValueKey
    CheckChoice_optionDisableKey: optionDisableKey
    CheckChoice_optionDisableMessage: optionDisableMessage
    CheckChoice_optionDisableContent: optionDisableContent
    CheckChoice_optionDisableContentItemization: optionDisableContentItemization
    CheckChoice_position: position
    CheckChoice_isPayloadStored: isPayloadStored
    CheckChoice_dontAllowMultiSelect: dontAllowMultiSelect
    CheckChoice_conditionalToolTipMessages: conditionalToolTipMessages {
      ...appendKey
    }
    CheckChoice_optionContentKey: optionContentKey
    CheckChoice_optionLobKey: optionLobKey
    CheckChoice_contentTemplate: contentTemplate
    CheckChoice_gridSize: gridSize
    CheckChoice_useBlockStyle: useBlockStyle
  }
`;

// export default CHECK_CHOICE_FIELDS;
// exports.default = CHECK_CHOICE_FIELDS;
export default CHECK_CHOICE_FIELDS;
