import { defineMessages } from 'react-intl';

const messages = defineMessages({
  pleaseWait: {
    id: 'app_components_common_spinner_pleaseWait',
    defaultMessage: 'Please Wait',
  },
});

export default messages;
