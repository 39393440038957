import { gql } from 'graphql-request';

const OPTION_FIELDS = gql`
  fragment option on ComponentMiscComponentsOptions {
    title
    title_en
    title_nl
    secondaryTitle
    secondaryTitle_en
    secondaryTitle_nl
    value
    startIcon
    position
    sampleData
    isConditional
    conditionalKey
    conditionalValue
    conditionalArrayKey
    conditionalOperator
    conditionalFieldType
    secondaryTitleIsNumeric
    secondaryTitleIsCurrency
    secondaryTitleIsAbbrev
    secondaryTitleIsLocalize
    secondaryTitleIsPercent
    titleIsNumeric
    titleIsCurrency
    titleIsAbbrev
    titleIsLocalize
    titleIsPercent
    secondaryUnits
    primaryUnits
    key
    conditionalAction
    conditionalArrayKey
    hidden
    optionSubForm {
      ...nonRecursiveForm
    }
    content
    lob
    secondaryTitleItemFromArrayKey
    secondaryTitleItemFromArrayValue
    secondaryTitleReferenceKey
    externalLink
    extraSettings
  }
`;
/*
      optionSubForm {
      ...nonRecursiveForm
    }
    */
// export default OPTION_FIELDS;
// exports.default = OPTION_FIELDS;
export default OPTION_FIELDS;
