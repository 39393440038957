const styles = theme => ({
  label: {
    fontWeight: 'bold !important',
  },
  customCard: {
    boxShadow: `3px 3px 1px 1px ${theme.palette.background.grid}`,
  },
  quoteDetails: {
    marginLeft: theme.spacing(1),
  },
  fileDownloadIcon: {
    marginTop: theme.spacing(1),
    fontSize: '30px',
    color: `${theme.palette.primary.main} !important`,
    '&:hover': {
      color: `${theme.palette.secondary.main} !important`,
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  downloadCard: {
    '&:hover': {
      color: `${theme.palette.primary.main} !important`,
      borderColor: `${theme.palette.primary.main} !important`,
      backgroundColor: `${theme.palette.background.grid} !important`,
      boxShadow: `3px 3px 1px 1px ${theme.palette.primary.main}`,
    },
  },
});

export default styles;
