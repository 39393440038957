import { portfolioButtonStyles } from '../../../styles';

const styles = theme => ({
  ...portfolioButtonStyles(theme),

  root: {
    backgroundColor: theme.palette.background.main,
    padding: theme.spacing(1),
  },
  buttonHeight: {
    height: '100%',
  },
  card: {
    padding: theme.spacing(2),
  },
  avatar: {
    padding: theme.spacing(2),
    height: '50px !important',
    width: '50px !important',
  },
  icon: {
    [theme.breakpoints.up('sm')]: {
      fontSize: `${theme.typography.h3.fontSize} !important`,
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: `${theme.typography.h4.fontSize} !important`,
      paddingRight: theme.spacing(1),
    },
  },
  units: {
    marginLeft: theme.spacing(1),
  },
});

export default styles;
