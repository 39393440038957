import { crmPostCall } from '../../../axios';
import { helpCenterApi } from '../../../../utils/functions/helpCenterApis';
import { SERVERERROR } from '../../constants';

export const getAllHelpCenterCasesUrl = helpCenterApi('getAllHelpCenterCasesUrl');
const getAllHelpCenterCases = (state, body) => {
  const url = getAllHelpCenterCasesUrl;

  if (url) {
    return crmPostCall(state, url, body);
  }
  return Promise.reject(new Error(SERVERERROR));
};
export default { getAllHelpCenterCases };
