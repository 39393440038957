import {
  GET_ALL_HELP_CENTER_CASES_API_ERROR,
  GET_ALL_HELP_CENTER_CASES_API_SUCCESS,
  GET_ALL_HELP_CENTER_CASES_API_REQUEST,
} from '../../../../constants/helpCenter/getAllHelpCenterCases';
import { getAllHelpCenterCasesApi } from '../../../../apis/helpCenter';

function getAllHelpCenterCasesError(err) {
  return {
    type: GET_ALL_HELP_CENTER_CASES_API_ERROR,
    payload: err,
  };
}

function getAllHelpCenterCasesRequest() {
  return {
    type: GET_ALL_HELP_CENTER_CASES_API_REQUEST,
    payload: true,
  };
}

function getAllHelpCenterCasesSuccess(prams) {
  return {
    type: GET_ALL_HELP_CENTER_CASES_API_SUCCESS,
    payload: prams,
  };
}

export function getAllHelpCenterCasesAction(params) {
  return (dispatch, getState) => {
    const state = getState();
    const language = state?.locations?.language || 'en';
    dispatch(getAllHelpCenterCasesRequest());
    // return getProductsApi .getProducts(state, params)

    return getAllHelpCenterCasesApi
      .getAllHelpCenterCases(state, params)
      .then(data => {
        dispatch(getAllHelpCenterCasesSuccess(data));
        return data;
      })
      .catch(error => {
        dispatch(getAllHelpCenterCasesError(error));
        throw error;
      });
  };
}

export default {
  getAllHelpCenterCasesAction,
};
