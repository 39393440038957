import React from 'react';
import { Box, Typography, Button, CardContent, Stack, Card } from '@mui/material';

export function RequestTitleCard({ header, title, description, buttonTitle, ...props }) {
  const containedStyles = {
    background: 'primary.main',
    color: 'text.bright',
    textTransform: 'capitalize',
    '&:hover': {
      border: '2px solid primary.main',
      color: 'primary.main',
      background: 'background.default',
    },
  };

  return (
    <Box {...props}>
      <Card sx={{ boxShadow: 'none', background: 'transparent' }}>
        <Stack
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            sx={{
              color: 'primary.main',
              fontSize: '1.625rem',
              fontWeight: '500',
              mb: 4,
            }}
            variant="h5"
            component="h5"
          >
            {header}
          </Typography>
          <CardContent
            sx={{
              flexGrow: 1,
              margin: 0,
              padding: 0,
              '&:last-child': {
                pb: 0,
              },
            }}
          >
            <Typography
              color="text.label"
              variant="body1"
              sx={{
                fontFamily: 'default',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '1.25rem',
                lineHeight: '2rem',
                color: 'text.label',
                mb: 1,
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.label"
              sx={{
                fontFamily: 'default',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '1rem',
                lineHeight: '1.5rem',
                color: 'text.label',
              }}
            >
              {description}
            </Typography>
            {buttonTitle && (
              <Button variant="contained" sx={containedStyles}>
                {buttonTitle}
              </Button>
            )}
          </CardContent>
        </Stack>
      </Card>
    </Box>
  );
}
