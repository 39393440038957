import { gql } from 'graphql-request';

const EXPANSION_PANEL_CHOICE_FIELDS = gql`
  fragment expansionPanelChoice on ComponentWizardComponentsExpansionPanelChoice {
    AccordionChoice_slug: slug
    AccordionChoice_label: label
    AccordionChoice_primaryTextSourceKey: primaryTextSourceKey
    AccordionChoice_secondaryTextSourceKey: secondaryTextSourceKey
    AccordionChoice_valueSourceKey: valueSourceKey
    AccordionChoice_iconSourceKey: iconSourceKey
    AccordionChoice_storeKey: storeKey

    AccordionChoice_items: items {
      ...option
    }
    AccordionChoice_staticValue: staticValue
    AccordionChoice_reducerKeyValue: reducerKeyValue
    AccordionChoice_name: name
    AccordionChoice_positionNo: positionNo
    AccordionChoice_layoutPosition: layoutPosition
    AccordionChoice_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    AccordionChoice_fullWidth: fullWidth
    AccordionChoice_required: required
    AccordionChoice_labelAlign: labelAlign
    AccordionChoice_isConditionalComponent: isConditionalComponent
    AccordionChoice_conditionalValue: conditionalValue
    AccordionChoice_conditionalKey: conditionalKey
    AccordionChoice_conditionalOperator: conditionalOperator
    AccordionChoice_conditionalFieldType: conditionalFieldType
    AccordionChoice_conditionalAction: conditionalAction
    AccordionChoice_conditionalArrayKey: conditionalArrayKey
    AccordionChoice_disable: disable
    AccordionChoice_show: show
    AccordionChoice_borderColor: borderColor
    AccordionChoice_subGridConfig: subGridConfig {
      xs
      sm
      md
      align
    }
    AccordionChoice_source: source
    AccordionChoice_tooltip: tooltip
    AccordionChoice_tooltip_en: tooltip_en
    AccordionChoice_tooltip_nl: tooltip_nl
    AccordionChoice_label_en: label_en
    AccordionChoice_label_nl: label_nl
    AccordionChoice_useSubForm: useSubForm
    AccordionChoice_collapse: collapse
    AccordionChoice_disableInitialValue: disableInitialValue
    AccordionChoice_conditions: conditions {
      ...condition
    }
    AccordionChoice_subForm: subForm {
      ...nonRecursiveFormWrapper
    }
  }
`;

// export default EXPANSION_PANEL_CHOICE_FIELDS;
// exports.default = EXPANSION_PANEL_CHOICE_FIELDS;
export default EXPANSION_PANEL_CHOICE_FIELDS;
/*
     subForm {
      ...nonRecursiveFormWrapper
    }
    */
