import { HIDE_BACK_BUTTON_STATE } from '../../../constants/tempActionNames';

function hideBackButtonSuccess(prod) {
  return {
    type: HIDE_BACK_BUTTON_STATE,
    payload: prod,
  };
}

export function hideBackButtonAction(data) {
  return hideBackButtonSuccess(data);
}

export default {
  hideBackButtonAction,
};
