import {
  GET_INSURANCE_FILTER_SETTINGS_API_REQUEST,
  GET_INSURANCE_FILTER_SETTINGS_API_SUCCESS,
  GET_INSURANCE_FILTER_SETTINGS_API_ERROR,
} from '../../../constants/portfolio/insuranceActionNames';
// import { api } from '../../../utils/variables';
import { getInsurancesApi } from '../../../apis/portfolio';
import { getInsuranceSummaryUrl } from '../../../apis/portfolio/getInsurancesApi';
import { checkCacheValid, generateCacheKey } from '../../../utils/functions/cache';
// import { getPayloadResponse } from '../../../reducers/portfolio/insurance';

function getInsuranceFilterSettingsError(err, body) {
  return {
    type: GET_INSURANCE_FILTER_SETTINGS_API_ERROR,
    payload: err,
    body,
  };
}

function getInsuranceFilterSettingsRequest(body) {
  return {
    type: GET_INSURANCE_FILTER_SETTINGS_API_REQUEST,
    payload: true,
    body,
  };
}
function getInsuranceFilterSettingsSuccess(data, body, cacheKey) {
  return {
    type: GET_INSURANCE_FILTER_SETTINGS_API_SUCCESS,
    payload: data,
    body,
    cacheKey,
  };
}

export function getInsuranceFilterSettingsAction(params = {}, body) {
  // const apiEndpoint = {}; // ...api };
  // const response = getPayloadResponse();
  return (dispatch, getState) => {
    const state = getState();
    const alpha2 = state?.locations?.yourLocation?.alpha2;
    params.alpha2 = alpha2;
    const cacheKey = generateCacheKey({
      path: getInsuranceSummaryUrl,
      cacheParams: params,
      state,
    });

    const { isCacheValid, cache } = checkCacheValid(getState, 'insurance', cacheKey);
    if (isCacheValid) {
      dispatch(getInsuranceFilterSettingsSuccess(cache, body, cacheKey));
      return Promise.resolve(cache);
    }
    // // console.log('firstTime1: ', firstTime);
    dispatch(getInsuranceFilterSettingsRequest(body));
    return Promise.resolve(null)
      .then(() => getInsurancesApi.getInsuranceFilterSettings(state, params))

      .then(data => {
        // // console.log('data: ', data);
        // Update the app state with the results of the API call.

        dispatch(getInsuranceFilterSettingsSuccess(data, body, cacheKey));
        return data;
      })
      .catch(error => {
        dispatch(getInsuranceFilterSettingsError(error, body));
        throw error;
      });
  };
}

export default {
  getInsuranceFilterSettingsAction,
};
