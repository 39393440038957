import { gql } from 'graphql-request';

const VALUE_INPUT_FIELDS = gql`
  fragment valueInput on ComponentWizardComponentsValueInput {
    ValueInput_slug: slug
    ValueInput_name: name
    ValueInput_label: label
    ValueInput_labelAlign: labelAlign
    ValueInput_type: type
    ValueInput_variant: variant
    ValueInput_rowsMax: rowsMax
    ValueInput_multiline: multiline
    ValueInput_placeholder: placeholder
    ValueInput_layoutPosition: layoutPosition
    ValueInput_staticValue: staticValue
    ValueInput_reducerKeyValue: reducerKeyValue
    ValueInput_positionNo: positionNo
    ValueInput_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    ValueInput_bypassFormatting: bypassFormatting
    ValueInput_fullWidth: fullWidth
    ValueInput_required: required
    ValueInput_tooltip: tooltip
    ValueInput_isConditionalComponent: isConditionalComponent
    ValueInput_conditionalValue: conditionalValue
    ValueInput_conditionalKey: conditionalKey
    ValueInput_conditionalOperator: conditionalOperator
    ValueInput_conditionalArrayKey: conditionalArrayKey
    ValueInput_conditionalFieldType: conditionalFieldType
    ValueInput_conditionalAction: conditionalAction
    ValueInput_conditionalDisable: conditionalDisable
    ValueInput_disable: disable
    ValueInput_show: show
    ValueInput_showEditEmail: showEditEmail
    ValueInput_disableInitialValue: disableInitialValue
    ValueInput_emailVerificationRequired: emailVerificationRequired
    ValueInput_noDelay: noDelay
    ValueInput_bypassValidationWithShowEditEmail: bypassValidationWithShowEditEmail
    ValueInput_regExp: regExp
    ValueInput_rows: rows
    ValueInput_validationExpMessage: validationExpMessage
    ValueInput_disablePlaceholder: disablePlaceholder
    ValueInput_validationReqMessage: validationReqMessage
    ValueInput_tooltip_en: tooltip_en
    ValueInput_tooltip_nl: tooltip_nl
    ValueInput_label_en: label_en
    ValueInput_label_nl: label_nl
    ValueInput_validationExpMessage_en: validationExpMessage_en
    ValueInput_validationExpMessage_nl: validationExpMessage_nl
    ValueInput_disablePlaceholder_en: disablePlaceholder_en
    ValueInput_disablePlaceholder_nl: disablePlaceholder_nl
    ValueInput_validationReqMessage_en: validationReqMessage_en
    ValueInput_validationReqMessage_nl: validationReqMessage_nl
    ValueInput_conditionalDefaultMessage: conditionalDefaultMessage
    ValueInput_conditionalDefaultMessageKey: conditionalDefaultMessageKey
    ValueInput_removeWhitespace: removeWhitespace
    ValueInput_regExpEnabled: regExpEnabled
    ValueInput_resetCountWhenUnMount: resetCountWhenUnMount
    ValueInput_calculateExpressions: calculateExpressions {
      key
      operator
      name
      operateOn
    }
    ValueInput_makeEmptyValueTrue: makeEmptyValueTrue
    ValueInput_dontShowMessage: dontShowMessage
    ValueInput_optionDisableKey: optionDisableKey
    ValueInput_checkValueLimit: checkValueLimit {
      minLimit
      maxLimit
      key
      limitExceedMessage
      keyName
      operateOn
      maxLimitStoreKey
      minLimitStoreKey
      maxReachedMessage
    }
    ValueInput_conditions: conditions {
      ...condition
    }
    ValueInput_minLimit: minLimit
    ValueInput_minLimitMessage: minLimitMessage
    ValueInput_maxLimit: maxLimit
    ValueInput_maxLimitMessage: maxLimitMessage
    ValueInput_ignoreSpacesForLimits: ignoreSpacesForLimits
    ValueInput_isLimitANumber: isLimitANumber
    ValueInput_verifiedEmailField: verifiedEmailField
  }
`;

// export default VALUE_INPUT_FIELDS;
// exports.default = VALUE_INPUT_FIELDS;
export default VALUE_INPUT_FIELDS;
