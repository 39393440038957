import { UPDATE_TEMP_STATE } from '../../../constants/tempActionNames';

function updateTempStateSuccess(prod) {
  return {
    type: UPDATE_TEMP_STATE,
    payload: prod,
  };
}

export function updateTempStateAction(data) {
  return updateTempStateSuccess(data);
}

export default {
  updateTempStateAction,
};
