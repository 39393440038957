import {
  SIGN_OUT_API_REQUEST,
  SIGN_OUT_API_ERROR,
  SIGN_OUT_API_SUCCESS,
} from '../../../constants/authActionNames';

// import { api } from '../../../utils/variables';
import { promiseTimeout } from '../../../utils/functions';
import { signOutApi } from '../../../apis/auth';

function signOutError(err) {
  return {
    type: SIGN_OUT_API_ERROR,
    payload: err,
  };
}

function signOutRequest() {
  return {
    type: SIGN_OUT_API_REQUEST,
    payload: true,
  };
}

function signOutSuccess(user, params) {
  return {
    type: SIGN_OUT_API_SUCCESS,
    payload: user,
    params,
  };
}

export function signOutAction(params = {}) {
  // const credentials = { ..._credentials };
  // credentials.apiKey = api.apiKey;
  // credentials.apiSecret = api.apiSecret;

  //    return signOutApi
  // .signOut(credentials)
  return (dispatch, getState) => {
    const state = getState();
    dispatch(signOutRequest());
    // return Promise.resolve();
    // .then(() => promiseTimeout(200))
    return signOutApi
      .signOut(state, params)
      .then(data => {
        // console.log('here sign out action');
        dispatch(signOutSuccess(data, params));
        return data;
      })
      .catch(error => {
        dispatch(signOutSuccess(error));
        throw error;
      });
  };
}

export default {
  signOutAction,
};
