import {
  GET_GENERIC_CONTENTS_API_REQUEST,
  GET_GENERIC_CONTENTS_API_SUCCESS,
  GET_GENERIC_CONTENTS_API_ERROR,
} from '../../../constants/miscActionNames';

import getGenericContentsApi, { genericContentsUrl } from '../../../apis/misc';
import { checkCacheValid, generateCacheKey } from '../../../utils/functions/cache';

function getGenericContentsError(err) {
  return {
    type: GET_GENERIC_CONTENTS_API_ERROR,
    payload: err,
  };
}

function getGenericContentsRequest() {
  return {
    type: GET_GENERIC_CONTENTS_API_REQUEST,
    payload: true,
  };
}
function getGenericContentsSuccess(content, cacheKey) {
  return {
    type: GET_GENERIC_CONTENTS_API_SUCCESS,
    payload: content,
    cacheKey,
  };
}

export function getGenericContentsAction(params) {
  // const apiEndpoint = {}; // ...api };

  return (dispatch, getState) => {
    const state = getState();

    const cacheKey = generateCacheKey({
      path: genericContentsUrl,
      cacheParams: params,
      state,
    });

    const { isCacheValid, cache } = checkCacheValid(getState, 'misc', cacheKey);
    if (isCacheValid) {
      dispatch(getGenericContentsSuccess(cache, cacheKey));
      return Promise.resolve(cache);
    }

    dispatch(getGenericContentsRequest());
    return getGenericContentsApi
      .getGenericContents(state, params)
      .then(data => {
        // Update the app state with the results of the API call.

        dispatch(getGenericContentsSuccess(data, cacheKey));
        return data;
      })
      .catch(error => {
        dispatch(getGenericContentsError(error));
        throw error;
      });
  };
}

export default {
  getGenericContentsAction,
};
