import React from 'react';
import { Grid, LinearProgress } from '@mui/material';
import { withStyles } from '@mui/styles';

const BorderLinearProgress = withStyles({
  root: {
    height: 40,
    backgroundColor: '#E5E5E5 ',
    borderRadius: 20,
  },
  bar: {
    borderRadius: 20,
    backgroundColor: '#35AF28',
  },
})(LinearProgress);

const CustomProgressBar = ({ percentage }) => {
  // const percentage = parseInt(profileData?.profileCompleted, 10);
  //   const percentage = 40;
  // console.log(percentage);

  return (
    <div>
      <Grid container>
        <Grid item xs={11}>
          <BorderLinearProgress
            variant="indeterminate"
            // variant="determinate"
            color="secondary"
            value={percentage}
            valueBuffer={percentage}
          />
        </Grid>
        {/* <Grid item xs={1}>
          <Typography>{`${percentage}%`}</Typography>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default CustomProgressBar;
