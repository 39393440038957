import { gql } from 'graphql-request';

const DISPLAY_LIST_ITEMS_FIELDS = gql`
  fragment displayListItems on ComponentWizardComponentsDisplayListItems {
    DisplayListItems_slug: slug
    DisplayListItems_borderSize: borderSize
    DisplayListItems_borderColor: borderColor
    DisplayListItems_borderRadius: borderRadius
    DisplayListItems_primaryTextSourceKey: primaryTextSourceKey
    DisplayListItems_secondaryTextSourceKey: secondaryTextSourceKey
    DisplayListItems_iconSourceKey: iconSourceKey
    DisplayListItems_positionNo: positionNo
    DisplayListItems_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    DisplayListItems_layoutPosition: layoutPosition
    DisplayListItems_dividerColor: dividerColor
    DisplayListItems_primaryHeading: primaryHeading
    DisplayListItems_secondaryHeading: secondaryHeading
    DisplayListItems_items: items {
      ...nonRecursiveOption
    }
    DisplayListItems_paper: paper
    DisplayListItems_name: name
    DisplayListItems_itemFontSize: itemFontSize
    DisplayListItems_headingFontSize: headingFontSize
    DisplayListItems_iconFontSize: iconFontSize
    DisplayListItems_source: source
    DisplayListItems_storeKey: storeKey
    DisplayListItems_isConditionalComponent: isConditionalComponent
    DisplayListItems_conditionalValue: conditionalValue
    DisplayListItems_conditionalKey: conditionalKey
    DisplayListItems_conditionalOperator: conditionalOperator
    DisplayListItems_conditionalFieldType: conditionalFieldType
    DisplayListItems_conditionalAction: conditionalAction
    DisplayListItems_conditionalArrayKey: conditionalArrayKey
    DisplayListItems_disable: disable
    DisplayListItems_show: show
    DisplayListItems_primaryHeading_en: primaryHeading_en
    DisplayListItems_primaryHeading_nl: primaryHeading_nl
    DisplayListItems_secondaryHeading_en: secondaryHeading_en
    DisplayListItems_secondaryHeading_nl: secondaryHeading_nl
    DisplayListItems_conditions: conditions {
      ...condition
    }
  }
`;

// export default DISPLAY_LIST_ITEMS_FIELDS;
// exports.default = DISPLAY_LIST_ITEMS_FIELDS;
export default DISPLAY_LIST_ITEMS_FIELDS;
