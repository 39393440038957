import { gql } from 'graphql-request';

const WIZARD_ACTION_BUTTON_FIELDS = gql`
  fragment wizardActionButton on ComponentMiscComponentsWizardActionButton {
    title
    title_en
    title_nl
    actionType
    buttonColor
    buttonVariant
    buttonSize
    hidden
    gridItemAlign
    apiCalls {
      ...formApiCall
    }
    redirectType
    hideChevron
    redirectUrl
    resetWizardData
    isConditionalComponent
    conditionalValue
    conditionalKey
    conditionalOperator
    conditionalFieldType
    conditionalAction
    urlAppendKeys {
      ...appendKey
    }
    preAppendKeys {
      ...appendKey
    }
  }
`;

// export default WIZARD_ACTION_BUTTON_FIELDS;
// exports.default = WIZARD_ACTION_BUTTON_FIELDS;
export default WIZARD_ACTION_BUTTON_FIELDS;
