import { referralImage } from '../../../utils/variables/images';
import { portfolioButtonStyles } from '../../../styles';

export default theme => ({
  ...portfolioButtonStyles(theme),
  cardHeader: {
    backgroundImage: `url(${referralImage})`,
    height: 'auto',
    width: 'auto',
    marginBottom: '15px',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    // height: '400px',
    height: '100%',
    gap: '.3rem',
    padding: theme.spacing(2),
    boxShadow: theme.effects.boxShadow,
  },
  cardHeaderText: {
    fontWeight: 'bold !important',
    textAlign: 'left',
  },
  cardImageStyle: {
    borderRadius: '10px',
    width: '100%',
  },
  cardContentStyle: {
    textAlign: 'left !important',
    color: theme.palette.text.dark,
    padding: `0 !important`,
    // paddingTop: `${theme.spacing(1)} !important`,
    paddingBottom: `${theme.spacing(1)} !important`,
  },

  cardButtonTextStyle: {
    fontWeight: 'bold !important',
    textTransform: 'none',
  },
  description: {
    textAlign: 'left',
    // height: imageHeight,
    verticalAlign: 'middle',
    whiteSpace: 'pre-wrap',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
});
