import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Avatar, ListItemAvatar } from '@mui/material';
import {
  AccountCircle as AccountCircleIcon,
  Person as PersonIcon,
} from '@mui/icons-material';
import styles from './style';
import colors from './colors';

// import { getNameInitials } from "../../../utils/functions";

const useStyles = makeStyles(styles);

const UserAvatar = props => {
  const classes = useStyles();

  // Hash any string into an integer value
  const hashCode = str => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  };

  const intToColor = i => {
    return colors[Math.abs(i) % colors.length];
  };

  const getLetters = str => {
    if (!str) return '?';
    return str
      .trim()
      .split(' ')
      .slice(0, 2)
      .map(i => i[0].toUpperCase())
      .join('');
  };

  // Properties
  const {
    context,
    title,
    imageSrc,
    defaultCursor,
    size,
    extraComponent,
    isAuthenticated,
  } = props;

  if (context === 'standalone') {
    if (!title || title.length === 0 || !isAuthenticated) {
      return (
        <>
          <AccountCircleIcon
            className={classnames(classes[size], classes.defaultAvatar)}
          />
          {extraComponent()}
        </>
      );
    }

    if (imageSrc) {
      return (
        <>
          <Avatar alt="Avatar" src={imageSrc} className={classes[size]} />
          {extraComponent()}
        </>
      );
    }

    const nameInitials = getLetters(title);

    if (nameInitials) {
      return (
        <Avatar
          style={{ backgroundColor: intToColor(hashCode(nameInitials)) }}
          className={classes[size]}
        >
          <span className={defaultCursor && classes.nameInitials}>{nameInitials}</span>
          {extraComponent()}
        </Avatar>
      );
    }

    return (
      <AccountCircleIcon className={classnames(classes[size], classes.defaultAvatar)} />
    );
  }

  if (context === 'list') {
    if (!title || title.length === 0 || !isAuthenticated) {
      return (
        <ListItemAvatar>
          <Avatar className={classnames(classes[size], classes.defaultAvatar)}>
            <PersonIcon />
          </Avatar>
          {extraComponent()}
        </ListItemAvatar>
      );
    }

    if (imageSrc) {
      return (
        <ListItemAvatar>
          <Avatar alt="Avatar" src={imageSrc} className={classes[size]} />
          {extraComponent()}
        </ListItemAvatar>
      );
    }

    const nameInitials = getLetters(title);

    if (nameInitials) {
      return (
        <ListItemAvatar>
          <Avatar
            style={{ backgroundColor: intToColor(hashCode(nameInitials)) }}
            className={classes[size]}
          >
            <span className={defaultCursor && classes.nameInitials}>{nameInitials}</span>
            {extraComponent()}
          </Avatar>
        </ListItemAvatar>
      );
    }

    return (
      <ListItemAvatar>
        <Avatar className={classnames(classes[size], classes.defaultAvatar)}>
          <PersonIcon />
        </Avatar>
        {extraComponent()}
      </ListItemAvatar>
    );
  }

  return null;
};

UserAvatar.defaultProps = {
  context: 'standalone',
  title: '',
  imageSrc: null,
  defaultCursor: true,
  size: 'md',
  extraComponent: () => null,
};

UserAvatar.propTypes = {
  context: PropTypes.oneOf(['standalone', 'list']),
  defaultCursor: PropTypes.bool,
  imageSrc: PropTypes.string,
  size: PropTypes.oneOf(['xxxs', 'xxs', 'xs', 'sm', 'md', 'lg']),
  title: PropTypes.string,
  extraComponent: PropTypes.func,
};

export default UserAvatar;

// photoURL: "https://source.unsplash.com/user/erondu",

/*


*/
