import { TRIGGER_RECAPTCHA } from '../../../constants/authActionNames';

export function triggerRecaptchaAction(data) {
  return {
    type: TRIGGER_RECAPTCHA,
    payload: data,
  };
}

export default {
  triggerRecaptchaAction,
};
