import React from 'react';
import { CardContent, Typography, Card, Button, Box } from '@mui/material';
import { useHistory } from 'react-router-dom';

export function ContactUs({
  title,
  description,
  buttonIcon,
  buttonTitle,
  buttonClassName,
  buttonVariant,
  cardIcon,
  cardIconColor,
  btnStyles,
  url,
}) {
  const history = useHistory();

  const ChatButtonStyling = {
    color: 'primary.main',
    width: 'max-content',
    padding: '0.56rem 0.75rem',
    border: '1px solid primary.main',
    textTransform: 'capitalize',
    borderRadius: '0.5rem',
    backgroundColor: 'background.default',
    fontSize: '0.75rem',
    fontWeight: '500',
    fontFamily: 'default',
    fontStyle: 'normal',
    lineHeight: '1rem',
    '&:hover': {
      border: '1px solid primary.main',
      color: 'primary.main',
      backgroundColor: 'background.default',
      cursor: 'pointer',
    },
  };

  return (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '0rem',
        background: 'background.default',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
      }}
    >
      <CardContent
        sx={{
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'center',
          padding: 4,
          '&:last-child': {
            paddingBottom: 4,
          },
        }}
      >
        {cardIcon && (
          <Box
            sx={{
              padding: '.5rem',
              borderRadius: '50%',
              color: 'text.bright',
              background: cardIconColor || 'orangered',
              textAlign: 'center',
            }}
          >
            {cardIcon}
          </Box>
        )}
        <Typography
          color="text.label"
          gutterBottom
          variant="h6"
          component="h3"
          sx={{ textAlign: 'center', color: 'text.secondary' }}
        >
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          color="text.label"
          sx={{
            textAlign: 'center',
            fontWeight: '400',
            color: 'text.disabled',
            fontFamily: 'default',
            width: {
              xs: '100%',
              sm: '100%',
              md: '17.5rem',
            },
            height: '3rem',
            lineHeight: '1.5rem',
            fontSize: '1rem',
            mb: 2,
          }}
        >
          {description}
        </Typography>

        <Button
          onClick={() => {
            if (url) {
              history.push(url);
            }
          }}
          sx={
            !buttonIcon && !btnStyles
              ? ChatButtonStyling
              : btnStyles || { color: 'primary.main', width: 'max-content' }
          }
          startIcon={buttonIcon}
          className={buttonClassName}
          variant={buttonVariant || 'outlined'}
        >
          {buttonTitle}
        </Button>
      </CardContent>
    </Card>
  );
}
