import {
  ADD_HELP_FORM_DATA_SUCCESS,
  EMPTY_HELP_FORM_DATA_SUCCESS,
} from '../../../constants/helpCenter/helpCenterActionNames';

function emptyHelpFormDataSuccess() {
  return {
    type: EMPTY_HELP_FORM_DATA_SUCCESS,
    payload: {},
  };
}

export function emptyHelpFormDataAction() {
  return emptyHelpFormDataSuccess();
}

function addHelpFormDataSuccess(data) {
  return {
    type: ADD_HELP_FORM_DATA_SUCCESS,
    payload: data,
  };
}

export function addHelpFormDataAction(data) {
  return addHelpFormDataSuccess(data);
}

export default {
  addHelpFormDataAction,
  emptyHelpFormDataAction,
};
