import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  textCustomSizeTypes,
  textCustomColorTypes,
  textCustomRadiusTypes,
  textAlignTypes,
} from '../../../../propertyValues';
import styles from './style';

const PaperWrapper = ({
  borderRadius,
  borderColor,
  borderSize,
  paper,
  align,
  children,
  className,
  onClick,
  style,
}) => {
  const useStyles = makeStyles(theme => styles(theme, align));
  const classes = useStyles();
  if (paper) {
    return (
      <Paper
        className={classnames(
          {
            [classes[`${borderSize}`]]: borderSize,
            [classes[borderColor]]: borderColor,
            [classes[borderRadius]]: borderRadius,
          },
          classes.paper,
          classes.align,
          classes.root,
          className,
        )}
      >
        <div aria-hidden="true" onClick={onClick}>
          {children}
        </div>
      </Paper>
    );
  }
  return (
    <div
      className={classnames(
        {
          [classes[borderSize]]: borderSize,
          [classes[borderColor]]: borderColor,
          [classes[borderRadius]]: borderRadius,
        },
        classes.align,
        classes.root,
        className,
      )}
      style={style}
    >
      <div aria-hidden="true" onClick={onClick}>
        {children}
      </div>
    </div>
  );
};

PaperWrapper.defaultProps = {
  borderColor: 'none',
  borderSize: 'none',
  borderRadius: 'none',
  paper: false,
  align: 'center',
};

PaperWrapper.propTypes = {
  borderSize: PropTypes.oneOf(textCustomSizeTypes),
  borderColor: PropTypes.oneOf(textCustomColorTypes),
  borderRadius: PropTypes.oneOf(textCustomRadiusTypes),
  align: PropTypes.oneOf(textAlignTypes),
  paper: PropTypes.bool,
};

export default PaperWrapper;
