import React from 'react';
import { FormControl, TextField, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export function TextInput({
  label,
  placeholder,
  helperText,
  name,
  value,
  handleChange,
  styles,
  type,
  error,
  background,
  required,
  TextInputMargin,
  disable,
  borderRadius,
  footNote,
  width,
  ...props
}) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: '100%',
          md: width || '100%',
        },
      }}
      {...props}
    >
      <Typography
        sx={{
          color: 'text.label',
          fontSize: '1rem',
          fontWeight: '500',
          mb: '0.5rem',
          ...styles,
        }}
      >
        {label}
        {required && ' *'}
      </Typography>
      <FormControl
        fullWidth
        sx={{
          borderRadius: borderRadius || '8px',
        }}
      >
        <TextField
          fullWidth
          placeholder={placeholder}
          name={name}
          value={value}
          type={type || 'text'}
          disabled={disable}
          data-testid="textInput"
          onChange={handleChange}
          error={error}
          helperText={(error && helperText) || <i>{footNote}</i>}
          sx={{
            background: `${theme.palette.background.paper}`,
            '& .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${theme.palette.background.contentBox}`,
              borderRadius: borderRadius || '8px',
            },
            '& .MuiInputBase-input': {
              background: background || `${theme.palette.background.main}`,
              borderRadius: borderRadius || '8px',
              padding: '0.75rem 1.25rem 0.75rem 1.25rem',
            },
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: 'text.disabled',
              '&::placeholder': {
                opacity: 1,
              },
            },
          }}
        />
      </FormControl>
    </Box>
  );
}
