import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { makeStyles } from '@mui/styles';
import styles from './style';

const useStyles = makeStyles(styles);

const StatusBullet = props => {
  const {
    className,
    size,
    currIndex,
    total,
    activeColor,
    inActiveColor,
    isCarousel,
    onClick,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div
        className={classnames(
          {
            [classes.carouselPosition]: isCarousel,
          },
          { [classes.authPosition]: !isCarousel },
        )}
      >
        {Array.from({ length: total }, (v, i) => {
          const color = currIndex === i ? activeColor : inActiveColor;
          return (
            <span
              role="button"
              aria-label="Bullet"
              tabIndex={0}
              key={i}
              onKeyDown={() => onClick(i)}
              onClick={() => onClick(i)}
              className={classnames(
                {
                  [classes.bullet]: true,
                  [classes[size]]: size,
                  [classes[color]]: color,
                },
                className,
              )}
            />
          );
        })}
      </div>
    </div>
  );
};

StatusBullet.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  currIndex: PropTypes.number,
  total: PropTypes.number,
  isCarousel: PropTypes.bool,
  onClick: PropTypes.func,
  activeColor: PropTypes.oneOf([
    'neutral',
    'primary',
    'info',
    'success',
    'warning',
    'danger',
  ]),
  inActiveColor: PropTypes.oneOf([
    'neutral',
    'primary',
    'info',
    'success',
    'warning',
    'danger',
  ]),
};

StatusBullet.defaultProps = {
  className: '',
  size: 'md',
  currIndex: 0,
  total: 3,
  onClick: () => {},
  activeColor: 'primary',
  inActiveColor: 'neutral',
  isCarousel: false,
};

export default StatusBullet;
