import React from 'react';
import {
  Pagination,
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ErrorAlert, Loading } from '../../common';
import { useRouter } from '../../../hooks';
import {
  uuidv4,
  createRelativeUrl,
  splitCamelCaseToString,
} from '../../../utils/functions';
import styles from './style';

const useStyles = makeStyles(styles);

const Index = ({
  InfoCard,
  rows,
  options,
  count = 0,
  openModal,
  customInfoMessage,
  customInfoTitle,
  displayName,
  layoutConfig = { lg: 6, md: 6, sm: 12 },
  isPending,
  isError,
  isSuccess,
  headers,
  hidePagination,
  statusCode,
}) => {
  const classes = useStyles();
  const router = useRouter();
  const { query } = router;

  const { startIndex } = options;
  const { itemCount } = options;
  // // console.log('itemCount: ', itemCount);
  const itemsPerPageOptions = options.itemsPerPageOptions || [];
  const initialStartPage = Math.floor(startIndex / itemCount) + 1;

  const pageCount = Math.ceil(count / itemCount);

  const handleChangeItemsPerPage = event => {
    const newItemCount = event.target.value;
    const queryObject = { ...query };
    queryObject.startIndex = 0;
    queryObject.itemCount = newItemCount;
    const relativeUrl = createRelativeUrl({ path: router.pathname, queryObject });
    router.replace(relativeUrl);
    window.scrollTo(0, 0);
  };

  const handleChangePage = (event, value) => {
    const newStartIndex = (value - 1) * itemCount;
    const queryObject = { ...query };
    queryObject.startIndex = newStartIndex;
    queryObject.itemCount = itemCount;
    const relativeUrl = createRelativeUrl({ path: router.pathname, queryObject });
    router.push(relativeUrl);
    window.scrollTo(0, 0);
  };

  if (isPending) {
    return (
      <Loading
        secondaryTitle={`Retrieving your ${splitCamelCaseToString(displayName)}`}
      />
    );
  }

  if (isPending) {
    return (
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        {Array(itemCount)
          .fill(0)
          .map(() => {
            return (
              <Grid
                item
                sm={layoutConfig?.sm}
                md={layoutConfig?.md}
                lg={layoutConfig?.lg}
                key={uuidv4()}
                sx={{ width: '100%' }}
              >
                <InfoCard loading={isPending} />
              </Grid>
            );
          })}
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      {rows.length === 0 && !isPending ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          spacing={2}
        >
          <ErrorAlert
            isError={isError}
            isPending={isPending}
            isSuccess={isSuccess}
            infoTitle={customInfoTitle || 'Not Available'}
            errorTitle="Error retrieving data"
            infoMessage={customInfoMessage || `Sorry, you don't have any data available.`}
            errorMessage="Error retrieving your data. Please try again."
            headers={headers}
            statusCode={statusCode}
          />
        </Grid>
      ) : (
        <Grid
          container
          justifyContent="flex-start"
          alignItems="stretch"
          direction="row"
          spacing={2}
        >
          {rows.map(row => (
            <Grid
              key={row.id}
              item
              sm={layoutConfig?.sm}
              md={layoutConfig?.md}
              lg={layoutConfig?.lg}
              sx={{ height: 'auto', width: '100%' }}
            >
              <InfoCard row={row} loading={isPending} openModal={openModal} />
            </Grid>
          ))}
        </Grid>
      )}
      {!hidePagination && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
          sx={{ pt: 2 }}
        >
          <Grid item xs={2} align="left">
            <FormControl className={classes.formControl}>
              <InputLabel
                id="demo-simple-select-label"
                variant="filled"
                sx={{ top: '-8px' }}
              >
                Items
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={itemCount}
                variant="filled"
                onChange={handleChangeItemsPerPage}
              >
                {itemsPerPageOptions.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={8} align="center">
            <Pagination
              classes={{ root: classes.pagination }}
              count={pageCount}
              page={initialStartPage}
              onChange={handleChangePage}
              boundaryCount={1}
              siblingCount={1}
            />
          </Grid>
          <Grid item xs={2} align="center" />
        </Grid>
      )}
    </div>
  );
};

export default Index;
