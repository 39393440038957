import { portfolioButtonStyles } from '../../../styles';

export default theme => ({
  ...portfolioButtonStyles(theme),
  dialog: ({ isSmall, modalSize }) => ({
    backgroundColor: `${theme.palette.background.default}`,
    margin: theme.spacing(1),
    zIndex: '10 !important',
  }),
  root: ({ isSmall, modalSize }) => ({
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    width: '100%',
    overflowY: 'overlay',
    // height: '100%',
    height: isSmall ? '100%' : 'auto',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
      minHeight: '200px',
      // maxHeight: '80vh',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '300px',
      // maxHeight: '79vh',
    },
    overscroll: 'overlay',
    paddingLeft: `${theme.spacing(1)}!important`,
    paddingRight: `${theme.spacing(1)}!important`,
    paddingTop: `${theme.spacing(1)}!important`,
    paddingBottom: `${theme.spacing(1)}!important`,
  }),
  dialogActions: {
    backgroundColor: theme.palette.background.default,
  },
  headerTitle: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  noPadding: {
    padding: 0,
  },
  pinToBottom: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
  },
});
