import { agentType } from '../../../utils/variables';
import { routerDeleteCall } from '../../axios';

const deletePendingClaim = (state, params) => {
  const ind_url = `/account-services/submitted-claims/${params.claimId}`;
  const batch_url = `/account-services/batch-claims/${params.claimId}`;
  const usertype = state?.auth?.usertype;
  let url = ind_url;
  if(usertype === agentType){
     url = batch_url;
  }
  return routerDeleteCall(state, url, params);
};

export default { deletePendingClaim };
