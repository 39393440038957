export const GET_ALL_LOCATIONS_API_REQUEST = 'GET_ALL_LOCATIONS_API_REQUEST';
export const GET_ALL_LOCATIONS_API_SUCCESS = 'GET_ALL_LOCATIONS_API_SUCCESS';
export const GET_ALL_LOCATIONS_API_ERROR = 'GET_ALL_LOCATIONS_API_ERROR';
export const GET_YOUR_LOCATION_API_REQUEST = 'GET_YOUR_LOCATION_API_REQUEST';
export const GET_YOUR_LOCATION_API_SUCCESS = 'GET_YOUR_LOCATION_API_SUCCESS';
export const GET_YOUR_LOCATION_API_ERROR = 'GET_YOUR_LOCATION_API_ERROR';

export const UPDATE_YOUR_LOCATION_API_REQUEST = 'UPDATE_YOUR_LOCATION_API_REQUEST';
export const UPDATE_YOUR_LOCATION_API_SUCCESS = 'UPDATE_YOUR_LOCATION_API_SUCCESS';
export const UPDATE_YOUR_LOCATION_API_ERROR = 'UPDATE_YOUR_LOCATION_API_ERROR';

export const UPDATE_YOUR_LANGUAGE_SUCCESS = 'UPDATE_YOUR_LANGUAGE_SUCCESS';

export const GET_YOUR_APP_SETTINGS_API_REQUEST = 'GET_YOUR_APP_SETTINGS_API_REQUEST';
export const GET_YOUR_APP_SETTINGS_API_SUCCESS = 'GET_YOUR_APP_SETTINGS_API_SUCCESS';
export const GET_YOUR_APP_SETTINGS_API_ERROR = 'GET_YOUR_APP_SETTINGS_API_ERROR';
