import { toastrStyles } from '../../../styles';

const searchStyles = theme => ({
  ...toastrStyles(theme),
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  navSearchBox: props => ({
    display: 'flex',
    padding: theme.spacing(0),
    textAlign: 'center',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    minWidth: props.hasFilter ? '90% !important' : '100% !important',
  }),
  searchButton: {
    // marginLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
      // color: theme.palette.secondary.main,
      // background: `${theme.palette.background.default} 0% 0% no-repeat padding-box`,
      // boxShadow: `0px ${theme.spacing(1)} ${theme.spacing(2)} #00000029 !important`,
    },
  },
  popPaper: {
    // left: '0px !important',
    width: '100% !important',
  },
  lineClamp: {
    color: theme.palette.text.paragraph,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    // Addition lines for 2 line or multiline ellipsis
    display: '-webkit-box !important',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'normal',
  },
  clearIndicator: {
    display: 'none',
  },
  textField: {
    padding: theme.spacing(0),
    marginLeft: theme.spacing(1),
    // marginBottom: theme.spacing(1),
  },
  button: {
    margin: theme.spacing.unit,
    marginLeft: theme.spacing(1),
  },
  searchIcon: {
    cursor: 'pointer',
  },
  resultOption: {
    '&:hover': {
      backgroundColor: theme.palette.background.main,
    },
  },
});

export default searchStyles;
