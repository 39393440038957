import { gql } from 'graphql-request';

const NEEDS_SUMMARY_CARD_FIELDS = gql`
  fragment needsSummaryCard on ComponentWizardComponentsNeedsSummaryCard {
    NeedsSummaryCard_slug: slug
    NeedsSummaryCard_positionNo: positionNo
    NeedsSummaryCard_layoutPosition: layoutPosition
    NeedsSummaryCard_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    NeedsSummaryCard_name: name
    NeedsSummaryCard_isConditionalComponent: isConditionalComponent
    NeedsSummaryCard_conditionalValue: conditionalValue
    NeedsSummaryCard_conditionalKey: conditionalKey
    NeedsSummaryCard_conditionalOperator: conditionalOperator
    NeedsSummaryCard_conditionalFieldType: conditionalFieldType
    NeedsSummaryCard_conditionalAction: conditionalAction
    NeedsSummaryCard_conditionalArrayKey: conditionalArrayKey
  }
`;

// export default NEEDS_SUMMARY_CARD_FIELDS;
// exports.default = NEEDS_SUMMARY_CARD_FIELDS;
export default NEEDS_SUMMARY_CARD_FIELDS;
