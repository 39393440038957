import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Stack, useMediaQuery } from '@mui/material';
import { createTheme } from '@mui/material/styles';

import { useFormik } from 'formik';

import { toastr } from 'react-redux-toastr';
import {
  TextInput,
  ButtonComponent,
} from '../../../../componentsV2/helpCenter/components';
import { toastrOptions } from '../../../../utils/functions';

import { getSingleHelpCenterCaseAction } from '../../../../actions/helpCenter/cases/getSingleHelpCenterCase';
import { useRouter } from '../../../../hooks';
import { Spinner } from '../../../../componentsV2/common';
import {
  statusCheckTitle,
  statusCheckHeader,
  Authenticated_statusCheckDescription,
  UnAuthenticated_statusCheckDescription,
  goToLogin,
  submitBtnContent,
} from '../../../../componentsV2/helpCenter/messages';

import {
  caseIDData,
  lastNameInputData,
  fullNameInputData,
} from '../../../../componentsV2/helpCenter/mockData';
import { addHelpFormDataAction } from '../../../../actions/helpCenter/helpFormData';
import { ValidateCheckStatus } from '../../requestForms/gloc/validationSchemaJM/validationSchema';

const CheckStatusComp = ({ territory }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  const isSmall = () => {
    const theme = createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 899,
          lg: 1200,
          xl: 1536,
        },
      },
    });

    const isSm = useMediaQuery(theme?.breakpoints?.down('lg'));
    return isSm;
  };

  const formik = useFormik({
    initialValues: {
      caseID: '',
      fullName: '',
    },
    validationSchema: () => {
      return ValidateCheckStatus(formik.values);
    },
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      const params = {
        caseNumber: values.caseID,
        fullName: values.fullName.trim(),
      };
      dispatch(addHelpFormDataAction({ fullName: values.fullName.trim() }));
      dispatch(getSingleHelpCenterCaseAction(params))
        .then(() => {
          setIsLoading(false);
          router.push(`/help/status/details/${params.caseNumber}`);
        })
        .catch(err => {
          setIsLoading(false);
          toastr.error(
            `${
              err.message === undefined
                ? 'Something went wrong, Please Try again'
                : err.message
            }`,
            toastrOptions({
              error: 'error',
            }),
          );
        });
    },
  });

  return (
    <>
      <Stack>
        <Stack
          sx={{
            m: 2,
          }}
        >
          <Grid container direction="column" alignItems="flex-start">
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{
                color: 'primary.main',
                fontFamily: 'default',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '1.625rem',
                lineHeight: '2.25rem',
                mb: 4,
              }}
            >
              {statusCheckTitle}
            </Typography>

            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{
                color: 'text.label',
                fontFamily: 'default',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '1rem',
                lineHeight: '1.5rem',
                mb: 12,
              }}
            >
              {statusCheckHeader}
            </Typography>
          </Grid>
          <Stack
            alignItems={isSmall() ? 'center' : 'flex-start'}
            justifyContent={isSmall() ? 'center' : 'flex-start'}
            sx={{
              backgroundColor: 'background.default',
              p: 5,

              boxShadow: '0rem 0.063rem 0.25rem rgba(0, 0, 0, 0.1)',
              borderRadius: '0.5rem',
            }}
          >
            {isAuthenticated ? (
              <Typography
                textAlign={isSmall() ? 'start' : 'start'}
                sx={{
                  mb: 4,
                  color: 'text.label',
                  fontFamily: 'default',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '1rem',
                  lineHeight: '1.5rem',
                }}
              >
                {Authenticated_statusCheckDescription.text1}
                <br />
                {Authenticated_statusCheckDescription.text2}
              </Typography>
            ) : (
              <Typography
                textAlign={isSmall() ? 'start' : 'start'}
                sx={{
                  mb: 4,
                  color: 'text.label',
                  fontFamily: 'default',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '1rem',
                  lineHeight: '1.5rem',
                }}
              >
                {UnAuthenticated_statusCheckDescription.text1}
                <br />
                {UnAuthenticated_statusCheckDescription.text2}
              </Typography>
            )}
            <Stack
              direction={isSmall() ? 'column' : 'row'}
              alignItems="center"
              sx={{
                width: '100%',
              }}
            >
              {!isAuthenticated && (
                <Stack
                  direction={isSmall() ? 'column' : 'row'}
                  alignItems="center"
                  sx={{
                    marginBottom: isSmall() ? '0.938rem' : '0.4rem',
                  }}
                >
                  <ButtonComponent
                    marginTop={isSmall() ? '0.063rem' : '0.2rem'}
                    marginBottom={isSmall() ? 'initial' : '0.2rem'}
                    marginLeft={isSmall() ? '0%' : '0.625rem'}
                    styles={{
                      width: isSmall() ? '100%' : '7.5rem',
                      height: '2.813rem',
                      maxWidth: '18.75rem',
                      backgroundColor: 'primary.main',
                    }}
                    variant="contained"
                    onClick={() => router.push(`/auth/sign-in`)}
                    size="small"
                    content={goToLogin}
                  />
                </Stack>
              )}
              <Stack
                direction={isSmall() ? 'column' : 'row'}
                alignItems="center"
                sx={{
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                {!isAuthenticated && (
                  <Typography
                    mb="1rem"
                    color="text.label"
                    sx={{ margin: isSmall() ? '0.938rem' : '0 0.938rem' }}
                  >
                    Or
                  </Typography>
                )}
                <Stack
                  direction={isSmall() ? 'column' : 'row'}
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                  maxWidth="60rem"
                >
                  <TextInput
                    label={caseIDData.label}
                    name={caseIDData.name}
                    width="100%"
                    mb="1rem"
                    maxWidth="26rem"
                    placeholder={caseIDData.placeholder}
                    borderRadius="0.5rem"
                    textAlign="left"
                    value={formik.values.caseID}
                    handleChange={formik.handleChange}
                    styles={{
                      fontFamily: 'default',
                      fontStyle: 'normal',
                      lineHeight: '1.5rem',
                      color: 'text.label',
                      fontSize: '1rem',
                      fontWeight: '500',
                      m: '0.5rem 0',
                    }}
                    type="text"
                    onBlur={formik.handleBlur}
                    error={formik.touched.caseID && !!formik.errors.caseID}
                    helperText={(formik.touched.caseID && formik.errors.caseID) || ' '}
                  />
                  <Typography
                    color="text.label"
                    mb="1rem"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: '0 0.938rem',
                      height: 'inherit',
                      visibility: isSmall() ? 'hidden' : 'initial',
                    }}
                  >
                    +
                  </Typography>
                  <TextInput
                    label={fullNameInputData.label}
                    name={fullNameInputData.name}
                    width="100%"
                    maxWidth="26rem"
                    mb="1rem"
                    placeholder={fullNameInputData.placeholder}
                    borderRadius="0.5rem"
                    textAlign="left"
                    value={formik.values.fullName}
                    handleChange={formik.handleChange}
                    styles={{
                      fontFamily: 'default',
                      fontStyle: 'normal',
                      lineHeight: '1.5rem',
                      color: 'text.label',
                      fontSize: '1rem',
                      fontWeight: '500',
                      m: '0.5rem 0',
                    }}
                    type="text"
                    onBlur={formik.handleBlur}
                    error={formik.touched.fullName && !!formik.errors.fullName}
                    helperText={
                      (formik.touched.fullName && formik.errors.fullName) || ' '
                    }
                  />
                  <ButtonComponent
                    marginTop={isSmall() ? '0.063rem' : 'initial'}
                    marginBottom={isSmall() ? 'initial' : '0.5rem'}
                    marginLeft={isSmall() ? '0%' : '0.625rem'}
                    styles={{
                      width: isSmall() ? '100%' : '5.625rem',
                      height: '2.813rem',
                      maxWidth: '18.75rem',
                      marginLeft: isSmall() ? '0' : '0.625rem',
                      background: 'primary.main',
                      pointerEvents: 'initial',
                    }}
                    variant="contained"
                    size="small"
                    content={submitBtnContent}
                    disabled={!formik.dirty || !formik.isValid}
                    onClick={formik.handleSubmit}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {isLoading && <Spinner message="Searching for Cases" />}
    </>
  );
};

export default CheckStatusComp;
