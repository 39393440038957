import { routerGetCall, routerPostCall } from '../../axios';

export const getInvestmentCalculationsUrl = `/accounts/viewPortfolio/getCalculations`;
export const getInvestmentsUrl = `/accounts/viewPortfolio/investments`;

const getInvestments = (state, params) => {
  return routerGetCall(state, getInvestmentsUrl, params);
};

const getInvestmentCalculations = (state, params) => {
  return routerPostCall(state, getInvestmentCalculationsUrl, params);
};
export default { getInvestments, getInvestmentCalculations };
