import { gql } from 'graphql-request';

const SURVEY_FIELDS = gql`
  fragment survey on ComponentWizardComponentsSurvey {
    Survey_slug: slug
    Survey_positionNo: positionNo
    Survey_layoutPosition: layoutPosition
    Survey_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    Survey_name: name
    Survey_isConditionalComponent: isConditionalComponent
    Survey_conditionalValue: conditionalValue
    Survey_conditionalKey: conditionalKey
    Survey_conditionalOperator: conditionalOperator
    Survey_conditionalFieldType: conditionalFieldType
    Survey_conditionalAction: conditionalAction
    Survey_conditionalArrayKey: conditionalArrayKey
    Survey_eventSlug: eventSlug
    Survey_conditions: conditions {
      ...condition
    }
  }
`;

// export default SURVEY_FIELDS;
// exports.default = SURVEY_FIELDS;
export default SURVEY_FIELDS;
