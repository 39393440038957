import { routerGetCall } from '../../axios';

export const getReceiptsUrl = `/accounts/receipts`;
const getReceipts = (state, params) => {
  const url = getReceiptsUrl;
  const opts = {
    allowGuestSession: true,
  };
  return routerGetCall(state, url, params, opts);
};
export default { getReceipts };
