export default [
  {
    Id: 4444444,
    title: 'Dr. ',
    firstName: 'Balakrishna',
    lastName: 'Kumar',
    company: 'Caribbean Vitreous & Retina Surgery',
    serviceProvider: 'Life Provider',
    address: [
      {
        town: 'Maraval',
        countryId: 'Trinidad',
        cardinalPoint: 'NORTH',
        contact: ['868-235-4834', '868-934-9845'],
        emailAddress: 'customerservice@trinidadeyehospital.org',
        website: 'www.trinidadeyehospital.org',
        geoLocation: '10.683117192909974, -61.523274932397754',
        addressLine: 'Royal Palm Suite Hotel',
        providerSpecialties: [
          {
            label: 'OPHTHALMOLOGIST',
          },
          {
            label: 'DENTIST',
          },
        ],
      },
      {
        town: 'St. Augustine ',
        countryId: 'Trinidad',
        cardinalPoint: 'EAST',
        contact: ['868-235-4834', '868-934-9845'],
        emailAddress: 'customerservice@trinidadeyehospital.org',
        website: 'www.trinidadeyehospital.org',
        geoLocation: '10.651449615981898, -61.40256357287966',
        addressLine: 'TIMT Bldg, Cor. Warren & Private Road',
        providerSpecialties: [
          {
            label: 'OPHTHALMOLOGIST',
          },
        ],
      },
      {
        town: 'Gulf View',
        countryId: 'Trinidad',
        cardinalPoint: 'SOUTH',
        contact: ['868-235-4834', '868-934-9845'],
        emailAddress: 'customerservice@trinidadeyehospital.org',
        website: 'www.trinidadeyehospital.org',
        geoLocation: '10.257371058067307, -61.470088405414934',
        addressLine: 'Maska Limited, South Trunk Road',
        providerSpecialties: [
          {
            label: 'OPHTHALMOLOGIST',
          },
        ],
      },
    ],
  },
];
