import { transformConditionalValue, transformConditionalStatus, getRef } from '.';

export default (storeState, props) => {
  let newAction = null;
  newAction = props?.action ? { ...props?.action } : { ...props }; // [wizardType];

  return { newAction }; // show: tempShow, disable: tempDisable
};

/*
  const {
    materialIcon,
    title,
    description,
    loading,
    isSmall,
    wizardType,
    lob,
    data = {},
  } = props;

  const newConditionalValue = transformConditionalValue(
    newAction?.conditionalValue,
    newAction?.conditionalFieldType,
  );

  const newConditionalStatus = transformConditionalStatus(
    newConditionalValue,
    newAction?.conditionalOperator,
    getRef(storeState, newAction?.conditionalKey),
    newAction?.conditionalFieldType,
  );

  const active =
    !newAction?.isConditional || (newAction?.isConditional && newConditionalStatus);

  let tempDisable = false;
  let tempShow = true;


  if (
    newAction?.isConditional &&
    !newConditionalStatus &&
    newAction?.conditionalAction === 'show'
  ) {
    tempShow = false;
  }
  if (active && newAction?.conditionalAction === 'hide') {
    tempShow = false;
  }
  if (active && newAction?.conditionalAction === 'show') {
    tempDisable = false;
    tempShow = true;
  }
  if (active && newAction?.conditionalAction === 'disable') {
    tempDisable = true;
  }
  if (active && newAction?.conditionalAction === 'enable') {
    tempDisable = false;
  }
  */
