const styles = theme => ({
  filter: {
    cursor: 'pointer',
    border: 'none',
  },
  filterFont: {
    ...theme.typography.body2,
    textTransform: 'capitalize',
  },
  marginVertical: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
});

export default styles;
