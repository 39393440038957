import {
  GET_SURVEY_API_REQUEST,
  GET_SURVEY_API_SUCCESS,
  GET_SURVEY_API_ERROR,
} from '../../../constants/miscActionNames';

// import { api } from '../../../utils/variables';
// import getSurveyApi from '../../../apis/misc';
import makeGraphQuery from '../../../queries';

import getOneSurvey from '../../../queries/surveys';

function getSurveyError(err) {
  return {
    type: GET_SURVEY_API_ERROR,
    payload: err,
  };
}

function getSurveyRequest() {
  return {
    type: GET_SURVEY_API_REQUEST,
    payload: true,
  };
}
function getSurveySuccess(content) {
  return {
    type: GET_SURVEY_API_SUCCESS,
    payload: content,
  };
}

export function getSurveyAction(params) {
  // const apiEndpoint = {}; // ...api };

  return (dispatch, getState) => {
    const state = getState();
    dispatch(getSurveyRequest());
    // return getSurveyApi.getSurvey(state, params)
    return makeGraphQuery(getOneSurvey, params)
      .then(data => {
        // Update the app state with the results of the API call.

        dispatch(getSurveySuccess(data?.c360SurveyEvents));
        return data;
      })
      .catch(error => {
        dispatch(getSurveyError(error));
        throw error;
      });
  };
}

export default {
  getSurveyAction,
};
