const styles = theme => ({
  title: {
    fontWeight: 'bold',
    color: theme.palette.text.label,
  },
  card: ({ backgroundColor, color, ...restProps }) => ({
    padding: theme.spacing(2),
    height: '100%',
    color: color || `${theme.palette.text.bright} !important`,
    backgroundColor: backgroundColor || `${theme.palette.background.chip} !important`,
    ...restProps,
  }),
});

export default styles;
