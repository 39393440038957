export default theme => ({
  noTitlePadding: {
    paddingTop: theme.spacing(3),
  },
  dialogActions: {
    backgroundColor: theme.palette.background.grid,
  },
  headerTitle: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  dialogContentText: {
    color: theme.palette.text.primary,
  },
  modalBox: {
    maxWidth: '350px !important',
  },
});
