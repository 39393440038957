// app.js is generally used as a wrapper component, where it is a regular component that only wraps
// the react application with third party or library required components that pass datadown thorugh the app
import React, { useContext, useEffect } from 'react';
import { Provider } from 'react-redux';
import Loadable from 'react-loadable';
// let's our entire app get access to our redux store
import { PersistGate } from 'redux-persist/integration/react';
import ReduxToastr from 'react-redux-toastr';

import { ConnectedRouter } from 'connected-react-router';

// date utility class to perform date operation and is require to
// allow MuiPickersUtilsProvider to show dates properly.

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// let's our entire app get access to using fancy date calendar react components
import CssBaseline from '@mui/material/CssBaseline';
// cssbaseline is just a utility class to entire no padding is applied to our application
import { StyledEngineProvider } from '@mui/material/styles';
import ThemeProvider from './providers/theme';
import ConsentProvider from './providers/consent';
import { EncryptKeyContext } from './context';
// let's our entire app have a consistent UX design  across all material ui react ui components
// involving colors, fonts, and sizes.
import Main from './main';
// this is the main routing point of the application and is where all the pages in the application connects to
import { configureStore } from './store';
// this store is used to store all the data and manage sending and
// receiving of data between the store and the components
import history from './history';
import DevTools from './devTools';
import PromptProvider from './providers/prompt';
import ScrollToTop from './routes/scrollToTop';
// import SyncWithPublicSite from './components/common/syncData/syncWithPublicSite';
import TokenListener from './componentsV2/common/tokenListener';
import AppLogout from './componentsV2/common/appLogout';
import IOSPrompt from './componentsV2/pwa/iosPrompt';
import SplashScreen from './componentsV2/common/splashScreen';
import { domain } from './utils/variables';
import { version } from '../package.json';
import './App.css';

const App = () => {
  const { encryptKey, setEncryptKey } = useContext(EncryptKeyContext);
  // console.log('encryptKey: ', encryptKey);
  // const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
  // console.log('publicUrl: ', publicUrl);
  // console.log('process.env.PUBLIC_URL: ', process.env.PUBLIC_URL);
  // console.log('process.env.NODE_ENV: ', process.env.NODE_ENV);
  // console.log('domain: ', domain);
  console.log('app-version', version);
  useEffect(() => {
    if (
      window.location.href.indexOf(domain.pwa) < 0 &&
      window.location.href.indexOf('localhost') < 0 &&
      (domain?.env === 'production' || domain?.env === 'staging')
    ) {
      if (window.location.pathname === '/') window.location.href = domain.pwa;
      else
        window.location.href =
          domain.pwa + window.location.pathname + window.location.search;
    }
  }, []);

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    // import theme from "../themes/dark";

    // console.log('consentValue: ', consentValue);
  }, []);

  if (process.env.CUSTOM_NODE_ENV === 'production') {
    console.log = function() {};
  }

  /*
  const addStyle = url => {
    const style = document.createElement('link');
    style.href = url;
    style.rel = 'stylesheet';
    style.async = true;
    document.head.appendChild(style);
  };

  useEffect(() => {
    const cssUrl = `${domain.strapi}/icons/style.css`;
    addStyle(cssUrl);
  }, []);
  */

  /*
  useEffect(() => {
    const scriptTag = document.createElement('script');
    scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${getGATrackingID()}`;
    scriptTag.async = true;
    document.body.appendChild(scriptTag);
  }, []);
  */

  const { store, persistor } = configureStore(encryptKey);
  if (!store) {
    return null;
  }

  return (
    <Provider store={store}>
      <ConsentProvider>
        <ConnectedRouter history={history}>
          <StyledEngineProvider injectFirst>
            <PromptProvider>
              <ThemeProvider>
                <PersistGate loading={<SplashScreen />} persistor={persistor}>
                  <IOSPrompt />
                  <TokenListener />
                  <AppLogout />

                  <ScrollToTop />

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <CssBaseline />
                    <ReduxToastr />

                    <Main />
                  </LocalizationProvider>
                  <DevTools />
                </PersistGate>
              </ThemeProvider>
            </PromptProvider>
          </StyledEngineProvider>
        </ConnectedRouter>
      </ConsentProvider>
    </Provider>
  );
};

export default App;

/*

  import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { updateServiceworker } from 'service-worker-redux'
import configureStore from 'store/configure-store'
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

const store = configureStore()

const onUpdate = () => {
  store.dispatch(updateServiceworker())
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
registerServiceWorker(onUpdate);
*/

/*
export function updateServiceworker () {
  return {
    type: UPDATE_SERVICEWORKER
  }
}
*/
