export const GET_RECURRING_PAYMENT_DETAILS_REQUEST =
  'GET_RECURRING_PAYMENT_DETAILS_REQUEST';
export const GET_RECURRING_PAYMENT_DETAILS_SUCCESS =
  'GET_RECURRING_PAYMENT_DETAILS_SUCCESS';
export const GET_RECURRING_PAYMENT_DETAILS_ERROR = 'GET_RECURRING_PAYMENT_DETAILS_ERROR';

export const GET_RECURRING_PAYMENTS_REQUEST = 'GET_RECURRING_PAYMENTS_REQUEST';
export const GET_RECURRING_PAYMENTS_SUCCESS = 'GET_RECURRING_PAYMENTS_SUCCESS';
export const GET_RECURRING_PAYMENTS_ERROR = 'GET_RECURRING_PAYMENTS_ERROR';

export const ADD_RECURRING_PAYMENT_REQUEST = 'ADD_RECURRING_PAYMENT_REQUEST';
export const ADD_RECURRING_PAYMENT_SUCCESS = 'ADD_RECURRING_PAYMENT_SUCCESS';
export const ADD_RECURRING_PAYMENT_ERROR = 'ADD_RECURRING_PAYMENT_ERROR';

export const UPDATE_RECURRING_PAYMENT_REQUEST = 'UPDATE_RECURRING_PAYMENT_REQUEST';
export const UPDATE_RECURRING_PAYMENT_SUCCESS = 'UPDATE_RECURRING_PAYMENT_SUCCESS';
export const UPDATE_RECURRING_PAYMENT_ERROR = 'UPDATE_RECURRING_PAYMENT_ERROR';

export const DELETE_RECURRING_PAYMENT_REQUEST = 'UPDATE_RECURRING_PAYMENT_REQUEST';
export const DELETE_RECURRING_PAYMENT_SUCCESS = 'UPDATE_RECURRING_PAYMENT_SUCCESS';
export const DELETE_RECURRING_PAYMENT_ERROR = 'UPDATE_RECURRING_PAYMENT_ERROR';

export const PAUSE_RECURRING_PAYMENT_REQUEST = 'PAUSE_RECURRING_PAYMENT_REQUEST';
export const PAUSE_RECURRING_PAYMENT_SUCCESS = 'PAUSE_RECURRING_PAYMENT_SUCCESS';
export const PAUSE_RECURRING_PAYMENT_ERROR = 'PAUSE_RECURRING_PAYMENT_ERROR';

export const ACTIVATE_RECURRING_PAYMENT_REQUEST = 'ACTIVATE_RECURRING_PAYMENT_REQUEST';
export const ACTIVATE_RECURRING_PAYMENT_SUCCESS = 'ACTIVATE_RECURRING_PAYMENT_SUCCESS';
export const ACTIVATE_RECURRING_PAYMENT_ERROR = 'ACTIVATE_RECURRING_PAYMENT_ERROR';
