import queryString from 'query-string';

import { applyLinkTheme } from '../utils/variables';
// eslint-disable-next-line import/no-cycle
import { formatThemeFromForm } from './index';

const GetLinkTheme = () => {
  if (typeof window === 'undefined') return null;
  const { location } = window;
  // // console.log('location: ', location);
  const parsed = queryString.parse(location.search);
  // // console.log('parsed: ', parsed);
  if (!parsed || !parsed.operation || parsed.operation !== applyLinkTheme) return null;
  const theme = formatThemeFromForm(parsed);
  return theme;
};

export default GetLinkTheme;
