import { Grid, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import Components from './components';

const GridCardsContainer = ({
  subComponentType,
  contents = [],
  columns,
  alignItems,
  justify: initialJustify,
  modalContentType,
  isNotGridContained = false,
}) => {
  const numberOfCards = contents.length;
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('md'));
  // const fullLengthCard = numberOfCards < columns;
  // const expandStates = [];
  const justify =
    subComponentType === 'ActionButton' && !initialJustify ? 'center' : 'flex-start';
  const newJustify =
    (contents &&
      contents.length > 0 &&
      subComponentType === 'ActionButton' &&
      contents[0]?.justifyContent?.replace(/_/g, '-')) ||
    justify;
  const newAlignItems =
    (contents &&
      contents.length > 0 &&
      subComponentType === 'ActionButton' &&
      contents[0]?.alignItems?.replace(/_/g, '-')) ||
    alignItems;

  const displayContents = contents?.map((content, index) => {
    const [parentExpandState, setParentExpandedState] = React.useState(false);
    // expandStates[index] = expandState; // [...expandStates];
    //
    const isGridEnabled = content?.isGridEnabled;
    const Component = Components[subComponentType] || <></>;
    return Component ? (
      <Grid
        item
        sm={(parentExpandState && 12) || (isGridEnabled && content?.sm) || 12}
        md={
          (parentExpandState && 12) ||
          (isGridEnabled && content?.md) ||
          (columns && columns < 4 ? 12 / columns : columns) ||
          12
        }
        lg={
          (parentExpandState && 12) ||
          (isGridEnabled && content?.lg) ||
          (columns && columns < 4 ? 12 / columns : columns) ||
          12
        }
        style={{ width: '100%' }}
      >
        <Component
          {...content}
          setParentExpandedState={setParentExpandedState}
          modalContentType={modalContentType}
          isAlreadyGrid
        />
      </Grid>
    ) : (
      <div />
    );
  });

  if (isNotGridContained) {
    return <>{displayContents}</>;
  }

  return (
    <Grid
      style={{
        // flexFlow: 'column wrap',
        width: '100%',
        height: 'auto',
        overflow: 'visible',
        marginBottom: '5px',
      }}
      justify={newJustify}
      alignItems={newAlignItems}
      container
      spacing={1}
    >
      {displayContents}
    </Grid>
  );
};

export default GridCardsContainer;
