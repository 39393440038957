import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  CardMedia,
  Typography,
  Container,
  Icon,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CallToActionCard from '../../cards/callToActionCard';
import { changeRedirect } from '../../../actions/misc';
import { updateTempState } from '../../../actions/temp';
import { addWizard } from '../../../actions/wizards';
import routes from '../../../utils/variables/routes';
import { useRouter } from '../../../hooks';
import styles from './style';

const { sanitizeUrl } = require('@braintree/sanitize-url');

function ReferralComponent(props) {
  const useStyles = makeStyles(styles);
  const dispatch = useDispatch();
  const router = useRouter();
  const classes = useStyles();
  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);

  const redirectUrl = routes.wizards.customerReferral;
  const authRequired = true;

  const handleClick = () => {
    dispatch(addWizard.emptyInitialWizardDataAction());
    const confirmingAction = (
      <Button
        className={classes.primaryButton}
        sx={{ width: '6rem', mx: 1 }}
        component={RouterLink}
        onClick={() => {
          dispatch(changeRedirect.changeRedirectAction(sanitizeUrl(redirectUrl)));
        }}
        to={routes.auth.signIn}
      >
        Go to Login
      </Button>
    );
    const dismissiveAction = (
      <Button
        className={classes.secondaryButton}
        sx={{ width: '6rem', mx: 1 }}
        onClick={() =>
          dispatch(updateTempState.updateTempStateAction({ alertPopUp: null }))
        }
      >
        Cancel
      </Button>
    );

    const title = `Login Required`; // : ${cartItem?.productName}
    const dialogContent = (
      <>
        In order to Refer a Friend, you are required to log in. Click "Go to Login" to
        login and access the Referral form.
        <br />
      </>
    );

    const alertObj = {
      confirmingAction,
      dismissiveAction,
      title,
      content: dialogContent,
      allowFullscreen: false,
    };

    if (authRequired && !isAuthenticated) {
      dispatch(updateTempState.updateTempStateAction({ alertPopUp: alertObj }));
    } else {
      router.push(redirectUrl);
    }
  };

  const handleLearnMoreClick = () => {
    const dismissiveAction = (
      <Button
        className={classes.secondaryButton}
        sx={{ width: '6rem', mx: 1 }}
        onClick={() =>
          dispatch(updateTempState.updateTempStateAction({ alertPopUp: null }))
        }
      >
        Close
      </Button>
    );

    const dialogContent = props?.content && (
      <div style={{ whiteSpace: 'pre-wrap' }}>{props?.content}</div>
    );

    const alertObj = {
      dismissiveAction,
      title: props?.title,
      content: dialogContent,
    };

    dispatch(updateTempState.updateTempStateAction({ alertPopUp: alertObj }));
  };

  const footer = (
    <CardActions
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        gap: '.63rem',
        padding: '0 !important',
        margin: '0 !important',
      }}
    >
      {/* <Button
        sx={{ textTransform: 'none', fontWeight: 'bold !important', m: 0.5 }}
        className={classes.tertiaryButton}
        onClick={handleLearnMoreClick}
        // to={routes.customerReferrals.learnMore}
        // component={RouterLink}
      >
        Learn More
      </Button> */}
      <Button
        className={classes.secondaryButton}
        sx={{ textTransform: 'none', fontWeight: 'bold !important' }}
        onClick={handleClick}
      >
        Refer a Friend
      </Button>
    </CardActions>
  );
  return <CallToActionCard {...props} footer={footer} />;
}

export default ReferralComponent;

/*
 Refer your friends and become eligible for a <b>reward</b>.
 const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  if (isMobile) {
    return (
      <Container>
        <Button
          variant="contained"
          className={classes.primaryButton}
          to={routes.wizards.customerReferral}
          component={RouterLink}
        >
          <Icon>connect_without_contact</Icon>
          Refer a Friend
        </Button>
      </Container>
    );
  }
  */
