import React from 'react';

export default function MiddleStepper({ backgroundColor, children, ...restProps }) {
  return (
    <svg
      viewBox="286.766 292.8 900 300"
      {...restProps}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        id="path2"
        fill={backgroundColor}
        // fill="rgb(185, 41, 39)"
        d="M 1034.031 292.835 L 286.766 292.8 L 438.829 441.375 L 286.801 592.8 L 1034.031 588.753 L 1186.766 441.425 L 1034.031 292.835"
      />
    </svg>
  );
}

/*
 textColor,
  textSize,
      <text
        textAnchor="middle"
        alignmentBaseline="central"
        x="100"
        y="25"
        fill={textColor}
        fontSize={textSize}
      >
        <textPath xlinkHref="#path2" startOffset="50%">
          {children}
        </textPath>
      </text>
      */
