export const getSlugIndex = myComponent => {
  return Object.keys(myComponent).findIndex(componentKey =>
    componentKey?.includes('_slug'),
  );
};

export const formatForm = form => {
  if (!form) return;
  const { components } = form;
  if (components) {
    const newComponents = components?.map(component => {
      let tempSlug = null;
      if (component?.AccordionChoice_slug === 'SummaryPanel') {
        tempSlug = component?.AccordionChoice_slug;
        component.AccordionChoice_slug = 'AccordionChoice';
        // console.log('component: ', component);
      }

      const slugIndex = getSlugIndex(component);
      if (slugIndex > -1) {
        // console.log('slugIndex: ', slugIndex);
        const slugValue = Object.values(component)[slugIndex];
        const newComponent = {};
        /* this code below is temporary */

        const result = Object.keys(component).map(oldKey => {
          const removeValue = `${slugValue}_`;
          // console.log('slugValue: ', slugValue);
          // console.log('removeValue: ', removeValue);
          if (oldKey.includes(removeValue)) {
            const newKey = oldKey.substring(removeValue.length);
            newComponent[newKey] = component[oldKey];
          } else {
            newComponent[oldKey] = component[oldKey];
          }
          return oldKey;
        });

        if (
          newComponent &&
          (newComponent.slug === 'AccordionChoice' ||
            newComponent.slug === 'CustomObject' ||
            newComponent.slug === 'UploadModalButton')
        ) {
          const { subForm, customForm, modalForm, items } = newComponent;
          if (subForm) {
            formatForm(subForm?.form);
          }
          if (customForm) {
            formatForm(customForm);
          }
          if (modalForm) {
            formatForm(modalForm?.form);
          }
          if (items && items.length > 0) {
            items.forEach(item => {
              if (item?.optionSubForm) {
                formatForm(item?.optionSubForm);
              }
            });
          }
        }

        if (tempSlug) {
          newComponent.slug = tempSlug;
        }

        return newComponent;
      }
      return component;
    });
    form.components = newComponents;
    // console.log('newComponents: ', newComponents);
    // wizard.forms[index].form.components = newComponents;
  }
};

export const formatWizardData = wizard => {
  // console.log('start wizard: ', wizard);
  if (wizard) {
    const { forms: formWrappers } = wizard || {};

    formWrappers?.forEach((formWrapper, index) => {
      const { form } = formWrapper || {};
      if (form) {
        formatForm(form);
      }
    });
  }
  // console.log('end wizard: ', wizard);
  return wizard;
};
