import { mainHeaderHeight } from '../../../utils/variables';
import { toastrStyles, portfolioButtonStyles } from '../../../styles';

export default theme => {
  return {
    ...toastrStyles(theme),
    ...portfolioButtonStyles(theme),
    toastr: {
      cursor: 'pointer',
    },
  };
};
