export const FIRST_NAME_REGEX = /^[ ]*[a-zA-Z]+[a-zA-Z\s-]+[a-zA-Z]{1,}[ ]*$/;

export const LAST_NAME_REGEX = /^[ ]*[a-zA-Z]+[- a-zA-Z]*[\s]*/;

export const TRN_NUM_REGEX = /^[0-9]{9}$/;

export const POLICY_NUM_REGEX = /(^\d{4,}$)|(^[a-zA-Z0-9]{8,}$)/;

export const AGENT_FIRST_NAME_REGEX = /^[ ]*[a-zA-Z]+[- a-zA-Z]*[a-zA-Z]+[\s]*$/;

export const AGENT_LAST_NAME_REGEX = /^[ ]*[a-zA-Z]+[- a-zA-Z]*[a-zA-Z]+[\s]*$/;

export const MOBILE_NUM_REGEX = /^[\+]?[(]?[0-9]{4}[)]?[0-9]{3}[0-9]{4,6}$/;

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const CASEID_REGEX = /^[a-zA-Z0-9]+[-a-zA-Z0-9]*$/;
