import { portfolioButtonStyles } from '../../../styles';

export default theme => ({
  ...portfolioButtonStyles(theme),
  cardTitleStyles: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
    margin: '10px !important',
    fontWeight: 'bold !important',
    textAlign: 'left',
  },
  cardContentStyles: {
    color: theme.palette.text.disabled,
    textAlign: 'justify',
    [theme.breakpoints.down('md')]: {
      fontSize: 'small',
    },
  },
  chipHeaderStyle: {
    color: theme.palette.text.label,
    fontWeight: 'bold !important',
    textAlign: 'left',
    margin: '10px !important',
  },
  chipsStyle: {
    backgroundColor: `${theme.palette.background.chip} !important`,
    color: `${theme.palette.text.bright} !important `,
  },
});
