import { crmPostCall } from '../../axios';
import { helpCenterApi } from '../../../utils/functions/helpCenterApis';

import { NOTIFYMEONUPDATEURL, SERVERERROR } from '../constants';

export const notifyMeOnUpdateUrl = helpCenterApi(NOTIFYMEONUPDATEURL);

const notifyMeOnUpdate = (state, body) => {
  const url = notifyMeOnUpdateUrl;
  if (url) {
    return crmPostCall(state, url, body);
  }
  return Promise.reject(new Error(SERVERERROR));
};
export default { notifyMeOnUpdate };
