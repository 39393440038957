import { agentType } from '../../../utils/variables';
import { routerGetCall } from '../../axios';

export const getPendingClaimUrl = `/account-services/submitted-claims`;
export const getPendingBatchClaimUrl = `/account-services/batch-claims`;
const getPendingClaim = (state, params) => {
  const usertype = state?.auth?.usertype;
  let url = getPendingClaimUrl + `/${params.claimId}`;
  if(usertype === agentType){
     url = getPendingBatchClaimUrl + `/${params.claimId}`;
  }
  return routerGetCall(state, url, params);
};

export default { getPendingClaim };
