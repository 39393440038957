import { useIntl } from 'react-intl';
// import initialLocaleData from '../localeData.json';
import en from '../translations/en.json';

const useCustomIntl = idKey => {
  // console.log('idKey: ', idKey);
  const intl = useIntl();
  // const c360LocaleData =
  // useSelector(state => state?.locations?.c360LocaleData?.localeData) ||
  //     initialLocaleData;
  // const messages = c360LocaleData.en;
  let message = intl.formatMessage({ id: idKey, defaultMessage: idKey });
  // console.log('message: ', message);
  const messages = en;
  if (message === idKey) {
    message = intl.formatMessage({ id: idKey, defaultMessage: messages[idKey] });
  }
  return message;
};
export default useCustomIntl;
