export const getSessionStorage = (storageName, { isObject, defaultValue } = {}) => {
  let value = window.sessionStorage.getItem(storageName) || defaultValue;
  if (isObject) {
    try {
      value = JSON.parse(value);
    } catch (e) {
      // console.log('e: ', e);
      value = null;
    }
  }
  // // console.log('alpha2: ', alpha2);
  return value;
};

export const removeSessionStorage = storageName => {
  if (window.sessionStorage.getItem(storageName)) {
    window.sessionStorage.removeItem(storageName);
  }
  return null;
};

export const setSessionStorage = (storageName, value, { isObject } = {}) => {
  if (value) {
    // // console.log('alpha2: ', alpha2);
    let storedValue = value;
    if (isObject) {
      try {
        storedValue = JSON.stringify(storedValue);
      } catch (e) {
        storedValue = null;
        // console.log('e: ', e);
      }
    }
    if (storedValue) {
      window.sessionStorage.setItem(storageName, storedValue);
    }
  }
  return null;
};

export default {
  setSessionStorage,
  removeSessionStorage,
  getSessionStorage,
};
