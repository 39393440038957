import { gql } from 'graphql-request';

const UPLOAD_BUTTON_FIELDS = gql`
  fragment uploadButton on ComponentWizardComponentsUploadButton {
    UploadButton_slug: slug
    UploadButton_layoutPosition: layoutPosition
    UploadButton_positionNo: positionNo
    UploadButton_gridConfig: gridConfig {
      xs
      sm
      md
      align
    }
    UploadButton_name: name
    UploadButton_isConditionalComponent: isConditionalComponent
    UploadButton_conditionalValue: conditionalValue
    UploadButton_conditionalKey: conditionalKey
    UploadButton_conditionalOperator: conditionalOperator
    UploadButton_conditionalFieldType: conditionalFieldType
    UploadButton_conditionalAction: conditionalAction
    UploadButton_conditionalArrayKey: conditionalArrayKey

    UploadButton_accept: accept
    UploadButton_autoUpload: autoUpload
    UploadButton_uploadButtonColor: uploadButtonColor
    UploadButton_uploadButtonVariant: uploadButtonVariant
    UploadButton_uploadButtonSize: uploadButtonSize

    UploadButton_buttonColor: buttonColor
    UploadButton_buttonSize: buttonSize
    UploadButton_buttonVariant: buttonVariant

    UploadButton_uploadButtonText: uploadButtonText
    UploadButton_uploadButtonText_en: uploadButtonText_en
    UploadButton_uploadButtonText_nl: uploadButtonText_nl

    UploadButton_uploadUrl: uploadUrl
    UploadButton_icon: icon
    UploadButton_multiple: multiple
    UploadButton_inverseBackgroundColor: inverseBackgroundColor

    UploadButton_selectFileText: selectFileText
    UploadButton_selectFileText_en: selectFileText_en
    UploadButton_selectFileText_nl: selectFileText_nl

    UploadButton_uploadConfig: uploadConfig {
      xs
      sm
      md
      align
    }
    UploadButton_formData: formData
    UploadButton_fullWidth: fullWidth
    UploadButton_wizardUpload: wizardUpload

    UploadButton_label: label
    UploadButton_label_en: label_en
    UploadButton_label_nl: label_nl

    UploadButton_required: required
    UploadButton_disable: disable
    UploadButton_show: show

    UploadButton_buttonConfig: buttonConfig {
      xs
      sm
      md
      align
    }

    UploadButton_labelAlign: labelAlign
    UploadButton_containerName: containerName
    UploadButton_disableInitialValue: disableInitialValue

    UploadButton_tooltip: tooltip
    UploadButton_tooltip_nl: tooltip_nl
    UploadButton_tooltip_en: tooltip_en

    UploadButton_appendTo: appendTo
    UploadButton_appendKeys: appendKeys {
      ...appendKey
    }
    UploadButton_reloadWizard: reloadWizard
    UploadButton_uploadTitle: uploadTitle
    UploadButton_autoCloseModal: autoCloseModal
    UploadButton_resetCountWhenUnMount: resetCountWhenUnMount
    UploadButton_conditions: conditions {
      ...condition
    }
  }
`;

// export default UPLOAD_BUTTON_FIELDS;
// exports.default = UPLOAD_BUTTON_FIELDS;
export default UPLOAD_BUTTON_FIELDS;
