import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useClient, useSmall } from '../../../hooks';
import DetailsModal from '../detailsModal';
import { ErrorAlert } from '../../common';
import { agentType } from '../../../utils/variables';
import { setModalContent } from '../../../actions/misc';
import ClientDetails from '../../../containersV2/clients/details';
import { getClientsApi } from '../../../apis/clients';
import { createPayload } from '../../../reducers/clients';
import styles from './style';

const useStyles = makeStyles(styles);

const ClientModal = () => {
  // // console.log('encryptKey11: ', encryptKey);
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isSmall = useSmall();
  const storeModal = useSelector(state => state.misc.modalContent);
  const storeState = useSelector(state => state);
  const clients = useSelector(state => state.clients.allRows);
  const isClientsSuccess = useSelector(state => state.clients.isSuccess);
  const isAgent = useSelector(
    state => state?.auth?.user?.agent?.id && state?.auth?.usertype === agentType,
  );

  const clientKey = storeModal?.clientKey;
  const backSlugs = storeModal?.backSlugs;

  const initialClient = clients?.find(
    x =>
      x.clientNumber && clientKey && x.clientNumber?.toString() === clientKey?.toString(),
  );

  const [client, setClient] = useState(initialClient);
  // console.log('client: ', client);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getSingleClient = async () => {
      setLoading(true);
      const params = { id: clientKey };
      try {
        const tempClient = await getClientsApi.getSingleClient(storeState, params);
        if (tempClient.payload) {
          const newClient = createPayload([tempClient?.payload])[0];
          // console.log('newClient: ', newClient);
          setClient(newClient);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    if (!client && isAgent && clientKey) {
      getSingleClient();
    }
  }, [clientKey]);

  let modalContent = null;
  if (loading) {
    modalContent = {
      title: '',
      content: <ErrorAlert isPending pendingMessage="Fetching your client details" />,
    };
  } else if (client && isAgent) {
    modalContent = {
      title: '',
      content: (
        <ClientDetails
          client={client}
          onClose={() => {
            dispatch(setModalContent.setModalContentAction(null));
            setClient(null);
          }}
        />
      ),
    };
  } else if (!client && clientKey && isClientsSuccess && isAgent) {
    modalContent = {
      title: '',
      content: (
        <ErrorAlert
          isError
          errorMessage={`This client No: ${clientKey} is not attached to your agent profile.`}
        />
      ),
    };
  }

  return (
    <>
      {modalContent && (
        <DetailsModal
          showBackButton={backSlugs && backSlugs?.length > 0}
          title={modalContent?.title}
          onClose={() => {
            dispatch(setModalContent.setModalContentAction(null));
            setClient(null);
          }} // dispatch(setModalContent.setModalContentAction(null))
          content={modalContent?.content}
          richTextContent={modalContent?.displayContent}
          proseMirrorContent={modalContent?.proseMirrorContent}
          modalSize="md"
          // className={classes.modalBackground}
          sx={{
            backgroundColor: `${theme.palette.background.main} !important`,
          }}
        />
      )}
    </>
  );
};

export default ClientModal;
