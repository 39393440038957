import {
  GET_POLICIES_API_REQUEST,
  GET_POLICIES_API_SUCCESS,
  GET_POLICIES_API_ERROR,
} from '../../constants/helpCenter/getPolicies';
import {
  SUBMIT_HELP_REQUEST_API_REQUEST,
  SUBMIT_HELP_REQUEST_API_SUCCESS,
  SUBMIT_HELP_REQUEST_API_ERROR,
} from '../../constants/helpCenter/submitHelpRequest';

import {
  DOCUMENTS_UPLOAD_API_REQUEST,
  DOCUMENTS_UPLOAD_API_SUCCESS,
  DOCUMENTS_UPLOAD_API_ERROR,
} from '../../constants/helpCenter/documentsUpload';

import {
  NOTIFY_ME_ON_UPDATE_API_REQUEST,
  NOTIFY_ME_ON_UPDATE_API_ERROR,
  NOTIFY_ME_ON_UPDATE_API_SUCCESS,
} from '../../constants/helpCenter/notifyMeOnUpdate';

import {
  GET_REQUEST_HELP_DROPDOWN_VALUES_API_REQUEST,
  GET_REQUEST_HELP_DROPDOWN_VALUES_API_ERROR,
  GET_REQUEST_HELP_DROPDOWN_VALUES_API_SUCCESS,
  GET_QNR_VALUES_API_REQUEST,
  GET_QNR_VALUES_API_ERROR,
  GET_QNR_VALUES_API_SUCCESS,
} from '../../constants/helpCenter/dropdown';

import {
  ADD_HELP_FORM_DATA_SUCCESS,
  EMPTY_HELP_FORM_DATA_SUCCESS,
  GET_ALL_ARTICLES_API_SUCCESS,
  GET_HELP_ARTICLE_TITLES_API_SUCCESS,
  GET_SINGLE_ARTICLE_API_SUCCESS,
} from '../../constants/helpCenter/helpCenterActionNames';

import { GET_TICKET_SPECIFIC_CS_REP_DETAILS } from '../../constants/helpCenter/getTicketSpecificCSRepDetails';

import {
  GET_ALL_HELP_CENTER_CASES_API_ERROR,
  GET_ALL_HELP_CENTER_CASES_API_REQUEST,
  GET_ALL_HELP_CENTER_CASES_API_SUCCESS,
  GET_SINGLE_HELP_CENTER_CASE_API_ERROR,
  GET_SINGLE_HELP_CENTER_CASE_API_REQUEST,
  GET_SINGLE_HELP_CENTER_CASE_API_SUCCESS,
  GET_SURVEY_LINK_ERROR,
  GET_SURVEY_LINK_REQUEST,
  GET_SURVEY_LINK_SUCCESS,
  GET_TICKET_HISTORY_API_ERROR,
  GET_TICKET_HISTORY_API_REQUEST,
  GET_TICKET_HISTORY_API_SUCCESS,
  RE_OPEN_OR_CANCEL_CASE_API_ERROR,
  RE_OPEN_OR_CANCEL_CASE_API_REQUEST,
  RE_OPEN_OR_CANCEL_CASE_API_SUCCESS,
  GET_REQUIRED_DOCUMENTS_LIST_ERROR,
  GET_REQUIRED_DOCUMENTS_LIST_REQUEST,
  GET_REQUIRED_DOCUMENTS_LIST_SUCCESS,
} from '../../constants/helpCenter/getAllHelpCenterCases';
import { getOnlyStringKeys, reduceKeys } from '../../utils/functions';
import { persistKey, domain, investmentLob } from '../../utils/variables';
import { cardStatus } from '../../containersV2/helpCenter/checkStatus';

export const initialState = {
  response: null, // { payload: summaryData },
  isPending: false,
  isError: false,
  isSuccess: false,
  isSubmitRequestSuccess: false,
  isSubmitRequestPending: false,
  isSubmitRequestError: false,
  isUpdateContactPending: false,
  isUpdateContactError: false,
  isUpdateContactSuccess: false,
  isDocumentsUploadPending: false,
  isDocumentsUploadError: false,
  isDocumentsUploadSuccess: false,
  isDropdownValuesPending: false,
  isDropdownValuesError: false,
  isDropdownValuesSuccess: false,

  isQnrValuesPending: false,
  isQnrValuesError: false,
  isQnrValuesSuccess: false,

  userDetails: null,
  error: null,
  submitRequestErrorerror: null,
  isPoliciesPending: false,
  isPoliciesError: false,
  isPoliciesSuccess: false,
  policies: [],
  caseNumber: '',
  accountId: '',
  [persistKey]: null,
  helpFormData: {},
  expectedResolutionDate: '',
  ticketSpecificCSRepDetails: null,
  allHelpArticles: [],
  helpArticleTitles: [],
  singleArticle: {},

  responseCases: null, // { payload: summaryData },
  isPendingCases: false,
  isErrorCases: false,
  isSuccessCases: false,
  errorCases: null,
  allHelpCenterCases: [],
  singleHelpCenterCase: [],
  ticketHistory: null,
  reOpenOrCancelCase: null,
  surveyLink: null,
  requiredDocumentsList: [],
};

const createPolicies = payloadArray => {
  if (!Array.isArray(payloadArray)) return null;
  const policies = payloadArray.map((object, index) => {
    const position = index;

    return {
      ...object,
      index: position,
      id: object?.policyId,
      name: object?.policyName,
      type: object?.policyType,
    };
  });

  return policies;
};

const createAllCases = payloadArray => {
  if (!Array.isArray(payloadArray)) {
    const cases = [
      {
        index: 0,
        ticketNumber: payloadArray.case[0].ticketnumber,
        issueStatus: cardStatus.Resolved.includes(
          payloadArray.case[0]['statuscode@OData.Community.Display.V1.FormattedValue'],
        )
          ? 'Resolved'
          : cardStatus.IN.includes(
              payloadArray.case[0][
                'statuscode@OData.Community.Display.V1.FormattedValue'
              ],
            )
          ? 'Info Needed'
          : cardStatus.IP.includes(
              payloadArray.case[0][
                'statuscode@OData.Community.Display.V1.FormattedValue'
              ],
            )
          ? 'In Progress'
          : cardStatus.Delayed.includes(
              payloadArray.case[0][
                'statuscode@OData.Community.Display.V1.FormattedValue'
              ],
            )
          ? 'Delayed'
          : cardStatus.Cancelled.includes(
              payloadArray.case[0][
                'statuscode@OData.Community.Display.V1.FormattedValue'
              ],
            )
          ? 'Cancelled'
          : payloadArray.case[0]['statuscode@OData.Community.Display.V1.FormattedValue'],
        createdOn:
          payloadArray.case[0]['createdon@OData.Community.Display.V1.FormattedValue'],
        createdOnSystemFormat: payloadArray.case[0]?.createdon,
        issueSubject: payloadArray.case[0].gg_queries_requests_type.gg_issuetypeparent,
        failureTime:
          payloadArray.case[0][
            'slakpiinstance1.failuretime@OData.Community.Display.V1.FormattedValue'
          ],
        ticketCreatedBy:
          payloadArray.case[0][
            '_customerid_value@OData.Community.Display.V1.FormattedValue'
          ] == 'GLOC Guest'
            ? payloadArray.case[0][
                '_gg_guestuser_value@OData.Community.Display.V1.FormattedValue'
              ]
            : payloadArray.case[0][
                '_customerid_value@OData.Community.Display.V1.FormattedValue'
              ],
        email: payloadArray.email,
        phone: payloadArray.phone,
        resolutionTime: payloadArray.ResolutionTime,
        policy: payloadArray?.policy,
        issueType: payloadArray?.issue,
        resolvedDate: payloadArray?.case?.[0]?.modifiedon,
        estimatedResolutionDate: payloadArray?.case?.[0]?.resolvebykpiid?.[
          'failuretime@OData.Community.Display.V1.FormattedValue'
        ]?.split(' ')[0],
        modifiedOn:
          payloadArray.case[0]['modifiedon@OData.Community.Display.V1.FormattedValue'],
        modifiedOnSystemFormat: payloadArray.case[0]?.modifiedon,
      },
    ];
    return cases;
  }
  const allCases = payloadArray.map((object, index) => {
    const position = index;

    return {
      index: position,
      ticketNumber: object?.ticketnumber,
      issueStatus: cardStatus.Resolved.includes(
        object['statuscode@OData.Community.Display.V1.FormattedValue'],
      )
        ? 'Resolved'
        : cardStatus.IN.includes(
            object['statuscode@OData.Community.Display.V1.FormattedValue'],
          )
        ? 'Info Needed'
        : cardStatus.IP.includes(
            object['statuscode@OData.Community.Display.V1.FormattedValue'],
          )
        ? 'In Progress'
        : cardStatus.Delayed.includes(
            object['statuscode@OData.Community.Display.V1.FormattedValue'],
          )
        ? 'Delayed'
        : cardStatus.Cancelled.includes(
            object['statuscode@OData.Community.Display.V1.FormattedValue'],
          )
        ? 'Cancelled'
        : object['statuscode@OData.Community.Display.V1.FormattedValue'],
      createdOn: object['createdon@OData.Community.Display.V1.FormattedValue'],
      createdOnSystemFormat: object?.createdon,
      ticketCreatedBy:
        object['_customerid_value@OData.Community.Display.V1.FormattedValue'] ==
        'GLOC Guest'
          ? object['_gg_guestuser_value@OData.Community.Display.V1.FormattedValue']
          : object['_customerid_value@OData.Community.Display.V1.FormattedValue'],
      issueSubject: object['aa.gg_issuetypeparent'],
      failureTime:
        object['slakpiinstance1.failuretime@OData.Community.Display.V1.FormattedValue'],
    };
  });

  return allCases;
};

const createTicketHistory = payloadData => {
  if (!payloadData) return null;

  const ticketHistoryContent = [];

  if (payloadData.notes.length > 0) {
    payloadData.notes.forEach((element, index) => {
      if (element.subject.includes('Case is')) {
        const newElement = {
          subject: element.subject,
          date: element.createdon,
          createdOnSystemFormat:
            element['createdon@OData.Community.Display.V1.FormattedValue'],
        };
        ticketHistoryContent.push(newElement);
      }
    });
    if (payloadData.appointments.length > 0) {
      payloadData.appointments.forEach((element, index) => {
        if (element.subject) {
          const newElement = {
            subject: element.subject.toUpperCase(),
            date: element.createdon,
          };
          ticketHistoryContent.push(newElement);
        }
      });
    }
  }

  const ticketHistory = { ...payloadData, ticketHistoryContent };

  return ticketHistory;
};

function helpCenterReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_HELP_CENTER_CASES_API_REQUEST:
      return {
        ...state,
        isPendingCases: true,
        isErrorCases: false,
        isSuccessCases: false,
      };
    case GET_ALL_HELP_CENTER_CASES_API_SUCCESS: {
      // const response = action.payload;
      const allCases = createAllCases(action?.payload) || [];

      const nowTime = new Date().getTime();
      return {
        ...state,
        isPendingCases: false,
        isErrorCases: false,
        isSuccessCases: true,
        // response, // newProducts,
        allHelpCenterCases: allCases,
        [persistKey]: nowTime,
      };
    }
    case GET_ALL_HELP_CENTER_CASES_API_ERROR:
      return {
        ...state,
        isPendingCases: false,
        isErrorCases: true,
        isSuccessCases: false,
        // error: action.payload,
      };
    case GET_SINGLE_HELP_CENTER_CASE_API_REQUEST:
      return {
        ...state,
        isPendingCases: true,
        isErrorCases: false,
        isSuccessCases: false,
      };
    case GET_SINGLE_HELP_CENTER_CASE_API_SUCCESS: {
      const caseDetails = createAllCases(action?.payload) || [];
      // const response = action.payload;

      const nowTime = new Date().getTime();
      return {
        ...state,
        isPendingCases: false,
        isErrorCases: false,
        isSuccessCases: true,
        // response, // newProducts,
        singleHelpCenterCase: caseDetails,
        [persistKey]: nowTime,
      };
    }
    case GET_SINGLE_HELP_CENTER_CASE_API_ERROR:
      return {
        ...state,
        isPendingCases: false,
        isErrorCases: true,
        isSuccessCases: false,
        singleHelpCenterCase: [],
        // error: action.payload,
      };
    case GET_TICKET_HISTORY_API_REQUEST:
      return {
        ...state,
        isPendingCases: true,
        isErrorCases: false,
        isSuccessCases: false,
      };
    case GET_TICKET_HISTORY_API_SUCCESS: {
      const updatedTicketHistory = createTicketHistory(action?.payload) || null;
      const nowTime = new Date().getTime();
      return {
        ...state,
        isPendingCases: false,
        isErrorCases: false,
        isSuccessCases: true,
        // response, // newProducts,
        ticketHistory: updatedTicketHistory,
        [persistKey]: nowTime,
      };
    }
    case GET_TICKET_HISTORY_API_ERROR:
      return {
        ...state,
        isPendingCases: false,
        isErrorCases: true,
        isSuccessCases: false,
        // error: action.payload,
      };
    case RE_OPEN_OR_CANCEL_CASE_API_REQUEST:
      return {
        ...state,
        isPendingCases: true,
        isErrorCases: false,
        isSuccessCases: false,
      };
    case RE_OPEN_OR_CANCEL_CASE_API_SUCCESS: {
      const nowTime = new Date().getTime();
      return {
        ...state,
        isPendingCases: false,
        isErrorCases: false,
        isSuccessCases: true,
        // response, // newProducts,
        reOpenOrCancelCase: action.payload,
        [persistKey]: nowTime,
      };
    }
    case RE_OPEN_OR_CANCEL_CASE_API_ERROR:
      return {
        ...state,
        isPendingCases: false,
        isErrorCases: true,
        isSuccessCases: false,
        // error: action.payload,
      };
    case GET_SURVEY_LINK_REQUEST:
      return {
        ...state,
        isPendingCases: true,
        isErrorCases: false,
        isSuccessCases: false,
      };
    case GET_SURVEY_LINK_SUCCESS: {
      const nowTime = new Date().getTime();
      return {
        ...state,
        isPendingCases: false,
        isErrorCases: false,
        isSuccessCases: true,
        surveyLink: action.payload,
        [persistKey]: nowTime,
      };
    }
    case GET_SURVEY_LINK_ERROR:
      return {
        ...state,
        isPendingCases: false,
        isErrorCases: true,
        isSuccessCases: false,
        // error: action.payload,
      };
    case GET_REQUIRED_DOCUMENTS_LIST_REQUEST:
      return {
        ...state,
        isPendingCases: true,
        isErrorCases: false,
        isSuccessCases: false,
      };
    case GET_REQUIRED_DOCUMENTS_LIST_SUCCESS: {
      const nowTime = new Date().getTime();

      return {
        ...state,
        isPendingCases: false,
        isErrorCases: false,
        isSuccessCases: true,
        requiredDocumentsList: action.payload,
        [persistKey]: nowTime,
      };
    }
    case GET_REQUIRED_DOCUMENTS_LIST_ERROR:
      return {
        ...state,
        isPendingCases: false,
        isErrorCases: true,
        isSuccessCases: false,
        // error: action.payload,
      };

    case GET_SINGLE_ARTICLE_API_SUCCESS: {
      const filteredArticle = action?.payload?.[0] || initialState?.singleArticle;
      return {
        ...state,
        singleArticle: filteredArticle,
      };
    }
    case GET_HELP_ARTICLE_TITLES_API_SUCCESS:
      return {
        ...state,
        helpArticleTitles: action.payload,
      };
    case GET_ALL_ARTICLES_API_SUCCESS:
      return {
        ...state,
        allHelpArticles: action.payload,
      };

    case GET_POLICIES_API_REQUEST:
      return {
        ...state,
        isPoliciesPending: true,
        isPoliciesError: false,
        isPoliciesSuccess: false,
      };
    case GET_POLICIES_API_SUCCESS: {
      // const response = action.payload;

      const policies = createPolicies(action?.payload) || [];

      const nowTime = new Date().getTime();
      return {
        ...state,
        isPoliciesPending: false,
        isPoliciesError: false,
        isPoliciesSuccess: true,
        // response, // newProducts,
        policies,
        [persistKey]: nowTime,
      };
    }
    case GET_POLICIES_API_ERROR:
      return {
        ...state,
        isPoliciesPending: false,
        isPoliciesError: true,
        isPoliciesSuccess: false,
      };

    case SUBMIT_HELP_REQUEST_API_REQUEST:
      return {
        ...state,
        isSubmitRequestPending: true,
        isSubmitRequestError: false,
        isSubmitRequestSuccess: false,
      };
    case SUBMIT_HELP_REQUEST_API_SUCCESS: {
      // const response = action.payload;

      const caseNumber = action?.payload?.caseNumber;
      const accountId = action?.payload?.AccountId;

      return {
        ...state,
        isSubmitRequestPending: false,
        isSubmitRequestError: false,
        isSubmitRequestSuccess: true,
        // response, // newProducts,
        caseNumber,
        accountId,
      };
    }
    case SUBMIT_HELP_REQUEST_API_ERROR:
      return {
        ...state,
        isSubmitRequestPending: false,
        isSubmitRequestError: true,
        isSubmitRequestSuccess: false,
        error: action.payload,
      };

    case NOTIFY_ME_ON_UPDATE_API_REQUEST:
      return {
        ...state,
        isUpdateContactPending: true,
        isSUpdateContactError: false,
        isUpdateContactSuccess: false,
      };
    case NOTIFY_ME_ON_UPDATE_API_SUCCESS: {
      // const response = action.payload;

      const updateContactMessage = action?.payload?.message;
      const accountId = action?.payload?.accountId;
      const updateContactStatus = action?.payload?.message;
      return {
        ...state,
        isUpdateContactPending: false,
        isUpdateContactError: false,
        isUpdateContactSuccess: true,
        // response, // newProducts,
        updateContactMessage,
        updateContactStatus,
        accountId,
      };
    }
    case NOTIFY_ME_ON_UPDATE_API_ERROR:
      return {
        ...state,
        isUpdateContactPending: false,
        isUpdateContactError: true,
        isUpdateContactSuccess: false,
        // error: action.payload,
      };

    case DOCUMENTS_UPLOAD_API_REQUEST:
      return {
        ...state,
        isDocumentsUploadPending: true,
        isDocumentsUploadError: false,
        isDocumentsUploadSuccess: false,
      };
    case DOCUMENTS_UPLOAD_API_SUCCESS: {
      // const response = action.payload;
      return {
        ...state,
        isDocumentsUploadPending: false,
        isDocumentsUploadError: false,
        isDocumentsUploadSuccess: true,
      };
    }
    case DOCUMENTS_UPLOAD_API_ERROR:
      return {
        ...state,
        isDocumentsUploadPending: false,
        isDocumentsUploadError: true,
        isDocumentsUploadSuccess: false,
      };

    case GET_REQUEST_HELP_DROPDOWN_VALUES_API_REQUEST:
      return {
        ...state,
        isDropdownValuesPending: true,
        isDropdownValuesError: false,
        isDropdownValuesSuccess: false,
      };
    case GET_REQUEST_HELP_DROPDOWN_VALUES_API_SUCCESS: {
      // const response = action.payload;

      return {
        ...state,
        isDropdownValuesPending: false,
        isDropdownValuesError: false,
        isDropdownValuesSuccess: true,
      };
    }
    case GET_REQUEST_HELP_DROPDOWN_VALUES_API_ERROR:
      return {
        ...state,
        isDropdownValuesPending: false,
        isDropdownValuesError: true,
        isDropdownValuesSuccess: false,
      };

    case GET_QNR_VALUES_API_REQUEST:
      return {
        ...state,
        isQnrValuesPending: true,
        isQnrValuesError: false,
        isQnrValuesSuccess: false,
      };
    case GET_QNR_VALUES_API_SUCCESS: {
      // const response = action.payload;

      return {
        ...state,
        isQnrValuesPending: false,
        isQnrValuesError: false,
        isQnrValuesSuccess: true,
      };
    }
    case GET_QNR_VALUES_API_ERROR: {
      return {
        ...state,
        isQnrValuesPending: false,
        isQnrValuesError: true,
        isQnrValuesSuccess: false,
      };
    }

    case ADD_HELP_FORM_DATA_SUCCESS: {
      const response = action.payload || {};

      const helpFormData = {
        ...state.helpFormData,
        ...response,
      };

      return {
        ...state,
        helpFormData,
      };
    }

    case GET_TICKET_SPECIFIC_CS_REP_DETAILS: {
      const response = action.payload || {};

      return {
        ...state,
        ticketSpecificCSRepDetails: response,
      };
    }

    case EMPTY_HELP_FORM_DATA_SUCCESS: {
      const helpFormData = {};

      return {
        ...state,
        helpFormData,
      };
    }

    default:
      return state;
  }
}
export default helpCenterReducer;
