import {
  GET_LOCATIONS_API_REQUEST,
  GET_LOCATIONS_API_SUCCESS,
  GET_LOCATIONS_API_ERROR,
} from '../../../constants/locationActionNames';

import { locationsApi } from '../../../apis/officeLocator';

function getLocationsRequest() {
  return {
    type: GET_LOCATIONS_API_REQUEST,
    payload: true,
  };
}

function getLocationsSuccess(locations) {
  return {
    type: GET_LOCATIONS_API_SUCCESS,
    payload: locations,
  };
}

function getLocationsError(err) {
  return {
    type: GET_LOCATIONS_API_ERROR,
    payload: err,
  };
}

export function getLocationsAction(params) {
  console.log('OfficeLocations Params', params);
  return (dispatch, getState) => {
    const state = getState();
    dispatch(getLocationsRequest());
    return locationsApi
      .getLocations(state, params)
      .then(data => {
        dispatch(getLocationsSuccess(data, params));
        return data;
      })
      .catch(error => {
        dispatch(getLocationsError(error));
        throw error;
      });
  };
}

export default { getLocationsAction };
