import { gql } from 'graphql-request';

const NON_RECURSIVE_FORM_WRAPPER_FIELDS = gql`
  fragment nonRecursiveFormWrapper on ComponentMiscComponentsCustomFormWrapper {
    title
    title_en
    title_nl
    description
    description_en
    description_nl
    positionNo
    disableValidation
    isRoutingUrlForm
    routingUrlKey
    routingStaticUrl
    isConditionalForm
    conditionalKey
    conditionalValue
    conditionalOperator
    conditionalFieldType
    hidden
    key
    conditions {
      ...formCondition
    }
    onChangeApiCalls {
      title
      api_call {
        ...formApiCall
      }
      dependentKeys {
        key
        notRequired
      }
    }
    onLoadApiCalls {
      ...formApiCall
    }
    form {
      ...nonRecursiveForm
    }
  }
`;

// export default FORM_WRAPPER_FIELDS;
// exports.default = NON_RECURSIVE_FORM_WRAPPER_FIELDS;
export default NON_RECURSIVE_FORM_WRAPPER_FIELDS;
