import { RESET_EXPIRED_SUCCESS } from '../../../constants/miscActionNames';

function resetExpiredSuccess(prod) {
  return {
    type: RESET_EXPIRED_SUCCESS,
    payload: prod,
  };
}

export function resetExpiredAction(data) {
  return resetExpiredSuccess(data);
}

export default {
  resetExpiredAction,
};
