import React from 'react';
import { getAppThemeName } from '../storage/themeName';
import Cookies from '../cookies/cookies';
import {
  consentKey,
  cookiePolicyVersion,
  customConsentKey,
  initialEncryptKey,
} from '../utils/variables';
import { isJsonString, getValueFromArray } from '../utils/functions';

export const ThemeContext = React.createContext({
  themeName: getAppThemeName(),
  setThemeName: () => {},
});

export const EncryptKeyContext = React.createContext({
  encryptKey: initialEncryptKey, // getAccessToken() ||
  setEncryptKey: () => {},
});
export const RequestModalContext = React.createContext({
  requestModal: false, // getAccessToken() ||
  setRequestModal: () => {},
});

export const PromptContext = React.createContext({
  installGlobalPromptEvent: null, // getAccessToken() ||
  setInstallGlobalPromptEvent: () => {},
});

export const ConsentContext = React.createContext({
  consent: {
    consent: Cookies.getCookie(consentKey),
    customConsent: isJsonString(
      Cookies.getCookie(customConsentKey)
        ?.split('@')[0]
        ?.toString(),
    )
      ? JSON.parse(
          Cookies.getCookie(customConsentKey)
            ?.split('@')[0]
            ?.toString(),
        )
      : [],
    cookies: isJsonString(
      Cookies.getCookie(customConsentKey)
        ?.split('@')[1]
        ?.toString(),
    )
      ? JSON.parse(
          Cookies.getCookie(customConsentKey)
            ?.split('@')[1]
            ?.toString(),
        )
      : [],
    version: Cookies.getCookie(customConsentKey)?.split('@')[2] || cookiePolicyVersion,
  }, // getAccessToken() ||
  setConsent: () => {},
});
