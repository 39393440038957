import React, { useEffect, useState } from 'react';
import { DocumentViewer } from 'react-documents';
import { CircularProgress, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { fetchPdfBlob } from '../../../utils/functions/fileManipulation';

const CustomDocumentViewer = ({ pdfUrl, viewerHeight }) => {
  const [pdfBlobUrl, setPdfBlobUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  useEffect(() => {
    const loadPdf = async () => {
      const pdfBlobUrl = await fetchPdfBlob(pdfUrl);
      if (pdfBlobUrl) {
        setPdfBlobUrl(pdfBlobUrl);
        setIsLoading(false);
      }
    };
    if (pdfUrl) {
      setIsLoading(true);
      loadPdf();
    }
  }, [pdfUrl]);
  const docurl = pdfUrl ? pdfBlobUrl : null;
  return (
    <Grid container style={{ height: viewerHeight || '75vh', backgroundColor: theme.palette.background.grid }} justifyContent={'center'} alignItems={'center'}>
      {isLoading ? (
          <CircularProgress color="inherit" size={30} />
      ) : (
        <DocumentViewer
          url={docurl}
          viewer={'pdf'}
          style={{ width: '100%', height: viewerHeight || '75vh' }}
        />
      )}
    </Grid>
  );
};

export default CustomDocumentViewer;
