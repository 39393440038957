import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, useMediaQuery, Popper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { monthAbbreviations } from '../../../utils/GGILUtils';

const useSmall = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme?.breakpoints?.down('sm'));
  return isSm;
};

export function ProgressBar({
  color,
  iconPosition,
  icon,
  ticketCreationDate,
  issueTitle,
  issueDescription,
  estimatedResolutionDate,
  ...props
}) {
  const [renderIconDescription, setRenderIconDescription] = useState(false);
  const [renderInitialPane, setRenderInitialPane] = useState(false);
  const theme = useTheme();
  const iconRef = useRef();
  const initialRef = useRef();
  const endRef = useRef();
  useEffect(() => {
    setRenderIconDescription(Boolean(iconRef?.current));
    setRenderInitialPane(Boolean(initialRef?.current));
  }, []);
  const computedIconPosition =
    iconPosition > 100 ? 100 : iconPosition <= 0 ? 1 : iconPosition;
  const placeOnTop = useSmall() || computedIconPosition <= 15;

  const formatDate = date => {
    const parts = date && date?.split('/');

    const originalDate = parts && new Date(`${parts[0]}/${parts[1]}/${parts[2]}`);

    const day = originalDate
      ?.getDate()
      ?.toString()
      ?.padStart(2, '0');
    const month = monthAbbreviations[originalDate?.getMonth()];
    const year = originalDate?.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <Box mb={issueDescription ? '7rem' : '3.5rem'}>
      <Box width="100%" maxWidth="125rem" {...props}>
        <Typography
          mb={{ xs: 6, sm: placeOnTop ? 6 : 3 }}
          fontSize={{ xs: '26px' }}
          variant="h1"
          color={color}
          fontWeight={500}
          textAlign="left"
        >
          {issueTitle}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '8px',
            width: '100%',
            borderRadius: '8px',
            background: `linear-gradient(to right, ${color} 0%, ${color} ${computedIconPosition}%, #DADADA ${computedIconPosition}%, #DADADA 100%)`,
          }}
        >
          <Box
            ref={initialRef}
            sx={{
              height: '16px',
              aspectRatio: '1 / 1',
              borderRadius: '50%',
              background: color,
              ...(computedIconPosition == 1 && { visibility: 'hidden' }),
            }}
          />
          <Box
            display="flex"
            alignItems="center"
            ref={iconRef}
            sx={{
              marginLeft: `calc(${computedIconPosition}% - 32px)`,
            }}
          >
            {icon}
          </Box>
          <Box
            ref={endRef}
            sx={{
              height: '16px',
              aspectRatio: '1 / 1',
              borderRadius: '50%',
              marginRight: 0,
              marginLeft: 'auto',
              border: `1px solid ${theme.palette.background.contentBox}`,
              background: 'white',
              ...(computedIconPosition == 100 && { visibility: 'hidden' }),
            }}
          />
        </Box>
      </Box>
      <Popper
        disablePortal
        open={renderIconDescription}
        anchorEl={iconRef?.current}
        placement={(placeOnTop && 'bottom-start') || undefined}
        modifiers={[
          {
            name: 'flip',
            enabled: false,
          },
        ]}
      >
        <Box
          maxWidth="200px"
          fontSize="12px"
          color="text.label"
          mt={1}
          lineHeight="1rem"
          textAlign="left"
        >
          <Typography variant="body2" fontSize="12px" fontWeight={700}>
            {issueTitle}
          </Typography>
          <Typography variant="body2" fontSize="12px">
            {issueDescription}
          </Typography>
        </Box>
      </Popper>
      <Popper
        disablePortal
        open={renderInitialPane}
        anchorEl={initialRef?.current}
        placement={placeOnTop ? 'top-start' : 'bottom-start'}
        modifiers={[
          {
            name: 'flip',
            enabled: false,
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: false,
              altBoundary: false,
              tether: false,
              rootBoundary: 'document',
              padding: 8,
            },
          },
        ]}
      >
        <Box
          maxWidth="150px"
          fontSize="12px"
          color="text.label"
          mt={1}
          lineHeight="1rem"
          textAlign="left"
        >
          <Typography variant="body2" fontSize="12px">
            Ticket created
          </Typography>
          <Typography variant="body2" fontSize="12px">
            {formatDate(ticketCreationDate)}
          </Typography>
        </Box>
      </Popper>
      {Boolean(estimatedResolutionDate) && (
        <Popper
          disablePortal
          open={renderIconDescription}
          anchorEl={endRef?.current}
          placement={'bottom-end' || undefined}
          modifiers={[
            {
              name: 'flip',
              enabled: false,
            },
          ]}
        >
          <Box
            maxWidth="200px"
            fontSize="12px"
            color="text.label"
            mt={1}
            lineHeight="1rem"
            textAlign="right"
          >
            <Typography variant="body2" fontSize="12px">
              Estimated Resolution
            </Typography>
            <Typography variant="body2" fontSize="12px">
              {formatDate(estimatedResolutionDate)}
            </Typography>
          </Box>
        </Popper>
      )}
    </Box>
  );
}
