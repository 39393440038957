/* eslint-disable no-nested-ternary */
import {
  getRef,
  toTitleCase,
  getTransformPeriod,
  getObjectIndexFromArray,
} from '../../../utils/functions';

export const checkValueLimitFunction = (storeState, checkValueLimit, data) => {
  // console.log('checkValueLimit: ', checkValueLimit);
  // console.log('storeState: ', storeState);
  // console.log('data: ', data);
  // console.log('checkValueLimit: ', checkValueLimit);
  let _data = typeof data !== 'number' ? parseInt(data, 10) : data || 0;
  _data = isNaN(_data) ? 0 : _data;
  // console.log('_data: ', _data);
  if (checkValueLimit != null) {
    if (checkValueLimit.operateOn === 'split') {
      const maxLimit =
        checkValueLimit.maxLimit || getRef(storeState, checkValueLimit.maxLimitStoreKey);
      const minLimit =
        checkValueLimit.minLimit || getRef(storeState, checkValueLimit.minLimitStoreKey);
      const index = getRef(storeState, 'wizards.allInitialWizardData.index');
      const splitValueArr = getRef(storeState, checkValueLimit.key);
      const prevSplit =
        splitValueArr === undefined
          ? null
          : getSumOfArrayObjectEleForSplit(splitValueArr, checkValueLimit.keyName, index);
      let minResult = typeof minLimit !== 'number' ? parseInt(minLimit, 10) : minLimit;
      minResult = isNaN(minResult) ? 0 : minResult;
      // console.log('minResult: ', minResult);
      let maxResult = typeof maxLimit !== 'number' ? parseInt(maxLimit, 10) : maxLimit;
      maxResult = isNaN(maxResult) ? 0 : maxResult;
      // console.log('maxResult: ', maxResult);
      if (prevSplit === null) {
        if (_data >= minResult && _data <= maxResult) {
          return false;
        }
        return true;
      }
      const remainingSplit = maxLimit - prevSplit;
      // console.log('prevSplit: ', prevSplit);
      // console.log('maxLimit: ', maxLimit);
      // console.log('remainingSplit: ', remainingSplit);
      if (_data <= remainingSplit && _data >= minLimit) {
        return false;
      }
      return true;
    }
    if (checkValueLimit.operateOn === 'simple') {
      // console.log('storeState: ', storeState);
      // console.log('checkValueLimit.maxLimitStoreKey: ', checkValueLimit.maxLimitStoreKey);
      const maxRefKey = getRef(storeState, checkValueLimit.maxLimitStoreKey);

      // console.log('maxRefKey: ', maxRefKey);
      const maxLimit = checkValueLimit.maxLimit || maxRefKey;
      const minRefKey = getRef(storeState, checkValueLimit.minLimitStoreKey);
      // console.log('minRefKey: ', minRefKey);
      const minLimit = checkValueLimit.minLimit || minRefKey;
      // console.log('maxLimit 1: ', maxLimit);
      // console.log('minLimit 1: ', minLimit);
      // console.log('checkValueLimit: ', checkValueLimit);
      let minResult = typeof minLimit !== 'number' ? parseInt(minLimit, 10) : minLimit;
      minResult = isNaN(minResult) ? 0 : minResult;
      // console.log('minResult: ', minResult);
      let maxResult = typeof maxLimit !== 'number' ? parseInt(maxLimit, 10) : maxLimit;
      maxResult = isNaN(maxResult) ? 0 : maxResult;
      // console.log('maxResult: ', maxResult);
      // console.log('_data: ', _data);
      if (_data >= minResult && _data <= maxResult) {
        // console.log('limit not exceeded: ', _data >= minResult && _data <= maxResult);
        return false;
      }
      // console.log('limit exceeded: ', _data >= minResult && _data <= maxResult);
      return true;
    }
  }
};

export const getSumOfArrayObjectEleForSplit = (array, name, index) => {
  let res = 0;
  if (array === undefined || array.length === 0) {
    return 0;
  }
  array.map(ele => {
    res += parseInt(ele[name], 10);
  });
  if (index != null || index != undefined) {
    const eleIndex = getObjectIndexFromArray(array, 'index', index);
    return res - array[eleIndex][name];
  }
  return res;
};

export default checkValueLimitFunction;
