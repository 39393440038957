import React, { useState, useContext } from 'react';
import { EncryptKeyContext } from '../context';
import { setAccessToken, removeAccessToken } from '../storage/accessToken';
import { initialEncryptKey } from '../utils/variables';

const EncryptKey = ({ children }) => {
  const curEncryptKey = useContext(EncryptKeyContext)?.encryptKey;
  // // console.log('curEncryptKey: ', curEncryptKey);

  const [encryptKey, setEncryptKey] = useState(curEncryptKey);
  // // console.log('encryptKey11: ', encryptKey);

  return (
    <EncryptKeyContext.Provider
      value={{
        encryptKey,
        setEncryptKey: value => {
          setEncryptKey(value);

          if (!value || value === initialEncryptKey) {
            removeAccessToken();
          } else {
            setAccessToken(value);
          }
        },
      }}
    >
      {children}
    </EncryptKeyContext.Provider>
  );
};

export default EncryptKey;
