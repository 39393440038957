import React from 'react';
import { useFeatureToggle } from '../../../hooks';
import FatumCard from './fatumCard';
import DefaultCard from './defaultCard';

const ProductCard = props => {
  const doFeatureToggle = useFeatureToggle();
  const useFatumProduct = doFeatureToggle('useFatumProductCard');

  if (useFatumProduct) {
    return <FatumCard {...props} />;
  }
  return <DefaultCard {...props} />;
};

export default ProductCard;
