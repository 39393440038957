import React from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  CardMedia,
  Typography,
  Grid,
  Icon,
  Stack,
  Chip,
  Tooltip,
} from '@mui/material';
import { useStore } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import styles from './style';
import { isValidHttpUrl, uuidv4, sourceTextFromData } from '../../../utils/functions';
import { domain } from '../../../utils/variables';
import { CustomTooltip } from '../../common';

function CallToActionCard({
  title,
  description,
  imageSrc,
  footer,
  content,
  jsxContent,
  actions,
  actionWidth,
  chips,
  chipsLabel,
  icon,
  iconTooltip,
  chipsTooltip,
  titleColor,
}) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const storeState = useStore()?.getState() || {};
  storeState.domain = domain;
  return (
    <Card className={classes.cardContainer}>
      <CustomTooltip title={iconTooltip} width="20rem">
        <Typography className={classes.cardHeaderText} color={titleColor || 'primary'}>
          <Grid container spacing={1} direction="row" alignItems="center">
            {icon && (
              <Grid item xs={2} align="center">
                <Icon
                  data-nosnippet
                  // fontSize="20px"
                  // sx={{ mr: 1 }}
                >
                  {icon}
                </Icon>
              </Grid>
            )}
            {title && (
              <Grid item xs={icon ? 10 : 12} align="left">
                {title}
              </Grid>
            )}
          </Grid>
        </Typography>
      </CustomTooltip>
      {imageSrc && (
        <CardMedia
          component="img"
          image={imageSrc}
          alt="referral-Image"
          className={classes.cardImageStyle}
        />
      )}
      <CardContent className={classes.cardContentStyle}>
        {chips && chips?.length > 0 && (
          <>
            <CustomTooltip title={chipsTooltip} width="20rem">
              {chipsLabel && (
                <Typography variant="caption" sx={{ width: '100%' }}>
                  {chipsLabel}
                </Typography>
              )}
              <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
                {chips &&
                  chips?.map(tag => (
                    <Chip
                      label={tag || ''}
                      color={undefined}
                      variant="outlined"
                      size="small"
                    />
                  ))}
              </Stack>
            </CustomTooltip>
          </>
        )}
        {jsxContent}
        {description && (
          <Typography
            variant="body1"
            align="left"
            gutterBottom
            color="text.dark"
            className={classes.description}
          >
            {description}
          </Typography>
        )}
        {content && <div style={{ whiteSpace: 'pre-wrap' }}>{content}</div>}
        {footer && footer}
      </CardContent>
      {actions && actions?.length > 0 && (
        <CardActions className={classes.cardActions} sx={{ pt: 1, mt: 'auto' }}>
          <Grid container justifyContent="center" spacing={1}>
            {actions.map(action => {
              const newRedirectUrl = sourceTextFromData(storeState, action?.url);
              return (
                <Grid
                  key={uuidv4()}
                  item
                  xs={12}
                  md={12}
                  lg={actionWidth || 12}
                  sx={{ height: 'auto' }}
                >
                  <Button
                    justifyContent="center"
                    className={classes[action?.className || 'secondaryButton']}
                    component={isValidHttpUrl(newRedirectUrl) ? 'a' : RouterLink}
                    href={isValidHttpUrl(newRedirectUrl) && newRedirectUrl}
                    target={isValidHttpUrl(newRedirectUrl) ? '_blank' : '_self'}
                    to={!isValidHttpUrl(newRedirectUrl) && newRedirectUrl}
                    startIcon={action?.icon && <Icon data-nosnippet>{action?.icon}</Icon>}
                    sx={{ height: '100%', fontWeight: 'bold !important' }}
                  >
                    {action?.capitalizeText
                      ? action?.title?.toUpperCase()
                      : action?.title}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </CardActions>
      )}
    </Card>
  );
}

export default CallToActionCard;
