import { portfolioButtonStyles, toastrStyles } from '../../styles';

const styles = theme => ({
  ...portfolioButtonStyles(theme),
  ...toastrStyles(theme),
  disablePadding: {
    padding: '6px 0px !important',
  },
  main: {
    height: '100%',
    minHeight: '100vh',
    width: '100%',
    padding: `${theme.spacing(4)}`,
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(2)}`,
    },
    backgroundColor: theme.palette.background.main,
  },
  flag: {
    height: '35px',
    borderRadius: theme.shape.borderRadius,
    width: '50px',
    objectFit: 'cover',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
  },
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    [theme.breakpoints.down('md')]: {
      width: 350,
    },
    bgcolor: theme.palette.background.paper,
    boxShadow: 24,
    padding: theme.spacing(4),
    outline: 'none',
  },
  modal: {},
});

export default styles;
