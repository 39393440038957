import { useSelector } from 'react-redux';
// import { getReports } from '../actions/reports';
import { dateToString, toTitleCase } from '../utils/functions';
import { listSelector } from '../selectors';
import { reportsIndexName } from '../utils/variables';
// import { loadReportsCount } from '../utils/variables';

const useReportData = ({ infiniteScroll = false } = {}) => {
  // const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const customerId = user?.customer?.id || '';
  const defaultOptions = useSelector(state =>
    listSelector.listOptions({ state, name: reportsIndexName, infiniteScroll }),
  );

  return ({ nextStartIndex, nextItemCount, nextScrollCount }) => {
    // const limit = count; // stopIndex - startIndex;
    // const offset = startIndex;
    const body = {
      id: customerId,
      limit: defaultOptions?.itemCount,
      offset: defaultOptions?.startIndex,
      fromDate: dateToString(defaultOptions?.startDate),
      toDate: dateToString(defaultOptions?.endDate),
      lob: toTitleCase(defaultOptions?.lob.toString()), // defaultOptions?.lob,
      nextItemCount,
      nextScrollCount,
      nextStartIndex,
    };

    return () => {};
  };
};
export default useReportData;
