/*
import { useMainContentHeight } from '../../../hooks';
const {
  contentHeight,
  bottomNavHeight,
  headerHeight,
  footerHeight,
} = useMainContentHeight();
*/
const Styles = theme => {
  return {
    root: {
      position: 'relative',
      width: 'inherit',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: theme.spacing(2),

      [theme.breakpoints.down('sm')]: {
        height: 0, // '48px', // height of two rows of breadcrumb
        justifyContent: 'center',
      },
      [theme.breakpoints.up('md')]: {
        height: '100%', // height of two rows of breadcrumb
      },
    },
    breadcrumbWrapper: {
      position: 'absolute',
      top: 0,
      width: '100%',
      left: 0,
    },
    breadcrumb: {
      opacity: 1,
      width: 'inherit',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        width: '100%',
        height: '48px',
        background: theme.palette.background.default,
      },
      // borderRadius: theme.shape.borderRadius,
    },
    backgroundColor: {
      backgroundColor: theme.palette.background.paper,
      // height: headerHeight,
    },
    link: {
      fontSize: '0.875rem',
      fontWeight: 400,
      color: theme?.palette?.text?.disabled,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
};

export default Styles;
