import {
  GET_INSURED_ITEM_REQUEST,
  GET_INSURED_ITEM_SUCCESS,
  GET_INSURED_ITEM_ERROR,
  GET_NEXT_INSURED_ITEMS_SUCCESS,
} from '../../constants/insuredItemsActionNames';
import { RESET_INFINITE_SCROLL_SUCCESS } from '../../constants/miscActionNames';
import payloadData from './payloadData';

import {
  isoStringToDate,
  dateToString,
  uniq,
  getAllKeys,
  getKeys,
} from '../../utils/functions';
import { dutchTemplate, statusOk } from '../../utils/variables';

let searchKeys = [];

const createPayload = (payloadArray, params, _policy, templateName = '') => {
  const isDutchRegion = templateName === dutchTemplate;
  if (!Array.isArray(payloadArray)) return null;
  searchKeys = [];
  const policy = _policy || {};
  // console.log('Policy', policy);
  const {
    lob,
    inceptionDate,
    originalInceptionDate,
    currency,
    icon,
    insuredName,
    status,
  } = policy;

  const newInsuredItems = payloadArray.map((object, index) => {
    // // console.log('Object', object);
    const position = index;
    const filterId = `insured_item_${position + 1}`;
    const insuredItem = {
      ...object,
      index: position,
      id: filterId,
      slug: params?.policyId,
      policyId: params?.policyId,
      itemSlug: filterId,
      filterDate: dateToString(isoStringToDate(object?.general?.addedOn)),
      filterId,
      inceptionDate,
      filterStatus: object?.status,
      filterLob: lob,
      lob,
      icon,
      currency,
      originalInceptionDate,
      insuredName,
      status,
      premium: (object?.basePremium || 0) + (object?.premiumChg || 0),
      homePremium: object?.annualPremium,
      riskTypeCheck: params?.policyId.split(' ')[1],
    };
    const newKeys = getKeys(insuredItem);
    searchKeys = [...searchKeys, ...newKeys];
    searchKeys = uniq(searchKeys);
    return insuredItem;
  });
  /*
  newInsuredItems.sort((a, b) => {
    return b.filterDate > a.filterDate ? 1 : -1;
  });
  */

  return newInsuredItems;
};

export const initialState = {
  isPending: false,
  isError: false,
  isSuccess: false,
  response: null,
  error: null,
  allRows: [],
  count: 0,
  nextItemCount: 0,
  nextScrollCount: 0,
  nextStartIndex: 0,
  searchKeys,
};

export const getPayloadResponse = () => {
  return { payload: [{ insuredItems: payloadData, count: 100000 }] };
};

function insuredItemsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_INSURED_ITEM_SUCCESS: {
      const insuredItems = action?.payload?.payload;
      const params = action?.params;
      const policy = action?.policy;

      const rows = insuredItems;
      // // console.log('rows: ', rows);
      const allRows = createPayload(rows, params, policy) || state?.allRows;
      const count = allRows?.length || 0;

      const response = action?.payload;
      const isError = response?.responseType !== statusOk;
      // response.payload = null;

      return {
        ...state,
        count,
        allRows,
        isPending: false,
        isError,
        isSuccess: true,
        response,
        searchKeys,
      };
    }
    case GET_INSURED_ITEM_REQUEST: {
      return {
        ...state,
        isPending: true,
        isError: false,
        isSuccess: false,
      };
    }
    case GET_INSURED_ITEM_ERROR: {
      return {
        ...state,
        isPending: false,
        isError: true,
        isSuccess: false,
        // error: action?.payload,
      };
    }

    case GET_NEXT_INSURED_ITEMS_SUCCESS: {
      const nextStartIndex = action?.payload?.nextStartIndex;
      const nextScrollCount = action?.payload?.nextScrollCount;
      const nextItemCount = action?.payload?.nextItemCount;

      return {
        ...state,
        nextItemCount,
        nextScrollCount,
        nextStartIndex,
      };
    }

    case RESET_INFINITE_SCROLL_SUCCESS: {
      return {
        ...state,

        nextItemCount: 0,
        nextScrollCount: 0,
        nextStartIndex: 0,
      };
    }
    default:
      return state;
  }
}

export default insuredItemsReducer;
