import { crmPostCall } from '../../../axios';
import { helpCenterApi } from '../../../../utils/functions/helpCenterApis';
import { GETDROPDOWNVALUESURL, SERVERERROR } from '../../constants';

export const getDropdownValuesUrl = helpCenterApi(GETDROPDOWNVALUESURL);

const getDropdownValues = (state, body) => {
  const url = getDropdownValuesUrl;
  if (url) {
    return crmPostCall(state, url, body);
  }
  return Promise.reject(new Error(SERVERERROR));
};

export default { getDropdownValues };
