import Fuse from 'fuse.js';
import queryString from 'query-string';
import { fuseOptions, trimOneChar } from './functions';
import { getKeys, reduceKeys } from '../utils/functions';

export const notificationFilter = (notifications, location) => {
  const parsed = queryString.parse(location.search);
  const options = { ...parsed };
  let filteredRows = [...notifications];

  if (options.search && options.search.length > 0 && filteredRows.length > 0) {
    const obj = filteredRows && filteredRows.length > 0 ? filteredRows[0] : {};
    // const reduceObj = reduceKeys(obj);
    const searchKeys = getKeys(obj);

    const fuse = new Fuse(filteredRows, fuseOptions(searchKeys));
    let searchResults = fuse.search(options.search);
    if (!searchResults || searchResults.length === 0) {
      options.search = `*${options.search}*`;
      searchResults = fuse.search(options.search); // .replace(/ /g, '|')
      options.search = trimOneChar(options.search, '*');
    }

    // // console.log('searchResults: ', searchResults);
    filteredRows = searchResults.map(result => result.item);
  }

  filteredRows = filteredRows.filter(el => {
    return el != null;
  });

  return { rows: filteredRows, options };
};

export default {
  notificationFilter,
};
