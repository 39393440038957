import React from 'react';
import { makeStyles } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import {
  Divider,
  Grid,
  Stack,
  Typography,
  Container,
  Paper,
  Button,
} from '@mui/material';
import styles from './style';
import { useSmall } from '../../../hooks';
import routes from '../../../utils/variables/routes';
import { updateTempState } from '../../../actions/temp';
import { uuidv4 } from '../../../utils/functions';
import { getSessionStorage, setSessionStorage } from '../../../storage/sessionStorage';

const useStyles = makeStyles(styles);

const Login = ({ id, askMessage }) => {
  const theme = useTheme();
  const isSmall = useSmall();
  const dispatch = useDispatch();
  const classes = useStyles();
  const askToLoginSession = getSessionStorage('askToLogin') || {};

  const handleClick = () => {
    askToLoginSession[id] = id;
    setSessionStorage('askToLogin', askToLoginSession, { isObject: true });
    setTimeout(() => {
      dispatch(updateTempState.updateTempStateAction({ reloadWizard: uuidv4() }));
    }, 100);
  };

  return (
    <Container maxWidth={isSmall ? 'sm' : 'md'} sx={{ pt: 4 }}>
      <Paper sx={{ p: isSmall ? 3 : 10 }}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Stack alignItems="center" justifyContent="center" spacing={1}>
              <Typography color="primary" gutterBottom variant={isSmall ? 'h3' : 'h4'}>
                Login or Continue as Guest
              </Typography>
              <Typography variant="body1" textAlign="justify">
                {askMessage}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Button
              component={RouterLink}
              to={routes.auth.signIn}
              className={classes.primaryButton}
              size="large"
            >
              Proceed to Login / Create an Account
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.secondaryButton}
              size="large"
              onClick={handleClick}
            >
              Continue as Guest
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default Login;
