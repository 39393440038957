/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Stepper, Step, StepLabel, StepConnector, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import classnames from 'classnames';
import { StartStepper, MiddleStepper, EndStepper } from '../../icons';

// const useStyles = makeStyles(styles);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 0,
    backgroundColor: 'transparent',
  },
  step: {
    padding: 0,
  },
  connector: {
    display: 'none',
  },
  font: {
    fontSize: theme.typography.h2.fontSize,
  },

  parentDiv: {
    width: '100%',
    position: 'relative',
    textAlign: 'center',
  },
  text: {
    display: 'inline-block',
    textAlign: 'center',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const MyStepIcon = props => {
  const classes = useStyles();
  const { active, label, activeStep, index } = props;
  const theme = useTheme();

  const icons = {
    0: StartStepper,
    1: MiddleStepper,
    2: EndStepper,
  };
  const inActiveColor = theme.palette.grey[500];
  const activeColorObj = {
    0: theme.palette.primary.light,
    1: theme.palette.primary.main,
    2: theme.palette.primary.dark,
  };
  const textLeftAdjust = {
    0: 5,
    1: 11,
    2: 11,
  };

  const MyIcon = icons[index] || icons[0];
  const activeColor = activeColorObj[index] || activeColorObj[0];
  const height = '100%';
  const width = '120%';

  const backgroundColor = active ? activeColor : inActiveColor;
  const textColor = active
    ? theme.palette.getContrastText(theme.palette.secondary.main)
    : theme.palette.common.black;

  return (
    <span className={classnames(classes.parentDiv)} style={{ left: index * -10 }}>
      <Typography
        variant="body2"
        style={{ color: textColor, marginLeft: textLeftAdjust[index] }}
        className={classes.secondaryButton}
      >
        {label}
      </Typography>
      <MyIcon
        className={classes.font}
        backgroundColor={backgroundColor}
        height={height}
        width={width}
      />
    </span>
  );
};

MyStepIcon.propTypes = {
  /**
   * Whether this step is currently active or finished.
   */
  active: PropTypes.bool.isRequired,
  /**
   * The label displayed in the step icon.
   */
  label: PropTypes.node.isRequired,
  activeStep: PropTypes.number.isRequired,
};

function getSteps() {
  return ['Submitted', 'Processing', 'Completed'];
}

const CustomStepper = ({ status }) => {
  const classes = useStyles();
  const steps = getSteps();

  let activeStep = steps.indexOf(status);
  console.log('activeStep: ', activeStep);
  if (activeStep < 0) {
    activeStep = 0;
  }

  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<StepConnector classes={{ root: classes.connector }} />}
      className={classes.root}
    >
      {steps.map((label, index) => (
        <Step key={label} className={classes.step}>
          <StepLabel
            StepIconComponent={() => (
              <MyStepIcon
                active={activeStep >= index}
                label={label}
                activeStep={activeStep}
                index={index}
              />
            )}
          />
        </Step>
      ))}
    </Stepper>
  );
};

export default CustomStepper;

//      <StartStepper viewBox="0 0 48 48" className={classes.font} />
// connector={<ColorlibConnector />}
