export const ADD_PRECERT_REQUEST = 'ADD_PRECERT_REQUEST';
export const ADD_PRECERT_SUCCESS = 'ADD_PRECERT_SUCCESS';
export const UPDATE_PRECERTS_CURRENT_CACHE_KEY = 'UPDATE_PRECERTS_CURRENT_CACHE_KEY';
export const UPDATE_PENDING_PRECERTS_CURRENT_CACHE_KEY =
  'UPDATE_PENDING_PRECERTS_CURRENT_CACHE_KEY';
export const ADD_PRECERT_ERROR = 'ADD_PRECERT_ERROR';
export const GET_REGISTERED_PRECERTS_REQUEST = 'GET_REGISTERED_PRECERTS_REQUEST';
export const GET_REGISTERED_PRECERTS_SUCCESS = 'GET_REGISTERED_PRECERTS_SUCCESS';
export const GET_REGISTERED_PRECERTS_ERROR = 'GET_REGISTERED_PRECERTS_ERROR';

export const GET_PENDING_PRECERTS_REQUEST = 'GET_PENDING_PRECERTS_REQUEST';
export const GET_PENDING_PRECERTS_SUCCESS = 'GET_PENDING_PRECERTS_SUCCESS';
export const GET_PENDING_PRECERTS_ERROR = 'GET_PENDING_PRECERTS_ERROR';

export const GET_PRECERT_BY_ID_SUCCESS = 'GET_PRECERT_BY_ID_SUCCESS';
export const GET_PRECERT_BY_ID_REQUEST = 'GET_PRECERT_BY_ID_REQUEST';
export const GET_PRECERT_BY_ID_ERROR = 'GET_PRECERT_BY_ID_ERROR';

export const DELETE_PENDING_PRECERT_ERROR = 'DELETE_PENDING_PRECERT_ERROR';
export const DELETE_PENDING_PRECERT_SUCCESS = 'DELETE_PENDING_PRECERT_SUCCESS';
export const DELETE_PENDING_PRECERT_REQUEST = 'DELETE_PENDING_PRECERT_REQUEST';
