import { alpha } from '@mui/material/styles';
import { dutchTemplate } from '../../../utils/variables';
export default theme => ({
  loadingMessage: {
    position: 'relative',
    width: '100%',

    display: ' flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  center: {
    display: 'block',

    textAlign: 'center',
  },
  text: {
    marginTop: theme.spacing(1),
  },
  backdrop: {
    color: theme.palette.primary.main,
    display: theme?.template === dutchTemplate ? 'flex' : 'none',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
  },
});
