import React from 'react';
import { Box, Typography, useMediaQuery, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import {
  ActionButton,
  ContentDisplay,
  ContentProseDisplay,
  ImageBox,
  TabSelector,
} from '../../../componentsV2/common';
import { useScreenWidth } from '../../../hooks';
import GridCardsContainer from './gridContainer';

const ModalHeader = props => {
  const newStyle = props.style || {};
  let newColor = props?.color;
  if (newColor === 'secondary' && props?.isOnlyPrimary) newColor = 'primary';

  if (!props.isAlreadyGrid && props.isGridEnabled) {
    const contents = [];
    contents.push({ ...props, color: newColor });
    return (
      <GridCardsContainer
        contents={contents}
        subComponentType="Header"
        isNotGridContained
      />
    );
  }
  return (
    <Typography
      // component={props.variant || 'h3'}
      style={{
        textAlign: 'left',
        width: '100%',
        paddingTop: '15px',
        paddingBottom: '15px',
        ...newStyle,
      }}
      variant={props.variant || 'h5'}
      color={newColor || 'primary'}
    >
      {props.title || props.value || ''}
    </Typography>
  );
};

const CustomImageBox = props => {
  if (!props.isAlreadyGrid && props.isGridEnabled) {
    const contents = [];
    contents.push({ ...props });
    return (
      <GridCardsContainer
        contents={contents}
        subComponentType="ImageBox"
        isNotGridContained
      />
    );
  }

  const imageHeight = props?.source?.height;
  const imageWidth = props?.source?.width;

  let defaultHeight = { lg: 600, sm: 300, md: 450 };
  let defaultWidth = { lg: 800, sm: 400, md: 600 };

  if (imageWidth > imageHeight) {
    defaultHeight = {
      lg: defaultWidth?.lg * (imageHeight / imageWidth),
      sm: defaultWidth?.sm * (imageHeight / imageWidth),
      md: defaultWidth?.md * (imageHeight / imageWidth),
    };
  }
  if (imageHeight >= imageWidth) {
    defaultWidth = {
      lg: defaultHeight?.lg * (imageWidth / imageHeight),
      sm: defaultHeight?.sm * (imageWidth / imageHeight),
      md: defaultHeight?.md * (imageWidth / imageHeight),
    };
  }
  const { height, width, source } = props;
  const screenWidth = useScreenWidth();

  let finalHeight = height && height[screenWidth()];
  let finalWidth = width && width[screenWidth()];
  if (!finalHeight) {
    const tempHeight = defaultHeight && defaultHeight[screenWidth()];
    finalHeight = tempHeight && parseInt(tempHeight, 10);
    const tempWidth = defaultWidth && defaultWidth[screenWidth()];
    finalWidth = tempWidth && parseInt(tempWidth, 10);
    // console.log('tempHeight: ', tempHeight);
    // console.log('tempWidth: ', tempWidth);
  }
  const cssUnits = ['%', 'em', 'rem', 'px', 'vh', 'vw'];
  const hasWidthUnits = cssUnits?.some(x => {
    return finalWidth?.toString()?.includes(x);
  });
  const hasHeightUnits = cssUnits?.some(x => {
    return finalHeight?.toString()?.includes(x);
  });

  if (!hasHeightUnits && finalHeight) {
    finalHeight = `${finalHeight}px`;
  }
  if (!hasWidthUnits && finalWidth) {
    finalWidth = `${finalWidth}px`;
  }

  // console.log('finalHeight: ', finalHeight);
  // console.log('finalWidth: ', finalWidth);
  return (
    <Grid
      container
      style={{
        position: 'relative',
        height: finalHeight || 'auto',
        width: finalWidth || '100%',
      }}
    >
      <ImageBox {...props} height={height} width={width} newSource={source} />
    </Grid>
  );
};

const CustomActionButton = props => {
  if (props.hidden) return null;
  let newButtonColor = props?.buttonColor;
  if (props?.buttonColor === 'secondary' && props?.isOnlyPrimary)
    newButtonColor = 'primary';
  if (!props.isAlreadyGrid && props.isGridEnabled) {
    const contents = [];
    contents.push({ ...props });
    return (
      <GridCardsContainer
        contents={contents}
        subComponentType="ActionButton"
        isNotGridContained
      />
    );
  }
  return (
    <Grid container style={{ width: '100%' }}>
      <ActionButton
        {...props}
        width="100%"
        maxModalWidth="md"
        buttonColor={newButtonColor}
      />
    </Grid>
  );
};

const CustomRichText = props => {
  const theme = useTheme();
  if (!props.isAlreadyGrid && props.isGridEnabled) {
    const contents = [];
    contents.push({ ...props });
    return (
      <GridCardsContainer
        contents={contents}
        subComponentType="RichText"
        isNotGridContained
      />
    );
  }

  return (
    <Box>
      {props.content &&
        props.content !== 'undefined' &&
        props.content !== 'null' &&
        props.content !== '<p></p>' &&
        props.content !== '<p><br></p>' && (
          <ContentDisplay content={props.content} videoHeight={props?.videoHeight} />
        )}
      {props.proseMirrorContent &&
        props.proseMirrorContent !== 'undefined' &&
        props.proseMirrorContent !== 'null' &&
        props.proseMirrorContent !== '<p></p>' &&
        props.proseMirrorContent !== '<p><br></p>' && (
          <ContentProseDisplay
            content={props.proseMirrorContent}
            videoHeight={props?.videoHeight}
          />
        )}
    </Box>
  );
};

const CustomTabSelector = props => {
  return <TabSelector {...props} />;
};

const components = {
  ActionButton: CustomActionButton,
  RichText: CustomRichText,
  Header: ModalHeader,
  HeaderTitle: ModalHeader,
  ImageBox: CustomImageBox,
  TabSelector: CustomTabSelector,
};

export default components;
