import {
  GET_INSURED_ITEM_REQUEST,
  GET_INSURED_ITEM_SUCCESS,
  GET_INSURED_ITEM_ERROR,
} from '../../../constants/insuredItemsActionNames';

import { getInsuredItemApi } from '../../../apis/insuredItems';

function getInsuredItemRequest() {
  return {
    type: GET_INSURED_ITEM_REQUEST,
    payload: true,
  };
}

function getInsuredItemSuccess(insuredItem, params, policy) {
  return {
    type: GET_INSURED_ITEM_SUCCESS,
    payload: insuredItem,
    params,
    policy,
  };
}

function getInsuredItemError(err) {
  return {
    type: GET_INSURED_ITEM_ERROR,
    payload: err,
  };
}

export function getInsuredItemAction(params, policy) {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(getInsuredItemRequest());
    return getInsuredItemApi
      .getInsuredItem(state, params)
      .then(data => {
        dispatch(getInsuredItemSuccess(data, params, policy));
        return data;
      })
      .catch(error => {
        dispatch(getInsuredItemError(error));
        throw error;
      });
  };
}

export default { getInsuredItemAction };
