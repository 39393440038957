import { gql } from 'graphql-request';

const APPEND_KEY_FIELDS = gql`
  fragment appendKey on ComponentMiscComponentsAppendKey {
    key
    referenceKey
    referenceOperation
    appendFrom
    staticValue
    fieldType
    isConditionalComponent
    conditionalValue
    conditionalKey
    conditionalOperator
    conditionalFieldType
    conditionalAction
  }
`;

export default APPEND_KEY_FIELDS;
