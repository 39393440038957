import { SET_RECAPTCHA_TOKEN } from '../../../constants/authActionNames';

export function setRecaptchaTokenAction(data) {
  return {
    type: SET_RECAPTCHA_TOKEN,
    payload: data,
  };
}

export default {
  setRecaptchaTokenAction,
};
